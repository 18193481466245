import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
} = config;

export interface Props {
  subTitleText: React.ReactNode;
}

const Component: React.FC<Props> = observer((props) => {
  return (
    <>
      <Styled_body_wrap>
        <div className="subtitle_text">
          {props.subTitleText}
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
