import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
  help_page_success_asset_v1,
} = config;

const Component: React.FC<{}> = observer(() => {
  return (
    <>
      <Styled_body_wrap>
        <div className="image_w_bg">
          <picture>
            <source  className="image_w_bg_box" media="(min-width : 361px) and (max-width : 768px)" srcSet={help_page_success_asset_v1}></source>
            <source className="image_w_bg_box" media="(min-width : 769px) and (max-width : 1150px), (min-width : 1151px) and (max-width : 1439px), (width : 1440px), (min-width : 1441px)" srcSet={help_page_success_asset_v1}></source>
            <img className="image_w_bg_box" src={help_page_success_asset_v1} alt={`help_page_success_asset_v1`}></img>
          </picture>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
