import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { Img_box_with_title_01 } from "../../components/v2/jobsPage_img_box_with_title_01";
import { Img_box_with_title_02 } from "../../components/v2/jobsPage_img_box_with_title_02";
import { Card_with_avatar } from "../../components/v2/jobsPage_card_with_avatar";
import { Perks_benefits_section } from "../../components/v2/jobsPage_perks_benefits";
import config from "../../utils/config";
import milesUtils, { useCountry } from "../../utils/milesUtils";
import changeLanguage from '../../utils/i18n/i18next';
import { Styled_body_wrap } from "./styles";
import './index.css';

let firstRender = true;

const {
  jobs_top_asset_1440_img,
  jobs_header_asset_01_img,
  jobs_find_your_fit_img,
  jobs_working_at_miles_img,
  jobs_avatar_01_img,
  jobs_avatar_02_img,
  jobs_avatar_03_img,
  jobs_perks_asset_img,
  jobs_perks_healthy_icon_img,
  jobs_perks_stock_icon_img,
  jobs_perks_pto_icon_img,
  jobs_perks_gift_icon_img,
  jobs_diversity_bg_img,
  jobs_miles_family_img,
  jobs_miles_location_img,
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
} = config;

const MilesWeb_JobsPage: React.FC<{}> = observer(() => {
  let country = useCountry();
  const { t } = useTranslation();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_JobsPage -- firstRender");
    switch (country) {
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_JobsPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  const card_with_testimonial_data = [
    {
      avatarImgSrc: jobs_avatar_01_img,
      avatarImgStyle: {
        width: "140px",
        height: "140px",
        zIndex: 222,
      },
      userName: "Irene Kadir",
      testimonialStr: `“It's fun to work and learn from great people! The team helps me to grow. Love Miles!”`,
      testimonialStyle: {
        width: "240px",
        height: "80px",
      },
    },
    {
      avatarImgSrc: jobs_avatar_02_img,
      avatarImgStyle: {
        width: "140px",
        height: "140px",
        zIndex: 222,
      },
      userName: "Samantha Brown",
      testimonialStr: ` “I love my team! Everyone here is exceptionally talented and passionate about what they do.”`,
      testimonialStyle: {
        width: "240px",
        height: "80px",
      },
    },
    {
      avatarImgSrc: jobs_avatar_03_img,
      avatarImgStyle: {
        width: "140px",
        height: "140px",
        zIndex: 222,
      },
      userName: "Balaji Yelchuru",
      testimonialStr: ` “At Miles, it is not just about having great ideas, but execute those ideas to perfection.”`,
      testimonialStyle: {
        width: "240px",
        height: "80px",
      },
    },    
  ]

  let testimonialEle: ReactNode[] = []
  card_with_testimonial_data.map((card, index) => {
    testimonialEle.push(
      <Card_with_avatar
        key={`jobs_work_at_miles_${index}`}
        className={`jobs_work_at_miles_${index}`}
        avatarImgSrc={card.avatarImgSrc}
        avatarImgStyle={card.avatarImgStyle}
        userName={card.userName}
        testimonialStr={card.testimonialStr}
        testimonialStyle={{
          width: card.testimonialStyle.width,
          height: card.testimonialStyle.height,
        }}
      />
    )
  })

  return (
    <Styled_body_wrap>
      <div className="jobs_top_asset display_block"></div>

      <div className="section_rocket_ship_wrap">
        <div className="section_rocket_ship_body">
          <div className="section_rocket_ship_title">{t('jobs_page_text_001')}</div>
          <div className="section_rocket_ship_content">{t('jobs_page_text_002')}</div>
        </div>
      </div>

      <div className="section_right_fit_wrap">
        <div className="section_right_fit_body">
          <div className="section_right_fit_content_wrap">
            <div className="section_right_fit_title">{t('jobs_page_text_003')}</div>
            <div className="section_right_fit_content_text">{t('jobs_page_text_004')}</div>
            <a className="section_right_fit_cta miles_ui_btn_primary" href={t('about_page_work_at_miles_url')} target="_blank">
            {t('jobs_page_text_005')}
            </a>
          </div>
          <img className="jobs_find_your_fit_asset display_block" src={jobs_find_your_fit_img} alt={`jobs_find_your_fit_img`}></img>
        </div>
      </div>

      {
        country === "jp" ? null : (
          <>
            
            <Img_box_with_title_01
              title={`What It’s Like Working At Miles!`}
              img_src={jobs_working_at_miles_img}
            />
            <div className="testimonial_card">{testimonialEle}</div>

            <Perks_benefits_section
              bgImgSrc={jobs_perks_asset_img}
              healthy_icon_img={jobs_perks_healthy_icon_img}
              stock_icon_img={jobs_perks_stock_icon_img}
              pto_icon_img={jobs_perks_pto_icon_img}
              gift_icon_img={jobs_perks_gift_icon_img}
            />

            <Img_box_with_title_02
              title={`A feeling of Inclusion & Diversity is a must at Miles!`}
              img_src={jobs_diversity_bg_img}
            />
          </>
        )
      }

      <div className="miles_family_wrap">
        {country === "jp" ? null : <div className="miles_ui_family"></div> }
        {/* <div className="miles_ui_jobsPage_title">{t('jobs_page_text_006')}</div>
        <div className="miles_ui_jobsPage_sub_title larger_than_768">{t('jobs_page_text_007')}</div>
        <div className="miles_ui_jobsPage_sub_title between_360_and_768">{t('jobs_page_text_008')} </div>
        <div className="miles_ui_jobsPage_address larger_than_768">
          <img className="jobs_miles_location" src={jobs_miles_location_img} alt={'jobs_miles_location_img'}></img>
          <div className="address_01">{t('jobs_page_text_009')} </div>
          <div className="address_02">{t('jobs_page_text_010')} </div>
          <div className="address_03">{t('jobs_page_text_011')}  </div>
        </div>

        <div className="miles_ui_jobsPage_address between_360_and_768">
          <div className="mobile_address_box">
            <img className="mobile_address_box_asset" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/location_icon_green%402x.png`} alt={'mobile_address_box_asset'}></img>
            <div className="address_01_mobile">{t('jobs_page_text_009')} </div>
            <div className="address_02_mobile">{t('jobs_page_text_010')} </div>
            <div className="address_03_mobile">{t('jobs_page_text_011')} </div>
          </div>

          <img className="jobs_miles_location_mobile" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_location_between_360_and_768%402x.png`} alt={'jobs_miles_location_img_mobile'}></img>
        </div> */}
        <div className="miles_ui_jobsPage_sub_title_02">{t('jobs_page_text_012')}</div>

        <a className="join_us_cta miles_ui_btn_primary" href={t('about_page_work_at_miles_url')} target="_blank">
        {t('jobs_page_text_013')}
        </a>
      </div>
      
    </Styled_body_wrap>
  );
});

export default MilesWeb_JobsPage;
