import styled from "styled-components";
import i18next from 'i18next';

interface Props {}
export const StyledUpdateConfirmBtn = styled("button")<Props>`
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
    width: 119px;
    height: 50px;
    margin: 0 0px 0 139px;
    padding: ${() => i18next.language === "english" ? "15px 30px" : "inherit"};
    border-radius: 3px;
    background-color: #e4e6e9;
    font-size: 15px;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #343434;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    margin: 0 20px 0 0;
    background-color: #e4e6e9;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    margin: 0 20px 0 0;
    background-color: #e4e6e9;
  }
`;