import React from "react";
import { Styled_Super_wrap, Styled_We_care_your_privacy } from "./styles";

interface Props {
  we_care_your_privacy_img: string;
}

export const We_care_your_privacy: React.FC<Props> = ({
  we_care_your_privacy_img,
}) => {
  return (
    <Styled_Super_wrap style={{ width: "100vw", backgroundColor: "#d4f0dd" }}>
      <Styled_We_care_your_privacy>
        <img className="inner_box_01" src={we_care_your_privacy_img} alt="brands_you_love_img"></img>

        <div className="inner_box_02">
          <div className="inner_box_02_title">We Value Your Privacy And Security</div>
          <div className="inner_box_02_content">When you use our app your data is always secure. Your privacy is important to us and we are fully committed to protecting it.</div>
        </div>
      </Styled_We_care_your_privacy>

      <div className="miles_ui_less_than_768">
        <div className="inner_box_title">We Value Your Privacy And Security</div>
        <img className="inner_box_asset" src={we_care_your_privacy_img} alt="brands_you_love_img"></img>
        <div className="inner_box_content">When you use our app your data is always secure. Your privacy is important to us and we are fully committed to protecting it.</div>
      </div>
    </Styled_Super_wrap>
  );
};
