import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import {
  useLocation
} from "react-router-dom";
import copy from 'copy-to-clipboard';
import { AsYouType } from 'libphonenumber-js'
import { store } from "../../Store";
import { MilesWebHeader } from "../../components/miles_web_header_v2"; // done
import { ImageCard } from "../../components/image_card_v2"; // done
import { Divider } from "../../components/divider_v2"; // done
import { AdBox } from "../../components/ad_box_v2"; // done
import { AppStoreIconBox } from "../../components/app_store_icon_box_v2";// done
import { DownloadTextBox } from "../../components/download_text_box_v2";// done
import { DownloadNumberInput } from "../../components/download_number_input_v2";// done
import { SendDownloadLinkBtn } from "../../components/send_downloadLink_btn_v2";// done
import { SendDownloadErrTip } from "../../components/download_err_tip_v2";// done
import { GetTheAppBox } from "../../components/get_the_app_box_v2";// done
import { ListItem } from "../../components/list_item_v2";// done
import { RewardShareTextImgCard } from "../../components/reward_share_text_img_card_v2";// done
import { Send_download_link } from "../../components/v2/miles_ui_box_send_download_link_v2";

import { RewardShareCopyBox } from "../../components/reward_share_copy_box_v2";
import { RewardShareCopyTip } from "../../components/reward_share_copy_tip_v2";
import { RewardShareCopyBtn } from "../../components/reward_share_copy_btn_v2";

import {
  checkValidPhoneNumber,
  jp_phone_regex,
} from '../../utils/phoneNumValidator';
import config from "../../utils/config";
import { useCountry, useLanguageOnTenant } from "../../utils/milesUtils";
import { useTranslation } from 'react-i18next';
import changeLanguage from '../../utils/i18n/i18next';
import {
  MainWrap,
  StyledRewardShareMainBody,
  StyledRewardShareMainBodyBottom,
  StyledRewardShareTitle,
  StyledRewardShareTextCard,
  StyledRewardShareMainMiddleBody_1,
  StyledRewardShareMainMiddleBody_2,
  StyledRewardShareMainMiddleBody_ImgCard,
  StyledAdBoxWrap,
  StyledAppStoreIconBoxWrap,
  StyledSendDownloadLinkWrap,
  StyledListWrap,
} from "./styles";

type referInfoObj = {
  phone_number: string;
  role_type: string;
};

let firstRender = true;

const {
  miles_share_reward_page_logo,
  illustrations_gift,
  download_icon,
  reward_share_download_text,
  share_icon,
  reward_share_share_text,
  redeem_rewards_icon,
  reward_share_redeem_rewards_text,
  apple_store_icon,
  apple_store_src,
  google_play_store_icon,
  google_play_store_src,
  listItemContent_1,
  listItemContent_2,
  listItemContent_3,
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  star_icon_green_img,
} = config;

const COUNTRY_CODE_TWO_LETTER = "US";

const RewardSharing: React.FC<{}> = observer(() => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const [phoneErr, setPhoneErr] = useState(false);
  const [shouldDisableSendBtn, setShouldDisableSendBtn] = useState(false);

  const [appStoreLink, setGetTheAppLink] = useState("");

  const [shouldShowCopyBox, setShouldShowCopyBox] = useState(false);

  const copyBox_ref = useRef<HTMLIFrameElement>(null);
  let country = useCountry();
  let languageOnTenant = useLanguageOnTenant();
  if (firstRender) {
    store.fetchRewardSharingInfo();
    switch (languageOnTenant) {
      case 'jp_edsp':
        changeLanguage("japanese_edsp")
        break;
      case 'jp_mjkk':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }

  useEffect(() => {
    firstRender = false;
    detectSys();
  }, []);

  const { t } = useTranslation();

  const handleShouldShowCopyBox = useCallback(
    () => {
      setShouldShowCopyBox(true)
      copy(store.reward_share_referral_code);
      let showCopyTimer = setTimeout(() => {
        setShouldShowCopyBox(false);
      }, 2000);
      return () => clearTimeout(showCopyTimer);
    },
    [shouldShowCopyBox]
  );

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let formattedPhoneNum;
      let isJP = country === "jp" || process.env.REACT_APP_MILES_TENANT === "edsp";
      if(isJP) {
        let shouldUseCurrentValue = (e.target.value.length <= 3);
        let currentPhoneNum = e.target.value.replace(/\D/g, "")
        formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : new AsYouType("JP").input(currentPhoneNum);
      } else {
        let shouldUseCurrentValue = (e.target.value.length <= 4) || (e.target.value[0] === '1' && e.target.value.length === 6)
        formattedPhoneNum = shouldUseCurrentValue ? e.target.value : new AsYouType("US").input(e.target.value)
      }
      setPhoneNumber(formattedPhoneNum);
    },
    [phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 8) {
        setPhoneErr(false);
      }
    },
    [phoneNumber]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let phoneNumErr = country === "jp"
        ? !jp_phone_regex().all.test(phoneNumber.replace( /[^0-9]/g, '' ))
        : !checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
      if(phoneNumErr) {
        setPhoneErr(true);
      } else {
        const referInfoObj: referInfoObj = {
          phone_number: phoneNumber,
          role_type: 'user',
        }
        store.handleSendDownloadLink_RewardSharingPage(referInfoObj);
      }
    },
    [phoneNumber]
  );

  const detectSys = () => {
    const u = navigator.userAgent, app = navigator.appVersion;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // leave here for future to use. we dont have Android FCA App for now.
    if (isAndroid && google_play_store_src) {
       setGetTheAppLink(google_play_store_src);
    } else {
      setGetTheAppLink(apple_store_src);
    }
  };

  return (
    <React.Fragment>
      <MainWrap>
        <MilesWebHeader src={miles_share_reward_page_logo} alt="Miles Tenant Logo"/>
        <StyledRewardShareMainBody>
          <StyledRewardShareMainMiddleBody_1>
            <StyledRewardShareTitle>{t('rs_text_001')}</StyledRewardShareTitle>
            <StyledRewardShareMainMiddleBody_ImgCard>
              <ImageCard />
            </StyledRewardShareMainMiddleBody_ImgCard>
            <Divider text={t('rs_text_002')} />
            <StyledAdBoxWrap>
              <AdBox
                src={download_icon}
                alt={t('rs_reward_share_download_text')}
                textContent={t('rs_reward_share_download_text')}
                width="30px"
                height="30px"
                iconMarginLeft="5px"
                marginTop="24px"
                marginLeft="38px"
                isFirst={true}
              />
              <AdBox
                src={share_icon}
                alt={t('rs_reward_share_share_text')}
                textContent={t('rs_reward_share_share_text')}
                width="34px"
                height="34px"
                iconMarginLeft="0"
                marginTop="19px"
                marginLeft="38px"
                isFirst={false}
              />
              <AdBox
                src={redeem_rewards_icon}
                alt={t('rs_reward_share_redeem_rewards_text')}
                textContent={t('rs_reward_share_redeem_rewards_text')}
                width="34px"
                height="34px"
                iconMarginLeft="0"
                marginTop="19px"
                marginLeft="38px"
                isFirst={false}
              />
            </StyledAdBoxWrap>

            <StyledAppStoreIconBoxWrap>
              <div className="app_store_url_wrap">
                <a className={`inline_block app_store_box apple ${process.env.REACT_APP_MILES_TENANT}`} href={apple_store_src} target="_blank">
                  <div className="inline_block app_store_logo apple_store_icon_img pre_footer"></div>
                  <div className="app_store_rating_wrap">
                    <div className={`rating_title ${process.env.REACT_APP_MILES_TENANT}`}>{t('rs_reward_share_available_ios')}</div>
                    {
                      process.env.REACT_APP_MILES_TENANT === "edsp" ? null : (
                        <div className="rating_star_wrap">
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={icon_star_half_img} alt="icon_star_half_img"></img>
                          <div className="inline_block rating_number">{t('rs_reward_share_available_rating_text')}</div>
                        </div>
                      )
                    }
                  </div>
                </a>

                <a className={`inline_block app_store_box google ${process.env.REACT_APP_MILES_TENANT}`} href={google_play_store_src} target="_blank">
                  <div className="inline_block app_store_logo google_play_icon_img pre_footer"></div>
                  <div className="app_store_rating_wrap">
                    <div className={`rating_title ${process.env.REACT_APP_MILES_TENANT}`}>{t('rs_reward_share_available_android')}</div>
                    {
                      process.env.REACT_APP_MILES_TENANT === "edsp" ? null : (
                        <div className="rating_star_wrap">
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
                          <img className="inline_block star_icon_img" src={icon_star_half_img} alt="icon_star_half_img"></img>
                          <div className="inline_block rating_number">{t('rs_reward_share_available_rating_text')}</div>
                        </div>
                      )
                    }
                  </div>
                </a>
              </div>
              
            </StyledAppStoreIconBoxWrap>

            {
              store.sendDownLoadLinkStatus === "sent" ? (
                <DownloadTextBox downloadText={t('rs_text_003')} />
              ) : (
                <>
                  <StyledSendDownloadLinkWrap>
                    <DownloadNumberInput
                      placeholder={t('rs_text_004')}
                      value={phoneNumber}
                      onChange={handleChangePhoneNum}
                      onKeyDown={handleOnKeyDown}
                      type="tel"
                      send_downloadLink_phoneErr={phoneErr}
                    />
                    <SendDownloadLinkBtn
                      onClick={handleSendDownloadLink}
                      send_downloadLink_shouldDisableSendBtn={shouldDisableSendBtn}
                    />
                  </StyledSendDownloadLinkWrap>
                  <SendDownloadErrTip send_downloadLink_phoneErr={phoneErr} />
                </>
              )
            }
            <GetTheAppBox href={appStoreLink} />
          </StyledRewardShareMainMiddleBody_1>
          <StyledRewardShareMainMiddleBody_2>
            <ImageCard />
          </StyledRewardShareMainMiddleBody_2>
        </StyledRewardShareMainBody>

        <StyledRewardShareMainBodyBottom>
          <StyledRewardShareTextCard>
            <Divider text={t('rs_text_010')} />
            <StyledListWrap>
              <ListItem
                keyNum="1"
                content={t('rs_listItemContent_1')}
              />
              <ListItem
                keyNum="2"
                content={`${t('rs_listItemContent_2_01')}${store.reward_sharing_brand_name}${t('rs_listItemContent_2_02')}`}
              />
              <ListItem
                keyNum="3"
                content={t('rs_listItemContent_3')}
              />
            </StyledListWrap>
          </StyledRewardShareTextCard>

          <RewardShareTextImgCard src={illustrations_gift} />
        </StyledRewardShareMainBodyBottom>
      </MainWrap>
    </React.Fragment>
  );
});

export default RewardSharing;
