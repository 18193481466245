import styled from "styled-components";

interface FcaRafSendDownloadErrTipEleProps {
  send_downloadLink_phoneErr: boolean;
}
export const StyledFcaRafSendDownloadErrTipEle = styled("span")<FcaRafSendDownloadErrTipEleProps>`
  display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
  width: 285px;
  height: 22px;
  margin-top: 10px;
  margin-left: 6px;
  font-family: 'Avenir Next';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff4949;

  @media only screen and (min-width : 361px) and (max-width : 768px) {
    font-size: 10px;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    margin-left: 112px;
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    margin-left: 112px;
  }
`;