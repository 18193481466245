import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils from "../../../utils/milesUtils";
import CarouselComponent from "./carousel_03";
import { Styled_body_wrap } from "./styles";

const {
  about_page_assets,
} = config

let firstRender = true;


const MilesWeb_Carousel_02: React.FC<{}> = observer(() => {

  if (firstRender) {
    milesUtils.debug_log("component - MilesWeb_Carousel_02 -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_Carousel_02 -- did mount");
    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap>
      <CarouselComponent />
    </Styled_body_wrap>
  );
});

export default MilesWeb_Carousel_02;
