import styled from "styled-components";

interface Props {}
export const StyledConfirmedNoteText = styled("p")<Props>`
  height: 32px;
  margin: 29px auto 0 auto;
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2d2f3b;

  b {
    font-weight: 600;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 232px;
    height: 40px;
    margin: 30px 24px 0 14px;
    line-height: 1.43;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 232px;
    height: 40px;
    margin: 30px 24px 0 14px;
    line-height: 1.43;
  }
`;