import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const press_page_assets = {
  partner_carousel_01_img_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01_img_01/placements%402x.png`,
  press_page_top_banner_background_2500: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_2500%402x.png`,
  press_page_top_banner_background: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background%402x.png`,
  press_page_top_banner_background_768_to_1150: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_768_to_1150%402x.png`,
  press_page_top_banner_background_600_ipad_mini: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_600_ipad_mini%402x.png`,
  press_page_top_banner_background_iphone_11_pro_max: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_iphone_11_pro_max%402x.png`,
  press_page_top_banner_background_less_360: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_less_360%402x.png`,
  press_page_top_banner_background_v2: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_v2%402x.png`,
  press_page_top_banner_background_2500_v2: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_banner_background/background_2500_v2%402x.png`,
  press_page_card_unit: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_card_unit/top-san-francisco-companies-2020%402x.png`,
  press_page_card_unit_top_circle_img: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_top_circle_img/group-10%402x.png`,
  press_page_partner_logo: `${MILES_WEB_S3_BASE_URL_V2}press_page/partner_logo/bitmap%402x.png`,
  press_page_partner_image_card: `${MILES_WEB_S3_BASE_URL_V2}press_page/partner_image_card/bitmap-copy-4%402x.png`,
  press_page_partner_image_asset_1: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_1%402x.png`,
  press_page_partner_image_asset_2: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_2%402x.png`,
  press_page_partner_image_asset_3: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_3%402x.png`,
  press_page_partner_image_asset_4: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_4%402x.png`,
  press_page_partner_image_asset_ozy: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_5%402x.png`,
  press_page_partner_image_asset_fast_company: `${MILES_WEB_S3_BASE_URL_V2}press_page/fastCompany_poster/bitmap-copy-7%402x.png`,
  press_page_partner_image_asset_6: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_6%402x.png`,
  press_page_partner_image_asset_7: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_7%402x.png`,
  press_page_partner_image_asset_8: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_8%402x.png`,
  press_page_partner_image_asset_9: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_9%402x.png`,
  press_page_partner_image_asset_10: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_10%402x.png`,
  press_page_partner_image_asset_11: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_11%402x.png`,
  press_page_partner_image_asset_12: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_12%402x.png`,
  press_page_partner_image_asset_13: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_13%402x.png`,
  press_page_partner_image_asset_14: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_14%402x.png`,
  press_page_partner_image_asset_15: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_15%402x.png`,
  press_page_partner_image_asset_16: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_16%402x.png`,
  press_page_partner_image_asset_17: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_17%402x.png`,
  press_page_partner_image_asset_18: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_18%402x.png`,
  press_page_partner_image_asset_19: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_19%402x.png`,
  press_page_partner_image_asset_20: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_rectangle_images/partner_img_20%402x.png`,
  press_page_partner_logo_asset_1: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_1%402x.png`,
  press_page_partner_logo_asset_2: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_2%402x.png`,
  press_page_partner_logo_asset_3: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_3%402x.png`,
  press_page_partner_logo_asset_4: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_4%402x.png`,
  press_page_partner_logo_asset_ozy: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_5%402x.png`,
  press_page_partner_logo_asset_fast_company: `${MILES_WEB_S3_BASE_URL_V2}press_page/fastCompany_logo/group-11%402x.png`,
  press_page_partner_logo_asset_6: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_6%402x.png`,
  press_page_partner_logo_asset_7: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_7%402x.png`,
  press_page_partner_logo_asset_8: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_8%402x.png`,
  press_page_partner_logo_asset_9: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_9%402x.png`,
  press_page_partner_logo_asset_10: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_10%402x.png`,
  press_page_partner_logo_asset_11: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_11%402x.png`,
  press_page_partner_logo_asset_12: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_12%402x.png`,
  press_page_partner_logo_asset_13: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_13%402x.png`,
  press_page_partner_logo_asset_14: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_14%402x.png`,
  press_page_partner_logo_asset_15: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_15%402x.png`,
  press_page_partner_logo_asset_16: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_16%402x.png`,
  press_page_partner_logo_asset_17: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_17%402x.png`,
  press_page_partner_logo_asset_18: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_18%402x.png`,
  press_page_partner_logo_asset_19: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_19%402x.png`,
  press_page_partner_logo_asset_20: `${MILES_WEB_S3_BASE_URL_V2}press_page/press_page_partner_logo_images/partner_logo_img_20%402x.png`,

  press_page_top_highlight_card_01_logo_01: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_highlight_carousel/hilight_carousel_roundLogo_01/bitmap%402x.png`,
  press_page_top_highlight_card_01_logo_02: `${MILES_WEB_S3_BASE_URL_V2}press_page/top_highlight_carousel/hilight_carousel_logo_01/benzinga-logo-navy%402x.png`,
};
