import { observer } from 'mobx-react';
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StyledInput,
  StyledBtn,
  StyledSendDownloadErrTipEle,
  StyledSuccessInfo,
} from './styles';
import milesUtils from "../../../../utils/milesUtils";
import { store } from "../../../../Store";
import {
  checkValidPhoneNumber
} from '../../../../utils/phoneNumValidator';
import { useMediaQuery } from '@material-ui/core';
import config from "../../../../utils/config";
import { StyledNav } from "./styles";

const {
  apple_store_icon_img,
  google_play_icon_img,
  apple_store_src,
  google_play_store_src,
} = config

interface InternalProps {
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type: string;
  handleSendDownloadLink?: () => void;
  inputEl: RefObject<HTMLInputElement>;
  className?: string;
}

const GetMilesCTAInternal: React.FC<InternalProps> = observer(({
  inputEl,
  placeholder,
  value,
  onChange,
  onKeyDown,
  type,
  handleSendDownloadLink,
  className,
}) => {
  let isBtnDisabled = store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error' || store.get_miles_links_status.get_miles_link_on_nav === 'sending'
  let shouldShowErrMessage = store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error'
                              || store.get_miles_links_status.get_miles_link_on_nav === 'timeout'
                              || store.get_miles_links_status.get_miles_link_on_nav === 'server_error'
  const mediaQueryResultForBtnText = useMediaQuery('(min-width: 550px) and (max-width: 1150px)');
  const ctaText = mediaQueryResultForBtnText ? "Download" : 'Send Download Link';
  const successText = mediaQueryResultForBtnText ? "Check your phone for link" : 'Check your phone for download link';

  return (
    <>
      {store.get_miles_links_status.get_miles_link_on_nav === 'sent'
        ? <StyledSuccessInfo>{successText}</StyledSuccessInfo>
        : (
          <>
            <div className={className}>
              <StyledInput
                ref={inputEl}
                send_downloadLink_phoneErr={shouldShowErrMessage}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type={type}
              /> 

              <StyledBtn
                onClick={handleSendDownloadLink}
                style={{
                  cursor: isBtnDisabled ? 'unset' : 'pointer'
                }}
                disabled={isBtnDisabled}
                bgColor={store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error' ? '#9b9ba5' : '#10af42'}
                className="navPhoneNumberButton"
              >{store.get_miles_links_status.get_miles_link_on_nav === 'sending' ? 'Sending...' : ctaText}</StyledBtn>
            </div>
            <div className="error_state">
              <StyledSendDownloadErrTipEle send_downloadLink_phoneErr={shouldShowErrMessage}>
              {store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error' &&  '* Enter a valid US number'}
              {(store.get_miles_links_status.get_miles_link_on_nav === 'timeout' || store.get_miles_links_status.get_miles_link_on_nav === 'server_error') && 'Something went wrong. Please try again.'}
              </StyledSendDownloadErrTipEle>
            </div>
          </>
        )
      }
    </>
  );
});


const GetMilesCTA: React.FC<{}> = observer(({
}) => {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [position, setPosition] = useState(0);
  const inputEl = useRef<HTMLInputElement>(null);
  const [deviceType, setDeviceType] = useState("");
  const mediaQueryResultForDeviceDetection1 = useMediaQuery('(min-width: 551px) and (max-width: 768px)');
  const mediaQueryResultForDeviceDetection2 = useMediaQuery('(max-width: 550px)');

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  let posit = 0;
  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      if (currentPhoneNum.length > 10) {
        currentPhoneNum = currentPhoneNum.slice(0, 10); 
      }
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);

      const selectionStart = inputEl!.current!.selectionStart as number;

      let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_miles_link_on_nav', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link_on_nav', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let finalPhoneNum = phoneNumber
      if(!checkValidPhoneNumber(finalPhoneNum.replace( /[^0-9]/g, '' ))) {
        store.update_miles_links_status('get_miles_link_on_nav', 'invalidNum_error')
      } else {
        store.handleSendDownloadLink_HomePage(finalPhoneNum, "get_miles_link_on_nav");
      }
    },
    [phoneNumber]
  );

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  let shouldHide_GetMilesCTAInternal = ((mediaQueryResultForDeviceDetection1) && (deviceType === "Mobile")) || mediaQueryResultForDeviceDetection2 

  return <StyledNav>
    {
      shouldHide_GetMilesCTAInternal ? (
        <div className="app_store_url_wrap">
          <a className="inline_block app_store_box apple" href={apple_store_src} target="_blank">
            <div className="inline_block app_store_logo apple_store_icon_img"></div>
          </a>

          <a className="inline_block app_store_box google" href={google_play_store_src} target="_blank">
            <div className="inline_block app_store_logo google_play_icon_img"></div>
          </a>
        </div>
      ) : (
        <GetMilesCTAInternal 
          inputEl={inputEl}
          placeholder="(123) - 456 - 7890"
          value={phoneNumber}
          onChange={handleChangePhoneNum}
          onKeyDown={handleOnKeyDown}
          type="tel"
          handleSendDownloadLink={handleSendDownloadLink}
          className="shouldHide_GetMilesCTAInternal"
        />
      )
    }
    
  </StyledNav>
});


export default GetMilesCTA;
