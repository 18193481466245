import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
} = config;

interface StyledListItemProps {}
export const StyledListItem = styled("div")<StyledListItemProps>`
    .keyNum {
        font-family: ${() => fontFamily_Gilroy_Medium};
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.83;
        letter-spacing: 0.6px;
        text-align: left;
        color: #9b9ba5;
    }

    .content {
        font-family: ${() => fontFamily_Gilroy_Medium};
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.83;
        letter-spacing: 0.6px;
        text-align: left;
        color: #9b9ba5;
        padding-left: 5px;
    }

    /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin-top: 3px;
  }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        margin-top: 3px;
    }
`;