import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;

  .ciq-root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
  }
  
  .ciq-container{
    width: auto;
    height: auto;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: 0px;
    /* margin-top: 101px; */
    /* margin-bottom: 202px; */
  }

  .cta{
    width: 319px;
    height: 64px;
    border-radius: 32px;
    background-color: #10af42;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq-container {
      /* margin-top: 61.5px; */
      /* margin-bottom: 135px; */
    }

    .cta{
      width: 217px;
      height: 52px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.7px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq-container {
      /* margin-top: 61px; */
      /* margin-bottom: 135px; */
    }

    .cta{
      width: 217px;
      height: 52px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.7px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .ciq-container {
      /* margin-top: 100px; */
      /* margin-bottom: 138px; */
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .ciq-container {
      /* margin-top: 100px; */
      /* margin-bottom: 195px; */
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .ciq-container {
      /* margin-top: 100px; */
      /* margin-bottom: 202px; */
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .ciq-container {
      /* margin-top: 101px; */
      /* margin-bottom: 202px; */
    }
  }
`;