import styled from "styled-components";

interface Props {}
export const StyledCard = styled("a")<Props>`
  width: 350px;
  height: 340px;
  margin: 0 auto;
  border-radius: 32px;
  box-shadow: 0 0 10px 0 #e6e6e8;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  .card_top {
    width: 291px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: inline-block;
    }
  
    .company_logo {
      width: 101px;
      height: 61px;

      &.version_2022_v1 {
        width: 218px;
        height: 31px;
      }
    }
  }

  .divider_line {
    width: 291px;
    height: 1px;
    background-color: #dcdcdc;
  }

  .testimonial {
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-top: 45px;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 240px;
    height: 279px;

    .card_top {
      width: 194px;
      height: 124px;
  
      img {}
    
      .company_logo {
        width: 126px;
        height: 33px;

        &.version_2022_v1 {
          width: 158px;
          height: auto;
        }

      }
    }
  
    .divider_line {
      width: 194px;
      height: 1px;
      background-color: #dcdcdc;
    }
  
    .testimonial {
      width: 221px !important;
      font-family: Gilroy-Medium;
      margin-top: 33px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.53px;
      text-align: center;
      color: #2d2f3b;
    }
  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 240px;
    height: 279px;

    .card_top {
      width: 194px;
      height: 124px;
  
      img {}
    
      .company_logo {
        width: 126px;
        height: 33px;
      }
    }
  
    .divider_line {
      width: 194px;
      height: 1px;
      background-color: #dcdcdc;
    }
  
    .testimonial {
      width: 221px !important;
      font-family: Gilroy-Medium;
      margin-top: 33px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.53px;
      text-align: center;
      color: #2d2f3b;

      &.version_2022_v1 {
        letter-spacing: normal;
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
   
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 256px;
    height: 249px;

    .card_top {
      width: 222px;
      height: 118px;
  
      img {}
    
      .company_logo {
        width: 126px;
        height: 33px;
      }
    }
  
    .divider_line {
      width: 222px;
      height: 1px;
      background-color: #dcdcdc;
    }
  
    .testimonial {
      width: 221px !important;
      font-family: Gilroy-Medium;
      margin-top: 23px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: 0.7px;
      text-align: center;
      color: #2d2f3b;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    width: 320px;
    height: 340px;

    .card_top {
      width: 244px;
      height: 135px;
  
      img {}
    
      .company_logo {
        width: 101px;
        height: 61px;
      }
    }
  
    .divider_line {
      width: 244px;
      height: 1px;
      background-color: #dcdcdc;
    }
  
    .testimonial {
      font-family: Gilroy-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-top: 45px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;