import React, { ReactNode } from "react";
import { animated } from "react-spring";
import AvatarBox from '../avatar_box/index';
import { RootStyled, MainWrapStyled } from "./styles";
import { useAvatarAnimation } from './hooks';
import { TeamLinksTabBar } from './team_links_tab_bar';
import { PreviousAndNextButtons } from './previous_and_next_buttons';
import { PagedNavigationButtons } from './paged_navigation_buttons';

export interface AvatarCarouselProps {
    // defaults to 2
    numColumnsPerSlide?: number;
    // defaults to 1000ms
    autoAdvanceDelayMs?: number;
}

const AvatarCarousel = (props: AvatarCarouselProps) => {
    const numColumnsPerSlide = props?.numColumnsPerSlide || 2;
    const autoAdvanceDelayMs = props?.autoAdvanceDelayMs || 1500;

    const {
        animationStyle,
        selectedTeam,
        selectedIndex,
        avatarControls,
        avatarRefFn,
    } = useAvatarAnimation({
        numColumnsPerSlide,
        autoAdvanceDelayMs,
    });

    return (
        <RootStyled>
            <div className="section_title">
                Meet the team
            </div>
            <TeamLinksTabBar
                selectedTeam={selectedTeam}
                avatarControls={avatarControls}
            />
            <div className="previousNextControls">
                <PreviousAndNextButtons
                    avatarControls={avatarControls}
                />
            </div>
            <div className="dotControls">
                <PagedNavigationButtons
                    selectedIndex={selectedIndex}
                    avatarControls={avatarControls}
                />
            </div>
            <MainWrapStyled>
                <animated.div style={animationStyle}>
                    <div style={{display: 'flex'}}>
                        <AvatarBox />
                        <AvatarBox ref={avatarRefFn} />
                        <AvatarBox />
                    </div>
                </animated.div>
            </MainWrapStyled>
            <div className="miles_ui_screen_on_1000"></div>
        </RootStyled>
    );
};


export default AvatarCarousel;