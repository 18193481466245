import styled from "styled-components";

interface FooterProps {}

interface BtnProps {
    bgColor: string;
    sendDownLoadLinkStatus: string;
  }
  
  export const StyledBtn = styled("button")<BtnProps>`
    .screen_larger_than_768 {
      display: block;
      vertical-align: middle;
      line-height: 52px;
    }
    .screen_between_361_and_768 {
      display: none;
    }
    width: 217px;
    height: 52px;
    margin: 0;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    border-color: transparent;
    font-family: Gilroy-SemiBold;
  
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    vertical-align: middle;
    position: absolute;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    margin-left: 247px;
  
    &:hover,
    &:active  {
      background-color: #178e3d;
      box-shadow: 0 2px 4px 0 #b1b1b180;
  
      :disabled {
        background-color: ${(props) => props.sendDownLoadLinkStatus === "sending" ? "#10af42" : "#9b9ba5"};
        border: ${(props) => props.sendDownLoadLinkStatus === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
        box-shadow: ${(props) => props.sendDownLoadLinkStatus === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
      }
    }
  
    background-color: ${(props) => props.bgColor};
  
    :hover {
      background-color: ${(props) => props.disabled ? "#9b9ba5" : "#178e3d"};
    }
  
    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      .screen_between_361_and_768 {
        display: block;
        vertical-align: middle;
        line-height: 40px;
      }
  
      .screen_larger_than_768 {
        display: none;
      }
      width: 84px;
      height: 40px;
      margin: 0;
      padding: 0;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 #b1b1b180;
      background-color: ${(props) => props.bgColor};
      border-color: transparent;
      border: ${(props) => `solid 1px ${props.bgColor}`};
      font-family: Gilroy-SemiBold;
  
      vertical-align: middle;
      position: absolute;
      cursor: pointer;
      outline: none;
      box-sizing: border-box;
      margin-left: 147px;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
  
      &:hover,
      &:active  {
        background-color: #178e3d;
        border: solid 1px #178e3d;
        box-shadow: 0 2px 4px 0 #b1b1b180;
  
        :disabled {
          background-color: ${(props) => props.sendDownLoadLinkStatus === "sending" ? "#10af42" : "#9b9ba5"};
          border: ${(props) => props.sendDownLoadLinkStatus === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
          box-shadow: ${(props) => props.sendDownLoadLinkStatus === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
        }
      }
    }
  `;