import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import {
  checkValidPhoneNumber
} from '../../../utils/phoneNumValidator';
import { StyledPreFooter } from "./styles";
import { Get_miles_CTA } from "../../../components/v2/miles_ui_box_get_miles";
import { store } from "../../../Store";
import milesUtils from "../../../utils/milesUtils";

interface Props {
  apple_store_icon_img: string;
  google_play_icon_img: string;
  star_icon_img: string;
  icon_star_half_img: string;
  apple_store_src: string;
  google_play_store_src: string;
  className?: string;
}

type referInfoObj = {
  phone_number: string;
  role_type: string;
};

const COUNTRY_CODE_TWO_LETTER = "US";

export const Pre_footer_hero: React.FC<Props> = observer(({
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
  className,
}) => {

  useEffect(() => {
  }, []);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [position, setPosition] = useState(0);
  const inputEl = useRef<HTMLInputElement>(null);

  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      if (currentPhoneNum.length > 10) {
        currentPhoneNum = currentPhoneNum.slice(0, 10); 
      }
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);

      const selectionStart = inputEl!.current!.selectionStart as number;

      let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_miles_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let finalPhoneNum = phoneNumber
      if(!checkValidPhoneNumber(finalPhoneNum.replace( /[^0-9]/g, '' ))) {
        store.update_miles_links_status('get_miles_link', 'invalidNum_error')
      } else {
        store.handleSendDownloadLink_HomePage(finalPhoneNum, "get_miles_link");
      }
    },
    [phoneNumber]
  );

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  return (
    <StyledPreFooter className={className}>
      <div className="title miles_ui_screen_larger_than_768">Join Miles, Get Rewarded Today!</div>
      <div className="title miles_ui_screen_less_than_768">Join Miles, Get Rewarded Today</div>

      <div className="cta_wrap">
        <Get_miles_CTA
          inputEl={inputEl}
          placeholder="(123) - 456 - 7890"
          value={phoneNumber}
          onChange={handleChangePhoneNum}
          onKeyDown={handleOnKeyDown}
          type="tel"
          handleSendDownloadLink={handleSendDownloadLink}
        />
      </div>

      <div className="app_store_url_wrap">
        <a className="inline_block app_store_box apple" href={apple_store_src} target="_blank">
          <div className="inline_block app_store_logo apple_store_icon_img pre_footer"></div>
          <div className="app_store_rating_wrap">
            <div className="rating_title">Available on App Store</div>
            <div className="rating_star_wrap">
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={icon_star_half_img} alt="icon_star_half_img"></img>
              <div className="inline_block rating_number">60K+ Ratings</div>
            </div>
          </div>
        </a>

        <a className="inline_block app_store_box google" href={google_play_store_src} target="_blank">
          <div className="inline_block app_store_logo google_play_icon_img pre_footer"></div>
          <div className="app_store_rating_wrap">
            <div className="rating_title">Available on Google Play</div>
            <div className="rating_star_wrap">
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={star_icon_img} alt="star_icon_img"></img>
              <img className="inline_block star_icon_img" src={icon_star_half_img} alt="icon_star_half_img"></img>
              <div className="inline_block rating_number">60K+ Ratings</div>
            </div>
          </div>
        </a>
      </div>
    </StyledPreFooter>
  );
});
