import styled from "styled-components";

interface Brands_you_love_Props {}
export const Styled_Brands_you_love = styled("div")<Brands_you_love_Props>`
  margin: 0;
  padding: 0;
  min-height: 955px;
  height: 955px;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;

  .para_01 {
    height: 56px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 125px;
  }

  .para_02 {
    height: 79px;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    text-align: center;
    color: #82828c;
    margin-top: 15px;
  }

  .inner_box_01 {
    display: inline-block;
    width: 745px;
    height: 492px;
    margin-top: 64px;
  }

  .large_than_360_screen_only {
    display: block;
  }

  .less_than_360_screen_only {
    display: none;
  }

  .large_than_768_screen {
    display: block;
  }

  .between_360_to_768_screen {
    display: none;
  }

  .less_than_360_screen {
    display: none;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-height: 633px;
    height: 633px;
    min-width: 360px;

    .large_than_768_screen {
      display: none;
    }
  
    .between_360_to_768_screen {
      display: none;
    }
  
    .less_than_360_screen {
      display: block;
    }

    .para_01 {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 59px;
    }
  
    .para_02 {
      height: 62px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #5f5f69;
      margin-top: 5px;
    }

    .inner_box_01 {
      width: 319px;
      height: auto;
      margin-top: 44px;
    }

    .large_than_360_screen_only {
      display: none;
    }
  
    .less_than_360_screen_only {
      display: block;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    min-height: 638px;
    height: 638px;

    .large_than_768_screen {
      display: none;
    }
  
    .between_360_to_768_screen {
      display: block;
    }
  
    .less_than_360_screen {
      display: none;
    }

    .para_01 {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 60px;
    }
  
    .para_02 {
      height: 62px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #5f5f69;
      margin-top: 5px;
    }

    .inner_box_01 {
      width: 319px;
      height: auto;
      margin-top: 44px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 1050px;
    height: 1050px;

    .para_01 {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: 1px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 125px;
    }
  
    .para_02 {
      height: 70px;
      font-family: Gilroy-Medium;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: 0.96px;
      text-align: center;
      color: #82828c;
      margin-top: 15px;
    }

    .inner_box_01 {
      width: 697px;
      height: auto;
      margin-top: 99px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    min-height: 959px;
    height: 959px;

    .inner_box_01 {
      margin-top: 71px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .inner_box_01 {
      margin-top: 64px;
    }
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1441px) {
    .inner_box_01 {
      margin-top: 71px;
    }
  }
`;