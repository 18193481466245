import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import {
  Styled_body_wrap,
  StyledCard,
} from "./styles";

const {
  partner_page_assets: assets,
} = config;

const CARDS_INFO = [
  // card 01
  {
    card_wrap_className: 'card_wrap_01 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_01_img,
    imgClassName: 'growing_fast_img_01',
    title: `3.5 Bn +`,
    titleClassName: 'growing_fast_title_01 card_title',
    subTitle: `Miles Redeemed`,
    subTitleClassName: 'growing_fast_subTitle_01 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_01`
  },
  // card 02
  {
    card_wrap_className: 'card_wrap_02 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_02_img,
    imgClassName: 'growing_fast_img_02',
    title: `7.5 Mn +`,
    titleClassName: 'growing_fast_title_02 card_title',
    subTitle: `Rewards Redeemed`,
    subTitleClassName: 'growing_fast_subTitle_02 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_02`
  },
  // card 03
  {
    card_wrap_className: 'card_wrap_03 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_03_img,
    imgClassName: 'growing_fast_img_03',
    title: `$50 Mn +`,
    titleClassName: 'growing_fast_title_03 card_title',
    subTitle: `In Savings`,
    subTitleClassName: 'growing_fast_subTitle_03 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_03`
  },
  // card 04
  {
    card_wrap_className: 'card_wrap_04 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_04_img,
    imgClassName: 'growing_fast_img_04',
    title: `$200 Mn +`,
    titleClassName: 'growing_fast_title_04 card_title',
    subTitle: `Revenue for Partners`,
    subTitleClassName: 'growing_fast_subTitle_04 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_04`
  },
]


const CARDS_INFO_FOR_LESS_THAN_768 = [
  // card 01
  {
    card_wrap_className: 'card_wrap_01 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_01_img,
    imgClassName: 'growing_fast_img_01',
    title: `3.5 Bn +`,
    titleClassName: 'growing_fast_title_01 card_title',
    subTitle: `Miles Redeemed`,
    subTitleClassName: 'growing_fast_subTitle_01 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_01`
  },
  // card 02
  {
    card_wrap_className: 'card_wrap_03 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_03_img,
    imgClassName: 'growing_fast_img_03',
    title: `$50 Mn +`,
    titleClassName: 'growing_fast_title_03 card_title',
    subTitle: `In Savings`,
    subTitleClassName: 'growing_fast_subTitle_03 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_03`
  },
  // card 03
  {
    card_wrap_className: 'card_wrap_02 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_02_img,
    imgClassName: 'growing_fast_img_02',
    title: `7.5 Mn +`,
    titleClassName: 'growing_fast_title_02 card_title',
    subTitle: `Rewards Redeemed`,
    subTitleClassName: 'growing_fast_subTitle_02 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_02`
  },
  // card 04
  {
    card_wrap_className: 'card_wrap_04 card_box_wrap',
    imgSrc: assets.we_are_growing_fast_04_img,
    imgClassName: 'growing_fast_img_04',
    title: `$200 Mn +`,
    titleClassName: 'growing_fast_title_04 card_title',
    subTitle: `Revenue for Partners`,
    subTitleClassName: 'growing_fast_subTitle_04 card_subTitle',
    card_inner_img_wrap_ClassName: `card_inner_img_wrap card_inner_img_wrap_04`
  },
]

interface cardProps {
  card_wrap_className: string;
  card_inner_img_wrap_ClassName: string;
  imgSrc: string;
  imgClassName: string;
  title: string | JSX.Element;
  titleClassName: string;
  subTitle: string | JSX.Element;
  subTitleClassName: string;
}

export const Card: React.FC<cardProps> = ({
  card_wrap_className,
  title,
  titleClassName,
  subTitle,
  subTitleClassName,
  card_inner_img_wrap_ClassName,
  imgSrc,
  imgClassName,
}) => {
  return (
    <StyledCard className={card_wrap_className} key={card_wrap_className}>
      <div className={titleClassName}>{title}</div>
      <div className={subTitleClassName}>{subTitle}</div>
      
      <div className={card_inner_img_wrap_ClassName}><img className={imgClassName} src={imgSrc} alt={imgClassName} /></div>
    </StyledCard>
  );
};

const Component: React.FC<{}> = observer(() => {
  let cardsEle_for_lager_than_768: ReactNode[] = []
  let cardsEle_for_less_than_768: ReactNode[] = []

  CARDS_INFO.map((card, index) => {
    cardsEle_for_lager_than_768.push(
      <Card
        card_wrap_className={card.card_wrap_className}
        imgSrc={card.imgSrc}
        imgClassName={card.imgClassName}
        title={card.title}
        titleClassName={card.titleClassName}
        subTitle={card.subTitle}
        subTitleClassName={card.subTitleClassName}
        card_inner_img_wrap_ClassName={card.card_inner_img_wrap_ClassName}
        key={card.imgClassName}
      />
    )
  })
  return (
    <>
      <Styled_body_wrap className="partner_section_we_are_growing_fast">
        <div className="main_container">
          <div className="title">We Are Growing Fast</div>
          <div className="inner_box_wrap screen_larger_than_768">
          {cardsEle_for_lager_than_768}
          </div>

          <div className="inner_box_wrap screen_less_than_768">
          {
            CARDS_INFO_FOR_LESS_THAN_768.map((card, index) => (
              <Card
                card_wrap_className={card.card_wrap_className}
                imgSrc={card.imgSrc}
                imgClassName={card.imgClassName}
                title={card.title}
                titleClassName={card.titleClassName}
                subTitle={card.subTitle}
                subTitleClassName={card.subTitleClassName}
                card_inner_img_wrap_ClassName={card.card_inner_img_wrap_ClassName}
                key={card.imgClassName}
              />
            ))
          }
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
