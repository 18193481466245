import React from "react";
import { Helmet } from "react-helmet";
import {
  useLocation
} from "react-router-dom";
export { default as usePageTracking } from "./usePageTracking";
export { default as usePageTracking_ga4 } from "./usePageTracking_ga4";

export function useCountry() {
  let location = useLocation();
  switch (location.pathname.split('/')[1].toLowerCase()) {
    case 'jp':
      return 'jp';
    default:
      return 'us';
  }
}

export function useLanguageOnTenant() {
  let location = useLocation();
  switch (location.pathname.split('/')[1].toLowerCase()) {
    case 'jp':
      return 'jp_mjkk';
    default:
      if (process.env.REACT_APP_MILES_TENANT === "edsp") {
        return 'jp_edsp';
      } else {
        return 'us_miles';
      }
  }
}

export function useHrefLang() {
  let location = useLocation();
  // Only Miles <-> MJKK is supported. 
  if (
    process.env.REACT_APP_MILES_TENANT !== 'miles' &&
    process.env.REACT_APP_MILES_TENANT !== 'mjkk' &&
    process.env.REACT_APP_MILES_TENANT !== 'mjp'
  ) {
    return null;
  }
  const {origin} = window.location;
  const [blank, first, ...rest] = location.pathname.split('/');
  const is_jp_subpage = first?.toLowerCase() == 'jp';
  const lang = is_jp_subpage ? 'jp' : 'en';
  const alt_lang = lang === 'en' ? 'jp' : 'en';
  const alt_path = (
    is_jp_subpage
    ? [blank, ...rest].join('/')
    : [blank, 'jp', first, ...rest].join('/')
  );
  const alt_href = `${origin}${alt_path}`;
  return <Helmet htmlAttributes={{lang}}>
    <link rel="alternate" href={alt_href} hrefLang={alt_lang} />
  </Helmet>;
}


export const REG_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

const milesUtils = {
  debug_log(...args: any) {
    if (process.env.NODE_ENV === "development") {
      console.log.apply(console.log, args);
    } else {}
  },
  formatPhoneText(value: string) {
    
    if(value.length > 3 && value.length <= 6) 
      value = "(" + value.slice(0,3) + ")" + "-" + value.slice(3);
    else if(value.length > 6) 
      value = "(" + value.slice(0,3) + ")" + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  },
  getNum(str: string) {
    let index = (str.length === 4) ? 7 : str.indexOf("-") + 1;
    return index;
  },
  getNum_JP(str: string) {
    let index = str.indexOf("-") > -1 ? str.indexOf("-") + 1 : str.length -1; 
    return index;
  },
  getCurrentPosition(formattedPhoneNum: string, phoneNumber: string, posit: number, selectionStart: number) {
    if (formattedPhoneNum.length > phoneNumber.length) {
      milesUtils.debug_log("onKeyDownP >>> ", posit);
      let len = formattedPhoneNum.length - phoneNumber.length;
      let addStr;
      if (len === 4) {
        addStr = formattedPhoneNum.substring(formattedPhoneNum.length-1, 1)
      } else {
        addStr = formattedPhoneNum.substring(posit, posit + len)
      }
      milesUtils.debug_log("addStr:", addStr);
      const step = milesUtils.getNum(addStr);
      return (selectionStart + step)
    } else if (formattedPhoneNum.length < phoneNumber.length) {
      milesUtils.debug_log("delete");
      if (formattedPhoneNum.charAt(selectionStart - 1) === ")") {
      return (selectionStart - 2);
      } else if (formattedPhoneNum.charAt(selectionStart - 1) === "(" || formattedPhoneNum.charAt(selectionStart - 1) === "-") {
      return (selectionStart - 1);
    } else {
      return selectionStart;
      }
    } else if (formattedPhoneNum.length === phoneNumber.length) {
      return selectionStart;
    }
  },
  getCurrentPosition_JP(formattedPhoneNum: string, phoneNumber: string, posit: number, selectionStart: number) {
    if (formattedPhoneNum.length > phoneNumber.length) {
      milesUtils.debug_log("onKeyDownP >>> ", posit);
      let len = formattedPhoneNum.length - phoneNumber.length;
      let addStr = formattedPhoneNum.substring(posit, posit + len);
      milesUtils.debug_log("addStr:", addStr);
      const step = milesUtils.getNum_JP(addStr);
      return (selectionStart + step)
    } else if (formattedPhoneNum.length < phoneNumber.length) {
      milesUtils.debug_log("delete");
      if (formattedPhoneNum.charAt(selectionStart - 1) === ")") {
      return (selectionStart - 2);
      } else if (formattedPhoneNum.charAt(selectionStart - 1) === "(" || formattedPhoneNum.charAt(selectionStart - 1) === "-") {
      return (selectionStart - 1);
    } else {
      return selectionStart;
      }
    } else if (formattedPhoneNum.length === phoneNumber.length) {
      return selectionStart;
    }
  },
  checkPasswordValue(password: string) {
    let length=/^.{8,}$/;
    let specificSymbol = /[^A-Za-z0-9]/g;

    let isCorrectPassword = specificSymbol.test(password) && length.test(password);
    return isCorrectPassword
  },
  isValidEmailAddress(address: string) {
    return REG_EMAIL.test(address);
  },
  get_checkBox_checked_color() {
    switch(process.env.REACT_APP_MILES_TENANT) {
      case 'miles':
        return "#009900";
      case 'fca':
        return "#0091C7";
      case 'edsp':
        return "#fd902b";
      case 'mjkk':
      case 'mjp':
        return "#009900";
      default:
          return null;
    }
  }
};


export default milesUtils;
