import React from "react";
import { StyledCopyTip } from "./styles";

interface Props {
  invite_code_tiip: string;
}

export const FcaRafCopyTip: React.FC<Props> = ({
  invite_code_tiip
}) => {
  return (
    <StyledCopyTip>{invite_code_tiip}</StyledCopyTip>
  );
};
