import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
    margin: 0;
    width: 100vw;
    height: 1192px;
    background: #ffffff;

    .main_container {
        margin: 0 auto;
        width: 1440px;
    }

    .oem_help_your_brand_title {
        margin: 0 auto;
        width: max-content;
        height: auto;
        font-family: Gilroy-Bold;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 147px;
    }

    .oem_help_your_brand_cards_wrap {
        margin: 0 auto;
        width: 950px;
        margin-top: 67px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        height: 1904px;

        .main_container {
            width: 100vw;
        }

        .oem_help_your_brand_title {
            width: max-content;
            height: auto;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 85px;
        }

        .oem_help_your_brand_cards_wrap {
            margin: 0 auto;
            width: 300px;
            margin-top: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        height: 1904px;

        .main_container {
            width: 100vw;
        }

        .oem_help_your_brand_title {
            width: max-content;
            height: auto;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 84px;
        }

        .oem_help_your_brand_cards_wrap {
            margin: 0 auto;
            width: 300px;
            margin-top: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        height: 2028px;

        .main_container {
            width: 100vw;
        }

        .oem_help_your_brand_title {
            font-size: 32px;
            font-family: Gilroy-Bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.41;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 113px;
        }

        .oem_help_your_brand_cards_wrap {
            margin: 0 auto;
            width: 460px;
            margin-top: 68px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        height: 1175px;

        .main_container {
            width: 100vw;
        }

        .oem_help_your_brand_title {
            font-size: 36px;
            font-family: Gilroy-Bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.14;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 151px;
        }
    
        .oem_help_your_brand_cards_wrap {
            width: 950px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        margin: 0;
        width: 100vw;
        background: #fff;
    
        .main_container {
            margin: 0 auto;
            width: 1440px;
        }
    }
`;

interface CardsProps {}
export const StyledCard = styled("div")<CardsProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 0;
    width: 460px;
    height: 350px;
    padding: 31px 30px 35px;
    border-radius: 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;

    &.card_wrap_03,
    &.card_wrap_04 {
        margin-top: 40px;
    }

    img {
        width: 120px;
        height: 120px;
        display: inline-block;
    }

    .card_title {
        width: 400px;
        height: auto;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin: 30px auto 0 auto;
    }

    .card_sub_title {
        margin: 22px auto 0 auto;
        width: 400px;
        height: 80px;
        font-family: Gilroy-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 350px;
        margin: 0 auto;
        padding: 34px 20px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        &.card_wrap_02,
        &.card_wrap_03,
        &.card_wrap_04 {
            margin-top: 68px;
        }

        .card_title {
            width: 260px;
            height: 32px;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin: 25px auto 0 auto;
        }

        .card_sub_title {
            margin: 25px auto 0 auto;
            width: 260px;
            height: 80px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
        }

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 350px;
        margin: 0 auto;
        padding: 34px 20px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        &.card_wrap_02,
        &.card_wrap_03,
        &.card_wrap_04 {
            margin-top: 68px;
        }

        .card_title {
            width: 260px;
            height: 32px;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin: 25px auto 0 auto;
        }

        .card_sub_title {
            margin: 25px auto 0 auto;
            width: 260px;
            height: 80px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        &.card_wrap_02,
        &.card_wrap_03,
        &.card_wrap_04 {
            margin-top: 68px;
        }

    }

    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {

    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        height: 350px;
    }
`;