import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils, { useCountry } from "../../utils/milesUtils";
import changeLanguage from '../../utils/i18n/i18next';
import { MILES_ANIMATIONS } from "../../utils/milesConstant";
import {Pre_footer_hero} from "../../components/v2/miles_ui_box_pre_footer_hero/index";
import { Styled_body_wrap } from "./styles";

const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
  not_found_page_assets: assets,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

const MilesWeb_NotFoundPage: React.FC<{}> = observer(() => {
  let country = useCountry();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_NotFoundPage -- firstRender");
    switch (country) {
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_NotFoundPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap isJP={country === "jp"}>
      <div className="main_body">
        <div className="miles_ds_height_6"></div>
        <div className="text_01">Page not found</div>
        <div className="miles_ds_height_4"></div>
        <picture className="miles_ui_not_found_bugg">
          <source className="miles_ui_not_found_bugg_img" media="(max-width : 360px)" srcSet={assets.not_found_bugg_01}></source>
          <source className="miles_ui_not_found_bugg_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.not_found_bugg_01}></source>
          <source className="miles_ui_not_found_bugg_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.not_found_bugg_01}></source>
          <source className="miles_ui_not_found_bugg_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.not_found_bugg_01}></source>
          <source  className="miles_ui_not_found_bugg_img" media="(min-width : 1441px)" srcSet={assets.not_found_bugg_01}></source>
          <img className="miles_ui_not_found_bugg_img" alt={`founders_picture`}></img>
        </picture>
        <div className="miles_ds_height_4"></div>
        <div className="description_text">The page you were looking for could not be found. Explore the rest of the site instead!</div>
        <div className="miles_ds_height_6"></div>
      </div>
      
      {country === "jp" ? null : <Pre_footer_hero
        apple_store_icon_img={apple_store_icon_img}
        google_play_icon_img={google_play_icon_img}
        star_icon_img={star_icon_img}
        icon_star_half_img={icon_star_half_img}
        apple_store_src={apple_store_src}
        google_play_store_src={google_play_store_src}
        className="miles_ui_pre_footer_commonSize miles_ui_about_page"
      />}

    </Styled_body_wrap>
  );
});

export default MilesWeb_NotFoundPage;
