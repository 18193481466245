import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import { useTranslation } from 'react-i18next';
import { useCountry } from "../../../utils/milesUtils";

const {
  cities_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  let country = useCountry();
  const { t } = useTranslation();
  return (
    <Styled_body_wrap country={country}>
      <div className="main_container">
        <div className="title">How Miles’ Solutions Can Help</div>

        <div className="asset_wrap">
          <div className="divider_space_before_box_01"></div>
          <div className="inner_box inner_box_01">
            <picture className="">
              <source className="" media="(max-width : 768px), " srcSet={assets.solutions_can_help_01_600}></source>
              <source className="" media="(min-width : 769px)" srcSet={assets.solutions_can_help_01_1440}></source>
              <img className="inner_box_asset_img inner_box_01_asset" alt={`inner_box_01_asset`}></img>
            </picture>
            <div className="inner_box_content_box inner_box_01_content">
              <div className="inner_box_title">Mode Shift Incentives</div>
              <div className="inner_box_description">Motivate the switch to more sustainable transportation while building loyalty for your transit agency.</div>
              <ul className="inner_box_list">
                <li>Award riders gift cards in challenges</li>
                <li>Offer unique rewards and raffles</li>
              </ul>
            </div>
          </div>

          <div className="divider_space_before_box_02"></div>
          <div className="inner_box inner_box_02">
            <div className="inner_box_content_box inner_box_02_content">
              <div className="inner_box_title">Multi-modal Data & Insights</div>
              <div className="inner_box_description">Unlock the power of data and use our platform to solve your biggest problems.</div>
              <ul className="inner_box_list">
                <li>Origin & Destination data</li>
                <li>First & Last Mile analysis</li>
                <li>Custom feed into City’s data lake</li>
              </ul>
            </div>
            <picture className="">
              <source className="" media="(max-width : 768px), " srcSet={assets.solutions_can_help_02_600}></source>
              <source className="" media="(min-width : 769px)" srcSet={assets.solutions_can_help_02_1440}></source>
              <img className="inner_box_asset_img inner_box_02_asset" alt={`inner_box_02_asset`}></img>
            </picture>
          </div>

          <div className="divider_space_before_box_03"></div>
          <div className="inner_box inner_box_03">
            <picture className="">
              <source className="" media="(max-width : 768px), " srcSet={assets.solutions_can_help_03_600}></source>
              <source className="" media="(min-width : 769px)" srcSet={assets.solutions_can_help_03_1440}></source>
              <img className="inner_box_asset_img inner_box_03_asset" alt={`inner_box_03_asset`}></img>
            </picture>
            <div className="inner_box_content_box inner_box_03_content">
              <div className="inner_box_content_box inner_box_03_content">
                <div className="inner_box_title">White Label/SDK Solutions</div>
                <div className="inner_box_description">Embed a customized white-label/SDK solution within your own mobility app.</div>
                <ul className="inner_box_list">
                  <li>Consistently branded user experience</li>
                  <li>All-in-one solution</li>
                  <li>Instant access to trip data</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="divider_space_after_box_03"></div>
        </div>

      </div>
    </Styled_body_wrap>
  );
});

export default Component;
