import React from "react";
import { observer } from "mobx-react";
import {
  StyledFCASignInSignUp
} from "./styles";

declare const window: any;

const stellantis_welcome_img = `https://getmiles.s3.amazonaws.com/assets/web_v2/fca_web/fca_sso_signIn_signUp/Group+67%403x.png`;

const FCASignInSignUp: React.FC<{}> = observer(() => {
  let userName = window.SERVER_DATA?.first_name || '';
  
  let getCurrentUserName = () => {
    if (userName.length > 18) {
      return userName.substring(0, 13) + `...`
    }
    return userName
  }

  return (
    <StyledFCASignInSignUp>
      <img className="signin_signup_asset" src={stellantis_welcome_img} alt={`stellantis_welcome_img`} />
      <a className="cta_continue" href={window.SERVER_DATA?.continue_url}>Continue as {getCurrentUserName()}</a>
    </StyledFCASignInSignUp>
  );
});

export default FCASignInSignUp;
