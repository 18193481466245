import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin-bottom: 20px;

  .ciq_root{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 258px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid red;"
  }
  }

  .ciq_container{
    width: 1440px;
    height: 862px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .form_container{
    width: 715px;
    height: 780px;
    padding: 45px 37px 61px;
    border-radius: 15px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
  }

  .contact_query_success{
    width: 715px;
    height: 780px;
    padding: 45px 37px 61px;
    border-radius: 15px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .label_text {
    width: max-content;
    height: auto;
    margin: 0;
    font-family: Gilroy-SemiBold;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #2d2f3b;
    text-align: left;
    display: block;
  }

  .combo_outline{
    margin: 12px 0px 30px 0px;
    width: 640px;
    height:48px;
    border-color: #eaeaea;
  }

  .contact_form_inputs{
    width: 640px;
    height: 48px;
    margin: 6px 0 0 -2px;
    padding: 16px 17px 16px 18px;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 16px;
    font-family: Gilroy-SemiBold;
    letter-spacing: 0.5px;
    color: #2d2f3b;
  }

  .contact_form_textarea{
    width: 640px;
    height: 155px;
    margin: 8px 0 0;
    padding: 18px 2px 2px 18px;
    border-radius: 4px;
    border: solid 1px #eaeaea !important;
    background-color: #ffffff;
    font-size: 16px;
    font-family: Gilroy-SemiBold;
    letter-spacing: 0.5px;
    color: #2d2f3b;
  }

  .contact_form_inputs::placeholder, .contact_form_textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #afafb5;
  }
  

  .contact_form_inputs::-ms-input-placeholder, .contact_form_textarea::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #afafb5;
  }

  .contact_form_inputs::-ms-input-placeholder, .contact_form_textarea::-ms-input-placeholder { /* Microsoft Edge */
    color: #afafb5;
  }

  .tip_text{
    width: 612px;
    height: 12px;
    font-size: 12px;
    font-family: Gilroy-SemiBold;
    letter-spacing: 0.38px;
    color: #afafb5;
    margin-top: 12px;
    margin-bottom: 22px;
  }

  .MuiOutlinedInput-root{
    height: 48px;
    border-color: #eaeaea;
  }

  .btn_submit{
    width: 183px;
    height: 40px;
    background-color: #10af42;
    padding: 10px 67px 12px;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    font-family: Gilroy-Bold;
    font-size: 14px;
    letter-spacing: 0.44px;
    margin-bottom:14px;
  }

  .MuiFormControl-root{
    margin-bottom: 0px !important;
  }

  .show_err_info{
    color: #ff4949;
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 16px;
    visibility: visible;
  }

  .hide_err_info{
    visibility: hidden;
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 16px;
  }

  .contact_form_inputs_no_error{
      border: solid 1px #eaeaea !important;
    }

  .contact_form_inputs_err_state, .contact_form_textarea_err_state{
    border: solid 1px #ff4949 !important;
  }

  .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23);
  }

  .select_error_class{
    border-color: #ff4949 !important;
  }

  .show_contact_form{
    display: block;
  }

  .hide_contact_form{
    display: none;
  }

  .show_success_resp{
    display: flex;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq_root{
      margin-bottom: 200px;
    }

    .form_container{
      width: 330px;
      height: 780px;
      padding: 45px 32px 61px 37px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
    }

    .contact_query_success{
      width: 330px;
      height: 780px;
      padding: 45px 37px 61px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .contact_form_inputs{
      width: 260px;
      height: 48px;
      margin: 6px 0 0 -2px;
      padding: 16px 17px 16px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
      margin-bottom: 30px;
    }
  
    .contact_form_textarea{
      width: 260px;
      height: 155px;
      margin: 8px 0 0;
      padding: 18px 2px 2px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
      margin-bottom: 19px;
    }

    .tip_text{
      width: 260px;
      height: 12px;
      font-size: 10px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.31px;
      color: #afafb5;
      margin-bottom: 32px;
    }

    .MuiOutlinedInput-root{
      width: 260px;
      margin-bottom: 0px;
    }

    .show_contact_form{
      display: block;
    }

    .hide_contact_form{
      display: none;
    }

    .show_success_resp{
      display: flex;
    }
  }

  .contact_form_subject_root .MuiListItem-button {
    border-left: 2px solid #3bbe64;
    background-color: #ecfbf5;
  }

  .select_subject:hover{
    border-left: 2px solid #3bbe64 !important;
    background-color: #ecfbf5 !important;
  }

  .select_subject{
    border-left: 2px solid #3bbe64 !important;
    background-color: #ecfbf5 !important;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq_root{
      margin-bottom: 200px;
    }

    .form_container{
      width: 330px;
      height: 780px;
      padding: 45px 32px 61px 37px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
    }

    .contact_query_success{
      width: 330px;
      height: 780px;
      padding: 45px 37px 61px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .contact_form_inputs{
      width: 260px;
      height: 48px;
      margin: 6px 0 0 -2px;
      padding: 16px 17px 16px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
    }
  
    .contact_form_textarea{
      width: 260px;
      height: 155px;
      margin: 8px 0 0;
      padding: 18px 2px 2px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
      margin-bottom: 2px;
    }

    .contact_form_textarea_no_error{
      border: solid 1px #eaeaea;
    }

    .tip_text{
      width: 260px;
      height: 12px;
      font-size: 10px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.31px;
      color: #afafb5;
      margin-bottom: 32px;
    }

    .MuiOutlinedInput-root{
      width: 260px;
    }

    .show_contact_form{
      display: block;
    }

    .hide_contact_form{
      display: none;
    }

    .show_success_resp{
      display: flex;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .ciq_root{
      margin-bottom: 200px;
    }

    .form_container{
      width: 330px;
      height: 780px;
      padding: 45px 32px 61px 37px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
    }

    .contact_query_success{
      width: 330px;
      height: 780px;
      padding: 45px 37px 61px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .contact_form_inputs{
      width: 260px;
      height: 48px;
      margin: 6px 0 0 -2px;
      padding: 16px 17px 16px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
    }
  
    .contact_form_textarea{
      width: 260px;
      height: 155px;
      margin: 8px 0 0;
      padding: 18px 2px 2px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
      margin-bottom: 2px;
    }

    .tip_text{
      width: 260px;
      height: 12px;
      font-size: 10px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.31px;
      color: #afafb5;
      margin-bottom: 32px;
    }

    .MuiOutlinedInput-root{
      width: 260px;
    }

    .show_contact_form{
      display: block;
    }
  
    .hide_contact_form{
      display: none;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .ciq_root{
      margin-bottom: 200px;
    }

    .form_container{
      width: 330px;
      height: 780px;
      padding: 45px 32px 61px 37px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
    }

    .contact_query_success{
      width: 330px;
      height: 780px;
      padding: 45px 37px 61px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .contact_form_inputs{
      width: 260px !important;
      height: 48px;
      margin: 6px 0 0 -2px;
      padding: 16px 17px 16px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
    }
  
    .contact_form_textarea{
      width: 260px;
      height: 155px;
      margin: 8px 0 0;
      padding: 18px 2px 2px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
      margin-bottom: 2px;
    }

    .tip_text{
      width: 260px;
      height: 12px;
      font-size: 10px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.31px;
      color: #afafb5;
      margin-bottom: 32px;
    }

    .MuiOutlinedInput-root{
      width: 260px;
    }

    .show_contact_form{
      display: block;
    }
  
    .hide_contact_form{
      display: none;
    }

  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .ciq_root{
      margin-bottom: 200px;
    }
    
    .form_container{
      width: 330px;
      height: 780px;
      padding: 45px 32px 61px 37px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
    }

    .contact_query_success{
      width: 330px;
      height: 780px;
      padding: 45px 37px 61px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .contact_form_inputs{
      width: 260px;
      height: 48px;
      margin: 6px 0 0 -2px;
      padding: 16px 17px 16px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
    }
  
    .contact_form_textarea{
      width: 260px;
      height: 155px;
      margin: 8px 0 0;
      padding: 18px 2px 2px 18px;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.44px;
      margin-bottom: 2px;
    }

    .tip_text{
      width: 260px;
      height: 12px;
      font-size: 10px;
      font-family: Gilroy-SemiBold;
      letter-spacing: 0.31px;
      color: #afafb5;
      margin-bottom: 32px;
    }

    .MuiOutlinedInput-root{
      width: 260px;
    }

    .show_contact_form{
      display: block;
    }
  
    .hide_contact_form{
      display: none;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;