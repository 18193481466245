import styled from "styled-components";

interface PhoneControlProps {}
export const StyledNav = styled("div")<PhoneControlProps>`
  position: relative;

  .app_store_logo{
    width: 32px !important;
    height: 32px !important;
  
    &.apple_store_icon_img {
      background-image: url(https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/apple_store_icon%402x.png);
      :hover {
        background-image: url(https://getmiles.s3.amazonaws.com/assets/web_v2/common_icon/apple_store_icon_hover_state/apple-icon%402x.png);
      }
    }

    &.google_play_icon_img {
      background-image: url(https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/google_play_icon%402x.png);
      :hover {
        background-image: url(https://getmiles.s3.amazonaws.com/assets/web_v2/common_icon/google_play_icon_hover_state/android-icon%402x.png);
      }
    }
    
  }

  .apple{
    margin-right: 16px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 550px) {
    .app_store_url_wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 18px auto 0;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 551px) and (max-width : 768px) {
    .app_store_url_wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 33px auto 0;
    }
  }
`;


interface DownloadNumberInputProps {
  send_downloadLink_phoneErr: boolean;
}

export const StyledInput = styled("input")<DownloadNumberInputProps>`
  padding: 0;
  margin: 0;
  width: 580px;
  height: 52px;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
  background-color: #ffffff;
  border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
  outline-style: none;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Noto Sans JP;
  line-height: 24px;
  letter-spacing: 0.67px;
  color: #2d2f3b;
  padding-left: 24px;
  padding-right: 222px;

  :active {
    box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
    border: 1px solid #10af42;
  }

  :focus {
    box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
    border: solid 1px #10af42;
  }

  &::-webkit-input-placeholder {
    width: 292px;
    height: 52px;
    font-family: Noto Sans JP;
    font-weight: 500;
    font-size: 20px;
    line-height: 52px;
    letter-spacing: 0.67px  ;
    color: #bfbfbf;
  }

  // --- firefox 19+ ---
  &::-moz-placeholder {
    width: 292px;
    height: 52px;
    font-family: Noto Sans JP;
    font-size: 20px;
    line-height: 52px;
    letter-spacing: 0.67px  ;
    color: #bfbfbf;
  }

  // --- ie ----
  &::-ms-input-placeholder {
    width: 292px;
    height: 52px;
    font-family: Noto Sans JP;
    font-weight: 500;
    font-size: 20px;
    line-height: 52px;
    letter-spacing: 0.67px  ;
    color: #bfbfbf;
  }

  &::-moz-placeholder {
    width: 292px;
    height: 52px;
    font-family: Noto Sans JP;
    font-size: 20px;
    line-height: 52px;
    letter-spacing: 0.67px  ;
    color: #bfbfbf;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

   /* Extra Small Devices, Phones */ 
   @media only screen and (max-width : 550px) {
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 768px) {
    width: 305px;
    height: 40px;
    border-radius: 25px;
    font-size: 12px;
    letter-spacing: 0.53px;
    text-align: left;
    color: #2d2f3b;
    font-family: Noto Sans JP;
    padding-left: 16px;
    padding-right: 90px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 550px) and (max-device-width : 480px) {
    width: 305px;
    height: 40px;
    border-radius: 25px;
    font-size: 12px;
    letter-spacing: 0.53px;
    text-align: left;
    color: #2d2f3b;
    font-family: Noto Sans JP;
    padding-left: 16px;
    padding-right: 90px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 305px;
    height: 40px;
    border-radius: 25px;
    font-size: 12px;
    letter-spacing: 0.53px;
    text-align: left;
    color: #2d2f3b;
    font-family: Noto Sans JP;
    padding-left: 16px;
    padding-right: 90px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    width: 305px;
    height: 40px;
    border-radius: 25px;
    font-size: 12px;
    letter-spacing: 0.53px;
    text-align: left;
    color: #2d2f3b;
    font-family: Noto Sans JP;
    padding-left: 16px;
    padding-right: 90px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 472px;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
    letter-spacing: 0.53px;
    text-align: left;
    color: #2d2f3b;
    font-family: Noto Sans JP;
    padding-left: 16px;
    padding-right: 145px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 196px;
      height: 40px;
      font-family: Noto Sans JP;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.53px;
      color: #bfbfbf;
    }
  }

`;

interface BtnProps {
  bgColor: string;
}

export const StyledBtn = styled("button")<BtnProps>`
  width: 217px;
  height: 52px;
  margin: 0;
  padding: 0;
  border-radius: 43.5px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  border-color: transparent;
  border: ${(props) => `solid 1px ${props.bgColor}`};
  font-family: Noto Sans JP;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  position: absolute;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  margin-left: -217px;

  &.navPhoneNumberButton {
    background-color: ${(props) => props.bgColor};

    :hover {
      background-color: ${(props) => props.disabled ? "#9b9ba5" : "#178e3d"};
    }
  }

  

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 140px;
    height: 40px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    font-family: Noto Sans JP;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    margin-left: -140px;
    margin-top: 0px;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

    /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 550px) {
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 768px) {
    width: 84px;
    height: 40px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    font-family: Noto Sans JP;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin-left: -84px;
    margin-top: 0px;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 550px) and (max-device-width : 480px) {
    width: 84px;
    height: 40px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    font-family: Noto Sans JP;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    margin-left: -84px;
    margin-top: 0px;
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 84px;
    height: 40px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    font-family: Noto Sans JP;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    margin-left: -84px;
    margin-top: 0px;
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    width: 84px;
    height: 40px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    font-family: Noto Sans JP;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    margin-left: -84px;
    margin-top: 0px;
  }
`;

interface SendDownloadErrTipEleProps {
  send_downloadLink_phoneErr: boolean;
}
export const StyledSendDownloadErrTipEle = styled("span")<SendDownloadErrTipEleProps>`
  display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
  width: max-content;
  height: 14px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-family: Noto Sans JP;
  font-size: 14px;
  line-height:14px;
  letter-spacing: 0.47px;
  color: #ff4949;
  padding: 0px;

  @media only screen and (max-width : 768px) {
    width: max-content;
    height: 14px;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: max-content;
    height: 14px;
    margin-top: 8px;
    font-size: 14px;
    letter-spacing: 0.47px;
    color: #ff4949;
  }
`;

interface StyledSuccessInfoProps {}

export const StyledSuccessInfo = styled("div")<StyledSuccessInfoProps>`
  margin: 0;
  width: 580px;
  height: 52px;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  background-color: #10af42;
  outline-style: none;
  box-sizing: border-box;
  font-family: Noto Sans JP;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
  line-height: 52px;


  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    padding: 0px;
    width: 472px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.7px;
    vertical-align: middle;
    text-align: center;
  }

  @media only screen and (max-width : 768px) {
    padding: 0px;
    width: 305px;
    height: 40px;
    font-size: 10px;
    line-height: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    vertical-align: middle;
    text-align: center;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }
`;