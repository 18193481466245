import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  partner_page_assets: assets,
} = config;

const Component: React.FC<{
  scoll_to_pre_footer_form?: () => void;
}> = observer(({
  scoll_to_pre_footer_form,
}) => {
  return (
    <>
      <Styled_body_wrap className="partner_section_partner_we_work_with">
        <div className="main_container">
          <div className="partner_we_work_with_box">
            <div className="title_on_partner_we_work_with">Partners We Work With</div>

            <div className="CTA_box_on_partner_we_work_with">
              <div className={`asset_on_partner_we_work_with_img`} />


              <div className="CTA_box_content_on_partner_we_work_with">
                <div className="CTA_text_on_partner_we_work_with miles_ui_screen_larger_than_360">
                Join the <span>hundreds</span> of brands already on Miles.
                </div>
                <div className="CTA_text_on_partner_we_work_with miles_ui_screen_less_than_360">
                Join the <span>hundreds</span> of brands already on <br /> Miles.
                </div>
                <button className={`CTA_btn_on_partner_we_work_with miles_ui_btn_primary`} onClick={scoll_to_pre_footer_form}>Partner with Us</button>
              </div>

            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
