import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin-top: 0px;

  .ciq-root{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
  }
  
  .ciq-container{
    width: 100%;
    height: 300px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    position: relative;
    padding: 0px;
    margin: 0px;
  }

  .btn-download-press-kit {
    width: 319px;
    height: 64px;
    background-color: #10af42;
    padding: 23px 46px 17px;
    border-radius: 32px;
    border: none;
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .cta-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .top_banner_picture{
    display: block;
    justify-content: center;
    position: relative;
    width: auto;
    height: 300px;
  }

  .top_banner_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq-container {
      height: 170px;
    }

    .top_banner_picture {
      height: 170px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq-container {
      height: 170px;
    }

    .top_banner_picture {
      height: 170px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;