import { ITEM_TYPE, QUESTION_TYPE } from "../../data";


export function urlForSection(section: ITEM_TYPE, country: string): string {
    (window as any).history.scrollRestoration = "manual";
    setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 500);
    return country === "jp" ? `/jp/help/category/${section.slug}` : `/help/category/${section.slug}`;
}

export function urlForQuestion(section: ITEM_TYPE, question: QUESTION_TYPE, country: string): string {
    return country === "jp" ? `/jp/help/category/${section.slug}/${question.slug}` : `/help/category/${section.slug}/${question.slug}`;
}
