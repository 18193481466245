import styled from "styled-components";

interface Props {}
export const StyledHandlePreferenceText = styled("p")<Props>`
  width: 201px;
  height: 19px;
  margin: 23px auto;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #2d2f3b;

  a {
    cursor: pointer;
    color: #10af42;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  }
`;