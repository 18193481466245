import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {} = config;
const downloadPressKitURL = "https://drive.google.com/file/d/19cqOPkQfqC8wnIUaJeG_Ai1Vprns8OnN/view?usp=sharing"

const Component: React.FC<{}> = observer(() => {
  return (
    <>
      <Styled_body_wrap className="">
      <button className="inner_cta miles_ui_btn_primary" onClick={()=> window.open(downloadPressKitURL, "_blank")}>
       <span className="inner_cta-text">
          Download Press Kit
        </span>
     </button>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
