import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import { useTranslation } from 'react-i18next';
import { useCountry } from "../../../utils/milesUtils";

const {
  partner_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  let country = useCountry();
  const { t } = useTranslation();
  return (
    <>
      <Styled_body_wrap country={country} className="partner_section_discover_new_customer_wrap">
        <div className="main_container">
          <div className="title">{t('partner_page_discover_text_001')}</div>

          <div className="asset_wrap">
            <div className="inner_box inner_box_01">
              <img className="inner_box_01_asset" src={assets.discover_01_img} alt={`discover_01_img`}></img>
              <div className="inner_box_content_box inner_box_01_content">
                <div className="inner_box_text_01">
                {t('partner_page_discover_text_002')}
                </div>
                <div className="inner_box_text_02">
                {t('partner_page_discover_text_003')}
                </div>
              </div>
            </div>

            <div className="inner_box inner_box_02">
              <div className="inner_box_content_box inner_box_02_content">
                <div className="inner_box_text_01">
                {t('partner_page_discover_text_004')}
                </div>
                <div className="inner_box_text_02">
                {t('partner_page_discover_text_005')}
                </div>
              </div>
              <img className="inner_box_02_asset" src={assets.discover_02_img} alt={`discover_02_img`}></img>
            </div>

            <div className="inner_box inner_box_03">
              <img className="inner_box_03_asset" src={assets.discover_03_img} alt={`discover_03_img`}></img>
              <div className="inner_box_content_box inner_box_03_content">
                <div className="inner_box_text_01">
                {t('partner_page_discover_text_006')}
                </div>
                <div className="inner_box_text_02">
                {t('partner_page_discover_text_007')}
                </div>
              </div>
            </div>
          </div>

        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
