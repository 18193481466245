import styled from "styled-components";

interface Props {}
export const StyledCard = styled("div")<Props>`
  width: 350px;
  height: 563px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  img {
    display: inline-block;
  }

  .avatar_img {
    width: 200px;
    height: 200px;
    margin: 0;
    z-index: 222;
  }

  .inner_card_wrap {
    width: 350px;
    height: 490px;
    margin: 0 auto;
    border-radius: 32px;
    box-shadow: 0 0 10px 0 #e6e6e8;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: -127px;
    padding-top: 127px;
  }

  .userName {
    height: 40px;
    font-family: Gilroy-Bold;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 43px;
  }

  .testimonial {
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-top: 40px;
  }

  .bottom_wrap {
    width: auto;
    height: 32px;
    bottom: 47px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .os_img {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
    .green_star {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }

    .green_star.last {
      margin-right: 0;
    }
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 246px;
    height: 438px;

    img {}

    .avatar_img {
      width: 138px;
      height: 138px;
    }

    .inner_card_wrap {
      width: 240px;
      height: 370px;
      margin-top: -80px;
      padding-top: 80px;
    }

    .userName {
      height: 40px;
      font-family: Gilroy-Bold;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 32px;
    }

    .testimonial {
      width: 218px !important;
      height: auto;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: 0.7px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 28px;
    }

    .bottom_wrap {
      width: auto;
      height: 20px;
      bottom: auto;    
      top: 371px;

      .green_star {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      .green_star.last {
        margin-right: 0;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 246px;
    height: 420px;

    img {}

    .avatar_img {
      width: 138px;
      height: 138px;
    }

    .inner_card_wrap {
      width: 240px;
      height: 340px;
      margin-top: -69px;
      padding-top: 69px;
    }

    .userName {
      height: 40px;
      font-family: Gilroy-Bold;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 30px;
    }

    .testimonial {
      width: 218px !important;
      height: auto;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: 0.7px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 21px;
    }

    .bottom_wrap {
      width: auto;
      height: 20px;
      bottom: auto;    
      top: 352px;

      .green_star {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      .green_star.last {
        margin-right: 0;
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
   
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 256px;
    height: 468px;

    img {}

    .avatar_img {
      width: 150px;
      height: 150px;
    }

    .inner_card_wrap {
      width: 256px;
      height: 400px;
      margin-top: -69px;
      padding-top: 69px;
    }

    .userName {
      height: 40px;
      font-family: Gilroy-Bold;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 40px;
    }

    .testimonial {
      width: 218px !important;
      height: auto;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: 0.7px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 28px;
    }

    .bottom_wrap {
      width: auto;
      height: 20px;
      bottom: 69px;

      .green_star {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      .green_star.last {
        margin-right: 0;
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    width: 256px;
    height: 468px;

    img {}

    .avatar_img {
      width: 150px;
      height: 150px;
    }

    .inner_card_wrap {
      width: 256px;
      height: 400px;
      margin-top: -77px;
      padding-top: 77px;
    }

    .userName {
      height: 40px;
      font-family: Gilroy-Bold;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 40px;
    }

    .testimonial {
      width: 218px !important;
      height: 100px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: 0.7px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 20px;
    }

    .bottom_wrap {
      width: auto;
      height: 20px;
      bottom: 69px;

      .os_img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      .green_star {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      .green_star.last {
        margin-right: 0;
      }
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;