import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import { useCountry } from "../../../utils/milesUtils";

const {
  contact_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  let country = useCountry();
  return (
    <>
      <Styled_body_wrap isJP={country === "jp"}>
        <div className="ciq_root">
          <div className="ciq_container">
            <picture className="top_banner_picture">
              <source className="top_banner_img" media="(max-width : 360px)" srcSet={assets.contact_page_top_banner}></source>
              <source className="top_banner_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.contact_page_top_banner}></source>
              <source className="top_banner_img" media="(min-width : 769px) and (max-width : 1440px)" srcSet={assets.contact_page_top_banner}></source>
              <source  className="top_banner_img" media="(min-width : 1441px)" srcSet={assets.contact_page_top_banner}></source>
              <img className="top_banner_img" alt={`contact_page_header_asset_1`}></img>
            </picture>
          </div>
          <div className="top_banner_title">{t('contact_page_text_001')}</div>

        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
