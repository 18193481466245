import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import copy from 'copy-to-clipboard';
import { AsYouType } from 'libphonenumber-js'
import {
  FcaLandingWrap
} from "./styles";
import { store } from "../../Store";
import share_icon from '../../../src/assets/commonIcon/share@3x.png'; 
import redeem_rewards_icon from '../../../src/assets/commonIcon/redeem-rewards@3x.png'; 
import { FcaCopyBox } from "../../components/fca_copy_box";
import {
  checkValidPhoneNumber
} from '../../utils/phoneNumValidator'
import './sass/index.scss'

type referInfoObj = {
  phone_number: string;
  referral_code: string;
  role_type: string;
};

const fca_miles_logo_url = `https://getmiles.s3.amazonaws.com/assets/web_v2/fca_web/fca_miles_logo_2021_v2/stellantis_logo_2022%402x.png`;
const fca_landingPage_hero_01 = `https://getmiles.s3.amazonaws.com/assets/web_v2/fca_web/fca_landingPage_hero_01/Group+586%402x.png`;

const FcaLanding: React.FC<{}> = observer(() => {
  const fcaCodeRef = useRef<HTMLIFrameElement>(null);
  const [shouldShowCopyBox, setShouldShowCopyBox] = useState(false);
  const [fcaCode, setFcaCode] = useState('FCA123');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [phoneErr, setPhoneErr] = useState(false);
  const [shouldDisableSendBtn, setShouldDisableSendBtn] = useState(false);

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 4) || (e.target.value[0] === '1' && e.target.value.length === 6)
      const formattedPhoneNum = shouldUseCurrentValue ? e.target.value : new AsYouType("US").input(e.target.value)
      setPhoneNumber(formattedPhoneNum)
    },
    [phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 8) {
        setPhoneErr(false)
      }
    },
    [phoneNumber]
  );

  const handleShouldShowCopyBox = useCallback(
    () => {
      setShouldShowCopyBox(true)
      copy(fcaCode)
      setTimeout(() => {
        setShouldShowCopyBox(false)
      }, 2000)
    },
    [shouldShowCopyBox]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      if(!checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))) {
        setPhoneErr(true)
      } else {
        const referInfoObj: referInfoObj = {
          phone_number: phoneNumber,
          referral_code: fcaCode,
          role_type: 'user'
        }
        store.handleSendDownloadLink_RewardShare(referInfoObj);
      }
    },
    [phoneNumber, fcaCode]
  );

  return (
    <React.Fragment>
      <FcaLandingWrap className="fca-landing-wrap">
        <img className="uconnect-miles-logo" src={fca_miles_logo_url} alt="uconnect-miles-logo"></img>
        <div className="fca-titile-wrap">
          <p className="fca-title-line-1">Welcome to Miles for Stellantis, a new loyalty
            <br />program for Alfa-Romeo, Chrysler, Dodge, FIAT, <br />
            Jeep, and RAM customers.</p>
        </div>
        <img className="fca-gift-img" src={fca_landingPage_hero_01} alt="fca-gift-img"></img>

        <div className="fca-icon-wrap">
          <img className="fca-share-icon" src={share_icon} alt=""></img>
          <p className="fca-share-text">{`Earn miles for your travel & commute`}</p>
        </div>

        <div className="fca-icon-wrap redeem-wrap">
          <img className="fca-redeem-icon" src={redeem_rewards_icon} alt=""></img>
          <p className="fca-redeem-text">Use miles to redeem exclusive rewards</p>
        </div>
        
        {
          store.isSendFcaLink === "sent" ? (
            <div className="copy-code-box download-link-box">
              Check your phone for download link
            </div>
          ) : (
            <>
              <div className="send-downloadLink-wrap">
                <input
                  className={`send-downloadLink-inputBox ${phoneErr ? 'fcaErr' : null}`}
                  placeholder="(xxx) - xxx - xxxx"
                  value={phoneNumber}
                  onChange={handleChangePhoneNum}
                  onKeyDown={handleOnKeyDown}
                  type="tel"
                />
                <span className={`send-downloadLink-inputBox-pre ${phoneErr ? 'fcaErr' : null}`}>+1</span>
                <button
                  className="send-downloadLink-btn"
                  onClick={handleSendDownloadLink}
                  style={{
                    cursor: shouldDisableSendBtn ? 'unset' : 'pointer'
                  }}
                  disabled={store.isSendFcaLink === 'sending' || shouldDisableSendBtn ? true : false}
                >{store.isSendFcaLink === 'sending' ? 'Sending...' : 'Send Download Link'}</button>
              </div>
              <span className={`${phoneErr ? 'fca-err-tip' : 'hide'}`}>Please enter a valid US phone number.</span>
              <a
                className="get-the-app-btn"
                referrerPolicy="unsafe-url"
                href={`https://fca-uconnect.milesapp.io/refer`}
              >GET THE APP</a>
            </>
          )
        }
      </FcaLandingWrap>
    </React.Fragment>
  );
});

export default FcaLanding;
