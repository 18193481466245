import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const jobs_page_assets = {
  jobs_top_asset_1440_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_top_asset_1440_v2%402x.png`,
  jobs_top_hero_largeThan_1440_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_top_hero_largeThan_1440_v2%402x.png`,
  jobs_family_largeThan_1440_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_family_largeThan_1440%402x.png`,
  jobs_header_asset_01_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_header_asset_01_v2%402x.png`,
  jobs_find_your_fit_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_find_your_fit%402x.png`,
  jobs_working_at_miles_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_working_at_miles%402x.png`,
  jobs_avatar_01_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_avatar_01%402x.png`,
  jobs_avatar_02_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_avatar_02%402x.png`,
  jobs_avatar_03_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_avatar_03%402x.png`,

  jobs_perks_asset_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_perks_asset%402x.png`,
  jobs_perks_healthy_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_perks_healthy_icon%402x.png`,
  jobs_perks_stock_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_perks_stock_icon%402x.png`,
  jobs_perks_pto_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_perks_pto_icon%402x.png`,
  jobs_perks_gift_icon_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_perks_gift_icon%402x.png`,
  jobs_diversity_bg_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_diversity_bg%402x.png`,
  jobs_miles_family_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_miles_family%402x.png`,
  jobs_miles_location_img: `${MILES_WEB_S3_BASE_URL_V2}jobs_page/jobs_miles_location%402x.png`,
};
