import React, { useMemo, ReactNode } from "react";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import config from "../../../utils/config";
import {
  Styled_body_wrap,
  Styled_Card,
  Styled_card_on_template_02,
  Styled_card_on_template_03,
} from "./styles";
import { useTransitionCarousel } from "../../../components/v2/transition_carousel";
import { ReactSpringCarouselItem } from "../../../components/v2/spring_carousel";
import { useCountry } from "../../../utils/milesUtils";

const {
  about_page_assets: assets,
} = config;

interface CardProps {
  imgSrc: string;
  descriptionEle: string | JSX.Element;
  className?: string;
}

const CardUnit: React.FC<CardProps> = observer(({
  imgSrc,
  descriptionEle,
  className,
}) => {
  return (
    <Styled_Card className={`carousel_main_wrap CardUnit_on_carousel_01 ${className}`}>
      <img src={imgSrc} className={`carousel_01_img`} alt={`carousel_03_img`} />
      <div className="descriptionEle">{descriptionEle}</div>
    </Styled_Card>
  );
});

const renderCardUnit = (card: CardProps) => (
  <CardUnit
    imgSrc={card.imgSrc}
    descriptionEle={card.descriptionEle}
    className={card.className}
  />
);

interface CardProps_02 {
  imgSrc: string;
  title?: string | JSX.Element;
  content: string | JSX.Element;
  className?: string;
}

const CardUnit_template_02: React.FC<CardProps_02> = observer(({
  imgSrc,
  title,
  content,
  className,
}) => {
  return (
    <Styled_card_on_template_02 className={`template_02 carousel_main_wrap CardUnit_on_carousel_01 ${className}`}>
      <img src={imgSrc} className={`carousel_01_template_img`} alt={`carousel_01_template_img`} />
      <div className="content_wrap">
        <div className="title">{title}</div>
        <div className="content">{content}</div>
      </div>
    </Styled_card_on_template_02>
  );
});

const CardUnit_template_03: React.FC<CardProps_02> = observer(({
  imgSrc,
  content,
  className,
}) => {
  return (
    <Styled_card_on_template_03 className={`carousel_main_wrap CardUnit_on_carousel_01 ${className}`}>
      <img src={imgSrc} className={`carousel_01_template_img`} alt={`carousel_01_template_img`} />
      <div className="content">{content}</div>
    </Styled_card_on_template_03>
  );
});

const Component: React.FC<{}> = observer(() => {
  let country = useCountry();
  const { t } = useTranslation();

  const rawItems = [
  {
    label: t('about_page_carousel_01_rawItems_01'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2016_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_01_content')}</span>,  
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_02'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2018_07_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_02_content_part1')}<br />{t('about_page_carousel_01_rawItems_02_content_part2')}</span>,
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_03'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2019_03_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_03_content')}</span>,
      className: "carousel_card_longer_content"
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_04'),
    renderItem: (
      <CardUnit_template_02 
        imgSrc={assets.carousel_01_2019_06_v2_img}
        title={t('about_page_carousel_01_rawItems_04_title')}
        content={
          t('about_page_carousel_01_rawItems_04_content_part0') ? (
            <span><span className="highlighted">{t('about_page_carousel_01_rawItems_04_content_part0')}</span>{t('about_page_carousel_01_rawItems_04_content_part1')}</span>
          ) : <span>{t('about_page_carousel_01_rawItems_04_content_part1')}<span className="highlighted">{t('about_page_carousel_01_rawItems_04_content_part2')}</span>{t('about_page_carousel_01_rawItems_04_content_part3')}</span>
        }
        className=""
      />
    ),
  },
  {
    label: t('about_page_carousel_01_rawItems_05'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2020_03_v2_img,
      descriptionEle: country === "jp" ? (
      <span>{t('about_page_carousel_01_rawItems_05_content_part0')} <span className="highlighted">{t('about_page_carousel_01_rawItems_05_content_part1')}</span><br />{t('about_page_carousel_01_rawItems_05_content_part2')} <span className="subtext">{t('about_page_carousel_01_rawItems_05_content_part3')}</span></span>
    ) : <span>{t('about_page_carousel_01_rawItems_05_content_part0')} <span className="highlighted">{t('about_page_carousel_01_rawItems_05_content_part1')}</span><br />{t('about_page_carousel_01_rawItems_05_content_part2')} <span className="subtext">{t('about_page_carousel_01_rawItems_05_content_part3')}</span></span>,
      className: "carousel_card_longer_content"
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_06'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2020_05_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_06_content')}</span>,
      className: "carousel_card_longer_content"
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_07'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2020_06_v2_img,
      descriptionEle: country === "jp" ? (
        <span><span className="highlighted">{t('about_page_carousel_01_rawItems_07_content_part1')}</span>{t('about_page_carousel_01_rawItems_07_content_part2')}<br />{t('about_page_carousel_01_rawItems_07_content_part3')}</span>
      ) : <span>{t('about_page_carousel_01_rawItems_07_content_part1')}<br />{t('about_page_carousel_01_rawItems_07_content_part2')}<span className="highlighted">{t('about_page_carousel_01_rawItems_07_content_part3')}</span></span>,
      className: "carousel_card_longer_content"
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_08'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2020_08_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_08_content_part1')}<br />{t('about_page_carousel_01_rawItems_08_content_part2')}</span>,
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_09'),
    renderItem: (
      <CardUnit_template_02 
        imgSrc={assets.carousel_01_2020_09_img}
        title={t('about_page_carousel_01_rawItems_09_title')}
        content={<span>{t('about_page_carousel_01_rawItems_09_content')}</span>}
        className="carousel_card_longer_content"
      />
    ),
  },
  {
    label: t('about_page_carousel_01_rawItems_10'),
    renderItem: (
      <CardUnit_template_02 
        imgSrc={assets.carousel_01_2020_12_img}
        title={t('about_page_carousel_01_rawItems_10_title')}
        content={<span>{t('about_page_carousel_01_rawItems_10_content')}</span>}
        className=""
      />
    ),
  },
  {
    label: t('about_page_carousel_01_rawItems_11'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2021_03_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_11_content_part1')}<br />{t('about_page_carousel_01_rawItems_11_content_part2')}</span>,
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_12'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2021_04_v2_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_12_content_part1')}<br />{t('about_page_carousel_01_rawItems_12_content_part2')}</span>,
      className: "carousel_card_longer_content"
    }),
  },
  {
    label: t('about_page_carousel_01_rawItems_13'),
    renderItem: (
      <CardUnit_template_03
        imgSrc={assets.carousel_01_2021_05_v2_img}
        content={
          country === "jp" ? (
            <span><span className="highlighted">{t('about_page_carousel_01_rawItems_13_content_part1')}</span>{t('about_page_carousel_01_rawItems_13_content_part2')}<br />{t('about_page_carousel_01_rawItems_13_content_part3')}</span>
          ) : <span>{t('about_page_carousel_01_rawItems_13_content_part1')}<br />{t('about_page_carousel_01_rawItems_13_content_part2')}<span className="highlighted">{t('about_page_carousel_01_rawItems_13_content_part3')}</span></span>}
        className=""
      />
    ),
  },
  {
    label: t('about_page_carousel_01_rawItems_14'),
    renderItem: (
      <CardUnit_template_03
        imgSrc={assets.carousel_01_2021_06_img}
        content={<span>{t('about_page_carousel_01_rawItems_14_content_part1')}<br />{t('about_page_carousel_01_rawItems_14_content_part2')}</span>}
        className=""
      />
    ),
  },
  {
    label: t('about_page_carousel_01_rawItems_15'),
    renderItem: (
      <CardUnit_template_03
        imgSrc={assets.carousel_01_2021_07_img}
        content={<span>{t('about_page_carousel_01_rawItems_15_content_part1')}<br />{t('about_page_carousel_01_rawItems_15_content_part2')}</span>}
        className=""
      />
    ),
  },
  {
    label: t('about_page_carousel_01_rawItems_16'),
    renderItem: renderCardUnit({
      imgSrc: assets.carousel_01_2021_10_v2_img,
      descriptionEle: <span>{t('about_page_carousel_01_rawItems_16_content_part1')}<br />{t('about_page_carousel_01_rawItems_16_content_part2')}</span>,
      className: "carousel_card_longer_content"
    }),
  },
];

  const items = useMemo(() => rawItems.map((item, index) => ({
    id: `item_${index}`,
    renderItem: (
      <div className="carousel-item active">
        <div className="carousel_main_wrap">
          {item.renderItem}
        </div>
      </div>
    ),
  })), []);
  const {
    carouselFragment,
    activeItem,
    slideToPrevItem,
    slideToNextItem,
    slideToItems,
  } = useTransitionCarousel({
    items,
  });

  return (
    <Styled_body_wrap country={country} className="partner_section_carousel_02_wrap">
      <div className="main_container">
        <div
          id="miles_ui_AboutPage_carousel_01"
          className="carousel slide carousel-fade"
        >
          <div className="miles_ui_about_carousel_01_title">{t('about_page_carousel_01_title')}</div>
          <div className="carousel-indicators">
            <div className="aboutPage_carousel_01_step_line"></div>
            {rawItems.map((item, index) => (
              <div
                onClick={slideToItems[index]}
                className={`indicator_dot ${
                    activeItem === index ? "active" : ""
                  } ${
                    rawItems.length - 1 === index ? "pre_last" : ""
                  }`
                }
                style={index < rawItems.length - 1 ? {} : {
                  marginRight: 0,
                }}
                aria-current={activeItem === index}
                aria-label={`Slide ${index+1}`}
              >
                <span />
                <div className={`aboutPage_carousel_01_label aboutPage_carousel_01_label_${index+1}`}>{item.label}</div>
              </div>
            ))}

          </div>

          <div className="carousel-inner">
            {carouselFragment}
          </div>

          <button
            className="carousel-control-prev carousel_arrow arrow_left"
            type="button"
            onClick={slideToPrevItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

          <button
            className="carousel-control-next carousel_arrow arrow_right"
            type="button"
            onClick={slideToNextItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default Component;
