import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;

  .inner_box_01_asset, .inner_box_01_asset > img {
    width: 350px;
    height: 190px;
    border-radius: 32px 32px 0px 0px;
    object-fit: cover;
  }

  .card_unit_text_section{
    width: 350px;
    height: 210px;
    padding: 20px 22px 32px 22px;
    border-radius: 0px 0px 32px 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
  }

  .card_unit_text_header{
    font-family: Gilroy-Bold;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.0px;
    color: #5f5f69;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 58px;
    width: 306px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card_unit_text_subheader{
    font-family: Gilroy-Medium;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0px;
    color: #9b9ba5;
    margin-top: 7px;
    height: 63.6px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .card_unit_text_footer{
    margin-top: 14px;
  }

  .card_unit_footer_1{
    font-family: Gilroy-Medium;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.0px;
    color: #9b9ba5;
    float:left;
  }

  .card_unit_footer_2 > a {
    font-family: Gilroy-Medium;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0px;
    color: #10af42;
    float:right;
  }

  .card_unit_top_circle_img{
    height: 85px;
    width: 85px;
    background-color: #ffffff;
    border-radius: 42px;
    box-shadow: 0 -2px 5px 2px #f3f4f4, 0 -2px 5px 0 #f3f4f4;
    text-align: center;
    padding-top: 20px;
    position: absolute  ;
    margin-top:-60px;
    margin-left: 136px;
  }

  .card_unit_partner_logo > img {
    width: 69px;
    height: 48px;
    object-fit: contain;
  }

  .card_unit_text{
    z-index:0;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .inner_box_01_asset, .inner_box_01_asset > img {
      width: 280px;
    }

    .card_unit_text_section{
      width: 280px;
      padding: 22px 23px 34px 20px;
    }

    .card_unit_top_circle_img {
      margin-left: 98px;
    }

    .card_unit_text_header{
      width: 235px;
    }
    .card_unit_text_subheader {

    }

    .card_unit_footer_1 {

    }

    .card_unit_footer_2 > a {
      
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .inner_box_01_asset, .inner_box_01_asset > img {
      width: 280px;
    }

    .card_unit_text_header{
      width: 235px;
    }

    .card_unit_text_section{
      width: 280px;
    }

    .card_unit_top_circle_img {
      margin-left: 98px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;