import styled from "styled-components";
import i18next from 'i18next';

interface Props {}
export const StyledSubTitle = styled("p")<Props>`
  width: ${() => i18next.language === "english" ? "500px" : "490px"};
  height: 64px;
  font-family: ${() => i18next.language === "english" ? "Avenir Next" : "'Noto Sans JP', sans-serif"};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  margin: 0 20px 30px;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.3px;
  color: #2d2f3b;
  margin-bottom: 37px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 260px;
    height: 48px;
    margin: 0;
    font-size: 14px;
    line-height: 1.71;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 260px;
    height: 48px;
    margin: 0;
    font-size: 14px;
    line-height: 1.71;
  }
`;