import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Brands_you_love } from '../../components/v2/miles_ui_box_brands_you_love';
import { Send_download_link } from '../../components/v2/miles_ui_box_send_download_link';
import { We_care_your_privacy } from '../../components/v2/miles_ui_box_we_care_your_privacy';
import { Growing_fast } from '../../components/v2/miles_ui_box_growing_fast';
import { We_are_highlighted } from '../../components/v2/miles_ui_box_we_are_highlighted';
import { Our_users_love } from '../../components/v2/miles_ui_box_our_users_love';
import { Pre_footer_hero } from '../../components/v2/miles_ui_box_pre_footer_hero';
import config from '../../utils/config';
import milesUtils from '../../utils/milesUtils';
import { Styled_home_wrap } from './styles';

let firstRender = true;

const {
    growing_fast_01_img,
    growing_fast_02_img,
    growing_fast_03_img,
    growing_fast_04_img,
    we_care_your_privacy_img,
    trips_modes_visits_icons_img,
    redeem_miles_icon_v2_img,
    we_are_highlighted_techCrunch_img,
    we_are_highlighted_Forbes_img,
    we_are_highlighted_fastCompany_img,
    we_are_highlighted_badcredit_img,
    we_are_highlighted_card_rates_img,
    we_are_highlighted_curbed_img,
    we_are_highlighted_geekwire_img,
    we_are_highlighted_greenBiz_img,
    we_are_highlighted_mashable_img,
    we_are_highlighted_nast_traveler_img,
    we_are_highlighted_ozy_img,
    we_are_highlighted_skift_img,
    we_are_highlighted_the_points_guy_img,
    we_are_highlighted_the_verge_img,
    we_are_highlighted_wired_img,
    logo_2022_wired_v1,
    logo_2022_greekwire_v1,
    logo_2022_smartCitiesDrive_v1,
    logo_2022_metro_v1,
    logo_2022_massTransit_v1,
    logo_2022_techCrunch_v1,
    logo_2022_intelligentTransport_v1,
    logo_2022_KQED_v1,
    logo_2022_eastCountyToday_v1,
    our_users_love_avatar_01_img,
    our_users_love_avatar_02_img,
    our_users_love_avatar_03_img,
    our_users_love_avatar_04_img,
    our_users_love_avatar_05_img,
    our_users_love_avatar_06_img,
    our_users_love_avatar_07_img,
    our_users_love_avatar_08_img,
    our_users_love_avatar_09_img,
    our_users_love_avatar_10_img,
    our_users_love_avatar_11_img,
    our_users_love_avatar_12_img,
    logo_apple_dark_img,
    logo_android_dark_img,
    carousel_arrow_left_img,
    carousel_arrow_right_img,
    apple_store_icon_img,
    google_play_icon_img,
    star_icon_img,
    icon_star_half_img,
    star_icon_green_img,
    apple_store_src,
    google_play_store_src,
    hero_1_1440,
    hero_1,
    hero_1_768_to_1150,
    hero_1_600_ipad_mini,
    home_page_hero_02_v2,
    home_page_hero_3_v2,
    brands_you_love_img,
} = config;

const MilesWeb_HomePage: React.FC<{}> = observer(() => {
    if (firstRender) {
        (window as any).history.scrollRestoration = 'manual';
        milesUtils.debug_log(
            'component - MilesWeb_HomePage -- firstRender',
        );
    }

    useEffect(() => {
        milesUtils.debug_log(
            'component - MilesWeb_HomePage -- did mount',
        );

        firstRender = false;
    }, []);

    return (
        <Styled_home_wrap>
            <div
                id='hero_01_wrap'
                className={`hero_01_wrap`}
            >
                <Send_download_link
                    apple_store_icon_img={apple_store_icon_img}
                    google_play_icon_img={google_play_icon_img}
                    apple_store_src={apple_store_src}
                    google_play_store_src={google_play_store_src}
                />
                <picture className='hero_1_picture'>
                    <source
                        className='hero_1_img'
                        media='(max-width : 360px)'
                        srcSet={hero_1_600_ipad_mini}
                    ></source>
                    <source
                        className='hero_1_img'
                        media='(min-width : 361px) and (max-width : 768px), '
                        srcSet={hero_1_600_ipad_mini}
                    ></source>
                    <source
                        className='hero_1_img'
                        media='(min-width : 769px) and (max-width : 1150px)'
                        srcSet={hero_1_768_to_1150}
                    ></source>
                    <source
                        className='hero_1_img'
                        media='(min-width : 1151px) and (max-width : 1439px)'
                        srcSet={hero_1}
                    ></source>
                    <source
                        className='hero_1_img'
                        media='(min-width : 1440px)'
                        srcSet={hero_1_1440}
                    ></source>
                    <img
                        className='hero_1_img'
                        alt={`hero_1`}
                    ></img>
                </picture>
            </div>

            <div
                id='hero_02_box_wrap'
                className={`hero_02_box_wrap`}
            >
                <div
                    id='hero_02_wrap'
                    className={`hero_02_wrap`}
                >
                    <div
                        id='hero_02_content_wrap'
                        className='hero_02_content_wrap fadeIn'
                    >
                        <div className='hero_02_title'>Earn miles</div>
                        <div className='hero_02_sub_title'>
                            Earn miles for all your daily commute &
                            travel
                        </div>
                        <div className='hero_02_sub_title'>
                            Everywhere, anytime.
                        </div>

                        <div className='hero_02_assets_wrap'>
                            <div className='hero_02_assets_wrap_01'>
                                <img
                                    src={trips_modes_visits_icons_img}
                                    alt='trips_modes_visits_icons_img'
                                ></img>
                            </div>
                        </div>
                    </div>

                    <picture className='hero_2_picture'>
                        <source
                            className='hero_2_img'
                            media='(max-width : 360px)'
                            srcSet={home_page_hero_02_v2}
                        ></source>
                        <source
                            className='hero_2_img'
                            media='(min-width : 361px) and (max-width : 768px), '
                            srcSet={home_page_hero_02_v2}
                        ></source>
                        <source
                            className='hero_2_img'
                            media='(min-width : 769px) and (max-width : 1150px)'
                            srcSet={home_page_hero_02_v2}
                        ></source>
                        <source
                            className='hero_2_img'
                            media='(min-width : 1151px) and (max-width : 1439px)'
                            srcSet={home_page_hero_02_v2}
                        ></source>
                        <source
                            className='hero_2_img'
                            media='(min-width : 1440px)'
                            srcSet={home_page_hero_02_v2}
                        ></source>
                        <img
                            className='hero_2_img'
                            alt={`hero_2`}
                        ></img>
                    </picture>
                </div>
            </div>

            <div
                id='hero_04_box_wrap'
                className={`hero_04_box_wrap`}
            >
                <div
                    id='hero_04_wrap'
                    className={`hero_04_wrap`}
                >
                    <div
                        id='hero_04_content_wrap'
                        className='hero_04_content_wrap fadeIn'
                    >
                        <div className='hero_03_title'>
                            Redeem miles
                        </div>
                        <div className='miles_ui_screen_larger_than_1150'>
                            <div className='hero_03_sub_title'>
                                For cash, gift cards, and donations
                            </div>
                        </div>

                        <div className='miles_ui_screen_768_to_1150'>
                            <div className='hero_03_sub_title'>
                                Redeem miles for cash, gift cards, and
                                donations
                            </div>
                        </div>

                        <div className='miles_ui_screen_less_than_768_px'>
                            <div className='hero_03_sub_title'>
                                Redeem miles for cash, gift cards,
                            </div>
                            <div className='hero_03_sub_title'>
                                and donations
                            </div>
                        </div>

                        <img
                            className='redeem_miles_icon_img'
                            src={redeem_miles_icon_v2_img}
                            alt='icon_green_ship_img'
                        ></img>
                    </div>

                    <picture className='hero_3_picture'>
                        <source
                            className='hero_3_img'
                            media='(max-width : 360px)'
                            srcSet={home_page_hero_3_v2}
                        ></source>
                        <source
                            className='hero_3_img'
                            media='(min-width : 361px) and (max-width : 768px), '
                            srcSet={home_page_hero_3_v2}
                        ></source>
                        <source
                            className='hero_3_img'
                            media='(min-width : 769px) and (max-width : 1150px)'
                            srcSet={home_page_hero_3_v2}
                        ></source>
                        <source
                            className='hero_3_img'
                            media='(min-width : 1151px) and (max-width : 1439px)'
                            srcSet={home_page_hero_3_v2}
                        ></source>
                        <source
                            className='hero_3_img'
                            media='(min-width : 1440px)'
                            srcSet={home_page_hero_3_v2}
                        ></source>
                        <img
                            className='hero_3_img'
                            alt={`hero_3`}
                        ></img>
                    </picture>
                </div>
            </div>

            <Brands_you_love
                brands_you_love_v3_2500_img={brands_you_love_img}
                brands_you_love_v3_600_img={brands_you_love_img}
                brands_you_love_v3_360_img={brands_you_love_img}
            />

            <We_care_your_privacy
                we_care_your_privacy_img={we_care_your_privacy_img}
            />

            <We_are_highlighted
                we_are_highlighted_techCrunch_img={
                    we_are_highlighted_techCrunch_img
                }
                we_are_highlighted_Forbes_img={
                    we_are_highlighted_Forbes_img
                }
                we_are_highlighted_fastCompany_img={
                    we_are_highlighted_fastCompany_img
                }
                we_are_highlighted_badcredit_img={
                    we_are_highlighted_badcredit_img
                }
                we_are_highlighted_card_rates_img={
                    we_are_highlighted_card_rates_img
                }
                we_are_highlighted_curbed_img={
                    we_are_highlighted_curbed_img
                }
                we_are_highlighted_geekwire_img={
                    we_are_highlighted_geekwire_img
                }
                we_are_highlighted_greenBiz_img={
                    we_are_highlighted_greenBiz_img
                }
                we_are_highlighted_mashable_img={
                    we_are_highlighted_mashable_img
                }
                we_are_highlighted_nast_traveler_img={
                    we_are_highlighted_nast_traveler_img
                }
                we_are_highlighted_ozy_img={we_are_highlighted_ozy_img}
                we_are_highlighted_skift_img={
                    we_are_highlighted_skift_img
                }
                we_are_highlighted_the_points_guy_img={
                    we_are_highlighted_the_points_guy_img
                }
                we_are_highlighted_the_verge_img={
                    we_are_highlighted_the_verge_img
                }
                we_are_highlighted_wired_img={
                    we_are_highlighted_wired_img
                }
                logo_2022_wired_v1={logo_2022_wired_v1}
                logo_2022_greekwire_v1={logo_2022_greekwire_v1}
                logo_2022_smartCitiesDrive_v1={
                    logo_2022_smartCitiesDrive_v1
                }
                logo_2022_metro_v1={logo_2022_metro_v1}
                logo_2022_massTransit_v1={logo_2022_massTransit_v1}
                logo_2022_techCrunch_v1={logo_2022_techCrunch_v1}
                logo_2022_intelligentTransport_v1={
                    logo_2022_intelligentTransport_v1
                }
                logo_2022_KQED_v1={logo_2022_KQED_v1}
                logo_2022_eastCountyToday_v1={
                    logo_2022_eastCountyToday_v1
                }
                carousel_arrow_left_img={carousel_arrow_left_img}
                carousel_arrow_right_img={carousel_arrow_right_img}
            />

            <Our_users_love
                our_users_love_avatar_01_img={
                    our_users_love_avatar_01_img
                }
                our_users_love_avatar_02_img={
                    our_users_love_avatar_02_img
                }
                our_users_love_avatar_03_img={
                    our_users_love_avatar_03_img
                }
                our_users_love_avatar_04_img={
                    our_users_love_avatar_04_img
                }
                our_users_love_avatar_05_img={
                    our_users_love_avatar_05_img
                }
                our_users_love_avatar_06_img={
                    our_users_love_avatar_06_img
                }
                our_users_love_avatar_07_img={
                    our_users_love_avatar_07_img
                }
                our_users_love_avatar_08_img={
                    our_users_love_avatar_08_img
                }
                our_users_love_avatar_09_img={
                    our_users_love_avatar_09_img
                }
                our_users_love_avatar_10_img={
                    our_users_love_avatar_10_img
                }
                our_users_love_avatar_11_img={
                    our_users_love_avatar_11_img
                }
                our_users_love_avatar_12_img={
                    our_users_love_avatar_12_img
                }
                carousel_arrow_left_img={carousel_arrow_left_img}
                carousel_arrow_right_img={carousel_arrow_right_img}
                star_icon_green_img={star_icon_green_img}
                logo_apple_dark_img={logo_apple_dark_img}
                logo_android_dark_img={logo_android_dark_img}
            />

            <Growing_fast
                growing_fast_01_img={growing_fast_01_img}
                growing_fast_02_img={growing_fast_02_img}
                growing_fast_03_img={growing_fast_03_img}
                growing_fast_04_img={growing_fast_04_img}
            />

            <div className='miles_ui_home_pre_footer_blankSpace'></div>

            <Pre_footer_hero
                apple_store_icon_img={apple_store_icon_img}
                google_play_icon_img={google_play_icon_img}
                star_icon_img={star_icon_img}
                icon_star_half_img={icon_star_half_img}
                apple_store_src={apple_store_src}
                google_play_store_src={google_play_store_src}
                className='miles_ui_pre_footer_commonSize'
            />
        </Styled_home_wrap>
    );
});

export default MilesWeb_HomePage;
