import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import {
  Styled_body_wrap,
  StyledCard,
} from "./styles";
import { useTranslation } from 'react-i18next';
import { useCountry } from "../../../utils/milesUtils";

const {
  partner_page_assets: assets,
} = config;

interface cardProps {
  card_wrap_className: string;
  imgSrc: string;
  imgClassName: string;
  title: string | JSX.Element;
  titleClassName: string;
}

export const Card: React.FC<cardProps> = ({
  card_wrap_className,
  imgSrc,
  imgClassName,
  title,
  titleClassName
}) => {
  let country = useCountry();
  return (
    <StyledCard className={card_wrap_className} key={card_wrap_className} country={country}>
      <img className={imgClassName} src={imgSrc} alt={`${imgSrc}`} />
      <div className={titleClassName}>{title}</div>
    </StyledCard>
  );
};


const Component: React.FC<{}> = observer(() => {
  let country = useCountry();
  const { t } = useTranslation();

  const CARDS_INFO = [
    // card 01
    {
      card_wrap_className: 'card_wrap_01',
      imgSrc: assets.drive_success_01_img,
      imgClassName: 'drive_success_img_01 miles_ui_screen_1440',
      title: <>{t('partner_page_drive_success_text_001_01')}<br/>{t('partner_page_drive_success_text_001_02')}</>,
      titleClassName: 'drive_success_title_01 card_title'
    },
    // card 02
    {
      card_wrap_className: 'card_wrap_02',
      imgSrc: assets.drive_success_02_img,
      imgClassName: 'drive_success_img_02',
      title: t('partner_page_drive_success_text_002'),
      titleClassName: 'drive_success_title_02 card_title'
    },
    // card 03
    {
      card_wrap_className: 'card_wrap_03',
      imgSrc: assets.drive_success_03_img,
      imgClassName: 'drive_success_img_03',
      title: t('partner_page_drive_success_text_003'),
      titleClassName: 'drive_success_title_03 card_title'
    },
    // card 04
    {
      card_wrap_className: 'card_wrap_04',
      imgSrc: assets.drive_success_04_img,
      imgClassName: 'drive_success_img_04',
      title: t('partner_page_drive_success_text_004'),
      titleClassName: 'drive_success_title_04 card_title'
    },
    // card 05
    {
      card_wrap_className: 'card_wrap_05',
      imgSrc: assets.drive_success_05_img,
      imgClassName: 'drive_success_img_05',
      title: t('partner_page_drive_success_text_005'),
      titleClassName: 'drive_success_title_05 card_title'
    },
    // card 06
    {
      card_wrap_className: 'card_wrap_06',
      imgSrc: assets.drive_success_06_img,
      imgClassName: 'drive_success_img_06',
      title: t('partner_page_drive_success_text_006'),
      titleClassName: 'drive_success_title_06 card_title'
    },
  ]

  let cardsEle: ReactNode[] = []

  CARDS_INFO.map((card, index) => {
    cardsEle.push(
      <Card
        card_wrap_className={card.card_wrap_className}
        imgSrc={card.imgSrc}
        imgClassName={card.imgClassName}
        title={card.title}
        titleClassName={card.titleClassName}
        key={card.imgClassName}
      />
    )
  })

  return (
    <>
      <Styled_body_wrap country={country} className="partner_section_drive_success">
        <div className="main_container">
          <div className="drive_success_title">{t('partner_page_drive_success_title_text_001')}</div>
          <div className="drive_success_sub_title">
          {t('partner_page_drive_success_title_text_002')}
          </div>
          <div className="drive_success_cards_wrap">
            {cardsEle}
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
