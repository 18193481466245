import { commonIcon } from './common/commonIcon';


const config = {
    appStoreLinkBaseUrl: '',  // no RAF support
    miles_tenant_logo: "https://getmiles.s3.amazonaws.com/assets/web_v2/jp_web/password_reset/edsp_password_reset_logo/Noruku+Logo%402x.png",
    miles_share_reward_page_logo: `https://connectiq-edsp-prod-getmiles.s3.amazonaws.com/assets/web_v2/common_icon/edsp_reward_share_logo_2021_v3/Noruku+Logo%402x.png`,
    miles_logo_white: require('src/assets/commonImg/logo_wm_h_white@3x.png'),
    illustrations_gift: `https://connectiq-edsp-prod-getmiles.s3.amazonaws.com/assets/web_v2/reward_share/edsp_reward_share_hero_01_2021_v1/graphic_raf_share%403x.png`,
    download_text: `Download the Miles app`,
    share_text: `Earn miles on all your travel`,
    redeem_rewards_text: `Use miles to redeem rewards`,
    apple_store_src: "https://apps.apple.com/jp/app/id1584227032",
    google_play_store_src: "https://play.google.com/store/apps/details?id=connectiq.norukumiles.app",
    listItemContent_1: `Open the Miles app from your mobile phone`,
    apple_store_icon: `https://connectiq-edsp-prod-getmiles.s3.amazonaws.com/assets/web_v2/reward_share/edsp_reward_share_ios_logo_v1/Group+560%403x.png`,
    google_play_store_icon: `https://connectiq-edsp-prod-getmiles.s3.amazonaws.com/assets/web_v2/reward_share/edsp_reward_share_android_logo_v1/Group+561%403x.png`,
    reward_share_download_text: `Download the Miles app`,
    reward_share_share_text: `Earn miles on all your travel`,
    reward_share_redeem_rewards_text: `Use miles to redeem rewards`,
    reward_share_title_width_on_1150: `605px`,
    reward_share_title_width_on_600: `335px`,
    reward_share_title_width_on_360: `295px`,
    currentSupportEmailAddress: `support-noruku@getmiles.com`,
    topLogo: `https://getmiles.s3.amazonaws.com/assets/web_v2/noruku_web/noruku_logo_2500_v3/1440+Logo%402x.png`,
    bgImgArr: [
        `https://getmiles.s3.amazonaws.com/assets/web_v2/noruku_web/noruku_web_v2_preference_top_bg_2500/group-2%402x.png`,
        `https://getmiles.s3.amazonaws.com/assets/web_v2/noruku_web/noruku_web_v2_preference_top_bg_1280/group-2%402x.png`,
        `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_preference_top_bg_mobile_v2_2022/Group+10%402x.png`,
    ],
    primary_color_01: "#fd902b",
    primary_color_02: "#fd902b",
    preference_sad_face_dog: `https://getmiles.s3.amazonaws.com/assets/web_v2/noruku_web/noruku_preference_sad_face_dog/asset%402x.png`,
    preference_re_subscribe_thankyou_bg: `https://getmiles.s3.amazonaws.com/assets/web_v2/noruku_web/noruku_preference_re_subscribe_thankyou_bg/resub%402x.png`,
    confirmation_10_days_text_note_description_margin: `23px auto 24px auto`,

    fontFamily_Gilroy_Medium: `Noto Sans JP Medium, Gilroy-Medium`,
    fontFamily_Gilroy_SemiBold: `Noto Sans JP Bold, Gilroy-SemiBold`,
    fontFamily_Gilroy_Regular: `Noto Sans JP, Gilroy-Regular`,

    password_reset_001: `新しいパスワードを設定する`,
    password_reset_002: `新しいパスワードをご設定ください。`,
    password_reset_003: `このページを更新しないでください。`,
    password_reset_004: `パスワード`,
    password_reset_005: `非表示`,
    password_reset_006: `表示`,
    password_reset_007: `パスワードは、少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`,
    password_reset_008: `パスワードは、8文字以上で少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`,
    password_reset_009: `パスワード（再入力）`,
    password_reset_010: `入力されたパワワードが一致していません`,
    password_reset_011: `パスワードを設定`,
    password_reset_012: `パスワードの再設定が完了しました`,
    password_reset_013: `パスワードの更新が完了しました。その他、ご不明な点は`,
    password_reset_014: `ヘルプセンター`,
    password_reset_014_01: `をご利用ください`,
    password_reset_015: `パスワード再設定エラー`,
    password_reset_016: `パスワードの再設定中にエラーが発生しました。その他、ご不明な点は`,
    password_reset_017: `ヘルプセンター`,
    password_reset_017_01: `をご利用ください`,
    password_reset_018: `リンクの期限切れ`,
    password_reset_019: `パスワード再設定リンクの期限が切れています。アプリから再設定の手続きを再度お試しください。その他、ご不明な点は`,
    password_reset_020: `ヘルプセンター`,
    password_reset_020_01: `をご利用ください`,
    password_reset_help_url: `https://www.getmiles.com/jp/help/category/account-settings-preferences/how-to-reset-password`,
    
    help_page_success_asset_v1: `https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/help_page/edsp_help_page_success_asset_v1/resub%403x.png`,
    ...commonIcon,
}

export default config;
