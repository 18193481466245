import { commonIcon } from './common/commonIcon';


const config = {

    appStoreLinkBaseUrl: `https://get-uconnect.milesapp.io`,
    miles_tenant_logo: `https://getmiles.s3.amazonaws.com/assets/web_v2/fca_web/fca_miles_share_logo_2021_v1/stellantis_logo_2022%402x.png`,
    miles_share_reward_page_logo: `https://getmiles.s3.amazonaws.com/assets/web_v2/fca_web/fca_miles_share_logo_2021_v1/stellantis_logo_2022%402x.png`,
    // TODO(Lin): Use FCA logo here.
    miles_logo_white: require('src/assets/commonImg/logo_wm_h_white@3x.png'),
    illustrations_gift: require('src/assets/FCA/graphic-raf-share@3x.png'),
    download_text: `Download the Stellantis Miles app`,
    share_text: `Earn miles on all your travel`,
    redeem_rewards_text: `Use miles to redeem rewards`,
    apple_store_src: "https://apps.apple.com/us/app/uconnect-miles/id1534053207",
    google_play_store_src: "https://play.google.com/store/apps/details?id=connectiq.fcamiles.app",
    listItemContent_1: `Open the Stellantis Miles app from your mobile phone`,
    apple_store_icon: require('src/assets/FCA/appStoreIcon/apple_app_store_icon@3x.png'),
    google_play_store_icon: require('src/assets/FCA/appStoreIcon/google_play_store_icon@3x.png'),
    reward_share_download_text: `Download the Stellantis Miles app`,
    reward_share_share_text: `Earn miles for your travel & commute`,
    reward_share_redeem_rewards_text: `Use miles to redeem exclusive rewards`,
    reward_share_title_width_on_1150: `647px`,
    reward_share_title_width_on_600: `327px`,
    reward_share_title_width_on_360: `327px`,
    currentSupportEmailAddress: `support-stellantis@getmiles.com`,
    topLogo: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/fca_unsubscribe_top_logo_v2_2022/stellantis_logo_2022%403x.png`,
    bgImgArr: [
      `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/fca_web_v2_preference_top_bg_1440/Group+10%402x.png`,
      `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/fca_web_v2_preference_top_bg_1280/hero_small%402x.png`,
      `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/fca_web_v2_preference_top_bg_mobile/hero_medium%402x.png`,
    ],
    primary_color_01: "#00aeef",
    primary_color_02: "#0091C7",
    preference_sad_face_dog: "https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/fca_preference_sad_face_dog_v1_2022/asset%402x.png",
    preference_re_subscribe_thankyou_bg: "https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/fca_preference_re_subscribe_thankyou_bg_v1_2022/resub%402x.png",
    confirmation_10_days_text_note_description_margin: `23px auto 0 auto`,  // no unsubscribe support

    fontFamily_Gilroy_Medium: `Gilroy-Medium`,
    fontFamily_Gilroy_SemiBold: `Gilroy-SemiBold`,
    fontFamily_Gilroy_Regular: `Gilroy-Regular`,

    password_reset_001: `Create a new password`,
    password_reset_002: `Please choose a new password for your Stellantis Miles account. Do not refresh this page.`,
    password_reset_003: ``,
    password_reset_004: `Password`,
    password_reset_005: `hide`,
    password_reset_006: `show`,
    password_reset_007: `Must have atleast 1 special character (e.g. - @#$%!^&) `,
    password_reset_008: `At least 1 special character (e.g. - @#$%!^&) and 8 or more characters`,
    password_reset_009: `Confirm Password`,
    password_reset_010: `Password must match`,
    password_reset_011: `Reset Password`,
    password_reset_012: `Password reset successful`,
    password_reset_013: `Your password was successfully updated. If you have any other questions, please contact `,
    password_reset_014: `support-stellantis@getmiles.com.`,
    password_reset_014_01: ``,
    password_reset_015: `Password reset error`,
    password_reset_016: `Unable to reset your password at this time. Please try again from your app. If you have any other questions, please contact `,
    password_reset_017: `support-stellantis@getmiles.com.`,
    password_reset_017_01: ``,

    password_reset_018: `Link expired`,
    password_reset_019: `We're sorry, your password reset link has expired. Please submit another password reset request from the app. For more assistance, please contact `,
    password_reset_020: `support-stellantis@getmiles.com.`,
    password_reset_020_01: ``,
    password_reset_help_url: `mailto:support-stellantis@getmiles.com`,

    help_page_success_asset_v1: ``, // not support
    ...commonIcon,
}

export default config;
