import React from "react";
import { observer } from "mobx-react";
import { store } from "../../Store";
import { useCountry } from "../../utils/milesUtils";

import { StyledDivider } from "./styles";

interface Props {
  text: string;
}

export const Divider: React.FC<Props> = observer(({
  text,
}) => {
  let country = useCountry();
  return (
    <StyledDivider isJP={country === "jp" || process.env.REACT_APP_MILES_TENANT === "edsp"}>
      <div className="divider-line-1"></div>
      <div className={`divider-text`}>{text}</div>
      <div className="divider-line-2"></div>
    </StyledDivider>
  );
})
