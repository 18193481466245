import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0 auto;
  padding: 0; 

  .miles_ui_about_page_leadership_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .miles_ui_about_page_leadership_container {
    width: 154px;
    height: 235px;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }

  .oval_img_box{
    width: 154px;
    height: auto;
    text-align: center;
  }

  .leadership_oval_img{
    width: 154px;
    height: 154px;
  }

  .leader_name {
    width: 154px;
    height: 29px;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 29px;
    letter-spacing: 0.7px;
    color: #2d2f3b;
    margin-top: 8px;
  }

  .leader_designation{
    width: 154px;
    height: 50px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0.7px;
    color: #2d2f3b;
    margin-top: 7px;
  }

  .ciq_spacer1{
    margin-right: 86px;
  }
 
  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_about_page_leadership_container {
      width: 154px;
      height: 246px;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
    }

    .leadership_oval_img{
      width: 154px;
      height: 154px;
    }

    .leader_name {
      width: 154px;
      height: 29px;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
      margin-top: 24px;
    }

    .leader_designation{
      width: 154px;
      height: 50px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: 0.8px;
      color: #2d2f3b;
    }

    .ciq_spacer1{
      margin-top: 90px;
      margin-right: 0px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_leadership_container {
      width: 154px;
      height: 246px;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
    }

    .leadership_oval_img{
      width: 154px;
      height: 154px;
    }

    .leader_name {
      width: 154px;
      height: 29px;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
      margin-top: 24px;
    }

    .leader_designation{
      width: 154px;
      height: 50px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: 0.8px;
      color: #2d2f3b;
    }

    .ciq_spacer1{
      margin-top: 90px;
      margin-right: 0px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .miles_ui_about_page_leadership_container {
      width: 154px;
      height: 246px;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
    }

    .leadership_oval_img{
      width: 154px;
      height: 154px;
    }

    .leader_name {
      width: 154px;
      height: 29px;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
      margin-top: 24px;
    }

    .leader_designation{
      width: 154px;
      height: 50px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: 0.8px;
      color: #2d2f3b;
    }

    .ciq_spacer1{
      margin-top: 90px;
      margin-right: 0px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .miles_ui_about_page_leadership_container {
      width: 154px;
      height: 246px;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
    }

    .leadership_oval_img{
      width: 154px;
      height: 154px;
    }

    .leader_name {
      width: 154px;
      height: 29px;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
      margin-top: 24px;
    }

    .leader_designation{
      width: 154px;
      height: 50px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: 0.8px;
      color: #2d2f3b;
    }

    .ciq_spacer1{
      margin-top: 90px;
      margin-right: 0px;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .miles_ui_about_page_leadership_container {
      width: 154px;
      height: 246px;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
    }

    .leadership_oval_img{
      width: 154px;
      height: 154px;
    }

    .leader_name {
      width: 154px;
      height: 29px;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
      margin-top: 24px;
    }

    .leader_designation{
      width: 154px;
      height: 50px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: 0.8px;
      color: #2d2f3b;
    }

    .ciq_spacer1{
      margin-top: 90px;
      margin-right: 0px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_leadership_container {
      width: 154px;
      height: 246px;
      flex-direction: column;
      align-items: center;
      margin-top: 79.6px;
    }

    .leadership_oval_img{
      width: 154px;
      height: 154px;
    }

    .leader_name {
      width: 154px;
      height: 29px;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
      margin-top: 24px;
    }

    .leader_designation{
      width: 154px;
      height: 50px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: 0.7px;
      color: #2d2f3b;
    }

    .ciq_spacer1{
      margin-right: 86px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;