import React from "react";
import { StyledInput } from "./styles";
import { useCountry } from "../../utils/milesUtils";

interface Props {
  send_downloadLink_phoneErr: boolean;
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type: string;
  inputEl: RefObject<HTMLInputElement>;
}

export const FcaRafDownloadNumberInput: React.FC<Props> = ({
  send_downloadLink_phoneErr,
  placeholder,
  value,
  onChange,
  onKeyDown,
  type,
  inputEl,
}) => {
  let country = useCountry();
  return (
    <StyledInput
      ref={inputEl}
      send_downloadLink_phoneErr={send_downloadLink_phoneErr}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      type={type}
      isJP={country === "jp" || process.env.REACT_APP_MILES_TENANT === "edsp"}
      /> 
  );
};
