import { useLocation } from "react-router-dom";
import { observable, action } from "mobx";
import milesUtils from "./utils/milesUtils";

export type FetchFcaRafReferResponse = {
  first_name: string;
  referral_code: string;
  bonus_miles: string;
};

export type categoriesT = {
  id: number,
  title: string,
  description: string,
  is_subscribed: boolean,
};

export type subscriptionPreferencesProps = {
  groups: { categories: categoriesT[] }[]
}

export type emailUnsubscribeProps = {
  id?: number | null,
  title?: string,
  sub_categories?:  {
    id: number,
    title: string,
  }[],
}

export const NUM_FOOTER_IMAGES = 8;

const IMAGE_FLIP_ORDER = [
  1,
  5,
  3,
  0,
  7,
  4,
  2,
  6,
];

if (IMAGE_FLIP_ORDER.length != NUM_FOOTER_IMAGES) {
  throw new Error("Internal Error");
}

export class Store {
  @observable isSendFcaLink = "waiting";
  @observable state = "ok";
  @observable fca_raf_first_name = "";
  @observable fca_raf_referral_code = "";
  @observable reward_share_referral_code = "FCA123";
  @observable fca_raf_bonus_miles = "";

  @observable fcaRaf_Rewards = [];
  @observable fcaRaf_substituteImages = [];
  @observable fcaRaf_flipOrderIndex = 0;
  @observable fcaRaf_flipOrder = [] as number[];
  @observable fcaRaf_rewardIndex = NUM_FOOTER_IMAGES;
  @observable reward_sharing_brand_name = "";
  @observable reward_sharing_title = "";
  @observable reward_sharing_image = "";
  @observable reward_sharing_brand_image = "";
  @observable reward_sharing_description = "";
  @observable sendDownLoadLinkStatus = "waiting";
  @observable email_preference_sub_category_ids = [];

  @observable password_reset_request_status = 'not_start';

  @observable get_miles_links_status = {
    get_download_link: "waiting",
    get_miles_link: "waiting",
    get_miles_link_on_nav: "waiting",
  }

  @observable ROLE_TYPE = {
    PARTNER: "partner",
    USER: "user",
  };

  @observable partner_page_form_status = {
    pre_footer_form: "not_start",
    dialog_form: "not_start",
  };

  @observable top_banner_notice = {
    body_text: "",
    click_here_url: "",
    show_banner: false,
  };

  @action.bound
  fetch_over_subscription() {
    this.state = "loading";
    return fetch(`/_api/over_subscription`)
      .then((response: Response) => {
        if (response.ok) {return response.json();}
        return Promise.reject(response);
      })
      .then(
        (response) => {
          this.top_banner_notice = response.response;
          // this.state = "ok"; // hide the API err catch
        })
      .catch((response) => {
        // this.state = "error"; // hide the API err catch
      });
  }


  @action update_partner_page_form_status(type: string, statusValue: string) {
    this.partner_page_form_status = {
      ...this.partner_page_form_status,
      [type]: statusValue,
    };
  }

  @action update_miles_links_status(type: string, statusValue: string) {
    this.get_miles_links_status = {
      ...this.get_miles_links_status,
      [type]: statusValue,
    };
  }
  
  @observable subscription_preferences: subscriptionPreferencesProps = {
    groups: []
  }

  @observable email_unsubscribe_info: emailUnsubscribeProps = {}
  
  @observable subscription_category_checkBox_status: Map<number, boolean> = new Map();

  @action update_subscription_preferences(new_subscription_preferences: subscriptionPreferencesProps) {
    this.subscription_preferences = new_subscription_preferences;
  }

  @action update_subscription_category_checkBox_status(new_subscription_category_checkBox_status: any) { // TBC
    this.subscription_category_checkBox_status = new_subscription_category_checkBox_status;
  }

  @observable is_unSubscribed_from_all_status = false;

  @action update_is_unSubscribed_from_all_status(new_is_unSubscribed_from_all_status: boolean) {
    this.is_unSubscribed_from_all_status = new_is_unSubscribed_from_all_status
  }

  @action.bound
  useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  @observable confirmEmailPreferenceUnsubscribeStatus = "waiting";

  @action update_confirmEmailPreferenceUnsubscribeStatus(new_confirmEmailPreferenceUnsubscribeStatus: string) {
    this.confirmEmailPreferenceUnsubscribeStatus = new_confirmEmailPreferenceUnsubscribeStatus;
  }

  @observable param_query = this.useQuery();
  @observable email_param: any = this.param_query.get("email")
  @observable token_param: any = this.param_query.get("token")
  @observable confirmed_param: any = this.param_query.get("confirmed")
  @observable type_param: any = this.param_query.get("type")

  @action.bound
  handleSendDownloadLink_RewardShare(fcaReferInfo: {
    phone_number: string;
    referral_code: string;
    role_type: string;
  }) {
    const { phone_number, referral_code, role_type } = fcaReferInfo;
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_number,
        referral_code,
        role_type
      }),
    };
    this.isSendFcaLink = "sending";
    return fetch("/_api/send_download_link_for_reward_share", patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        if (response.status == 400) return response.json().then((json_data) => Promise.reject(json_data));
        return Promise.reject({ error: { message: response.toString() } });
      })
      .then((response) => {
        if (response) {
          this.isSendFcaLink = "sent";
        } else {
          throw new Error("Error");
        }
      })
      .catch((response) => {
        console.log('catch err', response);
        this.isSendFcaLink = "error";
      });
  }

  @action.bound
  handleSendDownloadLink_Raf(fcaReferInfo: {
    phone_number: string;
    referral_code: string;
    role_type: string;
  }) {
    const { phone_number, referral_code, role_type } = fcaReferInfo;
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_number,
        referral_code,
        role_type
      }),
    };
    this.isSendFcaLink = "sending";
    return fetch("/_api/send_download_link_for_raf", patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        if (response.status == 400) return response.json().then((json_data) => Promise.reject(json_data));
        return Promise.reject({ error: { message: response.toString() } });
      })
      .then((response) => {
        if (response) {
          this.isSendFcaLink = "sent";
        } else {
          throw new Error("Error");
        }
      })
      .catch((response) => {
        console.log('catch err', response);
        this.isSendFcaLink = "error";
      });
  }

  @action.bound
  fetchFcaRafReferInfo() {
    let query = this.useQuery();
    let c_param = query.get("c") || '';
    return fetch(`/_api/raf_refer_info?c=${encodeURIComponent(c_param)}`)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response: FetchFcaRafReferResponse) => {
          let resResult = JSON.parse(JSON.stringify(response));
          this.fca_raf_first_name = resResult.first_name
          this.fca_raf_referral_code = resResult.referral_code
          this.fca_raf_bonus_miles = resResult.bonus_miles
          this.fcaRaf_Rewards = resResult.images.slice(0, NUM_FOOTER_IMAGES);
          this.fcaRaf_substituteImages = resResult.images;
          this.fcaRaf_flipOrder = IMAGE_FLIP_ORDER;
          this.state = "ok";
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  fetchRewardSharingInfo() {
    let query = this.useQuery();
    let offer_id_param = query.get("offer_id") || ''
    let action_param = query.get("action") || ''
    let source_param = query.get("source") || ''
    // if (action_param !== 'reward_detail' || source_param !== 'Share') {
    //   window.location.href = "/";
    // }
    let paramUrl = `?action=${encodeURIComponent(action_param)}&source=${encodeURIComponent(source_param)}&offer_id=${encodeURIComponent(offer_id_param)}`
    return fetch(`/_api/reward_sharing_info${paramUrl}`)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response: FetchFcaRafReferResponse) => {
          let resResult = JSON.parse(JSON.stringify(response));
          this.reward_sharing_brand_name = resResult.brand_name;
          this.reward_sharing_title = resResult.title;
          this.reward_sharing_image = resResult.image;
          this.reward_sharing_brand_image = resResult.brand_image;
          this.reward_sharing_description = resResult.description;
          this.state = "ok";
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  handleSendDownloadLink_RewardSharingPage(fcaReferInfo: {
    phone_number: string;
    role_type: string;
  }) {
    const { phone_number, role_type } = fcaReferInfo;
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_number,
        role_type,
      }),
    };
    this.sendDownLoadLinkStatus = "sending";
    return fetch("/_api/send_download_link_for_reward_sharing_page", patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        if (response.status == 400) return response.json().then((json_data) => Promise.reject(json_data));
        return Promise.reject({ error: { message: response.toString() } });
      })
      .then((response) => {
        if (response) {
          this.sendDownLoadLinkStatus = "sent";
        } else {
          throw new Error("Error");
        }
      })
      .catch((response) => {
        console.log('catch err', response);
        this.sendDownLoadLinkStatus = "error";
      });
  }

  @action.bound
  get_subscribe_status_by_category_id(categoryGroups: { categories: categoriesT[] }[]) {
    let updated_subscription_category_checkBox_status: Map<number, boolean> = new Map();
    categoryGroups.map((item) => {
      item.categories.map((categoryObj) => {
        updated_subscription_category_checkBox_status.set(categoryObj.id, categoryObj.is_subscribed)
      })
    })
    this.subscription_category_checkBox_status = updated_subscription_category_checkBox_status
  }

  @action.bound
  fetchSubscriptionPreferences() {
    this.state = "loading";
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
      }),
    };

    return fetch(`/_api/get_subscription_preferences`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response: subscriptionPreferencesProps) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          this.subscription_preferences = resResult;
          this.get_subscribe_status_by_category_id(resResult.groups)
          this.state = "ok";
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  update_notification_preferences() {
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
        status_by_category_id: store.subscription_category_checkBox_status,
      }),
    };

    return fetch(`/_api/update_notification_preferences`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          if (resResult) {
            this.type_param = "update_preference_by_group";
            this.state = "ok";
          }
          return resResult
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  handle_unsubscribe_all_emails() {
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
      }),
    };

    return fetch(`/_api/unsubscribe_all_emails`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          if (resResult) {
            this.type_param = "update_preference_unsubscribe_all_emails";
            this.state = "ok";
          }
          return resResult
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  handleEmailPreferenceReSubscribeAllEmails() {
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
      }),
    };

    return fetch(`/_api/subscribe_all_emails`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          if (resResult) {
            this.type_param = "subscribe_all_emails";
            this.state = "ok";
          }
          return resResult
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  fetchEmailUnsubscribeInfo() {
    this.state = "loading";
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
      }),
    };

    return fetch(`/_api/get_unsubscribe_info`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          this.email_unsubscribe_info = resResult.category
          this.email_preference_sub_category_ids = resResult.category.sub_categories.map((item: any) => item.id)
          this.state = "ok";
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  handleConfirmEmailUnsubscribe() {
    this.confirmEmailPreferenceUnsubscribeStatus = "loading";
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }

    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
        sub_category_ids: this.email_preference_sub_category_ids,
      }),
    };

    return fetch(`/_api/email_preference_unsubscribe`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          if (resResult.status === "SUCCESS") {
            this.confirmEmailPreferenceUnsubscribeStatus = "ok"
          }
        },
        (error) => {
          if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
          {
            window.location.href = "/jp";
          }
          else
          {
            window.location.href = "/";
          }
          this.confirmEmailPreferenceUnsubscribeStatus = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  handleEmailPreferenceReSubscribe() {
    this.state = "loading";
    if (!this.email_param || !this.token_param) {
      if(process.env.REACT_APP_MILES_TENANT == "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp")
      {
        window.location.href = "/jp";
      }
      else
      {
        window.location.href = "/";
      }
    }
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.email_param,
        token: this.token_param,
        sub_category_ids: this.email_preference_sub_category_ids,
      }),
    };

    return fetch(`/_api/email_preference_subscribe`, patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(
        (response) => {
          let resResult = JSON.parse(JSON.stringify(response)).response;
          if (resResult.status === "SUCCESS") {
            this.type_param = "resubscribe";
            this.state = "ok";
          }
          return resResult
        },
        (error) => {
          window.location.href = "/";
          this.state = "error";
          console.log(error);
        }
      );
  };

  @action.bound
  fetchWithTimeout(url: string, options: any, timeout = 5000) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('timeout')), timeout)
        )
    ]);
}

  @action.bound
  handleSendDownloadLink_HomePage(phone_number: string, typeName: string) {
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_number,
      }),
    };
    this.get_miles_links_status = {
      ...this.get_miles_links_status,
      [typeName]: "sending",
    };
    return this.fetchWithTimeout("/_api/send_download_link_for_home_page", patchMethod, 5000)
      .then((response: any) => {
        if (response.ok) return response.json();
        if (response.status == 400) return response.json().then((json_data: any) => Promise.reject(json_data));
        return Promise.reject({ error: { message: response.toString() } });
      })
      .then((response: any) => {
        if (response.response === true) {
          this.get_miles_links_status = {
            ...this.get_miles_links_status,
            [typeName]: "sent",
          };
        } else {
          this.get_miles_links_status = {
            ...this.get_miles_links_status,
            [typeName]: "server_error",
          };
          throw new Error("Error");
        }
      })
      .catch((response: any) => {
        console.log('catch err', response);
        this.get_miles_links_status = {
          ...this.get_miles_links_status,
          [typeName]: response.message === "timeout" ? "timeout" : "server_error",
        };
      });
  }

  @action.bound
  handleSendDownloadLink_HomePage_JP(email: string, typeName: string) {
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
      }),
    };
    this.get_miles_links_status = {
      ...this.get_miles_links_status,
      [typeName]: "sending",
    };
    return this.fetchWithTimeout("/_api/send_download_link_for_home_page_JP", patchMethod, 50000)
      .then((response: any) => {
        if (response.ok) return response.json();
        if (response.status == 400) return response.json().then((json_data: any) => Promise.reject(json_data));
        return Promise.reject({ error: { message: response.toString() } });
      })
      .then((response: any) => {
        if (response.response === 'success' || response.response === true) {
          this.get_miles_links_status = {
            ...this.get_miles_links_status,
            [typeName]: "sent",
          };
        } else {
          this.get_miles_links_status = {
            ...this.get_miles_links_status,
            [typeName]: "server_error",
          };
          throw new Error("Error");
        }
      })
      .catch((response: any) => {
        console.log('catch err', response);
        this.get_miles_links_status = {
          ...this.get_miles_links_status,
          [typeName]: response.message === "timeout" ? "timeout" : "server_error",
        };
      });
  }

  @action.bound
  fetchPasswordResetInfo() {
    let query = this.useQuery();
    let email_param = query.get("email") || '';
    let token_param = query.get("token") || '';
    return fetch(`/_api/get_password_reset?email=${encodeURIComponent(email_param)}&token=${encodeURIComponent(token_param)}`)
      .then((response) => {
        if (response.ok) return response.json();
        milesUtils.debug_log("debug_log: FIRST response", response);

        return Promise.reject(response);
      })
      .then(
        (response: any) => {
          let resResult = JSON.parse(JSON.stringify(response.response));
          milesUtils.debug_log("debug_log: resResult >>> ", resResult);
          
          if (resResult.code === 200) {
            this.password_reset_request_status = "not_start";
          } else {
            this.password_reset_request_status = "err";
          }

          if (!email_param || !token_param) {
            this.password_reset_request_status = "err";
          }
          
        },
        (error) => {
          this.password_reset_request_status = "err";
          if (error.status === 400) { this.password_reset_request_status = "link_expired"; }
          console.log("error", error);
        }
      );
  };

  @action.bound
  handlePostPasswordResetInfo(password_reset_info: {
    email: string;
    token: string;
    new_password: string;
    confirm_password: string;
  }) {
    const { email, token, new_password, confirm_password } = password_reset_info;
    const patchMethod = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        token,
        new_password,
        confirm_password,
      }),
    };
    return fetch("/_api/post_password_reset", patchMethod)
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject({ error: { message: response.toString() } });
      })
      .then((response) => {
          if (response) {
            this.password_reset_request_status = "success";
          } else {
            this.password_reset_request_status = "err";
          }
      })
      .catch((error) => {
        this.password_reset_request_status = "err";
        if (error.status === 400) { this.password_reset_request_status = "link_expired"; }
        console.log("error", error);
      });
  };

  @action.bound
  handlePartnerFormData(partnerData: {
    name: string,
    email: string,
    phone_number: string,
    business_title?: string,
  }, typeName: string) {
  const patchMethod = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...partnerData,
      "role_type": this.ROLE_TYPE.PARTNER,
    }),
  };
  
  this.partner_page_form_status = {
    ...this.partner_page_form_status,
    [typeName]: "sending",
  };
  return this.fetchWithTimeout("/_api/submit_partner_data", patchMethod, 5000)
    .then((response: any) => {
      if (response.ok) return response.json();
      if (response.status == 400) return response.json().then((json_data: any) => Promise.reject(json_data));
      return Promise.reject({ error: { message: response.toString() } });
    })
    .then((response: any) => {
      if (response.response === true) {
        this.partner_page_form_status = {
          ...this.partner_page_form_status,
          [typeName]: "sent",
        };
      } else {
        this.partner_page_form_status = {
          ...this.partner_page_form_status,
          [typeName]: "server_error",
        };
        throw new Error("Error");
      }
    })
    .catch((response: any) => {
      console.log('catch err', response);
      this.partner_page_form_status = {
        ...this.partner_page_form_status,
        [typeName]: "server_error",
      };
    });
  }
}

export const store = new Store();
