import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const help_page_assets = {
  help_page_category_icon_1: `${MILES_WEB_S3_BASE_URL_V2}help_page/help_page_category_icons/help_page_category_icon_1_v2%402x.png`,
  help_page_category_icon_2: `${MILES_WEB_S3_BASE_URL_V2}help_page/help_page_category_icons/help_page_category_icon_2_v2%402x.png`,
  help_page_category_icon_3: `${MILES_WEB_S3_BASE_URL_V2}help_page/help_page_category_icons/help_page_category_icon_3_v2%402x.png`,
  help_page_category_icon_4: `${MILES_WEB_S3_BASE_URL_V2}help_page/help_page_category_icons/help_page_category_icon_4_v2%402x.png`,
  help_page_category_icon_5: `${MILES_WEB_S3_BASE_URL_V2}help_page/help_page_category_icons/help_page_category_icon_5_v2%402x.png`,
  help_page_category_icon_6: `${MILES_WEB_S3_BASE_URL_V2}help_page/help_page_category_icons/help_page_category_icon_6%402x.png`,
};
