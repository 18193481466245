import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
} = config;

export interface Props {
  question: string;
}

const Component: React.FC<Props> = (props) => {
  return (
    <>
      <Styled_body_wrap>
        <div className="issue_title">
          {props.question}
        </div>
      </Styled_body_wrap>
    </>
  );
};

export default Component;
