import styled from "styled-components";

interface Props {}
export const StyledSadFaceImg = styled("img")<Props>`
  width: 92px;
  height: 92px;
  object-fit: contain;
  display: block;
  margin: 30px auto;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    margin: 23px auto;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    margin: 23px auto;
  }
`;