import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import NavBreadcrumb from "../navigation_path_breadcrumb/index";
import HelpSectionTitle from "../help_section_title/index";
import HelpSectionTitles from "../help_section_titles/index";
import HelpIssueTitle from "../help_issue_title/index";
import HelpIssueTitles from "../help_issue_titles/index";
import HelpSectionAnswer from "../help_section_answer/index";
import { useHelpPageRawData } from '../../../data';
import { store } from "../../../Store";
import { Redirect } from "react-router-dom";
import { urlForSection } from "../../helpPage/urls";
import { useHelpPageMatcher } from "../../helpPage/hooks";
import milesUtils, { useCountry } from "../../../utils/milesUtils";

const {
  help_page_assets: assets,
} = config;

let firstRender = true;


export interface Props {
  user_type: 'user';
}

const Component: React.FC<Props> = observer(({user_type}) => {
  let country = useCountry();
  const {
    category_slug,
    question_slug,
    selected_section,
    selected_question,
  } = useHelpPageMatcher();

  const RAW_DATA = useHelpPageRawData();

  const USER_SECTIONS = RAW_DATA.user;
  const DEFAULT_SECTION_REDIRECT = <Redirect to={urlForSection(USER_SECTIONS[0], country)} />;

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    window.scrollTo({ top: 0, behavior: 'smooth' })
    milesUtils.debug_log("component - helpCategoriesPage -- firstRender");
  }

  milesUtils.debug_log("category_slug", category_slug);
  milesUtils.debug_log("question_slug", question_slug);

  if (category_slug == null || selected_section == null) {
    return DEFAULT_SECTION_REDIRECT;
  }

  const questions = selected_section.question_array;
  return (
    <>
      <Styled_body_wrap>
        <NavBreadcrumb help_category={selected_section.name} question={selected_question ? selected_question.text : ""}/>
        <div className="ciq_root">
          <div className="ciq_container">
            <div className="help_section_title_wrapper">
              <HelpSectionTitle section={selected_section.name} />
            </div>
            <div className="ciq_inner_container">
              <HelpSectionTitles user_type={user_type} selected_section={selected_section}/>
              { (selected_question != null)
                ? <div>
                  <HelpIssueTitle question={selected_question.text}/>
                  <HelpSectionAnswer answer={selected_question.answer_array[0].text}/>
                </div>
                : <HelpIssueTitles questions={questions} selected_section={selected_section} selected_question={selected_question} />
              }
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
