import React from "react";
import { AvatarControls } from '../types';
import config from "../../../../../utils/config";
import { MainWrapStyled } from "./styles";
const {
    carousel_arrow_left_img,
    carousel_arrow_right_img,
} = config;

export interface PreviousAndNextButtonsProps {
    avatarControls: AvatarControls;
}

export const PreviousAndNextButtons = ({avatarControls}: PreviousAndNextButtonsProps) => {
    return (<MainWrapStyled>
        <button className="pre" onClick={avatarControls.slideToPrevious}>
            <div
                className="miles_carousel_wrap_arrow_left miles_ui_large_than_768"
            />
        </button>
        <button className="next" onClick={avatarControls.slideToNext}>
            <div
                className="miles_carousel_wrap_arrow_right miles_ui_large_than_768"
            />
        </button>
        <div className="gradient_box" />
    </MainWrapStyled>);
}
