import styled from "styled-components";
import i18next from 'i18next';
import config from "../../../utils/config";
const {
  primary_color_01,
} = config;

interface Props {}
export const StyledUpdateCancelBtn = styled("button")<Props>`
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
    width: 152px;
    height: 44px;
    border-radius: 43.5px;
    box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
    background-color: ${() => primary_color_01};
    border: ${() => `solid 1px ${primary_color_01}`};
    padding: ${() => i18next.language === "english" ? "10px 28px" : "inherit"};
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
      width: 234px;
      height: 40px;
      margin: 0;
      padding: 8px 34px 8px 35px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: ${() => primary_color_01};
      font-size: 13px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
    }
  
    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      width: 234px;
      height: 40px;
      margin: 0;
      padding: 8px 34px 8px 35px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: ${() => primary_color_01};
      font-size: 13px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
    }
  
    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
      
    }
  
    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }
  
    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }
  
    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
  
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    }
  
    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }
  
    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
    }
`;