import React, { useState, useEffect } from "react";
import { StyledNav, StyledNavWrap } from "./styles";
import GetMilesCTA from "./get_miles_cta";
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

interface Props {
  src: string;
  alt: string;
}

export const MilesWebHeader: React.FC<Props> = ({
  src,
  alt
}) => {

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const handleToggleMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const [showNavBarCTA, setShowNavBarCTA] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < prevPos.y && currPos.y < -400 && !showNavBarCTA) {
      setShowNavBarCTA(true);
    }
    if (currPos.y > prevPos.y && currPos.y > -375 && showNavBarCTA) {
      setShowNavBarCTA(false);
    }
  }, [showNavBarCTA]);

  return (
    <StyledNavWrap>
      <StyledNav id="miles_v2_header" className="navbar navbar-expand-miles navbar-light justify-content-between">
        <div className="container-fluid-wrapper">
          <div className="container-fluid container-height">
            <a className={`navbar-brand ${showNavBarCTA ? 'show_hide_on_less_than_768' : ''}`} href="/jp">
              <img src={src} alt={alt}></img>
            </a>

            <div className="collapse navbar-collapse" id="miles_v2_navbar">
              <div className="navbar-nav">
                <a className="nav-item nav-link first" href="/jp/partner">ビジネスパートナー</a>
                <a className="nav-item nav-link" href="/jp/about">会社情報</a>
                <a className="nav-item nav-link" href="/jp/contact">お問い合わせ</a>
                <a className="nav-item nav-link miles_ui_last_nav_item" href="/jp/jobs">採用情報</a>
              </div>
            </div>
          </div>

            {
              showNavBarCTA
              ? <div className="GetMilesCTA-root"><GetMilesCTA /></div>
              : <div className="miles_jp_header_tip"></div>
            }

          <div className="container-height hamburger_menu_container">
            <button
              id="navbar_toggler"
              className={`navbar-toggler rotate-by-90 ${showMobileMenu ? "mobile_menu_icon_collapse" : "mobile_menu_icon"}`}
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#miles_v2_navbar"
              aria-controls="miles_v2_navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => handleToggleMenu()}
            ></button>
          </div>
        </div>
      </StyledNav>
      <div className="miles_ui_mock_nav" />
    </StyledNavWrap>
  );
};
