export const dialogFormStyle = {
  margin: "0 auto",
  width: "715px",
  height: "630px",
  borderRadius: "15px",
  boxShadow: "0 0 17px 0 #e6e6e8",
  backgroundColor: "#ffffff",
  padding: "0 38px",

  "@media only screen and (max-width : 360px)": {
    width: "330px",
    height: "665px",
    borderRadius: "15px",
    boxShadow: "0 0 17px 0 #e6e6e8",
    backgroundColor: "#ffffff",
    padding: "0 35px 100px 35px",
  },

  "@media only screen and (min-width : 361px) and (max-width : 768px)": {
    width: "330px",
    height: "665px",
    borderRadius: "15px",
    boxShadow: "0 0 17px 0 #e6e6e8",
    backgroundColor: "#ffffff",
    padding: "0 35px",
  },

  "& .partner_page_dialog_header": {
    margin: "50px auto 0 auto",

    "@media only screen and (max-width : 768px)": {
      margin: "33px auto 0 auto",
    },

    "& .partner_page_dialog_header_title": {
      width: "335px",
      height: "20px",
      fontFamily: "Gilroy-Bold",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.63px",
      color: "#2d2f3b",

      "@media only screen and (max-width : 768px)": {
        width: "202px",
        height: "50px",
        fontSize: "16px",
        letterSpacing: "0.5px",
        display: "inline-block",
      },
    },

    "& .partner_page_esc_icon_btn_wrap": {
      width: "22px",
      height: "42px",
      outline: "none",
      border: "none",
      background: "none",
      float: "right",

      "@media only screen and (max-width : 768px)": {
        width: "16px",
        height: "30px",
      },

      "& .partner_page_esc_icon_img": {
        width: "22px",
        height: "42px",

        "@media only screen and (max-width : 768px)": {
          width: "16px",
          height: "30px",
        },
      }
    },
  },

  "& .input_inner_section": {
    margin: 0,
    marginTop: "30px",

    "&.input_box_01": {
      marginTop: "20px",

      "@media only screen and (max-width : 768px)": {
        marginTop: "28px",
      },
    },

    "& .label_text": {
      width: "max-content",
      height: "auto",
      margin: 0,
      fontFamily: "Gilroy-SemiBold",
      fontSize: "12px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.38px",
      color: "#2d2f3b",
      textAlgin: "left",
      display: "block",
    },

    "& .partner_preFooter_form_input": {
      width: "640px",
      height: "48px",
      margin: "0",
      padding: "16px 18px 16px 18px",
      borderRadius: "4px",
      border: "solid 1px #eaeaea",
      backgroundColor: "#ffffff",
      marginTop: "8px",
      outline: "none",
      fontFamily: "Gilroy-SemiBold",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.5px",
      color: "#2d2f3b",

      "@media only screen and (max-width : 768px)": {
        width: "260px",
        fontSize: "14px",
        letterSpacing: "0.44px",
      },

      "&:active, &:focus, &:focus-visible": {
        boxShadow: "0 2px 4px 0 #b1b1b180",
        border: "solid 1px #10af42",
      },

      "&.input_err": {
        border: "solid 1px #ff4949",

        "& :active, & :focus, & :focus-visible": {
          border: "solid 1px #ff4949"
        }
      },
    
      "&::-webkit-input-placeholder": {
        width: "max-content",
        height: "16px",
        fontFamily: "Gilroy-SemiBold",
        fontSize: "16px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.5px",
        color: "#afafb5",

        "@media only screen and (max-width : 768px)": {
          height: "14px",
          fontSize: "14px",
          letterSpacing: "0.44px",
        },
      },
    
      // --- firefox 19+ ---
      "&::-moz-placeholder": {
        width: "max-content",
        height: "16px",
        fontFamily: "Gilroy-SemiBold",
        fontSize: "16px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.5px",
        color: "#afafb5",

        "@media only screen and (max-width : 768px)": {
          height: "14px",
          fontSize: "14px",
          letterSpacing: "0.44px",
        },
      },
    
      // --- ie ----
      "&::-ms-input-placeholder": {
        width: "max-content",
        height: "16px",
        fontFamily: "Gilroy-SemiBold",
        fontSize: "16px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.5px",
        color: "#afafb5",
        
        "@media only screen and (max-width : 768px)": {
          height: "14px",
          fontSize: "14px",
          letterSpacing: "0.44px",
        },
      },
    },

    "& .show_err_info": {
      display: "block",
      width: "max-content",
      height: "auto",
      position: "absolute",
      margin: "0",
      fontFamily: "Gilroy-Medium",
      fontSize: "11px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.45",
      letterSpacing: "0.46px",
      color: "#ff4949",
      marginTop: "2px",
    },

    "& .hide_err_info": {
      display: "none"
    }

  },

  "& .tip_text": {
    width: "max-content",
    height: "auto",
    margin: "0",
    fontFamily: "Gilroy-SemiBold",
    fontSize: "12px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.38px",
    color: "#afafb5",
    marginTop: "29px",

    "@media only screen and (max-width : 768px)": {
      width: "250px",
    },
  },

  "& .partner_page_pre_form_cta_btn": {
    width: "155px",
    height: "40px",
    margin: "0",
    padding: "0",
    borderRadius: "20px",
    backgroundColor: "#10af42",
    marginTop: "25px",
    fontFamily: "Gilroy-Bold",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.44px",
    textAlign: "center",
    color: "#ffffff",
    cursor: "pointer",
    border: "none",
    outline: "none",

    "&.loading_status": {
      cursor: "progress",
    },

    "@media only screen and (max-width : 768px)": {
      width: "196px",
      height: "40px",
      marginLeft: "30px",
    },
  },

  "& .partner_page_pre_footer_form_asset": {
    display: "inline-block",
    width: "250px",
    height: "132px",
    margin: "0 auto",
    marginTop: "100px",
    marginLeft: "193px",

    "@media only screen and (max-width : 768px)": {
      width: "250px",
      height: "132px",
      marginLeft: "5px",
    },
  },

  "& .partner_page_pre_footer_form_success_text": {
    width: "336px",
    height: "auto",
    margin: "0 auto",
    fontFamily: "Gilroy-Regular",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.63",
    letterSpacing: "0.89px",
    textAlign: "center",
    color: "#2d2f3b",
    marginTop: "50px",

    "@media only screen and (max-width : 768px)": {
      width: "270px",
      fontSize: "14px",
      lineHeight: "1.86",
      letterSpacing: "0.78px",
      marginTop: "72px",
    },
  },

  "& .show_err_info": {
    display: "block",
    width: "max-content",
    height: "auto",
    position: "absolute",
    margin: "0",
    fontFamily: "Gilroy-Medium",
    fontSize: "11px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.46px",
    color: "#ff4949",

    "&.submissionErr": {
      marginTop: "4px",

      "@media only screen and (max-width : 768px)": {
        margin: "0 auto",
        marginTop: "10px",
        position: "relative",
      },
    }
  },

  "& .hide_err_info": {
    display: "none"
  }

};
