import styled from "styled-components";

interface Props {}
export const StyledEmailPreferenceMainBody = styled("div")<Props>`
  width: 600px;
  box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
  background-color: #ffffff;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -128px;
  padding: 50px 30px 81px 30px;
  margin-bottom: 130px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    margin-top: -105px;
    width: 300px;
    padding: 20px 17.5px 15px 17.5px;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    margin-top: -105px;
    width: 300px;
    padding: 20px 17.5px 15px 17.5px;
  }
`;