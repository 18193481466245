import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;

  .inner_cta {
    width: 319px;
    height: 64px;
    border-radius: 32px;
    background-color: #10af42;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
  }

  .inner_cta-text {
    line-height: 24px;
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .inner_cta {
      width: 128px;
      height: 40px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
    }

    .inner_cta-text {
      line-height: 24px;
      font-size: 10px;
      letter-spacing: 0.5px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .inner_cta {
      width: 128px;
      height: 40px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
    }

    .inner_cta-text {
      line-height: 24px;
      font-family: Gilroy-SemiBold;
      font-size: 10px;
      letter-spacing: 0.5px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .inner_cta-text {
        line-height: 33px;
        font-family: AvenirNext-DemiBold;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;