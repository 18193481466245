import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import config from "../../../utils/config";
import { useCountry } from "../../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  let country = useCountry();
  return (
    <>
      <Styled_body_wrap isJP={country === "jp" || process.env.REACT_APP_MILES_TENANT === "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp"}>
        <div className="title_text1">{t('about_page_leadership_text_001')}</div>
       {country !== "jp" &&  <div className="title_text2">{t('about_page_leadership_text_002')}</div>}
      </Styled_body_wrap>
    </>
  );
});

export default Component;
