import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { UnsubscribeCategoryList } from "../../components/unsubscribeCategoryList_jre";
import { store } from "../../Store";
import config from "../../utils/config";
import { StyledEmailPreferenceMainBody } from "./StyledEmailPreferenceMainBody";
import { StyledTitle } from "./UnsubscribeStyles/StyledTitle";
import { StyledDividerLine } from "./UnsubscribeStyles/StyledDividerLine";
import { StyledSubTitle } from "./UnsubscribeStyles/StyledSubTitle";
import { StyledUpdateBtnWrap } from "./UnsubscribeStyles/StyledUpdateBtnWrap";
import { StyledUpdateConfirmBtn } from "./UnsubscribeStyles/StyledUpdateConfirmBtn";
import { StyledUpdateCancelBtn } from "./UnsubscribeStyles/StyledUpdateCancelBtn";

import { StyledConfirmedTitle } from "./ConfirmedStyles/StyledTitle";
import { StyledConfirmedSubTitle } from "./ConfirmedStyles/StyledSubTitle";
import { StyledSadFaceImg } from "./ConfirmedStyles/StyledSadFaceImg";
import { StyledConfirmedContentText } from "./ConfirmedStyles/StyledConfirmedContentText";
import { StyledUpdateReSubscribeBtn } from "./ConfirmedStyles/StyledUpdateReSubscribeBtn";
import { StyledHandlePreferenceText } from "./ConfirmedStyles/StyledHandlePreferenceText";
import { StyledConfirmedNoteText } from "./ConfirmedStyles/StyledConfirmedNoteText";

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

let firstRender = true;

const {
  email_preference_backgroundImg_desktop,
  email_preference_backgroundImg_tablet,
  email_preference_backgroundImg_phone,
  miles_logo_white,
  mi_sad_face,
} = config;

const EmailPreferenceUnsubscribe: React.FC<{}> = observer(() => {
  let history = useHistory();

  if (firstRender) {
    store.fetchEmailUnsubscribeInfo();
  }

  useEffect(() => {
    firstRender = false;
  }, []);

  const handleConfirmUnsubscribe = useCallback(
    () => {
      store.handleConfirmEmailUnsubscribe()
  }, [store.confirmEmailPreferenceUnsubscribeStatus]);

  const bgImgArr = [
    email_preference_backgroundImg_desktop,
    email_preference_backgroundImg_tablet,
    email_preference_backgroundImg_phone,
  ]

  const handleCancelUnsubscribe = useCallback(
    () => {
      window.location.href = "/";
  }, []);

  let paramUrlPath = `?email=${encodeURIComponent(store.email_param)}&token=${encodeURIComponent(store.token_param)}`;

  const handleReSubscribe = useCallback(
    () => {
      store.handleEmailPreferenceReSubscribe().then(() => {
        history.push(`/preferences/email/thankyou${paramUrlPath}&type=resubscribe`);
      });
  }, []);

  const handleMangeEmailPreference = useCallback(
    () => {
      history.push(`/preferences/email${paramUrlPath}`);
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      {
        store.email_unsubscribe_info.id ? (
          <>
            {
              store.confirmEmailPreferenceUnsubscribeStatus === "ok" ? (
                <StyledEmailPreferenceMainBody type="confirm">
                  <StyledConfirmedTitle>{t('email_unsubscribe_confirm_box_title')}</StyledConfirmedTitle>
                  
                  {
                    i18next.language === "english" ? (
                      <StyledConfirmedSubTitle>{t('email_unsubscribe_confirm_box_subTitle_1')}{store.email_unsubscribe_info.title}{t('email_unsubscribe_confirm_box_subTitle_2')}</StyledConfirmedSubTitle>
                    ) : (
                      <StyledConfirmedSubTitle>{store.email_unsubscribe_info.title}{t('email_unsubscribe_confirm_box_subTitle_1')}{t('email_unsubscribe_confirm_box_subTitle_2')}</StyledConfirmedSubTitle>
                    )
                  }
                  <StyledSadFaceImg src={mi_sad_face} />
                  <StyledConfirmedContentText>{t('email_unsubscribe_confirm_box_cheer_text')}</StyledConfirmedContentText>
                  <StyledUpdateReSubscribeBtn onClick={handleReSubscribe}>{t('text_reSubscribe')}</StyledUpdateReSubscribeBtn>

                  <StyledHandlePreferenceText>{t('preference_preText')} <a onClick={handleMangeEmailPreference}>{t('text_preference')}</a></StyledHandlePreferenceText>
                  <StyledConfirmedNoteText><b>{t('text_note')}</b>{t('text_note_description')}</StyledConfirmedNoteText>
                </StyledEmailPreferenceMainBody>
              ) : (
                <StyledEmailPreferenceMainBody type="unsubscribe">
                  <StyledSubTitle>{t('email_unsubscribe_initial_box_title')}</StyledSubTitle>
                  <StyledTitle>{store.email_unsubscribe_info.title}</StyledTitle>
                  <StyledDividerLine height="1.2px" bg="#e6e6e8" />
                  <UnsubscribeCategoryList />
                  <StyledUpdateBtnWrap>
                    <StyledUpdateConfirmBtn onClick={handleConfirmUnsubscribe}>{t('text_confirm')}</StyledUpdateConfirmBtn>
                    <StyledUpdateCancelBtn onClick={handleCancelUnsubscribe}>{t('text_cancel')}</StyledUpdateCancelBtn>
                  </StyledUpdateBtnWrap>
                </StyledEmailPreferenceMainBody>
              )
            }
          </>
        ) : null
      }
    </React.Fragment>
  );
});

export default EmailPreferenceUnsubscribe;
