import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils from "../../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../../utils/milesConstant";
import { Styled_body_wrap } from "./styles";

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

interface Props {
  oval_image: string,
  leader_name: string,
  leader_designation: string,
  add_class: string
}

const MilesWeb_AboutPage: React.FC<Props> = observer((props) => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AboutPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AboutPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap>
      <div className="miles_ui_about_page_leadership_root">
        <div  className={`miles_ui_about_page_leadership_container ${props.add_class !== undefined ? props.add_class : ""}`}>
          <div className="oval_img_box">
            <img className="leadership_oval_img" src={props.oval_image} alt={props.oval_image}></img>
          </div>
          <div className="leader_name">{props.leader_name}</div>
          <div className="leader_designation">{props.leader_designation}</div>
        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default MilesWeb_AboutPage;
