import styled from "styled-components";

export const Styled_body_wrap = styled("div")`

  .image_w_bg{
    width: 356px;
    height: 188px;
    padding: 0px;
    margin-top: 144px;
  }

  .image_w_bg_box{
    width: 356px;
    height: 188px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .image_w_bg{
      width: 250px;
      height: 132px;
      padding: 0px;
      margin-top: 211px;
    }

    .image_w_bg_box{
      width: 250px;
      height: 132px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .image_w_bg{
      width: 250px;
      height: 132px;
      padding: 0px;
      margin-top: 211px;
    }

    .image_w_bg_box{
      width: 250px;
      height: 132px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .image_w_bg{
      width: 250px;
      height: 132px;
      padding: 0px;
      margin-top: 211px;
    }

    .image_w_bg_box{
      width: 250px;
      height: 132px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .image_w_bg{
      width: 250px;
      height: 132px;
      padding: 0px;
      margin-top: 211px;
    }

    .image_w_bg_box{
      width: 250px;
      height: 132px;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .image_w_bg{
      width: 250px;
      height: 132px;
      padding: 0px;
      margin-top: 211px;
    }

    .image_w_bg_box{
      width: 250px;
      height: 132px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;