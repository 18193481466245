import React, { useMemo } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap, StyledItem } from "./styles";
import { useTransitionCarousel } from "../../../components/v2/transition_carousel";
import { store } from "../../../Store";

const {
  partner_page_assets: assets,
} = config;

const Component: React.FC<{
  handleClickOpen_partnerForm: () => void;
}> = observer(({
  handleClickOpen_partnerForm,
}) => {
  const items = useMemo(() => [
    <div className="carousel-item card_01 active">

    <StyledItem key={`carousel-item-card_01`}>
      <div className={`top_title_01`}>See what our partners have been able to achieve with Miles</div>
      <div className="content_box content_box_01">
        <div className="text_content_box">
          <img className={`carousel_02_img_01 miles_ui_screen_larger_than_1150`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_01_1440/group%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_01 miles_ui_screen_1280`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_01_1280/group%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_01 miles_ui_screen_1000`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_01_1000/group%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_01 miles_ui_screen_600`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_01_600/group%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_01 miles_ui_screen_360`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_01_360/group-93%402x.png`} alt={`carousel_02_img_02`} />
        </div>
        <button className="CTA_button_on_carousel_02 btn_02 miles_ui_btn_primary" onClick={handleClickOpen_partnerForm}>Learn More</button>

      </div>
    </StyledItem>

    </div>,

    <div className="carousel-item card_02 active">
    <StyledItem key={`carousel-item-card_02`}>
      <div className={`top_title_02`}>See what our partners have been able to achieve with Miles</div>
      <div className="content_box content_box_02">
        <div className="text_content_box">
          <img className={`carousel_02_img_02 miles_ui_screen_larger_than_1150`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_02_1440/group%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_02 miles_ui_screen_1280`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_02_1280/group-3%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_02 miles_ui_screen_1000`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_02_1000/group-2%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_02 miles_ui_screen_600`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_02_600/group-2%402x.png`} alt={`carousel_02_img_02`} />
          <img className={`carousel_02_img_02 miles_ui_screen_360`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_02_360/group-4%402x.png`} alt={`carousel_02_img_02`} />
        </div>
        <button className="CTA_button_on_carousel_02 btn_02 miles_ui_btn_primary" onClick={handleClickOpen_partnerForm}>Learn More</button>

      </div>
    </StyledItem>

    </div>,

    <div className="carousel-item card_03 active">
    <StyledItem key={`carousel-item-card_03`}>
      <div className={`top_title_03`}>See what our partners have been able to achieve with Miles</div>
      <div className="content_box">
        <div className="text_content_box">
          <img className={`carousel_02_img_03 miles_ui_screen_larger_than_1150`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_03_1440/group%402x.png`} alt={`carousel_02_img_03`} />
          <img className={`carousel_02_img_03 miles_ui_screen_1280`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_03_1280/group-2%402x.png`} alt={`carousel_02_img_03`} />
          <img className={`carousel_02_img_03 miles_ui_screen_1000`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_03_1000/group-3%402x.png`} alt={`carousel_02_img_03`} />
          <img className={`carousel_02_img_03 miles_ui_screen_600`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_03_600/group-3%402x.png`} alt={`carousel_02_img_03`} />
          <img className={`carousel_02_img_03 miles_ui_screen_360`} src={`https://getmiles.s3.amazonaws.com/assets/web_v2/partner_page/carousel_02_img_03_360/group-3%402x.png`} alt={`carousel_02_img_03`} />
        </div>
        <button className="CTA_button_on_carousel_02 miles_ui_btn_primary" onClick={handleClickOpen_partnerForm}>Learn More</button>

      </div>
    </StyledItem>
    </div>,

  ].map((item, index) => ({
    id: `slide_${index}`,
    renderItem: item,
  })), []);
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
  } = useTransitionCarousel({items});
  return (
    <Styled_body_wrap className="partner_section_carousel_02_wrap">
      <div className="main_container">
        <div
          id="miles_ui_PartnerPage_carousel_02"
          className="carousel slide carousel-fade"
        >
          <div className="carousel-inner">
            {carouselFragment}
          </div>

          <button
            className="carousel-control-prev carousel_arrow arrow_left"
            type="button"
            onClick={slideToPrevItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

          <button
            className="carousel-control-next carousel_arrow arrow_right"
            type="button"
            onClick={slideToNextItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default Component;
