import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import config from "../../../utils/config";
import milesUtils, { useCountry } from "../../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../../utils/milesConstant";
import LeadershipOvalUnit from "../leadership_oval_unit/index";
import { Styled_body_wrap } from "./styles";

const {
  about_page_assets: assets,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;


const MilesWeb_AboutPage: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  let country = useCountry();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AboutPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AboutPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap isJP={country === "jp"}>
      <div className="miles_ui_about_page_leadership1_root">
        <div className="miles_ui_about_page_leadership1_container">
          {t('about_page_leadership_asset_001') && <LeadershipOvalUnit add_class="ciq_spacer1" oval_image={t('about_page_leadership_asset_001')} leader_name={t('about_page_leadership_asset_001_name')} leader_designation={t('about_page_leadership_asset_001_designation')} />}
          {t('about_page_leadership_asset_002') && <LeadershipOvalUnit add_class="ciq_spacer1" oval_image={t('about_page_leadership_asset_002')} leader_name={t('about_page_leadership_asset_002_name')} leader_designation={t('about_page_leadership_asset_002_designation')} />}
          {t('about_page_leadership_asset_003') && <LeadershipOvalUnit add_class="ciq_spacer1" oval_image={t('about_page_leadership_asset_003')} leader_name={t('about_page_leadership_asset_003_name')} leader_designation={t('about_page_leadership_asset_003_designation')} />}
        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default MilesWeb_AboutPage;
