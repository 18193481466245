import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;


export const Styled_Card = styled("div")`

  &.carousel_main_wrap {
    width: 1090px;
    height: 300px;
    margin: 0 auto;
    padding: 0;
    border-radius: 32px;
    box-shadow: 0 2px 17px 0 #e6e6e8;
    border: solid 1px #fafafa;
    background-color: #ffffff;
    margin-top: 42px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  .highlight_card_leftBox {
    width: 350px;
    height: 300px;
    margin: 0;
    padding: 44px 60.4px 44px 60.8px;
    background-color: #fafafa;
    border-radius: 32px 0 0 32px;

    .logo_01 {
      display: block;
      width: 228.8px;
      height: 212px;
    }
  }

  .highlight_card_rightBox {
    padding: 28px 24px 0 24px;

    .highlight_card_top_title {
      width: auto;
      height: 18px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.56px;
      text-align: left;
      color: #5f5f69;
      margin-left: 4px;
    }

    .highlight_card_divider_line {
      width: 46px;
      height: 4px;
      margin: 2px 0 0 0;
      background-color: #10af42;
    }

    .logo_02 {
      display: block;
      width: 117.6px;
      height: 16px;
      margin-top: 18px;
    }

    .highlight_card_testimonial {
      width: 688px;
      height: auto;
      margin: 24px 0 0 0;
      font-family: Gilroy-ExtraBoldItalic;
      font-size: 40px;
      font-weight: 800;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.5;
      letter-spacing: 1.25px;
      color: #10af42;
    }

  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    &.carousel_main_wrap {
      width: 280px;
      height: 400px;
      margin: 0 auto;
      padding: 0;

      padding: 0 0 16px;
      
      border-radius: 32px;
      box-shadow: 0 2px 17px 0 #e6e6e8;
      border: solid 1px #fafafa;
      background-color: #ffffff;
      margin-top: 42px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
  
    .highlight_card_leftBox {
      width: 280px;
      height: 190px;
      margin: 0;
      padding: 28px 67.4px 28px 68px;
      background-color: #fafafa;
      border-radius: 32px 32px 0 0;
  
      .logo_01 {
        display: block;
        width: 144.6px;
        height: 134px;
      }
    }
  
    .highlight_card_rightBox {
      padding: 12px 24px 0 24px;
  
      .highlight_card_top_title {
        width: auto;
        height: 21px;
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.44px;
        text-align: left;
        color: #5f5f69;
        margin-left: 4px;
      }
  
      .highlight_card_divider_line {
        width: 28px;
        height: 4px;
        margin: 2px 0 0 0;
        background-color: #10af42;
      }
  
      .logo_02 {
        display: block;
        width: 89px;
        height: 12px;
        margin-top: 12px;
      }
  
      .highlight_card_testimonial {
        width: 236px;
        height: auto;
        margin: 20px 0 0 0;
        font-family: Gilroy-ExtraBoldItalic;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: normal;
        color: #10af42;
      }
  
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {

    &.carousel_main_wrap {
      width: 280px;
      height: 400px;
      margin: 0 auto;
      padding: 0;

      padding: 0 0 16px;
      
      border-radius: 32px;
      box-shadow: 0 2px 17px 0 #e6e6e8;
      border: solid 1px #fafafa;
      background-color: #ffffff;
      margin-top: 42px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
  
    .highlight_card_leftBox {
      width: 280px;
      height: 190px;
      margin: 0;
      padding: 28px 67.4px 28px 68px;
      background-color: #fafafa;
      border-radius: 32px 32px 0 0;
  
      .logo_01 {
        display: block;
        width: 144.6px;
        height: 134px;
      }
    }
  
    .highlight_card_rightBox {
      padding: 12px 24px 0 24px;
  
      .highlight_card_top_title {
        width: auto;
        height: 21px;
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.44px;
        text-align: left;
        color: #5f5f69;
        margin-left: 4px;
      }
  
      .highlight_card_divider_line {
        width: 28px;
        height: 4px;
        margin: 2px 0 0 0;
        background-color: #10af42;
      }
  
      .logo_02 {
        display: block;
        width: 89px;
        height: 12px;
        margin-top: 12px;
      }
  
      .highlight_card_testimonial {
        width: 236px;
        height: auto;
        margin: 20px 0 0 0;
        font-family: Gilroy-ExtraBoldItalic;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: normal;
        color: #10af42;
      }
  
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    &.carousel_main_wrap {
      width: 720px;
      height: 300px;
      margin: 0 auto;
      padding: 0;
      border-radius: 32px;
      box-shadow: 0 2px 17px 0 #e6e6e8;
      border: solid 1px #fafafa;
      background-color: #ffffff;
      margin-top: 42px;
      display: flex;
      justify-content: flex-start;
    }
  
    .highlight_card_leftBox {
      width: 288px;
      height: 300px;
      margin: 0;
      padding: 44px 29.4px 44px 29.8px;
      background-color: #fafafa;
      border-radius: 32px 0 0 32px;
  
      .logo_01 {
        display: block;
        width: 228.8px;
        height: 212px;
      }
    }
  
    .highlight_card_rightBox {
      padding: 28px 24px 0 24px;
  
      .highlight_card_top_title {}
  
      .highlight_card_divider_line {}
  
      .logo_02 {}
  
      .highlight_card_testimonial {
        width: 370px;
        height: auto;
        margin: 24px 0 0 0;
        font-family: Gilroy-ExtraBoldItalic;
        font-size: 28px;
        font-weight: 800;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: 0.88px;
        color: #10af42;
      }
  
    }
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;