import React from "react";
import { observer } from "mobx-react";

import {
  FcaEndOfLifeWrap
} from "./styles";

const fca_end_of_life_header = `https://getmiles.miles.assets.getmiles.com/assets/web_v2/fca_web/end_of_life/end_of_life_header%402x.png`;
const fca_to_miles_benefits = `https://getmiles.miles.assets.getmiles.com/assets/web_v2/fca_web/end_of_life/app_benefits%402x.png`;
const fca_support = `https://getmiles.miles.assets.getmiles.com/assets/web_v2/fca_web/end_of_life/support%402x.png`;
const miles_download_app = `https://getmiles.miles.assets.getmiles.com/assets/web_v2/fca_web/end_of_life/download_miles_app_cta%402x.png`;

const FcaEndofLifeAndroid: React.FC<{}> = observer(() => {

  return (
    <React.Fragment>
      <FcaEndOfLifeWrap className="fca_center_align">

        <div className="header">
          <img className="fca_header" src={fca_end_of_life_header} alt="FCA Header"></img>
        </div>
        
        <a href="market://details?id=connectiq.miles.app" target="_blank" rel="noreferrer">
          <div className="download_cta">
            <img className="fca_download_cta" src={miles_download_app} alt="Download Miles App"></img>
          </div>
        </a>

        <div className="benefits">
          <img className="fca_miles_benefits" src={fca_to_miles_benefits} alt="Miles App Benefits"></img>
        </div>

        <a href="mailto:support-stellantis@getmiles.com" target="_blank" rel="noreferrer">
          <div className="support">
            <img className="fca_miles_support" src={fca_support} alt="FCA Support"></img>
          </div>
        </a>
        
      </FcaEndOfLifeWrap>
    </React.Fragment>
  );
});

export default FcaEndofLifeAndroid;
