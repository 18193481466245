import styled from "styled-components";

interface FooterProps {}
export const StyledFooter = styled("footer")<FooterProps>`
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: auto;

  .miles_ui_desktop_only {
    display: block;
  }

  .miles_ui_mobilePhone_only {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .inline_block {
    display: inline-block;
  }

  .pre_footer_img {
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_largerThan_1440/asset-greater-than-1440%402x.png) center no-repeat;
    ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
    "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v2_largerThan_1440/Group+696%403x.png) center no-repeat;"
    }
    background-size: cover;
    width: 100vw;
    height: 635.7px;
　　 background-position: center center;
  }

  .footer_content_wrap {
    margin: -3px 0 0 0; 
    padding: 0;
    width: 100vw;
    height: auto;
    background-color: #2d2f3b;
    box-sizing: border-box;
    padding-top: 30px;

    .footer_content_body {
      margin: 0 auto; 
      width: 1337px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      box-sizing: border-box;
    }

    .footer_content_left {
      width: 524px;

      .miles_logo_white_img, .miles_logo_white_img img {
        width: 153px;
        height: 43px;
      }

      .miles_logo_social_wrap {
        margin-top: 62px;
      }

      .miles_logo_social, .miles_logo_social img {
        width: 45px;
        height: 45px;
        margin-right: 28px;
      }

      .miles_copyright {
        margin-top: 29px;
        font-family: Noto Sans JP;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: 0;
        color: #ffffff;
        height: 60px;
      }
    }

    .footer_content_right {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      box-sizing: border-box;

      a:hover {
        text-decoration: none;
        color: #AFAFB5;
      }

      .footer_content_right_innerItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
      }

      .innerItem_01 {
        width: 270px;
      }
      .innerItem_02 {
        width: 321px;
      }
      .innerItem_03 {
        width: 232px;
      }

      .footer_content_right_innerItem_title {
        height: 40px;
        font-family: Gilroy-SemiBold;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 15px;

        &.disabled_hover:hover {
          color: #ffffff;
        }
      }

      .footer_content_right_innerItem_sub {
        height: 40px;
        font-family: Noto Sans JP;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 13px;
      }
    }
  }

  /* mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-width: 360px;

    .miles_ui_desktop_only {
      display: none;
    }
  
    .miles_ui_mobilePhone_only {
      display: block;

      .inline_block {
        display: inline-block;
      }

      .mobile_pre_footer_img {
        background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_less_than_768/all%402x.png) center no-repeat;
        ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
        "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v3_less_than_768/Group+26%403x.png) center no-repeat;"
        }
        background-size: cover;
        width: 100vw;
        min-width: 360px;
        height: 218px;
      }

      .mobile_footer_content_wrap {
        margin: -3px 0 0 0; 
        padding: 0;
        width: 100vw;
        height: auto;
        background-color: #2d2f3b;
        box-sizing: border-box;
        padding-top: 12.5px;
        min-width: 360px;

        .mobile_footer_content_body {
          margin: 0 auto; 
          width: 269px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          box-sizing: border-box;
        }

        .mobile_footer_text_wrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          box-sizing: border-box;

          .footer_content_right_innerItem {
            display: flex;
            flex-direction: column;

            .inline_block {
              display: inline-block;
            }
          }

          .innerItem_01 {
            width: 172px;
          }

          .innerItem_02 {
            min-width: 97px;
          }

          .footer_content_right_innerItem_title {
            width: 73px;
            height: 40px;
            margin: 0;
            font-family: Noto Sans JP;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 15px;

            &.Business {
              margin-top: 32px;
            }

            &.disabled_hover:hover {
              color: #ffffff;
            }
          }
          
          .footer_content_right_innerItem_sub {
            min-width: max-content;
            height: 20px;
            font-family: Noto Sans JP;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 20px;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 15px;
          }

          a:hover {
            text-decoration: none;
            color: #AFAFB5;
          }
        }
      }

      .mobile_miles_logo_social_wrap {
        margin: 0 auto;
        margin-top: 65px;
        width: max-content;

      }

      .miles_logo_social,
      .miles_logo_social img {
        width: 30px;
        height: 30px;
        margin-right: 19px;

        &.last {
          margin-right: 0;
        }
      }
      .miles_logo_white_img {
        margin: 0 auto;
        margin-top: 52px;
      }

      .miles_logo_white_img, .miles_logo_white_img img {
        width: 107px;
        height: 30px;
      }

      .miles_copyright_01 {
        height: 30px;
        margin: 0 auto;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-top: 20px;
      }

      .miles_copyright_02 {
        height: 30px;
        margin: 0 auto;
        margin-top: 20px;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-bottom: 57px;
      }
  
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_desktop_only {
      display: none;
    }
  
    .miles_ui_mobilePhone_only {
      display: block;

      .inline_block {
        display: inline-block;
      }

      .mobile_pre_footer_img {
        background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_less_than_768/all%402x.png) center no-repeat;
        ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
        "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v3_less_than_768/Group+26%403x.png) center no-repeat;"
        }
        background-size: cover;
        width: 100vw;
        height: 214px;
      }

      .mobile_footer_content_wrap {
        margin: -3px 0 0 0; 
        padding: 0;
        width: 100vw;
        height: auto;
        background-color: #2d2f3b;
        box-sizing: border-box;
        padding-top: 15px;

        .mobile_footer_content_body {
          margin: 0 auto; 
          width: 269px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          box-sizing: border-box;
        }

        .mobile_footer_text_wrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          box-sizing: border-box;

          .footer_content_right_innerItem {
            display: flex;
            flex-direction: column;

            .inline_block {
              display: inline-block;
            }
          }

          .innerItem_01 {
            width: 172px;
          }

          .innerItem_02 {
            min-width: 97px;
          }

          .footer_content_right_innerItem_title {
            width: 73px;
            height: 40px;
            margin: 0;
            font-family: Noto Sans JP;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 15px;

            &.Business {
              margin-top: 32px;
            }

            &.disabled_hover:hover {
              color: #ffffff;
            }
          }
          
          .footer_content_right_innerItem_sub {
            min-width: max-content;
            height: 20px;
            font-family: Noto Sans JP;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 20px;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 15px;
          }

          a:hover {
            text-decoration: none;
            color: #AFAFB5;
          }
        }
      }

      .mobile_miles_logo_social_wrap {
        margin: 0 auto;
        margin-top: 65px;
        width: max-content;

      }

      .miles_logo_social,
      .miles_logo_social img {
        width: 30px;
        height: 30px;
        margin-right: 19px;

        &.last {
          margin-right: 0;
        }
      }
      .miles_logo_white_img {
        margin: 0 auto;
        margin-top: 52px;
      }

      .miles_logo_white_img, .miles_logo_white_img img {
        width: 107px;
        height: 30px;
      }

      .miles_copyright_01 {
        height: 30px;
        margin: 0 auto;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-top: 20px;
      }

      .miles_copyright_02 {
        height: 30px;
        margin: 0 auto;
        margin-top: 20px;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-bottom: 70px;
      }
  
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .pre_footer_img {
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_768_x_1150/asset-1150-1440%402x.png) center no-repeat;
      ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
      "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v2_768_x_1150/Group+683%403x.png) center no-repeat;"
      }
      background-size: cover;
      width: 100vw;
      height: 300px;
    }

    .footer_content_wrap {
      padding-top: 13px;
      padding-bottom: 57px;
  
      .footer_content_body {
        width: 710px;
      }
  
      .footer_content_left {
        width: 352px;
  
        .miles_logo_white_img, .miles_logo_white_img img {
          width: 107px;
          height: 30px;
        }
  
        .miles_logo_social_wrap {
          margin-top: 32px;
        }
  
        .miles_logo_social, .miles_logo_social img {
          width: 30px;
          height: 30px;
          margin-right: 19px;
        }
  
        .miles_copyright {
          margin-top: 32px;
          font-family: Noto Sans JP;
          height: 60px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: normal;
          color: #ffffff;
        }
      }
  
      .footer_content_right {
        .innerItem_01 {
          width: 155px;
        }
        .innerItem_02 {
          width: 155px;
        }
        .innerItem_03 {
          width: 140px;
        }
  
        .footer_content_right_innerItem_title {
          height: 40px;
          font-family: Noto Sans JP;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.5;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 15px;

          &.disabled_hover:hover {
            color: #ffffff;
          }
      }
  
        .footer_content_right_innerItem_sub {
          height: 20px;
          font-family: Noto Sans JP;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 15px;
        }
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .pre_footer_img {
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_1150_x_1440/asset-1150-1440%402x.png) center no-repeat;
      ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
      "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v2_1150_x_1440/Group+26%403x.png) center no-repeat;"
      }
      background-size: cover;
      width: 100%;
      height: 409px;
    }

    .footer_content_wrap {
      padding-top: 121px;
      padding-bottom: 99px;
  
      .footer_content_body {
        width: 1058px;
      }
  
      .footer_content_left {
        width: 352px;
  
        .miles_logo_white_img, .miles_logo_white_img img {}
  
        .miles_logo_social_wrap {}
  
        .miles_logo_social, .miles_logo_social img {}
  
        .miles_copyright {}
      }
  
      .footer_content_right {
        .innerItem_01 {
          width: 270px;
        }
        .innerItem_02 {
          width: 266px;
        }
        .innerItem_03 {
          width: max-content;
        }
  
        .footer_content_right_innerItem_title {}
  
        .footer_content_right_innerItem_sub {}
      }
    }
  }

  /* 1440px Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .pre_footer_img {
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_largerThan_1440/asset-greater-than-1440%402x.png) center no-repeat;
      ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
      "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v2_largerThan_1440/Group+696%403x.png) center no-repeat;"
      }
      background-size: cover;
      width: 1440px;
      height: 464px;
    }

    .footer_content_wrap {
      padding-top: 30px;
      padding-bottom: 83px;
  
      .footer_content_body {
        width: 1337px;
      }
  
      .footer_content_left {
        width: 524px;
  
        .miles_logo_white_img, .miles_logo_white_img img {}
  
        .miles_logo_social_wrap {}
  
        .miles_logo_social, .miles_logo_social img {}
  
        .miles_copyright {}
      }
  
      .footer_content_right {
        .innerItem_01 {
          width: 270px;
        }
        .innerItem_02 {
          width: 321px;
        }
        .innerItem_03 {
          width: 232px;
        }
  
        .footer_content_right_innerItem_title {}
  
        .footer_content_right_innerItem_sub {}
      }
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) and (max-width : 2500px) {
    .pre_footer_img {
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_largerThan_1440/asset-greater-than-1440%402x.png) center no-repeat;
      ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
      "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v2_largerThan_1440/Group+696%403x.png) center no-repeat;"
      }
      background-size: cover;
      width: 100vw;
      height: 462px;
    }

    .footer_content_wrap {
      padding-top: 30px;
      padding-bottom: 84px;
  
      .footer_content_body {
        width: 1337px;
      }
  
      .footer_content_left {
        width: 524px;
  
        .miles_logo_white_img, .miles_logo_white_img img {}
  
        .miles_logo_social_wrap {}
  
        .miles_logo_social, .miles_logo_social img {}
  
        .miles_copyright {}
      }
  
      .footer_content_right {
        .innerItem_01 {
          width: 270px;
        }
        .innerItem_02 {
          width: 321px;
        }
        .innerItem_03 {
          width: 232px;
        }
  
        .footer_content_right_innerItem_title {}
  
        .footer_content_right_innerItem_sub {}
      }
    }
  
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 2500px) and (max-width : 3440px) {
    .pre_footer_img {
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/footer/pre_footer_bg_v3/pre_footer_bg_v3_largerThan_1440/asset-greater-than-1440%402x.png) center no-repeat;
      ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
      "background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/common/edsp_pre_footer_bg_v2_largerThan_1440/Group+696%403x.png) center no-repeat;"
      }
      background-size: cover;
      width: 100vw;
      height: 635.7px;
    }

    .footer_content_wrap {
      padding-top: 30px;
      padding-bottom: 84px;
  
      .footer_content_body {
        width: 1337px;
      }
  
      .footer_content_left {
        width: 524px;
  
        .miles_logo_white_img, .miles_logo_white_img img {}
  
        .miles_logo_social_wrap {}
  
        .miles_logo_social, .miles_logo_social img {}
  
        .miles_copyright {}
      }
  
      .footer_content_right {
        .innerItem_01 {
          width: 270px;
        }
        .innerItem_02 {
          width: 321px;
        }
        .innerItem_03 {
          width: 232px;
        }
  
        .footer_content_right_innerItem_title {}
  
        .footer_content_right_innerItem_sub {}
      }
    }
  
  }
`;