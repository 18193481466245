import styled from "styled-components";

interface Props {}
export const StyledConfirmedContentText = styled("p")<Props>`
  font-family: 'Avenir Next';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.27px;
  color: #2d2f3b;
  width: max-content;
  margin: 30px auto 20px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 270px;
    height: 48px;
    margin: 20px auto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 270px;
    height: 48px;
    margin: 20px auto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;