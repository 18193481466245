import styled from "styled-components";

interface Super_wrap_Props {}
export const Styled_Super_wrap = styled("div")<Super_wrap_Props>`
  width: 100vw;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .title {
    width: auto;
    height: 56px;
    margin: 0;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    position: absolute;
    margin-top: 160px;
  }

  .bg_img {
    width: 879px;
    height: 430px;
    position: absolute;
    margin-top: 125px;
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_working_at_miles@2x.png) center no-repeat;
    background-size: cover;
　　 background-position: center center;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 100vw;
    height: 254px;

    .title {
      width: 240px;
      height: 72px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 0.63px;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 60px;
    }

    .bg_img {
      width: 323px;
      height: 101px;
      margin-top: 154px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/working_at_miles_between_360_and_768@2x.png) center no-repeat;
      background-size: 323px 101px;
    }
  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 100vw;
    height: 274px;

    .title {
      width: 288px;
      height: 84px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 55px;
    }

    .bg_img {
      width: 363px;
      height: 120px;
      margin-top: 154px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/working_at_miles_between_360_and_768@2x.png) center no-repeat;
      background-size: 363px 120px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 100vw;
    height: 515px;

    .title {
      width: auto;
      height: 56px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 135px;
    }

    .bg_img {
      width: 751px;
      height: 415px;
      margin-top: 100px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_working_at_miles_1000@2x.png) center no-repeat;
      background-size: 751px 415px;
    }
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {

  }

  /* Large 1440px Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {

  }

  /* Larger Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
`

