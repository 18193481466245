import styled from "styled-components";

export const FcaEndOfLifeWrap = styled("div")`

    fca_center_align {
        align: center;
        width: 375px;
        height: 1221px;
    }

    div.header {
        width: 352px;
        height: 468px;
        margin: 17.5px 11px 37px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    div.download_cta {
        width: 375px;
        height: 105px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    div.benefits {
        width: 315px;
        height: 369px;
        margin: 37px 32px 38px 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    div.support {
        width: 235px;
        height: 137px;
        margin: 38px 76px 17.5px 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    fca_header {
        width: 352px;
        height: 468px;
        object-fit: contain;
    }

    fca_download_cta {
        width: 375px;
        height: 105px;
        flex-grow: 0;
        object-fit: contain;
    }

    fca_miles_benefits {
        width: 315px;
        height: 369px;
        object-fit: contain;
    }

    fca_miles_support {
        width: 235px;
        height: 137px;
        object-fit: contain;
    }

`;