import React, { useMemo } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap, StyledItem } from "./styles";
import { useTransitionCarousel } from "../../../components/v2/transition_carousel";

const {
  cities_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const items = useMemo(() => [
    <div className="carousel-item card_01 active">

      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img slide_asset carousel_03_01_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_01_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img slide_asset carousel_03_01_img miles_ui_screen_1280`} src={assets.our_partners_say_01_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img slide_asset carousel_03_01_img miles_ui_screen_1000`} src={assets.our_partners_say_01_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img slide_asset carousel_03_01_img miles_ui_screen_600`} src={assets.our_partners_say_01_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img slide_asset carousel_03_01_img miles_ui_screen_360`} src={assets.our_partners_say_01_360_v1} alt={`carousel_img`} />
      </div>

    </div>,

    <div className="carousel-item card_02 active">
      
      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img carousel_03_02_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_02_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_02_img miles_ui_screen_1280`} src={assets.our_partners_say_02_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_02_img miles_ui_screen_1000`} src={assets.our_partners_say_02_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_02_img miles_ui_screen_600`} src={assets.our_partners_say_02_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_02_img miles_ui_screen_360`} src={assets.our_partners_say_02_360_v1} alt={`carousel_img`} />
      </div>

    </div>,
    
    <div className="carousel-item card_03 active">

      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img carousel_03_03_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_03_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_03_img miles_ui_screen_1280`} src={assets.our_partners_say_03_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_03_img miles_ui_screen_1000`} src={assets.our_partners_say_03_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_03_img miles_ui_screen_600`} src={assets.our_partners_say_03_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_03_img miles_ui_screen_360`} src={assets.our_partners_say_03_360_v1} alt={`carousel_img`} />
      </div>

    </div>,

    <div className="carousel-item card_04 active">
          
      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img carousel_03_04_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_04_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_04_img miles_ui_screen_1280`} src={assets.our_partners_say_04_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_04_img miles_ui_screen_1000`} src={assets.our_partners_say_04_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_04_img miles_ui_screen_600`} src={assets.our_partners_say_04_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_04_img miles_ui_screen_360`} src={assets.our_partners_say_04_360_v1} alt={`carousel_img`} />
      </div>

    </div>,

    <div className="carousel-item card_05 active">
          
      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img carousel_03_05_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_05_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_05_img miles_ui_screen_1280`} src={assets.our_partners_say_05_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_05_img miles_ui_screen_1000`} src={assets.our_partners_say_05_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_05_img miles_ui_screen_600`} src={assets.our_partners_say_05_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_05_img miles_ui_screen_360`} src={assets.our_partners_say_05_360_v1} alt={`carousel_img`} />
      </div>

    </div>,

    <div className="carousel-item card_06 active">

      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img carousel_03_06_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_06_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_06_img miles_ui_screen_1280`} src={assets.our_partners_say_06_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_06_img miles_ui_screen_1000`} src={assets.our_partners_say_06_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_06_img miles_ui_screen_600`} src={assets.our_partners_say_06_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_06_img miles_ui_screen_360`} src={assets.our_partners_say_06_360_v1} alt={`carousel_img`} />
      </div>

    </div>,

    <div className="carousel-item card_07 active">

      <div className="carousel_main_wrap">
        <div className="carousel_body_title">What Our Partners Say</div>
        <img className={`carousel_03_img carousel_03_07_img miles_ui_screen_larger_than_1150`} src={assets.our_partners_say_07_1440_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_07_img miles_ui_screen_1280`} src={assets.our_partners_say_07_1280_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_07_img miles_ui_screen_1000`} src={assets.our_partners_say_07_1024_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_07_img miles_ui_screen_600`} src={assets.our_partners_say_07_600_v1} alt={`carousel_img`} />
        <img className={`carousel_03_img carousel_03_07_img miles_ui_screen_360`} src={assets.our_partners_say_07_360_v1} alt={`carousel_img`} />
      </div>

    </div>,

  ].map((item, index) => ({
    id: `slide_${index}`,
    renderItem: item,
  })), []);
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItems,
    activeItem,
  } = useTransitionCarousel({items});

  return (
    <Styled_body_wrap className="partner_section_carousel_02_wrap">
      <div className="main_container">
        <div
          id="miles_ui_PartnerPage_carousel_03"
          className="carousel slide carousel-fade"
        >
          <div className="carousel-indicators">
            <span
              className={activeItem === 0 ? "active" : ""}
              aria-current="true"
              aria-label="Slide 1"
              onClick={slideToItems[0]}
              role="button"
            ></span>
            <span
              className={activeItem === 1 ? "active" : ""}
              onClick={slideToItems[1]}
              aria-label="Slide 2"
              role="button"
            ></span>
            <span
              className={activeItem === 2 ? "active" : ""}
              onClick={slideToItems[2]}
              aria-label="Slide 3"
              role="button"
            ></span>

            <span
              className={activeItem === 3 ? "active" : ""}
              onClick={slideToItems[3]}
              aria-label="Slide 4"
              role="button"
            ></span>

            <span
              className={activeItem === 4 ? "active" : ""}
              onClick={slideToItems[4]}
              aria-label="Slide 5"
              role="button"
            ></span>

            <span
              className={activeItem === 5 ? "active" : ""}
              onClick={slideToItems[5]}
              aria-label="Slide 6"
              role="button"
            ></span>

            <span
              className={activeItem === 6 ? "active" : ""}
              onClick={slideToItems[6]}
              aria-label="Slide 7"
              role="button"
            ></span>
          </div>

          <div className="carousel-inner">
          {carouselFragment}
          </div>

          <button
            className="carousel-control-prev carousel_arrow arrow_left"
            type="button"
            onClick={slideToPrevItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

          <button
            className="carousel-control-next carousel_arrow arrow_right"
            type="button"
            onClick={slideToNextItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default Component;
