import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import smoothscroll from 'smoothscroll-polyfill';
import FcaLanding from "./containers/fca";
import FcaEndofLifeAndroid from "./containers/fca_end_of_life_android";
import FcaEndofLifeIOS from "./containers/fca_end_of_life_ios";
import FCASignInSignUp from "./containers/fca_login_or_signup";
import FcaRaf from "./containers/fcaRaf";
import RAF_v2 from "./containers/RAF_v2";
import RewardSharing from "./containers/rewardSharing";
import RewardSharing_current from "./containers/rewardSharing_v2";
import EmailPreferenceWrap from "./containers/emailPreferenceWrap";
import EmailPreferenceWrap_current from "./containers/emailPreferenceWrap_current";
import EmailPreferenceWrap_jre from "./containers/emailPreferenceWrap_jre";
import EmailPreferenceWrap_jp from "./containers/emailPreferenceWrap_jp";
import HomePage from "./containers/homePage";
import JobsPage from "./containers/jobsPage";
import PartnerPage from "./containers/partnerPage";
import PrivacyPage202406 from "./containers/privacyPage202406";
import CaliforniaPrivacyNoticePage from "./containers/california_privacy_notice";
import SupplementalStatePrivacyNoticePage from "./containers/supplemental_state_privacy_notice";
import HelpPage from "./containers/helpLandingPage";
import HelpCategoriesPage from "./containers/helpCategoriesPage";
import TosPage202406 from "./containers/tosPage202406";
import ContactPage from "./containers/contactPage";
import PressPage from "./containers/pressPage";
import AboutPage from "./containers/aboutPage";
import DevSamples from "./containers/dev_samples";
import NavBug from "./containers/nav_bug";
import Password_reset_router from "./containers/password_reset_router";
import Password_reset_router_jp from "./containers/password_reset_router_jp";
import Account_delete_request_router from "./containers/account_delete_request_router";
import Account_delete_request_router_jp from "./containers/account_delete_request_router_jp";
import { MilesWebHeader as Header } from "./components/v2/header";
import { Footer } from "./components/v2/footer";
import { TopBannerNoticeComponent_hide_show } from "./components/v2/topBannerNoticeComponent_hide_show";
// JP web - start
import HomePage_JP from "./containers/homePage_jp";
import { MilesWebHeader as Header_MJKK } from "./components_jp/header";
import { MilesWebHeader as Header_EDSP } from "./components_jp/header_edsp";
import { Footer as Footer_JP } from "./components_jp/footer";
// JP web - end
import AutoOEMPage from "./containers/autoOEMPage";
import CitiesPage from "./containers/citiesPage";
import NotFoundPage from "./containers/notFound_miles";
import { store } from "./Store";
import config from "./utils/config";
import { useCountry, useHrefLang, useLanguageOnTenant, usePageTracking, usePageTracking_ga4 } from "./utils/milesUtils";
import {
  BodyWrap
} from "./AppStyles";

smoothscroll.polyfill();

const {
  miles_tenant_logo,
  pre_footer_img,
  logo_fb_img,
  logo_ins_img,
  logo_linkedin_img,
  logo_x_img,
  miles_logo_white_img,
} = config;

const App: React.FC = observer(() => {
  // usePageTracking()

  const hreflang = useHrefLang();

  let action_param = store.param_query.get("action") || '';
  let source_param = store.param_query.get("source") || '';
  let type_device_view_param = store.param_query.get("view") || '';

  let country = useCountry();
  let Miles_Header;
  let Miles_Footer;
  let Miles_NotFoundPage: () => JSX.Element;
  
  let languageOnTenant = useLanguageOnTenant();
  switch (languageOnTenant) {
    case 'jp_edsp':
      Miles_Header = <Header_EDSP src={miles_tenant_logo} alt="Miles Tenant EDSP Logo"/>
      Miles_Footer = <Footer_JP pre_footer_img={pre_footer_img} />
      Miles_NotFoundPage = () => <NotFoundPage />
      break;
    case 'jp_mjkk':
      Miles_Header = <Header_MJKK src={miles_tenant_logo} alt="Miles Tenant MJKK Logo"/>
      Miles_Footer = <Footer_JP
        pre_footer_img={pre_footer_img}
        logo_fb_img={logo_fb_img}
        logo_ins_img={logo_ins_img}
        logo_linkedin_img={logo_linkedin_img}
        logo_x_img={logo_x_img}
        miles_logo_white_img={miles_logo_white_img}
      />
      Miles_NotFoundPage = () => <HomePage_JP />
      break;
    default:
      Miles_Header = <Header src={miles_tenant_logo} alt="Miles Tenant Logo"/>;
      Miles_Footer = <Footer
        pre_footer_img={pre_footer_img}
        logo_fb_img={logo_fb_img}
        logo_ins_img={logo_ins_img}
        logo_linkedin_img={logo_linkedin_img}
        logo_x_img={logo_x_img}
        miles_logo_white_img={miles_logo_white_img}
      />
      Miles_NotFoundPage = () => <NotFoundPage />
  }

  let Miles_Header_hide_show = type_device_view_param === "embed" ? null : Miles_Header
  let Miles_Footer_hide_show = type_device_view_param === "embed" ? null : Miles_Footer
  return (
    <div className="app">
      {hreflang}
      <Switch>
        {process.env.REACT_APP_MILES_ENV === "development" && <Route path="/fca/login_or_signup" render={() => <FCASignInSignUp />} />}
        {process.env.REACT_APP_MILES_TENANT === "fca" && <Route path="/web-android-migrate" render={() => <FcaEndofLifeAndroid />} />}
        {process.env.REACT_APP_MILES_TENANT === "fca" && <Route path="/web-ios-migrate" render={() => <FcaEndofLifeIOS />} />}
        <Route path="/fca" render={() => <FcaLanding />} />
        <Route path="/jp/refer" render={() => <RAF_v2 />} />
        <Route path="/refer" render={() => <RAF_v2 />} />
        <Route path="/password/reset" render={() => <Password_reset_router />} />
        <Route path="/preferences/email" render={() => {
          if (process.env.REACT_APP_MILES_TENANT === "jre") {
            return <EmailPreferenceWrap_jre />
          } else {
            return <EmailPreferenceWrap_current />
          }
        }} />
        <Route path="/jp/preferences/email" render={() => <EmailPreferenceWrap_jp />} />
        <Route path="/jp/password/reset" render={() => <Password_reset_router_jp />} />
        <Route path="/account/delete" render={() => {
          if (process.env.REACT_APP_MILES_TENANT === "mjp") {
            return <Account_delete_request_router_jp />
          } else {
            return <Account_delete_request_router />
          }
        }} />
        <Route path="/jp/account/delete" render={() => <Account_delete_request_router_jp />} />
        <Route path="/" render={() => {
          if (action_param !== 'reward_detail' || source_param !== 'Share') {
            return (
              <BodyWrap>
                {Miles_Header_hide_show}
                <TopBannerNoticeComponent_hide_show />
                <Switch>
                  <Route path="/nav_bug" render={() => <NavBug />} />
                  <Route path="/jp/jobs" render={() => <JobsPage />} />
                  <Route path="/jobs" render={() => <JobsPage />} />
                  <Route path="/partner" render={() => <PartnerPage />} />
                  <Route path="/jp/partner" render={() => <PartnerPage />} />
                  <Route path="/oem" render={() => <AutoOEMPage />} />
                  <Route path="/cities" render={() => <CitiesPage />} />
                  <Route path="/privacy/data-sharing-opt-out" render={() => (<Redirect to="/privacy" />)} />
                  <Route path="/privacy/sensitive-info-opt-out" render={() => (<Redirect to="/supplemental-state-privacy-notice" />)} />
                  <Route path="/privacy" render={() => <PrivacyPage202406 />} />
                  <Route path="/supplemental-state-privacy-notice" render={() => <SupplementalStatePrivacyNoticePage />} />
                  {/* Behavior for all users before CRPA launch (01/19/2023) */}
                  {/* <Route path="/california-resident-privacy-notice" render={() => <CaliforniaPrivacyNoticePage />} /> */}
                  <Route path="/california-resident-privacy-notice" render={() => <SupplementalStatePrivacyNoticePage />} />
                  <Route path="/jp/help/category/:category_slug/:question_slug" render={() => <HelpCategoriesPage/>}  />
                  <Route path="/jp/help/category/:category_slug" render={() => <HelpCategoriesPage/>}  />
                  <Route path="/jp/help/category" render={() => <HelpCategoriesPage/>} />
                  <Route path="/jp/help" render={() => <HelpPage/>} />
                  <Route strict path="/help/category/:category_slug/:question_slug" render={() => <HelpCategoriesPage/>}  />
                  <Route strict path="/help/category/:category_slug" render={() => <HelpCategoriesPage/>}  />
                  <Route strict path="/help/category" render={() => <HelpCategoriesPage/>} />
                  <Route strict path="/help" render={() => <HelpPage/>} />
                  <Route path="/tos" render={() => <TosPage202406 />} />
                  <Route path="/jp/contact/:formType" render={() => <ContactPage />} />
                  <Route path="/jp/contact" render={() => <ContactPage />} />
                  <Route path="/contact/:formType" render={() => <ContactPage />} />
                  <Route path="/contact" render={() => <ContactPage />} />
                  <Route path="/press" render={() => <PressPage />} />
                  <Route path="/jp/about" render={() => <AboutPage />} />
                  <Route path="/about" render={() => <AboutPage />} />
                  {(process.env.REACT_APP_MILES_ENV === "production") ? null : (
                    <Route path="/dev" render={() => <DevSamples />} />
                  )}
                  <Route path="/jp" render={() => <HomePage_JP />} />
                  <Route exact path="/" render={() => <HomePage />} />
                  <Route path="*" component={Miles_NotFoundPage} />
                </Switch>
                {Miles_Footer_hide_show}
              </BodyWrap>
            )
          }
          return <RewardSharing_current />
        }} />
      </Switch>
    </div>
  );
});

export default App;
