import config from "../../utils/config";
import React from 'react';

const {
    help_page_assets: assets,
    currentSupportEmailAddress,
} = config;

export const RAW_DATA = {
    "response": {
        "user": [
            {
                "id": 1,
                "name": "アカウント設定について",
                "slug": "account-settings-preferences",
                "user": "user",
                "image_src": assets.help_page_category_icon_1,
                "question_array": [
                    {
                        "id": 68,
                        "text": "アプリの設定とおすすめ",
                        "slug": "setting-up-the-app-and-best-practices",
                        "answer_array": [
                            {
                                "id": 65,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アカウント登録後、以下の設定でノルクをご利用いただけます。
                                    ＜位置情報サービス＞
                                    ●iOSデバイスの場合
                                    位置情報サービスを「常に許可」に設定
                                    「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。
                                    位置情報サービスは、ホーム画面「設定」→「プライバシー」→「位置情報サービス（オン）」→「ノルク」を選択して変更してください。
                                    ※「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可」を選択するとアプリが正常に動作します。
                                    ●Android端末の場合
                                    位置情報サービスを「許可」に設定
                                    位置情報サービスは、「設定」→「アプリケーション」→「ノルク」→「アクセス許可」→「位置情報」から「オン」で更新してください。

                                    ＜通知の設定＞
                                    新機能、ボーナスマイル、新しい特典など重要なアップデートを配信するため、「オン」にすることをおすすめします。
                                    なお、通知の頻度など、通知やEメールの詳細な設定を行うこともできます。
                                    ●iOSデバイスの場合：
                                    ホーム画面より「設定」→「通知」→「ノルク」を選択
                                    ●Android端末の場合
                                    「設定」→「アプリケーション」→「ノルク」→「通知」を選択

                                    ＜主要な通勤・通学手段の設定＞
                                    移動手段がより正確に判定されるため、主要な通勤・通学手段を設定することをおすすめします。
                                    アプリのアカウントページ「主要な通勤・通学手段を設定する」をタップして設定してください。

                                    ＜アプリのバージョン＞
                                    アプリのアップデートを定期的に実施しています。アプリを最新の状態にしておくことをおすすめします。

                                    ＜OSのバージョン＞
                                    アプリが正常に機能するために、最新のOSバージョンにアップデートしてください。

                                    ＜Wi-Fiの設定＞
                                    Wi-Fiを有効にすることで、パフォーマンス精度をさらに向上させ、バッテリー消費を抑えることができます。
                                    `}
                                </span></div>
                            }
                        ]
                    },
                    {
                        "id": 69,
                        "text": "プロフィールページに自分の写真を追加するにはどうすればいいですか？",
                        "slug": "how-can-i-add-my-picture-to-my-profile-page",
                        "answer_array": [
                            {
                                "id": 66,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Facebookアカウントでサインアップされた方は、Facebookのプロフィール写真がノルクアプリのプロフィールページに自動的に追加されます。ただし、Eメールアカウントでサインアップされた方は、アプリ内でプロフィール写真を追加することができません。この機能は近日中に追加される予定です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 70,
                    //     "text": "Why don’t I see the ride hailing services I use in the Connectors?",
                    //     "slug": "why-don-t-i-see-the-ride-hailing-services-i-use-in-the-connectors",
                    //     "answer_array": [
                    //         {
                    //             "id": 67,
                    //             "text": <div className="answer"><span><p><span>We’ve currently built connectors with mobility service providers like Uber and Lyft. We plan to expand that list to other service providers or fitness apps, as long as such services/apps allow us to do it. We will notify our users when we introduce a connector to a new service/app. Please provide your suggestions by using the ‘Send Feedback’ button towards the bottom of the Account page of the app or by emailing us at {currentSupportEmailAddress}.</span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 71,
                        "text": "通知の設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-notification-preferences",
                        "answer_array": [
                            {
                                "id": 68,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリの新機能、ボーナスマイル、新しい特典などの重要なアップデートを配信するため通知の設定を「オン」にすることをおすすめします。
                                    ●iOSデバイスの場合
                                    ホーム画面「設定」→「通知」→「ノルク」を選択
                                    ●Android端末の場合
                                    「設定」→「アプリケーション」→「ノルク」→「通知」を選択

                                    アプリ内で通知やEメールの詳細な設定を行うこともできます。アカウントページより「設定」を選択してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 72,
                        "text": "位置情報サービスの設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-location-services-preferences",
                        "answer_array": [
                            {
                                "id": 69,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ●iOSデバイスの場合
                                    ホーム画面「設定」→「プライバシー」→「位置情報サービス（オン）」→「ノルク」を選択

                                    ●Android端末の場合
                                    位置情報サービスは、「設定」→「アプリケーション」→「ノルク」→「アクセス許可」→「位置情報」から「オン」で更新してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 73,
                        "text": "アプリからログアウトするにはどうすればいいですか？",
                        "slug": "how-can-i-logout-of-the-app",
                        "answer_array": [
                            {
                                "id": 70,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ログアウトするには、アプリのアカウントページで、「設定」→「ログアウト」をタップします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 74,
                        "text": "パスワードを再設定するにはどうすればいいですか？",
                        "slug": "how-to-reset-password",
                        "answer_array": [
                            {
                                "id": 71,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    メールアドレスを使って再ログインする場合は、「パスワードをお忘れですか？」をタップしてください。以前にサインアップしたときに使用したEメールアドレスを入力するよう求められ、このメールアドレスにパスワードをリセットするためのリンクを含む認証メールが送信されます。リンクをタップして、パスワードをリセットしてください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 75,
                        "text": "メンテナンスマイルとは何ですか？",
                        "slug": "what-is-a-maintenance-fee",
                        "answer_array": [
                            {
                                "id": 72,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    より快適なユーザー体験を維持し続けるために、6ヶ月以上ノルクアプリを開いていない場合には、メンテナンスのため2,500マイルがアカウント保有マイル数から毎月差し引かれます。例えば、2021年12月9日以降、ノルクアプリを開かなかった場合、2022年6月9日に2,500マイルが失効し、再びアプリを開くまで毎月2,500マイルずつ差し引かれることとなります。マイル残高が0になった場合は、それ以上引かれることはありません。なお、ノルクアプリは無料です。金銭的な請求はありませんのでご安心ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 76,
                        "text": "アカウント削除について",
                        "slug": "deleting-an-account",
                        "answer_array": [
                            {
                                "id": 73,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アカウント削除をご希望の場合は、カスタマーサポートにて承っております。

                                    アカウント削除希望のアカウントでノルクアプリにログインいただき、画面下メニューからアカウント>お問い合わせ>問題を報告する>アカウント設定>「その他」を選択し「アカウント削除希望」を記載の上、ご連絡ください。
                                    
                                    ※問い合わせ本文内に会員IDやご登録メールアドレスの記載は不要です。
                                    
                                    上記手順でお問い合わせいただくと、お客様がログイン中のアプリのご登録メールアドレスがカスタマーサポートで確認できるようになっております。
                                    
                                    なお、ノルクアプリは無料です。アカウント削除せずにご登録されたままであっても、ノルクからお客様のアカウントに課金が発生することは一切ありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 2,
                "name": "インストール・初期設定について",
                "slug": "app-installation-setup",
                "user": "user",
                "image_src": assets.help_page_category_icon_2,
                "question_array": [
                    {
                        "id": 3,
                        "text": "アプリのインストールについて",
                        "slug": "installing-the-app",
                        "answer_array": [
                            {
                                "id": 3,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    App StoreまたはGoogle Play Storeで、「ノルク」を検索してアプリをダウンロードしてください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 4,
                        "text": "アカウントの作成について",
                        "slug": "creating-an-account",
                        "answer_array": [
                            {
                                "id": 4,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    以下の方法で、ノルクアプリよりアカウントを作成してください：
                                    Facebook：登録ページ「Facebookでログイン」をタップ、その後手順に従ってください。
                                    Apple ID：登録ページ「AppleIDでログイン」をタップ、その後手順に従ってください。
                                    Eメール：登録ページ「登録する」をタップ、有効なEメールアドレス、パスワード、名前を入力してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 5,
                        "text": "アプリの設定とおすすめ",
                        "slug": "setting-up-the-app-and-best-practices",
                        "answer_array": [
                            {
                                "id": 5,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アカウント登録後、以下の設定でノルクをご利用いただけます。
                                    ＜位置情報サービス＞
                                    ●iOSデバイスの場合
                                    位置情報サービスを「常に許可」に設定
                                    「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。
                                    位置情報サービスは、ホーム画面「設定」→「プライバシー」→「位置情報サービス（オン）」→「ノルク」を選択して変更してください。
                                    ※「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可」を選択するとアプリが正常に動作します。
                                    ●Android端末の場合
                                    位置情報サービスを「許可」に設定
                                    位置情報サービスは、「設定」→「アプリケーション」→「ノルク」→「アクセス許可」→「位置情報」から「オン」で更新してください。

                                    ＜通知の設定＞
                                    新機能、ボーナスマイル、新しい特典など重要なアップデートを配信するため、「オン」にすることをおすすめします。
                                    なお、通知の頻度など、通知やEメールの詳細な設定を行うこともできます。
                                    ●iOSデバイスの場合：
                                    ホーム画面より「設定」→「通知」→「ノルク」を選択
                                    ●Android端末の場合
                                    「設定」→「アプリケーション」→「ノルク」→「通知」を選択

                                    ＜主要な通勤・通学手段の設定＞
                                    移動手段がより正確に判定されるため、主要な通勤・通学手段を設定することをおすすめします。
                                    アプリのアカウントページ「主要な通勤・通学手段を設定する」をタップして設定してください。

                                    ＜アプリのバージョン＞
                                    アプリのアップデートを定期的に実施しています。アプリを最新の状態にしておくことをおすすめします。

                                    ＜OSのバージョン＞
                                    アプリが正常に機能するために、最新のOSバージョンにアップデートしてください。

                                    ＜Wi-Fiの設定＞
                                    Wi-Fiを有効にすることで、パフォーマンス精度をさらに向上させ、バッテリー消費を抑えることができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 6,
                        "text": "マイルをためるためにデータプランは必要ですか？",
                        "slug": "do-i-need-a-data-plan-to-earn-miles",
                        "answer_array": [
                            {
                                "id": 6,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ノルクは、インターネットに接続されていないオフライン状態でも動作します。オフラインで使用する場合、少なくとも24時間に1回インターネットに接続することで、過去24時間の移動のマイルが獲得できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 7,
                        "text": "なぜ主要な通勤・通学手段を設定する必要があるのですか？",
                        "slug": "why-should-i-set-a-primary-mode",
                        "answer_array": [
                            {
                                "id": 7,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    マイルをためるために必須ではありませんが、移動手段が正しく判別されるよう、主要な通勤・通学手段を設定することをおすすめします。例えば、渋滞の中、車で短距離移動した場合、誤って徒歩やランニングの移動と分類されることがあります。主要な通勤・通学手段を選択することで、最も頻繁に利用する移動手段を登録することができます。アプリのアカウントページで「主要な通勤・通学手段を設定する」をタップすると、主要な通勤・通学手段を設定することができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 8,
                        "text": "プロフィールページに自分の写真を追加するにはどうすればいいですか？",
                        "slug": "how-can-i-add-my-picture-to-my-profile-page",
                        "answer_array": [
                            {
                                "id": 8,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    Facebookアカウントでサインアップされた方は、Facebookのプロフィール写真がノルクアプリのプロフィールページに自動的に追加されます。ただし、Eメールアカウントでサインアップされた方は、アプリ内でプロフィール写真を追加することができません。この機能は近日中に追加される予定です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 9,
                    //     "text": "Why don’t I see the ride hailing services I use in the Connectors?",
                    //     "slug": "why-don-t-i-see-the-ride-hailing-services-i-use-in-the-connectors",
                    //     "answer_array": [
                    //         {
                    //             "id": 9,
                    //             "text": <div className="answer"><span><p><span>We’ve currently built connectors with mobility service providers like Uber and Lyft. We plan to expand that list to other service providers or fitness apps, as long as such services/apps allow us to do it. We will notify our users when we introduce a connector to a new service/app. Please provide your suggestions by using the ‘Send Feedback’ button towards the bottom of the Account page of the app or by emailing us at {currentSupportEmailAddress}.</span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 10,
                        "text": "通知の設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-notification-preferences",
                        "answer_array": [
                            {
                                "id": 10,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリの新機能、ボーナスマイル、新しい特典などの重要なアップデートを配信するため通知の設定を「オン」にすることをおすすめします。
                                    ●iOSデバイスの場合
                                    ホーム画面「設定」→「通知」→「ノルク」を選択
                                    ●Android端末の場合
                                    「設定」→「アプリケーション」→「ノルク」→「通知」を選択

                                    アプリ内で通知やEメールの詳細な設定を行うこともできます。アカウントページより「設定」を選択してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 11,
                        "text": "位置情報サービスの設定はどうすればいいですか？",
                        "slug": "how-can-i-set-my-location-services-preferences",
                        "answer_array": [
                            {
                                "id": 11,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ●iOSデバイスの場合
                                    ホーム画面「設定」→「プライバシー」→「位置情報サービス（オン）」→「ノルク」を選択

                                    ●Android端末の場合
                                    位置情報サービスは、「設定」→「アプリケーション」→「ノルク」→「アクセス許可」→「位置情報」から「オン」で更新してください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //    "id": 12,
                    //    "text": "アプリが動作しているかどうかを確認するにはどうすればいいですか？",
                    //    "slug": "how-do-i-know-if-the-app-is-working",
                    //    "answer_array": [
                    //        {
                    //            "id": 12,
                    //            "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                {`
                    //                アプリが正常に機能しない場合、理由は様々ですが、以下の条件を満たしていれば、アプリが正常に機能する可能性が高くなります。
                    //
                    //                                    位置情報サービス：
                    //                                    iOSデバイスの場合、位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。位置情報サービスを設定するには、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。iOSでは、「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可する」を選択するとアプリが正常に動作します。
                    //                                    Android端末では、位置情報サービスを「許可」に設定してください。位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。
                    //                                    
                    //                                    ログイン状態を維持する：意図せずにアプリからログアウトしていないか確認してください。
                    //                                    
                    //                                    アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。
                    //                                    
                    //                                    OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。
                    //                                    
                    //                                    インターネット接続環境：Milesは、インターネットに接続されていないオフライン手段でも動作します。オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。
                    //                                    `}
                    //                                    </span></div>
                    //                            }
                    //                        ]
                    //                    },
                    {
                        "id": 13,
                        "text": "アプリからログアウトするにはどうすればいいですか？",
                        "slug": "how-can-i-logout-of-the-app",
                        "answer_array": [
                            {
                                "id": 13,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ログアウトするには、アプリのアカウントページより「設定」をタップし、「ログアウト」をタップします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 14,
                        "text": "パスワードを再設定するにはどうすればいいですか？",
                        "slug": "how-to-reset-password",
                        "answer_array": [
                            {
                                "id": 14,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    メールアドレスを使って再ログインする場合は、「パスワードをお忘れですか？」をタップしてください。以前にサインアップしたときに使用したEメールアドレスを入力するよう求められ、このメールアドレスにパスワードをリセットするためのリンクを含む認証メールが送信されます。リンクをタップして、パスワードをリセットしてください。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            },
            {
                "id": 3,
                "name": "データプライバシー",
                "slug": "data-privacy",
                "user": "user",
                "image_src": assets.help_page_category_icon_3,
                "question_array": [
                    {
                        "id": 15,
                        "text": "利用規約やプライバシーポリシーはアプリのどこに記載されていますか？",
                        "slug": "where-can-i-find-the-terms-of-services-and-the-privacy-policy-in-the-app",
                        "answer_array": [
                            {
                                "id": 52,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    利用規約とプライバシーポリシーは、アプリのアカウントページで「アプリについて」をタップすると表示されます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 16,
                        "text": "ノルクアプリを使用することでどのような情報が収集されるのですか？",
                        "slug": "what-information-is-collected-through-my-use-of-the-miles-app",
                        "answer_array": [
                            {
                                "id": 53,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    （1）本サービスの利用登録の申込み時にご提供いただく以下の情報を取得します。
                                    なお、サービス利用者がソーシャルログイン機能やSNSその他サービスとの連携機能を利用することによって、下記①～⑤の情報を提供する場合を含みます。
                                    ① 氏名
                                    ② 電話番号
                                    ③ 電子メールアドレス
                                    ④ アカウントのプロフィールとしてアップロードした写真
                                    ⑤ アカウントに関する情報として、本サービスに対して、任意に提供等したその他の情報（生年月日、性別等）

                                    (２) 本サービスの提供に伴って以下の情報を取得します。

                                    ① 利用端末に関する情報（利用端末の機種、個体識別番号などの識別子情報、OS情報、本アプリのバージョン等）
                                    ② 本サービスの利用に伴って発生する情報（IPアドレス、タイムスタンプ、ウェブページ等の閲覧履歴、マイレージポイントの利用履歴、その他のログや行動履歴等）
                                    ③ Cookieおよびこれに類する技術に関する情報（以下「Cookie等」といいます。）
                                    ④ 利用端末のGPS機能およびその他の位置情報測位機能を利用した位置情報並びにこれらから推知するサービス利用者の移動に関する情報（移動速度、移動する方角・方向、移動の角速度等）

                                    (３) サービス利用者が自身のSNSとの連携を行った場合には、取得することができる情報（友人リスト、位置情報、移動距離、移動手段に関する情報（以下、移動距離と移動手段に関する情報を総称して「移動に関する情報」といいます。）、ソーシャルプラグイン機能（SNSその他サービスにおいて設置された「いいね」などのプラグイン）によって取得するウェブページ等の閲覧履歴等を指し、当該SNSで登録された認証情報、サービス利用者がソーシャルログイン機能によって提供等する情報（（１）①～⑤をいいます。）は除きます。）を取得します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 17,
                        "text": "収集された情報はどのように利用されるのですか？",
                        "slug": "how-is-information-collected-used",
                        "answer_array": [
                            {
                                "id": 54,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    取得したデータは以下の目的で利用させていただきます。
                                    ①　本サービス内容の履行
                                    ②　本サービスの改良または新機能の追加
                                    ③　新規サービス・アプリ・新商品の開発および研究
                                    ④　当社サービス品質の向上に資する研究
                                    ⑤　ConnectlQ Labs, Inc.のAI高度化
                                    ⑥　①から⑤に掲げる利用目的に準ずるまたはこれらに関連する目的
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //    "id": 18,
                    //    "text": "収集された情報はどのように共有されるのですか？",
                    //    "slug": "how-is-information-collected-shared",
                    //    "answer_array": [
                    //        {
                    //            "id": 55,
                    //            "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                {`
                    //                当社は、以下のとおり、または本プライバシーポリシーに記載されているとおり、利用者に関する情報を共有することがあります：
                    
                    //                当社に代わって業務を遂行するために当該情報へのアクセスを必要とするベンダー、コンサルタント、およびその他のサービスプロバイダーと。
                    //                利用者が本サービスからのログアウトまたはモバイルアプリケーションの削除によって情報の共有を拒否しない限り、第三者のサービスおよび一般的なマーケティング目的のために、匿名化された、集約された、または非識別化された形で、第三者と。
                    //                情報の要求に応じて、適用される法律または法的手続き（国家安全保障または法執行の要件を満たすための公的機関による合法的な要求を含む）に従って、またはそれらによって開示が必要であると当社が判断した場合。
                    //                利用者の行為が当社の利用者同意書やポリシーに反していると当社が判断した場合、またはMilesもしくは他者の権利、資産、安全を守るため。
                    //                合併、会社資産の売却、資金調達、または他社による当社事業の全部または一部の買収に関連して、またはその交渉中に。
                    //                Milesと当社の現在および将来の親会社、関連会社、子会社、および共通の支配と所有下にあるその他の企業との間での使用。
                    //                利用者の同意がある場合、または利用者の指示がある場合。
                    //                当社は、利用者を特定するために合理的に使用することができない、集約された情報または非識別情報を共有することがあります。
                    //                `}
                    //                </span></div>
                    //        }
                    //    ]
                    //   }
                ]
            },
            {
                "id": 4,
                "name": "その他",
                "slug": "general",
                "user": "user",
                "image_src": assets.help_page_category_icon_4,
                "question_array": [
                    {
                        "id": 19,
                        "text": "ノルクアプリはどのくらいバッテリーを消費しますか？",
                        "slug": "how-much-battery-does-miles-app-consume",
                        "answer_array": [
                            {
                                "id": 58,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ノルクは、静止しているときはバッテリーを消費しません。移動するときのバッテリー消費量は最小限に抑えられ、平均的な日々の移動では、バッテリーの1％程度が消費されます。Wi-Fiを常にオンにしておくことで、バッテリー消費を抑えることができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 20,
                        "text": "複数のデバイスで利用できますか？",
                        "slug": "can-i-use-the-app-on-multiple-devices",
                        "answer_array": [
                            {
                                "id": 59,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    利用できますが、ログインしている1つのデバイスでしかマイルはたまりません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 21,
                        "text": "アプリのデータ使用量は？",
                        "slug": "how-much-data-does-the-app-use",
                        "answer_array": [
                            {
                                "id": 60,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    データ使用量は、移動回数や各移動の距離によって異なりますが、平均的な月間データプランの1～2％程度を使用することがあります。データ使用量を軽減するためには、Wi-Fiの使用をおすすめします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                //    {
                //        "id": 22,
                //        "text": "アプリのホームページにある「お気に入り」とは何ですか？",
                //        "slug": "what-is-my-places-on-the-home-page-of-the-app",
                //        "answer_array": [
                //            {
                //                "id": 61,
                //                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                //                    {`
                //                    お気に入りの場所やよく行く場所をアプリ内に「お気に入り」として保存することができます。一度保存すると、アプリ内の機能を使ってその場所に移動したり、ETAを共有したり、連絡先と場所を共有したりすることができます。
                //                    `}
                //                    </span></div>
                //            }
                //        ]
                //    },
                //    {
                //        "id": 23,
                //        "text": "「お気に入り」に場所を追加する方法は？",
                //        "slug": "how-can-you-add-locations-to-my-places",
                //        "answer_array": [
                //            {
                //                "id": 62,
                //                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                //                    {`
                //                    新しい場所を追加するには、「お気に入り」セクションのプラス記号をタップして、住所を検索してください。
                //                    `}
                //                    </span></div>
                //            }
                //        ]
                //      }
                ]
            },
            {
                "id": 5,
                "name": "特典について",
                "slug": "rewards",
                "user": "user",
                "image_src": assets.help_page_category_icon_5,
                "question_array": [
                    {
                        "id": 27,
                        "text": "特典はどのように分類され、アプリ上に表示されるのですか？",
                        "slug": "how-are-all-the-rewards-being-grouped",
                        "answer_array": [
                            {
                                "id": 44,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典は4つのグループに分類されています。

                                    ・チャレンジ：利用者がある特定のタスクを完了することで得られる特典。（例えば、1週間に5回自転車で移動する、等）
                                    ・新しく追加された特典：過去30日以内に追加された特典
                                    ・お近くにある特典： 特典が実店舗等で交換可能で、その場所が現在地から16キロ以内にある場合
                                    ・おすすめ：上記以外で利用者が交換可能のすべての特典
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 28,
                        "text": "特典の仕組みは？",
                        "slug": "how-do-i-purchase-rewards",
                        "answer_array": [
                            {
                                "id": 45,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動やボーナスで獲得したマイルを使って、アプリ内の特典と交換することができます。特典と交換するには、特典カードをタップし、画面の下部にある「交換する」ボタンをタップし（十分なマイルを持っている場合）、次の画面で「確認」を押します。すべての特典で同じように動作します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 29,
                        "text": "一度交換した特典はどうなりますか？",
                        "slug": "what-happens-to-the-rewards-once-purchased",
                        "answer_array": [
                            {
                                "id": 46,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    交換された特典は、「特典」ページの「交換された特典」タブに表示されています。交換した特典をタップすると、その特典の交換確認ページに移動します。ここから、特典を利用することができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 30,
                    //     "text": "Do Lyft Line and Uber Pool qualify for carpool Challenges?",
                    //     "slug": "do-lyft-line-and-uber-pool-qualify-for-carpool-challenges",
                    //     "answer_array": [
                    //         {
                    //             "id": 47,
                    //             "text": <div className="answer"><span><p><span>Yes. Since these are technically carpool trips, they qualify for a carpool Challenge. &nbsp;However, both users must have the Miles app to earn carpool credit. In the near future, we expect to be able to detect Lyft Line/Uber Pool automatically and award Carpool credit even if only one party has the Miles app.</span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 31,
                        "text": "特典に有効期限はありますか？",
                        "slug": "do-rewards-expire",
                        "answer_array": [
                            {
                                "id": 48,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典には有効期限があります。特典ごとに有効期限が設定されています。有効期限が近い特典は、特典ページでハイライト表示され、交換ができる残りの日数が表示されます。ギフトカードなどの一部の特典は、交換に有効期限がありますが、一度交換すればいつでも利用できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 32,
                        "text": "特典ページに特典が表示されないのはなぜですか？",
                        "slug": "why-don-t-i-see-any-rewards-in-the-rewards-page",
                        "answer_array": [
                            {
                                "id": 49,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典ページに特典が表示されていない場合、ページを下にスクロールして更新してください。 更新しても特典が表示されない場合は、ご連絡ください。
                                    アカウントページの下部にある「お問い合わせ」→「フィードバックを送る」を選択するか、support-noruku@getmiles.com に直接メールをお送りください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 33,
                        "text": "特典の更新頻度は？",
                        "slug": "how-frequently-are-the-rewards-refreshed",
                        "answer_array": [
                            {
                                "id": 50,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ノルクでは、継続的に新しい特典を追加し、利用者から好評だった過去の特典も提供しています。更新の頻度は決まっていません。ご興味ある特典は、数量限定や有効期限付きのものも多いので、お早めに交換いただくことをおすすめします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 34,
                        "text": "後で交換したい特典をブックマークしたり、保存するには？",
                        "slug": "how-can-i-bookmark-or-save-a-reward-that-i-want-to-purchase-later",
                        "answer_array": [
                            {
                                "id": 51,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ブックマークや保存された特典は、特典ページの上部に表示され、検討していた特典を簡単に見直すことができます。保存したい特典のハートマークをタップすると、保存できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 67,
                        "text": "特典を並べ替える方法はありますか？",
                        "slug": "is-there-a-way-to-sort-rewards",
                        "answer_array": [
                            {
                                "id": 64,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    以下のような基準で特典を並び替えることができます。

                                    ・近く（距離）
                                    ・オンライン
                                    ・有効期限
                                    ・必要なマイル数
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 66,
                        "text": "カテゴリー別に特典を絞り込む方法はありますか？",
                        "slug": "is-there-a-way-to-filter-rewards-by-categories",
                        "answer_array": [
                            {
                                "id": 63,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典ページでは、画面上部のカテゴリータブをタップすると、以下のカテゴリーごとに分類されたすべての特典が表示されます。

                                    ・フード/ドリンクオート
                                    ・日用品
                                    ・健康/美容
                                    移動/体験
                                    ・プレゼント用
                                    ・ジュエリー/アクセサリー
                                    ・アパレル/シューズ
                                    ・ペット
                                    ・ギフトカード
                                    ・その他
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 78,
                        "text": "特典とは何ですか？",
                        "slug": "how-do-the-rewards-work-2",
                        "answer_array": [
                            {
                                "id": 76,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    特典 とは、通常、特典パートナーの製品やサービスの割引、クレジット、または無料提供のことです。特典の種類には、通常以下があります。

                                    ・通常特典
                                    ・ギフトカード
                                    ・抽選
                                    ・チャレンジ
                                    ・寄付

                                    移動やボーナスで獲得したマイルを使って、アプリ内の特典と交換可能。特典カードをタップし、画面の下部にある「交換する」ボタンをタップし（十分なマイルを持っている場合）、次の画面で「確認」を押します。会員ランクの高い利用者（例：プラチナ）は、ランクの低い利用者（例：シルバー）と比較して、同じ特典でも必要なマイル数が少なくなります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //   "id": 79,
                    //   "text": "お友達紹介のギフトカードはどのように受け取れますか？",
                    //    "slug": "when-do-i-get-my-referral-gift-card",
                    //    "answer_array": [
                    //        {
                    //            "id": 77,
                    //            "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                {`
                    //                ギフトカード"は、お友達紹介キャンペーン終了日までに紹介者が登録し、指定された移動の回数・距離を完了した対象会員に付与されます。
                    
                    //                ご注意：ご紹介いただく方は、Milesの新規利用者であることが条件となります。
                                   
                    //                条件を満たしていれば、プッシュ通知とEメールが送信され、Milesアプリの「特典」タブの「新しく追加された特典」にギフトカードが表示されます。
                                  
                    //                特定のキャンペーンで発表された期日までにギフトカードが表示されない場合は、残念ながら1つ以上の理由で対象外となっている可能性があります：
                                  
                    //                キャンペーン期間中に、必要最低人数のご紹介者様が登録されなかった。
                    //               キャンペーン終了日までに1人以上のご紹介者が指定された移動を完了していない。
                    //                紹介者が新規利用者として確認されなかった（以前に別のログイン名でMilesに登録していたり、同じデバイスを使用していた場合など）。
                    //               紹介者のリストを表示するには、Milesアプリから「友達紹介」ページにをタップし、右上にある「紹介履歴」を選択してください。
                    //               `}
                    //                </span></div>
                    //        }
                    //    ]
                    //   }
                ]
            },
            {
                "id": 6,
                "name": "移動・マイルについて",
                "slug": "trips-miles",
                "user": "user",
                "image_src": assets.help_page_category_icon_6,
                "question_array": [
                    {
                        "id": 35,
                        "text": "マイルはどのようにしてたまるのですか？",
                        "slug": "how-do-i-earn-miles",
                        "answer_array": [
                            {
                                "id": 15,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリをインストールして位置情報の取得を”常に許可”に設定した状態で、以下の各手段での移動が自動検知され、有効な移動ごとにマイルが付与されます。
                                    移動やマイルをご自身で登録する必要はなく、移動前から移動後まで、アプリを開く必要もありません。

                                    ＜マイルを獲得できる移動手段と有効な移動として判定される条件＞
                                    ・自動車 - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ・相乗り - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。相乗りを自動的に検出するためには、利用者に加えて、ノルクをインストールして動作している利用者がもう一人同乗している必要があります。
                                    ・電車 - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ・バス - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ・ボート - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ・徒歩/ランニング - 2分以上の休憩なしで0.2マイル（約320メートル）以上の距離を移動すること。屋外での徒歩およびランニングのみ。
                                    ・自転車* - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ・航空 - 短距離および長距離のフライト。

                                    * 自転車には電動スクーターは含まれていません。電動スクーターの自動検出機能の追加は現在検討中です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 36,
                        "text": "マイルや移動を登録する必要はありますか？",
                        "slug": "do-i-need-to-log-my-miles-or-trips",
                        "answer_array": [
                            {
                                "id": 16,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    いいえ、マイルを登録する必要はありません。アプリをインストールして、位置情報の取得を”常に許可”に設定いただくと、すべての移動のマイルが自動的に記録され、加算されます。移動前から移動後まで、アプリを開く必要はありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 37,
                        "text": "他人の車に同乗して移動しているときに、自分のアプリが起動していたらマイルはたまりますか？",
                        "slug": "what-if-i-m-traveling-as-a-passenger-in-someone-else-s-car-and-my-app-is-running",
                        "answer_array": [
                            {
                                "id": 17,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい、マイルがたまります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 38,
                        "text": "移動やマイルが正しく反映されていません。確認すべきことはありますか？",
                        "slug": "why-do-i-have-a-missing-trip-and-missing-miles-how-can-i-report-it",
                        "answer_array": [
                            {
                                "id": 18,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動したにも関わらず、その移動やマイルが自動的に記録されていない場合があります。
                                    理由としては、【電波が弱い・届かない】が、最も一般的な理由です。

                                    ＜位置情報サービス＞
                                    ●iOS端末の場合、位置情報サービスを「常に許可」に設定。
                                    「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。
                                    位置情報サービスは、「設定」→「プライバシー」→「位置情報サービス（オン）」→「ノルク」を選択で更新可能。
                                    ※「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示された際は、「常に許可する」を選択するとアプリが正常に動作します。

                                    ●Android端末の場合：位置情報サービスを「常に許可」に設定。
                                    位置情報サービスは、「設定」→「アプリ管理」→「ノルク」→「アプリの権限」→「位置情報：常に許可」で更新可能。

                                    アプリのバージョン：
                                    パフォーマンス向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。

                                    ＜OSのバージョン＞
                                    利用可能な場合は最新のOSバージョンにアップデートしてください。

                                    ＜インターネット接続環境＞
                                    ノルクは、インターネットに接続されていないオフライン手段でも動作します。
                                    オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。

                                    アプリ内では有効な移動のみが表示され、マイルが付与されます。
                                    ＜マイルを獲得できる移動手段と有効な移動として判定される条件＞
                                    自動車 - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    相乗り - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。相乗りを自動的に検出するためには、利用者に加えて、ノルクをインストールして動作している利用者がもう一人同乗している必要があります。
                                    電車 - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    バス - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    ボート - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    徒歩/ランニング - 2分以上の休憩なしで0.2マイル（約320メートル）以上の距離を移動すること。屋外での徒歩およびランニングのみ。
                                    自転車* - 5分以上の休憩なしで0.5マイル（約800メートル）以上の距離を移動すること。
                                    航空 - 短距離および長距離のフライト。
                                    * 自転車には電動機付自転車は含まれていません。電動機付自転車の自動検出機能の追加は現在検討中です。
                                    これらの条件を満たしているにもかかわらず、表示されるべき移動が表示されなかった場合は、ご報告ください。「アカウント」ページより「お問い合わせ」→「フィードバックを送る」をタップするか、support-noruku@getmiles.com まで直接メールでご連絡ください。                    
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 39,
                        "text": "なぜ移動が部分的しか表示されないことがあるのですか？",
                        "slug": "why-only-partial-walk-trips-show-up",
                        "answer_array": [
                            {
                                "id": 19,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    部分的な移動がアプリに表示される主な理由は、特に短距離（1マイル（1.6キロ）未満）の移動の際に、スマートフォンの電波の状況が悪いことです。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 40,
                        "text": "1回の移動で何マイルたまりますか？",
                        "slug": "how-many-miles-are-earned-for-each-trip",
                        "answer_array": [
                            {
                                "id": 20,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動毎に何マイルたまったかは、アプリ内「移動履歴」で確認できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 41,
                        "text": "獲得できるボーナスマイルにはどのようなものがありますか？",
                        "slug": "what-are-all-the-bonus-miles-i-can-earn",
                        "answer_array": [
                            {
                                "id": 21,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ボーナスマイルの獲得方法は、利用者のステータス、ご利用いただいた移動手段、の2つがあります。

                                    ＜移動手段毎に獲得できるボーナスマイル＞
                                    移動手段毎に獲得できるボーナスマイルは、アプリの「移動履歴」ページで、右上にある「i」ボタンをタップしてください。

                                    ＜利用者のステータスによるボーナスマイルの獲得＞
                                    ステータスに応じて、毎月ボーナスマイルを獲得できます。詳細は、アプリ内の「月間ステータスについて」ページをご覧ください。
                                    このページを見つけるには、トップページの右上にあるステータス（シルバー/ゴールド/プラチナ）をタップし、その後のページの右上にある「?」ボタンをタップしてください。

                                    ＜お誕生日やノルクとの記念日によるボーナスマイルの獲得＞
                                    お誕生日だけでなく、ノルクのアカウント登録記念日にもボーナスマイルを獲得することができます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 42,
                    //     "text": "What do I need to do to earn bonus miles for ride hailing services like Uber/Lyft?",
                    //     "slug": "what-do-i-need-to-do-to-earn-bonus-miles-for-ride-hailing-services-like-uber-lyft",
                    //     "answer_array": [
                    //         {
                    //             "id": 22,
                    //             "text": <div className="answer"><span><p><span>To earn bonus miles for ride hailing services like Uber/Lyft, connect your Miles app with them. To connect, go to the Account page, click on ‘Connectors’ and select the apps/services you’d like to connect with and earn bonus miles. </span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 43,
                        "text": "たまった・つかったマイルの履歴を見ることはできますか？",
                        "slug": "can-i-see-a-history-of-my-miles-earned-and-redeemed",
                        "answer_array": [
                            {
                                "id": 23,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    獲得したマイル（ボーナスマイルを含む）や交換したマイルの履歴を月ごとに確認するには、アプリのトップページの左上にある獲得マイル数をタップしてから、「マイルバンク」をタップします。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 44,
                        "text": "相乗りでボーナスマイルはたまりますか？",
                        "slug": "can-i-earn-bonus-miles-for-carpooling",
                        "answer_array": [
                            {
                                "id": 24,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ノルクは、利用者がノルクアプリを使って他の利用者と相乗りしていることを自動的に検出します。相乗りしている方がまだノルクアプリをダウンロードしていない場合は、相乗りボーナスマイルを獲得するために、相乗りしている方にノルクアプリのダウンロードをお願いしてください。ボーナスマイルは、利用者が他の方と相乗りしていた移動のマイル数に対してのみ付与されます。例えば、10マイルの自動車移動の場合、40%の4マイルのみを相乗りした場合、4マイルの移動に対してのみボーナスマイルが付与されます。 さらに、市や交通機関が相乗りに対して、追加の特典を提供する場合もあります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 45,
                        "text": "移動は登録する必要ありますか？",
                        "slug": "do-i-have-to-log-the-trips",
                        "answer_array": [
                            {
                                "id": 25,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    いいえ、移動の記録は、スマートフォンでノルクアプリでの位置情報取得を「常に許可」に設定しておくことで、自動的に行われます。移動前から移動後まで、アプリを開く必要はありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 46,
                        "text": "移動がアプリ内に表示されるまで、どれくらい時間がかかりますか？",
                        "slug": "how-long-before-my-trip-appears",
                        "answer_array": [
                            {
                                "id": 26,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    通常、移動が完了してからアプリに移動が表示され、マイルが加算されるまで10分程度かかります。スマートフォンの電波状況が悪い、WiFiネットワークの状態が悪いまたは遅い、バッテリー残量が少ないなどの理由で、システムが移動を処理するのにそれ以上かかる場合があり、その結果、移動がアプリに表示されるまでに20分から60分かかることがあります。60分以上経過してもトリップが表示されない場合は、「問題を報告」機能を使ってご連絡ください。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 47,
                        "text": "過去のすべての移動履歴を見ることはできますか？",
                        "slug": "can-i-see-all-my-past-trip-details",
                        "answer_array": [
                            {
                                "id": 27,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    アプリの「移動履歴」ページでは、過去のすべての移動履歴が表示されます。移動履歴は、当月のトリップのみが表示されます。前月以前の移動を表示するには、同ページの上部中央にある月をタップして、希望の月を選択します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 48,
                        "text": "過去の移動を、移動手段別にまとめて見ることはできますか？",
                        "slug": "can-i-see-all-my-trips-grouped-by-mode",
                        "answer_array": [
                            {
                                "id": 28,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい。 特定の移動手段とまとめて見るには、「移動履歴」ページの左上にあるメニューボタンをタップし、希望する移動手段を選択します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    //{
                    //    "id": 49,
                    //    "text": "なぜ移動手段が誤って判別されたのですか？",
                    //    "slug": "why-was-my-trip-misclassified",
                    //    "answer_array": [
                    //        {
                    //            "id": 29,
                    //            "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                {`
                    //                移動したにも関わらず、その移動やマイルが自動的に記録されていない場合があります。以下のような理由が考えられます。
                    //
                    //                携帯電波が弱い・届かない：これが最も一般的な理由です。
                    //                
                    //                位置情報サービス：iOSデバイスの場合、位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。位置情報サービスを設定するには、ホーム画面より「設定」→「プライバシー」→「位置情報サービス（オン）」→「Miles」を選択してください。iOSでは、「バックグランドでの位置情報の使用を許可したままにしますか?」 と表示されることがありますが、「常に許可する」を選択するとアプリが正常に動作します。
                    //                Android端末では、位置情報サービスを「許可」に設定してください。位置情報サービスは、「設定」→「アプリケーション」→「Miles」→「アクセス許可」→「位置情報」から「オン」にすることで更新できます。
                    //                
                    //                アプリのバージョン：当社は、新機能の追加、バグの修正、体験やパフォーマンスの向上のために、アプリのアップデートを定期的に公開しています。アプリを最新の状態にしておくことをおすすめします。
                    //                
                    //                OSのバージョン：アプリが正常に機能するために、利用可能な場合は最新のOSバージョンにアップデートしてください。
                    //               
                    //                インターネット接続環境：Milesは、インターネットに接続されていないオフライン手段でも動作します。オフライン手段で使用する場合、少なくとも24時間に1回インターネット（Wi-Fiまたはデータプラン）に接続することで、過去24時間の移動のマイルが獲得できます。
                    //               
                    //                また、アプリ内では有効な移動のみが表示され、マイルを獲得することができます。有効なトリップの基準：
                    //               
                    //                車 - 5分以上の休憩なしで0.5マイル以上の距離を移動する。
                    //                相乗り - 5分以上の休憩なしに0.5マイル以上の距離を移動すること。相乗り移動を自動的に検出するには、利用者と相乗りするもう一人のユーザーの少なくとも一人が、Milesアプリを携帯電話にインストールして動作させている必要があります。
                    //               電車 - 最短距離0.5マイル、5分以上の休憩なし。
                    //               バス - 5分以上の休憩がない0.5マイル以上の距離。
                    //               ボート - 5分以上の休憩を挟まずに0.5マイル以上の距離を移動することができます。
                    //                ウォーク/ラン - 2分以上の休憩なしで0.2マイル以上の距離を移動すること。屋外でのウォーキングおよびランニングのみ。
                    //                自転車* - 5分以上の休憩なしで0.5マイル以上の距離を移動すること。
                    //                航空 - 短距離および長距離のフライト。
                    //                * Bikeにはe-scootersが含まれていません。e-scootersの自動検出機能の追加を検討しています。
                    //                
                    //                `}
                    //                </span></div>
                    //        }
                    //    ]
                    // },
                    {
                        "id": 50,
                        "text": "誤って判定された移動を更新するにはどうすればいいですか？",
                        "slug": "how-can-i-update-a-misclassified-trip",
                        "answer_array": [
                            {
                                "id": 30,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    誤って判定された移動を更新するには、「移動履歴」ページで関連する移動を探します。移動履歴の左上にあるアイコンをタップし、希望する手段を選択します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 51,
                        "text": "移動手段の変更リクエストはどのくらいの時間で処理されますか？",
                        "slug": "in-how-much-time-are-trip-mode-change-requests-processed",
                        "answer_array": [
                            {
                                "id": 31,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動手段の変更リクエストは、当社のデータに基づいて合理的だと判断された場合、即座に承認されます。それ以外の場合、通常翌営業日の対応となりますが、それ以上時間を要することもあるので予めご了承ください。利用者のリクエストが対応済みになると、プッシュ通知でお知らせします。追加情報が必要な場合は、Eメールでご連絡します。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 52,
                        "text": "位置情報サービスをオンにしなくても、ノルクアプリを利用できますか？",
                        "slug": "can-i-use-the-miles-app-without-turning-on-location-services",
                        "answer_array": [
                            {
                                "id": 32,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    ノルクは、利用者のすべての移動に対して自動的にマイルを付与するために、位置情報サービスを必要とします。利用者の位置情報が第三者と共有されることはありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 53,
                        "text": "獲得したマイルは四捨五入されますか？",
                        "slug": "are-the-miles-earned-rounded-off",
                        "answer_array": [
                            {
                                "id": 33,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    はい、月間獲得マイル数の合計は、最も近い整数値に切り捨てられます。各移動で獲得したマイルは切り捨てられません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 54,
                        "text": "マイルに有効期限はありますか？",
                        "slug": "do-miles-expire",
                        "answer_array": [
                            {
                                "id": 34,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    獲得したマイルに有効期限はありません。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 55,
                        "text": "トリップカードの相乗りアイコンの周りに、％の数字が表示されるのはなぜですか？",
                        "slug": "why-do-i-see-a-certain-number-around-the-carpool-icon-in-the-trip-card",
                        "answer_array": [
                            {
                                "id": 35,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    %の数字は、他のノルクアプリユーザーと相乗りしていた移動の割合を示しています。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 56,
                    //     "text": "Why do I see a certain % number around the ride hailing app icon in the trip card?",
                    //     "slug": "why-do-i-see-a-certain-number-around-the-ride-hailing-app-icon-in-the-trip-card",
                    //     "answer_array": [
                    //         {
                    //             "id": 36,
                    //             "text": <div className="answer"><span><p><span>
                    //                 The % number indicates the portion of the trip you were carpooling with another Miles app user(s) while using a ride hailing service.
                    //                 </span></p></span></div>
                    //         }
                    //     ]
                    // },
                    {
                        "id": 57,
                        "text": "移動を削除するにはどうすればいいですか？",
                        "slug": "how-can-i-delete-a-trip",
                        "answer_array": [
                            {
                                "id": 37,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    現在のところ、移動を削除することはできません。近日中にこの機能を追加する予定です。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 59,
                        "text": "ステータスのルールとメリットは何ですか？",
                        "slug": "what-are-the-rules-benefits-of-all-the-tier-status",
                        "answer_array": [
                            {
                                "id": 39,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    各ステータスには、達成するための条件が定まっており、毎月のボーナスマイル、特典への交換レート、特定の特典への早期アクセスなど、ステータスによってメリットが異なります。詳細については、アプリ内の「月間ステータスについて」ページをご覧ください。「ホーム」ページの右上にあるステータス（シルバー/ゴールド/プラチナ）をタップし、その後のページの右上にある「?」ボタンをタップしてください。
                                    `}
                                    <b>
                                    {`
                                    なお、寄付および抽選へのマイル交換についてはステータスアップの対象外となります。
                                    `}
                                    </b>
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 60,
                        "text": "移動がが2つ以上に分かれているのはなぜですか？",
                        "slug": "why-do-i-see-my-trip-split-into-two-or-more",
                        "answer_array": [
                            {
                                "id": 40,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    移動中に静止している（移動していない）期間がある場合、1つの移動が2つ以上の移動に分割されることがあります。
                                    この静止期間は移動手段ごとに異なります。移動手段ごとに、移動が分割されないために静止していられる期間の最大値は以下の通りです。

                                    ・自動車：5分
                                    ・相乗り：5分
                                    ・バス：5分
                                    ・電車：5分
                                    ・徒歩/ランニング：2分
                                    ・自転車：5分
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    {
                        "id": 61,
                        "text": "相乗りの移動はどのように検出され、判定されるのですか？",
                        "slug": "how-are-carpool-trips-detected-and-classified",
                        "answer_array": [
                            {
                                "id": 41,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    相乗りを自動的に検出するためには、利用者に加えて相乗りをするもう一人のユーザーが、スマートフォンにノルクアプリをインストールして動作させている必要があります。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //    "id": 62,
                    //    "text": "アプリを利用している他のユーザーとの相乗りが検出されなかったのはなぜですか？",
                    //    "slug": "why-wasn-t-my-carpool-trip-with-another-user-who-uses-the-app-detected",
                    //    "answer_array": [
                    //        {
                    //            "id": 42,
                    //           "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                {`
                    //                相乗り移動を自動的に検出するためには、利用者と相乗りするもう一人のユーザーが、スマートフォンにMilesアプリをインストールして動作させている必要があります。また、利用者と相乗りするユーザーが以下の条件を満たしている必要があります。
                    //
                    //                ・位置情報サービス
                    //                iOS端末の場合：位置情報サービスを「常に許可」に設定してください。「なし」または「このAppの使用中のみ許可」を選択すると、アプリが正常に機能しなくなります。利用者の位置情報が第三者と共有されることはありません。位置情報サービスを設定するには、お使いのスマートフォンの設定 → プライバシー → 位置情報サービス（オン） → Milesで「常に許可」とします。iOSでは、時々「バックグラウンドでの位置情報の使用を許可したままにしますか？」と表示されますが、「常に許可する」を選択してください。
                    //                
                    //                Android端末の場合：位置情報サービスを「常に許可」に設定してください。位置情報サービスはお使いのスマートフォンで、「設定」→「アプリ管理」→「Miles」→「アプリの権限」→「位置情報：常に許可」で更新できます。
                    //                
                    //                ・ログインしたままにする：意図せずにアプリからログアウトしていないかご確認ください。
                    //                
                    //                ・アプリのバージョン：当社は、新機能の追加、問題の修正、エクスペリエンスやパフォーマンスの向上のために、アプリのアップデートを定期的にリリースしています。アプリをアップデートして、最高の体験をしてください。
                    //                
                    //                ・OSのバージョン：アプリの機能を確実にサポートするために、可能な限り最新のOSバージョンにアップデートしてください。
                    //                
                    //                ・インターネット接続環境：このアプリは、インターネットに接続されていないオフライン手段でも完全に動作します。オフライン手段で使用する場合、24時間の間にオフライン手段で行ったすべての移動のマイルを獲得するために、少なくとも24時間に1回はインターネット（Wi-Fiまたはデータプラン）に接続する必要がある場合があります。
                    //                `}
                    //                </span></div>
                    //        }
                    //    ]
                    //},
                    {
                        "id": 63,
                        "text": "獲得したマイルには換金性がありますか？",
                        "slug": "do-the-miles-earned-have-a-cash-value",
                        "answer_array": [
                            {
                                "id": 43,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    獲得したマイルには現金価値はありません。獲得したマイルは、アプリ内で提供される特典のご購入にのみご利用いただけます。
                                    `}
                                    </span></div>
                            }
                        ]
                    },
                    // {
                    //     "id": 75,
                    //     "text": "「友達紹介」の仕組みについて教えてください。",
                    //     "slug": "how-does-refer-a-friend-work",
                    //     "answer_array": [
                    //         {
                    //             "id": 72,
                    //             "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                 {`
                    //                 アプリ内「友達紹介」ページに保時されている、利用者独自の招待コードを使って、お友達を招待できます。招待されたお友達は、Milesアプリをインストール後、その招待コードをアプリ内で入力し、一定期間に一定距離の移動を複数回すると、アマゾンギフト券やボーナスマイルが入手できます（条件や特典に関しては、「友達紹介」ページの詳細をご確認ください）。
                    //                 `}
                    //                 </span></div>
                    //         }
                    //     ]
                    // },
                    //{
                    //    "id": 76,
                    //    "text": "友達を紹介しました。なぜボーナスマイルがもらえないのですか？",
                    //    "slug": "i-referred-a-friend-why-havent-i-received-my-bonus-referral-miles",
                    //    "answer_array": [
                    //        {
                    //            "id": 73,
                    //            "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                    //                {`
                    //                お友達を紹介してボーナスマイルを受け取るためには、お友達がMilesアプリに登録し、一定期間に一定距離を複数回移動する必要があります。詳しい条件に関しては、アプリ内「友達紹介」ページをご確認ください。
                    //
                    //                また、紹介したお友達が新しいアカウントと新しいデバイスを使ってサインアップして、条件をクリアした場合のみ、紹介ボーナスマイルを受け取ることができます。
                    //                
                    //                なお、お友達紹介のキャンペーンとして、アマゾンギフト券などをプレゼントしている場合は、ボーナスマイルは付与されない場合がございます。
                    //                `}
                    //               </span></div>
                    //       }
                    //    ]
                    //},
                    {
                        "id": 77,
                        "text": "マイルはどのようにしてためるのですか？",
                        "slug": "how-do-i-earn-miles-2",
                        "answer_array": [
                            {
                                "id": 74,
                                "text": <div className="answer"><span style={{whiteSpace: "pre-line"}}>
                                    {`
                                    いつでも、どこでも、すべての移動でマイルがたまります。また、健康で環境にやさしい移動では、さらにボーナスマイルが加算されます。
                                    アプリを初期設定をすると（位置情報の許可が「常に許可」に設定されていることを確認）、移動が自動検出され、移動完了後10分以内にマイルが付与されます。
                                    マイルを獲得できる移動手段には、自動車、相乗り、徒歩、ランニング、自転車、電車、バス、船、スキー、飛行機があります。
                                    ※マイルを獲得するためには、一定の最低距離（通常は移動手段に応じて0.5マイル（約800メートル）以下）を移動する必要があります。
                                    また、以下の方法でもマイルが加算されます。

                                    <ステータス>
                                     毎月のステータスに応じて、ボーナスマイルを獲得できます（例：プラチナは400、ゴールドは200、シルバーは100のボーナスマイル）。

                                    <マイルストーンとアニバーサリー>
                                    マイルストーン（例：利用者のお誕生日やノルクアカウント登録記念日）に応じてボーナスマイルを獲得できます。
                                    `}
                                    </span></div>
                            }
                        ]
                    }
                ]
            }
        ],
    }
}.response;


export type ITEM_TYPE = typeof RAW_DATA.user[0];
export type QUESTION_TYPE = typeof RAW_DATA.user[0]['question_array'][0];
