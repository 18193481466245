import styled from "styled-components";

interface ActionSectionProps {
}
export const StyledActionSection = styled("div")<ActionSectionProps>`
  
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .fca-raf-download-share-redeem-wrap {
      margin-left: 57px;
      width: 650px;
  }

  .fca-raf-invite-sendDownloadLink-wrap {
    width: auto;
    padding-left: 2px;
    padding-right: 2px;
  }

  /* mobile phone */
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    flex-direction: column;
    width: 100vw;
    margin-top: 40px;
    align-items: center;

    .send-downloadLink-wrap {
      display: none;
    }

    .fca-raf-download-share-redeem-wrap {
        margin-left: 0;
        margin: 0;
        width: max-content;
    }

    .fca-raf-invite-sendDownloadLink-wrap {
      width: 335px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: column;
    width: 100vw;
    margin-top: 40px;
    align-items: center;

    .fca-raf-download-share-redeem-wrap {
        margin-left: 0;
        margin: 0;
        width: max-content;
    }

    .fca-raf-invite-sendDownloadLink-wrap {
      width: 335px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    align-items: center;

    .fca-raf-download-share-redeem-wrap {
        margin-left: 0;
        margin: 0;
        width: max-content;
    }

    .fca-raf-invite-sendDownloadLink-wrap {
      width: auto;
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 50px;
    }
  }

  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    align-items: center;

    .fca-raf-download-share-redeem-wrap {
        margin-left: 0;
        margin: 0;
        width: max-content;
    }

    .fca-raf-invite-sendDownloadLink-wrap {
      width: auto;
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 50px;
    }
  }
  
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    justify-content: space-between;
    
    .fca-raf-download-share-redeem-wrap {
      margin-left: 62px;
      width: auto;
    }

    .fca-raf-invite-sendDownloadLink-wrap {
      margin-right: 60px;
      width: auto;
      padding: 0;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    margin-top: 50px;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin-top: 50px;
  }
`;