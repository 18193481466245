import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import DateTextBox from "../date_text_box/index";
import ImageBox from "../image_box_01/index";
import PartnerLogo from "../image_box_02/index";
import Title from "../title/index";
import Subtitle from "../subtitle/index";
import ReadMoreTextBox from "../read_more_text_box/index";
import { Styled_body_wrap } from "./styles";

const {} = config;

export interface Props {
  logoImage: React.ReactNode;
  headerText: React.ReactNode;
  subHeader: React.ReactNode;
  assetImage: React.ReactNode;
  date: React.ReactNode;
  readMoreCta: string;
}

const Component: React.FC<Props> = observer((props) => {
  return (
    <>
      <Styled_body_wrap className="">
      <div className="ciq-root">
        <div className="ciq-container">
          <div className="article">
            <div className="image"><ImageBox imageAsset={props.assetImage}/></div>
            <div className="logo"><PartnerLogo partnerLogoAsset={props.logoImage}/></div>
            <div className="title"><Title titleText={props.headerText}/></div>
            <div className="subtitle"><Subtitle subTitleText={props.subHeader}/></div>
            <div className="date"><DateTextBox dateText={props.date}/></div>
            <div className="cta-read-more"><ReadMoreTextBox readMoreCTA={props.readMoreCta}/></div>
          </div>
        </div>
      </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
