import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils, { useCountry } from "../../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../../utils/milesConstant";
import LeadershipTextElement from "../leadership_text_element/index";
import LeadershipStrip1 from "../leadership_strip_1/index";
import LeadershipStrip2 from "../leadership_strip_2/index";
import LeadershipStrip3 from "../leadership_strip_3/index";
import { Styled_body_wrap } from "./styles";

const {
  about_page_assets: assets,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;


const MilesWeb_AboutPage: React.FC<{}> = observer(() => {
  let country = useCountry();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AboutPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AboutPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap isJP={country === "jp"}>
      <div className="miles_ui_about_page_leadership_main_root">
        <div className="miles_ui_about_page_leadership_main_container">
          <LeadershipTextElement />
          <LeadershipStrip1 />
          <LeadershipStrip2 />
          {country === "jp" && <LeadershipStrip3 />}
        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default MilesWeb_AboutPage;
