import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import i18next from 'i18next';
import EmailPreferenceUnsubscribe from "../emailPreferenceUnsubscribe_jre";
import EmailPreferenceThankyou from "../emailPreferenceThankyou_jre";
import EmailPreference from "../emailPreference_jre";
import changeLanguage from '../../utils/i18n/i18next';
import config from "../../utils/config";

import {
  MainWrap
} from "./styles";
import { StyledEmailPreferenceHeader } from "./StyledEmailPreferenceHeader";
import { StyledLogoImg } from "./StyledLogoImg";

const {
  email_preference_backgroundImg_desktop,
  email_preference_backgroundImg_tablet,
  email_preference_backgroundImg_phone,
  miles_logo_white,
} = config;

let firstRender = true;

const emailPreferenceWrap_jre: React.FC<{}> = observer(() => {
  if (firstRender) {
    switch (process.env.REACT_APP_MILES_TENANT) {
      case 'miles':
        changeLanguage("english")
        break;
      case 'jre':
        changeLanguage("japanese_jre")
        break;
      case 'edsp':
        changeLanguage("japanese_edsp")
        break;
      case 'mjkk':
      case 'mjp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        console.log(`Sorry, wrong REACT_APP_MILES_TENANT`);
    }
  }

  useEffect(() => {
    firstRender = false;
  }, []);

  const bgImgArr = [
    email_preference_backgroundImg_desktop,
    email_preference_backgroundImg_tablet,
    email_preference_backgroundImg_phone,
  ]
  return (
    <React.Fragment>
      <MainWrap>
        <StyledEmailPreferenceHeader bg={bgImgArr}>
          <StyledLogoImg src={miles_logo_white} />
        </StyledEmailPreferenceHeader>
        <Switch>
          <Route path="/preferences/email/unsubscribe" component={EmailPreferenceUnsubscribe} />
          <Route path="/preferences/email/thankyou" component={EmailPreferenceThankyou} />
          <Route path="/preferences/email" component={EmailPreference} />
        </Switch>
      </MainWrap>
    </React.Fragment>
  );
});

export default emailPreferenceWrap_jre;
