import styled from "styled-components";

interface NavProps {}
export const StyledNav = styled("nav")<NavProps>`
  margin: 0 auto;
  padding: 0;
  border-bottom: none;
  background-color: #ffffff;
  box-shadow: none;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;

  .shouldHide_GetMilesCTAInternal {
    display: block;
  }

  .hamburger_menu_container {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .container-fluid-wrapper {
    width: 100%;
    display: flex;
  }

  .container-height {
    height: 75px;
  }

  .container-fluid {
    width: 'fit-content';
    display: flex;
  }

  .GetMilesCTA-spacer {
    margin-top: -20px !important;
    display: inline-block;
    margin-right: 50px;
  }

  .GetMilesCTA-root {
    margin-left: 16px;
    margin-top: 11px;
    margin-right: 60px;
    height: 100%;
    display: block;
    align-self: flex-start;
    display: inline-block;
  }

  .container-fluid {
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }

  #miles_v2_navbar{
    height: 100%;
  }

  #miles_v2_navbar.collapsing {
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    height: 0;
  }

  a {
    display: inline-block;
    line-height: inherit;
  }

  img {
    width: 108px;
    height: 30px;
    box-sizing: border-box;
  }

  .navbar-brand {
    align-self: center;
  }

  .navbar-collapse {
    flex-grow: initial;
  }

  .nav-item.nav-link {
    padding-right: 10px;
    padding-left: 10px;
    margin: 0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 1.4px;
    text-align: center;
    color: #5f5f69;
  }

  .nav-item.nav-link:focus,
  .nav-item.nav-link:hover {
    color: #AFAFB5;
  }

  .nav-item.nav-link.miles_ui_last_nav_item {
    padding-right: 10px;
  }

  .navbar-toggler {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 550px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "green" : "#ffffff"};
    width: 100vw;
    min-width: 360px;
    min-height: 80px;

    .shouldHide_GetMilesCTAInternal {
      display: none;
    }

    .navbar-toggler {
      display: block;
      transition: box-shadow 0s ease-in-out,-webkit-box-shadow 0s ease-in-out;
    }

    .container-height {
      height: 80px;
    }

    .container-fluid {
      margin: 0;
      padding: 19px 0 19px 30px;
      justify-content: space-between;
    }

    .mobile_menu_icon {
      margin-top: 0;
      margin-right: 0;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/header/mobile_menu_icon%402x.png) center no-repeat;
      background-size: contain;
      width: 27px;
      height: 13px;
      width: 30px;
      height: 30px;
      background-position: center center;
      transition: all 400ms ease 0s;
    }

    .mobile_menu_icon_collapse {
      margin-top: 0;
      margin-right: 0;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/header/mobile_menu_icon%402x.png) center no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
      background-position: center center;
      transform: rotate(-90deg);
      transition: 400ms ease all;
    }

    .nav-item.nav-link, .nav-item.nav-link.miles_ui_last_nav_item {
      text-align: right;
      padding: 0;
      padding-top: 20px;
    }

    .nav-item.nav-link:hover {
      color: #10af42;
    }

    .GetMilesCTA-root {
      margin-left: 0;
      margin-top: 0;
      margin-right: 0;
    }

    #miles_v2_navbar{
      position: absolute;
      right: 0;
      top: 80px;
      background: ${() => process.env.NODE_ENV === "development" ? "green" : "#ffffff"};
      width: 100vw;
      height: max-content;
      padding-right: 20px;
      padding-bottom: 20px;
      min-width: 360px;
    }

    .nav-item.nav-link.first {
      padding-top: 5px;
    }

    img {
      width: 92px;
      height: 26px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 551px) and (max-width : 768px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "red" : "#ffffff"};
    width: 100vw;
    min-height: 80px;
    
    .navbar-toggler {
      display: block;
      transition: box-shadow 0s ease-in-out,-webkit-box-shadow 0s ease-in-out;
    }

    .container-height {
      height: 80px;
    }

    .container-fluid {
      margin: 0;
      padding: 19px 30px 19px 30px;
      padding: 19px 0 19px 30px;
      justify-content: space-between;
    }

    .mobile_menu_icon {
      margin-top: 0;
      margin-right: 0;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/header/mobile_menu_icon%402x.png) center no-repeat;
      background-size: contain;
      width: 27px;
      height: 13px;
      width: 30px;
      height: 30px;
      background-position: center center;
      transition: all 400ms ease 0s;
    }

    .mobile_menu_icon_collapse {
      margin-top: 0;
      margin-right: 0;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/header/mobile_menu_icon%402x.png) center no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
      background-position: center center;
      transform: rotate(-90deg);
      transition: 400ms ease all;
    }

    .nav-item.nav-link, .nav-item.nav-link.miles_ui_last_nav_item {
      text-align: right;
      padding: 0;
      padding-top: 20px;
    }

    .nav-item.nav-link:hover {
      color: #10af42;
    }

    .GetMilesCTA-root {
      margin-left: 0;
      margin-top: 20px;
      margin-right: 25px;
      height: 100%;
      display: block;
      align-self: flex-start;
      display: inline-block;
    }

    #miles_v2_navbar{
      position: absolute;
      right: 0;
      top: 80px;
      background: ${() => process.env.NODE_ENV === "development" ? "red" : "#ffffff"};
      width: 100vw;
      height: max-content;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    .nav-item.nav-link.first {
      padding-top: 0;
    }

    img {
      margin: 0;
    }

  }

  @media only screen and (min-device-width : 551px) and (max-device-width : 768px) {
    .GetMilesCTA-root {
      margin-left: 0;
      margin-top: 0;
      margin-right: 0;
    }
  }

  @media only screen and (min-device-width : 551px) and (max-device-width : 768px) {
    .shouldHide_GetMilesCTAInternal {
      display: none;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 550px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "yellow" : "#ffffff"};
    padding-left: 60px;
    min-width: 100vw;
    min-height: 80px;
    width: 100vw;

    .navbar-toggler {
      display: block;
      transition: box-shadow 0s ease-in-out,-webkit-box-shadow 0s ease-in-out;
    }

    .container-height {
      height: 80px;
    }

    .container-fluid {
      margin: 0;
      padding: 19px 30px 19px 30px;
      padding: 19px 0 19px 30px;
      justify-content: space-between;
    }

    .mobile_menu_icon {
      margin-top: 0;
      margin-right: 0;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/header/mobile_menu_icon%402x.png) center no-repeat;
      background-size: contain;
      width: 27px;
      height: 13px;
      width: 30px;
      height: 30px;
      background-position: center center;
      transition: all 400ms ease 0s;
    }

    .mobile_menu_icon_collapse {
      margin-top: 0;
      margin-right: 0;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/header/mobile_menu_icon%402x.png) center no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
      background-position: center center;
      transform: rotate(-90deg);
      transition: 400ms ease all;
    }

    .nav-item.nav-link, .nav-item.nav-link.miles_ui_last_nav_item {
      text-align: right;
      padding: 0;
      padding-top: 20px;
    }

    .nav-item.nav-link:hover {
      color: #10af42;
    }

    .GetMilesCTA-root {
      margin-left: 0;
      margin-top: 20px;
      margin-right: 25px;
      height: 100%;
      display: block;
      align-self: flex-start;
      display: inline-block;
    }

    #miles_v2_navbar{
      position: absolute;
      right: 0;
      top: 80px;
      background: ${() => process.env.NODE_ENV === "development" ? "red" : "#ffffff"};
      width: 100vw;
      height: max-content;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    .nav-item.nav-link.first {
      padding-top: 0;
    }

    img {
      margin: 0;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "green" : "#ffffff"};
    padding-left: 61.5px;
    min-width: 100vw;
    min-height: 75px;
    width: 100vw;

    .container-height {
      height: 75px;
    }

    .container-fluid {
    }

    .nav-item.nav-link {
      padding-right: 15px;
      padding-left: 15px;
    }

    .GetMilesCTA-root {
      margin-left: 15px;
      margin-right: 40px;
    }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "red" : "#ffffff"};
    padding-left: 60px;
    min-width: 1440px;
    min-height: 75px;
    width: 1440px;
    
    .container-height {
      height: 75px;
    }

    .container-fluid {
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "pink" : "#ffffff"};
    min-width: 100vw;
    min-height: 75px;
    width: 100vw;
    padding: 0 calc(50vw - 720px);

    .container-height {
      height: 75px;
    }
    .container-fluid {
    }

    .navbar-brand {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
`;

interface StyledNavWrapProps {}
export const StyledNavWrap = styled("div")<StyledNavWrapProps>`

  .miles_ui_mock_nav {
    height: 75px;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 550px) {
    .miles_ui_mock_nav {
      height: 80px;
      width: 100%;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 551px) and (max-width : 768px) {
    .miles_ui_mock_nav {
      height: 80px;
      width: 100%;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 550px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_mock_nav {
      height: 80px;
      width: 100%;
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
  
`;