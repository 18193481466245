import styled from "styled-components";

interface Props {
  bgColor: string;
  dataSource?: {
    categories: {
      id: number,
      title: string,
      description: string,
      is_subscribed: boolean,
    }[]
  }[];
}
export const StyledCategoryGroup = styled("div")<Props>`
  width: 540px;
  margin: 0 auto;
  padding: 30px 0 10px 0;
  border-radius: 10px;
  background-color: ${(props) => props.bgColor};

  .mi-checkBox-description {
    width: 480px;
    margin: 8px 0 0 30px;
    font-family: 'Avenir Next';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.35px;
    color: #2d2f3b;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 100%;
    padding: 0 0 10px 0;

    .mi-checkBox-description {
      width: calc(100% - 50px);
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.25px;
    }
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    padding: 0 0 10px 0;

    .mi-checkBox-description {
      width: calc(100% - 50px);
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.25px;
    }
  }
`;