import styled from "styled-components";

interface Props {
  type: string
}
export const StyledEmailPreferenceMainBody = styled("div")<Props>`
  width: 600px;
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
  background-color: #ffffff;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -126px;
  padding: ${(props) => props.type === "unsubscribe" ? '50px 30px' : '30px'};
  margin-bottom: 130px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 300px;
    margin-top: -110px;
    padding: ${(props) => props.type === "unsubscribe" ? '20px 17.5px 20px 17.5px' : '20px 17.5px 40px 17.5px'};
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 300px;
    margin-top: -110px;
    padding: ${(props) => props.type === "unsubscribe" ? '20px 17.5px 20px 17.5px' : '20px 17.5px 40px 17.5px'};
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 768px) and (max-width : 1280px) {
    width: 600px;
    height: 580px;
    padding: 50px;
    background-color: #ffffff;
  }
`;