import styled from "styled-components";

export const Styled_home_wrap = styled("div")`
    margin: 0 auto;
    padding: 0;
    width: inherit;
    overflow-x: hidden;
    
    .miles_ui_home_pre_footer_blankSpace {
        height: 125px;
        width: 100%;
    }

    .hero_01_wrap {
        width: 1440px;
        box-sizing: border-box;
        margin: 0 auto;
        height: auto;
    }

    .hero_02_box_wrap {
        width: inherit;
        position: relative;
        background: #fff8e9;
    }

    .hero_02_wrap {
        width: 1440px;
        position: relative;
        height: 965px;
        display: flex;
        margin: 0 auto;
    }

    .hero_02_content_wrap {
        position: absolute;
        width: 492px;
        height: 374px;
        top: 292px;
        left: calc(50% - 642px);

        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        .hero_02_title {
            width: 492px;
            height: 52px;
            font-family: Noto Sans JP;
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 52px;
            letter-spacing: normal;
            color: #2d2f3b;
            margin-bottom: 30px;
        }

        .hero_02_sub_title {
            width: max-content;
            height: 40px;
            margin: 0;
            font-family: Noto Sans JP;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: 0.5px;
            color: #82828c;
        }

        .hero_02_assets_wrap_01 {
            margin-top: 30px;
            width: 328px;
            img {
                display: inline-block;
                width: 70px;
                height: 70px;
                margin-right: 16px;
            }

            img.last {
                margin-right: 0;
            }
        }

        .hero_02_assets_wrap_02 {
            margin-top: 15px;
            width: 328px;
            img {
                display: inline-block;
                width: 70px;
                height: 70px;
                margin-right: 16px;
            }

            img.last {
                margin-right: 0;
            }
        }
    }

    .hero_03_style {
        position: relative;
        width: 1440px;
        top: 5%;
        right: 35%;
    }

    .hero_04_content_wrap {
        width: 418px;
        height: 629px;
        position: absolute;
        top: 321px;
        right: 56px;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        .hero_03_title {
            width: 492px;
            height: 52px;
            font-family: Noto Sans JP;
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 52px;
            letter-spacing: normal;
            color: #2d2f3b;
            margin-bottom: 30px;
        }

        .hero_03_sub_title {
            width: max-content;
            height: 40px;
            margin: 0;
            font-family: Noto Sans JP;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: 0.5px;
            color: #82828c;
        }
        .redeem_miles_icon_img {
            display: inline-block;
            width: 418px;
            height: auto;
            margin-top: 30px;
        }
    }

    .hero_04_box_wrap {
        margin: 0 auto;
        width: inherit;
        background: #fffaf5;
    }

    .hero_04_wrap {
        width: 1440px;
        position: relative;
        height: 966px;
        display: flex;
        margin: 0 auto;
    }

    .miles_ui_screen_larger_than_1440 {
        display: flex;
    }

    .miles_ui_screen_1150_x_1440 {
        display: block;
    }

    .miles_ui_screen_768_x_1150 {
        display: block;
    }

    .miles_ui_screen_360_x_768 {
        display: block;
    }

    .miles_ui_screen_larger_than_1150 {
        display: block;
    }

    .miles_ui_screen_768_to_1150 {
        display: none;
    }

    .miles_ui_screen_less_than_768_px {
        display: none;
    }

    .hero_1_picture {
        justify-content: center;
        position: relative;
        width: auto;
        height: 564px;
    }

    .hero_1_img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        margin-top: 48px;
    }

    .hero_2_picture {
        display: block;
        justify-content: center;
        position: absolute;
        width: 832px;
        height: 684px;
        top: 181.2px;
        left: 553px;
    }

    .hero_2_img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    .hero_3_picture {
        display: block;
        justify-content: center;
        position: absolute;
        width: 762px;
        height: 741px;
        top: 141px;
        left: 80px;
    }

    .hero_3_img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    /* mini Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        min-width: 360px;
        overflow-x: hidden;
        .miles_ui_home_pre_footer_blankSpace {
            height: 92px;
            width: 100%;
        }

        .hero_01_wrap {
            width: 100vw;
        }
        
        .hero_02_wrap {
            width: 100vw;
            height: 631px;
        }

        .hero_02_style {
            width: 100vw;
        }

        .hero_04_wrap {
            width: 100vw;
            height: 631px;
        }

        .miles_ui_screen_larger_than_1440 {
            display: none;
        }
    
        .miles_ui_screen_1150_x_1440 {
            display: none;
        }
    
        .miles_ui_screen_768_x_1150 {
            display: none;
        }
    
        .miles_ui_screen_360_x_768 {
            display: flex;
        }

        .hero_02_content_wrap {
            top: 56px;
            width: 300px;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
    
            .hero_02_title {
                width: auto;
                height: auto;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.75px;
                text-align: center;
                color: #2d2f3b;
                margin-bottom: 0;
            }
    
            .hero_02_sub_title {
                width: auto;
                height: 30px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.61px;
                text-align: center;
                color: #5f5f69;
            }

            .miles_ui_screen_larger_than_1150 {
                display: none;
            }
        
            .miles_ui_screen_768_to_1150 {
                display: none;
            }
        
            .miles_ui_screen_less_than_768_px {
                display: inline-block;
            }

            .hero_02_assets_wrap {
                left: 0;
                right: 0;
                margin: auto;
                width: fit-content;
            }
    
            .hero_02_assets_wrap_01 {
                margin-top: 11px;
                width: auto;
                display: inline-block;
                img {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    margin-right: 5px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
    
            .hero_02_assets_wrap_02 {
                margin-top: 11px;
                margin-left: 5px;
                width: auto;
                display: inline-block;
                img {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    margin-right: 5px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
        }

        .hero_02_style {
            bottom: 0;
            height: 100vh;
        }

        .hero_03_style {
            position: relative;
            width: 1440px;
            top: 5%;
            right: 35%;
        }
    
        .hero_04_content_wrap {
            width: 275px;
            height: auto;
            position: absolute;
            top: 57px;
            right: calc(50vw - 132px);
    
            .hero_03_title {
                width: auto;
                height: auto;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.75px;
                text-align: center;
                color: #2d2f3b;
                margin-bottom: 5px;
            }

            .miles_ui_screen_larger_than_1150 {
                display: none;
            }
        
            .miles_ui_screen_768_to_1150 {
                display: none;
            }
        
            .miles_ui_screen_less_than_768_px {
                display: block;
            }
    
            .hero_03_sub_title {
                width: 290px;
                height: auto;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.61px;
                text-align: center;
                color: #5f5f69;
            }
            .redeem_miles_icon_img {
                display: inline-block;
                width: 300px;
                margin: 0 auto;
                margin-top: 27px;
                display: flex;
            }
        }


        .hero_1_picture {
            height: 343px;
        }

        .hero_1_img{
            margin-top: 10px;
        }

        .hero_2_picture {
            height: 290px;
            width: 340px;
            top: 286px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .hero_3_picture {
            height: 254px;
            width: 300px;
            top: 316px;
            left: 30px;
        }

    }

        /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .miles_ui_home_pre_footer_blankSpace {
            height: 130px;
            width: 100%;
        }

        .hero_01_wrap {
            width: 100vw;
        }
        
        .hero_02_wrap {
            width: 100vw;
            height: 631px;
        }

        .hero_02_style {
            width: 100vw;
        }

        .hero_04_box_wrap {
            height: 631px;
        }

        .hero_04_wrap {
            width: 100vw;
            height: 631px;
        }

        .miles_ui_screen_larger_than_1440 {
            display: block;
        }
    
        .miles_ui_screen_1150_x_1440 {
            display: block;
        }
    
        .miles_ui_screen_768_x_1150 {
            display: block;
        }
    
        .miles_ui_screen_360_x_768 {
            display: flex;
        }

        .hero_02_content_wrap {
            top: 56px;
            width: 358px;
            margin: 0 auto;
            left: 0;
            right: 0;
    
            .hero_02_title {
                width: auto;
                height: auto;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.75px;
                text-align: center;
                color: #2d2f3b;
                margin-bottom: 0;
            }
    
            .hero_02_sub_title {
                width: auto;
                height: 30px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.61px;
                text-align: center;
                color: #5f5f69;
            }

            .miles_ui_screen_larger_than_1150 {
                display: none;
            }
        
            .miles_ui_screen_768_to_1150 {
                display: none;
            }
        
            .miles_ui_screen_less_than_768_px {
                display: inline-block;
            }

            .hero_02_assets_wrap {
                left: 0;
                right: 0;
                margin: auto;
                width: fit-content;
            }
    
            .hero_02_assets_wrap_01 {
                display: inline-block;
                margin-top: 11px;
                width: auto;
                img {
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                    margin-right: 5px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
    
            .hero_02_assets_wrap_02 {
                display: inline-block;
                margin-top: 11px;
                margin-left: 5px;
                width: auto;
                img {
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                    margin-right: 5px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
        }

        .hero_02_style {
            bottom: 0;
            height: 100vh;
        }

        .hero_03_style {
            position: relative;
            width: 1440px;
            top: 5%;
            right: 35%;
        }
    
        .hero_04_content_wrap {
            width: 275px;
            height: auto;
            position: absolute;
            top: 57px;
            right: calc(50vw - 132px);
    
            .hero_03_title {
                width: auto;
                height: auto;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.75px;
                text-align: center;
                color: #2d2f3b;
                margin-bottom: 5px;
            }

            .miles_ui_screen_larger_than_1150 {
                display: none;
            }
        
            .miles_ui_screen_768_to_1150 {
                display: none;
            }
        
            .miles_ui_screen_less_than_768_px {
                display: block;
            }
    
            .hero_03_sub_title {
                width: 290px;
                height: auto;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.61px;
                text-align: center;
                color: #5f5f69;
            }
            .redeem_miles_icon_img {
                display: inline-block;
                width: 300px;
                margin: 0 auto;
                margin-top: 27px;
                display: flex;
            }
        }

        .hero_1_picture {
            height: 343px;
        }

        .hero_1_img{
            margin-top: 8px;
        }

        .hero_2_picture {
            height: 305px;
            width: 358px;
            top: 287px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .hero_3_picture {
            height: 277px;
            width: 328px;
            top: 296.8px;
            left: 0;
            right: 0;
            margin: auto;
        }

    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {

        .miles_ui_home_pre_footer_blankSpace {
            height: 181px;
            width: 100%;
        }

        .hero_01_wrap {
            width: 100vw;
        }
        
        .hero_02_wrap {
            width: 100vw;
        }

        .hero_02_style {
            width: 100vw;
            top: 0;
        }

        .hero_04_wrap {
            width: 100vw;
        }

        .miles_ui_screen_larger_than_1440 {
            display: block;
        }
    
        .miles_ui_screen_1150_x_1440 {
            display: block;
        }
    
        .miles_ui_screen_768_x_1150 {
            display: flex;
        }
    
        .miles_ui_screen_360_x_768 {
            display: block;
        }

        .hero_02_content_wrap {
            top: 80px;
            width: 550px;
            margin: 0 auto;
            left: 0;
            right: 0;
    
            .hero_02_title {
                width: auto;
                height: 46px;
                font-size: 32px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: 1px;
                text-align: center;
                color: #2d2f3b;
                margin-bottom: 20px;
            }
    
            .hero_02_sub_title {
                width: auto;
                height: 35px;
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.64;
                letter-spacing: 0.96px;
                text-align: center;
                color: #82828c;
            }

            .miles_ui_screen_larger_than_1150 {
                display: none;
            }
        
            .miles_ui_screen_768_to_1150 {
                display: inline-block;
            }
        
            .miles_ui_screen_less_than_768_px {
                display: none;
            }

            .hero_02_assets_wrap {
                left: 0;
                right: 0;
                margin: auto;
                width: 520px;
            }
    
            .hero_02_assets_wrap_01 {
                margin-top: 24px;
                width: auto;
                float: left;
                img {
                    display: inline-block;
                    width: 72px;
                    height: 72px;
                    margin-right: 16px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
    
            .hero_02_assets_wrap_02 {
                margin-top: 24px;
                margin-left: 16px;
                width: auto;
                float: left;
                img {
                    display: inline-block;
                    width: 72px;
                    height: 72px;
                    margin-right: 16px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
        }


        .hero_03_style {
            position: relative;
            width: 1440px;
            top: 5%;
            right: 35%;
        }
    
        .hero_04_content_wrap {
            width: 647px;
            height: auto;
            position: absolute;
            top: 80px;
            right: calc(50vw - 325px);
    
            .hero_03_title {
                width: auto;
                height: 46px;
                font-size: 32px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: 1px;
                text-align: center;
                color: #2d2f3b;
                margin-bottom: 20px;
            }

            .miles_ui_screen_larger_than_1150 {
                display: none;
            }

            .miles_ui_screen_768_to_1150 {
                display: block;
            }
    
            .hero_03_sub_title {
                width: 647px;
                height: 36px;
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.64;
                letter-spacing: 0.96px;
                text-align: center;
                color: #82828c;
            }
            .redeem_miles_icon_img {
                display: inline-block;
                width: 448px;
                margin: 0 auto;
                margin-top: 50px;
                display: flex;
            }
        }


        .hero_1_picture {
            height: 581px;
        }

        .hero_1_img{
            margin-top: 53px;
        }

        .hero_2_picture {
            height: 517px;
            width: 608px;
            top: 406px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .hero_3_picture {
            height: 410px;
            width: 586px;
            top: 474px;
            left: 0;
            right: 0;
            margin: auto;
        }

    }
  
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .miles_ui_home_pre_footer_blankSpace {
            height: 134px;
            width: 100%;
        }

        .hero_01_wrap {
            width: 100vw;
        }

        .hero_02_wrap {
            width: 100vw;
        }

        .hero_02_style {
            width: 100vw;
            height: 100vh;
            top: 0;
        }

        .hero_02_content_wrap {
            left: calc(50% - 540px);
    
            .hero_02_title {
                margin-bottom: 20px;
            }
    
            .hero_02_sub_title {}
    
            .hero_02_assets_wrap_01 {
                margin-top: 34px;
                width: auto;
                img {
                    display: inline-block;
                    width: 74px;
                    height: 74px;
                    margin-right: 16px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
    
            .hero_02_assets_wrap_02 {
                margin-top: 15px;
                width: 328px;
                img {
                    display: inline-block;
                    width: 70px;
                    height: 70px;
                    margin-right: 16px;
                }
    
                img.last {
                    margin-right: 0;
                }
            }
        }

        .hero_04_wrap {
            width: 100vw;
        }

        .miles_ui_screen_larger_than_1440 {
            display: block;
        }
    
        .miles_ui_screen_1150_x_1440 {
            display: flex;
        }
    
        .miles_ui_screen_768_x_1150 {
            display: block;
        }
    
        .miles_ui_screen_360_x_768 {
            display: block;
        }

        .hero_1_picture {
            height: 504px;
        }

        .hero_1_img{
            margin-top: 60px;
        }

        .hero_2_picture {
            height: 610px;
            width: 742px;
            top: 199.5px;
            left: 451.1px;
        }

        .hero_3_picture {
            height: 627px;
            width: 593px;
            top: 232px;
            left: 115px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {

    }
`;