import styled from "styled-components";
import config from "../../utils/config";

const {
  reward_share_title_width_on_1150,
  reward_share_title_width_on_600,
  reward_share_title_width_on_360,
  fontFamily_Gilroy_Medium,
} = config;

export const MainWrap = styled("div")`
  width: 1440px;
  min-height: 100vh;
  overflow: visible;
  margin: 0;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: max-content;
    max-width: 100vw;
    height: 100%;
    min-height: 100%;
    overflow: visible;

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: max-content;
    max-width: 100vw;
    height: 100%;
    min-height: 100%;
    overflow: visible;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 768px;
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    width: 768px;
  }
  
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    width: 1280px;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    width: 1280px;
  }
`;

export const StyledAdBoxWrap = styled("div")`
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: max-content;
    margin: 0 auto;
    margin-top: 10px;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: max-content;
    margin: 0 auto;
    margin-top: 10px;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: max-content;
    margin: 0 auto;
    margin-top: 20px;
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    width: max-content;
    margin: 0 auto;
    margin-top: 20px;
  }
`;

export const StyledAppStoreIconBoxWrap = styled("div")`
  width: inherit;
  margin-top: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "30px" : "53px"};

  a {
    text-decoration: none;
  }

  .app_store_url_wrap {
    margin-top: 54px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    box-sizing: border-box;
    
    .app_store_box {
      width: 342px;
      width: auto;
      height: 54px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      box-sizing: border-box;
      &.apple {
        margin-right: 17.5px;
      }

      .app_store_logo {
        margin-right: 10px;
        min-width: 50px;
        height: 50px;
      }

      .app_store_rating_wrap {
        .rating_title {
          width: max-content;
          height: 17px;
          margin-top: 5px;
          font-family: Gilroy-SemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d2f3b;
        }

        .edsp.rating_title {
          width: max-content;
          height: 17px;
          margin-top: 13px;
          font-family: Gilroy-SemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d2f3b;
        }

        .rating_star_wrap {
          margin-top: 4px;
          .star_icon_img {
            width: 15px;
            height: 15px;
            margin-right: 3px;
          }

          .rating_number {
            height: auto;
            margin-left: 4px;
            font-family: Gilroy-Medium;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9b9ba5;
            vertical-align: middle;
          }
          
          .inline_block {
            display: inline-block;
          }
        
        }
        
      }

    }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: max-content;
    margin: 0 auto;
    display: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "none" : "visible"};

    
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    width: max-content;
    margin: 0 auto;
    display: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "none" : "visible"};
  }
`;

export const StyledListWrap = styled("div")`
  margin-top: 14px;
  width: 430px;
  height: 57px;
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "290px" : "320px"};
    height: 92px;
    margin: 0 auto;
    margin-bottom: 54px;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "290px" : "320px"};
    height: 92px;
    margin: 0 auto;
    margin-bottom: 54px;
  }
  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: max-content;
    margin: 0 auto;
    margin-top: 20px;
  }

  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    width: max-content;
    margin: 0 auto;
    margin-top: 20px;
  }
`;

export const StyledRewardShareMainBody = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 61px;
  margin-left: 57px;
  margin-top: 50px;
  height: 610px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    margin-top: 14px;
    margin-right: 24px;
    margin-left: 24px;
    align-items: center;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    margin-top: 14px;
    margin-right: 24px;
    margin-left: 24px;
    align-items: center;
  }
  
  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    margin-top: 14px;
    margin-right: 0;
    margin-left: 0;
    align-items: center;
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    margin-top: 14px;
    margin-right: 0;
    margin-left: 0;
    align-items: center;
  }
`;

export const StyledRewardShareMainBodyBottom = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 61px;
  margin-left: 57px;
  margin-top: 50px;
  margin-bottom: 75px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin-top: -25px;
    flex-direction: row;
    justify-content: center;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    margin-top: -25px;
    flex-direction: row;
    justify-content: center;
  }
  
  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    margin-top: -35px;
    justify-content: center;
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    margin-top: -35px;
    justify-content: center;
  }
  
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    margin-top: 50px;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    margin-top: 50px;
  }
`;

export const StyledRewardShareMainMiddleBody_1 = styled("div")`
  width: 671px;
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: inherit;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: inherit;
  }
`;

export const StyledRewardShareMainMiddleBody_2 = styled("div")`
  width: 645px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    display: none;
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    display: none;
  }
`;

export const StyledRewardShareMainMiddleBody_ImgCard = styled("div")`
  display: none;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: block;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: block;
  }
  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    display: block;
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    display: block;
  }
`;

export const StyledRewardShareTextCard = styled("div")`
  width: 671px;
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: inherit;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: inherit;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin-bottom: 90px;
  }

  @media only screen and (min-width : 768px) and (max-width : 1150px) {
    margin-bottom: 90px;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    margin-bottom: 90px;
  }

`;

export const StyledRewardShareTitle = styled("div")`
  width: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "560px" : "590px"};
  height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "110px" : "160px"};
  font-family: ${() => fontFamily_Gilroy_Medium};
  font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "40px" : "50px"};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #343434;

  /* mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: ${() => reward_share_title_width_on_360};

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: ${() => reward_share_title_width_on_600};
    margin: 0 auto;
    height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "22px" : "54px"};
    font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "16px" : "20px"};
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-bottom: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "20px" : "15px"};
    margin-top: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "8px" : "0"};
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: ${() => reward_share_title_width_on_600};
    margin: 0 auto;
    height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "22px" : "54px"};
    font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "16px" : "20px"};
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-bottom: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "20px" : "15px"};
    margin-top: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "8px" : "0"};
  }
  
  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: ${() =>reward_share_title_width_on_1150};
    margin: 0 auto;
    height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "40px" : "130px"};
    font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "32px" : "50px"};
    line-height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "1.25" : "1.3"};
    letter-spacing: 1.02px;
    text-align: center;
    color: #3b3b3b;
    margin-bottom: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "50px" : "14px"};
    margin-top: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "32px" : "0"};
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    width: ${() => reward_share_title_width_on_1150};
    margin: 0 auto;
    height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "40px" : "130px"};
    font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "32px" : "50px"};
    line-height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "1.25" : "1.3"};
    letter-spacing: 1.02px;
    text-align: center;
    color: #3b3b3b;
    margin-bottom: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "50px" : "14px"};
    margin-top: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "32px" : "0"};
  }
  
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    width: 560px;
    height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "140px" : "150px"};
    font-family: 'Avenir Next';
    font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "40px" : "50px"};
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.83px;
    color: #343434;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    width: 560px;
    height: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "140px" : "150px"};
    font-family: 'Avenir Next';
    font-size: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "40px" : "50px"};
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.83px;
    color: #343434;
  }
`;

export const StyledSendDownloadLinkWrap = styled("div")`
  margin-top: 42px;
  display: flex;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    margin: 0 auto;
    width: max-content;
    margin-top: 42px;
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    margin: 0 auto;
    width: max-content;
    margin-top: 42px;
  }
`;