import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {} = config;

export interface Props {
  dateText: React.ReactNode;
}

const Component: React.FC<Props> = observer((props) => {
  return (
    <>
      <Styled_body_wrap className="">
        <div className="text-box">
          {props.dateText}
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
