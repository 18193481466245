import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";

let firstRender = true;

const {
  currentSupportEmailAddress,
} = config;

function bookmark(id: string) {
  return (
    <div id={id} style={{
      position: "relative",
      top: "calc(0px - var(--nav-bar-height))",
    }} />
  );
}

const SUPPORT_EMAIL_LINK = <a href={`mailto:${currentSupportEmailAddress}`} target="_blank">{currentSupportEmailAddress}</a>;

const JP_TOS_LINK = extLink("https://www.getmiles.com/jp/tos", "Terms of Service")
const JP_PRIVACY_LINK = extLink("https://www.getmiles.com/jp/privacy", "Japan Privacy Policy")

const SUPPLEMENTAL_STATE_PRIVACY_NOTICE_LINK = extLink("/supplemental-state-privacy-notice", <>Supplemental State Privacy Notice</>);

const SUPPLEMENTAL_NOTICE_FOR_CANADA_ID = "supplemental-notice-for-canada";
const SUPPLEMENTAL_NOTICE_FOR_CANADA_LINK = localLink(SUPPLEMENTAL_NOTICE_FOR_CANADA_ID, "Supplemental Notice for Canada, Australia and New Zealand")

const REGIONAL_PRIVACY_NOTICES_ID = "regional-privacy-notices";
const REGIONAL_PRIVACY_NOTICE_LINK = localLink(REGIONAL_PRIVACY_NOTICES_ID, <>Regional Privacy Notices</>);

const YOUR_RIGHTS_AND_CHOICES_ID = "your-rights-and-choices";
const YOUR_RIGHTS_AND_CHOICES_LINK = localLink(YOUR_RIGHTS_AND_CHOICES_ID, <>Your Rights and Choices</>);

const OPEN_SIGNAL_LEARN_MORE_LINK = extLink(
  "https://www.opensignal.com/privacy-policy-mobile-applications#:~:text=We%20prevent%20user%20identification%3B%20we,individual%20or%20their%20specific%20device.&text=Opensignal%20aggregates%20or%20depersonalizes%20all%20data%20prior%20to%20sharing",
  <>Learn More</>
);

const OUTLOGIC_PRIVACY_POLICY_URL = "https://outlogic.io/privacy-policy/";
const OUTLOGIC_TRUSTED_PARTNERS_URL = "https://outlogic.io/trusted-partners/";
const OUTLOGIC_OPT_OUT_URL = "https://outlogic.io/opt-out-form/";

function extLink(url: string, content: JSX.Element | string) {
  return (
    <a href={url} target="_blank">{content}</a>
  );
}

function link(url: string, content: JSX.Element | string) {
  return (
    <a href={url}>{content}</a>
  );
}

function localLink(id: string, content: JSX.Element | string) {
  return link(`#${id}`, content);
}

function H1(content: JSX.Element) {
  return <div className="miles_ui_privacy_title_01">{content}</div>;
}

function H2(content: JSX.Element) {
  return <div className="miles_ui_privacy_title_02">{content}</div>
}

function H3(content: JSX.Element) {
  return <div className="miles_ui_privacy_title_03">{content}</div>
}

function P(content: JSX.Element) {
  return <div className="para_content inner_para_01">{content}</div>;
}

function bulletPointList(items: JSX.Element[]) {
  const listItems = items.map((item, index) => {
    const className = index === 0 ? "miles_ui_privacy_sub_para first" : "miles_ui_privacy_sub_para";
    return (
      <div className={className} key={index}>
        <div className="miles_ui_para_with_dot">
          <span className="dot"></span> 
          <div className="miles_ui_para_with_dot_main_content">
            {item}
          </div>
        </div>
      </div>
    );
  });
  return <>{listItems}</>;
}

function contactUs() {
  return <>
    {H3(<>Contact Us</>)}

    {P(<>If you have any questions about this Privacy Policy, please contact us at:</>)}

    {P(SUPPORT_EMAIL_LINK)}

    {P(<>or</>)}

    {P(<>
      ConnectIQ Labs, Inc. (d/b/a Miles)<br />
      4064 Rivermark Parkway<br />
      Santa Clara, California, 95054
    </>)}
  </>;
}


function supplementalNoticeForCanada() {
  return <>
  {H2(<>Supplemental Notice for Canada, Australia and New Zealand</>)}

  <div className="miles_ui_special_gap_02"></div>

  {H3(<>Your Rights Regarding Your Personal Information</>)}

  {H3(<><i>The Right to Access</i></>)}

  {P(<>You have the right to access personal information that we may collect or retain about you. If requested, we shall provide you with a copy of the personal information about you that we have collected or inferred.</>)}

  {P(<>Please note that such rights are not absolute. There are instances where applicable law or regulatory requirements allow or require us to decline to provide some or all of the personal data that we hold about you. In the event that we cannot accommodate your request, we will inform you of the reasons why, subject to any legal or regulatory restrictions.</>)}

  {H3(<><i>The Right to Correct/Rectify</i></>)}

  {P(<>You have the right to request correction of any inaccurate personal information. Upon verifying the validity of a valid consumer correction request, we will use commercially reasonable efforts to correct your personal information as directed, taking into account the nature of the personal information and the purposes of maintaining your personal information.</>)}

  {H3(<>Right to Delete</>)}

  {P(<>When you exercise your right to deletion of your personal information, we will delete your personal information, subject to some instances where your personal information will be maintained for us to fulfill our legal obligations.</>)}

  {P(<>You also have the right to lodge a complaint with the relevant data protection or privacy authority, such as the Office of the Privacy Commissioner.</>)}

  {H3(<>International Transfer and Storage of Information</>)}

  {P(<>Personal information will be processed in the United States to fulfil the purpose for which we collected the personal information. As a result, your personal information will be transferred to the United States, which may have different data protection rules than in your place of residence.  While such information is in the U.S., it is subject to the laws of the U.S., and may be subject to disclosure to the governments, courts or law enforcement or regulatory agencies of the U.S. pursuant to its laws.</>)}

  {contactUs()}
  </>;
}



const MilesWeb_PrivacyPage202301: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_PrivacyPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_PrivacyPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);
  
  return (
    <Styled_body_wrap>
      <div className="privacy_top_bg display_block"></div>
      <div className="privacy_top_hero display_block"></div>

      <div className="main_content">
        <div className="privacy_title">Privacy Policy</div>
        <div className="privacy_update_date">Last Updated: July 19, 2024</div>

        <div className="para_content top_intro_para">
        This Privacy Policy explains how ConnectIQ Labs, Inc. (d/b/a Miles), a Delaware corporation with offices at 4064 Rivermark Parkway, Santa Clara, California, 95054 (<b>"Miles"</b>, <b>“we”</b>, or <b>“us”</b>) collects, uses, discloses, or otherwise processes information about you through its mobile applications, websites, and other online products and services (collectively, the "Services") or when you otherwise interact with us. The state or country where you reside may have separate Privacy Policy terms. Please visit the {REGIONAL_PRIVACY_NOTICE_LINK} section below to review supplemental or separate terms that may apply to you.
        </div>

        <div className="para_content top_intro_para">
        We may change this Privacy Policy from time to time. If we make changes, we will revise the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). When we want to use your personal information for a purpose not previously identified to you at the time of collection, we will notify you prior to our use of such information for this new purpose. 
        </div>

        <div className="para_content top_intro_para">
        We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you. By submitting your personal information to us, by using our website and mobile applications, or by otherwise accessing or using the Services, you acknowledge that you have read and understand this Privacy Policy, including our collecting, using, disclosing or otherwise processing your personal information.
        </div>

        <div className="miles_ui_privacy_title_01">Collection of Information</div>

        <div className="para_content top_intro_para">
        We collect information from you in the following ways, as described in more detail below: 
        </div>

        {bulletPointList([
          <>When you provide personal information to us directly, such as when you sign up for the Services and create a profile, or upload or create information.</>,
          <>Through automatic collection when you use the Services, through cookies or similar technologies. This may take place when the app is in use or running in the background, depending on your device privacy settings.</>,
          <>From other third party sources, such as third party companies we link to, our partners, and service providers.</>,
        ])}

        <div className="miles_ui_privacy_title_02">Information You Provide to Us</div>

        <div className="para_content inner_para_01">
        We collect information you provide directly to us. For example, we collect information when you create an account, participate in any interactive features of the Services, fill out a form, upload a profile photo, participate in a contest or promotion, redeem a reward, request customer support or otherwise communicate with us. The types of information we may collect include your name, phone number, email address, profile photo, and any other information you choose to provide.
        </div>

        <div className="miles_ui_privacy_title_02">Information About Your Use of the Services</div>

        <div className="miles_ui_privacy_title_03">Usage Information</div>

        <div className="para_content inner_para_01">
        Subject to your device settings, we collect information about your use of the Services and from your device when the app is running in the background.
        </div>

        <div className="miles_ui_privacy_title_03">Automatically Collected Information</div>

        <div className="para_content inner_para_01">
        When you access or use our Services, we automatically collect information about you, including:
        </div>

        <div className="miles_ui_privacy_sub_para first">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              Log and Device Information: We collect log information about your device and your use of the Services, including the type of operating system and version, app version, unique device identifiers such as device ID and mobile ad ID, type of device, device settings, IP address, time zone, carrier, mobile advertising ID, app names and/or identifiers, and in-app or online activity (user path, access times, pages viewed, etc.).
            </div>
          </div>
        </div>

        <div className="miles_ui_privacy_sub_para">
          <div className="miles_ui_para_with_dot">
            <span className="dot"></span> 
            <div className="miles_ui_para_with_dot_main_content">
              Location Information: In accordance with your device permissions, we use various technologies to determine the precise location of your device, including WiFi signals or Bluetooth Low Energy devices in your proximity and cell towers in your vicinity. We also may collect information about the movement of your device, including travel data captured by your device (e.g., acceleration). We may also receive information from other sensors on your device such as the compass and gyroscope. We may also collect IP address, GPS, and other information from sensors that may provide us with information on nearby devices.  For more details, please see "{YOUR_RIGHTS_AND_CHOICES_LINK}" below.
            </div>
          </div>
        </div>

        <div className="miles_ui_privacy_title_03">Information We Collect from Other Sources</div>
        <div className="para_content inner_para_01">
          We may obtain information from other sources and combine that with information we collect to create a profile about you. For example, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, gender, photos, account information, and friends lists, in accordance with the authorization procedures determined by such social media site. You should always check the privacy settings and privacy policies in these third-party services to understand what data may be disclosed to us.
        </div>

        <div className="miles_ui_privacy_title_03">Use of Information</div>
        <div className="para_content inner_para_01">
        We use the information we collect to provide, maintain, and improve our Services, such as to facilitate the performance of primary functions of the Services (e.g., rewarding miles for travel and other activities, and facilitating miles redemptions) or to manage your account. We also use the information we collect to:
        </div>

        {bulletPointList([
          <>Send you technical notices, updates, security alerts and support and administrative messages and to respond to your comments, questions and customer service requests;</>,
          <>Based on the personal information we collect, make inferences about you to create profiles;</>,
          <>Communicate with you about products, services, offers, promotions, and events offered by Miles or third parties, or provide news and information related to the Services or third parties we think will be of interest to you;</>,
          <>Monitor and analyze trends, your app usage, and your activities in connection with our Services;</>,
          <>Detect, investigate and prevent fraudulent transactions and other illegal activities, and protect the rights and property of Miles, users and/or partners;</>,
          <>Personalize and improve the Services and provide advertisements, content, or features that match user profiles or interests;</>,
          <>Customize and measure ads in apps and other advertising;</>,
          <>Cybersecurity</>,
          <>For market, civic, government or other research regarding aggregated human foot and traffic patterns;</>,
          <>Generate proprietary pseudonymized identifiers tied to the information we collect, such as a persistent user ID;</>,
          <>Facilitate Miles and third-party contests, sweepstakes, and promotions and process and deliver entries and rewards;</>,
          <>Identify and remediate technical issues impacting our Service;</>,
          <>Comply with our legal obligations;</>,
          <>Enforce, or otherwise use in connection with, the {link("/tos", <>Terms of Service</>)}; and</>,
          <>Carry out any other purpose described to you at the time the information was collected.</>,
        ])}

        <div className="miles_ui_special_gap_01"></div>

        <div className="miles_ui_privacy_title_03">Sharing of Information</div>

        <div className="para_content inner_para_01">
        We may share information about you as follows or as otherwise described in this Privacy Policy.
        </div>

        {bulletPointList([
          <>With vendors, consultants and other service providers who need access to such information to carry out work on our behalf, including customer service, technical support, data hosting, data storage and processing, research and analytics, payment processing, email services, marketing campaigns, customer relations management, surveys and contests, fraud detection, and cybersecurity;</>,
          <>With our third party partners ("Partners") who work with us to provide certain services and benefits to Miles and/or our users; including but not limited to working with Opensignal to understand and improve connectivity and app experience on wireless networks, and to help make your Miles experience more consistent and reliable in more places. {OPEN_SIGNAL_LEARN_MORE_LINK}.</>,

          <>Upon receiving your consent, the information we collect, including device data, identifiers and precise location data, may sold to third parties including advertising and data companies, brands, and analytics, non-profit, financial, government and research organizations and/or other businesses that do location-based analytics. Please note that such information may be further shared, disclosed, or sold by these third parties for humanitarian aid, third party marketing, ad targeting and analytics purposes. Also, those third parties may use the information they receive from us for their own marketing purposes and may disclose it to their service providers. You may opt out of this type of sharing on our mobile application in the privacy settings or by contacting us at {SUPPORT_EMAIL_LINK}.
          <br />
          {bulletPointList([
            <>One such third party is Outlogic, LLC, a location data and other data provider. You can review Outlogic’s privacy notice {extLink(OUTLOGIC_PRIVACY_POLICY_URL, "here")}, or by visiting {extLink(OUTLOGIC_PRIVACY_POLICY_URL, OUTLOGIC_PRIVACY_POLICY_URL)}. For GDPR purposes, you can review Outlogic’s Service Providers & Trusted Partners list here, or at {extLink(OUTLOGIC_TRUSTED_PARTNERS_URL, OUTLOGIC_TRUSTED_PARTNERS_URL)}.</>,
            <>To opt-out of Outlogic’s use and sale of the personal information noted above, please go to Outlogic’s “Your Privacy Choices” page at {extLink(OUTLOGIC_OPT_OUT_URL, OUTLOGIC_OPT_OUT_URL)}. To opt-out of Miles’ disclosure of this personal information to Outlogic, you can opt-out in your app settings or email us at {SUPPORT_EMAIL_LINK}, or for residents of certain locations, please also refer to the {REGIONAL_PRIVACY_NOTICE_LINK} for more information. </>,
          ])}
          </>,
          <>In response to a request for information if we reasonably believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements;</>,
          <>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Miles or others, including to detect fraud;</>,
          <>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;</>,
          <>Between and among Miles and our current and future parents, affiliates, subsidiaries and other companies under common control and ownership in furtherance of our provision of services to you; </>,
          <>Pursuant to your request or consent; and</>,
          <>As aggregated or de-identified information, which cannot reasonably be used to identify you.</>,
        ])}

        <div className="miles_ui_special_gap_02"></div>

        <div id="tracking" className="miles_ui_privacy_title_03">Information Collected by Cookies and Other Tracking Technologies</div>

        {P(<>Like most online services and mobile applications, we may use cookies and other technologies such as web beacons, web storage, and unique advertising identifiers, to collect information about your activity, browser, and device, and as described in this Privacy Policy. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, count visits, and deliver personalized advertising. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits and understand usage and campaign effectiveness.</>)}

        {P(<>The cookies we use may include:</>)}

        <div className="indent">
        {P(<><u>Essential Cookies</u> that enable the website to function properly and assist in navigating pages and accessing Services. Without these cookies, the website will not function properly.</>)}
        {P(<><u>Analytical Cookies</u> that collect information about how users interact with a website. This may include the page that users visit most often and whether users get error messages from certain pages. These cookies help improve users’ browsing experience, but opting out, blocking or deleting these cookies will not render the website unusable.</>)}
        {P(<><u>Advertising Cookies.</u> We may allow others to provide analytics services and serve advertisements on our behalf across the internet and in applications. These entities may use cookies, web beacons, device identifiers and other technologies to collect information about your use of the Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked and conversion information. This information may be used by Miles and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity. Opting out, blocking or deleting these Cookies will not affect website functionality. </>)}
        {P(<>If you would like to opt-out of our use of non-essential cookies, trackers or similar technology, please see the “{YOUR_RIGHTS_AND_CHOICES_LINK}” section below.</>)}
        </div>

        {/* <div className="miles_ui_special_gap_02"></div> */}

        {H3(<>Social Sharing Features</>)}

        {P(<>The Services may offer social sharing features and other integrated tools (such as the Facebook "Like" button), which let you share actions you take on our Services with other media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, and to adjust your privacy settings on those platforms, please visit the privacy policies of the entities that provide these features. This privacy policy does not address the privacy, security or other practices of these third-party service providers.</>)}

        {H3(<>Third-Party Services</>)}

        {P(<>Miles provides you with opportunities to connect with third parties or third-party applications or services, such as through third-party promotions or challenges. If you choose to interact with such third parties outside of the Services or use any such third-party applications or services, upon your request, we may facilitate sharing of your information, and such third parties may contact you directly as necessary. Your interactions outside of the Services with such third parties or use of such applications and services is not governed by this privacy policy. Miles does not control the applications or services of those third parties or how they use your information and documents. Be sure to review the terms and the privacy policies of those third parties before using their applications or services.</>)}

        {H3(<>Payments</>)}

        {P(<>When you make a payment on our Services, you may be taken to the website or platform of one of our third-party service providers. The service provider will either collect or have access to your first and last name, zip code, postal address, email address, payment amount, billing information, and payment card information. Please review the Privacy Policy of our third-party payment providers, which is available on their website or platform, to learn how they process personal information. </>)}

        {bookmark(YOUR_RIGHTS_AND_CHOICES_ID)}
        {H3(<>Your Rights and Choices</>)}

        {H3(<>Account Information</>)}

        {P(<>You may request a copy of, or delete information about you at any time by going to the Settings page of the app or emailing us at {SUPPORT_EMAIL_LINK}. If you wish to delete or deactivate your account, please email us at {SUPPORT_EMAIL_LINK}, but note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time. For information about how to exercise your privacy rights under certain laws, please see the {REGIONAL_PRIVACY_NOTICE_LINK} section below.</>)}

        {H3(<>Location Information</>)}

        {P(<>When you first launch any of our mobile applications that collect location information, you will be asked to consent to the application's collection of this information. If you do not consent to our collection, use or other processing of your location information, or subsequently stop this collection, certain features of our Services will not function or be available to you. You may stop our collection of location information by changing the preferences on your mobile device, or by following the standard uninstall process to remove all of our mobile applications from your device.</>)}


        {H3(<>Cookies, Trackers and Similar Technologies</>)}

        {P(<>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. To opt out of having your web browsing information used for behavioral advertising purposes, please visit {extLink("https://www.aboutads.info/choices", "www.aboutads.info/choices")}. To opt out of having your website activity available to Google Analytics, install the {extLink("https://chromewebstore.google.com/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en", "Google Analytics opt-out browser add-on")}. To opt-out of our sale or disclosure of personal information to Partners and other third parties, please go to the app’s privacy options in the Settings menu. To opt-out of receiving behavioral ads on your iOS or Android device, please visit your device settings. To opt-out of personal data collection on your mobile device, visit your device settings to turn off your mobile ad ID.</>)}

        {H3(<>Promotional Communications</>)}

        {P(<>You may opt out of receiving promotional emails and communications from Miles by clicking on the unsubscribe link in these communications. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.</>)}

        {H3(<>Mobile Push Notifications/Alerts</>)}

        {P(<>With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</>)}

        {H3(<>Children’s Privacy</>)}

        {P(<>Our service is not targeted towards children under the age of 13. If you are a parent or guardian of a child and believe your child may have disclosed their personal information to us, please contact us at {SUPPORT_EMAIL_LINK}.</>)}

        {H3(<>Safeguarding Your Information</>)}

        {P(<>Miles secures data through physical, technical and administrative measures commensurate to the data we collect and the risks associated with that data. Miles also has policies and procedures designed to promote security practices.</>)}

        {bookmark(REGIONAL_PRIVACY_NOTICES_ID)}
        {H3(<>Regional Privacy Notices</>)}

        {P(<><u>United States</u></>)}
        {P(<>Residents of certain states, including California, Virginia, Connecticut, Colorado, Utah, or another state with comprehensive consumer privacy laws may have additional privacy rights. Please see our {SUPPLEMENTAL_STATE_PRIVACY_NOTICE_LINK} for more information.</>)}
        {P(<><u>Canada, Australia and New Zealand</u></>)}
        {P(<>If you reside in Canada, Australia or New Zealand please see below our {SUPPLEMENTAL_NOTICE_FOR_CANADA_LINK} for information about your privacy rights.</>)}
        {P(<><u>Japan </u></>)}
        {P(<>For residents of Japan, please see the {JP_PRIVACY_LINK} and {JP_TOS_LINK} for the policies applicable to you.</>)}

        {contactUs()}

        <div className="miles_ui_special_gap_02" />
        <hr />
        <div className="miles_ui_special_gap_02" />

        {bookmark(SUPPLEMENTAL_NOTICE_FOR_CANADA_ID)}
        {supplementalNoticeForCanada()}

        <div className="miles_ui_special_gap_03"></div>
      </div>

    </Styled_body_wrap>
  );
});

export default MilesWeb_PrivacyPage202301;
