import styled from "styled-components";

interface We_care_your_privacy_Props {}
export const Styled_We_care_your_privacy = styled("div")<We_care_your_privacy_Props>`
  margin: 0 auto;
  padding: 0;
  min-height: 430px;
  height: 430px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  padding: 108px 205px 107px 179px;

  .inner_box_01 {
    width: 333px;
    height: 215px;
    display: inline-block;
    margin-right: 123px;
  }
  
  .inner_box_02 {
    display: flex;
    flex-direction: column;
    justify-content: start;

    .inner_box_02_title {
      width: 600px;
      height: 56px;
      margin-top: 10px;
      font-family: Noto Sans JP;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #2d2f3b;
    }
    .inner_box_02_content {
      width: 600px;
      height: 124px;
      font-family: Noto Sans JP;
      margin-top: 15px;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2d2f3b;
    }
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    display: none;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 294px;
    height: 294px;
    padding: 0;
    width: max-content;
    margin: 0 auto;

    .inner_box_01 {
      width: 206px;
      height: 135px;
      margin-right: 42px;
    }
    
    .inner_box_02 {
      .inner_box_02_title {
        width: auto;
        height: 56px;
        margin-top: 10px;
        font-family: Noto Sans JP;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: #2d2f3b;

      }
      .inner_box_02_content {
        width: 403px;
        height: 87px;
        margin-top: 15px;
        font-family: Noto Sans JP;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.7px;
        color: #2d2f3b;

      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    padding: 120px 147px 107px 120px;

    .inner_box_01 {
      margin-right: 81px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    width: 1440px;
  }
`;

interface Super_wrap_Props {}
export const Styled_Super_wrap = styled("div")<Super_wrap_Props>`
  .miles_ui_less_than_768 {
    display: none;
  }
  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 100vw;
    min-width: 360px;
    margin: 0;
    padding: 0;
    height: 621px;
    .miles_ui_less_than_768 {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      .inner_box_title {
        width: 288px;
        height: 95px;
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.75px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 83px;
      }

      .inner_box_asset {
        width: 241px;
        height: 156px;
        margin-top: 42px;
      }

      .inner_box_content {
        width: 265px;
        height: auto;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.61px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 52px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 100vw;
    margin: 0;
    padding: 0;
    height: 621px;
    .miles_ui_less_than_768 {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      .inner_box_title {
        width: 288px;
        height: 95px;
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.75px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 86px;
      }

      .inner_box_asset {
        width: 248px;
        height: 161px;
        margin-top: 50px;
      }

      .inner_box_content {
        width: 313px;
        height: auto;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.61px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 40px;
      }
    }
  }
`

