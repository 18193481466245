import React from "react";
import { observer } from "mobx-react";
import { AdBox } from "../raf_ad_box_v2";
import { FcaRafCopyBox } from "../raf_copy_box_v2"; // done
import { FcaRafCopyTip } from "../raf_copy_tip_v2"; // done
import { FcaRafCopyBtn } from "../raf_copy_btn_v2"; // done
import { FcaRafOrTextBox } from "../raf_or_text_box_v2"; // done
import { FcaRafDownloadTextBox } from "../raf_download_text_box_v2"; // done
import { FcaRafDownloadNumberInput } from "../raf_download_number_input_v2"; // done
import { FcaRafSendDownloadLinkBtn } from "../raf_send_downloadLink_btn_v2"; // done
import { FcaRafSendDownloadErrTip } from "../raf_download_err_tip_v2"; // done
import { FcaRafGetTheAppBox } from "../raf_get_the_app_box_v2"; // done
import config from "../../utils/config";
import { useTranslation } from 'react-i18next';
import { StyledActionSection } from "./styles";
import { store } from "../../Store";

interface Props {
  download_src: string;
  download_alt: string;
  download_text: string;
  share_icon: string;
  share_alt: string;
  share_text: string;
  redeem_rewards_src: string;
  redeem_rewards_alt: string;
  redeem_rewards_text: string;
  invite_code_tiip: string;
  // copyBox section
  copyBox_ref: RefObject<HTMLIFrameElement>;
  copyBox_shouldShowCopyBox: boolean;
  copyBox_fcaCode: string;
  handleShouldShowCopyBox?: () => void;
  // send_downloadLink section
  send_downloadLink_phoneErr: boolean;
  phoneNumber: string;
  handleChangePhoneNum?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSendDownloadLink?: () => void;
  send_downloadLink_shouldDisableSendBtn: boolean;
  inputEl: RefObject<HTMLInputElement>;
}

export const ActionSection: React.FC<Props> = observer(({
  download_src,
  download_alt,
  download_text,
  share_icon,
  share_alt,
  share_text,
  redeem_rewards_src,
  redeem_rewards_alt,
  redeem_rewards_text,
  invite_code_tiip,
  // copyBox section
  copyBox_ref,
  copyBox_shouldShowCopyBox,
  copyBox_fcaCode,
  handleShouldShowCopyBox,
  // send_downloadLink section
  send_downloadLink_phoneErr,
  inputEl,
  phoneNumber,
  handleChangePhoneNum,
  handleOnKeyDown,
  handleSendDownloadLink,
  send_downloadLink_shouldDisableSendBtn

}) => {
  const { t } = useTranslation();
  return (
    <StyledActionSection>
      <div className="fca-raf-download-share-redeem-wrap">
        <AdBox
          src={download_src}
          alt={download_alt}
          textContent={download_text}
          width="30px"
          height="30px"
          iconMarginLeft="5px"
          marginTop="45px"
          marginLeft="35px"
          isFirst={true}
        />
        <AdBox
          src={share_icon}
          alt={share_alt}
          textContent={share_text}
          width="34px"
          height="34px"
          iconMarginLeft="0"
          marginTop="25px"
          marginLeft="35px"
          isFirst={false}
        />
        <AdBox
          src={redeem_rewards_src}
          alt={redeem_rewards_alt}
          textContent={redeem_rewards_text}
          width="34px"
          height="34px"
          iconMarginLeft="0"
          marginTop="25px"
          marginLeft="35px"
          isFirst={false}
        />
      </div>

      <div className="fca-raf-invite-sendDownloadLink-wrap">
        <FcaRafCopyTip invite_code_tiip={invite_code_tiip} />
        <FcaRafCopyBox
          id="fca-code"
          ref={copyBox_ref}
          content={copyBox_shouldShowCopyBox ? t('raf_text_copied'): copyBox_fcaCode}
          shouldShowCopyBox={copyBox_shouldShowCopyBox}
          invite_code_tip={invite_code_tiip}
        />
        <FcaRafCopyBtn
          copyBox_shouldShowCopyBox={copyBox_shouldShowCopyBox}
          handleShouldShowCopyBox={handleShouldShowCopyBox}
        />
        <FcaRafOrTextBox />
        {
          store.isSendFcaLink === "sent" ? (
            <FcaRafDownloadTextBox fcaRafDownloadText={t('raf_fcaRafDownloadText')} />
          ) : (
            <>
              <div className="send-downloadLink-wrap">
                <FcaRafDownloadNumberInput
                  inputEl={inputEl}
                  placeholder={t('raf_phone_num_placeholder')}
                  value={phoneNumber}
                  onChange={handleChangePhoneNum}
                  onKeyDown={handleOnKeyDown}
                  type="tel"
                  send_downloadLink_phoneErr={send_downloadLink_phoneErr}
                />
                <FcaRafSendDownloadLinkBtn
                  onClick={handleSendDownloadLink}
                  send_downloadLink_shouldDisableSendBtn={send_downloadLink_shouldDisableSendBtn}
                />
              </div>
              <FcaRafSendDownloadErrTip send_downloadLink_phoneErr={send_downloadLink_phoneErr} />
            </>
          )
        }
        <FcaRafGetTheAppBox href={`${config.appStoreLinkBaseUrl}${t('raf_get_the_app_url_path')}${store.fca_raf_referral_code}`} />
      </div>
    </StyledActionSection>
  );
})
