import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0 auto;
  padding: 0;
  width: 100vw;
  overflow: hidden;

  --nav-bar-height: 80px;

  .inline_block {
    display: inline-block;
  }

  .display_block {
    display: block;
  }

  .privacy_top_bg {
    margin: 0 auto;
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_asset_1440%402x.png) center no-repeat;
    background-size: cover;
    width: 1440px;
    height: 182px;
　　 background-position: center center;
  }

  .privacy_top_hero {
    margin: 0 auto;
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_hero_1440%402x.png) center no-repeat;
    background-size: cover;
    width: 270px;
    height: 174px;
　　 background-position: right bottom;
    right: 189px;
    top: 148px;
    position: absolute;
  }

  .main_content {
    margin: 0 auto;
    width: 1064px;
    position: relative;

    .privacy_title {
      width: auto;
      font-family: Gilroy-Bold;
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.46;
      letter-spacing: normal;
      color: #2d2f3b;
      margin-top: -185px;
      position: absolute;
    }

    .privacy_update_date {
      width: auto;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #2d2f3b;
      margin-top: 51px;
    }

    .para_content {
      width: 1064px;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #2d2f3b;

      &.bold {
        font-family: Gilroy-Bold;
      }

      span.bold {
        font-family: Gilroy-Bold;
      }
    }

    .top_intro_para {
      margin-top: 30px;
    }

    .miles_ui_privacy_title_01 {
      width: inherit;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: 0.39px;
      color: #2d2f3b;
      margin-top: 25px;
    }

    .miles_ui_privacy_title_02 {
      width: inherit;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: 0.34px;
      color: #2d2f3b;
      margin-top: 20px;
    }

    .inner_para_01 {
      margin-top: 10px;
    }

    .miles_ui_privacy_title_03 {
      width: inherit;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #2d2f3b;
      margin-top: 20px;
    }

    .indent {
      margin-left: 44px;
      &:first-child {
        margin-top: 20px;
      }
    }

    .miles_ui_privacy_sub_para {
      width: 1004px;
      margin: 0;
      margin-top: 15px;
      margin-left: 44px;
      &.first {
        margin-top: 20px;
      }
    }

    .miles_ui_para_with_dot {
      display: flex;
      width: 1048px;
    }

    .dot {
      height: 5px;
      width: 5px;
      background-color: #2d2f3b;
      border-radius: 50%;
      display: inline-block;
      margin-right: 11px;
      margin-top: 10px;
      float: left;
    }

    .miles_ui_para_with_dot_main_content {
      float: right;
      width: 1004px;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #2d2f3b;

      &.inner {
        width: 960px;
      }

      .with_dot_title {
        font-family: Gilroy-SemiBold;
      }

    }

    .miles_ui_para_without_dot_main_content {
      width: inherit;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.3px;
      color: #2d2f3b;

      .with_dot_title {
        font-family: Gilroy-SemiBold;
      }

    }

    .miles_ui_special_gap_01 {
      height: 12px;
    }

    .miles_ui_special_gap_02 {
      height: 25px;
    }

    .miles_ui_special_gap_03 {
      height: 66px;
    }

    a {
      text-decoration: none;
      color: #10af42;
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

    .privacy_top_bg {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_bg_600%402x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 145px;
    }
  
    .privacy_top_hero {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_hero_1440%402x.png) center no-repeat;
      background-size: cover;
      width: 160px;
      height: 104px;
  　　 background-position: center;
      right: calc(50vw - 80px);
      top: 173px;
      position: absolute;
    }

    .main_content {
      margin: 0 25px;
      width: auto;
  
      .privacy_title {
        width: auto;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.92;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: -120px;
        position: relative;
      }
  
      .privacy_update_date {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.3px;
        color: #2d2f3b;
        margin-top: 140px;
      }
  
      .para_content {
        width: inherit;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
      }
  
      .top_intro_para {
        margin-top: 30px;
      }
  
      .miles_ui_privacy_title_01 {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.34px;
        color: #2d2f3b;
        margin-top: 40px;
      }
  
      .miles_ui_privacy_title_02 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.3px;
        color: #2d2f3b;
        margin-top: 20px;
      }
  
      .inner_para_01 {
        margin-top: 20px;
      }
  
      .miles_ui_privacy_title_03 {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
        margin-top: 20px;
      }
  
      .indent {
        margin-left: 25px;
        &.first {
          margin-top: 20px;
        }
      }

      .miles_ui_privacy_sub_para {
        width: calc(100vw - 100px);
        margin: 0;
        margin-top: 15px;
        margin-left: 25px;
        &.first {
          margin-top: 20px;
        }
      }
  
      .miles_ui_para_with_dot {
        display: flex;
        width: calc(100vw - 100px);
      }
  
      .dot {
        height: 5px;
        width: 5px;
        background-color: #2d2f3b;
        border-radius: 50%;
        display: inline-block;
        margin-right: 11px;
        margin-top: 10px;
        float: left;
      }
  
      .miles_ui_para_with_dot_main_content {
        float: right;
        width: calc(100vw - 100px);
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
  
        &.inner {
          width: calc(100vw - 140px);
        }
  
        .with_dot_title {
          font-family: Gilroy-SemiBold;
        }
  
      }
  
      .miles_ui_para_without_dot_main_content {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
  
        .with_dot_title {
          font-family: Gilroy-SemiBold;
        }
  
      }
  
      .miles_ui_special_gap_01 {
        height: 12px;
      }
  
      .miles_ui_special_gap_02 {
        height: 25px;
      }
  
      .miles_ui_special_gap_03 {
        height: 10px;
      }
  
      a {
        text-decoration: none;
        color: #10af42;
      }
  
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {

    .privacy_top_bg {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_bg_600%402x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 145px;
    }
  
    .privacy_top_hero {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_hero_1440%402x.png) center no-repeat;
      background-size: cover;
      width: 160px;
      height: 104px;
  　　 background-position: center;
      right: calc(50vw - 80px);
      top: 173px;
      position: absolute;
    }

    .main_content {
      margin: 0 25px;
      width: auto;
  
      .privacy_title {
        width: auto;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.92;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: -120px;
        position: relative;
      }
  
      .privacy_update_date {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.3px;
        color: #2d2f3b;
        margin-top: 140px;
      }
  
      .para_content {
        width: inherit;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
      }
  
      .top_intro_para {
        margin-top: 30px;
      }
  
      .miles_ui_privacy_title_01 {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.34px;
        color: #2d2f3b;
        margin-top: 40px;
      }
  
      .miles_ui_privacy_title_02 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.3px;
        color: #2d2f3b;
        margin-top: 20px;
      }
  
      .inner_para_01 {
        margin-top: 10px;
      }
  
      .miles_ui_privacy_title_03 {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
        margin-top: 20px;
      }
  
      .indent {
        margin-left: 25px;
        &.first {
          margin-top: 20px;
        }
      }

      .miles_ui_privacy_sub_para {
        width: calc(100vw - 100px);
        margin: 0;
        margin-top: 15px;
        margin-left: 25px;
        &.first {
          margin-top: 20px;
        }
      }
  
      .miles_ui_para_with_dot {
        display: flex;
        width: calc(100vw - 100px);
      }
  
      .dot {
        height: 5px;
        width: 5px;
        background-color: #2d2f3b;
        border-radius: 50%;
        display: inline-block;
        margin-right: 11px;
        margin-top: 10px;
        float: left;
      }
  
      .miles_ui_para_with_dot_main_content {
        float: right;
        width: calc(100vw - 100px);
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
  
        &.inner {
          width: calc(100vw - 140px);
        }
  
        .with_dot_title {
          font-family: Gilroy-SemiBold;
        }
  
      }
  
      .miles_ui_para_without_dot_main_content {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
  
        .with_dot_title {
          font-family: Gilroy-SemiBold;
        }
  
      }
  
      .miles_ui_special_gap_01 {
        height: 12px;
      }
  
      .miles_ui_special_gap_02 {
        height: 25px;
      }
  
      .miles_ui_special_gap_03 {
        height: 100px;
      }
  
      a {
        text-decoration: none;
        color: #10af42;
      }
  
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

    .privacy_top_bg {
      width: 100vw;
    }

    .privacy_top_hero {
      width: 248px;
      height: 160px;
      right: calc(50vw - 368px);
      top: 148px;
      position: absolute;
    }

    .main_content {
      width: 700px;
  
      .privacy_title {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.19;
        letter-spacing: normal;
        color: #2d2f3b;
        margin-top: -185px;
        position: absolute;
      }
  
      .privacy_update_date {}
  
      .para_content {
        width: 700px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
      }
  
      .top_intro_para {
        margin-top: 30px;
      }
  
      .miles_ui_privacy_title_01 {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.34px;
        color: #2d2f3b;
        margin-top: 45px;
      }
  
      .miles_ui_privacy_title_02 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.3px;
        color: #2d2f3b;
        margin-top: 20px;
      }
  
      .inner_para_01 {
        margin-top: 10px;
      }
  
      .miles_ui_privacy_title_03 {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
        margin-top: 20px;
      }

      .indent {
        margin-left: 44px;
        &.first {
          margin-top: 20px;
        }
      }

      .miles_ui_privacy_sub_para {
        width: 640px;
        margin: 0;
        margin-top: 15px;
        margin-left: 44px;
        &.first {
          margin-top: 20px;
        }
      }
  
      .miles_ui_para_with_dot {
        display: flex;
        width: auto;
      }
  
      .dot {
        height: 5px;
        width: 5px;
        background-color: #2d2f3b;
        border-radius: 50%;
        display: inline-block;
        margin-right: 11px;
        margin-top: 10px;
        float: left;
      }
  
      .miles_ui_para_with_dot_main_content {
        float: right;
        width: 640px;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
  
        &.inner {
          width: 700px;
        }
  
        .with_dot_title {
          font-family: Gilroy-SemiBold;
        }
  
      }
  
      .miles_ui_para_without_dot_main_content {
        width: inherit;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: 0.26px;
        color: #2d2f3b;
  
        .with_dot_title {
          font-family: Gilroy-SemiBold;
        }
  
      }
  
      .miles_ui_special_gap_01 {
        height: 12px;
      }
  
      .miles_ui_special_gap_02 {
        height: 20px;
      }
  
      .miles_ui_special_gap_03 {
        height: 52px;
      }
  
      a {
        text-decoration: none;
        color: #10af42;
      }
  
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {

    .privacy_top_bg {
      width: 100vw;
      height: 182px;
    }
  
    .privacy_top_hero {
      right: 189px;
      right: calc(50vw - 532px);
      top: 148px;
      position: absolute;
    }

    .main_content {
      .dot {
        margin-right: 0;
        margin-top: 10px;
        float: left;
      }
  
      .miles_ui_para_with_dot_main_content {
        margin-left: 11px;
      }
    }

    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

    .privacy_top_bg {
      margin: -5px auto 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/privacy/privacy_top_hero_2500%402x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 188px;
  　　 background-position: center center;
    }
  
    .privacy_top_hero {
      right: 189px;
      right: calc(50vw - 532px);
      top: 148px;
      position: absolute;
    }

    .main_content {
      .dot {
        margin-right: 0;
        margin-top: 10px;
        float: left;
      }
  
      .miles_ui_para_with_dot_main_content {
        margin-left: 11px;
      }
    }
  
  }
`;
