import React, { useState, useCallback, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import Password_reset_jp from "../password_reset_jp";
import { MilesWebHeader_option_02 } from "../../components/v2/header_option_02";

import {
  MainWrap
} from "./styles";
import { store } from "../../Store";
import config from "../../utils/config";
import { CompassCalibrationOutlined } from "@material-ui/icons";

let firstRender = true;

const {
  miles_tenant_logo,
} = config;

const Password_reset_router_jp: React.FC<{}> = observer(() => {
  if (firstRender) {}

  useEffect(() => {
    firstRender = false;
    document.body.classList.add('miles_ui_mobile_page_view')
  }, []);

  return (
    <React.Fragment>
      <MainWrap>
        <MilesWebHeader_option_02 src={miles_tenant_logo} alt="Miles Tenant Logo"/>

        <Switch>
          <Route path="/jp/password/reset" component={Password_reset_jp} />
        </Switch>
      </MainWrap>
    </React.Fragment>
  );
});

export default Password_reset_router_jp;
