import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils, { useCountry } from "../../utils/milesUtils";
import changeLanguage from '../../utils/i18n/i18next';
import Founders from "./founders/index";
import OurTeam from "./our_team/index";
import Investors from "./investors/index";
import WorkingAtMiles from "./work_at_miles/index";
import Leadership from "./leadership/index";
import CompanyInfo from "./companyInfo/index";
import OurBoard from "./our_board/index";
import WhereWeAreHeaded from "./where_we_are_headed/index";
import Carousel_01 from "./carousel_01/index";
import Carousel_02 from "./miles_team_carousel/index";
import Carousel_03 from "./carousel_03/index";
import { MILES_ANIMATIONS } from "../../utils/milesConstant";
import {Pre_footer_hero} from "../../components/v2/miles_ui_box_pre_footer_hero/index";
import { Styled_body_wrap } from "./styles";

const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
  about_page_assets,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

const MilesWeb_AboutPage: React.FC<{}> = observer(() => {
  let country = useCountry();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AboutPage -- firstRender");
    switch (country) {
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AboutPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap isJP={country === "jp"}>
      <Carousel_01 />

      <div className="divider_space_after_carousel_01" />

      <Founders/>

      {country === "jp" ? (
        <>
          <div className="divider_space_before_companyInfo"></div>
          <CompanyInfo />
          <div className="divider_space_after_companyInfo"></div>
        </>
      ) : null}

      {country === "jp" ? null : <OurTeam/>}

      <Leadership />

      {/* {(country === "jp" || process.env.REACT_APP_MILES_TENANT === "mjkk" || process.env.REACT_APP_MILES_TENANT == "mjp") ? <div className="divider_space_after_jp_leadership"></div> : (
        <>
          <div className="divider_space_before_carousel_02"></div>
          <Carousel_02 />
          <div className="divider_space_after_carousel_02"></div>
        </>
      )} */}

    <div className="divider_space_after_jp_leadership"></div>
      
      {country === "jp" ? null : <OurBoard />}

      <Investors />

      {country === "jp" ? null : <WhereWeAreHeaded />}

      <WorkingAtMiles/>

      {country === "jp" ? null : <>
        <Carousel_03 />
        <div className="divider_space_after_carousel_03"></div>
      </>}
      
      {country === "jp" ? null : <Pre_footer_hero
        apple_store_icon_img={apple_store_icon_img}
        google_play_icon_img={google_play_icon_img}
        star_icon_img={star_icon_img}
        icon_star_half_img={icon_star_half_img}
        apple_store_src={apple_store_src}
        google_play_store_src={google_play_store_src}
        className="miles_ui_pre_footer_commonSize miles_ui_about_page"
      />}

    </Styled_body_wrap>
  );
});

export default MilesWeb_AboutPage;
