import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Send_download_link_cta } from "../../components_jp/miles_ui_box_send_download_link_cta";
import { store } from "../../Store";
import milesUtils from "../../utils/milesUtils";
import { Styled_Box } from "./styles";

interface Props {
  apple_store_icon_img: string;
  google_play_icon_img: string;
  apple_store_src: string;
  google_play_store_src: string;
}

export const Send_download_link: React.FC<Props> = observer(({
  apple_store_icon_img,
  google_play_icon_img,
  apple_store_src,
  google_play_store_src,
}) => {
  const [email, setEmail] = useState('');
  const inputEl = useRef<HTMLInputElement>(null);

  const handleChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLFormElement | HTMLTextAreaElement | HTMLInputElement>) => {
      setEmail(event.target.value);
      store.update_miles_links_status('get_download_link', 'no_error')
      event.preventDefault();
    },
    [email],
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_download_link', 'no_error')
      }
    },
    [email]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let finalEmail = email
      if(!milesUtils.isValidEmailAddress(finalEmail)) {
        store.update_miles_links_status('get_download_link', 'invalidNum_error')
      } else {
        store.handleSendDownloadLink_HomePage_JP(finalEmail, "get_download_link");
      }
    },
    [email]
  );

  return (
    <Styled_Box>
      <div className="title">すべての移動に、マイルを</div>
      <div className="sub_title">毎日の移動や通勤・通学でマイルがたまる</div>
      <div className="sub_title">マイルを使って素敵な特典をゲット</div>
      <div className="cta_box desktop">
        <Send_download_link_cta
          inputEl={inputEl}
          placeholder="メールアドレス"
          value={email}
          onChange={handleChangeEmail}
          onKeyDown={handleOnKeyDown}
          type="text"
          handleSendDownloadLink={handleSendDownloadLink}
          apple_store_icon_img={apple_store_icon_img}
          google_play_icon_img={google_play_icon_img}
          apple_store_src={apple_store_src}
          google_play_store_src={google_play_store_src}
        />
      </div>
      
    </Styled_Box>
  );
});
