import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import i18next from 'i18next';
import EmailPreferenceUnsubscribe from "../emailPreferenceUnsubscribe_current";
import EmailPreferenceThankyou from "../emailPreferenceThankyou_current";
import EmailPreference from "../emailPreference_current";
import changeLanguage from '../../utils/i18n/i18next';
import config from "../../utils/config";

import {
  MainWrap
} from "./styles";
import { StyledEmailPreferenceHeader } from "./StyledEmailPreferenceHeader";
import { StyledLogoImg } from "./StyledLogoImg";
import "./style.css";

const {
  topLogo,
  bgImgArr,
} = config;

let firstRender = true;

const EmailPreferenceWrap_current: React.FC<{}> = observer(() => {
  if (firstRender) {
    switch (process.env.REACT_APP_MILES_TENANT) {
      case 'miles':
        changeLanguage("english")
        break;
      case 'jre':
        changeLanguage("japanese_jre")
        break;
      case 'edsp':
        changeLanguage("japanese_edsp")
        break;
      case 'mjkk':
      case 'mjp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        console.log(`Sorry, wrong REACT_APP_MILES_TENANT`);
    }
  }

  useEffect(() => {
    const app_wrap = document.querySelector<HTMLElement>('.app')!
    
    window.onscroll = () => {
      app_wrap!.className = "app web_v2_unsubscribe"
    }

  }, []);

  useEffect(() => {
    firstRender = false;
  }, []);

  return (
    <React.Fragment>
      <MainWrap>
        <div className="preferences_top_wrap">
          <StyledEmailPreferenceHeader bg={bgImgArr}>
            <a className="miles_logo_icon" href="/">
              <img src={topLogo} alt={`web_v2_logo_white_2500`}></img>
            </a>
          </StyledEmailPreferenceHeader>
        </div>
        <Switch>
          <Route path="/preferences/email/unsubscribe" component={EmailPreferenceUnsubscribe} />
          <Route path="/preferences/email/thankyou" component={EmailPreferenceThankyou} />
          <Route path="/preferences/email" component={EmailPreference} />
        </Switch>
        <div className="miles_ui_email_preference_only bottom_space"></div>
      </MainWrap>
    </React.Fragment>
  );
});

export default EmailPreferenceWrap_current;
