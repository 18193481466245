import styled from "styled-components";

interface FcaRafGetTheAppBoxProps {}

export const StyledFcaRafGetTheAppBox = styled("a")<FcaRafGetTheAppBoxProps>`
    display: none;

    &:hover {
      text-decoration: none;
      color: #ffffff;
    }

    /* mobile phone */
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: visible;
    display: block;
    margin: 25px auto;
    height: 55px;
    width: 335px;
    padding: 10px;
    color: #ffffff;
    background-color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
    border: 1px solid ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
    border-radius: 30px;
    font-family: 'Avenir Next';
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: 0.5px;
    text-align: center;
    outline: none;
    box-shadow: none !important;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19) !important;
    outline: none;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: visible;
    display: block;
    margin: 25px auto;
    height: 55px;
    width: 335px;
    padding: 10px;
    color: #ffffff;
    background-color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
    border: 1px solid ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
    border-radius: 30px;
    font-family: 'Avenir Next';
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: 0.5px;
    text-align: center;
    outline: none;
    box-shadow: none !important;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19) !important;
    outline: none;
  }

  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 95%;
  }
`;