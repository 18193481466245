import React from "react";
import { StyledFooter } from "./styles";
import { Link } from "react-router-dom";

interface Props {
  miles_logo_white_img?: string;
  pre_footer_img: string;
  logo_fb_img?: string;
  logo_ins_img?: string;
  logo_linkedin_img?: string;
  logo_x_img?: string;
}

export const Footer: React.FC<Props> = ({
  miles_logo_white_img,
  pre_footer_img,
  logo_fb_img,
  logo_ins_img,
  logo_linkedin_img,
  logo_x_img,
}) => {
  let shouldShowFooterContent = process.env.REACT_APP_MILES_TENANT !== "edsp";
  return (
    <StyledFooter>
      <div className="miles_ui_desktop_only">
        <div className="pre_footer_img"></div>
        
        {shouldShowFooterContent &&
        <div className="footer_content_wrap">
          <div className="footer_content_body">
            <div className="footer_content_left">
              <a className="inline_block miles_logo_white_img" href="/jp">
                <img src={miles_logo_white_img} alt="miles_logo_white_img"></img>
              </a>

              <div className="miles_logo_social_wrap">
                <a className="inline_block miles_logo_social" href="https://www.facebook.com/miles.jpn" target="_blank">
                  <img src={logo_fb_img} alt="logo_fb_img"></img>
                </a>
                <a className="inline_block miles_logo_social" href="https://www.instagram.com/miles_jpn/" target="_blank">
                  <img src={logo_ins_img} alt="logo_ins_img"></img>
                </a>
                <a className="inline_block miles_logo_social" href="https://twitter.com/miles_jpn" target="_blank">
                  <img src={logo_x_img} alt="logo_x_img"></img>
                </a>
              </div>

              <div className="miles_copyright">
              Miles Japan株式会社
              </div>
            </div>

            <div className="footer_content_right">
              <div className="footer_content_right_innerItem innerItem_01">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Company</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/about">会社情報</a> 
                <a className="inline_block footer_content_right_innerItem_sub" href="https://news-jp.getmiles.com/" target="_blank">ブログ</a>
                {/* <a className="inline_block footer_content_right_innerItem_sub" href="/">米国Miles</a> */}
                <Link className="inline_block footer_content_right_innerItem_sub" to="/">米国Miles</Link>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/jobs">採用情報</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/contact">お問い合わせ</a>
              </div>
              <div className="footer_content_right_innerItem innerItem_02">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Legal</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/privacy">プライバシーポリシー</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/tos">利用規約</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/help">サポート</a>
              </div>
              <div className="footer_content_right_innerItem innerItem_03">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Business</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/partner">ビジネスパートナー</a>
              </div>
            </div>
            </div>
        </div>
        }
      </div>

      <div className="miles_ui_mobilePhone_only">
        <div className="mobile_pre_footer_img"></div>

        {shouldShowFooterContent &&
        <div className="mobile_footer_content_wrap">
          <div className="mobile_footer_content_body">
            <div className="mobile_footer_text_wrap">
              <div className="footer_content_right_innerItem innerItem_01">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Company</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/about">会社情報</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="https://news-jp.getmiles.com/" target="_blank">ブログ</a>
                <Link className="inline_block footer_content_right_innerItem_sub" to="/">米国Miles</Link>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/jobs">採用情報</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/contact">お問い合わせ</a>

                <a className="inline_block footer_content_right_innerItem_title Business disabled_hover">Business</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/partner">ビジネスパートナー</a>
              </div>

              <div className="footer_content_right_innerItem innerItem_02">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Legal</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/privacy">プライバシーポリシー</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/tos">利用規約</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jp/help">サポート</a>
              </div>
            </div>

            <div className="mobile_miles_logo_social_wrap">
              <a className="inline_block miles_logo_social" href="https://www.facebook.com/miles.jpn" target="_blank">
                <img src={logo_fb_img} alt="logo_fb_img"></img>
              </a>
              <a className="inline_block miles_logo_social" href="https://www.instagram.com/miles_jpn/" target="_blank">
                <img src={logo_ins_img} alt="logo_ins_img"></img>
              </a>
              <a className="inline_block miles_logo_social last" href="https://twitter.com/miles_jpn" target="_blank">
                <img className="last" src={logo_x_img} alt="logo_x_img"></img>
              </a>
            </div>

            <a className="inline_block miles_logo_white_img" href="/jp">
              <img src={miles_logo_white_img} alt="miles_logo_white_img"></img>
            </a>

            <div className="miles_copyright_02">
            Miles Japan株式会社
            </div>
          </div>
        </div>

        }
      </div>
    </StyledFooter>
  );
};
