import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import ArticleLeft, {Props as ArticleProps} from "./article_left/index";
import ArticleRight from "./article_right/index";
import CardUnit from "../top_stories/card_unit/index";
import ShowMoreCta from "./show_more_cta/index";
import { Styled_body_wrap } from "./styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const {
  press_page_assets: assets,
} = config;

const ALL_ARTICLES: ArticleProps[] = [
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_4} alt={`press_page_partner_logo_asset_4`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_4} alt={`press_page_partner_image_asset_4`}></img>,
    "headerText": "The miles can be redeemed on the app for rewards through its partners",
    "subHeader": "Transit officials around the country are preparing for your daily commute to score you complimentary drinks, gift certificates",
    "date": "Jul 7, 2019",
    "readMoreCta": "https://www.wired.com/story/public-transit-agencies-rewards-programs/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_fast_company} alt={`press_page_partner_logo_asset_fast_company`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_fast_company} alt={`press_page_partner_image_asset_fast_company`}></img>,
    "headerText": "Miles lets you earn airplane-like miles for walking, biking, or scootering",
    "subHeader": "The innovative idea has been such a success that now other people want in on the action. [like] Porsche Digital...and JetBlue Technology Ventures",
    "date": "Apr 02, 2019",
    "readMoreCta": "https://www.forbes.com/sites/grantmartin/2019/04/03/miles-a-loyalty-program-for-everyday-transportation-raises-additional-funding-and-expands-reach/?sh=7300d0e42d5b"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_6} alt={`press_page_partner_logo_asset_6`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_6} alt={`press_page_partner_image_asset_6`}></img>,
    "headerText": "The Miles App Will Reward You for Every Mode of Transportation",
    "subHeader": "Much like airline loyalty programs, Miles offers elite status tiers. Everyone starts with Silver status and can move up to to Gold or Platinum",
    "date": "Jul 24, 2018",
    "readMoreCta": "https://thepointsguy.com/news/the-miles-app-will-reward-you-for-every-mode-of-transportation/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_ozy} alt={`press_page_partner_logo_asset_ozy`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_ozy} alt={`press_page_partner_image_asset_ozy`}></img>,
    "headerText": "The app puts perks into your soul-sucking commute.",
    "subHeader": "Shah came up with the idea in response to what he perceives as a shift in attitudes toward commuting... to on-demand mobility",
    "date": "Nov 15, 2019",
    "readMoreCta": "https://www.ozy.com/good-sht/how-to-get-free-coffee-just-for-taking-the-train-to-work/228052/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_7} alt={`press_page_partner_logo_asset_7`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_7} alt={`press_page_partner_image_asset_7`}></img>,
    "headerText": "Introducing a frequent flyer program for ground transportation",
    "subHeader": "The rise of such innovations shows how new mobility services are growing rapidly in cities and in workplaces",
    "date": "July 24, 2018",
    "readMoreCta": "https://www.greenbiz.com/article/introducing-frequent-flyer-program-ground-transportation"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_8} alt={`press_page_partner_logo_asset_8`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_8} alt={`press_page_partner_image_asset_8`}></img>,
    "headerText": "Walk to work and earn miles with this app",
    "subHeader": "Frequent flyer miles aren't just for airplanes -- this app rewards you for walking, carpooling",
    "date": "July 24, 2018",
    "readMoreCta": "https://mashable.com/2018/07/24/miles-transit-app-rewards/#zpL0f2ySU5q6"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_9} alt={`press_page_partner_logo_asset_9`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_9} alt={`press_page_partner_image_asset_9`}></img>,
    "headerText": "Miles is an app that tracks your every move in exchange for deals and discounts",
    "subHeader": "Miles is an app that tracks your every move in exchange for deals and discounts",
    "date": "July 24, 2018",
    "readMoreCta": "https://www.theverge.com/2018/7/24/17604438/miles-app-tracking-transportation-deals-discounts"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_10} alt={`press_page_partner_logo_asset_10`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_10} alt={`press_page_partner_image_asset_10`} className="press_page_partner_image_asset_10"></img>,
    "headerText": "This New App Will Give You Free Starbucks Just for Traveling",
    "subHeader": "you can use your trips, vacations, and commutes to save money on everyday purchases—which, obviously, sounds like a dream come true.",
    "date": "August 3, 2018",
    "readMoreCta": "https://www.cntraveler.com/story/this-new-app-will-give-you-free-starbucks-just-for-traveling"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_11} alt={`press_page_partner_logo_asset_11`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_11} alt={`press_page_partner_image_asset_11`}></img>,
    "headerText": "Walk a few miles and earn points: New...loyalty program launches",
    "subHeader": "Silicon Valley veterans aims to use your phone’s location-based data patterns to deliver reward points.",
    "date": "July 24, 2018",
    "readMoreCta": "https://skift.com/2018/07/24/l/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_12} alt={`press_page_partner_logo_asset_12`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_12} alt={`press_page_partner_image_asset_12`}></img>,
    "headerText": "Miles...Promotes Eco-Friendly Travel with Appealing Rewards",
    "subHeader": "The platform is geared toward those of us who don't fly frequently but still want to be rewarded for our travel",
    "date": "Oct 19, 2020",
    "readMoreCta": "https://www.cardrates.com/news/miles-lets-users-earn-rewards-for-ground-travel/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_13} alt={`press_page_partner_logo_asset_13`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_13} alt={`press_page_partner_image_asset_13`}></img>,
    "headerText": "Points and Perks to Users For All Forms of Transportation",
    "subHeader": "Global names leverage Miles as an engagement channel, while startups often come to the platform for its efficiency",
    "date": "Sep 11, 2020",
    "readMoreCta": "https://www.badcredit.org/news/miles-app-offers-rewards-for-all-transportation/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_14} alt={`press_page_partner_logo_asset_14`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_14} alt={`press_page_partner_image_asset_14`}></img>,
    "headerText": "Sacramento is offering incentives... by partnering with... Miles.",
    "subHeader": "Miles has gamified using non-car transportation, but it also adds a rewards structure",
    "date": "Apr 11, 2019",
    "readMoreCta": "https://www.curbed.com/2019/4/11/18306547/london-ultra-low-emission-zone-biking"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_15} alt={`press_page_partner_logo_asset_15`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_15} alt={`press_page_partner_image_asset_15`}></img>,
    "headerText": "The ground transportation equivalent to frequent flyer miles",
    "subHeader": "Commuters looking for a reason to ditch the car and use alternate modes of transportation will be encouraged to do so in Pierce County",
    "date": "Sep 10, 2019",
    "readMoreCta": "https://www.geekwire.com/2019/earn-green-greener-travel-transit-agency-teams-rewards-app-miles-target-commuters/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_16} alt={`press_page_partner_logo_asset_16`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_16} alt={`press_page_partner_image_asset_16`}></img>,
    "headerText": "Miles popped the hood on its 2020 growth with...Miles 2020 in Review.",
    "subHeader": "Miles can subsequently be used toward perks at partner companies like Chewy, DoorDash and other notable brands.",
    "date": "Apr 22, 2021",
    "readMoreCta": "https://www.builtinsf.com/2021/04/22/21-bay-area-companies-to-watch-2021"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_17} alt={`press_page_partner_logo_asset_17`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_17} alt={`press_page_partner_image_asset_17`}></img>,
    "headerText": "The Miles app automatically logs each trip from point A to B",
    "subHeader": "Miles is a universal transportation rewards program",
    "date": "Apr 3, 2019",
    "readMoreCta": "https://www.phocuswire.com/Startup-Stage-Miles"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_18} alt={`press_page_partner_logo_asset_18`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_18} alt={`press_page_partner_image_asset_18`}></img>,
    "headerText": "Miles gives you reward miles for almost everything",
    "subHeader": "Miles gives you reward miles for almost everything",
    "date": "Jul 24, 2018",
    "readMoreCta": "https://techcrunch.com/2018/07/24/miles-gives-you-reward-miles-for-almost-everything/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_19} alt={`press_page_partner_logo_asset_19`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_19} alt={`press_page_partner_image_asset_19`}></img>,
    "headerText": "Jacksonville Transportation Authority has partnered with Miles",
    "subHeader": "Users who choose...eco-friendly forms of transportation — such as walking, biking, carpooling or JTA — are rewarded even more.",
    "date": "Jul 14, 2020",
    "readMoreCta": "https://www.masstransitmag.com/technology/passenger-info/mobile-applications/press-release/21146064/jacksonville-transportation-authority-jta-jta-partners-with-miles-to-offer-rewards-for-riding"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_20} alt={`press_page_partner_logo_asset_20`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_20} alt={`press_page_partner_image_asset_20`}></img>,
    "headerText": "It's better for the consumers, cities and transit operators.",
    "subHeader": "Rewards programs have long been used...to incentivize customers to shop there more often. Could that work for public transit, too?",
    "date": "Jul 7, 2019",
    "readMoreCta": "https://www.kqed.org/news/11759464/could-rewards-systems-work-for-public-transit"
  }
];

export interface Props {
  className?: string;
}

const Component: React.FC<Props> = observer((props) => {
  const matches_mobile = useMediaQuery('(max-width: 768px)');
  const matches_tablet = useMediaQuery('(max-width: 1150px)');
  const [maxNumArticles, setMaxNumArticles] = useState(4);
  let articlesStartIndex = 3;
  if (matches_tablet == true) {
    articlesStartIndex = 1
  }
  if (matches_mobile == true) {
    articlesStartIndex = 0
  }
  const articleElements = ALL_ARTICLES.slice(articlesStartIndex)
  const endIndex = Math.min(
    maxNumArticles + articlesStartIndex, articleElements.length);
  const ARTICLE_TYPES = (
    matches_mobile ? [CardUnit, CardUnit] : [ArticleLeft, ArticleRight]
  )
  const onClickCta = useCallback(() => {
    setMaxNumArticles(old => {
      return Math.min(old + 4, articleElements.length);
    });
  },
  [articleElements.length]);
  const ctaDisabled = endIndex == articleElements.length;
  const articles: React.ReactElement[] = [];
  for (let i=0; i < endIndex; i++) {
    let className = "card-unit";
    let ArticleType = ARTICLE_TYPES[i % 2];
    if (matches_mobile !== true) {
      className = (i % 2 == 0) ? "left" : "right";
    }
    articles.push(<div className={className} key={i}>{
      <ArticleType {...articleElements[i]} />
    }</div>);
  }
  return (
    <>
      <Styled_body_wrap className="">
      <div className={`ciq-root ${props.className}`}>
          <div className="ciq-container">
            {articles}
          </div>
        </div>
        <ShowMoreCta className="showMoreCtaWrapper" onClick={onClickCta} disabled={ctaDisabled}/>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
