import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  width: 100vw;
  height: 873px;
  background: #ffffff;

  .main_container {
    margin: 0 auto;
    width: 1440px;
    display: flex;
  }

  .title {
    width: max-content;
    height: auto;
    margin: 0 auto;
    font-family: Gilroy-Bold;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #2d2f3b;
    margin-top: 125px;
  }

  .form_wrap {
    margin: 0 auto;
    width: 715px;
    height: 460px;
    border-radius: 15px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
    margin-top: 56px;
    padding: 0 38px;

    .input_inner_section {
      margin: 0;
      margin-top: 30px;

      &.input_box_01 {
        margin-top: 47px;
      }

      .label_text {
        width: max-content;
        height: auto;
        margin: 0;
        font-family: Gilroy-SemiBold;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        color: #2d2f3b;
        text-algin: left;
        display: block;
      }

      .partner_preFooter_form_input {
        width: 640px;
        height: 48px;
        margin: 0;
        padding: 16px 18px 16px 18px;
        border-radius: 4px;
        border: solid 1px #eaeaea;
        background-color: #ffffff;
        margin-top: 8px;
        outline: none;
        font-family: Gilroy-SemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #2d2f3b;

        :active,
        :focus,
        :focus-visible {
          box-shadow: 0 2px 4px 0 #b1b1b180;
          border: solid 1px #10af42;
        }

        &.input_err {
          border: solid 1px #ff4949;

          :active,
          :focus,
          :focus-visible {
            border: solid 1px #ff4949;
          }
        }
      
        &::-webkit-input-placeholder {
          width: max-content;
          height: 16px;
          font-family: Gilroy-SemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #afafb5;
        }
      
        // --- firefox 19+ ---
        &::-moz-placeholder {
          width: max-content;
          height: 16px;
          font-family: Gilroy-SemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #afafb5;
        }
      
        // --- ie ----
        &::-ms-input-placeholder {
          width: max-content;
          height: 16px;
          font-family: Gilroy-SemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #afafb5;
        }
      
        &::-moz-placeholder {
          width: max-content;
          height: 16px;
          font-family: Gilroy-SemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #afafb5;
        }

      }

      .show_err_info {
        display: block;
        width: max-content;
        height: auto;
        position: absolute;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: 0.46px;
        color: #ff4949;
        margin-top: 2px;
      }

      .hide_err_info {
        display: none;
      }

    }

    .tip_text {
      width: max-content;
      height: auto;
      margin: 0;
      font-family: Gilroy-SemiBold;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.38px;
      color: #afafb5;
      margin-top: 29px;
    }

    .partner_page_pre_form_cta_btn {
      width: 155px;
      height: 40px;
      margin: 0;
      padding: 0;
      border-radius: 20px;
      background-color: #10af42;
      margin-top: 25px;
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      border: none;
      outline: none;

      &.loading_status {
        cursor: progress;
      }
    }

    .partner_page_pre_footer_form_asset {
      display: inline-block;
      width: 250px;
      height: 132px;
      margin: 0 auto;
      margin-top: 100px;
      margin-left: 193px;
    }

    .partner_page_pre_footer_form_success_text {
      width: 336px;
      height: auto;
      margin: 0 auto;
      font-family: Gilroy-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: 0.89px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 50px;
    }

    .show_err_info {
      display: block;
      width: max-content;
      height: auto;
      position: absolute;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: 0.46px;
      color: #ff4949;

      &.submissionErr {
        margin-top: 4px;
      }
    }

    .hide_err_info {
      display: none;
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 730px;

    .main_container {
      width: 100vw;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 63px;
    }

    .form_wrap {
      margin: 0 auto;
      width: 330px;
      height: 500px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-top: 30px;
      padding: 0 40px;

      .input_inner_section {
        margin: 0;
        margin-top: 30px;

        &.input_box_01 {
          margin-top: 47px;
        }

        .label_text {
          width: max-content;
          height: auto;
          margin: 0;
          font-family: Gilroy-SemiBold;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.38px;
          color: #2d2f3b;
          text-algin: left;
          display: block;
        }

        .partner_preFooter_form_input {
          width: 260px;
          height: 48px;
          padding: 17px 27px 17px 18px;
          border-radius: 4px;
          border: solid 1px #eaeaea;
          background-color: #ffffff;
          margin-top: 8px;
          outline: none;
          font-family: Gilroy-SemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.44px;
          color: #2d2f3b;

          :active,
          :focus,
          :focus-visible {
            box-shadow: 0 2px 4px 0 #b1b1b180;
            border: solid 1px #10af42;
          }

          &.input_err {
            border: solid 1px #ff4949;

            :active,
            :focus,
            :focus-visible {
              border: solid 1px #ff4949;
            }
          }
        
          &::-webkit-input-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }
        
          // --- firefox 19+ ---
          &::-moz-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }
        
          // --- ie ----
          &::-ms-input-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }
        
          &::-moz-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }

        }

        .show_err_info {
          display: block;
          width: max-content;
          height: auto;
          position: absolute;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 11px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: 0.46px;
          color: #ff4949;
          margin-top: 2px;
        }

        .hide_err_info {
          display: none;
        }

      }

      .tip_text {
        width: 260px;
        height: auto;
        margin: 0;
        font-family: Gilroy-SemiBold;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.38px;
        color: #afafb5;
        margin-top: 30px;
      }

      .partner_page_pre_form_cta_btn {
        width: 155px;
        height: 40px;
        margin: 0;
        padding: 0;
        border-radius: 20px;
        background-color: #10af42;
        margin-top: 30px;
        font-family: Gilroy-Bold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.44px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border: none;
        outline: none;

        &.loading_status {
          cursor: progress;
        }
      }

      .partner_page_pre_footer_form_asset {
        width: 250px;
        height: 132px;
        margin: 0 auto;
        margin-top: 109px;
      }

      .partner_page_pre_footer_form_success_text {
        width: 267px;
        height: auto;
        margin: 0 auto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.78px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 72px;
      }

      .show_err_info {
        display: block;
        width: max-content;
        height: auto;
        position: absolute;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: 0.46px;
        color: #ff4949;

        &.submissionErr {
          margin-top: 4px;
        }
      }

      .hide_err_info {
        display: none;
      }

    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: 730px;

    .main_container {
      width: 100vw;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 63px;
    }

    .form_wrap {
      margin: 0 auto;
      width: 330px;
      height: 500px;
      border-radius: 15px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-top: 30px;
      padding: 0 40px;

      .input_inner_section {
        margin: 0;
        margin-top: 30px;

        &.input_box_01 {
          margin-top: 47px;
        }

        .label_text {
          width: max-content;
          height: auto;
          margin: 0;
          font-family: Gilroy-SemiBold;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.38px;
          color: #2d2f3b;
          text-algin: left;
          display: block;
        }

        .partner_preFooter_form_input {
          width: 260px;
          height: 48px;
          padding: 17px 27px 17px 18px;
          border-radius: 4px;
          border: solid 1px #eaeaea;
          background-color: #ffffff;
          margin-top: 8px;
          outline: none;
          font-family: Gilroy-SemiBold;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.44px;
          color: #2d2f3b;

          :active,
          :focus,
          :focus-visible {
            box-shadow: 0 2px 4px 0 #b1b1b180;
            border: solid 1px #10af42;
          }

          &.input_err {
            border: solid 1px #ff4949;

            :active,
            :focus,
            :focus-visible {
              border: solid 1px #ff4949;
            }
          }
        
          &::-webkit-input-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }
        
          // --- firefox 19+ ---
          &::-moz-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }
        
          // --- ie ----
          &::-ms-input-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }
        
          &::-moz-placeholder {
            width: max-content;
            height: 14px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #afafb5;
          }

        }

        .show_err_info {
          display: block;
          width: max-content;
          height: auto;
          position: absolute;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 11px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: 0.46px;
          color: #ff4949;
          margin-top: 2px;
        }

        .hide_err_info {
          display: none;
        }

      }

      .tip_text {
        width: 260px;
        height: auto;
        margin: 0;
        font-family: Gilroy-SemiBold;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.38px;
        color: #afafb5;
        margin-top: 30px;
      }

      .partner_page_pre_form_cta_btn {
        width: 155px;
        height: 40px;
        margin: 0;
        padding: 0;
        border-radius: 20px;
        background-color: #10af42;
        margin-top: 30px;
        font-family: Gilroy-Bold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.44px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        border: none;
        outline: none;

        &.loading_status {
          cursor: progress;
        }
      }

      .partner_page_pre_footer_form_asset {
        width: 250px;
        height: 132px;
        margin: 0 auto;
        margin-top: 109px;
      }

      .partner_page_pre_footer_form_success_text {
        width: 267px;
        height: auto;
        margin: 0 auto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.78px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 72px;
      }

      .show_err_info {
        display: block;
        width: max-content;
        height: auto;
        position: absolute;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: 0.46px;
        color: #ff4949;

        &.submissionErr {
          margin-top: 4px;
        }
      }

      .hide_err_info {
        display: none;
      }

    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .main_container {
      width: 100vw;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    height: 840px;

    .main_container {
      width: 100vw;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: 873px;
    background: #ffffff;

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }
    
  }
`;