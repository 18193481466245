import styled from "styled-components";

interface Props {
  height: string;
  bg: string;
}
export const StyledDividerLine = styled("div")<Props>`
  width: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '100px' : '540px'};
  height: ${(props) => props.height};
  margin: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b') ? '15px auto 7px auto' : '20px auto 0 auto'};
  background-color: ${(props) => props.bg};

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '100px' : '95%'};
    margin-top: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '17px' : '24px'};
    margin-bottom: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '18px' : '24px'};

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '100px' : '95%'};
    margin-top: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '17px' : '24px'};
    margin-bottom: ${(props) => (props.bg === '#1ba756' || props.bg === '#fd902b' || props.bg === '#0091C7') ? '18px' : '24px'};

  }
 
`;