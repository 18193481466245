import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils, { useCountry } from "../../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";
import styled from "styled-components";
import { useHelpPageMatcher } from "../../helpPage/hooks";
import { urlForSection } from "../../helpPage/urls";
import { Link } from "react-router-dom";

const {
  contact_page_assets: assets,
} = config;

export interface Props {
  help_category: string;
  question?: string;
}

const Component: React.FC<Props> = observer((props) => {
  let country = useCountry();
  const {selected_section, selected_question} = useHelpPageMatcher();
  let sectionPath = <span className="section_path">{props.help_category}</span>;
  if (selected_question != null && selected_section != null) {
    sectionPath = <Link to={urlForSection(selected_section, country)}>{sectionPath}</Link>;
  }
  return (
    <>
      <Styled_body_wrap>
        <div className="ciq_root_breadcrumb">
          <div className="ciq_container_breadcrumb">
            <div className="ciq_inner_container_breadcrumb">
              <a className="help_path" href={country === "jp" ? "/jp/help" : "/help"}>{country === "jp" || process.env.REACT_APP_MILES_TENANT === "edsp" ? "ヘルプセンター" : "HELP"}</a>
              <span className="pointer_path">&gt;</span>
              {sectionPath}
              { !selected_question ? null : <>
                  <span className="pointer_path_question">&gt;</span>
                  <span className="section_path_question">{props.question}</span>
                  </>
              }
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
