import styled from "styled-components";

interface Props {}
export const StyledSubTitle = styled("p")<Props>`
  height: 24px;
  margin: 0 auto;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.35px;
  text-align: center;
  color: #525252;
  margin-bottom: 37px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    height: 32px;
    margin: 20px 14.5px 20px 13.5px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: #2d2f3b;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 32px;
    margin: 20px 14.5px 20px 13.5px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: #2d2f3b;
  }
`;