import React, { useState, useCallback, useEffect, useRef, ReactNode, Ref } from "react";
import { observer } from "mobx-react";
import { Styled_body_wrap } from "./styles";
import SampleGrid from './sample_grid';

import GetMilesCTA from "../../components/v2/header/get_miles_cta";
import { relative } from "node:path";
import SampleCarousel from '../aboutPage/miles_team_carousel/desktop_carousel';
import MilesJourneyDots from './miles_journey_dots';

const Component: React.FC<{}> = observer(() => {
  return (
    <Styled_body_wrap>
      <MilesJourneyDots />
      <br/><br/><br/><br/>
      <SampleCarousel/>
      <br/>
      <div style={{ height: "70px" }} />
      <SampleGrid />
      <div style={{ height: "70px" }} />
      <div style={{ height: "70px", background: 'green', color: 'white', textAlign: 'center', width: '3400px' }}>
        Divider Line
      </div>
      <GetMilesCTA />
    </Styled_body_wrap>
  );
});

export default Component;
