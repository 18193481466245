import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  .root{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid red;"
  }
  }

  .container{
    width: 1440px;
    height: 200px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
    display: flex;
    justify-content: center;
    flex-direction: row;
  }


  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .container{
      width: auto;
      height: 443px;
      ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 274px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .container{
      width: auto;
      height: 443px;
      ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 200px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .container{
      margin-bottom: 200px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .container{
      margin-bottom: 200px;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .container{
      margin-bottom: 200px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .container{
      margin-bottom: 259px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .container{
      margin-bottom: 259px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .container{
      margin-bottom: 186px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .container{
      margin-bottom: 259px;
    }
  }
`;