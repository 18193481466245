import styled from "styled-components";

interface Props {}
export const StyledUpdateReSubscribeBtn = styled("button")<Props>`
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
    height: 50px;
    width: 197px;
    margin: 20px auto 50px auto;
    padding: 15px 50px;
    border-radius: 3px;
    background-color: #10af42;
    font-size: 15px;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #ffffff;


  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 137px;
    margin: 20px auto;
    padding: 15px 20px;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 137px;
    margin: 20px auto;
    padding: 15px 20px;
  }
`;