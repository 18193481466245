import React from "react";
import { observer } from "mobx-react";
import { StyledEle } from "./styles";

const top_banner_notice_icon = "https://getmiles.s3.amazonaws.com/assets/web_v2/top_banner/top_banner_icon_v1/top_banner_icon_v1%402x.png";

interface Props {
  click_here_url?: string;
}

export const TopBannerNoticeComponent: React.FC<Props> = observer(({
  click_here_url,
}) => {
  return (
    <StyledEle>
      <div className="top_banner_notice_content">
        <img src={top_banner_notice_icon} alt="top_banner_notice_icon" />
        <div className="top_banner_notice_text">現在、Milesアプリにおいてシステムの改修を行なっております。これに伴い、新規ユーザーの登録を一時停止しております。ご不便をお掛け致しますが、しばらくお待ちください。最新状況は、twitterをフォローください。</div>
      </div>
    </StyledEle>
  );
});
