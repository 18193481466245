import React from "react";
import { Styled_Brands_you_love } from "./styles";

interface Props {
  brands_you_love_v3_2500_img: string,
  brands_you_love_v3_600_img: string,
  brands_you_love_v3_360_img: string,
}

export const Brands_you_love: React.FC<Props> = ({
  brands_you_love_v3_2500_img,
  brands_you_love_v3_600_img,
  brands_you_love_v3_360_img,
}) => {
  return (
    <Styled_Brands_you_love>
      <div className="para_01">特典掲載ブランド一例</div>
      <div className="para_02 large_than_360_screen_only">人気ブランドや店舗からの特典を提供</div>
      <div className="para_02 less_than_360_screen_only">人気ブランドや店舗からの特典を提供</div>
      <img className="inner_box_01 large_than_768_screen" src={brands_you_love_v3_2500_img} alt="brands_you_love_img"></img>
      <img className="inner_box_01 between_360_to_768_screen" src={brands_you_love_v3_600_img} alt="brands_you_love_img_between_360_to_768_screen"></img>
      <img className="inner_box_01 less_than_360_screen" src={brands_you_love_v3_360_img} alt="brands_you_love_img_less_than_360_screen"></img>
    </Styled_Brands_you_love>
  );
};
