import styled from "styled-components";
import config from "../../../utils/config";

const {
  confirmation_10_days_text_note_description_margin,
} = config;

interface Props {}
export const StyledConfirmedNoteText = styled("p")<Props>`
  height: 32px;
  margin: 28px auto 0 auto;
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2d2f3b;

  b {
    font-weight: 600;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    margin: ${() => confirmation_10_days_text_note_description_margin};
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.2;
    letter-spacing: 0.15px;
    text-align: center;
    color: #2d2f3b;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin: ${() => confirmation_10_days_text_note_description_margin};
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.2;
    letter-spacing: 0.15px;
    text-align: center;
    color: #2d2f3b;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }
`;