import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { useTranslation } from 'react-i18next';
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  return (
    <>
      <Styled_body_wrap>
        <div className="title_text">
          {t('help_form_text_001')}
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
