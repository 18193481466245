import styled from "styled-components";

interface RewardShareTextCardProps {}
export const StyledRewardShareTextImgCard = styled("div")<RewardShareTextCardProps>`
  width: 645px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .reward-gift-icon {
    height: 108px;
    width: auto;
  }

  .reward-share-bottom-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  .reward-share-bottom-text-1 {
    height: 40px;
    font-family: 'Avenir Next';
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    font-weight: 600;
    color: #2d2f3b;
  }

  .reward-share-bottom-text-2 {
    height: 40px;
    font-family: 'Avenir Next';
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #9b9ba5;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }
  
  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    display: none;
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    display: none;
  }
  
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    width: 523px;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    width: 523px;
  }
`;