import React from 'react';
import { observer } from 'mobx-react';
import {
  StyledCta_inner_wrap,
  StyledInput,
  StyledBtn,
  StyledFcaRafSendDownloadErrTipEle,
  StyledSuccessInfo,
  StyledMilesJpTopCtaTip,
} from './styles';
import { store } from '../../Store';

interface Props {
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type: string;
  handleSendDownloadLink?: () => void;
  inputEl: RefObject<HTMLInputElement>;
  apple_store_icon_img: string;
  google_play_icon_img: string;
  apple_store_src: string;
  google_play_store_src: string;
}

export const Send_download_link_cta: React.FC<Props> = observer(({
  inputEl,
  placeholder,
  value,
  onChange,
  onKeyDown,
  type,
  handleSendDownloadLink,
  apple_store_icon_img,
  google_play_icon_img,
  apple_store_src,
  google_play_store_src,
}) => {
  let isBtnDisabled = store.get_miles_links_status.get_download_link === 'invalidNum_error' || store.get_miles_links_status.get_download_link === 'sending'
  let shouldShowErrMessage = store.get_miles_links_status.get_download_link === 'invalidNum_error'
                              || store.get_miles_links_status.get_download_link === 'timeout'
                              || store.get_miles_links_status.get_download_link === 'server_error'
  return (
    <>
      <StyledCta_inner_wrap className="cta_inner_wrap">
        {
          store.get_miles_links_status.get_download_link === 'sent'
            ? <StyledSuccessInfo>リンクが送られました！</StyledSuccessInfo>
            : (
              <>
                <StyledInput
                  ref={inputEl}
                  send_downloadLink_phoneErr={shouldShowErrMessage}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  type={type}
                /> 

                <StyledBtn
                  className="screen_larger_than_768"
                  onClick={handleSendDownloadLink}
                  style={{
                    cursor: isBtnDisabled ? 'unset' : 'pointer'
                  }}
                  disabled={isBtnDisabled}
                  get_miles_links_status={store.get_miles_links_status.get_download_link}
                  bgColor={store.get_miles_links_status.get_download_link === 'invalidNum_error' ? '#9b9ba5' : '#10af42'}
                >{store.get_miles_links_status.get_download_link === 'sending' ? '送信中…' : 'リンクを送る'}</StyledBtn>

                <StyledBtn
                  className="screen_between_361_and_768"
                  onClick={handleSendDownloadLink}
                  style={{
                    cursor: isBtnDisabled ? 'unset' : 'pointer'
                  }}
                  disabled={isBtnDisabled}
                  get_miles_links_status={store.get_miles_links_status.get_download_link}
                  bgColor={store.get_miles_links_status.get_download_link === 'invalidNum_error' ? '#9b9ba5' : '#10af42'}
                >{store.get_miles_links_status.get_download_link === 'sending' ? '送信中…' : 'リンクを送る'}</StyledBtn>
              </>
            )
        }
        <a className="inline_block apple" href={apple_store_src} target="_blank">
          <div className="apple_store_icon_img"/>
        </a>
        <a className="inline_block google" href={google_play_store_src} target="_blank">
          <div className="google_play_icon_img"/>
        </a>
      </StyledCta_inner_wrap>


      <StyledFcaRafSendDownloadErrTipEle send_downloadLink_phoneErr={shouldShowErrMessage}>
      {store.get_miles_links_status.get_download_link === 'invalidNum_error' &&  '*有効なメールアドレスをご記入ください。'}
      {(store.get_miles_links_status.get_download_link === 'timeout' || store.get_miles_links_status.get_download_link === 'server_error') && '*しばらく時間をおいてからお試しください。'}
      </StyledFcaRafSendDownloadErrTipEle>
      {/* <StyledMilesJpTopCtaTip send_downloadLink_phoneErr={shouldShowErrMessage} className="miles_jp_top_cta_tip">ご登録いただいた方には、アプリ配信後、ボーナスマイル特典をご用意しております。</StyledMilesJpTopCtaTip> */}
      <div className="screen_1150_to_1440_only">
        <a className="inline_block apple" href={apple_store_src} target="_blank">
          <div className="apple_store_icon_img"/>
        </a>
        <a className="inline_block google" href={google_play_store_src} target="_blank">
          <div className="google_play_icon_img"/>
        </a>
      </div>
    </>
  );
});
