import styled from "styled-components";

interface Props {}
export const StyledUnsubscribeCategoryList = styled("div")<Props>`
    width: fit-content;
    margin: 25px auto 25px 139px;

    .mi-unsubscribe-category0list-item-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px auto;

        p {
            height: 30px;
            margin: 0 0 0 14px;
            font-family: 'Avenir Next';
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: 0.3px;
            color: #2d2f3b;
        }
    }
    
    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 320px) and (max-width : 768px) {
        margin: 17px auto 20px 0;

        .mi-unsubscribe-category0list-item-wrap {
            margin: 5px auto;
    
            p {
                margin: 0 0 0 10px;
                font-size: 14px;
                line-height: 2.14;
            }
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        margin: 17px auto 20px 0;

        .mi-unsubscribe-category0list-item-wrap {
            margin: 5px auto;
    
            p {
                margin: 0 0 0 10px;
                font-size: 14px;
                line-height: 2.14;
            }
        }
    }
`;