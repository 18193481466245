import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import CardUnit, {Props as CardUnitProps} from "./card_unit/index";
import { Styled_body_wrap } from "./styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const {
  press_page_assets: assets,
} = config;

const ALL_ARTICLES: CardUnitProps[] = [
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_1} alt={`press_page_partner_logo_asset_1`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_1} alt={`press_page_partner_image_asset_1`}></img>,
    "headerText": "Miles is a startup company you will want to know about.",
    "subHeader": "Employees at Miles enjoy an amazing work environment and career development opportunities.",
    "date": "Feb 12, 2020",
    "readMoreCta": "https://sanfranciscobootcamps.com/top-startups-to-watch-in-san-francisco-2020/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_2} alt={`press_page_partner_logo_asset_2`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_2} alt={`press_page_partner_image_asset_2`}></img>,
    "headerText": "Miles has a lot of potential to connect travelers, brands, and destinations",
    "subHeader": "Miles... aligns with [JetBlue's] investment theme of 'Innovations in Revenue, Distribution & Loyalty.'",
    "date": "Apr 4, 2019",
    "readMoreCta": "https://www.forbes.com/sites/grantmartin/2019/04/03/miles-a-loyalty-program-for-everyday-transportation-raises-additional-funding-and-expands-reach/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_3} alt={`press_page_partner_logo_asset_3`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_3} alt={`press_page_partner_image_asset_3`}></img>,
    "headerText": "Miles, the app that rewards traveling, expands to Latin America and Europe",
    "subHeader": "While Miles rewards all travel, it gives bonuses for different, greener modes of transportation",
    "date": "Jul 14, 2020",
    "readMoreCta": "https://techcrunch.com/2020/07/14/miles-the-app-that-offers-rewards-for-traveling-finds-a-strategic-investor-in-mexicos-mobilityado/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_4} alt={`press_page_partner_logo_asset_4`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_4} alt={`press_page_partner_image_asset_4`}></img>,
    "headerText": "The miles can be redeemed on the app for rewards through its partners",
    "subHeader": "Transit officials around the country are preparing for your daily commute to score you complimentary drinks, gift certificates",
    "date": "Jul 7, 2019",
    "readMoreCta": "https://www.wired.com/story/public-transit-agencies-rewards-programs/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_fast_company} alt={`press_page_partner_logo_asset_fast_company`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_fast_company} alt={`press_page_partner_image_asset_fast_company`}></img>,
    "headerText": "Miles lets you earn airplane-like miles for walking, biking, or scootering",
    "subHeader": "The innovative idea has been such a success that now other people want in on the action. [like] Porsche Digital...and JetBlue Technology Ventures",
    "date": "Apr 02, 2019",
    "readMoreCta": "https://www.forbes.com/sites/grantmartin/2019/04/03/miles-a-loyalty-program-for-everyday-transportation-raises-additional-funding-and-expands-reach/?sh=7300d0e42d5b"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_6} alt={`press_page_partner_logo_asset_6`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_6} alt={`press_page_partner_image_asset_6`}></img>,
    "headerText": "The Miles App Will Reward You for Every Mode of Transportation",
    "subHeader": "Much like airline loyalty programs, Miles offers elite status tiers. Everyone starts with Silver status and can move up to to Gold or Platinum",
    "date": "Jul 24, 2018",
    "readMoreCta": "https://thepointsguy.com/news/the-miles-app-will-reward-you-for-every-mode-of-transportation/"
  },
  {
    "logoImage": <img src={assets.press_page_partner_logo_asset_ozy} alt={`press_page_partner_logo_asset_ozy`}></img>,
    "assetImage": <img src={assets.press_page_partner_image_asset_ozy} alt={`press_page_partner_image_asset_ozy`}></img>,
    "headerText": "The app puts perks into your soul-sucking commute.",
    "subHeader": "Shah came up with the idea in response to what he perceives as a shift in attitudes toward commuting... to on-demand mobility",
    "date": "Nov 15, 2019",
    "readMoreCta": "https://www.ozy.com/good-sht/how-to-get-free-coffee-just-for-taking-the-train-to-work/228052/"
  }
];


const Component: React.FC<{}> = observer(() => {
  const matches_tablet = useMediaQuery('(max-width: 1150px)');
  const matches_mobile = useMediaQuery('(max-width: 768px)');
  const articles: React.ReactElement[] = [];
  let numArticlesToDisplay = 6;
  if (matches_tablet === true) {
    numArticlesToDisplay = 4;
  }
  if (matches_mobile === true) {
    numArticlesToDisplay = 3;
  }

  for (let i=0; i < numArticlesToDisplay; i++) {
    articles.push(<CardUnit {...ALL_ARTICLES[i]} />);
  }
  return (
    <>
      <Styled_body_wrap className="">
      <div className="ciq-root">
        <div className="ciq-container">
          {articles}
        </div>
      </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
