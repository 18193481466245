import { Team } from '../avatar_box/index';

export const {
    TEAMS,
    TEAM_INFOS,
    TEAM_INFO_BY_ID,
} = (function() {
    const titles: {[id in Team]: string} = {
        design: 'Product & Design',
        engineering: 'Engineering',
        marketing: 'Growth & Sales',
        support: 'HR, Operations & Support',
    };
    const TEAMS = Object.keys(titles) as Team[];
    const TEAM_INFOS = Object.entries(titles).map(([id, title]) => ({
        id: id as Team,
        title,
    }));
    const TEAM_INFO_BY_ID = Object.fromEntries(TEAM_INFOS.map((info) => [
        info.id, info])) as {
            [id in Team]: typeof TEAM_INFOS[0];
        };
    return {
        TEAMS,
        TEAM_INFOS,
        TEAM_INFO_BY_ID,
    };
})();
