import styled from "styled-components";

export const MainWrap = styled("div")`
    width: 100%;
    margin: 0 auto;
    height: 100%;
    min-height: 100vh;
    background-color: #ffffff;

    /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    overflow: scroll;
  }
`;