import { commonIcon } from './common/commonIcon';


const config = {
    appStoreLinkBaseUrl: '',  // no RAF support
    miles_tenant_logo: '',  // no RAF support
    miles_share_reward_page_logo: ``, // no reward sharing support
    miles_logo_white: require('src/assets/commonImg/jread_white_logo@3x.png'),
    illustrations_gift: '',  // no RAF support
    download_text: '',  // no RAF support
    share_text: '',  // no RAF support
    redeem_rewards_text: '',  // no RAF support
    apple_store_src: '',  // no RAF support
    google_play_store_src: '',  // no RAF support
    listItemContent_1: '',  // no RAF support
    apple_store_icon: '',  // no RAF support
    google_play_store_icon: '',  // no RAF support
    reward_share_download_text: '',  // no RAF support
    reward_share_share_text: '',  // no RAF support
    reward_share_redeem_rewards_text: '',  // no RAF support
    reward_share_title_width_on_1150: `605px`, // no RAF support
    reward_share_title_width_on_600: `260px`, // no reward sharing support
    reward_share_title_width_on_360: `260px`, // no reward sharing support
    currentSupportEmailAddress: `support@getmiles.com`,
    topLogo: ``, // JRE unsubscribe not using this
    bgImgArr: [], // JRE unsubscribe not using this
    primary_color_01: "", // JRE unsubscribe not using this
    primary_color_02: "", // JRE unsubscribe not using this
    preference_sad_face_dog: "", // JRE unsubscribe not using this
    preference_re_subscribe_thankyou_bg: "", // JRE unsubscribe not using this
    confirmation_10_days_text_note_description_margin: `23px auto 0 auto`, // JRE unsubscribe not using this

    fontFamily_Gilroy_Medium: `Noto Sans JP Medium, Gilroy-Medium`,
    fontFamily_Gilroy_SemiBold: `Noto Sans JP Bold, Gilroy-SemiBold`,
    fontFamily_Gilroy_Regular: `Noto Sans JP, Gilroy-Regular`,

    password_reset_001: `新しいパスワードを設定する`,
    password_reset_002: `新しいパスワードをご設定ください。`,
    password_reset_003: `このページを更新しないでください。`,
    password_reset_004: `パスワード`,
    password_reset_005: `非表示`,
    password_reset_006: `表示`,
    password_reset_007: `パスワードは、少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`,
    password_reset_008: `パスワードは、8文字以上で少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`,
    password_reset_009: `パスワード（再入力）`,
    password_reset_010: `入力されたパワワードが一致していません`,
    password_reset_011: `パスワードを設定`,
    password_reset_012: `パスワードの再設定が完了しました`,
    password_reset_013: `パスワードの更新が完了しました。その他、ご不明な点は`,
    password_reset_014: `ヘルプセンター`,
    password_reset_014_01: `をご利用ください`,
    password_reset_015: `パスワード再設定エラー`,
    password_reset_016: `パスワードの再設定中にエラーが発生しました。その他、ご不明な点は`,
    password_reset_017: `ヘルプセンター`,
    password_reset_017_01: `をご利用ください`,
    password_reset_018: `リンクの期限切れ`,
    password_reset_019: `パスワード再設定リンクの期限が切れています。アプリから再設定の手続きを再度お試しください。その他、ご不明な点は`,
    password_reset_020: `ヘルプセンター`,
    password_reset_020_01: `をご利用ください`,
    password_reset_help_url: `https://www.getmiles.com/jp/help/category/account-settings-preferences/how-to-reset-password`,
    
    help_page_success_asset_v1: ``, // not support
    ...commonIcon,
}

export default config;
