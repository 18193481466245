import styled from "styled-components";

interface Props {
  type?: string;
}
export const StyledConfirmedSubTitle = styled("p")<Props>`
  width: 500px;
  font-family: 'Avenir Next';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.3px;
  color: #2d2f3b;
  margin: 0 auto;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: ${(props) => props.type === "update_preference_unsubscribe_all_emails" ? '255px' : '270px'};
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: ${(props) => props.type === "update_preference_unsubscribe_all_emails" ? '255px' : '270px'};
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;