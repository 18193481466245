import React from "react";
import { StyledAd } from "./styles";

interface Props {
  src: string;
  alt: string;
  textContent: string;
  width: string;
  height: string;
  marginTop: string;
  marginLeft: string;
  iconMarginLeft: string;
  isFirst?: boolean;
}

export const AdBox: React.FC<Props> = ({
  src,
  alt,
  textContent,
  width,
  height,
  marginTop,
  marginLeft,
  iconMarginLeft,
  isFirst
}) => {
  return (
    <StyledAd
      width={width}
      height={height}
      marginTop={marginTop}
      marginLeft={marginLeft}
      iconMarginLeft={iconMarginLeft}
      isFirst={isFirst}
    >
      <img
        className="fca-raf-ad-icon"
        src={src}
        alt={alt}
      ></img>
      <p className="fca-raf-slogan-text">{textContent}</p>
    </StyledAd>
  );
};
