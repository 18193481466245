import {MILES_WEB_S3_BASE_URL_V2} from './common/base';
import { commonIcon } from './common/commonIcon';


const config = {
  appStoreLinkBaseUrl: `https://app.getmiles.com`,
  miles_tenant_logo: require('src/assets/Miles/miles_logo@2x.png'),
  miles_share_reward_page_logo: require('src/assets/Miles/miles_logo@2x.png'),
  miles_logo_white: require('src/assets/commonImg/logo_wm_h_white@3x.png'),
  illustrations_gift: require('src/assets/Miles/graphic-raf-share@3x.png'),
  download_text: `Download the Miles app`,
  share_text: `Earn miles on all your travel & much more`,
  redeem_rewards_text: `Use miles to redeem for cash and gift cards`,
  apple_store_src: "https://apps.apple.com/app/miles/id1393913947",
  google_play_store_src: "https://play.google.com/store/apps/details?id=connectiq.miles.app",
  listItemContent_1: `Open the Miles app from your mobile phone`,
  apple_store_icon: require('src/assets/Miles/appStoreIcon/apple_app_store_icon@3x.png'),
  google_play_store_icon: require('src/assets/Miles/appStoreIcon/google_play_store_icon@3x.png'),
  reward_share_download_text: `Download the Miles app`,
  reward_share_share_text: `Earn miles on all your travel & much more`,
  reward_share_redeem_rewards_text: `Use miles to redeem for cash and gift cards`,
  reward_share_title_width_on_1150: `555px`,
  reward_share_title_width_on_600: `260px`,
  reward_share_title_width_on_360: `260px`,
  currentSupportEmailAddress: `support@getmiles.com`,
  topLogo: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_logo_white_2500%402x.png`,
  bgImgArr: [
    `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/miles_unsubscribe_bg_1440_v1/Group+10%402x.png`,
    `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_preference_top_bg_1280%402x.png`,
    `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_preference_top_bg_mobile%402x.png`,
  ],
  primary_color_01: "#10af42",
  primary_color_02: "#1ba756",
  preference_sad_face_dog: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/preference_sad_face_dog%402x.png`,
  preference_re_subscribe_thankyou_bg: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/preference_re_subscribe_thankyou_bg%402x.png`,
  confirmation_10_days_text_note_description_margin: `23px auto 0 auto`,

  fontFamily_Gilroy_Medium: `Gilroy-Medium`,
  fontFamily_Gilroy_SemiBold: `Gilroy-SemiBold`,
  fontFamily_Gilroy_Regular: `Gilroy-Regular`,

  password_reset_001: `Create a new password`,
  password_reset_002: `Please choose a new password for your Miles account.`,
  password_reset_003: `Do not refresh this page.`,
  password_reset_004: `Password`,
  password_reset_005: `hide`,
  password_reset_006: `show`,
  password_reset_007: `Must have atleast 1 special character (e.g. - @#$%!^&) `,
  password_reset_008: `At least 1 special character (e.g. - @#$%!^&) and 8 or more characters`,
  password_reset_009: `Confirm Password`,
  password_reset_010: `Password must match`,
  password_reset_011: `Reset Password`,
  password_reset_012: `Password reset successful`,
  password_reset_013: `Your password was successfully updated. If you have any other queries, please visit our `,
  password_reset_014: `Help Center.`,
  password_reset_014_01: ``,
  password_reset_015: `Password reset error`,
  password_reset_016: `Unable to reset your password at this time. Please try again from your app. If you have any other queries, please visit our `,
  password_reset_017: `Help Center.`,
  password_reset_017_01: ``,

  password_reset_018: `Link expired`,
  password_reset_019: `We're sorry, your password reset link has expired. Please submit another password reset request from the app. For more assistance, please visit our `,
  password_reset_020: `Help Center.`,
  password_reset_020_01: ``,
  password_reset_help_url: `https://www.getmiles.com/help/category/account-settings-preferences/how-to-reset-password`,
  
  help_page_success_asset_v1: `${MILES_WEB_S3_BASE_URL_V2}contact_page/send_contact_query_success/send_contact_query_success_1%402x.png`,
  ...commonIcon
};


export default config;
