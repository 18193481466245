import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin-top: 75px;

  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  background-color: aliceblue;

  .ciq_container {
    width: 600px;
    display: grid;
    grid-template-columns: repeat(4, 120px) ;
    grid-template-rows: repeat(2, 120px) ;
    place-items: center center;
    justify-content: space-between;
    gap: 20px 20px;
    background-color: lightgray;
  }

  .item {
      background-color: yellow;
      width: 120px;
      height: 120px;
      text-align: center;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {

    .ciq_container {
      width: 260px;
      grid-template-columns: repeat(2, 120px) ;
      grid-template-rows: repeat(2, 120px) ;
    }
  

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;