const miles_web_v2_s3_baseUrl = "https://getmiles.s3.amazonaws.com/assets/web_v2/";

const PARTNER_PAGE_ANIMATION = {
  TOP_ANIMATION_ON_PARTNER_PAGE: `${miles_web_v2_s3_baseUrl}partner_page/animation/partnerPage_animation/1440/data.json`,
  TOP_ANIMATION_ON_PARTNER_PAGE_1150_x_1440: `${miles_web_v2_s3_baseUrl}partner_page/animation/partnerPage_animation/1150_x_1440/data.json`,
  TOP_ANIMATION_ON_PARTNER_PAGE_768_x_1150: `${miles_web_v2_s3_baseUrl}partner_page/animation/partnerPage_animation/768_x_1150/data.json`,
  TOP_ANIMATION_ON_PARTNER_PAGE_360_x_768: `${miles_web_v2_s3_baseUrl}partner_page/animation/partnerPage_animation/360_x_768/data.json`,

  MJKK_TOP_ANIMATION_ON_PARTNER_PAGE: `${miles_web_v2_s3_baseUrl}partner_page/animation/mjkk_partnerPage_animation/1440/data.json`,
  MJKK_TOP_ANIMATION_ON_PARTNER_PAGE_1150_x_1440: `${miles_web_v2_s3_baseUrl}partner_page/animation/mjkk_partnerPage_animation/1150_x_1440/data.json`,
  MJKK_TOP_ANIMATION_ON_PARTNER_PAGE_768_x_1150: `${miles_web_v2_s3_baseUrl}partner_page/animation/mjkk_partnerPage_animation/768_x_1150/data.json`,
  MJKK_TOP_ANIMATION_ON_PARTNER_PAGE_360_x_768: `${miles_web_v2_s3_baseUrl}partner_page/animation/mjkk_partnerPage_animation/360_x_768/data.json`,
}

export const MILES_ANIMATIONS = {
  HERO_JSON_DATA: require("../assets/Miles/animations/hero/data.json"),
  EARN_MILES_JSON_DATA: require("../assets/Miles/animations/earnMiles/data.json"),
  REDEEM_MILES_JSON_DATA: require("../assets/Miles/animations/redeemMiles/data.json"),

  HERO_JSON_DATA_1440: require("../assets/Miles/animations/homePage/1440/Hero/data.json"),
  HERO_JSON_DATA_1150_1440: require("../assets/Miles/animations/homePage/1150_1440/Hero/data.json"),
  HERO_JSON_DATA_768_1150: require("../assets/Miles/animations/homePage/768_1150/Hero/data.json"),
  HERO_JSON_DATA_360_768: require("../assets/Miles/animations/homePage/360_768/Hero/data.json"),

  EARN_MILES_STARTING_STATE_JSON_DATA_1440: require("../assets/Miles/animations/homePage/1440/Earn/Earn_Starting_State/data.json"),
  EARN_MILES_STARTING_STATE_JSON_DATA_1150_1440: require("../assets/Miles/animations/homePage/1150_1440/Earn/Earn_Starting_State/data.json"),
  EARN_MILES_STARTING_STATE_JSON_DATA_768_1150: require("../assets/Miles/animations/homePage/768_1150/Earn/Earn_Starting_State/data.json"),
  EARN_MILES_STARTING_STATE_JSON_DATA_360_768: require("../assets/Miles/animations/homePage/360_768/Earn/Earn_Starting_State/data.json"),

  EARN_MILES_PLAY_STATE_JSON_DATA_1440: require("../assets/Miles/animations/homePage/1440/Earn/Earn_Play_State/data.json"),
  EARN_MILES_PLAY_STATE_JSON_DATA_1150_1440: require("../assets/Miles/animations/homePage/1150_1440/Earn/Earn_Play_State/data.json"),
  EARN_MILES_PLAY_STATE_JSON_DATA_768_1150: require("../assets/Miles/animations/homePage/768_1150/Earn/Earn_Play_State/data.json"),
  EARN_MILES_PLAY_STATE_JSON_DATA_360_768: require("../assets/Miles/animations/homePage/360_768/Earn/Earn_Play_State/data.json"),

  REDEEM_MILES_JSON_DATA_1440: require("../assets/Miles/animations/homePage/1440/Redeem/data.json"),
  REDEEM_MILES_JSON_DATA_1150_1440: require("../assets/Miles/animations/homePage/1150_1440/Redeem/data.json"),
  REDEEM_MILES_JSON_DATA_768_1150: require("../assets/Miles/animations/homePage/768_1150/Redeem/data.json"),
  REDEEM_MILES_JSON_DATA_360_768: require("../assets/Miles/animations/homePage/360_768/Redeem/data.json"),

  PARTNER_PAGE_ANIMATION,
};

export const EMAIL_DOMAINS_CLEANED = [
  "gmail.cm",
  "gmail.cn",
  "gmail.co",
  "gmail.co.uk",
  "gmail.com",
  "gmail.com.br",
  "gmail.com.com",
  "gmail.om",
  "gmail.ru",
  "outlook.cl",
  "outlook.co.id",
  "outlook.co.il",
  "outlook.com",
  "outlook.com.br",
  "outlook.com.gr",
  "outlook.com.tr",
  "outlook.com.vn",
  "outlook.de",
  "outlook.es",
  "outlook.fr",
  "outlook.ie",
  "outlook.in",
  "outlook.it",
  "outlook.kr",
  "outlook.pt",
  "outlook.sa",
  "yahoo.ca",
  "yahoo.cm",
  "yahoo.cn",
  "yahoo.co",
  "yahoo.co.id",
  "yahoo.co.in",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "yahoo.co.nz",
  "yahoo.co.uk",
  "yahoo.com",
  "yahoo.com.ar",
  "yahoo.com.au",
  "yahoo.com.br",
  "yahoo.com.cn",
  "yahoo.com.co",
  "yahoo.com.hk",
  "yahoo.com.mx",
  "yahoo.com.my",
  "yahoo.com.pe",
  "yahoo.com.ph",
  "yahoo.com.sg",
  "yahoo.com.tr",
  "yahoo.com.tw",
  "yahoo.com.ve",
  "yahoo.com.vn",
  "yahoo.de",
  "yahoo.dk",
  "yahoo.es",
  "yahoo.fr",
  "yahoo.gr",
  "yahoo.ie",
  "yahoo.in",
  "yahoo.it",
  "yahoo.net",
  "yahoo.no",
  "yahoo.pl",
  "yahoo.ro",
  "yahoo.se",
  "aol.co.uk",
  "aol.com",
  "aol.fr",
  "aol.net",
  "zoho.com",
  "mail.com.tr",
  "protonmail.ch",
  "protonmail.com",
  "icloud.com",
];

export const EXTRA_EMAIL_DOMAINS_CLEANED = [
  "privaterelay.appleid.com",
  "hotmail.com",
  "live.com",
  "ymail.com",
  "comcast.net",
  "msn.com",
  "me.com",
  "att.net",
  "rocketmail.com",
  "sbcglobal.net",
  "mail.com",
  "aim.com",
  "verizon.net",
  "bellsouth.net",
  "cox.net",
  "charter.net",
  "juno.com",
  "qq.com",
  "mail.ru",
  "gmx.com",
  "mac.com",
  "optonline.net",
  "email.com",
  "hotmail.es",
  "gmail.con",
  "hotmail.fr",
  "owee.org",
  "jarilusua.com",
  "1.c",
  "lakqs.com",
  "dmeproject.com",
  "windstream.net",
  "deselling.com",
  "justlibre.com",
  "yopmail.com",
  "cjpeg.com",
  "lanelofte.com",
  "frontier.com",
  "roadrunner.com",
  "molms.com",
  "tashjw.com",
  "netzero.com",
  "earthlink.net",
  "fuwari.be",
  "gnail.com",
  "jetblue.com",
  "netzero.net",
  "ximtyl.com",
  "embarqmail.com",
  "getmiles.com",
  "googlemail.com",
  "usa.com",
  "hmail.com",
  "gosupertechs.com",
  "mamariot.com",
  "fuwamofu.com",
  "netscape.net",
  "twc.com",
  "y.com",
  "fiu.edu",
  "naver.com",
  "g.com",
  "yahoo.con",
  "yandex.com",
  "asu.edu",
  "gmai.com",
  "cityofaspen.com",
  "kent.edu",
  "septicvernon.com",
  "umich.edu",
  "163.com",
  "vt.edu",
  "email.vccs.edu",
  "zik2zik.com",
  "psk3n.com",
  "cps.edu",
  "swanticket.com",
  "usako.net",
  "5y5u.com",
  "g.c",
  "tampabay.rr.com",
  "q.com",
  "xhypm.com",
  "hotmail.co.uk",
  "pastortips.com",
  "osu.edu",
  "dkt1.com",
  "vmvgoing.com",
  "tdcryo.com",
  "teeshirtsprint.com",
  "ucdavis.edu",
  "questza.com",
  "live.com.mx",
  "lowcache.com",
  "frontiernet.net",
  "csus.edu",
  "ptd.net",
  "cfl.rr.com",
  "gmsil.com",
  "umn.edu",
  "gmail.c",
  "btsese.com",
  "gclee.xyz",
  "excite.com",
  "mail.usf.edu",
  "ofdow.com",
  "mymail.lausd.net",
  "gm.com",
  "ncsu.edu",
  "58as.com",
  "live.fr",
  "myself.com",
  "whowlft.com",
  "ivytech.edu",
  "ahk.jp",
  "gmal.com",
  "liberty.edu",
  "ruru.be",
  "mchsi.com",
  "qortu.com",
  "gmail.coml",
  "usc.edu",
  "my.com",
  "gomail4.com",
  "berkeley.edu",
  "suddenlink.net",
  "choco.la",
  "hawaii.edu",
  "msu.edu",
  "knights.ucf.edu",
  "icloud.con",
  "mirai.re",
  "mailinator.com",
  "ufl.edu",
  "prowerl.com",
  "hotsoup.be",
  "centurylink.net",
  "tds.net",
  "fmail.com",
  "zoominternet.net",
  "stayhome.li",
  "neko2.net",
  "ichigo.me",
  "fanclub.pm",
  "h.com",
  "kkmail.be",
  "cream.pink",
  "cableone.net",
  "utexas.edu",
  "gmail.comm",
  "yandex.ru",
  "mbox.re",
  "nyu.edu",
  "crimson.ua.edu",
  "rochester.rr.com",
  "macr2.com",
  "rbiwc.com",
  "b.com",
  "buffalo.edu",
  "nc.rr.com",
  "merry.pink",
  "via.tokyo.jp",
  "moimoi.re",
  "uci.edu",
  "cornell.edu",
  "us.army.mil",
  "nv.ccsd.net",
  "pacbell.net",
  "icould.com",
  "ameritech.net",
  "live.cn",
  "eay.jp",
  "foxmail.com",
  "rediffmail.com",
  "tutanota.com",
  "unicornpoop.club",
  "hughes.net",
  "gmx.us",
  "gkworkoutq.com",
  "owleyes.ch",
  "126.com",
  "hotmail.it",
  "f5.si",
  "fau.edu",
  "gmaik.com",
  "ucr.edu",
  "gmil.com",
  "wp.pl",
  "prodigy.net",
  "insightbb.com",
  "carolina.rr.com",
  "mail.gvsu.edu",
  "centurytel.net",
  "sika3.com",
  "societyhill.club",
  "windowslive.com",
  "optimum.net",
  "fuse.net",
  "qa.team",
  "new.rr.com",
  "nycap.rr.com",
  "hanmail.net",
  "kpay.be",
  "deloitte.com",
  "bostonk12.org",
  "gmail.cim",
  "atlanticbb.net",
  "digdig.org",
  "gamil.com",
  "b.n",
  "miamioh.edu",
  "triad.rr.com",
  "fxprix.com",
  "mail.fresnostate.edu",
  "mee.com",
  "myway.com",
  "audi.com",
  "cchealth.org",
  "temple.edu",
  "nwytg.net",
  "my.fsu.edu",
  "cmich.edu",
  "twcny.rr.com",
  "inbox.com",
  "desoz.com",
  "udel.edu",
  "livinginsurance.co.uk",
  "colorado.edu",
  "hotmail.con",
  "mailfile.org",
  "magim.be",
  "peoplepc.com",
  "svk.jp",
  "prin.be",
  "psu.edu",
  "wowway.com",
  "kean.edu",
  "g.g",
  "utclubsxu.com",
  "ucla.edu",
  "hotmail.de",
  "cadenwjh.com",
  "swbell.net",
  "ojbk2010.club",
  "recursor.net",
  "ucsd.edu",
  "g.austincc.edu",
  "bu.edu",
  "dmvhypee.club",
  "myspace.com",
  "hushmail.com",
  "obliviondesigns.org",
  "unknmail.com",
  "cinci.rr.com",
  "pm.me",
  "mymdc.net",
  "kctcs.edu",
  "bol.com.br",
  "gmail.coms",
  "maine.edu",
  "deloitte.c",
  "deloitte.co",
  "gmx.de",
  "d.d",
  "web.de",
  "neo.rr.com",
  "mix.wvu.edu",
  "ohio.edu",
  "niseko.be",
  "vcu.edu",
  "zips.uakron.edu",
  "uga.edu",
  "snet.net",
  "mailnd7.com",
  "dollalive.com",
  "dumoac.net",
  "loketa.com",
  "eoopy.com",
  "kw.com",
  "rapt.be",
  "armcams.com",
  "email.arizona.edu",
  "bk.ru",
  "advyc.com",
  "isu.edu",
  "mail3plus.net",
  "royandk.com",
  "snhu.edu",
  "gmail.comj",
  "g.clemson.edu",
  "yhaoo.com",
  "b.c",
  "h.j",
  "google.com",
  "auburn.edu",
  "gmial.com",
  "emich.edu",
  "upr.edu",
  "cs.com",
  "columbia.edu",
  "hj.com",
  "maricopa.edu",
  "whhmail.com",
  "stny.rr.com",
  "woh.rr.com",
  "sc.rr.com",
  "purdue.edu",
  "pickybuys.com",
  "gamail.com",
  "nau.edu",
  "tywmp.com",
  "hotmail.co.jp",
  "wensonnn.com",
  "bresnan.net",
  "gma.com",
  "list.ru",
  "email.sc.edu",
  "virginia.edu",
  "abv.bg",
  "gmail.coma",
  "email.phoenix.edu",
  "wgu.edu",
  "frzpzr.xyz",
  "v.com",
  "mail3x.net",
  "tmail2.com",
  "themail3.net",
  "overcomebf.com",
  "digital10network.com",
  "gmail.comcom",
  "honeys.be",
  "okstate.edu",
  "iu.edu",
  "vols.utk.edu",
  "mail.ccsf.edu",
  "chevron.com",
  "larjem.com",
  "hotmail.com.ar",
  "myldsmail.net",
  "gmail.vom",
  "guacap.xyz",
  "keviiii.33mail.com",
  "gmail.org",
  "matra.top",
  "illinois.edu",
  "bths.edu",
  "wsu.edu",
  "csu.fullerton.edu",
  "simaenaga.com",
  "pgcps.org",
  "sharklasers.com",
  "austin.rr.com",
  "georgiasouthern.edu",
  "mediacombb.net",
  "sacrt.com",
  "uconn.edu",
  "stopspam.app",
  "odu.edu",
  "syr.edu",
  "dopejay.com",
  "wi.rr.com",
  "mindspring.com",
  "ya.com",
  "cheerful.com",
  "case.edu",
  "uh.edu",
  "uic.edu",
  "tamu.edu",
  "seznam.cz",
  "ivyandmarj.com",
  "students.ocps.net",
  "uky.edu",
  "baker.edu",
  "att.com",
  "csumb.edu",
  "mtu.edu",
  "bsu.edu",
  "yk20.com",
  "tmail.com",
  "cloud.com",
  "ucsc.edu",
  "rambler.ru",
  "n.m",
  "nate.com",
  "gmaim.com",
  "mail3.top",
  "wwmails.com",
  "facebook.com",
  "iclould.com",
  "gail.com",
  "hawaii.rr.com",
  "binghamton.edu",
  "saintbot.online",
  "my.csun.edu",
  "aang.club",
  "4tmail.com",
  "synevde.com",
  "avc.edu",
  "sina.com",
  "post.com",
  "banetc.com",
  "fastmail.com",
  "comporium.net",
  "pitt.edu",
  "tx.rr.com",
  "wisc.edu",
  "mailrnl.com",
  "nickrizos.com",
  "uol.com.br",
  "shinnemo.com",
  "uncg.edu",
  "trufilth.com",
  "celinecityitalia.com",
  "o2.pl",
  "go.lisd.net",
  "dpsk12.net",
  "firemailbox.club",
  "acu.edu",
  "wonghmail.com",
  "uncc.edu",
  "gaorena.xyz",
  "live.ca",
  "husky.neu.edu",
  "hruayang.com",
  "u.com",
  "tmails.top",
  "gmail.clm",
  "aprilmayphotography.com",
  "u.northwestern.edu",
  "meee.com",
  "radford.edu",
  "risu.be",
  "email.cpcc.edu",
  "adkaddy.com",
  "ghj.com",
  "gmaul.com",
  "wayne.edu",
  "ukr.net",
  "krzyzacy.com",
  "ucmerced.edu",
  "coastal.edu",
  "gmail.come",
  "h.h",
  "dr.com",
  "inscriptio.in",
  "nycstudents.net",
  "inbox.ru",
  "gmali.com",
  "topper.wku.edu",
  "s.s",
  "hotmail.com.br",
  "f.f",
  "aldine-isd.org",
  "gj.com",
  "my.smccd.edu",
  "gmailnator.com",
  "polishcastles.com",
  "maildrop.cc",
  "gmaio.com",
  "ail.com",
  "yhoo.com",
  "o3enzyme.com",
  "dukes.jmu.edu",
  "lsu.edu",
  "hormail.com",
  "mail.uc.edu",
  "hotmai.com",
  "satx.rr.com",
  "gmail.comn",
  "njit.edu",
  "student.gsu.edu",
  "stanford.edu",
  "g.h",
  "zohomail.com",
  "gmm.com",
  "uw.edu",
  "kingskyfish.com",
  "j.j",
  "a.a",
  "u.pacific.edu",
  "drivermiles.com",
  "umbc.edu",
  "live.co.uk",
  "epbfi.com",
  "sjsu.edu",
  "outlook.con",
  "ig.com.br",
  "cuoly.com",
  "rcn.com",
  "goldmail.etsu.edu",
  "porsche.digital",
  "whatbotis.com",
  "wildblue.net",
  "enayu.com",
  "harding.edu",
  "tapi.re",
  "handsomeman.club",
  "criptext.com",
  "eagles.usm.edu",
  "indiana.edu",
  "hoi-poi.com",
  "gmail.comd",
  "my.uri.edu",
  "mnsu.edu",
  "yaho.com",
  "ualr.edu",
  "d.umn.edu",
  "maine.rr.com",
  "interia.pl",
  "sfamo.com",
  "pwc.com",
  "g.ucla.edu",
  "t.com",
  "trimsj.com",
  "bex.net",
  "j.k",
  "iastate.edu",
  "mail.chapman.edu",
  "montclair.edu",
  "aggiemail.usu.edu",
  "altmails.com",
  "live.com.ar",
  "luanjianming.com",
  "bgsu.edu",
  "georgetown.edu",
  "e.com",
  "eatmeat.monster",
  "ycp.edu",
  "lol.com",
  "yan.com",
  "starkstate.net",
  "mtmail.mtsu.edu",
  "osxi.io",
  "ponp.be",
  "tmailer.org",
  "topikt.com",
  "nypanz.icu",
  "appstate.edu",
  "moreheadstate.edu",
  "stu.neisd.net",
  "qqmimpi.com",
  "lindsey.edu",
  "mailmetal.com",
  "hellokitty.com",
  "mailer2.net",
  "keepdecent.xyz",
  "mail.broward.edu",
  "gmail.edu",
  "u.boisestate.edu",
  "philasd.org",
  "alumni.nd.edu",
  "hunsten.tech",
  "hocking.edu",
  "delotti.com",
  "buckeyemail.osu.edu",
  "tomaswins.com",
  "wright.edu",
  "gmail.comc",
  "comcast.com",
  "e.e",
  "peace888.com",
  "utdallas.edu",
  "umd.edu",
  "mail.missouri.edu",
  "selu.edu",
  "gmail.comt",
  "b.b",
  "uark.edu",
  "daum.net",
  "unab.edu.co",
  "eagle.fgcu.edu",
  "gav.best",
  "txstate.edu",
  "pdx.edu",
  "winthrop.edu",
  "hotnail.com",
  "louisville.edu",
  "myfairpoint.net",
  "gmaill.com",
  "yanoo.com",
  "uola.org",
  "99mimpi.com",
  "sjhzhwcop.xyz",
  "bethelu.edu",
  "stew941.club",
  "gmail.comp",
  "mimiale.xyz",
  "hduwoh.club",
  "uahoo.com",
  "mail2chicago.com",
  "icoud.com",
  "wildcats.unh.edu",
  "gemail.com",
  "midmich.edu",
  "oregonstate.edu",
  "student.ctuonline.edu",
  "prequels.org",
  "yah.com",
  "chartermi.net",
  "student.vvc.edu",
  "blacktostit.com",
  "dhhd.com",
  "tahoo.com",
  "estopg.com",
  "qq.c",
  "terpmail.umd.edu",
  "marshall.edu",
  "qq.co",
  "ut.utm.edu",
  "bpghmag.com",
];