import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import {
  Styled_body_wrap,
  StyledCard,
} from "./styles";

const {
  auto_oem_page_assets: assets,
} = config;

const CARDS_INFO = [
  // card 01
  {
    card_wrap_className: 'card_wrap_01',
    imgSrc: assets.help_your_brand_01_img,
    imgClassName: 'drive_success_img_01 miles_ui_screen_1440',
    title: `Increase Engagement`,
    titleClassName: 'oem_help_your_brand_title_01 card_title',
    sub_title: `Added level of customer engagement by offering a rewards program`,
    sub_titleClassName: 'card_sub_title'
  },
  // card 02
  {
    card_wrap_className: 'card_wrap_02',
    imgSrc: assets.help_your_brand_02_img,
    imgClassName: 'drive_success_img_02',
    title: `Build Brand Loyalty`,
    titleClassName: 'oem_help_your_brand_title_02 card_title',
    sub_title: `An effective way to increase brand loyalty by offering your own products and services`,
    sub_titleClassName: 'card_sub_title'
  },
  // card 03
  {
    card_wrap_className: 'card_wrap_03',
    imgSrc: assets.help_your_brand_03_img,
    imgClassName: 'drive_success_img_03',
    title: `Improve Customer Insights`,
    titleClassName: 'oem_help_your_brand_title_03 card_title',
    sub_title: `Discover unprecedented value in your customer’s journey through behavioral insights`,
    sub_titleClassName: 'card_sub_title'
  },
  // card 04
  {
    card_wrap_className: 'card_wrap_04',
    imgSrc: assets.help_your_brand_04_img,
    imgClassName: 'drive_success_img_04',
    title: `Add Digital Revenue`,
    titleClassName: 'oem_help_your_brand_title_04 card_title',
    sub_title: `Drive revenue by tapping into new digital monetization streams`,
    sub_titleClassName: 'card_sub_title'
  },
]

interface cardProps {
  card_wrap_className: string;
  imgSrc: string;
  imgClassName: string;
  title: string | JSX.Element;
  titleClassName: string;
  sub_title: string | JSX.Element;
  sub_titleClassName: string;
}

export const Card: React.FC<cardProps> = ({
  card_wrap_className,
  imgSrc,
  imgClassName,
  title,
  titleClassName,
  sub_title,
  sub_titleClassName
}) => {
  return (
    <StyledCard className={card_wrap_className} key={card_wrap_className}>
      <img className={imgClassName} src={imgSrc} alt={`${imgSrc}`} />
      <div className={titleClassName}>{title}</div>
      <div className={sub_titleClassName}>{sub_title}</div>
    </StyledCard>
  );
};


const Component: React.FC<{}> = observer(() => {
  let cardsEle: ReactNode[] = []

  CARDS_INFO.map((card, index) => {
    cardsEle.push(
      <Card
        card_wrap_className={card.card_wrap_className}
        imgSrc={card.imgSrc}
        imgClassName={card.imgClassName}
        title={card.title}
        titleClassName={card.titleClassName}
        sub_title={card.sub_title}
        sub_titleClassName={card.sub_titleClassName}
        key={card.imgClassName}
      />
    )
  })

  return (
    <>
      <Styled_body_wrap className="oem_section_help_your_brand">
        <div className="main_container">
          <div className="oem_help_your_brand_title">How can Miles help your brand?</div>
          
          <div className="oem_help_your_brand_cards_wrap">
            {cardsEle}
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
