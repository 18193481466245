import styled from "styled-components";

interface Props {
  isJP: boolean;
}
export const Styled_body_wrap = styled("div")<Props>`
  padding: 0;  
  overflow-x: hidden;

  .main_body {
    max-width: 1440px;
    margin: 0 auto;
  }

  .text_01 {
    width: auto;
    height: 58px;
    flex-grow: 0;
    font-family: Gilroy-Bold;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: 2d2f3b;
  }

  .miles_ui_not_found_bugg {
    img {
      width: 320px;
      height: 129px;
      display: block;
      margin: 0 auto;
    }
  }

  .description_text {
    margin: 0 auto;
    width: 600px;
    height: 64px;
    flex-grow: 0;
    font-family: Gilroy-Medium;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.7px;
    text-align: center;
    color: #82828c;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .text_01 {
      height: 36px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1;
    }

    .miles_ui_not_found_bugg {
      img {
        width: 200px;
        height: 80.6px;
      }
    }
  
    .description_text {
      width: 328px;
      height: 81px;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.59;
      letter-spacing: 0.7px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .text_01 {
      height: 36px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1;
    }

    .miles_ui_not_found_bugg {
      img {
        width: 200px;
        height: 80.6px;
      }
    }
  
    .description_text {
      width: 328px;
      height: 81px;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.59;
      letter-spacing: 0.7px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 361px) and (max-device-width : 480px) {
    
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .text_01 {
      height: 52px;
      font-size: 42px;
      font-weight: bold;
      line-height: 1.24;
    }
  
    .description_text {
      width: 600px;
      height: 60px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.7px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .text_01 {
      height: 52px;
      font-size: 42px;
      font-weight: bold;
      line-height: 1.24;
    }
  
    .description_text {
      width: 600px;
      height: 60px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.7px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;