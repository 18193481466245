import React from 'react';
import { observer } from 'mobx-react';
import {
  StyledInput,
  StyledBtn,
  StyledFcaRafSendDownloadErrTipEle,
  StyledSuccessInfo,
} from './styles';
import { store } from '../../../Store';

interface Props {
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type: string;
  handleSendDownloadLink?: () => void;
  inputEl: RefObject<HTMLInputElement>;
}

export const Get_miles_CTA: React.FC<Props> = observer(({
  inputEl,
  placeholder,
  value,
  onChange,
  onKeyDown,
  type,
  handleSendDownloadLink,
}) => {
  let isBtnDisabled = store.get_miles_links_status.get_miles_link === 'invalidNum_error' || store.get_miles_links_status.get_miles_link === 'sending'
  let shouldShowErrMessage = store.get_miles_links_status.get_miles_link === 'invalidNum_error'
                              || store.get_miles_links_status.get_miles_link === 'timeout'
                              || store.get_miles_links_status.get_miles_link === 'server_error'
  return (
    <>
      {store.get_miles_links_status.get_miles_link === 'sent'
        ? <StyledSuccessInfo>Check your phone for download link</StyledSuccessInfo>
        : (
          <>
            <div>
              <StyledInput
                ref={inputEl}
                send_downloadLink_phoneErr={shouldShowErrMessage}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type={type}
              /> 

              <StyledBtn
                onClick={handleSendDownloadLink}
                style={{
                  cursor: isBtnDisabled ? 'unset' : 'pointer'
                }}
                disabled={isBtnDisabled}
                bgColor={store.get_miles_links_status.get_miles_link === 'invalidNum_error' ? '#9b9ba5' : '#10af42'}
              >{store.get_miles_links_status.get_miles_link === 'sending' ? 'Sending...' : 'Send Download Link'}</StyledBtn>
            </div>

            <StyledFcaRafSendDownloadErrTipEle send_downloadLink_phoneErr={shouldShowErrMessage}>
            {store.get_miles_links_status.get_miles_link === 'invalidNum_error' &&  '* Enter a valid US number'}
            {(store.get_miles_links_status.get_miles_link === 'timeout' || store.get_miles_links_status.get_miles_link === 'server_error') && 'Something went wrong. Please try again.'}
            </StyledFcaRafSendDownloadErrTipEle>
          </>
        )
      }
    </>
  );
});
