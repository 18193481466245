import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0px;

  .ciq-root{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
  }
  
  .ciq-container{
    width: auto;
    height: auto;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: 0px;
    margin-top: 100px;
  }
 
  .title {
    width: 667px;
    height: 70px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq-container {
      margin-top: 60px;
    }
    .title {
      width: 288px;
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.1;
      letter-spacing: 0.63px;
      text-align: center;
      color: #2d2f3b;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq-container {
      margin-top: 60px;
    }
    
    .title {
      width: 288px;
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .title {
      width: 667px;
      height: 70px;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.19;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;