// S3 https://s3.console.aws.amazon.com/s3/buckets/getmiles?prefix=assets/web_v2/partner_page/&showversions=false
import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import Lottie, { LottieRefCurrentProps, InteractivityProps } from "lottie-react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils, { useCountry } from "../../utils/milesUtils";
import { useTranslation } from 'react-i18next';
import changeLanguage from '../../utils/i18n/i18next';
import {
  EMAIL_DOMAINS_CLEANED,
  EXTRA_EMAIL_DOMAINS_CLEANED,
  MILES_ANIMATIONS,
} from "../../utils/milesConstant";
import {
  checkValidPhoneNumber
} from '../../utils/phoneNumValidator';
import { Styled_body_wrap } from "./styles";
import { store } from "../../Store";
import Discover_new from "./discover_new/index";
import Carousel_01 from "./carousel_01/index";
import Carousel_02 from "./carousel_02/index";
import Carousel_03 from "./carousel_03/index";
import Drive_success from "./drive_success/index";
import Partners_we_worked_with from "./partners_we_worked_with/index";
import Partners_we_worked_with_JP from "../../components_jp/partners_we_worked_with";

import Growing_fast from "./growing_fast/index";
import Pre_footer_form from "./pre_footer_form/index";
import Pre_footer_form_JP from "../../components_jp/pre_footer_form";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import { dialogFormStyle } from "./dialogFormStyle";
import { dialogFormStyle_MJKK } from "./dialogFormStyle_MJKK";
import partnerPage_animation_data from "../../utils/animation_json";

let firstRender = true;

const {
  partner_page_assets: assets,
} = config;


const GlobalCss = withStyles({
  "@global": {
    ".partner_form_dialog": {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgb(45, 47, 59, 0.7)",
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100vw",
      },
      "& .MuiDialog-paper": {
        padding: "0",
        margin: "0",
        maxHeight: "calc(100% - 15px)",
        "& .form_wrap": dialogFormStyle,

        "@media only screen and (max-width : 360px)": {
          margin: "10px",
        },
      }
    },
    ".partner_section_partner_form.mjkk": {
      "& .form_wrap": dialogFormStyle_MJKK,
    },
  },
})(() => null);

const MilesWeb_PartnerPage: React.FC<{}> = observer(() => {
  const hero_01_on_partner_Ref = useRef<LottieRefCurrentProps>(null);
  const hero_01_on_partner_Ref_768_x_1150 = useRef<LottieRefCurrentProps>(null);
  const hero_01_on_partner_Ref_360_x_768 = useRef<LottieRefCurrentProps>(null);

  const [values, setValues] = useState({
    name: "",
    business_title: "",
    email: "",
    phone_number: "",
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errs, setErrs] = useState({
    name: false,
    business_title: false,
    email: false,
    phone_number: false,
    submissionErr: false
  });

  const [position, setPosition] = useState(0);
  
  const inputEl_on_dialog = useRef<HTMLInputElement>(null);
  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      if (currentPhoneNum.length > 10) {
        currentPhoneNum = currentPhoneNum.slice(0, 10); 
      }
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);

      const selectionStart = inputEl_on_dialog!.current!.selectionStart as number;

      let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_miles_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl_on_dialog.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleUpdateValues = useCallback(
    (event: React.ChangeEvent<HTMLFormElement | HTMLTextAreaElement | HTMLInputElement>) => {
      setValues({
        ...values,
        ...{ [event.target.name]: event.target.value },
      });
      event.preventDefault();
    },
    [values],
  );

  const checkEmailValue = (email: string) => {
    let new_array = [
      ...EMAIL_DOMAINS_CLEANED,
      ...EXTRA_EMAIL_DOMAINS_CLEANED,
    ]
    return new_array.indexOf(email.trim().split("@")[1]) > -1
  }

  const handleSubmitPartnerData = () => {
    console.log("handleSubmitPartnerData")
    setErrs({
      ...errs,
      business_title: !values.business_title.trim(),
      name: !values.name.trim(),
      email: checkEmailValue(values.email) || !milesUtils.isValidEmailAddress(values.email),
      phone_number: !checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
    })

    let no_err = values.business_title.trim()
      && values.name.trim()
      && !checkEmailValue(values.email) && milesUtils.isValidEmailAddress(values.email)
      && checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
    
    if (
      (values.name && values.business_title && values.email && phoneNumber)
      && no_err) {
      store.handlePartnerFormData({
        ...values,
        phone_number: phoneNumber,
      }, "dialog_form")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (inputEl_on_dialog.current) {
      inputEl_on_dialog.current!.selectionStart = position;
      inputEl_on_dialog.current!.selectionEnd = position;
    }
  }, [position, phoneNumber]);

  const [openPartnerForm, setOpenPartnerForm] = React.useState(false);

  const handleClickOpen_partnerForm = () => {
    setOpenPartnerForm(true);
  };

  const handleClose_partnerForm = () => {
    store.update_partner_page_form_status("dialog_form", "not_start");
    setValues({
      name: "",
      business_title: "",
      email: "",
      phone_number: "",
    });
    setPhoneNumber("");
    setErrs({
      name: false,
      business_title: false,
      email: false,
      phone_number: false,
      submissionErr: false
    });
    setOpenPartnerForm(false);
  };

  let country = useCountry();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_PartnerPage -- firstRender");
    switch (country) {
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_PartnerPage -- did mount");

    firstRender = false;
  }, []);

  const scoll_to_pre_footer_form = () => {
    let scrollFormDiv = document && document.getElementById("partner_page_let_get_started")!.offsetTop;
    window.scrollTo({ top: scrollFormDiv - 20, behavior: 'smooth'});
  }

  const { t } = useTranslation();

  let current_partnerPage_animation_data = country === 'jp' ? partnerPage_animation_data.mjkk : partnerPage_animation_data.miles;

  return (
    <Styled_body_wrap country={country} >
      <GlobalCss />

      <div className="miles_ui_top_hero_01">
        <div className="main_container">
          <Lottie
            lottieRef={hero_01_on_partner_Ref}
            animationData={current_partnerPage_animation_data["1440"]}
            loop={true}
            autoplay={true}
            className="miles_ui_partner_lottie_01 screen_larger_than_1150"
          />

          <div className="miles_ui_top_content_wrap">

            <div className="miles_ui_top_content_01">
            {t('partner_page_text_001')}
            </div>
            <div className="miles_ui_top_content_02">
            {t('partner_page_text_002')}
            </div>
            <div className="miles_ui_top_content_03">
            {t('partner_page_text_003')}
            </div>
            <button className={`miles_ui_btn_partner_with_us miles_ui_btn_primary`} onClick={scoll_to_pre_footer_form}>{t('partner_page_text_004')}</button>

          </div>

          <Lottie
            lottieRef={hero_01_on_partner_Ref_768_x_1150}
            animationData={current_partnerPage_animation_data["768_x_1150"]}
            loop={true}
            autoplay={true}
            className="screen_between_768_and_1150 miles_ui_partner_lottie_between_768_and_1150"
          />

          <Lottie
            lottieRef={hero_01_on_partner_Ref_360_x_768}
            animationData={current_partnerPage_animation_data["360_x_768"]}
            loop={true}
            autoplay={true}
            className="screen_between_360_x_768 miles_ui_partner_lottie_between_360_x_768"
          />

        </div>
      </div>

      <Discover_new />

      <Carousel_01 />

      <Drive_success />

      {
        country === "jp"
        ? null
        : <Carousel_02 handleClickOpen_partnerForm={handleClickOpen_partnerForm} />
      }

      {
        country === "jp"
        ? <Partners_we_worked_with_JP scoll_to_pre_footer_form={scoll_to_pre_footer_form} />
        : <Partners_we_worked_with scoll_to_pre_footer_form={scoll_to_pre_footer_form} />
      }
      
      {
        country === "jp"
        ? null
        : <Carousel_03 />
      }

      {
        country === "jp"
        ? null
        : <Growing_fast />
      }

      {
        country === "jp"
        ? <div className="miles_ui_home_pre_footer_blankSpace"></div>
        : null
      }

      {
        country === "jp"
        ? <Pre_footer_form_JP />
        : <Pre_footer_form />
      }

      <Dialog
        className="partner_form_dialog"
        open={openPartnerForm}
        onClose={handleClose_partnerForm}
        aria-labelledby="form_dialog_on_partner_page"
        disableBackdropClick={true}
      >
        {
            store.partner_page_form_status.dialog_form === "sent"
            ? (
              <div className="form_wrap">
                <div className="partner_page_dialog_header">
                  <span className="partner_page_dialog_header_title">Learn More About This Case Study</span>

                  <button className="partner_page_esc_icon_btn_wrap" onClick={handleClose_partnerForm}>
                    <img className="partner_page_esc_icon_img" src={assets.partner_page_esc_icon_img} alt={`partner_page_esc_icon_img`}></img>
                  </button>
                </div>

                <img className="partner_page_pre_footer_form_asset" src={assets.partner_page_form_sent_success_asset_img} alt={`partner_page_form_sent_success_asset_img`}></img>
                <div className="partner_page_pre_footer_form_success_text">We have received your information. Someone from our team will get back to you within 48 hours. </div>
              </div>
            ) : (
              <div className="form_wrap">

              <div className="partner_page_dialog_header">
                <span className="partner_page_dialog_header_title">Learn More About This Case Study</span>

                <button className="partner_page_esc_icon_btn_wrap" onClick={handleClose_partnerForm}>
                  <img className="partner_page_esc_icon_img" src={assets.partner_page_esc_icon_img} alt={`partner_page_esc_icon_img`}></img>
                </button>
              </div>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">Name</span>
                  <input
                    className={`${errs.name ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleUpdateValues}
                    required
                    placeholder="Name"
                  />
                  <span className={errs.name ? "show_err_info" : "hide_err_info"}>Please enter a valid name</span>
                </label>

                <label className="input_inner_section input_box_02">
                  <span className="label_text">Business Title</span>
                  <input
                    className={`${errs.business_title ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="business_title"
                    value={values.business_title}
                    onChange={handleUpdateValues}
                    required
                    placeholder="Business Title"
                  />
                  <span className={errs.business_title ? "show_err_info" : "hide_err_info"}>Please enter a valid business title</span>
                </label>

                <label className="input_inner_section input_box_03">
                  <span className="label_text">Business Email Address</span>
                  <input
                    className={`${errs.email ? "input_err" : "no_err"} partner_preFooter_form_input input_02`}
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleUpdateValues}
                    required
                    placeholder="Business Email Address"
                  />
                  <span className={errs.email ? "show_err_info" : "hide_err_info"}>Please enter a valid business email address</span>
                </label>

                <label className="input_inner_section input_box_04">
                  <span className="label_text">Phone Number</span>
                  <input
                    ref={inputEl_on_dialog}
                    className={`${errs.phone_number ? "input_err" : "no_err"} partner_preFooter_form_input input_03`}
                    type="tel"
                    name="phone_number"
                    required
                    value={phoneNumber}
                    onChange={handleChangePhoneNum}
                    onKeyDown={handleOnKeyDown}
                    placeholder="(123) - 456 - 7890"
                  />
                  <span className={errs.phone_number ? "show_err_info" : "hide_err_info"}>Please enter a valid phone number</span>
                </label>

                <div className="tip_text">By proceeding, I agree that MILES may contact me by email or phone</div>

                <button className={`partner_page_pre_form_cta_btn miles_ui_btn_primary ${store.partner_page_form_status.pre_footer_form === "sending" ? "loading_status" : ""}`} onClick={() => store.partner_page_form_status.pre_footer_form === "sending" ? {} : handleSubmitPartnerData()}>Submit Request</button>
                <span className={store.partner_page_form_status.pre_footer_form === "server_error" ? "show_err_info submissionErr" : "hide_err_info"}>Something went wrong. Please try again.</span>
              </div>
            )
          }
      </Dialog>
      
    </Styled_body_wrap>
  );
});

export default MilesWeb_PartnerPage;
