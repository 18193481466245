import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  .ciq_root{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
    background-color: #fffcef;
    margin-bottom: 115px;
  }

  .ciq_container{
    width: 100%;
    height: auto;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px;
    background-color: #fffcef;
    max-width: 1440px;
  }

  .top_banner_picture{
    display: block;
    justify-content: center;
    width: auto;
    height: 356px;
  }

  .top_banner_title {
    width: max-content;
    height: auto;
    font-family: Gilroy-Bold;
    text-align: center;
    color: #10af42;
    ${process.env.REACT_APP_MILES_TENANT === "edsp" &&
    "color: #fd902b;"
    }
    position: absolute;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 2.59px;
    margin-top: 98px;
  }

  .top_banner_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq_root{
      margin-bottom: 70px;
      position: relative;
    }

    .top_banner_picture{
      display: block;
      justify-content: center;
      width: auto;
      height: 224px;
    }

    .top_banner_title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.8px;
      text-align: center;
      margin-top: 67px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq_root{
      position: relative;
    }
    .ciq_container {
      max-width: 600px;
    }
    .top_banner_picture{
      display: block;
      justify-content: center;
      width: auto;
      height: 260px;
    }

    .top_banner_title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.8px;
      text-align: center;
      margin-top: 67px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .ciq_root{
      position: relative;
    }
    .top_banner_picture{
      display: block;
      justify-content: center;
      width: auto;
      height: 224px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .ciq_root{
      position: relative;
    }
    .top_banner_picture{
      display: block;
      justify-content: center;
      width: auto;
      height: 224px;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .ciq_root{
      position: relative;
    }
    .top_banner_picture{
      display: block;
      justify-content: center;
      width: auto;
      height: 224px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .top_banner_title {
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      text-align: center;
      margin-top: 103px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .top_banner_title {
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2.7px;
      text-align: center;
      margin-top: 100px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .top_banner_title {
      margin-top: 93px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1745px) {
    .top_banner_img{
      object-fit: contain;
    }
  }
`;