import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;
interface AdProps {
  width: string;
  height: string;
  marginTop: string;
  marginLeft: string;
  iconMarginLeft: string;
  isFirst?: boolean;
}

export const StyledAd = styled("div")<AdProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: ${(props) => props.marginTop};
  .fca-raf-ad-icon {
    display: block;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin-left: ${(props) => props.iconMarginLeft};
  }

  .slogan-text {
      height: 40px;
      font-family: ${() => fontFamily_Gilroy_Medium};
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      color: #5F5F69;
      margin-left: ${(props) => props.marginLeft};
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin-top: ${(props) => (props.isFirst ? '0' : '16px')};
    .fca-raf-ad-icon {
      display: block;
      width: 20px;
      height: 20px;
      margin-left: 0;
    }
    .slogan-text {
      height: 20px;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      color: #363636;
      margin-left: 12px;
    }
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    margin-top: ${(props) => (props.isFirst ? '0' : '16px')};
    .fca-raf-ad-icon {
      display: block;
      width: 20px;
      height: 20px;
      margin-left: 0;
    }
    .slogan-text {
      height: 20px;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
      color: #363636;
      margin-left: 12px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    margin-top: ${(props) => (props.isFirst ? '0' : '14px')};
    .slogan-text {
      font-size: 14px;
    }

    .fca-raf-ad-icon {
      width: 18px;
      height: 18px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
  }

  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    margin-top: ${(props) => (props.isFirst ? '0' : '15px')};
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    margin-top: ${(props) => (props.isFirst ? '0' : '15px')};
  }
`;