import styled from "styled-components";

interface Props {
  bg: string[];
}
export const StyledEmailPreferenceHeader = styled("div")<Props>`
  height: 300px;
  background-image: url(${(props) => props.bg[0]});
  position: relative;
  background-size: 1440px 300px;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 0;

  .miles_logo_icon {
    display: inline-block;
    width: 178px;
    height: 50px;

    margin-top: 64px;

    img {
      width: 178px;
      height: 50px;
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 168px;
    background-image: url(${(props) => props.bg[2]});
    background-size: cover;
    margin-top: 0;

    .miles_logo_icon {
      display: inline-block;
      width: 100px;
      height: 28px;
  
      margin-top: 36px;
  
      img {
        width: 100px;
        height: 28px;
      }
  
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 600px) {
    height: 168px;
    background-image: url(${(props) => props.bg[2]});
    background-size: cover;

    .miles_logo_icon {
      display: inline-block;
      width: 100px;
      height: 28px;
  
      margin-top: 36px;
  
      img {
        width: 100px;
        height: 28px;
      }
  
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 601px) and (max-width : 1150px) {
    height: 334px;
    background-image: url(${(props) => props.bg[1]});
    background-size: cover;

    .miles_logo_icon {
      display: inline-block;
      width: 199px;
      height: 56px;
  
      margin-top: 70px;
  
      img {
        width: 199px;
        height: 56px;
      }
  
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    height: 267px;
    background-size: 100vw 267px;

    .miles_logo_icon {
      display: inline-block;
      width: 159px;
      height: 45px;
  
      margin-top: 64px;
  
      img {
        width: 159px;
        height: 45px;
      }
  
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {}
`;