import styled from "styled-components";
import config from "../../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

export const Styled_body_wrap = styled("div")`
  margin: 0;
  .subtitle_text{
    width: 336px;
    height: 78px;
    font-family: ${fontFamily_Gilroy_Regular};
    font-size: 16px;
    text-align: center;
    line-height: 26px;
    letter-spacing: 0.89px;
    color: #2d2f3b;
    margin-top: 119px;
    white-space: pre-line;
  }


  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .subtitle_text{
      width: 279px;
      height: 78px;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      letter-spacing: 0.78px;
      color: #2d2f3b;
      margin-top: 72px;
    } 
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .subtitle_text{
      width: 279px;
      height: 78px;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      letter-spacing: 0.78px;
      color: #2d2f3b;
      margin-top: 72px;
    } 
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .subtitle_text{
      width: 279px;
      height: 78px;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      letter-spacing: 0.78px;
      color: #2d2f3b;
      margin-top: 72px;
    } 
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .subtitle_text{
      width: 279px;
      height: 78px;
      font-family: Gilroy-Regular;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      letter-spacing: 0.78px;
      color: #2d2f3b;
      margin-top: 72px;
    }  
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .subtitle_text{
      width: 279px;
      height: 78px;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      letter-spacing: 0.78px;
      color: #2d2f3b;
      margin-top: 72px;
    } 
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;