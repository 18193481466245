import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import {
  Styled_body_wrap,
} from "./styles";

const {
  auto_oem_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {

  return (
    <>
      <Styled_body_wrap className="oem_section_core_solution">
        <div className="main_container">
          <div className="core_solution_01_wrap">
            <picture className="top_banner_picture">
              <source className="core_solution_01_img" media="(max-width : 360px)" srcSet={assets.core_solution_hero_01_x_360_img}></source>
              <source className="core_solution_01_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.core_solution_hero_01_360_x_768_img}></source>
              <source className="core_solution_01_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.core_solution_hero_01_768_x_1150_img}></source>
              <source className="core_solution_01_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.core_solution_hero_01_1150_x_1440_img}></source>
              <source  className="core_solution_01_img" media="(min-width : 1441px)" srcSet={assets.core_solution_hero_01_1440_img}></source>
              <img className="core_solution_01_img" alt={`core_solution_01_img`}></img>
            </picture>
          </div>

          <div className="core_solution_02_wrap">
            <picture className="top_banner_picture">
              <source className="core_solution_02_img" media="(max-width : 360px)" srcSet={assets.core_solution_hero_02_x_360_img}></source>
              <source className="core_solution_02_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.core_solution_hero_02_360_x_768_img}></source>
              <source className="core_solution_02_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.core_solution_hero_02_768_x_1150_img}></source>
              <source className="core_solution_02_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.core_solution_hero_02_1150_x_1440_img}></source>
              <source  className="core_solution_02_img" media="(min-width : 1441px)" srcSet={assets.core_solution_hero_02_1440_img}></source>
              <img className="core_solution_02_img" alt={`core_solution_02_img`}></img>
            </picture>
          </div>

          <div className="core_solution_03_wrap">
            <picture>
              <source className="core_solution_03_img" media="(max-width : 360px)" srcSet={assets.core_solution_hero_03_x_360_img}></source>
              <source className="core_solution_03_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.core_solution_hero_03_360_x_768_img}></source>
              <source className="core_solution_03_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.core_solution_hero_03_768_x_1150_img}></source>
              <source className="core_solution_03_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.core_solution_hero_03_1150_x_1440_img}></source>
              <source  className="core_solution_03_img" media="(min-width : 1441px)" srcSet={assets.core_solution_hero_03_1440_img}></source>
              <img className="core_solution_03_img" alt={`core_solution_03_img`}></img>
            </picture>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
