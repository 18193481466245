import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import HelpCardUnit from "../help_card_unit/index";
import { useHelpPageRawData, ITEM_TYPE, QUESTION_TYPE } from '../../../data';

const Component: React.FC<{}> = observer(() => {
  const items = useHelpPageRawData().user;

  return (
    <>
      <Styled_body_wrap>
        <div className="ciq_root">
          <div className="ciq_container">
            <div className="top_row ciq_spacer">
              {/* TODO(Prutha): Write this in the CSS grid format so every line here can look the same. */}
              <HelpCardUnit item={items[0]} addClass={true}/>
              <HelpCardUnit item={items[1]} addClass={true}/>
              <HelpCardUnit item={items[2]} addClass={false}/>
            </div>
            <div className="bottom_row">
              <HelpCardUnit item={items[3]} addClass={true}/>
              <HelpCardUnit item={items[4]} addClass={true}/>
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
