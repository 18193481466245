import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0 auto;
  padding: 0;
  width: 100vw;

  .inline_block {
    display: inline-block;
  }

  .display_block {
    display: block;
  }

  .larger_than_768 {
    display: block;
  }

  .between_360_and_768 {
    display: none;
  }

  .jobs_top_asset {
    margin: 0 auto;
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_top_asset_1440_v2@2x.png) center no-repeat;
    background-size: 1440px 325px;
　　 background-position: center center;
  }

  /* section_rocket_ship_wrap - start */ 

  .section_rocket_ship_wrap {
    margin: 0;
    padding: 0;
    width: 100vw;
    .section_rocket_ship_body {
      margin: 0 auto;
      width: 1440px;
      height: 544px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_header_asset_01_v2@2x.png) center no-repeat;
      background-size: 1193px 408px;
  　　 background-position: center center;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .section_rocket_ship_title {
        width: 513px;
        height: 118px;
        font-family: Gilroy-Bold;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 59px;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 79px;
      }

      .margin_top_0 {
        margin-top: 0;
      }

      .section_rocket_ship_content {
        width: 492px;
        height: 130px;
        font-family: Gilroy-Medium;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 33px;
      }

    }
  }
  /* section_rocket_ship_wrap - end */ 

  /* section_right_fit_wrap - start */ 

  .section_right_fit_wrap {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 490px;
    background-color: #fbfefc;
    

    .section_right_fit_body {
      margin: 0 auto;
      padding-right: 116px;
      padding-left: 121px;
      width: 1440px;
      height: 490px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .section_right_fit_content_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;

        .section_right_fit_title {
          width: auto;
          height: 36px;
          margin: 0;
          font-family: Gilroy-Bold;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.13px;
          color: #2d2f3b;
        }

        .section_right_fit_content_text {
          width: 575px;
          height: 72px;
          margin-top: 20px;
          font-family: Gilroy-Medium;
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.64;
          letter-spacing: normal;
          color: #2d2f3b;
        }

        .margin_top_0 {
          margin-top: 0;
        }

        .section_right_fit_cta {
          width: 319px;
          height: 64px;
          margin-top: 50px;
          padding: 17px 71px 14px;
          border-radius: 32px;
          background-color: #10af42;
          outline: none;
          border: none;
          font-family: Gilroy-SemiBold;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;

          &:hover {
            text-decoration: none;
          }
        }

      }

      .jobs_find_your_fit_asset {
        width: 505px;
        height: 250px;
      }
    }


  }

  /* section_right_fit_wrap - end */ 
  
  .testimonial_card {
    display: flex;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 140px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 985px;
  }

  .miles_family_wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 360px;
  }

  .miles_ui_family {
    margin: 0 auto;
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_family@2x.png) center no-repeat;
    background-size: cover;
    width: 1440px;
    height: 606px;
　　 background-position: center center;
  }

  .miles_ui_jobsPage_title {
    width: max-content;
    height: 56px;
    margin: 0;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 125px;
  }

  .miles_ui_jobsPage_sub_title {
    width: 678px;
    height: auto;
    margin: 0;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 20px;
  }

  .jobs_miles_location {
    width: 1078px;
    height: 527px;
    display: inline-block;
    margin-top: 64px;
  }

  .miles_ui_jobsPage_address {

    .address_01 {
      position: absolute;
      width: 429px;
      height: auto;
      font-family: Gilroy-Bold;
      font-size: 34px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.59;
      letter-spacing: normal;
      color: #2d2f3b;
      margin-top: -213px;
      margin-left: 556px;
    }
    .address_02 {
      position: absolute;
      width: 429px;
      height: auto;
      font-family: Gilroy-Medium;
      font-size: 34px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.59;
      letter-spacing: normal;
      color: #2d2f3b;
      margin-top: -159px;
      margin-left: 556px;
    }
    .address_03 {
      position: absolute;
      width: 429px;
      height: auto;
      font-family: Gilroy-Medium;
      font-size: 34px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.59;
      letter-spacing: normal;
      color: #2d2f3b;
      margin-top: -105px;
      margin-left: 556px;
    }
  }

  .miles_ui_jobsPage_sub_title_02 {
    width: 600px;
    height: 84px;
    margin: 0;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 212px;
  }

  .join_us_cta {
    width: 319px;
    height: 64px;
    margin: 0;
    padding: 17px 71px 14px;
    border-radius: 32px;
    background-color: #10af42;
    margin-top: 52px;
    margin-bottom: 100px;
    outline: none;
    border: none;
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    &:hover {
      text-decoration: none;
    }
  }

  /* mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-width: 360px;

    .larger_than_768 {
      display: none;
    }

    .between_360_and_768 {
      display: block;
    }

    .jobs_top_asset {
      width: 100vw;
      height: 250px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_top_asset_lessThan_360_v2@2x.png) center no-repeat;
      background-size: cover;
    }

    /* section_rocket_ship_wrap - start */ 

    .section_rocket_ship_wrap {
      .section_rocket_ship_body {
        width: 100vw;
        height: 337px;
        background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_header_asset_01_between_360_and_768_v2@2x.png) center no-repeat;
        background-size: 100vw auto;
    　　 background-position: center 234px;


        .section_rocket_ship_title {
          width: 240px;
          height: 72px;
          font-family: Gilroy-Bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 40px;
        }

        .section_rocket_ship_content {
          width: 300px;
          height: 72px;
          font-family: Gilroy-Medium;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 25px;
        }
      }
    }
    /* section_rocket_ship_wrap - end */ 

    /* section_right_fit_wrap - start */ 

    .section_right_fit_wrap {
      height: 667px;

      .section_right_fit_body {
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;
        width: 100vw;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;

        .section_right_fit_content_wrap {
          align-items: center;

          .section_right_fit_title {
            width: auto;
            height: 46px;
            margin: 0;
            font-family: Gilroy-Bold;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.1;
            letter-spacing: 0.63px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 90px;
          }

          .section_right_fit_content_text {
            width: 290px;
            height: 72px;
            font-family: Gilroy-Medium;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 0.53px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 20px;
          }

          .margin_top_0 {
            margin-top: 0;
          }

          .section_right_fit_cta {
            margin: 0;
            width: 217px;
            height: 52px;
            border-radius: 25px;
            box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
            background-color: #10af42;
            margin-top: 494px;
            padding: 15px 23px;
            outline: none;
            border: none;
            font-family: Gilroy-SemiBold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.7px;
            text-align: center;
            color: #ffffff;
            position: absolute;

            &:hover {
              text-decoration: none;
            }
          }

        }

        .jobs_find_your_fit_asset {
          width: 287px;
          height: 142px;
          margin-top: 47px;
        }
      }

    }

    /* section_right_fit_wrap - end */ 

    .testimonial_card {
      display: flex;
      margin: 0 auto;
      margin-top: 55px;
      margin-bottom: 115px;
      width: 275px;

      & .jobs_work_at_miles_1 {
        display: none;
      }
  
      & .jobs_work_at_miles_2 {
        display: none;
      }
    }

    .miles_ui_family {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_family_between_360_and_768@2x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 631px;
      background-position: center center;
      margin-top: 0;
    }

    .miles_ui_jobsPage_title {
      width: max-content;
      height: 46px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 100px;
    }

    .miles_ui_jobsPage_sub_title {
      width: 327px;
      height: auto;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 20px;
    }

    .miles_ui_jobsPage_address {

      .mobile_address_box {
        width: 230px;
        height: 190px;
        margin: 0 auto;
        padding: 27px 20px 24px;
        border-radius: 14px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ecfbf5;
        margin-top: 33px;

        .mobile_address_box_asset {
          display: block;
          margin: 0 auto;
          width: 26px;
          height: 32px;
        }

        .address_01_mobile {
          width: 190px;
          height: auto;
          margin: 0;
          font-family: Gilroy-Bold;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 17px;
        }

        .address_02_mobile {
          width: max-content;
          height: auto;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
        }

        .address_03_mobile {
          width: 190px;
          height: auto;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
        }
      }

      .jobs_miles_location_mobile {
        width: 320px;
        height: 324px;
        margin-top: 43px;
      }
    }

    .miles_ui_jobsPage_sub_title_02 {
      width: 230px;
      height: 72px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 120px;
    }

    .join_us_cta {
      width: 146px;
      height: 40px;
      margin: 0;
      padding: 11px 21px 8px 20px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
      margin-top: 30px;
      margin-bottom: 45px;
      outline: none;
      border: none;
      font-family: Gilroy-SemiBold;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
  
      &:hover {
        text-decoration: none;
      }
    }

    
  }

    /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .larger_than_768 {
      display: none;
    }

    .between_360_and_768 {
      display: block;
    }

    .jobs_top_asset {
      width: 100vw;
      height: 250px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_top_asset_between_360_and_768_v2@2x.png) center no-repeat;
      background-size: cover;
    }

    /* section_rocket_ship_wrap - start */ 

    .section_rocket_ship_wrap {
      .section_rocket_ship_body {
        width: 100vw;
        height: 337px;
        background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_header_asset_01_between_360_and_768_v2@2x.png) center no-repeat;
        background-size: 348px 79px;
    　　 background-position: center 234px;


        .section_rocket_ship_title {
          width: 336px;
          height: 72px;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 38px;
        }

        .section_rocket_ship_content {
          width: 268px;
          height: 72px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 26px;
        }
      }
    }
    /* section_rocket_ship_wrap - end */ 

    /* section_right_fit_wrap - start */ 

    .section_right_fit_wrap {
      height: 667px;

      .section_right_fit_body {
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;
        width: 100vw;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;

        .section_right_fit_content_wrap {
          align-items: center;

          .section_right_fit_title {
            width: auto;
            height: 46px;
            margin: 0;
            font-family: Gilroy-Bold;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: 0.75px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 66px;
          }

          .section_right_fit_content_text {
            width: 327px;
            height: 72px;
            font-family: Gilroy-Medium;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 0.61px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 15px;
          }

          .margin_top_0 {
            margin-top: 0;
          }

          .section_right_fit_cta {
            margin: 0;
            width: 217px;
            height: 52px;
            border-radius: 25px;
            box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
            background-color: #10af42;
            margin-top: 522px;
            padding: 15px 23px;
            outline: none;
            border: none;
            font-family: Gilroy-SemiBold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.7px;
            text-align: center;
            color: #ffffff;
            position: absolute;

            &:hover {
              text-decoration: none;
            }
          }

        }

        .jobs_find_your_fit_asset {
          width: 350px;
          height: 173px;
          margin-top: 69px;
        }
      }

    }

    /* section_right_fit_wrap - end */ 

    .testimonial_card {
      display: flex;
      margin: 0 auto;
      margin-top: 36px;
      margin-bottom: 115px;
      width: 275px;

      & .jobs_work_at_miles_1 {
        display: none;
      }
  
      & .jobs_work_at_miles_2 {
        display: none;
      }
    }

    .miles_ui_family {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_family_between_360_and_768@2x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 631px;
      background-position: center center;
      margin-top: 0;
    }

    .miles_ui_jobsPage_title {
      width: max-content;
      height: 46px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 120px;
    }

    .miles_ui_jobsPage_sub_title {
      width: 327px;
      height: auto;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 20px;
    }

    .miles_ui_jobsPage_address {

      .mobile_address_box {
        width: 230px;
        height: 190px;
        margin: 0 auto;
        padding: 27px 20px 24px;
        border-radius: 14px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ecfbf5;
        margin-top: 33px;

        .mobile_address_box_asset {
          display: block;
          margin: 0 auto;
          width: 26px;
          height: 32px;
        }

        .address_01_mobile {
          width: 190px;
          height: auto;
          margin: 0;
          font-family: Gilroy-Bold;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 17px;
        }

        .address_02_mobile {
          width: max-content;
          height: auto;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
        }

        .address_03_mobile {
          width: 190px;
          height: auto;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
        }
      }

      .jobs_miles_location_mobile {
        width: 320px;
        height: 324px;
        margin-top: 43px;
      }
    }

    .miles_ui_jobsPage_sub_title_02 {
      width: 230px;
      height: 72px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 97px;
    }

    .join_us_cta {
      width: 146px;
      height: 40px;
      margin: 0;
      padding: 11px 21px 8px 20px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
      margin-top: 30px;
      margin-bottom: 84px;
      outline: none;
      border: none;
      font-family: Gilroy-SemiBold;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
  
      &:hover {
        text-decoration: none;
      }
    }
    
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .jobs_top_asset {
      width: 100vw;
      height: 325px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_top_asset_1000_v2@2x.png) center no-repeat;
      background-size: cover;
    }

    /* section_rocket_ship_wrap - start */ 

    .section_rocket_ship_wrap {
      .section_rocket_ship_body {
        width: 100vw;
        height: 618px;
        background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_header_asset_01_1000_v2@2x.png) center no-repeat;
        background-size: 727px 415px;
    　　 background-position: center 70px;


        .section_rocket_ship_title {
          width: 416px;
          height: 118px;
          font-size: 32px;
          line-height: 1.63;
          margin-top: 79px;
        }

        .section_rocket_ship_content {
          width: 470px;
          height: 118px;
          font-size: 20px;
          line-height: 1.8;
          margin-top: 36px;
        }
      }
    }
    /* section_rocket_ship_wrap - end */ 

    /* section_right_fit_wrap - start */ 

    .section_right_fit_wrap {
      height: 1024px;

      .section_right_fit_body {
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;
        width: 100vw;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;

        .section_right_fit_content_wrap {
          align-items: center;

          .section_right_fit_title {
            width: auto;
            height: 32px;
            margin: 0;
            font-family: Gilroy-Bold;
            font-size: 32px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            color: #2d2f3b;
            margin-top: 125px;
          }

          .section_right_fit_content_text {
            width: 428px;
            height: 73px;
            font-family: Gilroy-Medium;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.25;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 15px;
          }

          .margin_top_0 {
            margin-top: 0;
          }

          .section_right_fit_cta {
            width: 217px;
            height: 52px;
            margin: 0;
            margin-top: 751px;
            padding: 15px 23px;;
            border-radius: 25px;
            box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
            background-color: #10af42;
            outline: none;
            border: none;
            font-family: Gilroy-SemiBold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.7px;
            text-align: center;
            color: #ffffff;
            position: absolute;

            &:hover {
              text-decoration: none;
            }
          }

        }

        .jobs_find_your_fit_asset {
          width: 709px;
          height: 351px;
          margin-top: 75px;
        }
      }

    }

    /* section_right_fit_wrap - end */ 

    .testimonial_card {
      display: flex;
      margin: 0 auto;
      margin-top: 72px;
      margin-bottom: 140px;
      width: 597px;
  
      & .jobs_work_at_miles_2 {
        display: none;
      }
    }

    .miles_ui_family {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_family_between_768_and_1150@2x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 1024px;
      background-position: center center;
      margin-top: 18px;
    }

    .miles_ui_jobsPage_title {
      width: max-content;
      height: 56px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 125px;
    }
  
    .miles_ui_jobsPage_sub_title {
      width: 500px;
      height: 56px;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 20px;
    }

    .jobs_miles_location {
      width: 769px;
      height: 376px;
      display: inline-block;
      margin-top: 67px;
    }

    .miles_ui_jobsPage_address {

      .address_01 {
        position: absolute;
        width: 306px;
        height: auto;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #2d2f3b;
        margin-top: -154px;
        margin-left: 397px;
      }
      .address_02 {
        position: absolute;
        width: 429px;
        height: auto;
        font-family: Gilroy-Medium;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #2d2f3b;
        margin-top: -114px;
        margin-left: 397px;
      }
      .address_03 {
        position: absolute;
        width: 429px;
        height: auto;
        font-family: Gilroy-Medium;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #2d2f3b;
        margin-top: -74px;
        margin-left: 397px;
      }
    }

    .miles_ui_jobsPage_sub_title_02 {
      width: 356px;
      height: 56px;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 125px;
    }

    .join_us_cta {
      width: 217px;
      height: 52px;
      margin: 0;
      padding: 13px 23px 15px 21px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      background-color: #10af42;
      margin-top: 41px;
      margin-bottom: 189px;
      outline: none;
      border: none;
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.7px;
      text-align: center;
      color: #ffffff;
  
      &:hover {
        text-decoration: none;
      }
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .jobs_top_asset {
      width: 100vw;
      height: 325px;
      background-size: auto 325px;
      background-size: cover;
    }

    /* section_rocket_ship_wrap - start */ 
    .section_rocket_ship_wrap {
      .section_rocket_ship_body {
        margin: 0 auto;
        width: 100vw;
        height: 544px;
        background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_header_asset_01_v2@2x.png) center no-repeat;
        background-size: 1099px auto;
    　　 background-position: center center;

        .section_rocket_ship_title {
          width: 513px;
          height: 118px;
          font-family: Gilroy-Bold;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 119px;
        }

        .margin_top_0 {
          margin-top: 0;
        }

        .section_rocket_ship_content {
          width: 405px;
          height: 118px;
          font-family: Gilroy-Medium;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.8;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 33px;
        }

      }
    }
    /* section_rocket_ship_wrap - end */ 

    /* section_right_fit_wrap - start */ 

    .section_right_fit_wrap {

      .section_right_fit_body {
        margin: 0 auto;
        padding-right: 97px;
        padding-left: 104px;
        width: 100vw;
        height: 490px;
        display: flex;

        .section_right_fit_content_wrap {

          .section_right_fit_title {}

          .section_right_fit_content_text {
            width: 527px;
            height: 72px;
            margin-top: 20px;
            font-size: 20px;
            line-height: 1.8;
          }

          .margin_top_0 {
            margin-top: 0;
          }

          .section_right_fit_cta {}

        }

        .jobs_find_your_fit_asset {}
      }

    }

    /* section_right_fit_wrap - end */ 

    .testimonial_card {
      margin-bottom: 125px;
    }

    .miles_ui_family {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_family_1280@2x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 965px;
  　　 background-position: center center;
    }

    .miles_ui_jobsPage_sub_title {
      width: 678px;
      margin-top: 32px;
    }

    .jobs_miles_location {
      margin-top: 52px;
    }

    .miles_ui_jobsPage_sub_title_02 {
      margin-top: 211px;
    }

    .join_us_cta {
      margin-bottom: 145px;
    }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .jobs_top_asset {
      margin: 0 auto;
      width: 1440px;
      height: 325px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_top_asset_1440_v2@2x.png) center no-repeat;
      background-size: cover;
  　　 background-position: center center;
    }

    .miles_ui_family {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_miles_family@2x.png) center no-repeat;
      background-size: cover;
      width: 1440px;
      height: 606px;
  　　 background-position: center center;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .jobs_top_asset {
      margin: 0 auto;
      width: 100vw;
      height: 325px;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_top_hero_largeThan_1440_v2@2x.png) center no-repeat;
      background-size: cover;
  　　 background-position: center center;
    }

    .miles_ui_family {
      margin: 0 auto;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_family_largeThan_1440@2x.png) center no-repeat;
      background-size: cover;
      width: 100vw;
      height: 606px;
  　　 background-position: center center;
    }
  }
`;