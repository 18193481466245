import styled from "styled-components";

interface Props {
  isJP: boolean;
}
export const Styled_body_wrap = styled("div")<Props>`
  margin: 0 auto;
  padding: 0;  
  overflow-x: hidden;

  .divider_space_after_carousel_01 {
    height: 117px;
    width: 100vw;
  }

  .divider_space_before_carousel_02 {
    height: 270px;
    width: 100vw;
    background: rgb(232,247,237,0.17);
    ${(props) => props.isJP &&
    `background: #fff;`
    }
  }

  .divider_space_after_jp_leadership {
    height: 263px;
    width: 100vw;
  }

  .divider_space_after_carousel_02 {
    height: 150px;
    width: 100vw;
  }

  .miles_ui_about_page_leadership {
    height: 820px;
    background: yellow;
  }

  .miles_ui_about_page_ourBoard {
    height: 775px;
    background: yellow;
  }

  .miles_ui_about_page_ourInvestor {
    height: 660px;
    background: green;
  }

  .miles_ui_about_page_workAtMiles {
    height: 1138px;
    background: green;
  }

  .divider_space_after_carousel_03 {
    height: 161px;
  }

  .divider_space_before_companyInfo {
    height: 260px;
  }

  .divider_space_after_companyInfo {
    height: 128px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .divider_space_after_carousel_01 {
      height: 100px;
    }

    .divider_space_before_companyInfo {
      height: 130px;
    }
  
    .divider_space_after_companyInfo {
      height: 64px;
    }

    .divider_space_after_jp_leadership {
      height: 135px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .divider_space_after_carousel_01 {
      height: 176px;
    }
    .divider_space_after_carousel_03 {
      height: 161px;
    }

    .divider_space_after_jp_leadership {
      height: 135px;
    }
  
    .divider_space_after_carousel_02 {
      height: 120px;
      width: 100vw;
    }

    .divider_space_before_companyInfo {
      height: 130px;
    }
  
    .divider_space_after_companyInfo {
      height: 64px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 361px) and (max-device-width : 480px) {
    .divider_space_after_carousel_01 {
      height: 176px;
    }
    .divider_space_after_carousel_03 {
      height: 161px;
    }

    .divider_space_before_carousel_02 {
      height: 120px;
      width: 100vw;
    }
  
    .divider_space_after_carousel_02 {
      height: 120px;
      width: 100vw;
    }

    .divider_space_after_jp_leadership {
      height: 135px;
    }
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .divider_space_after_carousel_01 {
      height: 182px;
    }

    .divider_space_after_carousel_03 {
      height: 198px;
    }

    .divider_space_after_jp_leadership {
      height: 269px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .divider_space_after_carousel_01 {
      height: 144px;
    }

    .divider_space_after_carousel_03 {
      height: 241px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;