import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import copy from 'copy-to-clipboard';
import { AsYouType } from 'libphonenumber-js'
import { store } from "../../Store";
import { FcaRafHeader } from "../../components/raf_header_v2";
import { TitleSection } from "../../components/raf_title_section_v2";
import { ActionSection } from "../../components/raf_action_section_v2";
import {
  checkValidPhoneNumber
} from '../../utils/phoneNumValidator'
import config from "../../utils/config";
import { useTranslation } from 'react-i18next';
import changeLanguage from '../../utils/i18n/i18next';
import {
  jp_phone_regex,
} from '../../utils/phoneNumValidator';
import milesUtils, { useCountry } from "../../utils/milesUtils";
import {
  FcaRafWrap
} from "./styles";

type referInfoObj = {
  phone_number: string;
  referral_code: string;
  role_type: string;
};

let firstRender = true

let animateFlipTimer: any = null

const {
  miles_share_reward_page_logo,
  illustrations_gift,
  download_icon,
  download_text,
  share_icon,
  share_text,
  redeem_rewards_icon,
  redeem_rewards_text
} = config

const RAF_v2: React.FC<{}> = observer(() => {
  const fcaCodeRef = useRef<HTMLIFrameElement>(null);
  const [shouldShowCopyBox, setShouldShowCopyBox] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [position, setPosition] = useState(0);
  const inputEl = useRef<HTMLInputElement>(null);

  const [phoneErr, setPhoneErr] = useState(false);
  const [shouldDisableSendBtn, setShouldDisableSendBtn] = useState(false);
  
  let country = useCountry();
  if (firstRender) {
    store.fetchFcaRafReferInfo().then(() => {
      animateFlip()
    })
    switch (window.location.pathname.split('/')[1].toLowerCase()) {
      case 'refer':
        changeLanguage("english")
        break;
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        console.log(`Sorry, wrong raf url`);
    }
  }

  useEffect(() => {
    firstRender = false
    return () => clearTimeout(animateFlipTimer);
  }, []);

  const animateFlip = () => {
    animateFlipTimer = setTimeout(() =>  {
      if (store.fcaRaf_Rewards.length > 0) {
        store.fcaRaf_Rewards[store.fcaRaf_flipOrder[store.fcaRaf_flipOrderIndex]] =
        store.fcaRaf_substituteImages[store.fcaRaf_rewardIndex];
        store.fcaRaf_flipOrderIndex =
        (store.fcaRaf_flipOrderIndex + 1) % store.fcaRaf_flipOrder.length;
        store.fcaRaf_rewardIndex =
        (store.fcaRaf_rewardIndex + 1) % store.fcaRaf_substituteImages.length;
      }
      requestAnimationFrame(animateFlip);
    }, 3500);
  }

  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      let currentPosition;
      const selectionStart = inputEl!.current!.selectionStart as number;

      if(country === "jp") {
        shouldUseCurrentValue = (e.target.value.length <= 3);
        currentPhoneNum = e.target.value.replace(/\D/g, "")
        formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : new AsYouType("JP").input(currentPhoneNum);
        currentPosition = milesUtils.getCurrentPosition_JP(formattedPhoneNum, phoneNumber, posit, selectionStart)
    } else {
        if (currentPhoneNum.length > 10) {
          currentPhoneNum = currentPhoneNum.slice(0, 10); 
        }
        formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);
  
        currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)
  
      }

      setPosition(currentPosition as number)
      setPhoneNumber(formattedPhoneNum)
      store.update_miles_links_status('get_miles_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_download_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleShouldShowCopyBox = useCallback(
    () => {
      setShouldShowCopyBox(true)
      copy(store.fca_raf_referral_code)
      let showCopyTimer = setTimeout(() => {
        setShouldShowCopyBox(false)
      }, 2000);
      return () => clearTimeout(showCopyTimer);
    },
    [shouldShowCopyBox]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let phoneNumErr = country === "jp"
        ? !jp_phone_regex().all.test(phoneNumber.replace( /[^0-9]/g, '' ))
        : !checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
      if(phoneNumErr) {
        setPhoneErr(true)
      } else {
        const referInfoObj: referInfoObj = {
          phone_number: phoneNumber,
          referral_code: store.fca_raf_referral_code,
          role_type: 'user'
        }
        store.handleSendDownloadLink_Raf(referInfoObj);
      }
    },
    [phoneNumber, store.fca_raf_referral_code]
  );

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  const { t } = useTranslation();

  if (firstRender) {
    return null;
  };

  let current_fca_raf_first_name = country === "jp"
    ? `${store.fca_raf_first_name || 'お友達'}`
    : `${store.fca_raf_first_name || 'Your friend'} `

  let current_fca_raf_bonus_miles = country === "jp"
    ? `${store.fca_raf_bonus_miles}`
    : ` ${store.fca_raf_bonus_miles} `

  return (
    <React.Fragment>
      <FcaRafWrap>
        <FcaRafHeader src={miles_share_reward_page_logo} alt="Miles Tenant Logo"/>
        <div className="fcaRaf-mainBody-wrap">
          <TitleSection
            src={illustrations_gift}
            alt="FCA RAF Gift Image"
            titleText1={`${t('raf_text_01_01')}${current_fca_raf_bonus_miles}${t('raf_text_01_02')}`}
            titleText2={t('raf_text_02')}
          />
          <ActionSection
            download_src={download_icon}
            download_alt={t('raf_download_text')}
            download_text={t('raf_download_text')}
            share_icon={share_icon}
            share_alt={t('raf_share_text')}
            share_text={t('raf_share_text')}
            redeem_rewards_src={redeem_rewards_icon}
            redeem_rewards_alt={t('raf_redeem_rewards_text')}
            redeem_rewards_text={t('raf_redeem_rewards_text')}
            invite_code_tiip={`${current_fca_raf_first_name}${t('raf_text_03')}`}
            // copyBox
            copyBox_shouldShowCopyBox={shouldShowCopyBox}
            copyBox_ref={fcaCodeRef}
            copyBox_fcaCode={store.fca_raf_referral_code.toUpperCase()}
            handleShouldShowCopyBox={handleShouldShowCopyBox}
            // send_downloadLink_phoneErr
            send_downloadLink_phoneErr={phoneErr}
            inputEl={inputEl}
            phoneNumber={phoneNumber}
            handleChangePhoneNum={handleChangePhoneNum}
            handleOnKeyDown={handleOnKeyDown}
            handleSendDownloadLink={handleSendDownloadLink}
            send_downloadLink_shouldDisableSendBtn={shouldDisableSendBtn}
          />
        </div>
      </FcaRafWrap>
    </React.Fragment>
  );
});

export default RAF_v2;
