import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  width: 100vw;
  height: 1062px;
  background: lightgray;

  .main_container {
    margin: 0 auto;
    width: 100vw;
  }

  .carousel-inner {
    width: inherit;
    height: 1062px;
  }

  .carousel-item.card_01 {
    background-color: #FDFAF3;
    opacity: 1;
  }

  .carousel-item.card_02 {
    background-color: #f8fffb;
    opacity: 1;
  }

  .carousel-item.card_03 {
    background-color: #edeeff;
    opacity: 1;
  }

  .carousel-item.card_04 {
    background-color: #fefaf3;
    opacity: 1;
  }

  .carousel_arrow_on_desktop {
    display: block;
  }

  .carousel_arrow_on_mobile {
    display: none;
  }

  .carousel_arrow {
    width: 97px;
    height: 97px;
    margin-top: 598px;

    &.arrow_left {
      left: calc(50vw - 680px);
      z-index: 2;
    }

    &.arrow_right {
      right: calc(50vw - 680px);
      z-index: 2;
    }

    & > div {
      width: 97px;
      height: 97px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: auto;

    .carousel-inner {
      height: auto;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }
  
    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 14px;
      margin-top: 525px;
  
      &.arrow_left {
        left: 145px;
        left: calc(50vw - 158px);
      }
  
      &.arrow_right {
        right: 145px;
        right: calc(50vw - 158px);;
      }
  
      & > div {
        width: 10px;
        height: 14px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: auto;

    .carousel-inner {
      height: auto;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }
  
    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 16px;
      margin-top: 532px;
  
      &.arrow_left {
        left: 145px;
        left: calc(50vw - 160px);
      }
  
      &.arrow_right {
        right: 145px;
        right: calc(50vw - 160px);;
      }
  
      & > div {
        width: 10px;
        height: 16px;
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 1050px;

    .carousel_arrow {
      width: 60px;
      height: 60px;
      margin-top: 520px;
  
      &.arrow_left {
        left: 160px;
      }
  
      &.arrow_right {
        right: 160px;
      }
  
      & > div {
        width: 60px;
        height: 60px;
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .carousel_arrow {
      width: 50px;
      height: 50px;
      margin-top: 650px;
  
      &.arrow_left {
        left: 78px;
      }
  
      &.arrow_right {
        right: 88px;
      }
  
      & > div {
        width: 50px;
        height: 50px;
      }
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: 1062px;
    background: lightgray;

    .main_container {
      margin: 0 auto;
      width: 100vw;
    }
  }
`;


interface ItemProps {}
export const StyledItem = styled("div")<ItemProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    height: 1062px;

    padding: 157px 220px 82px 200px;
    width: 1440px;
    margin-left: calc(50vw - 720px);

    .title_01 {
      width: max-content;
      height: auto;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.14;
      letter-spacing: normal;
      color: #2d2f3b;
    }

    .subtitle_01 {
      width: 1021px;
      height: auto;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.82;
      letter-spacing: 0.46px;
      color: #5f5f69;
      margin-top: 10px;
    }

    .content_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .miles_ui_screen_on_1440 {
      display: flex;

      .carousel_item_img.carousel_01_a_1440_v2_img {
        margin-top: 46px;
        width: 973px;
        height: 625px;
      }

      .carousel_item_img.carousel_01_b_1440_v2_img {
        margin-top: 9px;
        width: 1064px;
        height: 696px;
      }

      .carousel_item_img.carousel_01_c_1440_v2_img {
        margin-top: 46px;
        width: 1020px;
        height: 353px;
      }

      .carousel_item_img.carousel_01_d_1440_v2_img {
        margin-top: 43px;
        width: 1007px;
        height: 642px;
      }
    }

    .miles_ui_screen_on_1280 {
      display: none;
    }

    .miles_ui_screen_on_1000 {
      display: none;
    }

    .miles_ui_screen_on_600 {
      display: none;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 962px;

      padding: 61.1px 20px 95.3px;
      width: 100vw;
      margin-left: 0;

      .title_01 {
        width: 271px;
        height: auto;
        margin: 0 auto;
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .subtitle_01 {
        width: 320px;
        height: auto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.29px;
        text-align: center;
        color: #5f5f69;
        margin-top: 9.8px;
      }

      .content_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top: 48px;

        img {
          display: inline-block;
        }

      }

      .miles_ui_screen_on_1440 {
        display: none;
      }

      .miles_ui_screen_on_1280 {
        display: none;
      }
  
      .miles_ui_screen_on_1000 {
        display: none;
      }

      .miles_ui_screen_on_600 {
        display: flex;

        .carousel_item_img.carousel_01_a_600_v2_img {
          width: 316px;
          height: 525px;
          margin-bottom: 46.7px;
        }

        .carousel_item_img.carousel_01_b_600_v2_img {
          width: 316px;
          height: 568.9px;
          margin-bottom: 3px;
        }

        .carousel_item_img.carousel_01_c_600_v2_img {
          width: 316px;
          height: 388.6px;
          margin-bottom: auto;
        }

        .carousel_item_img.carousel_01_d_600_v2_img {
          width: 316px;
          height: 571.7px;
        }
      }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 962px;

      padding: 71.6px 135px 48.4px 136px;
      width: 100vw;
      margin-left: 0;

      .title_01 {
        width: 271px;
        height: auto;
        margin: 0 auto;
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .subtitle_01 {
        width: 320px;
        height: auto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.29px;
        text-align: center;
        color: #5f5f69;
        margin-top: 20px;
      }

      .content_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top: 65px;

        img {
          display: inline-block;
        }

      }

      .miles_ui_screen_on_1440 {
        display: none;
      }

      .miles_ui_screen_on_1280 {
        display: none;
      }
  
      .miles_ui_screen_on_1000 {
        display: none;
      }

      .miles_ui_screen_on_600 {
        display: flex;

        .carousel_item_img.carousel_01_a_600_v2_img {
          width: 316px;
          height: 492.9px;
          margin-bottom: 96px;
        }

        .carousel_item_img.carousel_01_b_600_v2_img {
          width: 361px;
          height: 575px;
          margin-bottom: 13px;
        }

        .carousel_item_img.carousel_01_c_600_v2_img {
          width: 316px;
          height: 432.9px;
          margin-bottom: 156px;
        }

        .carousel_item_img.carousel_01_d_600_v2_img {
          width: 329px;
          height: 588px;
        }
      }

    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 1050px;

      padding: 80px 125px 166px 129px;
      width: 100vw;
      margin-left: 0;

      .title_01 {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.41;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .subtitle_01 {
        width: 654px;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 0.46px;
        text-align: center;
        color: #5f5f69;
        margin-top: 20px;
      }

      .content_box {
        margin-top: 45px;
      }

      .miles_ui_screen_on_1440 {
        display: none;
      }

      .miles_ui_screen_on_1280 {
        display: none;
      }

      .miles_ui_screen_on_600 {
        display: none;
      }
  
      .miles_ui_screen_on_1000 {
        display: flex;

        .carousel_item_img.carousel_01_a_1000_v2_img {
          width: 488px;
          height: 613px;
        }

        .carousel_item_img.carousel_01_b_1000_v2_img {
          width: 488px;
          height: 613px;
        }

        .carousel_item_img.carousel_01_c_1000_v2_img {
          width: 488px;
          height: 611px;
        }

        .carousel_item_img.carousel_01_d_1000_v2_img {
          width: 521px;
          height: 543px;
          margin-bottom: 70px;
        }

      }
    }

    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
      padding: 157px 130px 78px 130px;
      width: 100vw;
      margin: 0 auto;

      .title_01 {
        width: 945px;
        margin: 0 auto;
      }

      .subtitle_01 {
        width: 939px;
        margin: 0 auto;
      }

      .miles_ui_screen_on_1440 {
        display: none;
      }
  
      .miles_ui_screen_on_1280 {
        display: flex;
        align-items: center;
        justify-content: center;

        .carousel_item_img.carousel_01_a_1280_v2_img {
          margin-top: 53px;
          width: 962px;
          height: 625px;
        }
  
        .carousel_item_img.carousel_01_b_1280_v2_img {
          margin-top: 41px;
          width: 962px;
          height: 626px;
        }

        .carousel_item_img.carousel_01_c_1280_v2_img {
          margin-top: 60px;
          width: 891px;
          height: 322px;
        }
  
        .carousel_item_img.carousel_01_d_1280_v2_img {
          margin-top: 37px;
          width: 921px;
          height: 656px;
        }
      }
  
      .miles_ui_screen_on_1000 {
        display: none;
      }
  
      .miles_ui_screen_on_600 {
        display: none;
      }


    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        
    }
`;