import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {} = config;

const Component: React.FC<{}> = observer(() => {
  return (
    <>
      <Styled_body_wrap className="">
      <div className="ciq-root">
          <div className="ciq-container">
            <div className="title">
              Top Stories
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
