import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  width: 100vw;
  height: 625px;
  background: #fffaf5;

  .main_container {
    margin: 0 auto;
    width: 100vw;
  }

  .carousel-inner {
    width: inherit;
    height: 625px;
  }

  .carousel_arrow_on_desktop {
    display: block;
  }

  .carousel_arrow_on_mobile {
    display: none;
  }

  .carousel_arrow {
    width: 97px;
    height: 97px;
    margin-top: 298px;

    &.arrow_left {
      left: calc(50vw - 640px);
      z-index: 2;
    }

    &.arrow_right {
      right: calc(50vw - 640px);
      z-index: 2;
    }

    & > div {
      width: 97px;
      height: 97px;
    }
  }

  .carousel-indicators {
    margin-bottom: 50px;
    
    span {
      height: 14px;
      width: 14px;
      background-color: #e6e6e8;
      border-radius: 50%;
      display: block;
      margin-right: 15px;

      &.active {
        background-color: #10af42;
      }
    }

  }

  .carousel-item {
    background: #fffaf5;
  }

  .carousel_main_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 96px 0 52px 0;

    .carousel_body_title {
      width: max-content;
      height: auto;
      margin: 0 auto;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
    }

    .carousel_03_img.miles_ui_screen_larger_than_1150 {
      display: inline-block;
      width: 786.2px;
      height: 208.2px;
      margin-top: 82px;

      &.carousel_03_01_img {
        width: 784px;
        height: 206px;
      }

      &.carousel_03_02_img {
        width: 784.7px;
        height: 207.2px;
      }
    }

    .carousel_03_img.miles_ui_screen_1280 {
      display: none;
    }

    .carousel_03_img.miles_ui_screen_1000 {
      display: none;
    }

    .carousel_03_img.miles_ui_screen_600 {
      display: none;
    }

    .carousel_03_img.miles_ui_screen_360 {
      display: none;
    }


    
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 667px;

    .carousel-inner {
      height: auto;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }
  
    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 14px;
      margin-top: 195px;
  
      &.arrow_left {
        left: calc(50vw - 158px);
      }
  
      &.arrow_right {
        right: calc(50vw - 158px);;
      }
  
      & > div {
        width: 10px;
        height: 14px;
      }
    }

    .carousel-indicators {
      margin-bottom: -30px;
      
      span {
        height: 9px;
        width: 9px;
        background-color: #e6e6e8;
        border-radius: 50%;
        display: block;
        margin-right: 10px;
  
        &.active {
          background-color: #10af42;
        }
      }
  
    }

    .carousel_main_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      padding: 69.1px 0 45px 0;
  
      .carousel_body_title {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }
  
      .carousel_03_img.miles_ui_screen_larger_than_1150 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1280 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1000 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_600 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_360 {
        display: inline-block;
        width: 289px;
        height: 410px;
        margin-top: 24px;

        &.carousel_03_01_img {
          width: 270px;
          height: 405.3px;
        }

        &.carousel_03_02_img {
          width: 289px;
          height: 394px;
        }

      }
      
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: 667px;

    .carousel-inner {
      height: auto;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }
  
    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 16px;
      margin-top: 195px;
  
      &.arrow_left {
        left: calc(50vw - 160px);
      }
  
      &.arrow_right {
        right: calc(50vw - 160px);;
      }
  
      & > div {
        width: 10px;
        height: 16px;
      }
    }

    .carousel-indicators {
      margin-bottom: -30px;
      
      span {
        height: 9px;
        width: 9px;
        background-color: #e6e6e8;
        border-radius: 50%;
        display: block;
        margin-right: 10px;
  
        &.active {
          background-color: #10af42;
        }
      }
  
    }

    .carousel_main_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      padding: 69px 0 45px 0;
  
      .carousel_body_title {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }
  
      .carousel_03_img.miles_ui_screen_larger_than_1150 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1280 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1000 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_600 {
        display: inline-block;
        width: 289px;
        height: 410px;
        margin-top: 25px;

        &.carousel_03_01_img {
          width: 270px;
          height: 405.3px;
        }

        &.carousel_03_02_img {
          width: 289px;
          height: 394px;
        }
      }
  
      .carousel_03_img.miles_ui_screen_360 {
        display: none;
      }
      
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 625px;

    .carousel_arrow {
      width: 60px;
      height: 60px;
      margin-top:323px;
  
      &.arrow_left {
        left: 116px;
      }
  
      &.arrow_right {
        right: 116px;
      }
  
      & > div {
        width: 60px;
        height: 60px;
      }
    }

    .carousel_main_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      padding: 126px 0 52px;
  
      .carousel_body_title {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Gilroy-Bold;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }
  
      .carousel_03_img.miles_ui_screen_larger_than_1150 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1280 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1000 {
        display: inline-block;
        width: 628px;
        height: 202px;
        margin-top: 70px;

        &.carousel_03_01_img {
          width: 605px;
          height: 203px;
        }

        &.carousel_03_02_img {
          width: 622px;
          height: 203px;
        }
      }
  
      .carousel_03_img.miles_ui_screen_600 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_360 {
        display: none;
      }
      
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    height: 625px;

    .carousel_arrow {
      width: 50px;
      height: 50px;
      margin-top: 320px;
  
      &.arrow_left {
        left: 83px;
      }
  
      &.arrow_right {
        right: 83px;
      }
  
      & > div {
        width: 50px;
        height: 50px;
      }
    }

    .carousel_main_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      padding: 113.7px 0 52px;
  
      .carousel_body_title {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Gilroy-Bold;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }
  
      .carousel_03_img.miles_ui_screen_larger_than_1150 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_1280 {
        display: inline-block;
        width: 785.2px;
        height: 213.5px;
        margin-top: 70px;

        &.carousel_03_01_img {
          width: 783.7px;
          height: 206.2px;
        }

        &.carousel_03_02_img {
          width: 783.2px;
          height: 206.3px;
        }
      }
  
      .carousel_03_img.miles_ui_screen_1000 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_600 {
        display: none;
      }
  
      .carousel_03_img.miles_ui_screen_360 {
        display: none;
      }
      
    }
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: 625px;
    background: #fffaf5;

    .main_container {
      margin: 0 auto;
      width: 1440px;
      width: 100vw;
    }

  }
`;


interface ItemProps {}
export const StyledItem = styled("div")<ItemProps>`
    padding: 96px 329px 178px 328px;
    width: 1440px;
    margin-left: calc(50vw - 720px);

    .title_01,
    .title_02,
    .title_03 {
      width: max-content;
      height: auto;
      margin: 0 auto;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
    }

    img {
      display: inline-block;
    }

    .inner_box {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-top: 82px;

      .avatar_img {
        width: 200px;
        height: 200px;
        margin-right: 33px;
      }

      .testimonial_01,
      .testimonial_02,
      .testimonial_03 {
        width: 550px;
        height: auto;
        margin: 0;
        font-family: Gilroy-SemiBold;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        color: #5f5f69;

        span {
          font-weight: 600;
          color: #10af42;
        }
      }

      .signature_box {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        .name {
          width: max-content;
          height: 36px;
          margin: 0;
          font-family: Gilroy-Bold;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.25;
          letter-spacing: normal;
          color: #2d2f3b;
          margin-top: 25px;
        }

        .position_title {
          width: max-content;
          height: 36px;
          margin: 0;
          font-family: Gilroy-Bold;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.25;
          letter-spacing: normal;
          color: #2d2f3b;
        }

        .logo_img_01 {
          width: 102px;
          height: 43px;
          margin-left: 25px;
          margin-top: 56px;
        }

        .logo_img_02 {
          width: 237.5px;
          height: 19px;
          margin-left: 25px;
          margin-top: 70px;
        }

        .logo_img_03 {
          width: 153.1px;
          height: 51px;
          margin-left: 25px;
          margin-top: 50px;
        }

      }

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {

    }

    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
      padding: 114px 250px 188px 248px;
      width: 100vw;
      margin-left: calc(50vw - 640px);

      .title_01,
      .title_02,
      .title_03 {
        font-size: 36px;
        line-height: 1.56;
      }

      .inner_box {
        margin-top: 70px;
      }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        
    }
`;