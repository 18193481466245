import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import { useTranslation } from 'react-i18next';
import { useCountry } from "../../../utils/milesUtils";

const {
  cities_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  let country = useCountry();
  const { t } = useTranslation();
  return (
    <Styled_body_wrap country={country}>
      <div className="main_container">
        <div className="title">Reward Your Community</div>
        <div className="asset_wrap">
          <div className="inner_box inner_box_01">
            <div className="inner_box_content_box inner_box_01_content">
              <div className="inner_box_title">Rider Benefits</div>
              <ul className="inner_box_list">
                <li>Exclusive incentives and rewards</li>
                <li className="second_item">Boosted health and sense of community</li>
                <li>Reduction in carbon footprint</li>
              </ul>
            </div>

            <picture className="">
              <source className="" media="(max-width : 768px), " srcSet={assets.reward_community_01_600}></source>
              <source className="" media="(min-width : 769px)" srcSet={assets.reward_community_01_1440}></source>
              <img className="inner_box_asset_img inner_box_01_asset" alt={`inner_box_01_asset`}></img>
            </picture>
          </div>
        </div>

        <div className="divider_space_after_box_01"></div>
      </div>
    </Styled_body_wrap>
  );
});

export default Component;
