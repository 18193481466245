import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  padding: 0; 

  .miles_ui_about_page_work_at_miles_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background:#ffffff;
  }

  .miles_ui_about_page_work_at_miles_container {
    width: 1440px;
    height: auto;
    flex-direction: row;
  }

  .miles_ui_about_page_work_at_miles_title {
    width: fit-content;
    margin: 167px auto 41px auto;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
  }

  .miles_ui_about_page_work_at_miles_body {
    width: 678px;
    margin: auto;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
  }

  .miles_ui_about_page_work_at_miles_picture {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
  }

  .miles_ui_about_page_work_at_miles_picture_img {
    height: 396px;
  }

  .miles_ui_about_page_work_at_miles_cta_wrapper {
    margin: auto;
    width: fit-content;
    margin-bottom: 130px;
  }

  .miles_ui_about_page_work_at_miles_cta {
    width: 319px;
    height: 64px;
    padding: 20px 71px;
    border-radius: 32px;
    box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
    background-color: #10af42;
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_about_page_work_at_miles_title {
      font-size: 24px;
      letter-spacing: 0.75px;
      margin-top: 110px;
    }

    .miles_ui_about_page_work_at_miles_body {
      min-width: 360px;
      width: 100vw;
      font-size: 16px;
      margin-top: 23px;
    }

    .miles_ui_about_page_work_at_miles_picture {
      margin-top: 32px;
      margin-bottom: 44px;
    }

    .miles_ui_about_page_work_at_miles_picture_img {
      width: 346px;
      height: 114px;
    }

    .miles_ui_about_page_work_at_miles_cta {
      width: 166px;
      height: 40px;
      padding: 8px 26px 8px 27px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.63;
      letter-spacing: 0.7px;
    }

    .miles_ui_about_page_work_at_miles_cta_wrapper {
      margin-bottom: 161px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_work_at_miles_title {
      font-size: 24px;
      letter-spacing: 0.75px;
      margin-top: 110px;
    }

    .miles_ui_about_page_work_at_miles_body {
      width: 346px;
      font-size: 16px;
      margin-top: 23px;
    }

    .miles_ui_about_page_work_at_miles_picture {
      margin-top: 32px;
      margin-bottom: 44px;
    }

    .miles_ui_about_page_work_at_miles_picture_img {
      width: 346px;
      height: 114px;
    }

    .miles_ui_about_page_work_at_miles_cta {
      width: 166px;
      height: 40px;
      padding: 8px 26px 8px 27px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.63;
      letter-spacing: 0.7px;
    }

    .miles_ui_about_page_work_at_miles_cta_wrapper {
      margin-bottom: 161px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_work_at_miles_title {
      font-size: 32px;
      line-height: 2.19;
      margin-top: 150px;
    }

    .miles_ui_about_page_work_at_miles_body {
      width: 346px;
      font-size: 16px;
      margin-top: 15px;
    }

    .miles_ui_about_page_work_at_miles_picture {
      margin-top: 32px;
      margin-bottom: 55px;
    }

    .miles_ui_about_page_work_at_miles_picture_img {
      height: 248px;
    }

    .miles_ui_about_page_work_at_miles_cta_wrapper {
      height: 64px;
      padding: 20px 71px;
    }

    .miles_ui_about_page_work_at_miles_cta_wrapper {
      margin-bottom: 200px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .miles_ui_about_page_work_at_miles_title {
      font-size: 32px;
      line-height: 2.19;
      margin-top: 150px;
    }

    .miles_ui_about_page_work_at_miles_body {
      width: 426px;
      font-size: 16px;
      margin-top: 15px;
    }

    .miles_ui_about_page_work_at_miles_picture {
      margin-top: 32px;
      margin-bottom: 37px;
    }

    .miles_ui_about_page_work_at_miles_picture_img {
      height: 269px;
    }

    .miles_ui_about_page_work_at_miles_cta_wrapper {
      height: 64px;
      padding: 20px 71px;
    }

    .miles_ui_about_page_work_at_miles_cta_wrapper {
      margin-bottom: 241px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;