import React from "react";
import { StyledBtn } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {
  handleShouldShowCopyBox?: () => void;
  copyBox_shouldShowCopyBox: boolean;
}

export const FcaRafCopyBtn: React.FC<Props> = ({
  copyBox_shouldShowCopyBox,
  handleShouldShowCopyBox
}) => {
  const { t } = useTranslation();
  return (
    <StyledBtn
      style={{ display: copyBox_shouldShowCopyBox ? 'none' : 'block'
      }}
      onClick={handleShouldShowCopyBox}
    ><>
    <span className="scree_larger_than_769">{t('raf_text_copy')}</span>
    <span className="scree_less_than_769">{t('raf_text_copy_mobile')}</span>
    </></StyledBtn>
  );
};
