import { useEffect, useMemo, useRef } from "react";
import { ReactSpringCarouselItem, useTransitionCarousel as _useTransitionCarousel } from "react-spring-carousel-js";
import { SpringConfig, config as SPRING_CONFIGS } from 'react-spring';


export interface UseTransitionCarouselArgs {
    items: ReactSpringCarouselItem[];
    autoAdvanceDelayMs?: number;
    springConfig?: SpringConfig;
};


export const DEFAULT_ARGS: Partial<UseTransitionCarouselArgs> = {
    autoAdvanceDelayMs: 4000,
    springConfig: SPRING_CONFIGS.slow,
};


export function useTransitionCarousel(args: UseTransitionCarouselArgs) {
    const {
        items,
        autoAdvanceDelayMs,
        springConfig,
    } = {...DEFAULT_ARGS, ...args};
    const {
        carouselFragment,
        slideToPrevItem,
        slideToNextItem,
        slideToItem,
        useListenToCustomEvent,
        activeItem,
    } = _useTransitionCarousel({
        withThumbs: false,
        withLoop: true,
        items,
        springConfig,
    });
    
    const timerRef = useRef<null|NodeJS.Timeout>(null);

    const {startTimer, clearTimer, slideToItems} = useMemo(() => {
        function clearTimer() {
            if (timerRef.current != null) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
        return {
            startTimer: function() {
                clearTimer();
                if (typeof autoAdvanceDelayMs === "number" && autoAdvanceDelayMs > 0) {
                    timerRef.current = setTimeout(slideToNextItem, autoAdvanceDelayMs);
                }
            },
            clearTimer,
            slideToItems: items.map((_, idx) => () => {
                slideToItem(idx);
            })
        };
    }, [items, slideToItem, slideToNextItem])

    useListenToCustomEvent((data) => {
        if (data.eventName === 'onSlideStartChange') {
            clearTimer();
        } else if (data.eventName === 'onSlideChange') {
            startTimer();
        }
    });

    useEffect(function() {
        startTimer();
        return clearTimer;
    }, []);

    return {
        carouselFragment,
        activeItem,
        slideToPrevItem,
        slideToNextItem,
        slideToItem,
        slideToItems,
    };
}
