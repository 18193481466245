import styled from "styled-components";
import config from "../../../utils/config";

const {
    fontFamily_Gilroy_SemiBold,
    fontFamily_Gilroy_Regular,
    fontFamily_Gilroy_Medium,
} = config;

interface Props {
  isJP: boolean;
}
export const Styled_body_wrap = styled("div")<Props>`
  .head_title {
    width: auto;
    height: 56px;
    font-family: ${fontFamily_Gilroy_SemiBold};
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-bottom: 64px;
  }
  .info_wrap {
    margin: 0 auto;
    padding: 0; 
    max-width: 500px;
    height: auto;

    display: grid;
    grid-template-columns: 130px 387px;
    grid-template-rows: 47px 47px 47px 47px;
    grid-row-gap: 32px;
    grid-column-gap: 52px;
    align-items: center;

    .title {
      width: 130px;
      height: 47px;
      padding: 9px 0;
      border-radius: 27px;
      background-color: #e9f8ee;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.93px;
      color: #2d2f3b;
      text-align: center;
      font-family: ${fontFamily_Gilroy_SemiBold};
    }

    .content {
      height: 47px;
      font-family: ${fontFamily_Gilroy_Medium};
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2d2f3b;
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .head_title {
      height: 32px;
      font-family: ${fontFamily_Gilroy_SemiBold};
      font-size: 24px;
      line-height: 1.33;
      margin-bottom: 32px;
    }

    .info_wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 37px 23px 37px 23px 37px 23px 37px 46px;
      grid-row-gap: 16px;
      grid-column-gap: 0;
  
      .title {
        width: 110px;
        height: 37px;
        padding: 6px 0;
        border-radius: 20px;
        background-color: #e9f8ee;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.71px;
        text-align: center;
        color: #2d2f3b;
        font-family: ${fontFamily_Gilroy_SemiBold};
        margin: 0 auto;
      }
  
      .content {
        height: 23px;
        font-family: ${fontFamily_Gilroy_Medium};
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }
    }
    
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .head_title {
      height: 32px;
      font-family: ${fontFamily_Gilroy_SemiBold};
      font-size: 24px;
      line-height: 1.33;
      margin-bottom: 32px;
    }
    
    .info_wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 37px 23px 37px 23px 37px 23px 37px 46px;
      grid-row-gap: 16px;
      grid-column-gap: 0;
  
      .title {
        width: 110px;
        height: 37px;
        padding: 6px 0;
        border-radius: 20px;
        background-color: #e9f8ee;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.71px;
        text-align: center;
        color: #2d2f3b;
        font-family: ${fontFamily_Gilroy_SemiBold};
        margin: 0 auto;
      }
  
      .content {
        height: 23px;
        font-family: ${fontFamily_Gilroy_Medium};
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }
    }
    
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;