import styled from "styled-components";

interface Props {
  isJP: boolean;
}

export const Styled_body_wrap = styled("div")<Props>`
  margin: 0 auto;
  padding: 0; 

  .miles_ui_about_page_leadership1_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .miles_ui_about_page_leadership1_container {
    ${(props) => props.isJP ? `width: 500px;` : `width: 700px;`}
    ${(props) => props.isJP ? `height: auto;` : `height: 296px;`}
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
 
  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_about_page_leadership1_container {
      width: 215px;
      ${(props) => props.isJP ? `height: auto;` : `height: 510px;`}
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      ${(props) => props.isJP ? `margin-top:0;` : `margin-top:20px;`}
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_leadership1_container {
      width: 215px;
      ${(props) => props.isJP ? `height: auto;` : `height: 510px;`}
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      ${(props) => props.isJP ? `margin-top:0;` : `margin-top:20px;`}
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_leadership1_container {
      ${(props) => props.isJP ? `width: 355px;` : `width: 660px;`}
      ${(props) => props.isJP ? `height: auto;` : `height: 296px;`}
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;