import styled from "styled-components";

interface Props {}
export const StyledUpdateBtn = styled("button")<Props>`
  height: 50px;
  margin: 0 auto;
  margin-top: 10px;
  padding: 15px 20px;
  border-radius: 3px;
  background-color: #10af42;
  font-family: 'Avenir Next';

  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  outline: none;
  border: none;
  display: block;
  cursor: pointer;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    font-size: 15px;
    width: max-content;
    height: 50px;
    margin: 35px 18px 0 17px;
    padding: 15px 20px;
    border-radius: 3px;
    background-color: #10af42;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 15px;
    width: max-content;
    height: 50px;
    margin: 35px 18px 0 17px;
    padding: 15px 20px;
    border-radius: 3px;
    background-color: #10af42;
  }
`;