import styled from "styled-components";

interface RootProps {}
export const RootStyled = styled("div")<RootProps>`
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  background: rgb(232,247,237,0.17);

  .section_title {
    width: max-content;
    height: 20px;
    margin: 0;
    font-family: Gilroy-Bold;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.63px;
    color: rgb(155, 155, 165);
    left: calc(50vw - 468px);
    position: absolute;
  }

  .previousNextControls {
    position: absolute;

    button {
      background: transparent;
      visibility: hidden;
    }

    .miles_carousel_wrap_arrow_left {
      left: -600px;
      position: absolute;
      outline: 0;
      border-radius: 35px;
      z-index: 901;
      border: 0;
      background: transparent;
      width: 80px;
      height: 80px;
      opacity: 1;
      cursor: pointer;
      display: block;
      top: 400px;
      visibility: visible;
    }
  
    .miles_carousel_wrap_arrow_right {
      right: -600px;
      position: absolute;
      outline: 0;
      z-index: 901;
      border: 0;
      background: transparent;
      width: 80px;
      height: 80px;
      opacity: 1;
      cursor: pointer;
      display: block;
      top: 400px;
      visibility: visible;
    }

  }

  .dotControls {
    position: absolute;
    z-index: 1;
    margin-top: 830px;

    button {
      background: #e6e6e8;
      box-shadow: none;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      transition: opacity .25s ease-in;
      border: none;
      margin-right: 15px;

      &.active {
        background: #10af42;
      }
    }

    .tab_title {}

  }

  .miles_ui_screen_on_1000 {
    display: none;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .section_title {
      left: calc(50vw - 300px);
    }
    .previousNextControls {
      position: absolute;
  
      button {
        background: transparent;
        visibility: hidden;
      }
  
      .miles_carousel_wrap_arrow_left {
        left: -360px;
        position: absolute;
        outline: 0;
        border-radius: 35px;
        border: 0;
        background: transparent;
        width: 50px;
        height: 50px;
        opacity: 1;
        cursor: pointer;
        display: block;
        top: 400px;
        visibility: visible;
      }
    
      .miles_carousel_wrap_arrow_right {
        right: -360px;
        position: absolute;
        outline: 0;
        border: 0;
        background: transparent;
        width: 50px;
        height: 50px;
        opacity: 1;
        cursor: pointer;
        display: block;
        top: 400px;
        visibility: visible;
      }
  
    }

    .miles_ui_screen_on_1000 {
      width: 30vw;
      height: 580px;
      // display: block;
      background: -webkit-linear-gradient(left,rgba(255,0,0,0), rgba(255,255,255, 0.9), white);
      background: -o-linear-gradient(right,rgba(255,0,0,0), rgba(255,255,255, 0.9), white);
      background: -moz-linear-gradient(right,rgba(255,0,0,0), rgba(255,255,255, 0.9), white);
      background: linear-gradient(to right,rgba(255,0,0,0), rgba(255,255,255, 0.9), white);;
      position: absolute;
      margin-top: 150px;
      right: 0;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .previousNextControls {
      .miles_carousel_wrap_arrow_left {
        left: -558px;
      }
    
      .miles_carousel_wrap_arrow_right {
        right: -558px;
      }
  
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;

interface Props {}
export const MainWrapStyled = styled("div")<Props>`
  width: 1040px;
  height: auto;
  overflow: hidden;
  position: relative;
  margin-top: 150px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 600px;
    // width: 100vw;
    // margin-left: calc(50vw - 300px);
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;