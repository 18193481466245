import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import { useHelpPageRawData, ITEM_TYPE } from '../../../data';
import milesUtils, { useCountry } from "../../../utils/milesUtils";
import { Link } from "react-router-dom";
import { urlForSection } from "../../helpPage/urls";

const {
  contact_page_assets: assets,
} = config;

export interface Props {
  user_type: 'user';
  selected_section: ITEM_TYPE;
}

const Component: React.FC<Props> = observer(({user_type, selected_section}) => {
  let country = useCountry();
  const items = useHelpPageRawData()[user_type] as ITEM_TYPE[];
  const num_items = items.length;
  return (
    <>
      <Styled_body_wrap>
        <div className="section_titles">{
          items.map((item, index) => {
            let className = "section_name"
            if (index !== num_items) {
                className = `${className} section_spacer`;
            }
            if (item.slug === selected_section?.slug) {
                className = `${className} selected_section`;
            }
            return <Link to={urlForSection(item, country)}>
                <div className={className}>
                  <span className="section_name_span">{item.name}</span>
                </div>
              </Link>;
          })}
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
