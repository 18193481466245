import miles_1440 from "./miles/1440/data";
import miles_1150_x_1440 from "./miles/1150_x_1440/data";
import miles_768_x_1150 from "./miles/768_x_1150/data";
import miles_360_x_768 from "./miles/360_x_768/data";

import mjkk_1440 from "./mjkk/1440/data";
import mjkk_1150_x_1440 from "./mjkk/1150_x_1440/data";
import mjkk_768_x_1150 from "./mjkk/768_x_1150/data";
import mjkk_360_x_768 from "./mjkk/360_x_768/data";


const data = {
    miles: {
        "1440": miles_1440,
        "1150_x_1440": miles_1150_x_1440,
        "768_x_1150": miles_768_x_1150,
        "360_x_768": miles_360_x_768,
    },
    mjkk: {
        "1440": mjkk_1440,
        "1150_x_1440": mjkk_1150_x_1440,
        "768_x_1150": mjkk_768_x_1150,
        "360_x_768": mjkk_360_x_768,
    },
    mjp: {
        "1440": mjkk_1440,
        "1150_x_1440": mjkk_1150_x_1440,
        "768_x_1150": mjkk_768_x_1150,
        "360_x_768": mjkk_360_x_768,
    }
}

export default data;