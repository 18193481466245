import React from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  about_page_assets,
} = config

const MilesWeb_WhereWeAreHeaded: React.FC<{}> = observer(() => {
  return (
    <Styled_body_wrap>
      <picture className="miles_ui_about_page_whereWeAreHeaded">
        <source className="miles_ui_about_page_whereWeAreHeaded_img" media="(max-width : 360px)" srcSet={about_page_assets.where_we_headed_v3_img} sizes="100vw"></source>
        <source className="miles_ui_about_page_whereWeAreHeaded_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={about_page_assets.where_we_headed_v3_img} sizes="100vw"></source>
        <source className="miles_ui_about_page_whereWeAreHeaded_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={about_page_assets.where_we_headed_v3_img} sizes="100vw"></source>
        <source className="miles_ui_about_page_whereWeAreHeaded_img" media="(min-width : 1151px) and (max-width : 1439px)" srcSet={about_page_assets.where_we_headed_v3_img} sizes="100vw"></source>
        <source className="miles_ui_about_page_whereWeAreHeaded_img" media="(width : 1440px)" srcSet={about_page_assets.where_we_headed_v3_img} sizes="100vw"></source>
        <source  className="miles_ui_about_page_whereWeAreHeaded_img" media="(min-width : 1441px)" srcSet={about_page_assets.where_we_headed_v3_img} sizes="100vw"></source>
        <img className="miles_ui_about_page_whereWeAreHeaded_img" alt={`miles_ui_about_page_whereWeAreHeaded`}></img>
      </picture>

      <p className="about_page_text_001">Our Mission</p>
      <p className="about_page_text_002">To be the world’s most rewarding program by celebrating every action, big and small</p>
    </Styled_body_wrap>
  );
});

export default MilesWeb_WhereWeAreHeaded;
