import styled from "styled-components";
import i18next from 'i18next';
import config from "../../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

interface Props {}
export const StyledConfirmedTitle = styled("p")<Props>`
  height: 32px;
  margin: 0 auto;
  font-family: ${() => fontFamily_Gilroy_SemiBold};
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.26px;
  text-align: center;
  color: #2d2f3b;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 32px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.19px;
    text-align: center;
    color: #2d2f3b;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.19px;
    text-align: center;
    color: #2d2f3b;

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }
`;