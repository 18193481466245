import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  overflow-x: hidden;
  .ciq_root{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
  }

  .ciq_container{
    width: 1440px;
    min-height: 430px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    flex-direction: column;
    align-items: center;
    margin-top: 65px;
    position: relative;
  }

  .ciq_inner_container{
    width: 820px;
    display: flex;
    flex-direction: row;
    position: relative;
    left: 216.5px;
  }

  .help_section_title_wrapper {
    margin-bottom: 48px;
  }


  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq_inner_container{
      width: 310px;
      left: 25px;
      margin-top: 45px;
    }

    .ciq_container{
      margin-bottom: 147px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq_inner_container{
      width: 320px;
      left: auto;
      margin: 0 auto;
    }

    .ciq_container{
      margin-bottom: 140px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .ciq_inner_container{
      width: 320px;
      left: auto;
      margin: 0 auto;
    }

    .ciq_container{
      margin-bottom: 104px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .ciq_inner_container{
      width: 320px;
      left: auto;
      margin: 0 auto;
    }

    .ciq_container{
      margin-bottom: 104px;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .ciq_inner_container{
      width: 320px;
      left: auto;
      margin: 0 auto;
    }

    .ciq_container{
      margin-bottom: 104px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .ciq_container{
      margin-bottom: 129px;
    }

    .ciq_inner_container{
      left: 82.5px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .ciq_inner_container{
      left: 125.5px;
    }

    .ciq_container{
      margin-bottom: 225px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .ciq_container{
      margin-bottom: 311px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .ciq_container{
      margin-bottom: 311px;
    }
  }
`;