import ENGLISH_LNG from "./language/english";
import JAPANESE_LNG_JRE from "./language/japanese_jre";
import JAPANESE_LNG_EDSP from "./language/japanese_edsp";
import JAPANESE_LNG_MJKK from "./language/japanese_mjkk";

export const languages = { 
	english: {
		translation: ENGLISH_LNG
	},
	japanese_jre: {
		translation: JAPANESE_LNG_JRE
	},
	japanese_edsp: {
		translation: JAPANESE_LNG_EDSP
	},
	japanese_mjkk: {
		translation: JAPANESE_LNG_MJKK
	},
};