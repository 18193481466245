import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

interface FooterProps {}

export const StyledBtn = styled("div")<FooterProps>`
    width: auto;
    height: 48px;
    border-radius: 24px;
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    margin-top: -50px;
    margin-left: 517px;
    font-family: ${fontFamily_Gilroy_Medium};
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
    color: #428DFC;
    outline: none;

    .scree_larger_than_769 {
      display: block;
    }

    .scree_less_than_769 {
      display: none;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      width: 82px;
      height: 34px;
      background-color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
      border-radius: 17px;
      margin-top: -64px;
      margin-left: 250px;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;

      .scree_larger_than_769 {
        display: none;
      }
  
      .scree_less_than_769 {
        display: block;
        padding: 10px 0;
      }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
      width: 82px;
      height: 34px;
      background-color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
      border-radius: 17px;
      margin-top: -64px;
      margin-left: 250px;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;

      .scree_larger_than_769 {
        display: none;
      }
  
      .scree_less_than_769 {
        display: block;
        padding: 10px 0;
      }
    }

    /* iPhone 5 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
     
      width: 82px;
      height: 34px;
      background-color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10AF42"};
      border-radius: 17px;
      margin-top: -64px;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      margin-left: 225px;

      .scree_larger_than_769 {
        display: none;
      }
  
      .scree_less_than_769 {
        display: block;
        padding: 10px 0;
      }
    }

    /* iPhone 11 ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
      margin-left: 433px;
    }

    @media only screen and (min-width : 769px) and (max-width : 1279px) {
      height: 48px;
      margin-top: -50px;
      margin-left: 431px;
    }



    @media only screen and (min-width : 1280px) and (max-width : 1439px) {
      height: 48px;
      margin-top: -50px;
      margin-left: 431px;
    }
  

  
`;
