import React from "react";
import { observer } from "mobx-react";
import DoneIcon from '@material-ui/icons/Done';
import config from "../../utils/config";
import { store } from "../../Store";
import { StyledUnsubscribeCategoryList } from "./styles";

const {
  primary_color_01,
} = config;

interface Props {}

export const UnsubscribeCategoryList: React.FC<Props> = observer(({}) => {
  return (
    <StyledUnsubscribeCategoryList className="mi-Unsubscribe-Category-List">
      {
        store.email_unsubscribe_info && store.email_unsubscribe_info.sub_categories
        && store.email_unsubscribe_info.sub_categories.map((item, index) => (
          <div className="mi-unsubscribe-category0list-item-wrap" key={`email_unsubscribe_info_sub_categories_${index}`}>
            <DoneIcon style={{ color: `${primary_color_01}`, width: "15px", height: "15px" }} />
            <p>{item.title}</p>
          </div>
        ))
      }
    </StyledUnsubscribeCategoryList>
  );
})
