import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;

  a:link, a:active, a:visited, a:hover {
    text-decoration: none;
  }

  .inner_box_01_asset {
    width: 72px;
    height: 72.5px;
    margin: 20px 120px 18.5px 120px;
    object-fit: contain;
  }

  .card_unit_text{
    width: 320px;
    height:87px;
    font-family: Gilroy-Medium;
    font-size: 20px;
    text-align: center;
    line-height: 87px;
    letter-spacing: 0.0px;
    color: #343434;
    text-transform: capitalize;
  }

  .card_unit_text_half {
    width: 320px;
    height: 50px;
    font-family: Gilroy-Medium;
    font-size: 20px;
    text-align: center;
    line-height: 43px;
    letter-spacing: 0.0px;
    color: #343434;
    text-transform: capitalize;

    &.text_02 {
      margin-top: -15px;
    }

  }

  .card_unit{
    width: 320px;
    height: 200px;
    margin: 0 1px 0 0;
    border-radius: 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
    display: block;
    border: 0px;
    padding: 0px;
  }

  .card_unit_01{ 
    width: 321px;
    height: 113px;
    border-radius: 32px 32px 0px 0px;
    background-color: #fffcee !important;
  }

  .ciq_spacer{
    margin-right: 31px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

    .inner_box_01_asset {
      width: 60px;
      height: 76px;
      margin: 20px 120px 18.5px 120px;
      margin: 39px 110px 23px;
      object-fit: contain;
    }
  
    .card_unit_text{
      width: 280px;
        height: 87px;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #343434;
        padding-top: 25px;
    }

    .card_unit_text_half {
      width: 280px;
        height: 50px;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #343434;
        padding-top: 10px;
    }
  
    .card_unit{
      width: 280px;
      height: 200px;
      margin: 0 1px 0 0;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: block;
      border: 0px;
      padding: 0px;
    }
  
    .card_unit_01{ 
      width: 280px;
      height: 113px;
      border-radius: 32px 32px 0px 0px;
      background-color: #fffcee !important;
    }
  

    .ciq_spacer{
      margin-right: 0px;
      margin-bottom: 43px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq_spacer{
      margin-right: 0px;
      margin-bottom: 50px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .ciq_spacer{
      margin-right: 21px;
    }

    .card_unit_text{
      width: 210px;
      line-height: 25px;
      vertical-align: middle;
      display: table-cell;
    }

    .card_unit_text_half {
      width: 210px;
      line-height: normal;
      vertical-align: middle;
      margin-top: 10px;
    }

    .card_unit_text div{
      align-items: center;  
      /* width: 210px;
      height: 87px; */
    }

    .card_unit{
      width: 210px;
      height: 200px;
    }

    .card_unit_01{ 
      width: 210px;
    }

    .inner_box_01_asset {
      margin: 35px 69px 6px 69px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;