import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {} = config;


export interface Props {
  logoImage: React.ReactNode;
  headerText: React.ReactNode;
  subHeader: React.ReactNode;
  assetImage: React.ReactNode;
  date: React.ReactNode;
  readMoreCta: string;
}


const Component: React.FC<Props> = observer((props) => {  return (
    <>
      <Styled_body_wrap className="">
        <div className="">
          <div className="card_unit_top_circle_img">
            <div className="card_unit_partner_logo" >{props.logoImage}</div>
          </div>
          <div>
            <div>
              <div className="inner_box_01_asset">{props.assetImage}</div>
            </div>
            <div className="card_unit_text_section">
              <div className="card_unit_text_header">{props.headerText}</div>
              <div className="card_unit_text_subheader">{props.subHeader}</div>
              <div className="card_unit_text_footer">
                <div className="card_unit_footer_1">{props.date}</div>
                <div className="card_unit_footer_2">
                  <a href={props.readMoreCta} target="_blank">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
