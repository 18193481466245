import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import { QUESTION_TYPE, ITEM_TYPE } from '../../../data';
import { Link } from "react-router-dom";
import { urlForQuestion } from "../../helpPage/urls";
import milesUtils, { useCountry } from "../../../utils/milesUtils";

const {
  contact_page_assets: assets,
} = config;

export interface Props {
  questions: QUESTION_TYPE[];
  selected_section: ITEM_TYPE;
  selected_question?: QUESTION_TYPE;
}

const Component: React.FC<Props> = (props) => {
  let country = useCountry();
  const items = props.questions;
  const num_items = items.length;
  const {selected_section, selected_question} = props;
  return (
    <>
      <Styled_body_wrap>
        <div className="issue_titles">{
          items.map((item, index) => {
            const className = (index === num_items)
              ? "issue_name"
              : "issue_name issue_spacer";
            return <Link to={urlForQuestion(selected_section, item, country)}>
              <div className={className}>
                {item.text}
                </div>
              </Link>
          })}
        </div>
      </Styled_body_wrap>
    </>
  );
};

export default Component;
