import styled from "styled-components";
import config from "../../../utils/config";
const {
  primary_color_01
} = config

export const Styled_body_wrap = styled("div")`
  .section_titles, .section_titles > a {
    width: auto;
    height: auto;
    font-family: Gilroy-Medium;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 2.0px;
    color: #2d2f3b;
    margin-right: 67px;
    text-transform: uppercase;

    :hover {
      text-decoration: none;
    }
  }

  .selected_section {
    text-decoration: underline;
    text-decoration-color: ${() => primary_color_01};
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

 .section_name {
    line-height: 2;
  }

  .section_name:hover{
    width: auto;
    text-decoration: underline;
    text-decoration-color: ${() => primary_color_01};
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  .section_spacer{
    margin-bottom: 10px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .section_titles{
      display: none;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .section_titles{
      display: none;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;
