import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { AsYouType } from 'libphonenumber-js'
import { Styled_body_wrap } from "./styles";
import { useTranslation } from 'react-i18next';
import AccountDeleteFormTitle from "../account_delete_form_title/index";
import milesUtils, { useCountry, useLanguageOnTenant } from "../../utils/milesUtils";
import changeLanguage from '../../utils/i18n/i18next';
import {
  checkValidPhoneNumber,
  jp_phone_regex,
} from '../../utils/phoneNumValidator';
import AccountDeleteQuerySuccessImg from "../account_delete_query_success_img/index";
import AccountDeleteQuerySuccessText from "../account_delete_query_success_text/index";

let firstRender = true;

const Component: React.FC = observer(() => {
  let country = useCountry();
  let languageOnTenant = useLanguageOnTenant();
  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    switch (country) {
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }
  firstRender = false;
  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>(null);
  const [position, setPosition] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accountDeleteRequestFlag, setaccountDeleteRequestFlag] = useState(false);
  const [errs, setErrs] = useState({
    name: false,
    email: false,
    phone_number: false,
    message: false,
    submissionErr: false
  });
  let posit = 0;

  const [{formValues, onChangeName, onChangeEmailAddress, onChangePhoneNumber, onChangeMessage}] = useState(() => {
    const formValues = {
      name: '',
      email: '',
      phone_number: '',
      message: '',
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    function makeCallback(attribute_name: keyof (typeof formValues)) {
      return (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        if(attribute_name === 'phone_number'){
          let formattedPhoneNum;
          let shouldUseCurrentValue;
          let currentPhoneNum;
          switch (languageOnTenant) {
            case 'jp_edsp':
            case 'jp_mjkk':
              shouldUseCurrentValue = (event.target.value.length <= 3);
              currentPhoneNum = event.target.value.replace(/\D/g, "")
              formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : new AsYouType("JP").input(currentPhoneNum);
            break;
            default:
              shouldUseCurrentValue = (event.target.value.length <= 3);
              currentPhoneNum = event.target.value.replace(/\D/g, "")
              if (currentPhoneNum.length > 10) {
                currentPhoneNum = currentPhoneNum.slice(0, 10); 
              }
              formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);
          }
              
          const selectionStart = inputEl!.current!.selectionStart as number;

          let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

          setPosition(currentPosition as number)
          milesUtils.debug_log("selectionStart >>> ", selectionStart);

          setPhoneNumber(formattedPhoneNum);
          
        }
        formValues[attribute_name] = event.target.value
      }
    }

    return {
      formValues,
      onChangeName: makeCallback('name'),
      onChangeEmailAddress: makeCallback('email'),
      onChangePhoneNumber: makeCallback('phone_number'),
      onChangeMessage: makeCallback('message'),
    };
  });

  const sendAccountDeleteRequest = useCallback(() => {
    let phoneNumErr = country === "jp"
        ? !jp_phone_regex().all.test(formValues.phone_number.replace( /[^0-9]/g, '' ))
        : !checkValidPhoneNumber(formValues.phone_number.replace( /[^0-9]/g, '' ))
    const newErrs = {
      ...errs,
      name: !formValues.name.trim(),
      email: !milesUtils.isValidEmailAddress(formValues.email),
      phone_number: formValues.phone_number.trim() === '' ? false : phoneNumErr,
    }

    setErrs(newErrs);
    
    if (!newErrs.name && !newErrs.email) {
      const finalFormValues = {...formValues, enquiry_type: null};
      
      fetch('/_api/submit_account_delete_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalFormValues),
      })
      .then(response => {
        if (response.ok) {
          setaccountDeleteRequestFlag(true)
          return 
        }

        if (response.status != 200) 
        {
          setErrs({
            ...errs,
            submissionErr: true
          })
        }
        return
      })
      .catch((error) => {
        {
          setErrs({
          ...errs,
            submissionErr: true
          })
        }
        return
      });
    }
  },
  [errs]);

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  return (
    <>
      <Styled_body_wrap>
        <div className="ciq_root">
          <div className="ciq_container">
            <AccountDeleteFormTitle />
            {/* Contact Query Form Container */}
            <div className={`form_container ${accountDeleteRequestFlag ? "hide_contact_form" : "show_contact_form"}`}>
               <label className="input_inner_section input_box_01">
                  <span className="label_text">{t('account_delete_form_text_001')}</span>
                  <input
                    type="text"
                    name="name"
                    className={`contact_form_inputs ${errs.name ? "contact_form_inputs_err_state" : "contact_form_inputs_no_error"}`}
                    required
                    placeholder={t('account_delete_form_text_001_placeHolder')}
                    onChange={onChangeName}
                  />
                  <span className={errs.name ? "show_err_info" : "hide_err_info"}>{t('account_delete_form_text_001_err')}</span>
                </label>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">{t('account_delete_form_text_002')}</span>
                  <input
                    type="text"
                    name="emailAddress"
                    className={`contact_form_inputs ${errs.email ? "contact_form_inputs_err_state" : "contact_form_inputs_no_error"}`}
                    required
                    onChange={onChangeEmailAddress}
                    placeholder={t('account_delete_form_text_002_placeHolder')}
                  />
                  <span className={errs.email ? "show_err_info" : "hide_err_info"}>{t('account_delete_form_text_002_err')}</span>
                </label>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">{t('account_delete_form_text_003')}</span>
                  <input
                    ref={inputEl}
                    type="text"
                    name="phoneNumber"
                    className={`contact_form_inputs ${errs.phone_number ? "contact_form_inputs_err_state" : "contact_form_inputs_no_error"}`}
                    required
                    placeholder={t('account_delete_form_text_003_placeHolder')}
                    value={phoneNumber}
                    onChange={onChangePhoneNumber}
                  />
                  <span className={errs.phone_number ? "show_err_info" : "hide_err_info"}>{t('account_delete_form_text_003_err')}</span>
                </label>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">{t('account_delete_form_text_004')}</span>
                  <textarea
                    className={`contact_form_textarea ${errs.message ? "contact_form_textarea_err_state" : "contact_form_textarea_no_error"}`}
                    placeholder={t('account_delete_form_text_004_placeHolder')}
                    onChange={onChangeMessage}
                  ></textarea>
                  <span  className={errs.message ? "show_err_info" : "hide_err_info"}>{t('account_delete_form_text_004_placeHolder')}</span>
                </label>

                <div className="tip_text">{t('account_delete_form_text_005')}</div>

                <button className={`btn_submit miles_ui_btn_primary ${process.env.REACT_APP_MILES_TENANT}`} onClick={sendAccountDeleteRequest}>{t('account_delete_form_text_006')}</button>
                <div className={errs.submissionErr ? "show_err_info" : "hide_err_info"}>
                {t('account_delete_form_text_007')}
                </div>
                {/* //  */}
            </div>
            
            {/* Success Response Container */}
            <div className={`contact_query_success ${accountDeleteRequestFlag ? "show_success_resp" : "hide_contact_form"}`}>
              <AccountDeleteQuerySuccessImg />
              <AccountDeleteQuerySuccessText />
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
