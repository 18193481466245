import React from "react";
import { StyledFooter } from "./styles";

interface Props {
  miles_logo_white_img: string;
  pre_footer_img: string;
  logo_fb_img: string;
  logo_ins_img: string;
  logo_linkedin_img: string;
  logo_x_img: string;
}

export const Footer: React.FC<Props> = ({
  miles_logo_white_img,
  pre_footer_img,
  logo_fb_img,
  logo_ins_img,
  logo_linkedin_img,
  logo_x_img,
}) => {
  return (
    <StyledFooter>
      <div className="miles_ui_desktop_only">
        <div className="pre_footer_img"></div>
        
        <div className="footer_content_wrap">
          <div className="footer_content_body">
            <div className="footer_content_left">
              <a className="inline_block miles_logo_white_img" href="/">
                <img src={miles_logo_white_img} alt="miles_logo_white_img"></img>
              </a>

              <div className="miles_logo_social_wrap">
                <a className="inline_block miles_logo_social" href="https://www.facebook.com/getmilesapp" target="_blank">
                  <img src={logo_fb_img} alt="logo_fb_img"></img>
                </a>
                <a className="inline_block miles_logo_social" href="https://www.instagram.com/getmilesapp" target="_blank">
                  <img src={logo_ins_img} alt="logo_ins_img"></img>
                </a>
                <a className="inline_block miles_logo_social" href="https://www.linkedin.com/company/getmiles/" target="_blank">
                  <img src={logo_linkedin_img} alt="logo_linkedin_img"></img>
                </a>
                <a className="inline_block miles_logo_social" href="https://twitter.com/getmilesapp" target="_blank">
                  <img src={logo_x_img} alt="logo_x_img"></img>
                </a>
              </div>

              <div className="miles_copyright">
                  © {new Date().getFullYear()} ConnectIQ Labs, Inc. <br />
                  All rights reserved
              </div>
            </div>

            <div className="footer_content_right">
              <div className="footer_content_right_innerItem innerItem_01">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Company</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/about">About Us</a> 
                <a className="inline_block footer_content_right_innerItem_sub" href="/press">Press</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jobs">Jobs</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/contact">Contact</a>
              </div>
              <div className="footer_content_right_innerItem innerItem_02">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Legal</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/privacy">Privacy Policy</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/tos">Terms of Service</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/help">Help</a>
              </div>
            </div>
            </div>
        </div>
      </div>

      <div className="miles_ui_mobilePhone_only">
        <div className="mobile_pre_footer_img"></div>

        <div className="mobile_footer_content_wrap">
          <div className="mobile_footer_content_body">
            <div className="mobile_footer_text_wrap">
              <div className="footer_content_right_innerItem innerItem_01">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Company</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/about">About Us</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/press">Press</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/jobs">Jobs</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/contact">Contact</a>
              </div>

              <div className="footer_content_right_innerItem innerItem_02">
                <a className="inline_block footer_content_right_innerItem_title disabled_hover">Legal</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/privacy">Privacy Policy</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/tos">Terms of Service</a>
                <a className="inline_block footer_content_right_innerItem_sub" href="/help">Help</a>
              </div>
            </div>

            <div className="mobile_miles_logo_social_wrap">
              <a className="inline_block miles_logo_social" href="https://www.facebook.com/getmilesapp" target="_blank">
                <img src={logo_fb_img} alt="logo_fb_img"></img>
              </a>
              <a className="inline_block miles_logo_social" href="https://www.instagram.com/getmilesapp" target="_blank">
                <img src={logo_ins_img} alt="logo_ins_img"></img>
              </a>
              <a className="inline_block miles_logo_social" href="https://www.linkedin.com/company/getmiles/" target="_blank">
                <img src={logo_linkedin_img} alt="logo_linkedin_img"></img>
              </a>
              <a className="inline_block miles_logo_social last" href="https://twitter.com/getmilesapp" target="_blank">
                <img className="last" src={logo_x_img} alt="logo_x_img"></img>
              </a>
            </div>

            <a className="inline_block miles_logo_white_img" href="/">
              <img src={miles_logo_white_img} alt="miles_logo_white_img"></img>
            </a>

            <div className="miles_copyright_01">
                © {new Date().getFullYear()} ConnectIQ Labs, Inc.
            </div>
            <div className="miles_copyright_02">
                All rights reserved
            </div>
          </div>
        </div>

      </div>
    </StyledFooter>
  );
};
