import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import Lottie, { LottieRefCurrentProps, InteractivityProps } from "lottie-react";
import {
  useHistory,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils, { useCountry } from "../../utils/milesUtils";
import changeLanguage from '../../utils/i18n/i18next';
import { MILES_ANIMATIONS } from "../../utils/milesConstant";
import Sample_component from "./sample_component/index";
import WhereItAllHappens from "./where_it_all_happens/index";
import TopBanner from "./top_banner/index";
import BreadCrumb from "./bread_crumb/index";
import Support from "./support/index";
import Address from "./address/index";
import ContactForm from "./contact_form/index";
import {Pre_footer_hero} from "../../components/v2/miles_ui_box_pre_footer_hero/index";

const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

type FormSelectType = 'user' | 'partner' | null;

const MilesWeb_ContactPage: React.FC<{}> = observer(() => {
  let app_wrap_Ele = document.getElementsByClassName("app") as any;
  let country = useCountry();
  const history = useHistory();
  let location = useLocation();
  let { formType } = useParams<{formType?: string}>();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_ContactPage -- firstRender");
    switch (country) {
      case 'jp':
        changeLanguage("japanese_mjkk")
        break;
      default:
        changeLanguage("english")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_ContactPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {
    document.body.classList.add('overflow_x_hidden_page')
  }, []);

  const [formSelection, setFormSelection] = useState(() => (
    null as FormSelectType
    // 'user' as FormSelectType
  ));

  let jp_path = country === "jp" ? '/jp' : ''

  const onClickMemberSupport = useCallback(() => {
    console.log('onClickMemberSupport');
    history.push(`${jp_path}/contact/member-support`)
    setFormSelection('user');

  }, []);

  const onClickPartnerSupport = useCallback(() => {
    console.log('onClickPartnerSupport');
    setFormSelection('partner');
    history.push(`${jp_path}/contact/partner-support`)
  }, []);

  useEffect(() => {
    if (!formSelection) {
      history.push(`${jp_path}/contact`)
    }
    
  }, [formSelection]);  

  useEffect(() => {
    if (!formType) {
      setFormSelection(null);
    }
  }, [formType]);  

  

  return (<>
      <TopBanner />
      <BreadCrumb user_type={formSelection} />
      {
        (formSelection !== null)
          ? <ContactForm user_type={formSelection}/>
          : <Support 
            onClickMemberSupport={onClickMemberSupport}
            onClickPartnerSupport={onClickPartnerSupport}/>  
      }
      {
        country === 'jp' ? null : (
          <>
            {/* <WhereItAllHappens />
            <br></br>
            <Address /> */}
            <Pre_footer_hero
              apple_store_icon_img={apple_store_icon_img}
              google_play_icon_img={google_play_icon_img}
              star_icon_img={star_icon_img}
              icon_star_half_img={icon_star_half_img}
              apple_store_src={apple_store_src}
              google_play_store_src={google_play_store_src}
              className="miles_ui_pre_footer_commonSize"
            />
          </>
        )
      }
  </>);
});

export default MilesWeb_ContactPage;
