import React from "react";
import { StyledFcaRafDownloadTextBox } from "./styles";

interface Props {
  fcaRafDownloadText: string;
}

export const FcaRafDownloadTextBox: React.FC<Props> = ({
  fcaRafDownloadText
}) => {
  return (
    <StyledFcaRafDownloadTextBox>{fcaRafDownloadText}</StyledFcaRafDownloadTextBox>
  );
};
