import React from "react";
import { StyledCard } from "./styles";

interface Props {
  logoImgSrc: string;
  logoImgStyle: {};
  webUrl: string;
  testimonialStr: string;
  testimonialStyle: {};
  className?: string;
  version_type?: string;
}

export const Card_with_testimonial: React.FC<Props> = ({
  logoImgSrc,
  logoImgStyle,
  webUrl,
  testimonialStr,
  testimonialStyle,
  className,
  version_type,
}) => {
  return (
    <StyledCard className={className} target="_blank" href={webUrl}>
      <div className="card_top">
        <img
          className={`company_logo`}
          src={logoImgSrc}
          style={logoImgStyle}
        />
      </div>

      <div className={`divider_line`}></div>

      <p
        className={`testimonial ${version_type || ""}`}
        style={testimonialStyle}
      >
        {testimonialStr}
      </p>
    </StyledCard>
  );
};
