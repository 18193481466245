import styled from "styled-components";

export const BodyWrap = styled("div")`
  width: 100vw;
  overflow: visible;
  margin: 0;
  padding: 0;
  background-color: white;
  min-width: 360px;
  height: auto;
  min-height: 100vh;
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  }
  
  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;