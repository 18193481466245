import styled from "styled-components";

interface DownloadNumberInputProps {
  send_downloadLink_phoneErr: boolean;
  isJP: boolean;
}

export const StyledInput = styled("input")<DownloadNumberInputProps>`
    padding: 0;
    margin: 0;
    width: 686px;
    height: 52px;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: #ffffff;
    border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
    outline-style: none;
    box-sizing: border-box;
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.67px;
    color: #2d2f3b;
    padding-left: 24px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 191px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: ${(props) => props.isJP ? '0.67px' : "0.67px"};
      color: #bfbfbf;
    }
  
    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 191px;
      height: 52px
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: ${(props) => props.isJP ? '0.67px' : "0.67px"};
      color: #bfbfbf;
    }
  
    // --- ie ----
    &::-ms-input-placeholder {
      width: 191px;
      height: 52px
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: ${(props) => props.isJP ? '0.67px' : "0.67px"};
      color: #bfbfbf;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      padding: 0;
      margin: 0;
      width: 231px;
      height: 40px;
      border-radius: 25px;
      box-shadow: 0 2px 4px 0 #b1b1b180;
      background-color: #ffffff;
      border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
      outline-style: none;
      box-sizing: border-box;
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: ${(props) => props.isJP ? '0.4px' : "0.4px"};
      color: #2d2f3b;
      padding-left: 18px;

      :active {
        box-shadow: 0 2px 4px 0 #b1b1b180;
        border: solid 1px #10af42;
      }

      :focus {
        box-shadow: 0 2px 4px 0 #b1b1b180;
        border: solid 1px #10af42;
      }

      &::-webkit-input-placeholder {
        width: 132px;
        height: 27px
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 40px;
        letter-spacing: ${(props) => props.isJP ? '0.4px' : "0.4px"};
        color: #bfbfbf;
      }

      // --- firefox 19+ ---
      &::-moz-placeholder {
        width: 132px;
        height: 27px
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 40px;
        letter-spacing: ${(props) => props.isJP ? '0.4px' : "0.4px"};
        color: #bfbfbf;
    }

      // --- ie ----
      &::-ms-input-placeholder {
        width: 132px;
        height: 27px
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 40px;
        letter-spacing: ${(props) => props.isJP ? '0.4px' : "0.4px"};
        color: #bfbfbf;
      }

      &::-moz-placeholder {
        width: 132px;
        height: 27px
        font-family: Gilroy-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 40px;
        letter-spacing: ${(props) => props.isJP ? '0.4px' : "0.4px"};
        color: #bfbfbf;
      }
    }

    @media only screen and (min-width : 769px) and (max-width : 1150px) {
      width: 591px;
    }
  
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1279px) {
      width: 591px;
    }
  
    @media only screen and (min-width : 1280px) and (max-width : 1439px) {
      width: 600px;
    }
`;
