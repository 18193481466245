import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import config from "../../../utils/config";
import { useCountry } from "../../../utils/milesUtils";
import { useTranslation } from 'react-i18next';
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
} = config;

type UserType = 'user' | 'partner' | null;

export interface Props {
  user_type: UserType
}

const Component: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  let country = useCountry();
  let jp_path = country === "jp" ? '/jp' : ''

  const getSecondPathName = () => {
    switch(props.user_type) {
      case 'user':
          return t('help_text_002');
      case 'partner':
          return t('help_text_003');
      default:
          return null;
  }
  }
  return (
    <>
    {
      props.user_type && (
        <Styled_body_wrap>
          <div className="main_body">
            <Link className="path_first inline_block" to={`${jp_path}/contact`}>{country === "jp" ? "お問い合わせ" : "Contact"}</Link>
            <p className="path_icon">&gt;</p>
            <p className="path_second">{getSecondPathName()}</p>
          </div>
      </Styled_body_wrap>
      )
    }
    </>
  );
});

export default Component;
