import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import Carousel from "react-multi-carousel";
import config from "../../../utils/config";
import { Styled_carousel } from "./carouselStyles";

const {
    about_page_assets,
    carousel_arrow_left_img,
    carousel_arrow_right_img,
  } = config

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: Infinity, min: 1440 },
        items: 3,
        slidesToSlide: 1,
    },
    desktop: {
        breakpoint: { max: 1440, min: 1150 },
        items: 3,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1150, min: 768 },
        items: 2,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
        slidesToSlide: 1,
    }
};

const images = [
    about_page_assets.carousel_03_1440_01_img,
    about_page_assets.carousel_03_1440_02_img,
    about_page_assets.carousel_03_1440_03_img,
];

const carouselItemStyle = [
    {
        screen_1440: {
            width: "auto",
            height: "585px",
        },
        screen_1280: {
            width: "auto",
            height: "585px",
        },
        screen_1000: {
            width: "auto",
            height: "457px",
        },
        screen_600: {
            width: "auto",
            height: "400px",
        },
    },
    {
        screen_1440: {
            width: "auto",
            height: "585px",
        },
        screen_1280: {
            width: "auto",
            height: "585px",
        },
        screen_1000: {
            width: "auto",
            height: "457px",
        },
        screen_600: {
            width: "auto",
            height: "400px",
        },
    },
    {
        screen_1440: {
            width: "auto",
            height: "585px",
        },
        screen_1280: {
            width: "auto",
            height: "585px",
        },
        screen_1000: {
            width: "auto",
            height: "457px",
        },
        screen_600: {
            width: "auto",
            height: "400px",
        },
    },
]

const Component = () => {
  return (
    <Styled_carousel>
        <Carousel
            className="miles_ui_about_carousel_03_wrap"
            autoPlay={true}
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            transitionDuration={600}
            dotListClass="custom-dot-list-style"
            itemClass="carousel_03_unit image-item"
            customLeftArrow={
                <div>
                  <div className="miles_carousel_wrap_arrow_left miles_ui_large_than_768" />
                  <div className="miles_carousel_wrap_arrow_left miles_ui_less_than_768" />
                </div>
              }
              customRightArrow={
                <div>
                  <div className="miles_carousel_wrap_arrow_right miles_ui_large_than_768" />
                  <div className="miles_carousel_wrap_arrow_right miles_ui_less_than_768" />
                </div>
              }
              slidesToSlide={1}
        >
            {images.map((image, index) => {
                return (
                    <>
                        <img
                            className={`about_page_carousel_03_${index} miles_ui_screen_larger_than_1440`}
                            src={image}
                            style={carouselItemStyle[index].screen_1440}
                            alt={`about_page_carousel_03_${index}`}
                        ></img>

                        <img
                            className={`about_page_carousel_03_${index} miles_ui_screen_768_x_1150`}
                            src={image}
                            style={carouselItemStyle[index].screen_1000}
                            alt={`about_page_carousel_03_${index}`}
                        ></img>

                        <img
                            className={`about_page_carousel_03_${index} miles_ui_screen_360_x_768`}
                            src={image}
                            style={carouselItemStyle[index].screen_600}
                            alt={`about_page_carousel_03_${index}`}
                        ></img>

                        <img
                            className={`about_page_carousel_03_${index} miles_ui_screen_less_than_360`}
                            src={image}
                            style={carouselItemStyle[index].screen_600}
                            alt={`about_page_carousel_03_${index}`}
                        ></img>
                    </>
                );
            })}
        </Carousel>
    </Styled_carousel>
  );
};

export default Component;
