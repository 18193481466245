import React from "react";
import { observer } from "mobx-react";
import { store } from "../../Store";
import { StyledImageCard } from "./styles";

interface Props {}

export const ImageCard: React.FC<Props> = observer(({}) => {
  return (
    <StyledImageCard
      src={store.reward_sharing_image}
    >
      <img className="card-image"></img>
      <img className="card-logo" src={store.reward_sharing_brand_image} alt="reward_sharing_brand_name_logo"></img>
      <p className="card-description">{store.reward_sharing_title}</p>
    </StyledImageCard>
  );
})
