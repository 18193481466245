import { useCountry } from '../utils/milesUtils';
import { RAW_DATA as MILES_RAW_DATA } from './help-page/miles';
import { RAW_DATA as MJKK_RAW_DATA } from './help-page/mjkk';
import { RAW_DATA as EDSP_RAW_DATA } from './help-page/edsp';

export type ITEM_TYPE = typeof MILES_RAW_DATA.user[0];
export type QUESTION_TYPE = typeof MILES_RAW_DATA.user[0]['question_array'][0];


export function useHelpPageRawData() {
    let country = useCountry();

    switch (country) {
        case 'us':
            if (process.env.REACT_APP_MILES_TENANT === "edsp") {
                return EDSP_RAW_DATA;
            }
            return MILES_RAW_DATA;
        case 'jp':
            return MJKK_RAW_DATA;
        default:
            return MILES_RAW_DATA;
    }
}
