import styled from "styled-components";

export const Styled_body_wrap = styled("div")`

  .inner_box_01_asset {
    width: 80px;
    height: 72.5px;
    margin: 20px 120px 18.5px 120px;
    object-fit: contain;
  }

  .card_unit_text{
    width: 320px;
    height:87px;
    font-family: Gilroy-Medium;
    font-size: 20px;
    text-align: center;
    line-height: 87px;
    letter-spacing: 0.0px;
    color: #343434;
  }

  .card_unit{
    width: 320px;
    height: 200px;
    margin: 0 1px 0 0;
    border-radius: 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
    display: block;
    border: 0px;
    padding: 0px;
  }

  .card_unit_01{ 
    width: 321px;
    height: 113px;
    border-radius: 32px 32px 0px 0px;
    background-color: #fffcee !important;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .card_unit{
      width: 320px;
      height: 200px;
      margin: 0 1px 0 0;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-bottom: 43px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .card_unit{
      width: 320px;
      height: 200px;
      margin: 0 1px 0 0;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-right: 39px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .card_unit{
      width: 320px;
      height: 200px;
      margin: 0 1px 0 0;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-right: 39px; 
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .card_unit{
      width: 320px;
      height: 200px;
      margin: 0 1px 0 0;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-right: 39px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .card_unit{
      width: 320px;
      height: 200px;
      margin: 0 1px 0 0;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      margin-right: 40px;
    }
  }
`;