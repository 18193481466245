import styled from "styled-components";

interface StyledImageCardProps {
    src: string;
}
export const StyledImageCard = styled("div")<StyledImageCardProps>`
    width: 646px;
    height: 610px;
    .card-image {
        width: 100%;
        border-radius: 12px;
        background: ${(props) => `linear-gradient(to bottom, rgba(45, 47, 59, 0) 63%, #2d2f3b),
        url(${props.src}) center no-repeat`};
        background-size: cover;
        padding-top: 300px;
        padding-bottom: 300px;
    }

    .card-logo {
        width: 134px;
        height: 40px;
        z-index: 111;
        display: block;
        position: relative;
        margin: 0 auto;
        margin-top: -120px;
    }

    .card-description {
        height: 34px;
        margin: 10px 0 0;
        font-family: 'Avenir Next';
        font-size: 25px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.04px;
        text-align: center;
        color: #ffffff;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        width: 327px;
        height: 144px;
        margin: 0 auto;
        .card-image {
            display: block;
            width: inherit;
            height: inherit;
            object-fit: cover;
            padding-top: 144px;
            padding-bottom: 0;
        }

        .card-logo {
            width: 84px;
            height: 25px;
            margin: -65px auto 0px;
        }

        .card-description {
            margin-top: 6px;
            height: 20px;
            width: auto;
            font-family: 'Avenir Next';
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.63px;
            text-align: center;
            color: #ffffff;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        width: 327px;
        height: 144px;
        margin: 0 auto;
        .card-image {
            display: block;
            height: inherit;
            object-fit: cover;
            padding-top: 144px;
            padding-bottom: 0;
        }

        .card-logo {
            width: 84px;
            height: 25px;
            margin: -65px auto 0px;
        }

        .card-description {
            margin-top: 6px;
            height: 20px;
            width: auto;
            font-family: 'Avenir Next';
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.63px;
            text-align: center;
            color: #ffffff;
        }
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        width: 633px;
        height: 279px;
        margin: 0 auto;
        .card-image {
            object-fit: cover;
            display: block;
            padding-top: 279px;
            padding-bottom: 0;
        }

        .card-logo {
            margin-top: -113px;
        }

        .card-description {
            margin-top: 10px;
            height: 34px;
        }
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1279px) {
        width: 633px;
        height: 279px;
        margin: 0 auto;
        .card-image {
            object-fit: cover;
            display: block;
            padding-top: 279px;
            padding-bottom: 0;
        }

        .card-logo {
            margin-top: -113px;
        }

        .card-description {
            margin-top: 10px;
            height: 34px;
        }
    }
    
    @media only screen and (min-width : 1280px) and (max-width : 1439px) {
        width: 540px;
        height: 610px;
        .card-image {
            object-fit: cover;
        }
    }

    /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    width: 540px;
    height: 610px;
    .card-image {
        object-fit: cover;
    }
  }
`;