import React from "react";
import { StyledFcaRafGetTheAppBox } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {
  href: string;
}

export const FcaRafGetTheAppBox: React.FC<Props> = ({
  href
}) => {
  const { t } = useTranslation();
  return (
    <StyledFcaRafGetTheAppBox
      href={href}
    >{t('raf_text_06')}</StyledFcaRafGetTheAppBox>
  );
};
