import styled from "styled-components";

interface Props {}
export const Styled_We_are_highlighted = styled("div")<Props>`
  margin: 0 auto;
  padding: 0;
  min-height: 966px;
  height: 966px;
  box-sizing: border-box;
  padding-top: 125px;

  .title {
    height: 64px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .miles_carousel_wrap {
    width: 1130px;
    height: 412px;
    box-sizing: border-box;
    padding: 0;
    margin: 0 auto;
    position: unset;
    margin-top: 115px;

    .use-spring-carousel-item {
      padding-top: 7px;
    }
  }
  
  .react-multi-carousel-dot button {
    background: #e6e6e8;
    box-shadow: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    transition: opacity .25s ease-in;
    border: none;
  }

  .react-multi-carousel-dot--active.react-multi-carousel-dot button {
     background: #10af42;
  }

  .miles_carousel_wrap_arrow_left {
    left: 51px;
    position: absolute;
    outline: 0;
    border-radius: 35px;
    z-index: 900;
    border: 0;
    background: transparent;
    width: 97px;
    height: 97px;
    opacity: 1;
    cursor: pointer;
    display: block;
  }

  .miles_carousel_wrap_arrow_right {
    right: 51px;
    position: absolute;
    outline: 0;
    z-index: 900;
    border: 0;
    background: transparent;
    width: 97px;
    height: 97px;
    opacity: 1;
    cursor: pointer;
    display: block;
  }
  
  .cta_btn {
    display: block;
    width: 319px;
    height: 64px;
    padding: 17px 71px 14px;
    border-radius: 32px;
    background-color: #10af42;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 79px;
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    position: relative;
  }

  .miles_ui_large_than_768 {
    display: block;
    margin: 0;
    padding: 0;
    top: 128px;
  }

  .miles_ui_less_than_768 {
    display: none;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-height: 635px;
    height: 635px;
    padding-top: 81px;
    min-width: 360px;

    .miles_ui_large_than_768 {
      display: none;
    }
  
    .miles_ui_less_than_768 {
      display: block;
    }

    .title {
      height: 46px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
    }

    a {
      text-decoration: none;
    }

    .miles_carousel_wrap {
      width: 260px;
      height: 360px;
      height: 400px;
      margin-top: 45px;

      .react-multi-carousel-track {
        margin-top: 40px;
        margin-bottom: 131px;
      }
    }
    

    .react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 140px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 140px;
    }
    
    .cta_btn {
      width: 84px;
      height: 40px;
      padding: 0;
      border-radius: 25px;
      background-color: #10af42;
      box-sizing: border-box;
      margin: 0 auto;
      font-family: Gilroy-SemiBold;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
      margin-top: -90px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    min-height: 635px;
    height: 635px;
    padding-top: 81px;

    .miles_ui_large_than_768 {
      display: none;
    }
  
    .miles_ui_less_than_768 {
      display: block;
    }

    .title {
      height: 46px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
    }

    a {
      text-decoration: none;
    }

    .miles_carousel_wrap {
      width: 260px;
      height: 400px;
      margin-top: 45px;

      .react-multi-carousel-track {
        margin-top: 40px;
        margin-bottom: 131px;
      }
    }
    

    .react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 140px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 140px;
    }
    
    .cta_btn {
      width: 84px;
      height: 40px;
      padding: 0;
      border-radius: 25px;
      background-color: #10af42;
      box-sizing: border-box;
      margin: 0 auto;
      font-family: Gilroy-SemiBold;
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
      margin-top: -90px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {

  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 730px;
    height: 730px;
    padding-top: 120px;

    .title {
      height: 56px;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
    }

    a {
      text-decoration: none;
    }

    .miles_carousel_wrap {
      width: 532px;
      height: 322px;
      margin-top: 54px;

      .react-multi-carousel-track {
        margin-top: 54px;
        margin-bottom: 60px;
      }
    }
    

    .react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 338px);
      width: 60px;
      height: 60px;
      top: 88px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 338px);
      width: 60px;
      height: 60px;
      top: 88px;
    }
    
    .cta_btn {
      width: 164px;
      height: 52px;
      padding: 14px 21px;
      border-radius: 25px;
      background-color: #10af42;
      box-sizing: border-box;
      margin: 0 auto;
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;  
      line-height: 1.5;
      letter-spacing: 0.7px;
      text-align: center;
      color: #ffffff;
      margin-top: 46px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    min-height: 966px;
    height: 966px;
    padding-top: 181px;

    .title {}

    a {
      text-decoration: none;
    }

    .miles_carousel_wrap {
      width: 1000px;
      height: 412px;
      margin-top: 52px;
    }
    
    .react-multi-carousel-dot button {}

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 560px);
      width: 51px;
      height: 51px;
      top: 150px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 560px);
      width: 51px;
      height: 51px;
      top: 150px;
    }
    
    .cta_btn {}
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    width: 1440px;
  }
`;