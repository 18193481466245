import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils from "../../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../../utils/milesConstant";
import {Pre_footer_hero} from "../../../components/v2/miles_ui_box_pre_footer_hero/index";
import { Styled_body_wrap } from "./styles";

const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
  about_page_assets,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

const MilesWeb_AboutPage: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AboutPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AboutPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap>
      <div className="miles_ui_about_page_our_team_root">
        <div className="miles_ui_about_page_our_team_container">
          <div className="miles_ui_about_page_our_team_title"><span>Our Team</span></div>
          <picture className="miles_ui_about_page_our_team_picture">
            <source className="miles_ui_about_page_our_team_picture_img" media="(max-width : 360px)" srcSet={about_page_assets.our_team_picture_less_360}></source>
            <source className="miles_ui_about_page_our_team_picture_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={about_page_assets.our_team_picture_600_ipad_mini}></source>
            <source className="miles_ui_about_page_our_team_picture_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={about_page_assets.our_team_picture_768_to_1150}></source>
            <source className="miles_ui_about_page_our_team_picture_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={about_page_assets.our_team_picture}></source>
            <source  className="miles_ui_about_page_our_team_picture_img" media="(min-width : 1441px)" srcSet={about_page_assets.our_team_picture}></source>
            <img className="miles_ui_about_page_our_team_picture_img" alt={`our_team_picture`}></img>
          </picture>
          <div className="miles_ui_about_page_our_team_body">
            <span>
            At Miles we’re like family! The culture and atmosphere is a commitment to excellence and to each other.
            </span>
          </div>
        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default MilesWeb_AboutPage;
