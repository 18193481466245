import { useRouteMatch } from "react-router-dom";
import { useHelpPageRawData, ITEM_TYPE, QUESTION_TYPE } from "../../data";

export interface HelpPageMatch {
    category_slug?: string;
    question_slug?: string;
    selected_section?: ITEM_TYPE;
    selected_question?: QUESTION_TYPE;
}

export function useHelpPageMatcher(): HelpPageMatch {
    const {category_slug, question_slug}: {
        category_slug?: string;
        question_slug?: string;
    } = useRouteMatch().params;

    const USER_SECTIONS = useHelpPageRawData().user;

    if (category_slug == null) {
        return {
            category_slug,
            question_slug,
        };
    }

    const selected_section = USER_SECTIONS.find((item) => {
        return item.slug === category_slug;
    });

    if (selected_section == null) {
        return {
            category_slug,
            question_slug,
        };
    }

    const selected_question = selected_section.question_array.find((item) => {
        return item.slug === question_slug;
    });

    return {
        category_slug,
        question_slug,
        selected_section,
        selected_question,
    };
}
