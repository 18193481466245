import React from "react";
import { StyledGetTheAppBox } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {
  href: string;
}

export const GetTheAppBox: React.FC<Props> = ({
  href
}) => {
  const { t } = useTranslation();
  return (
    <StyledGetTheAppBox
      href={href}
    >{t('rs_text_009')}</StyledGetTheAppBox>
  );
};
