import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  cities_page_assets: assets,
} = config;

const Component: React.FC<{
  scoll_to_pre_footer_form?: () => void;
}> = observer(({
  scoll_to_pre_footer_form,
}) => {
  return (
    <Styled_body_wrap>
      
      <div className="main_container">
        <picture className="miles_ui_cities_page_top_box">
          <div className="content_wrap">
            <div className="top_box_text_01">Make an impact with Miles</div>
            <div className="top_box_text_02">Use Miles’ innovative solutions to incentive riders and gain insights into citizen behavior</div>
            <button className={`miles_ui_btn_primary get_started_btn`} onClick={scoll_to_pre_footer_form}>Get Started</button>
          </div>
          <source className="miles_ui_cities_page_top_box_img" media="(max-width : 360px)" srcSet={assets.top_box_img_360}></source>
          <source className="miles_ui_cities_page_top_box_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.top_box_img_600}></source>
          <source className="miles_ui_cities_page_top_box_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.top_box_img_1024}></source>
          <source className="miles_ui_cities_page_top_box_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.top_box_img_1280}></source>
          <source  className="miles_ui_cities_page_top_box_img" media="(min-width : 1441px)" srcSet={assets.top_box_img_1440}></source>
          <img className="miles_ui_cities_page_top_box_img" alt={`top_box_img`}></img>
        </picture>
      </div>
    </Styled_body_wrap>
  );
});

export default Component;
