import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0 auto;
  padding: 0; 

  .miles_ui_about_page_founders_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fffaf5;
  }

  .miles_ui_about_page_founders_container {
    width: 1440px;
    height: 516px;
    flex-direction: column;
    position: relative;
  }

  .miles_ui_about_page_founders_title {
    font-family: Gilroy-Bold;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #2d2f3b;
    position: absolute;
    top: 177px;
    left: 726px;
  }

  .miles_ui_about_page_founders_picture {
    position: absolute;
    top: 102px;
    left: 186px;
  }

  .miles_ui_about_page_founders_picture_img {
    height: 310px;
  }

  .miles_ui_about_page_founders_body {
    width: 528px;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2d2f3b;
    position: absolute;
    top: 229px;
    left: 726px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

    .miles_ui_about_page_founders_container {
      width: 100vw;
    }
    .miles_ui_about_page_founders_title {
      width: fit-content;
      font-size: 24px;
      letter-spacing: 0.75px;
      top: 81px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_founders_picture {
      width: fit-content;
      top: 139px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_founders_picture_img {
      height: 176px;
    }

    .miles_ui_about_page_founders_body {
      font-size: 16px;
      letter-spacing: 1.5;
      text-align: center;
      width: 294px;
      top: 339px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_founders_container {
      width: 100vw;
    }
    .miles_ui_about_page_founders_title {
      width: fit-content;
      font-size: 24px;
      letter-spacing: 0.75px;
      top: 81px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_founders_picture {
      width: fit-content;
      top: 139px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_founders_picture_img {
      height: 176px;
    }

    .miles_ui_about_page_founders_body {
      font-size: 16px;
      letter-spacing: 1.5;
      text-align: center;
      width: 294px;
      top: 339px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_founders_container {
      width: 100vw;
    }
    .miles_ui_about_page_founders_title {
      font-size: 24px;
      letter-spacing: 0.75px;
      top: 179px;
      left: 553px;
    }

    .miles_ui_about_page_founders_picture {
      top: 139px;
      left: 158px;
    }

    .miles_ui_about_page_founders_picture_img {
      height: 235px;
    }

    .miles_ui_about_page_founders_body {
      font-size: 14px;
      letter-spacing: 1.71;
      width: 294px;
      top: 233px;
      left: 553px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .miles_ui_about_page_founders_container {
      width: 100vw;
    }
    .miles_ui_about_page_founders_title {
      top: 177px;
      left: 646px;
    }

    .miles_ui_about_page_founders_picture {
      left: 106px;
    }

    .miles_ui_about_page_founders_picture_img {
      height: 310px;
    }

    .miles_ui_about_page_founders_body {
      left: 646px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;