import styled from "styled-components";

export const MainWrap = styled("div")`
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .text_01 {
        width: auto;
        height: auto;
        margin: 0;
        font-family: Gilroy-SemiBold;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 66px;
    }

    .text_02 {
        height: auto;
        margin: 0;
        font-family: Gilroy-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 24px;
    }

    .text_03 {
        height: auto;
        margin: 0;
        font-family: Gilroy-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 0;
    }

    .text_04 {
        width: auto;
        height: auto;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 36px;
    }

    .reset_password_input_box {
        margin: 0;
        margin-top: 51px;
        width: 335px;

        .label_01 {
            width: auto;
            height: 12px;
            margin: 0;
            font-family: Gilroy-SemiBold;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.38px;
            color: #2d2f3b;
            text-align: left;
            margin-bottom: 11px;

            &.err {
                color: #ff4949;
            }
        }

        .input_tip_01 {
            width: 335px;
            height: auto;
            margin: 0;
            font-family: Gilroy-Medium;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.5px;
            color: #9b9ba5;
            margin-top: 11px;

            &.err {
                color: #ff4949;
            }
        }

        .label_02 {
            width: auto;
            height: 12px;
            margin: 0;
            font-family: Gilroy-SemiBold;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.38px;
            color: #2d2f3b;
            text-align: left;
            margin-top: 31px;
            margin-bottom: 8px;

            &.err {
                color: #ff4949;
            }
        }

        .input_tip_02 {
            width: 335px;
            height: auto;
            margin: 0;
            font-family: Gilroy-Medium;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.5px;
            color: #9b9ba5;
            margin-top: 11px;

            &.err {
                color: #ff4949;
            }
        }

    }

    .btn_reset_password {
        width: 198px;
        height: 44px;
        margin: 0;
        padding: 0;
        border-radius: 43.5px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        margin-top: 75px;
        outline: none;
        border: none;
        font-family: Gilroy-SemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: 0.7px;
        text-align: center;
        color: #ffffff;

        &.err {
            margin-top: 48px;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        .text_01 {
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: 0.19px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 14px;
        }
    
        .text_02 {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: 0.23px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 21px;
        }
    
        .text_03 {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: 0.23px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 0;
        }
    
        .text_04 {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.21px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 25px;
        }

        .reset_password_input_box {
            margin-top: 42px;
            width: 280px;
    
            .label_01 {}
    
            .input_tip_01 {
                width: 280px;
            }
    
            .label_02 {}
    
            .input_tip_02 {
                width: 280px;
            }
    
        }
        
    }

    @media only screen and (max-device-width : 768px) {
        .btn_reset_password {
            margin-bottom: 50px;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .text_01 {
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.26px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 45px;
        }
    
        .text_02 {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.08;
            letter-spacing: 0.23px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 24px;
        }
    
        .text_03 {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.08;
            letter-spacing: 0.23px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 0;
        }
    
        .text_04 {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.21px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 37px;
        }

        .btn_reset_password {
            margin-top: 50px;
            &.err {
                margin-top: 23px;
            }
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        .text_01 {
            margin-top: 61px;
        }
        
        .text_02 {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.3px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 24px;
        }
        
        .text_03 {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.3px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 0;
        }
        
        .text_04 {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.27px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 36px;
        }
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        .text_01 {
            font-size: 32px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.34px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 119px;
        }
        
        .text_02 {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.89;
            letter-spacing: 0.34px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 20px;
        }
        
        .text_03 {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.89;
            letter-spacing: 0.34px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 0;
        }
        
        .text_04 {
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.33px;
            text-align: center;
            color: #2d2f3b;
            margin-top: 70px;
        }

        .reset_password_input_box {
            margin: 0;
            margin-top: 45px;
            width: 400px;
    
            .label_01 {
                width: auto;
                height: 18px;
                margin: 0;
                font-family: Gilroy-SemiBold;
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.56px;
                color: #2d2f3b;
                text-align: left;
                margin-bottom: 10px;
    
                &.err {
                    color: #ff4949;
                }
            }
    
            .input_tip_01 {
                width: 414px;
                height: auto;
                margin: 0;
                font-family: Gilroy-Medium;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: 0.58px;
                color: #9b9ba5;
                margin-top: 8px;
    
                &.err {
                    color: #ff4949;
                }
            }
    
            .label_02 {
                width: auto;
                height: 18px;
                margin: 0;
                font-family: Gilroy-SemiBold;
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.56px;
                color: #2d2f3b;
                text-align: left;
                margin-top: 45px;
                margin-bottom: 6px;
    
                &.err {
                    color: #ff4949;
                }
            }
    
            .input_tip_02 {
                width: 414px;
                height: auto;
                margin: 0;
                font-family: Gilroy-Medium;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: 0.58px;
                color: #9b9ba5;
                margin-top: 15px;
    
                &.err {
                    color: #ff4949;
                }
            }
    
        }
        
        .btn_reset_password {
            margin: 0;
            padding: 0;
            width: 217px;
            height: 52px;
            padding: 13px 23px 15px 21px;
            border-radius: 25px;
            box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
            background-color: #10af42;
            margin-top: 70px;
            outline: none;
            border: none;
            font-family: Gilroy-SemiBold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.7px;
            text-align: center;
            color: #ffffff;
    
            &.err {
                margin-top: 33px;
            }
        }
    }
`;

export const Styled_password_reset_success = styled("div")`
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .content_01 {
        width: auto;
        height: 32px;
        margin: 0;
        margin-top: 61px;
        font-family: Gilroy-SemiBold;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2f3b;
    }

    .password_reset_success_img {
        width: 249px;
        height: 211px;
        margin-top: 45px;
    }

    .content_02 {
        width: 453px;
        height: auto;
        margin-top: 45px;
        font-family: Gilroy-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 1px;
        text-align: center;
        color: #2d2f3b;
    }

    img {
        display: inline-block;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        color: #10af42;
        display: inline-block;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        .content_01 {
            margin-top: 51px;
            height: 32px;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: 0.19px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_success_img {
            width: 190px;
            height: 161px;
            margin-top: 26px;
        }
    
        .content_02 {
            margin-top: 46px;
            width: 283px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: 0.67px;
            text-align: center;
            color: #2d2f3b;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .content_01 {
            margin-top: 48px;
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.26px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_success_img {
            width: 249px;
            height: 211px;
            margin-top: 24px;
        }
    
        .content_02 {
            margin-top: 47px;
            width: 334px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: 0.78px;
            text-align: center;
            color: #2d2f3b;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {

    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .content_01 {
            margin-top: 66px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        .content_01 {
            width: auto;
            height: 32px;
            margin: 0;
            margin-top: 118px;
            font-family: Gilroy-SemiBold;
            font-size: 32px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.34px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_success_img {
            width: 249.5px;
            height: 211.4px;
            margin-top: 80px;
        }
    
        .content_02 {
            width: 453px;
            height: auto;
            margin-top: 80px;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 1px;
            text-align: center;
            color: #2d2f3b;
        }
    }
`;

export const Styled_password_reset_err = styled("div")`
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .content_01 {
        width: auto;
        height: 32px;
        margin: 0;
        margin-top: 61px;
        font-family: Gilroy-SemiBold;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2f3b;
    }

    .password_reset_err_img {
        width: 187px;
        height: 111px;
        margin-top: 45px;
    }

    .content_02 {
        width: 573px;
        height: auto;
        margin-top: 45px;
        font-family: Gilroy-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 1px;
        text-align: center;
        color: #2d2f3b;
    }

    img {
        display: inline-block;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        color: #10af42;
        display: inline-block;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        .content_01 {
            margin-top: 51px;
            height: 32px;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: 0.19px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_err_img {
            width: 150px;
            height: 89px;
            margin-top: 45px;
        }
    
        .content_02 {
            margin-top: 46px;
            width: 286px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: 0.67px;
            text-align: center;
            color: #2d2f3b;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .content_01 {
            height: 32px;
            margin-top: 48px;
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.26px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_err_img {
            width: 187px;
            height: 111px;
            margin-top: 45px;
        }
    
        .content_02 {
            width: 340px;
            margin-top: 70px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: 0.78px;
            text-align: center;
            color: #2d2f3b;
        }

        a {
            display: block;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {

    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .content_01 {
            margin-top: 66px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        .content_01 {
            width: auto;
            height: 32px;
            margin: 0;
            margin-top: 118px;
            font-family: Gilroy-SemiBold;
            font-size: 32px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.34px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_err_img {
            width: 246px;
            height: 146px;
            margin-top: 80px;
        }
    
        .content_02 {
            width: 453px;
            height: auto;
            margin-top: 80px;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 1px;
            text-align: center;
            color: #2d2f3b;
        }

        a {
            display: block;
        }
    }
`;

export const Styled_password_reset_expired = styled("div")`
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .content_01 {
        width: auto;
        height: 32px;
        margin: 0;
        margin-top: 61px;
        font-family: Gilroy-SemiBold;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2f3b;
    }

    .password_reset_expired_img {
        width: 130px;
        height: 130px;
        margin-top: 45px;
    }

    .content_02 {
        width: 573px;
        height: auto;
        margin-top: 45px;
        font-family: Gilroy-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 1px;
        text-align: center;
        color: #2d2f3b;
    }

    img {
        display: inline-block;
    }

    a {
        text-decoration: none;
        cursor: pointer;
        color: #10af42;
        display: inline;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        .content_01 {
            margin-top: 51px;
            height: 32px;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: 0.19px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_expired_img {
            width: 100px;
            height: 100px;
            margin-top: 42px;
        }
    
        .content_02 {
            margin-top: 55px;
            width: 277px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: 0.67px;
            text-align: center;
            color: #2d2f3b;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .content_01 {
            height: 24px;
            margin-top: 48px;
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.26px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_expired_img {
            width: 130px;
            height: 130px;
            margin-top: 45px;
        }
    
        .content_02 {
            margin-top: 50px;
            width: 321px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: 0.78px;
            text-align: center;
            color: #2d2f3b;
        }

        a {
            display: block;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {

    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .content_01 {
            margin-top: 66px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        .content_01 {
            width: auto;
            height: 32px;
            margin: 0;
            margin-top: 118px;
            font-family: Gilroy-SemiBold;
            font-size: 32px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.34px;
            text-align: center;
            color: #2d2f3b;
        }
    
        .password_reset_expired_img {
            width: 142px;
            height: 151px;
            margin-top: 80px;
        }
    
        .content_02 {
            width: 511px;
            height: auto;
            margin-top: 80px;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 1px;
            text-align: center;
            color: #2d2f3b;
        }
        
        a {
            display: block;
        }
    }
`;

