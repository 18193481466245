import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { Link } from 'react-router-dom';
import { observer } from "mobx-react";
import config from "../../../utils/config";
import milesUtils, { useCountry } from "../../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";
import { ITEM_TYPE } from '../../../data';
import { urlForSection } from "../../helpPage/urls";

const {
  contact_page_assets: assets,
} = config;

export interface Props {
  item: ITEM_TYPE;
  // TODO(Prutha): Remove the need for addClass.
  addClass: boolean;
}

export const Component: React.FC<Props> = observer((props) => {
  const {item, addClass} = props;
  let country = useCountry();
  return (
    <Styled_body_wrap>
      <Link to={urlForSection(item, country)}>
        <button className={`card_unit ${addClass ? "ciq_spacer" : ""}`}>
          <div className="card_unit_01">
            <img className="inner_box_01_asset" src={item.image_src} alt={`help_page_category_card_unit`}>
            </img>
          </div>
          {
            item.slug === "miles_earning"
            ? <>
              {/* <div className="card_unit_text_half text_01">Account Settings &</div>
              <div className="card_unit_text_half text_02">Preferences</div> */}
            <div className="card_unit_text">{item.name}</div>
              </>
            : <div className="card_unit_text">{item.name}</div>
          }
        </button>
      </Link>
    </Styled_body_wrap>
  );
});


export default Component;
