import React from "react";
import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin-top: 0;

  .avatar_box_wrap {
    width: max-content;
    height: 580px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .innerBox {
    margin: 0;
    padding: 0;

    .inner_sub_wrap {
      display: flex;
      flex-direction: row;
    }

    &.box_01,
    &.box_02,
    &.box_03,
    &.box_04 {
      margin: 0;
      padding: 0;
    }

    &.box_common {
      width: 2020px;
      width:auto;
      height: 580px;
      border-right: solid 1px #e6e6e8;
      display: flex;
      flex-direction: column;
    }

  }

  .innerBox_margin_top {
    margin-top: 20px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;

const _Styled_Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  justify-content: flex-start;

  &.design,
  &.engineering,
  &.marketing,
  &.support {
    margin: 0;
    margin-right: 60px;
    width: 185px;
    height: 276px;

    img {
      width: 170px;
      height: 170px;
      display: inline-block;
    }

    .descriptionEle.title {
      margin: 0 auto;
      margin-top: 20px;
      width: 185px;
      height: auto;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.61;
      letter-spacing: 0.7px;
      text-align: center;
      color: rgb(45, 47, 59);
    }

    .descriptionEle.subtitle {
      margin: 0 auto;
      margin-top: 6px;
      width: max-content;
      height: auto;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: 0.7px;
      text-align: center;
      color: rgb(45, 47, 59);
    }

    &.first {
      margin-left: 60px;
    }

  }

  &.design {
  }

  &.engineering {
  }

  &.marketing {
  }

  &.support {
  }

  .carousel_01_img {
  }

  .descriptionEle.title {
    
    .highlighted {
      color: #10af42;
    }
  }

  .descriptionEle.subtitle {
    
    .highlighted {
      color: #10af42;
    }
  }

  &.carousel_card_longer_content {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;

export const Styled_Card = React.forwardRef<HTMLDivElement, Omit<JSX.IntrinsicElements['div'], 'ref'>>((props, ref) => (
  <_Styled_Card {...props} ref={ref} />
));
