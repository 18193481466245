import React from "react";
import { StyledNav, StyledNavWrap } from "./styles";

interface Props {
  src: string;
  alt: string;
}

export const MilesWebHeader: React.FC<Props> = ({
  src,
  alt
}) => {

  return (
    <StyledNavWrap>
      <StyledNav id="miles_v2_header" className="navbar navbar-expand-miles navbar-light justify-content-between">
        <div className="container-fluid-wrapper">
          <div className="container-fluid container-height">
            <a className={`navbar-brand`} href="https://www.edsp.co.jp/camp/lp/noruku/?cid=noruku_lp">
              <img src={src} alt={alt}></img>
            </a>
          </div>
        </div>
      </StyledNav>
      <div className="miles_ui_mock_nav" />
    </StyledNavWrap>
  );
};
