import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  width: 100vw;
  height: 1150px;
  background: #e9f8ee;

  .main_container {
    margin: 0 auto;
    width: 1440px;
  }

  .screen_larger_than_768 {
    display: block;
  }

  .screen_less_than_768 {
    display: none;
  }

  .title {
    width: max-content;
    height: auto;
    margin: 0 auto;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 150px;
  }

  .inner_box_wrap {
    width: 748px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 113px;

    .card_wrap_01 {
      width: 320px;
    }

    .card_wrap_02 {
      width: 320px;
    }

    .card_wrap_03 {
      width: 320px;
      margin-top: 115px;
    }

    .card_wrap_04 {
      width: 320px;
      margin-top: 115px;
    }

    .card_box_wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .card_title {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Gilroy-Bold;
        font-size: 48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .card_subTitle {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Gilroy-Medium;
        font-size: 32px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: #82828c;
        margin-top: 6px;
      }

      .card_inner_img_wrap {
        width: 150px;
        height: 150px;
        background-color: #ffffff;
        margin-top: 24px;
        border-radius: 50%;

        &.card_inner_img_wrap_01 {
          padding: 41px 33px 39px 27px;
        }

        &.card_inner_img_wrap_02 {
          padding: 34px 36px 36px 34px;
        }

        &.card_inner_img_wrap_03 {
          padding: 35px 29px 35px 28px;
        }

        &.card_inner_img_wrap_04 {
          padding: 35px 35px 35px 36px;
        }

        img {
          &.growing_fast_img_01 {
            width: 90px;
            height: 70px;
          }

          &.growing_fast_img_02 {
            width: 80px;
            height: 80px;
          }

          &.growing_fast_img_03 {
            width: 93px;
            height: 80px;
          }

          &.growing_fast_img_04 {
            width: 79px;
            height: 80px;
          }

        }
      }

    }


  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 1750px;

    .main_container {
      margin: 0 auto;
      width: 100vw;
    }

    .screen_larger_than_768 {
      display: none;
    }
  
    .screen_less_than_768 {
      display: block;
    }

    .title {
      width: max-content;
      height: auto;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 80px;
    }

    .inner_box_wrap {
      width: 319px;
      flex-direction: column;
      justify-content: center;
      margin-top: 52px;

      .card_wrap_01 {
        width: 320px;
        margin-top: 0;
      }

      .card_wrap_02 {
        width: 320px;
        margin-top: 120px;
      }

      .card_wrap_03 {
        width: 320px;
        margin-top: 120px;
      }

      .card_wrap_04 {
        width: 320px;
        margin-top: 120px;
      }

      .card_box_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card_title {}

        .card_subTitle {
          width: max-content;
          height: auto;
          margin: 0 auto;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.17;
          letter-spacing: normal;
          text-align: center;
          color: #82828c;
          margin-top: 5px;
        }

        .card_inner_img_wrap {
          width: 106px;
          height: 106px;
          margin-top: 35px;

          &.card_inner_img_wrap_01 {
            padding: 30px 23px 27px 19px;
          }

          &.card_inner_img_wrap_02 {
            padding: 30px 25px 26px 30px;
          }

          &.card_inner_img_wrap_03 {
            padding: 25px 21px 24px 22px;
          }

          &.card_inner_img_wrap_04 {
            padding: 25px 25px 24px;
          }

          img {
            &.growing_fast_img_01 {
              width: 64px;
              height: 49px;
            }

            &.growing_fast_img_02 {
              width: 45px;
              height: 45px;
            }

            &.growing_fast_img_03 {
              width: 59px;
              height: auto;
            }

            &.growing_fast_img_04 {
              width: 56px;
              height: 56px;
            }

          }
        }

      }


    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: 1750px;

    .main_container {
      margin: 0 auto;
      width: 100vw;
    }

    .screen_larger_than_768 {
      display: none;
    }
  
    .screen_less_than_768 {
      display: block;
    }

    .title {
      width: max-content;
      height: auto;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 80px;
    }

    .inner_box_wrap {
      width: 319px;
      flex-direction: column;
      justify-content: center;
      margin-top: 52px;

      .card_wrap_01 {
        width: 320px;
        margin-top: 0;
      }

      .card_wrap_02 {
        width: 320px;
        margin-top: 120px;
      }

      .card_wrap_03 {
        width: 320px;
        margin-top: 120px;
      }

      .card_wrap_04 {
        width: 320px;
        margin-top: 120px;
      }

      .card_box_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card_title {}

        .card_subTitle {
          width: max-content;
          height: auto;
          margin: 0 auto;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.17;
          letter-spacing: normal;
          text-align: center;
          color: #82828c;
          margin-top: 5px;
        }

        .card_inner_img_wrap {
          width: 106px;
          height: 106px;
          margin-top: 35px;

          &.card_inner_img_wrap_01 {
            padding: 30px 23px 27px 19px;
          }

          &.card_inner_img_wrap_02 {
            padding: 30px 25px 26px 30px;
          }

          &.card_inner_img_wrap_03 {
            padding: 25px 21px 24px 22px;
          }

          &.card_inner_img_wrap_04 {
            padding: 25px 25px 24px;
          }

          img {
            &.growing_fast_img_01 {
              width: 64px;
              height: 49px;
            }

            &.growing_fast_img_02 {
              width: 45px;
              height: 45px;
            }

            &.growing_fast_img_03 {
              width: 59px;
              height: auto;
            }

            &.growing_fast_img_04 {
              width: 56px;
              height: 56px;
            }

          }
        }

      }


    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 1220px;

    .main_container {
      width: 100vw;
    }

    .title {
      width: max-content;
      height: auto;
      margin: 0 auto;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 188px;
    }

    .inner_box_wrap {
      width: 730px;
      margin-top: 110px;

      .card_wrap_01 {
        width: 345px;
      }

      .card_wrap_02 {
        width: 345px;
      }

      .card_wrap_03 {
        width: 345px;
        margin-top: 115px;
      }

      .card_wrap_04 {
        width: 345px;
        margin-top: 115px;
      }

      .card_box_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card_title {}

        .card_subTitle {}

        .card_inner_img_wrap {

          &.card_inner_img_wrap_01 {
            padding: 41px 30px 39px;
          }

          &.card_inner_img_wrap_02 {
            padding: 34px 36px 36px 34px;
          }

          &.card_inner_img_wrap_03 {
            padding: 35px 29px 35px 28px;
          }

          &.card_inner_img_wrap_04 {
            padding: 35px 35px 35px 36px;
          }

          img {
            &.growing_fast_img_01 {
              width: 90px;
              height: 70px;
            }

            &.growing_fast_img_02 {
              width: 80px;
              height: 80px;
            }

            &.growing_fast_img_03 {
              width: 93px;
              height: 80px;
            }

            &.growing_fast_img_04 {
              width: 79px;
              height: 80px;
            }

          }
        }

      }


    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .main_container {
      width: 100vw;
    }
  
    .title {
      margin-top: 185px;
    }
  
    .inner_box_wrap {
      width: 773px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: 1150px;
    background: #e9f8ee;

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }
  }

`;

interface CardsProps {}
export const StyledCard = styled("div")<CardsProps>`

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {

    }

    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        
    }
`;