import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  .section_title{
    /* width: 256px; */
    height: 24px;
    font-family: Gilroy-Medium;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 3.0px;
    color: #343434;
    position: relative;
    left: 436.5px;
    padding: 0px;
    top: 0px;
    text-transform: uppercase;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .section_title{
      left: 25px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .section_title{
      width: 320px;
      margin: 0 auto;
      left: auto;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .section_title{
      width: 320px;
      margin: 0 auto;
      left: auto;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .section_title{
      width: 320px;
      margin: 0 auto;
      left: auto;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .section_title{
      width: 320px;
      margin: 0 auto;
      left: auto;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .section_title{
      left: 300px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .section_title{
      left: 346px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;