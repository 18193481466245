import config from "../../config";
const {
	// reward sharing start
	apple_store_src,
	google_play_store_src,
	// reward sharing end

	about_page_assets,
  } = config

const JAPANESE_LNG = {
	"email_unsubscribe_initial_box_title": "以下項目に関してのメール配信を停止してもよろしいでしょうか？",
	"text_confirm": "配信を停止する",
	"text_cancel": "キャンセル",

	"email_unsubscribe_confirm_box_title": "配信設定が更新されました",
	"email_unsubscribe_confirm_box_subTitle_1": " に関するメールの配信停止が完了しました。以下ボタンから配信を再開することもできます。",
	"email_unsubscribe_confirm_box_subTitle_2": "",
	"email_unsubscribe_confirm_box_cheer_text": "今後もMilesをよろしくお願いいたします！",
	"text_reSubscribe": "配信を再開する",
	"text_note": "",
	"text_note_icon": "",
	"text_preference": "こちら",
	"preference_preText": "からでも配信設定を管理できます",
	"text_note_description": ` 配信設定の変更に10日ほどお時間をいただくことがあります`,

	"text_Thank_you": "ありがとうございます",
	"email_unsubscribe_thx_box_resubscribe_subTitle_1": " に関するメールの配信が再開されました。メールの配信設定は、アプリからいつでも更新ができます。",
	"email_unsubscribe_thx_box_resubscribe_subTitle_2": "",
	"email_unsubscribe_thx_box_resubscribeAll_subTitle": "すべてのメール配信が再開されました。メールの配信設定は、アプリからいつでも更新ができます。",
	"email_unsubscribe_thx_box_cheer_text": "今後もMilesをよろしくお願いいたします！",

	"email_unsubscribe_thx_box_update_preference_by_group_subTitle": "メールの配信設定が更新されました",
	"email_unsubscribe_thx_box_note_description": "配信設定の変更に10日ほどお時間をいただくことがあります",

	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_title": "配信設定が更新されました",
	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_subTitle": " すべてのメールの配信停止が完了しました。以下ボタンから配信を再開することもできます。",
	
	"email_Preferences_box_title": "Eメール配信設定",
	"email_Preferences_box_subTitle": "受信を希望しないメールの項目は、チェックマークをお外しください。",
	"email_Preferences_box_btn_text": "設定を保存する",
	"text_Unsubscribe_from_all": "すべてのメールを配信停止",
	"text_Unsubscribe_from_all_description": "全てのメール配信を停止します。必要に応じて、お客様のアカウントに関連する重要なお知らせは送らせていただくこともありますので、ご了承ください。",

	// -- RAF page start --
	"raf_head_logo_url": "/jp",
	"raf_text_01_01": "Miles App を試してみると、二人とも",
	"raf_text_01_02": "マイルを獲得できます",
	"raf_text_02": "毎日をおトクに！",

	"raf_download_text_pre": "+81",
	"raf_download_text": "Milesをダウンロード",
	"raf_share_text": "移動、クイズ等でマイルを獲得",
	"raf_redeem_rewards_text": "ためたマイルはギフトカード交換や換金に！",

	"raf_text_03": "さんの招待コード",

	"raf_phone_num_placeholder": "(090) - 1234 - 5678",
	"raf_text_copied": "コピーされました",
	"raf_text_copy": "招待コードのコピー",
	"raf_text_copy_mobile": "コピー",
	"raf_text_or": "または",

	"raf_fcaRafDownloadText": "リンクがご入力された電話番号に送られました",

	"raf_text_04": "リンクを送信",
	"raf_text_05": "送信中…",

	"raf_text_err_01": "有効な電話番号をご入力ください",
	"raf_text_06": "アプリをインストール",
	"raf_get_the_app_url_path": "/jp/refer?c=",

	// -- RAF page end --

	// -- Reward Sharing page start --
	"rs_header_logo_url": "/jp",
	"rs_text_001": "友達が素敵な特典をシェアしました",
	"rs_text_002": "Milesへのご登録がまだの方",
	"rs_reward_share_download_text": "Milesをダウンロード",
	"rs_reward_share_share_text": "移動、クイズ等でマイルを獲得",
	"rs_reward_share_redeem_rewards_text": "ためたマイルはギフトカード交換や換金に！",

	"rs_reward_share_available_ios": "Apple Store からダウンロード",
	"rs_reward_share_available_android": "Google Play で手に入れよう",
	"rs_reward_share_available_rating_text": "3.5万件の評価",

	"rs_apple_store_icon": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/common_icon/mjkk_reward_sharing_apple_store_icon/Group+560%403x.png`,
	"rs_apple_store_src": apple_store_src,
	"rs_google_play_store_icon": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/common_icon/mjkk_reward_sharing_google_store_icon/Group+561%403x.png`,
	"rs_google_play_store_src": google_play_store_src,

	"rs_text_003": "リンクがご入力された電話番号に送られました",
	"rs_text_004": "(090) - 1234 - 5678",
	"rs_text_005": "+81",
	"rs_text_006": "送信中…",
	"rs_text_007": "リンクを送信",
	"rs_text_007_short": "リンクを送信",
	"rs_text_008": "有効な電話番号をご入力ください",
	"rs_text_009": "アプリをインストール",

	"rs_text_010": "すでにアカウントをお持ちですか？",

	"rs_listItemContent_1": `スマホからMilesアプリを開く`,
	"rs_listItemContent_2_01": `特典ページから`,
	"rs_listItemContent_2_02": `を検索`,
	"rs_listItemContent_3": `マイルをつかって特典と交換`,

	"rs_text_011": `100以上の特典を`,
	"rs_text_012": `アプリ内から提供中`,

	// -- Reward Sharing page end --

	// -- Help page start --
	"help_text_001": "ヘルプセンター",
	"help_page_header_asset_1": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/help_page/mjkk_help_page_header_asset_1440/help_center_graphic1%403x.png`,
	"help_page_header_asset_2": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/help_page/mjkk_help_page_header_asset_1440/help_center_graphic1%403x.png`,
	"help_page_header_asset_3": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/help_page/mjkk_help_page_header_asset_600_v2/header_small%403x.png`,
	"help_page_header_asset_4": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/help_page/mjkk_help_page_header_asset_600_v2/header_small%403x.png`,
	"help_text_002": "ユーザーサポート",
	"help_text_003": "パートナー企業サポート",

	"help_form_text_001": "お問い合わせフォーム",
	"help_form_text_002": "項目",
	"help_form_text_002_placeHolder": "一つご選択ください",
	"help_form_text_002_err": "項目を選択ください",
	"help_form_text_003": "お名前",
	"help_form_text_003_placeHolder": "お名前をご入力ください",
	"help_form_text_003_err": "有効なお名前をご入力ください",
	"help_form_text_004": "メールアドレス",
	"help_form_text_004_placeHolder": "メールアドレスをご入力ください",
	"help_form_text_004_err": "有効なメールアドレスをご入力ください",
	"help_form_text_005": "お電話番号（任意）",
	"help_form_text_005_err": "有効なお電話番号をご入力ください",
	"help_form_text_006": "お問い合わせフォーム",
	"help_form_text_006_placeHolder": "お問い合わせ内容をご入力ください",
	"help_form_text_006_err": "お問い合わせ内容をご入力ください",
	"help_form_text_007": "お問い合わせ内容をご入力ください。 担当者より近日中に連絡をさせていただきます",
	"help_form_text_008": "送信",
	"help_form_text_009": "お電話番号をご入力ください",
	"help_form_text_010": "エラーが発生しました。再度お試しください",
	"help_form_text_011": `この度は、Milesへのお問い合わせありがとうございました。
	ブランドパートナー様のご希望やPR関連のお問い合わせについては、改めて担当者よりご連絡をさせていただきますので、今しばらくお待ちください。
	個人ユーザー様からのお問い合わせは、アプリ内から受け付けております。こちらのメールにはご返信できませんのでご了承くださいませ。`,
	// SubjectValuesByUserType start
	"help_form_text_012": "アカウント設定について",
	"help_form_text_013": "インストール・初期設定について",
	"help_form_text_014": "データプライバシー",
	"help_form_text_015": "その他",
	"help_form_text_016": "特典について",
	"help_form_text_017": "移動・マイルについて",
	"help_form_text_018": "その他",

	"help_form_text_019": "ブランドパートナーへのご参画希望",
	"help_form_text_020": "代理店様からのお問い合わせ",
	"help_form_text_021": "公共交通機関のお客様からのお問い合わせ",
	"help_form_text_022": "PR関連のお問い合わせ",
	"help_form_text_023": "その他",
	// SubjectValuesByUserType end
	"help_form_text_024": "一つご選択ください",
	
	// -- Help page end --

	// -- partner page start --
	"partner_page_text_001": "積極的にマイル交換先を探す",
	"partner_page_text_002": "アクティブなユーザーへリーチ",
	"partner_page_text_003": "ブランドの認知度を高め、高い購買意欲を持つ顧客を獲得するためのマーケティングプラットフォーム",
	"partner_page_text_004": "お問い合わせ",

	"partner_page_discover_text_001": "新規顧客の開拓",
	"partner_page_discover_text_002": "プロフィールの分析",
	"partner_page_discover_text_003": "ニーズに合致したユーザーの発見",
	"partner_page_discover_text_004": "行動分析",
	"partner_page_discover_text_005": "行動をベースにターゲット層を絞り込み、ブランドのリーチを広める",
	"partner_page_discover_text_006": "位置情報と目標物",
	"partner_page_discover_text_007": "位置情報と目標地点情報を活用し、ジオターゲティングを行うことが可能。最適のタイミングでユーザーにリーチできます",

	"partner_page_carousel_01_text_001": "新規顧客獲得と顧客エンゲージメントの増加",
	"partner_page_carousel_01_text_002": "ブランド認知度の向上から、LTVの向上まで、Milesは適切なターゲット層へリーチする機会を様々な手法で提供しています",
	"partner_page_carousel_01_a_001": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_a_1440_v1/Group+5%403x.png",
	"partner_page_carousel_01_a_002": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_a_1280_v1/Group+5%403x.png",
	"partner_page_carousel_01_a_003": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_a_1000_v1/Group+670%403x.png",
	"partner_page_carousel_01_a_004": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_a_600_v1/Group+671%403x.png",
	"partner_page_carousel_01_b_001": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_b_1440_v1/Group+673%403x.png",
	"partner_page_carousel_01_b_002": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_b_1280_v1/Group+4%403x.png",
	"partner_page_carousel_01_b_003": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_b_1000_v1/Group+5%403x.png",
	"partner_page_carousel_01_b_004": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_b_600_v1/Group+677%403x.png",
	"partner_page_carousel_01_c_001": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_carousel_01_c_1440_v1/Group+53%403x.png",
	"partner_page_carousel_01_c_002": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_carousel_01_c_1280_v1/Group+675%403x.png",
	"partner_page_carousel_01_c_003": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_carousel_01_c_1000_v1/Group+8%403x.png",
	"partner_page_carousel_01_c_004": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_carousel_01_c_600_v1/Group+678%403x.png",
	"partner_page_carousel_01_d_001": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_d_1440_v1/Group+53%403x.png",
	"partner_page_carousel_01_d_002": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_d_1280_v1/Group+9%403x.png",
	"partner_page_carousel_01_d_003": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_d_1000_v1/Group+676%403x.png",
	"partner_page_carousel_01_d_004": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/partner_page/mjkk_slide_d_600_v1/Group+9%403x.png",

	"partner_page_drive_success_title_text_001": "様々なマーケティング戦略",
	"partner_page_drive_success_title_text_002": "Milesは独自のサービスを通じて、貴社の目標達成をサポートします",
	"partner_page_drive_success_text_001_01": "売上・AOV（平均注文金額）の増加",
	"partner_page_drive_success_text_001_02": "",
	"partner_page_drive_success_text_002": "新規顧客の獲得",
	"partner_page_drive_success_text_003": "CAC（顧客獲得費用）の低減",
	"partner_page_drive_success_text_004": "実店舗への来店を促進",
	"partner_page_drive_success_text_005": "成果報酬型",
	"partner_page_drive_success_text_006": "短期間での導入が可能",
	// -- partner page end --

	// -- contact page start --
	"contact_page_text_001": "Milesへのお問い合わせ",
	"contact_page_text_002": "ユーザーサポート",
	"contact_page_text_003": "パートナー企業サポート",
	// "contact_page_card_unit_01": contact_page_assets.contact_page_card_unit_01,
	// "contact_page_card_unit_02": contact_page_assets.contact_page_card_unit_02,
	// "contact_page_image_background_box": contact_page_assets.contact_page_image_background_box,
	// "contact_page_location_image_box": contact_page_assets.contact_page_location_image_box,
	"contact_page_header_asset_1": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/contact_page/mjkk_contact_page_header_asset_1440_v2/header_asset%402x.png",
	"contact_page_header_asset_2": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/contact_page/mjkk_contact_page_header_asset_1280_v2/Group+12%402x.png",
	"contact_page_header_asset_3": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/contact_page/mjkk_contact_page_header_asset_600_v2/header_small%402x.png",
	"contact_page_header_asset_4": "https://getmiles.miles.assets.getmiles.com/assets/web_v2/jp_web/contact_page/mjkk_contact_page_header_asset_360_v2/header_small%402x.png",
	// "contact_page_send_contact_query_success_asset_1": contact_page_assets.send_contact_query_success_asset_1,
	// "contact_page_send_contact_query_success_asset_2": contact_page_assets.send_contact_query_success_asset_2,
	// -- contact page end --

	// -- about page start --
	"about_page_carousel_01_title": "Milesのストーリー",
	"about_page_carousel_01_rawItems_01": "2016年",
	"about_page_carousel_01_rawItems_01_content": "Miles創業",
	"about_page_carousel_01_rawItems_02": "2018年7月",
	"about_page_carousel_01_rawItems_02_content_part1": "Milesアプリ",
	"about_page_carousel_01_rawItems_02_content_part2": "をリリース",
	"about_page_carousel_01_rawItems_03": "2019年3月",
	"about_page_carousel_01_rawItems_03_content": "累計移動数1,000万回達成",
	"about_page_carousel_01_rawItems_04": "2019年6月",
	"about_page_carousel_01_rawItems_04_title": "自治体とのパートナーシップ",
	"about_page_carousel_01_rawItems_04_content_part0": `エコな移動`,
	"about_page_carousel_01_rawItems_04_content_part1": `を促進するため、米カリフォルニア州の州都サクラメント市、サン・マテオ郡、コントラコスタ郡などの自治体とパートナーシップを締結`,
	"about_page_carousel_01_rawItems_04_content_part2": "",
	"about_page_carousel_01_rawItems_04_content_part3": "",
	"about_page_carousel_01_rawItems_05": "2020年3月",
	"about_page_carousel_01_rawItems_05_content_part1": `新型コロナウィルスの感染拡大に伴い`,
	"about_page_carousel_01_rawItems_05_content_part2": ` 「ステイホーム」`,
	"about_page_carousel_01_rawItems_05_content_part3": `マイルをリリース`,
	"about_page_carousel_01_rawItems_06": "2020年5月",
	"about_page_carousel_01_rawItems_06_content": `累計移動数1億回達成`,
	"about_page_carousel_01_rawItems_07": "2020年6月",
	"about_page_carousel_01_rawItems_07_content_part1": `ソーシャルディスタンス`,
	"about_page_carousel_01_rawItems_07_content_part2": `を`,
	"about_page_carousel_01_rawItems_07_content_part3": `保った移動に 20倍のボーナスマイルを付与`,
	"about_page_carousel_01_rawItems_08": "2020年8月",
	"about_page_carousel_01_rawItems_08_content_part1": `獲得特典が累計100万個に到達`,
	"about_page_carousel_01_rawItems_08_content_part2": ``,
	"about_page_carousel_01_rawItems_09": "2020年9月",
	"about_page_carousel_01_rawItems_09_title": "寄付マイル数6億マイル達成",
	"about_page_carousel_01_rawItems_09_content": `赤十字など様々な団体との連携により、累計6億マイルの寄付を達成`,
	"about_page_carousel_01_rawItems_10": "2020年12月",
	"about_page_carousel_01_rawItems_10_title": "200社以上の提携パートナー",
	"about_page_carousel_01_rawItems_10_content": `今後もさらに提携先を拡大していきます`,
	"about_page_carousel_01_rawItems_11": "2021年3月",
	"about_page_carousel_01_rawItems_11_content_part1": `獲得マイル数10億`,
	"about_page_carousel_01_rawItems_11_content_part2": `達成`,
	"about_page_carousel_01_rawItems_12": "2021年4月",
	"about_page_carousel_01_rawItems_12_content_part1": `米Benzingaの「2021年`,
	"about_page_carousel_01_rawItems_12_content_part2": `Top 10 Startup」に選出`,
	"about_page_carousel_01_rawItems_13": "2021年5月",
	"about_page_carousel_01_rawItems_13_content_part1": `サステナビリティ`,
	"about_page_carousel_01_rawItems_13_content_part2": `を推進する`,
	"about_page_carousel_01_rawItems_13_content_part3": `チャレンジ機能をリリース`,
	"about_page_carousel_01_rawItems_14": "2021年6月",
	"about_page_carousel_01_rawItems_14_content_part1": `獲得マイル数30億マイル達成`,
	"about_page_carousel_01_rawItems_14_content_part2": ``,
	"about_page_carousel_01_rawItems_15": "2021年7月",
	"about_page_carousel_01_rawItems_15_content_part1": `獲得特典数が累計700万個に`,
	"about_page_carousel_01_rawItems_15_content_part2": `到達`,
	"about_page_founders_text_001": `Milesの共同創業者`,
	"about_page_founders_text_002": `ジガー・シャー、パレッシュ・ジャイン、パリン・シャーは、「すべての移動に価値を」をミッションに掲げ、Milesをスタートしました`,
	
	"about_page_investors_text_001": `米国Milesの主な株主・投資家`,

	"about_page_work_at_miles_text_001": `Milesで働く`,
	"about_page_work_at_miles_text_002": `一緒にMilesの成長に貢献するメンバーを募集中！`,
	"about_page_work_at_miles_text_003": `募集職種一覧`,
	"about_page_work_at_miles_url": `https://apply.workable.com/getmiles`,

	"about_page_leadership_text_001": `チーム紹介`,
	"about_page_leadership_text_002": `Miles Japan株式会社`,
	"about_page_leadership_asset_001": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/about_us_leadership/jigar_shah%402x.png`,
	"about_page_leadership_asset_001_name": `ジガー・シャー`,
	"about_page_leadership_asset_001_designation": `代表取締役CEO`,
	"about_page_leadership_asset_002": ``,
	"about_page_leadership_asset_002_name": ``,
	"about_page_leadership_asset_002_designation": ``,
	"about_page_leadership_asset_003": ``,
	"about_page_leadership_asset_003_name": ``,
	"about_page_leadership_asset_003_designation": ``,
	"about_page_leadership_asset_004": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/leadership/mjkk_leadership_ron/Oval_1%403x.png`,
	"about_page_leadership_asset_004_name": `ロン・ドラブキン`,
	"about_page_leadership_asset_004_designation": `セールスディレクター`,
	"about_page_leadership_asset_005": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/leadership/mjkk_leadership_006/Oval%403x.png`,
	"about_page_leadership_asset_005_name": `服部 大輔`,
	"about_page_leadership_asset_005_designation": `グロース責任者`,
	"about_page_leadership_asset_006": ``,
	"about_page_leadership_asset_006_name": ``,
	"about_page_leadership_asset_006_designation": ``,
	"about_page_leadership_asset_007": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/leadership/mjkk_leadership_007/Oval%403x.png`,
	"about_page_leadership_asset_007_name": `ショーワ 大`,
	"about_page_leadership_asset_007_designation": `プロダクトマネージャー`,
	"about_page_leadership_asset_0fy": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/leadership/mjkk_leadership_0fy/Oval%403x.png`,
	"about_page_leadership_asset_0fy_name": `飯嶋 優子`,
	"about_page_leadership_asset_0fy_designation": `カスタマーサポート`,
	"about_page_leadership_asset_0yy": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/leadership/mjkk_leadership_0yy/Oval%403x.png`,
	"about_page_leadership_asset_0yy_name": `原田 宙詞`,
	"about_page_leadership_asset_0yy_designation": `パートナーサクセス`,
	"about_page_leadership_asset_saori_image": `https://getmiles.miles.assets.getmiles.com/assets/web_v2/about_page/leadership/mjkk_leadership_saori/Oval%403x.png`,
	"about_page_leadership_asset_saori_name": `阿部 沙織`,
	"about_page_leadership_asset_saori_designation": `カスタマーサポート`,
	// -- about page start --

	// -- jobs page start --
	"jobs_page_text_001": "Milesで一緒に働きませんか?",
	"jobs_page_text_002": "「すべての移動に価値を提供する」というミッションの下、エコで健康的な移動を推進する画期的なアプリです",
	
	"jobs_page_text_003": "こんな人たちが活躍中！",
	"jobs_page_text_004": "成長のため常に挑戦をし続ける人や、既成概念にとらわれず、新たな価値を生み出せる人が活躍中です",
	"jobs_page_text_005": "求人情報をみる",

	"jobs_page_text_006": "Milesオフィス",
	"jobs_page_text_007": "Milesの本社は、アメリカのシリコンバレーにオフィスがあります。日本オフィスは、渋谷にあります",
	"jobs_page_text_008": "成長のため常に挑戦をし続ける人や、既成概念にとらわれず、新たな価値を生み出せる人が活躍中です",

	"jobs_page_text_009": "東京都港区六本木1-4-5",
	"jobs_page_text_010": "アークヒルズサウスタ",
	"jobs_page_text_011": "ワー 16F",

	"jobs_page_text_012": "Milesチームの一員になりませんか？自分にも、環境にもやさしい世界をつくりましょう",
	"jobs_page_text_013": "求人情報をみる",

	// -- jobs page end --

	// -- account delete form start --

	"account_delete_form_text_header": "アカウント削除リクエスト",
	"account_delete_form_text_001": "名前",
	"account_delete_form_text_001_placeHolder": "名前",
	"account_delete_form_text_001_err": "有効なお名前をご入力ください",
	"account_delete_form_text_002": "ご登録メールアドレス",
	"account_delete_form_text_002_placeHolder": "ご登録メールアドレス",
	"account_delete_form_text_002_err": "有効なメールアドレスをご入力ください",
	"account_delete_form_text_003": "電話番号（任意）",
	"account_delete_form_text_003_placeHolder": "（例）090-1234-5678",
	"account_delete_form_text_003_err": "有効なお電話番号をご入力ください",
	"account_delete_form_text_004": "アカウント削除理由（任意）",
	"account_delete_form_text_004_placeHolder": "アカウント削除理由をお聞かせください。",
	"account_delete_form_text_004_err": "お問い合わせ内容をご入力ください",
	"account_delete_form_text_005": "アカウント削除リクエストの詳細をご入力ください。弊社サポートスタッフが順次対応させていただきます。",
	"account_delete_form_text_006": "送信",
	"account_delete_form_text_007": "エラーが発生しました。再度お試しください",
	"account_delete_form_text_008": "アカウント削除リクエストを承りました。弊社サポートスタッフが順次ご連絡させていただきます。",

	// -- account delete form end --
}

export default JAPANESE_LNG;