import styled from "styled-components";

interface Props {}
export const StyledSadFaceImg = styled("img")<Props>`
  width: 263px;
  height: 89px;
  object-fit: contain;
  display: block;
  margin: 50px auto;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    margin: 40px auto 0 auto;
    width: 201px;
    height: 68px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin: 40px auto 0 auto;
    width: 201px;
    height: 68px;
  }
  
`;