import styled from "styled-components";
import i18next from 'i18next';

interface Props {}
export const StyledConfirmedTitle_confirmUnsubscribe = styled("p")<Props>`
  height: 32px;
  margin: 0 auto;
  font-family: Noto Sans JP;
  font-size: 28px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.42px;
  text-align: center;
  color: #2d2f3b;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    color: "#343434";
    height: 32px;
    font-size: ${() => i18next.language === "english" ? "20px" : "16px"};
    font-weight: 500;
    line-height: 1.6;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    color: "#343434";
    height: 32px;
    font-size: ${() => i18next.language === "english" ? "20px" : "16px"};
    font-weight: 500;
    line-height: 1.6;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 768px) and (max-width : 1280px) {
    color: "#2d2f3b";
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1280px) and (max-width : 1440px) {
    color: "#2d2f3b";
  }

`;