import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import Lottie, { LottieRefCurrentProps, InteractivityProps } from "lottie-react";
import changeLanguage from '../../utils/i18n/i18next';
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils, { useCountry, useLanguageOnTenant } from "../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../utils/milesConstant";
import TopBanner from "./top_banner/index";
import Support from "./support/index";
import HelpCategories from "./help_categories/index";
import ContactForm from "./contact_form/index";
import {Pre_footer_hero} from "../../components/v2/miles_ui_box_pre_footer_hero/index";

const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
} = config

let firstRender = true;
let shouldShowPre_footer_hero = true;

const {
} = MILES_ANIMATIONS;

type FormSelectType = 'user' | 'partner' | null;


interface Props {
  category_slug?: string;
  question_slug?: string;
}

const MilesWeb_ContactPage: React.FC<Props> = observer((props) => {

  let country = useCountry();
  let languageOnTenant = useLanguageOnTenant();

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - helpCategoriesPage -- firstRender");

    switch (languageOnTenant) {
      case 'jp_edsp':
        changeLanguage("japanese_edsp")
        shouldShowPre_footer_hero = false;
        break;
      case 'jp_mjkk':
        changeLanguage("japanese_mjkk")
        shouldShowPre_footer_hero = false;
        break;
      default:
        changeLanguage("english")
        shouldShowPre_footer_hero = true;
    }
  }

  useEffect(() => {
    milesUtils.debug_log("component - helpCategoriesPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  const [formSelection, setFormSelection] = useState(() => (
    null as FormSelectType
    // 'user' as FormSelectType
  ));

  const onClickMemberSupport = useCallback(() => {
    console.log('onClickMemberSupport');
    setFormSelection('user');
  }, []);

  const onClickPartnerSupport = useCallback(() => {
    console.log('onClickPartnerSupport');
    setFormSelection('partner');
  }, []);

  return (<>
    {/* // <Styled_body_wrap> */}
      {/* TODO(Praharshit) */}
      <HelpCategories user_type="user"/>
      {
        (formSelection !== null)
          ? <ContactForm user_type={formSelection}/>
          : <Support 
            onClickMemberSupport={onClickMemberSupport}
            onClickPartnerSupport={onClickPartnerSupport}/>  
      }

      {
        shouldShowPre_footer_hero && (
          <Pre_footer_hero
            apple_store_icon_img={apple_store_icon_img}
            google_play_icon_img={google_play_icon_img}
            star_icon_img={star_icon_img}
            icon_star_half_img={icon_star_half_img}
            apple_store_src={apple_store_src}
            google_play_store_src={google_play_store_src}
            className="miles_ui_pre_footer_commonSize"
          />
        )
      }
      
    {/* // </Styled_body_wrap> */}
  </>);
});

export default MilesWeb_ContactPage;
