import styled from "styled-components";

interface Props {}
export const Styled_Our_users_love = styled("div")<Props>`
  margin: 0 auto;
  padding: 0;
  min-height: 966px;
  height: 966px;
  box-sizing: border-box;
  padding-top: 125px;

  .title {
    height: 56px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .miles_carousel_wrap {
    width: 1140px;
    height: 590px;
    box-sizing: border-box;
    padding: 0;
    margin: 0 auto;
    position: unset;
    margin-top: 52px;
  }
  
  .react-multi-carousel-dot button {
    background: #e6e6e8;
    box-shadow: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    transition: opacity .25s ease-in;
    border: none;
  }

  .react-multi-carousel-dot--active.react-multi-carousel-dot button {
     background: #10af42;
  }

  .custom-dot-list-style {
    bottom: -79px;
  }

  .miles_carousel_wrap_arrow_left {
    left: 51px;
    position: absolute;
    outline: 0;
    border-radius: 35px;
    z-index: 900;
    border: 0;
    background: transparent;
    width: 97px;
    height: 97px;
    opacity: 1;
    cursor: pointer;
    display: block;
    top: 287px;
  }

  .miles_carousel_wrap_arrow_right {
    right: 51px;
    position: absolute;
    outline: 0;
    z-index: 900;
    border: 0;
    background: transparent;
    width: 97px;
    height: 97px;
    opacity: 1;
    cursor: pointer;
    display: block;
    top: 287px;
  }

  .miles_ui_large_than_768 {
    display: block;
  }

  .miles_ui_less_than_768 {
    display: none;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-height: 634px;
    height: 634px;
    padding-top: 55px;
    min-width: 360px;

    .miles_ui_large_than_768 {
      display: none;
    }
  
    .miles_ui_less_than_768 {
      display: block;
    }

    .title {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      box-sizing: border-box;
    }

    a {}

    .miles_carousel_wrap {
      width: 245px;
      height: 438px;
      margin-top: 27px;
    }
      
    .react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .custom-dot-list-style {
      bottom: -36px;
    }

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 220px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 220px;
    }
  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    min-height: 634px;
    height: 634px;
    padding-top: 55px;

    .miles_ui_large_than_768 {
      display: none;
    }
  
    .miles_ui_less_than_768 {
      display: block;
    }

    .title {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      box-sizing: border-box;
    }

    a {}

    .miles_carousel_wrap {
      width: 245px;
      height: 435px;
      margin-top: 20px;
    }
      
    .react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {
      width: 9px;
      height: 9px;
    }

    .custom-dot-list-style {
      bottom: -47px;
    }

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 220px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 155px);
      width: 9px;
      height: 14px;
      top: 220px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 1024px;
    height: 1024px;
    padding-top: 120px;

    .title {
      height: 56px;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      box-sizing: border-box;
    }

    a {}

    .miles_carousel_wrap {
      width: 548px;
      height: 575px;
      margin-top: 100px;
    }
    
    .react-multi-carousel-dot button {}

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

    .custom-dot-list-style {
      bottom: -3px;
    }

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 342px);
      width: 60px;
      height: 60px;
      top: 200px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 342px);
      width: 60px;
      height: 60px;
      top: 200px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    min-height: 964px;
    height: 964px;
    padding-top: 125px;

    .title {
      height: 56px;
      font-family: Gilroy-Bold;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
    }

    .miles_carousel_wrap {
      width: 824px;
      height: 600px;
      box-sizing: border-box;
      padding: 0;
      margin: 0 auto;
      position: unset;
      margin-top: 100px;
    }
    
    .react-multi-carousel-dot button {}

    .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

    .custom-dot-list-style {
      bottom: -12px;
    }

    .miles_carousel_wrap_arrow_left {
      left: calc(50% - 520px);
      width: 70px;
      height: 70px;
      top: 220px;
    }

    .miles_carousel_wrap_arrow_right {
      right: calc(50% - 520px);
      width: 70px;
      height: 70px;
      top: 220px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    width: 1440px;
  }
`;