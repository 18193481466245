import styled from "styled-components";
interface Props {
    country?: string;
  }
export const Styled_body_wrap = styled("div")<Props>`
    margin: 0;
    width: 100vw;
    height: 1106px;
    background: #ffffff;

    .main_container {
        margin: 0 auto;
        width: 1440px;
    }

    .drive_success_title {
        margin: 0 auto;
        width: max-content;
        height: auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 125px;
    }

    .drive_success_sub_title {
        margin: 0 auto;
        width: 883px;
        height: auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 0.46px;
        text-align: center;
        color: #5f5f69;
        margin-top: 46px;
    }

    .drive_success_cards_wrap {
        margin: 0 auto;
        width: 1065px;
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        height: 1875px;

        .main_container {
            width: 100vw;
        }

        .drive_success_title {
            width: 271px;
            height: auto;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 42px;
        }

        .drive_success_sub_title {
            width: 302px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.07;
            letter-spacing: 0.29px;
            text-align: center;
            color: #5f5f69;
            margin-top: 25px;
        }

        .drive_success_cards_wrap {
            margin: 0 auto;
            width: 275px;
            margin-top: 55px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        height: 1933px;

        .main_container {
            width: 100vw;
        }

        .drive_success_title {
            width: 290px;
            height: auto;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 100px;
        }

        .drive_success_sub_title {
            width: 320px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.07;
            letter-spacing: 0.29px;
            text-align: center;
            color: #5f5f69;
            margin-top: 25px;
        }

        .drive_success_cards_wrap {
            margin: 0 auto;
            width: 275px;
            margin-top: 55px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        height: 1100px;

        .main_container {
            width: 100vw;
        }

        .drive_success_title {
            font-size: 32px;
            font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 80px;
        }

        .drive_success_sub_title {
            margin: 0 auto;
            width: 685px;
            height: auto;
            font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};;
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.82;
            letter-spacing: 0.46px;
            text-align: center;
            color: #5f5f69;
            margin-top: 20px;
        }

        .drive_success_cards_wrap {
            margin: 0 auto;
            width: 570px;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .main_container {
            width: 100vw;
        }
    
        .drive_success_cards_wrap {
            width: 975px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        margin: 0;
        width: 100vw;
        height: 1106px;
        background: #ffffff;
    
        .main_container {
            margin: 0 auto;
            width: 1440px;
        }
    }
`;

interface CardsProps {
    country?: string;
}
export const StyledCard = styled("div")<CardsProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 305px;
    height: 270px;
    margin: 0;
    padding: 43px 57px 34px;
    border-radius: 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;

    &.card_wrap_01,
    &.card_wrap_02,
    &.card_wrap_04,
    &.card_wrap_05 {
        margin-right: 75px;
    }

    &.card_wrap_04,
    &.card_wrap_05,
    &.card_wrap_06 {
        margin-top: 40px;
    }

    img {
        width: 100px;
        height: 100px;
        display: inline-block;
    }

    .card_title {
        width: ${(props) => props.country === 'jp' ? '245px' : '190px'};
        height: auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;

        &.drive_success_title_01 {
            margin-top: 33px;
        }

        &.drive_success_title_02 {
            margin-top: 29px;
        }

        &.drive_success_title_03 {
            margin-top: 38px;
        }

        &.drive_success_title_04 {
            margin-top: 24px;
        }

        &.drive_success_title_05 {
            margin-top: 24px;
        }

        &.drive_success_title_06 {
            margin-top: 24px;
        }
        
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        justify-content: center;
        align-items: center;
        width: 275px;
        height: 220px;
        margin: 0 AUTO;
        padding: 37px 42px 37px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        &.card_wrap_01,
        &.card_wrap_02,
        &.card_wrap_04,
        &.card_wrap_05 {
            margin-right: 0;
        }

        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 0;
        }

        &.card_wrap_01,
        &.card_wrap_02,
        &.card_wrap_03,
        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 25px;
        }

        img {
            width: 65px;
            height: 65px;
        }

        .card_title {
            width: 191px;
            height: 60px;
            margin: 0 auto;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;

            &.drive_success_title_01 {
                margin-top: 20px;
            }

            &.drive_success_title_02 {
                width: 141px;
                margin-top: 20px;
            }

            &.drive_success_title_03 {
                margin-top: 20px;
                height: ${(props) => props.country === 'jp' ? '30px' : '60px'};
                line-height: ${(props) => props.country === 'jp' ? '30px' : '60px'};
            }

            &.drive_success_title_04 {
                width: 186px;
                margin-top: 20px;
            }

            &.drive_success_title_05 {
                width: 147px;
                margin-top: 20px;
            }

            &.drive_success_title_06 {
                margin-top: 20px;
            }
            
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        justify-content: center;
        align-items: center;
        width: 275px;
        height: 220px;
        margin: 0 AUTO;
        padding: 37px 42px 37px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        &.card_wrap_01,
        &.card_wrap_02,
        &.card_wrap_04,
        &.card_wrap_05 {
            margin-right: 0;
        }

        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 0;
        }

        &.card_wrap_01,
        &.card_wrap_02,
        &.card_wrap_03,
        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 25px;
        }

        img {
            width: 65px;
            height: 65px;
        }

        .card_title {
            width: 191px;
            height: 60px;
            margin: 0 auto;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;

            &.drive_success_title_01 {
                margin-top: 20px;
            }

            &.drive_success_title_02 {
                width: 141px;
                margin-top: 20px;
            }

            &.drive_success_title_03 {
                margin-top: 20px;
                height: ${(props) => props.country === 'jp' ? '30px' : '60px'};
                line-height: ${(props) => props.country === 'jp' ? '30px' : '60px'};
            }

            &.drive_success_title_04 {
                width: 186px;
                margin-top: 20px;
            }

            &.drive_success_title_05 {
                width: 147px;
                margin-top: 20px;
            }

            &.drive_success_title_06 {
                margin-top: 20px;
            }
            
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 275px;
        height: 220px;
        margin: 0;
        padding: 40px 67px 37px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        &.card_wrap_01,
        &.card_wrap_02,
        &.card_wrap_04,
        &.card_wrap_05 {
            margin-right: 0;
        }

        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 0;
        }

        &.card_wrap_01,
        &.card_wrap_03,
        &.card_wrap_05 {
            margin-right: 20px;
        }

        &.card_wrap_03,
        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 20px;
        }

        img {
            width: 65px;
            height: 65px;
            display: inline-block;
        }

        .card_title {
            width: 184px;
            height: auto;
            font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
            margin: 0;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;

            &.drive_success_title_01 {
                margin-top: 33px;
            }

            &.drive_success_title_02 {
                width: 141px;
                margin-top: 29px;
            }

            &.drive_success_title_03 {
                margin-top: 38px;
            }

            &.drive_success_title_04 {
                margin-top: 24px;
            }

            &.drive_success_title_05 {
                margin-top: 24px;
            }

            &.drive_success_title_06 {
                margin-top: 24px;
            }
            
        }

    }

    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        height: 277px;
        padding: 48px 61px 43px 62px;

        &.card_wrap_01,
        &.card_wrap_02,
        &.card_wrap_04,
        &.card_wrap_05 {
            margin-right: 30px;
        }

        &.card_wrap_04,
        &.card_wrap_05,
        &.card_wrap_06 {
            margin-top: 33px;
        }

        .card_title {
            &.drive_success_title_01 {
                margin-top: 26px;
            }
    
            &.drive_success_title_02 {
                margin-top: 26px;
            }
    
            &.drive_success_title_03 {
                margin-top: 39px;
            }
    
            &.drive_success_title_04 {
                margin-top: 18px;
            }
    
            &.drive_success_title_05 {
                margin-top: 17px;
            }
    
            &.drive_success_title_06 {
                margin-top: 18px;
            }
            
        }

    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        height: 277px;
        
    }
`;