import styled from "styled-components";

interface NavProps {}
export const StyledNav = styled("nav")<NavProps>`
  flex-flow: row;
  justify-content: flex-start;
  display: flex;
  position: relative;
  padding: 0;
  padding-top: 25px;
  padding-left: 45px;
  height: 81px;
  max-width: 1440px;

  a {
    display: inline-block;
    line-height: inherit;
  }

  img {
    width: 108px;
    height: 30px;
  }

  ${process.env.REACT_APP_MILES_TENANT === "fca" && 
    `
    img {
      width: 260px;
      height: 36px;
    }
    `
  }

  ${process.env.REACT_APP_MILES_TENANT === "edsp" && 
    `
    img {
      width: 139px;
      height: 30px;
    }
    `
  }

  @media only screen and (max-width : 360px) {
    ${process.env.REACT_APP_MILES_TENANT === "fca" && 
      `
      img {
        width: 225px;
        height: auto;
      }
      `
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    padding: 0;
    padding-top: 25px;
    // padding-bottom: 6px;
    padding-left: 30px;
    height: 80px;
    margin: 0;
    img {
      width: 100px;
      height: auto;
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "fca" && 
      `
      img {
        width: 225px;
        height: auto;
      }
      `
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "edsp" && 
      `
      img {
        width: 139px;
        height: auto;
      }
      `
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 0;
    padding-top: 25px;
    padding-left: 30px;
    height: 80px;
    margin: 0;
    img {
      width: 100px;
      height: auto;
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "fca" && 
      `
      img {
        width: 125px;
        height: auto;
      }
      `
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "edsp" && 
      `
      img {
        width: 139px;
        height: auto;
      }
      `
    }
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    padding: 0;
    padding-top: 13px;
    padding-bottom: 6px;
    padding-left: 20px;
    height: auto;

    img {
      width: 66px;
      height: auto;
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "fca" && 
      `
      img {
        width: 125px;
        height: auto;
      }
      `
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "edsp" && 
      `
      img {
        width: 139px;
        height: auto;
      }
      `
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    padding: 0;
    padding-bottom: 9.5px;
    padding-top: 9.5px;
    padding-left: 20px;
    height: auto;
    img {
      width: 66px;
      height: auto;
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "fca" && 
      `
      img {
        width: 125px;
        height: auto;
      }
      `
    }
  
    ${process.env.REACT_APP_MILES_TENANT === "edsp" && 
      `
      img {
        width: 139px;
        height: auto;
      }
      `
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "yellow" : "transparent"};
    padding-left: 40px;
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "yellow" : "transparent"};
    padding-left: 40px;
  }
  
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "green" : "transparent"};
    padding-left: 54px;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "green" : "transparent"};
    padding-left: 54px;
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "red" : "transparent"};
  }
`;