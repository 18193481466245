import React from "react";
import { StyledCard } from "./styles";

interface Props {
  avatarImgSrc: string;
  userName: string;
  testimonialStr: string;
  testimonialStyle: {};
  osType: string;
  star_icon_green_img: string;
  logo_android_dark_img: string;
  logo_apple_dark_img: string;
  className?: string;
}

export const Card_with_avatar_testimonial: React.FC<Props> = ({
  avatarImgSrc,
  userName,
  testimonialStr,
  testimonialStyle,
  osType,
  star_icon_green_img,
  logo_android_dark_img,
  logo_apple_dark_img,
  className,
}) => {
  return (
    <StyledCard className={className ?? ""}>
      <img
        className={`avatar_img`}
        src={avatarImgSrc}
      />
      <div className="inner_card_wrap">
        <div className="userName">{userName}</div>
        <p
          className={`testimonial`}
          style={testimonialStyle}
        >
          {testimonialStr}
        </p> 
        <div className="bottom_wrap">
          <img className={`green_star`} src={star_icon_green_img} />
          <img className={`green_star`} src={star_icon_green_img} />
          <img className={`green_star`} src={star_icon_green_img} />
          <img className={`green_star`} src={star_icon_green_img} />
          <img className={`green_star last`} src={star_icon_green_img} />
        </div>
      </div>
    </StyledCard>
  );
};
