import styled from "styled-components";

interface Super_wrap_Props {}
export const Styled_Super_wrap = styled("div")<Super_wrap_Props>`
  width: 100vw;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .title {
    width: auto;
    height: 56px;
    margin: 0;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    position: absolute;
    margin-top: 160px;
  }
  
  .subTitle {
    width: 750px;
    height: auto;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    position: absolute;
    margin-top: 235px;
  }

  .bg_img {
    width: 718px;
    height: 250px;
    display: inline-block;
    background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_diversity_bg@2x.png) center no-repeat;
    background-size: 718px 250px;
　　 background-position: center center;
    position: absolute;
    margin-top: 125px;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 100vw;
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .title {
      width: 288px;
      height: 79px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.1;
      letter-spacing: 0.63px;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 100px;
    }
    
    .subTitle {
      width: 290px;
      height: 72px;
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.53px;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 194px;
    }

    .bg_img {
      display: none;
    }
  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 100vw;
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .title {
      width: 330px;
      height: 104px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 100px;
    }
    
    .subTitle {
      width: 327px;
      height: 72px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 224px;
    }

    .bg_img {
      display: none;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 402px;

    .title {
      width: 564px;
      height: 96px;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 134px;
    }
    
    .subTitle {
      width: 400px;
      height: 48px;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      position: absolute;
      margin-top: 272px;
    }

    .bg_img {
      width: 777px;
      height: 302px;
      display: inline-block;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_diversity_bg_between_768_and_1150@2x.png) center no-repeat;
      background-size: 777px 302px;
  　　 background-position: center center;
      position: absolute;
      margin-top: 100px;
    }
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .bg_img {
      width: 797px;
      height: 252px;
      display: inline-block;
      background: url(https://getmiles.s3.amazonaws.com/assets/web_v2/jobs_page/jobs_diversity_bg@2x.png) center no-repeat;
      background-size: 797px 252px;
  　　 background-position: center center;
      position: absolute;
      margin-top: 125px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`

