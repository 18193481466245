import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  padding: 0; 

  .miles_ui_about_page_investors_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }

  .miles_ui_about_page_investors_container {
    width: 1440px;
    height: auto;
    flex-direction: row;
  }

  .miles_ui_about_page_investors_title {
    width: fit-content;
    margin: auto;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.13px;
    color: #2d2f3b;
  }

  .miles_ui_about_page_investors_picture {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 133px;
    margin-bottom: 216px;
  }

  .miles_ui_about_page_investors_picture_img {
    height: 290px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_about_page_investors_title {
      font-size: 24px;
      letter-spacing: 0.75px;
    }

    .miles_ui_about_page_investors_picture {
      margin-top: 75px;
      margin-bottom: 116px;
    }

    .miles_ui_about_page_investors_picture_img {
      height: 723px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_investors_title {
      font-size: 24px;
      letter-spacing: 0.75px;
    }

    .miles_ui_about_page_investors_picture {
      margin-top: 75px;
      margin-bottom: 116px;
    }

    .miles_ui_about_page_investors_picture_img {
      height: 723px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_investors_title {
      font-size: 32px;
      letter-spacing: 1px;
    }

    .miles_ui_about_page_investors_picture {
      margin-top: 73px;
      margin-bottom: 178px;
    }

    .miles_ui_about_page_investors_picture_img {
      height: 357px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;