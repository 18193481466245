import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Brands_you_love } from "../../components_jp/miles_ui_box_brands_you_love";
import { AsYouType } from 'libphonenumber-js';
import { Send_download_link } from "../../components_jp/miles_ui_box_send_download_link";
import { We_care_your_privacy } from "../../components_jp/miles_ui_box_we_care_your_privacy";
import { Tab_carousel } from "../../components_jp/miles_ui_box_tab_carousel";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";
import {
  jp_phone_regex,
} from '../../utils/phoneNumValidator';
import { Styled_home_wrap } from "./styles";
import { store } from "../../Store";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import { dialogFormStyle } from "./dialogFormStyle";

let firstRender = true;

const {
  apple_store_src,
  google_play_store_src,
  jp_home_page_assets: assets,
  partner_page_assets,
} = config;

const {
  brands_you_love_img,
  we_care_your_privacy_img,
} = assets;

const GlobalCss = withStyles({
  "@global": {
    ".partner_form_dialog": {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgb(45, 47, 59, 0.7)",
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100vw",
      },
      "& .MuiDialog-paper": {
        padding: "0",
        margin: "0",
        maxHeight: "calc(100% - 15px)",
        "& .form_wrap": dialogFormStyle,

        "@media only screen and (max-width : 360px)": {
          margin: "10px",
        },
      }
    },
  },
})(() => null);

const MilesWeb_HomePage_JP: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_HomePage_JP -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_HomePage_JP -- did mount");

    firstRender = false;
  }, []);

  const [values, setValues] = useState({
    name: "",
    business_title: "",
    email: "",
    phone_number: "",
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errs, setErrs] = useState({
    name: false,
    business_title: false,
    email: false,
    phone_number: false,
    submissionErr: false
  });

  const [position, setPosition] = useState(0);
  
  const inputEl_on_dialog = useRef<HTMLInputElement>(null);
  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      // TIP: JP phone number may longer than 10
      // if (currentPhoneNum.length > 10) {
      //   currentPhoneNum = currentPhoneNum.slice(0, 10); 
      // }
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : new AsYouType("JP").input(currentPhoneNum);
      

      const selectionStart = inputEl_on_dialog!.current!.selectionStart as number;

      let currentPosition = milesUtils.getCurrentPosition_JP(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_miles_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl_on_dialog.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleUpdateValues = useCallback(
    (event: React.ChangeEvent<HTMLFormElement | HTMLTextAreaElement | HTMLInputElement>) => {
      setValues({
        ...values,
        ...{ [event.target.name]: event.target.value },
      });
      event.preventDefault();
    },
    [values],
  );

  const handleSubmitPartnerData = () => {
    console.log("handleSubmitPartnerData")
    setErrs({
      ...errs,
      business_title: !values.business_title.trim(),
      name: !values.name.trim(),
      email: !milesUtils.isValidEmailAddress(values.email),
      phone_number: !jp_phone_regex().all.test(phoneNumber.replace( /[^0-9]/g, '' ))
    })

    let no_err = values.business_title.trim()
      && values.name.trim()
      && milesUtils.isValidEmailAddress(values.email)
      && jp_phone_regex().all.test(phoneNumber.replace( /[^0-9]/g, '' ))
    
    if (
      (values.name && values.business_title && values.email && phoneNumber)
      && no_err) {
      store.handlePartnerFormData({
        ...values,
        phone_number: phoneNumber,
      }, "dialog_form")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (inputEl_on_dialog.current) {
      inputEl_on_dialog.current!.selectionStart = position;
      inputEl_on_dialog.current!.selectionEnd = position;
    }
  }, [position, phoneNumber]);

  const [openPartnerForm, setOpenPartnerForm] = React.useState(false);

  const handleClickOpen_partnerForm = () => {
    setOpenPartnerForm(true);
  };

  const handleClose_partnerForm = () => {
    store.update_partner_page_form_status("dialog_form", "not_start");
    setValues({
      name: "",
      business_title: "",
      email: "",
      phone_number: "",
    });
    setPhoneNumber("");
    setErrs({
      name: false,
      business_title: false,
      email: false,
      phone_number: false,
      submissionErr: false
    });
    setOpenPartnerForm(false);
  };

  return (
    <Styled_home_wrap>
      <GlobalCss />

      <div
        id="hero_01_wrap"
        className={`hero_01_wrap`}
      >
        <Send_download_link
          apple_store_icon_img={assets.apple_store_icon_img}
          google_play_icon_img={assets.google_play_icon_img}
          apple_store_src={apple_store_src}
          google_play_store_src={google_play_store_src}
        />
        <picture className="hero_1_picture">
          <source className="hero_1_img" media="(max-width : 360px)" srcSet={assets.hero_1_360} sizes="100vw"></source>
          <source className="hero_1_img" media="(min-device-width : 361px) and (max-device-width : 768px)" srcSet={assets.hero_1_360} sizes="100vw"></source>
          <source className="hero_1_img" media="(min-width : 361px) and (max-width : 768px)" srcSet={assets.hero_1_600_ipad_mini} sizes="100vw"></source>
          <source className="hero_1_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.hero_1_768_to_1150}></source>
          <source className="hero_1_img" media="(min-width : 1151px) and (max-width : 1439px)" srcSet={assets.hero_1_1280}></source>
          <source  className="hero_1_img" media="(min-width : 1440px)" srcSet={assets.hero_1_1440}></source>
          <img className="hero_1_img" alt={`hero_1`}></img>
        </picture>

      </div>

      <div id="hero_02_box_wrap" className={`hero_02_box_wrap`}>
        <div
          id="hero_02_wrap"
          className={`hero_02_wrap`}
        >
          <div id="hero_02_content_wrap" className="hero_02_content_wrap fadeIn">
            <div className="hero_02_title">マイルをためる</div>
            <div className="hero_02_sub_title">すべての移動手段で自動的にマイルがたまる</div>
            <div className="hero_02_sub_title">エコな移動手段だとマイルがたまりやすい</div>
            
            <div className="hero_02_assets_wrap">
              <div className="hero_02_assets_wrap_01">
                <img src={assets.icon_green_train_img} alt="icon_green_train_img"></img>
                <img src={assets.icon_blue_car_img} alt="icon_blue_car_img"></img>
                <img src={assets.icon_yellow_car_img} alt="icon_yellow_car_img"></img>
                <img className="last" src={assets.icon_blue_airplan_img} alt="icon_blue_airplan_img"></img>
              </div>

              <div className="miles_ui_screen_larger_than_1150">
                <div className="hero_02_assets_wrap_02">
                    <img src={assets.icon_green_ship_img} alt="icon_green_ship_img"></img>
                    <img src={assets.icon_walk_img} alt="icon_walk_img"></img>
                    <img src={assets.icon_run_img} alt="icon_run_img"></img>
                    <img className="last" src={assets.icon_ski_img} alt="icon_ski_img"></img>
                  </div>
              </div>

              <div className="miles_ui_screen_768_to_1150">
              <div className="hero_02_assets_wrap_02">
                  <img src={assets.icon_walk_img} alt="icon_walk_img"></img>
                  <img className="last" src={assets.icon_run_img} alt="icon_run_img"></img>
                </div>
              </div>

              <div className="miles_ui_screen_less_than_768_px">
                <div className="hero_02_assets_wrap_02">
                  <img src={assets.icon_walk_img} alt="icon_walk_img"></img>
                  <img className="last" src={assets.icon_run_img} alt="icon_run_img"></img>
                </div>
              </div>
            </div>
          </div>

          <picture className="hero_2_picture">
            <source className="hero_2_img" media="(max-width : 360px)" srcSet={assets.hero_2_360}></source>
            <source className="hero_2_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.hero_2_600_ipad_mini}></source>
            <source className="hero_2_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.hero_2_768_to_1150}></source>
            <source className="hero_2_img" media="(min-width : 1151px) and (max-width : 1439px)" srcSet={assets.hero_2_1280}></source>
            <source  className="hero_2_img" media="(min-width : 1440px)" srcSet={assets.hero_2_1440}></source>
            <img className="hero_2_img" alt={`hero_2`}></img>
          </picture>
        </div>
      </div>

      <div id="hero_04_box_wrap" className={`hero_04_box_wrap`}>
        <div
          id="hero_04_wrap"
          className={`hero_04_wrap`}
        > 
          
          <div id="hero_04_content_wrap" className="hero_04_content_wrap fadeIn">
            <div className="hero_03_title">マイルをつかう</div>
            <div className="miles_ui_screen_larger_than_1150">
              <div className="hero_03_sub_title">ためたマイルは、お得に商品やサービ</div>
              <div className="hero_03_sub_title">スを購入できる特典やギフトカードな</div>
              <div className="hero_03_sub_title">どへ交換</div>
            </div>

            <div className="miles_ui_screen_768_to_1150">
              <div className="hero_03_sub_title">ためたマイルは、お得に商品やサービスを購入できる特典やギ</div>
              <div className="hero_03_sub_title">フトカードなどへ交換</div>
            </div>

            <div className="miles_ui_screen_less_than_768_px">
              <div className="hero_03_sub_title">すべての移動手段で自動的にマイルがた</div>
              <div className="hero_03_sub_title">まる。エコな移動手段だとマイルがたま</div>
              <div className="hero_03_sub_title">りやすい</div>
            </div>

            <img className="redeem_miles_icon_img" src={assets.redeem_miles_icon_img} alt="icon_green_ship_img"></img>
          </div>

          <picture className="hero_3_picture">
            <source className="hero_3_img" media="(max-width : 360px)" srcSet={assets.hero_3_360}></source>
            <source className="hero_3_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.hero_3_600_ipad_mini}></source>
            <source className="hero_3_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.hero_3_768_to_1150}></source>
            <source className="hero_3_img" media="(min-width : 1151px) and (max-width : 1439px)" srcSet={assets.hero_3_1280}></source>
            <source  className="hero_3_img" media="(min-width : 1440px)" srcSet={assets.hero_3_1440}></source>
            <img className="hero_3_img" alt={`hero_3`}></img>
          </picture>

        </div>
      </div>

      <Brands_you_love
          brands_you_love_v3_2500_img={brands_you_love_img}
          brands_you_love_v3_600_img={brands_you_love_img}
          brands_you_love_v3_360_img={brands_you_love_img}
      />

      {/* <Partners_we_worked_with handleOpenPartnerForm={handleClickOpen_partnerForm} /> */}
      {/* <div className="miles_ui_home_pre_footer_blankSpace"></div> */}

      <Tab_carousel
        carousel_raffle_img={assets.carousel_raffle_img}
        carousel_giftcard_img={assets.carousel_giftcard_img}
        carousel_donation_img={assets.carousel_donation_img}
        carousel_challenges_img={assets.carousel_challenges_img}
        active_raffle_img={assets.active_raffle_img}
        active_giftcard_img={assets.active_giftcard_img}
        active_donation_img={assets.active_donation_img}
        active_challenges_img={assets.active_challenges_img}
        fade_raffle_img={assets.fade_raffle_img}
        fade_Challenges_img={assets.fade_Challenges_img}
        fade_gift_card_img={assets.fade_gift_card_img}
        fade_Donation_img={assets.fade_Donation_img}
        carousel_arrow_left_img={assets.carousel_arrow_left_img}
        carousel_arrow_right_img={assets.carousel_arrow_right_img}
      />

      <We_care_your_privacy we_care_your_privacy_img={we_care_your_privacy_img} />
      <div className="miles_ui_home_pre_footer_blankSpace"></div>

      <Dialog
        className="partner_form_dialog"
        open={openPartnerForm}
        onClose={handleClose_partnerForm}
        aria-labelledby="form_dialog_on_partner_page"
        disableBackdropClick={true}
      >
        {
            store.partner_page_form_status.dialog_form === "sent"
            ? (
              <div className="form_wrap">
                <div className="partner_page_dialog_header">
                  <span className="partner_page_dialog_header_title"></span>

                  <button className="partner_page_esc_icon_btn_wrap" onClick={handleClose_partnerForm}>
                    <img className="partner_page_esc_icon_img" src={assets.jp_home_page_esc_icon_img} alt={`jp_home_page_esc_icon_img`}></img>
                  </button>
                </div>

                <img className="partner_page_pre_footer_form_asset" src={partner_page_assets.partner_page_form_sent_success_asset_img} alt={`partner_page_form_sent_success_asset_img`}></img>
                <div className="partner_page_pre_footer_form_success_text">お問い合わせいただきありがとうございました。折り返し、担当者よりご連絡いたしますので、今しばらくお待ちください。</div>
              </div>
            ) : (
              <div className="form_wrap">

              <div className="partner_page_dialog_header">
                <span className="partner_page_dialog_header_title">以下ご入力お願い致します。</span>

                <button className="partner_page_esc_icon_btn_wrap" onClick={handleClose_partnerForm}>
                  <img className="partner_page_esc_icon_img" src={assets.jp_home_page_esc_icon_img} alt={`jp_home_page_esc_icon_img`}></img>
                </button>
              </div>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">お名前</span>
                  <input
                    className={`${errs.name ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleUpdateValues}
                    required
                    placeholder="お名前"
                  />
                  <span className={errs.name ? "show_err_info" : "hide_err_info"}>有効なお名前をご入力ください</span>
                </label>

                <label className="input_inner_section input_box_02">
                  <span className="label_text">会社名</span>
                  <input
                    className={`${errs.business_title ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="business_title"
                    value={values.business_title}
                    onChange={handleUpdateValues}
                    required
                    placeholder="会社名"
                  />
                  <span className={errs.business_title ? "show_err_info" : "hide_err_info"}>有効な会社名をご入力ください</span>
                </label>

                <label className="input_inner_section input_box_03">
                  <span className="label_text">会社のメールアドレス</span>
                  <input
                    className={`${errs.email ? "input_err" : "no_err"} partner_preFooter_form_input input_02`}
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleUpdateValues}
                    required
                    placeholder="会社のメールアドレス"
                  />
                  <span className={errs.email ? "show_err_info" : "hide_err_info"}>有効な会社のメールアドレスをご入力ください</span>
                </label>

                <label className="input_inner_section input_box_04">
                  <span className="label_text">お電話番号</span>
                  <input
                    ref={inputEl_on_dialog}
                    className={`${errs.phone_number ? "input_err" : "no_err"} partner_preFooter_form_input input_03`}
                    type="tel"
                    name="phone_number"
                    required
                    value={phoneNumber}
                    onChange={handleChangePhoneNum}
                    onKeyDown={handleOnKeyDown}
                    placeholder="例) 03-1234 - 5678"
                  />
                  <span className={errs.phone_number ? "show_err_info" : "hide_err_info"}>有効な電話番号をご入力ください</span>
                </label>

                <div className="tip_text">Milesよりご連絡させていただくことにご同意いただいたものと致します。</div>

                <button className={`partner_page_pre_form_cta_btn miles_ui_btn_primary ${store.partner_page_form_status.dialog_form === "sending" ? "loading_status" : ""}`} onClick={() => store.partner_page_form_status.dialog_form === "sending" ? {} : handleSubmitPartnerData()}>送信</button>
                <span className={store.partner_page_form_status.dialog_form === "server_error" ? "show_err_info submissionErr" : "hide_err_info"}>エラーが発生しました。<br></br>しばらく時間を置いてから再度お試しください。</span>
              </div>
            )
          }
      </Dialog>
    </Styled_home_wrap>
  );
});

export default MilesWeb_HomePage_JP;
