import React from "react";
import { Styled_Super_wrap } from "./styles";

interface Props {
  img_src: string;
  title: string;
}

export const Img_box_with_title_02: React.FC<Props> = ({
  img_src,
  title,
}) => {
  return (
    <Styled_Super_wrap>
      <p className="title">{title}</p>
      <p className="subTitle">
      As we are building a brand that is loved by users of all backgrounds, we start with the team at Miles first.
      </p>
      <div className="bg_img"></div>
    </Styled_Super_wrap>
  );
};
