import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;

  .ciq-root{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid red;"
    }
  }
  
  .ciq-container{
    width: auto;
    height: auto;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    align-items: center;
    flex-direction: row;
    flex-flow: row wrap;
    position: relative;
    padding: 0px;
  }

  .card-unit {
    display: block;
    margin-top: 101px;
  }

  .left {
    margin-right: 139px;
    display: block;
    flex: 1;
  }

  .right {
    margin-left: 139px;
    display: block;
    flex: 1;
  }

  .showMoreCtaWrapper {
    margin-bottom: 202px;
  }

  .press_page_partner_image_asset_10 {
    box-shadow: 0px -1px 17px 0 #e6e6e8;
  }


  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .showMoreCtaWrapper {
      margin-bottom: 135px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .showMoreCtaWrapper {
      margin-bottom: 135px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .showMoreCtaWrapper {
      margin-bottom: 138px;
    }

    .ciq-container {
      display: flex;
      gap: 40px 0;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .showMoreCtaWrapper {
      margin-bottom: 195px;
    }

    .ciq-container {
      display: flex;
      gap: 40px 0;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .ciq-container {
      display: flex;
      gap: 40px 0;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .ciq-container {
      display: flex;
      gap: 40px 0;
    }
  }
`;