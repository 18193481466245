import config from "../../utils/config";
import React from 'react';

const {
    help_page_assets: assets,
    currentSupportEmailAddress,
} = config;


type AnswerType = {
    id: number;
    text: React.ReactNode;
};

type QuestionType = {
    id: number;
    text: string;
    slug: string;
    answer_array: AnswerType[];
};

type SubcategoryType = {
    name: string;
    question_array: QuestionType[]
};


type CategoryType = {
    id: number;
    name: string;
    slug: string;
    user: string;
    image_src: string;
    subcategories: SubcategoryType[];
};

function flatten(categoryDetail: CategoryType) {
    const nested_questions = categoryDetail.subcategories.map(subcategory => subcategory.question_array);
    const flattened_questions: typeof nested_questions[0] = Array.prototype.concat.apply([], nested_questions);
    return {
        id: categoryDetail.id,
        name: categoryDetail.name,
        slug: categoryDetail.slug,
        user: categoryDetail.user,
        image_src: categoryDetail.image_src,
        question_array: flattened_questions,
    };
}


const RAW_DATA_2_LEVEL = {
    "response": {
        "user": [
            {
                "id": 1,
                "name": "Earning Miles",
                "slug": "earning_miles",
                "user": "user",
                "image_src": assets.help_page_category_icon_1,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 11,
                                "text": "Trips",
                                "slug": "trips",
                                "answer_array": [
                                    {
                                        "id": 111,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How many miles are earned for each trip?</span></p>
                                                    <ul>
                                                        <li>For users utilizing the free version, each trip can earn you up to 25 miles! Each trip itself is worth between 10 and 25 miles depending on your trip plan, but you can earn double miles by watching a short video.</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>For Miles+ members, for trips 25 miles or less, you will earn 25 miles for the trip. If the trip is longer than 25 miles, you will earn the full trip mileage, up to 100 miles and 10% thereafter. You can also earn double miles for your trips by selecting the purple button to boost your trip.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>How many trips can I earn daily?</span></p>
                                                    <ul>
                                                        <li>This depends on which Miles plan you currently utilize. Here is the breakdown:
                                                            <ul>
                                                                <li>No data sharing, Free version: up to 10 trips each day</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Data sharing, Free version: up to 10 trips each day</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Miles+: up to 20 trips each day</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>Do I have to log the trips?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>No. Trips are automatically logged. However, users utilizing the free version of Miles will need to manually claim trips. Users utilizing Miles+ will have trips claimed automatically. Users have up to 7 days to claim trips for users utilizing the free version. Miles+ users will have trips claimed automatically, and will be available for 30 days.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How long before my trip appears?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Typically it takes around 30 mins between trip completion and the trip showing up in the app and miles getting awarded. On certain occasions (such as poor mobile signal, bad or slow WiFi network, low battery mode) the system may need a few additional minutes to process the trip, which may result in the trips showing up in the app between 1-4 hours. If you are missing any of your trips beyond the 4 hour time window, please please log out and log back into your account. If the trip does not show after logging back in, please reach out to us through the “Report An Issue” feature and we will get back to you as soon as possible.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How do I claim a trip with my free subscription?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>To claim a trip, locate your trip on the trips page. Select the green button on the bottom right corner of the trip to claim the base mileage. If you would like to earn double miles for this trip, you can select the white button offering double the miles for watching a short video. Once you have claimed your trip, your miles will reflect in your account.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Will I earn miles if I don’t claim a trip?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>If you are a Miles member utilizing the free subscription, you will not earn miles for your trips if you do not claim your trips. If you are a Miles+ member, your trips are automatically claimed, so you will not need to take this action manually.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Can I see all my past trip details?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can see your past trips in the Trips page of the app. Users utilizing the free subscription will have access to a 7 day trip history within this page. Users utilizing Miles+ will have access to a 30 day trip history.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How long do I have to claim my trips?</span></p>
                                                    <ul>
                                                        <li>Users have up to 7 days to claim trips for users utilizing the free version. If trips have not been claimed within 7 days, the trips will expire.</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>For Miles+ users, trips claim automatically, and will be available for 30 days.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How do I boost a trip?</span></p>
                                                    <ul>
                                                        <li>To boost a trip, locate the trip in the trips page. Once you have located the trip you would like to boost, tap the Boost button on the bottom of the trip card and watch your miles double!</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>Please note Trip Boosts are only available for Miles+ Users</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>Why do I have a missing trip and missing miles? How can I report it?</span></p>
                                                    <ul>
                                                        <li><p><span>There is a small chance you could have trips and miles that are not automatically logged. The following could be the reasons for such missing trips.</span></p></li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>No phone signals: This may be the most common reason for a missing trip.</span></p>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            Location Services:
                                                            <ul>
                                                                <li>
                                                                    <p><span><b>For iOS devices</b>, please set Location Services to ‘Always Allow’. Selecting ‘Never’ or ‘While Using the App’ will result in the app not functioning the way it should.Your location information will not be shared with third parties.To
                                                                                                            set Location Services go to your phone Settings → Select Miles from the list of apps → Tap Motion & Fitness to allow this setting → Select Location Services (On) → Miles.</span></p>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span><b>For Android devices</b>, please set Location Services to 'Allow’. Location Services can also be updated by heading to Settings → Applications → Miles → Permissions → Location (toggle ‘on’) → Physical Activity (toggle ‘on’).</span></p>
                                                                </li>
                                                            </ul>
                                        
                                                        </li>
                                        
                                        
                                                        <p><span>Staying logged in: Please ensure you haven’t unintentionally logged out of the app.</span></p>
                                        
                                        
                                                        <p><span>App version: We periodically release updates to the app to add new features, fix issues or improve the experience and performance. Please update the app to ensure you get the best possible experience.</span></p>
                                        
                                                        <p><span>OS version: Whenever available, please update to the latest OS version that ensure support for functioning of the app.</span></p>
                                        
                                                        <p><span>Internet connection: The app works perfectly in an offline mode i.e. without an internet connection. When using in an offline mode, you may need to connect to the internet (wi-fi or data plan) at least once in 24 hours to earn miles for all the trips
                                                                                                        taken in offline mode during that 24 hour period.</span></p>
                                        
                                                        <p><span>If each of these conditions are met and you still didn’t see a trip you should have, please report it. You can do so by using the ‘Send Feedback’ button towards the bottom of the Account page or email us directly at support@getmiles.com.</span></p>
                                        
                                        
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Can I use the Miles app without turning on location services?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>The Miles app will not be able to properly register trips without location permissions. However, other features, such as Trivia, Surveys, Games, Referrals, Promo Codes, Shop & Earn offers, etc. will still be available.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why do I see my trip split into two or more?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>A single trip may be split into two or more trips if it is made up of two or more different modes or if the trip had a 5 minute period where you were stationary (not moving).</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 21,
                                "text": "Shop",
                                "slug": "shop",
                                "answer_array": [
                                    {
                                        "id": 221,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I earn miles for Shopping?</span></p>
                                                    <ul>
                                                        <li><span><p>You can earn miles for shopping by utilizing any of the offers from the “Miles Back” category. In the offer, there will be a green button at the bottom of the offer to activate the offer. Once activated, you will be redirected to the offer page. Please be sure to use this link, as the link will help ensure that you are awarded your miles. Additionally, please be sure not to use any private browsers or VPNs, as this may affect the ability for the partnering brand to verify the purchase. </p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How long after purchasing will I receive my bonus miles?</span></p>
                                                    <ul>
                                                        <li><span><p>For purchases made from the Shop & Earn categories, verification from partnering brands may take up to 30 days. Once the verification of purchase has been completed, the Miles will be deposited into your account automatically.</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Do I earn miles for Perks as well?</span></p>
                                                    <ul>
                                                        <li><span><p>If the offer is listed in the Perk category, there will not be bonus miles associated with the offer. Only offers listed in the “Miles Back” category will be associated with bonus miles.</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Are all offers in the Shop tab associated with bonus miles?</span></p>
                                                    <ul>
                                                        <li><span><p>No, only offers in the “Miles Back” category will be associated with bonus miles.</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Do Perks expire?</span></p>
                                                    <ul>
                                                        <li><span><p>Yes, Perks do expire. Each perk will be available for 24 hours, starting from the release at 12:00pm. After a new Perk has been released, the previous perk will no longer be available.</p></span></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How frequently do Perks change??</span></p>
                                                    <ul>
                                                        <li><span><p>Perks change daily, and are updated at 12:00pm. Once the perk has been updated, the previous perk will no longer be available for use. We recommend users to utilize the perk as soon as possible, as they are only offered for a limited time.</p></span></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 31,
                                "text": "Play",
                                "slug": "play",
                                "answer_array": [
                                    {
                                        "id": 331,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I earn miles for playing games?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can earn miles for playing games by opening your Miles app to the Play Tab, Selecting “Play Now,” adding the game you would like to play to the “My Games” tab, downloading the specific game, and playing the different levels! Please be sure to enable the tracking setting to ensure you receive credit for your games.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How long after playing a game will I receive my miles?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>This varies between games, as different earned miles have different game requirements. However, it may take up to 3 days after reaching the listed goal to receive your miles.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Is there a limit to how many games I can play?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>There is no limit to how many games you can play. However, All of the games will have listed goals for certain earned miles. Games played after reaching those goals will not be associated with additional miles.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>Why didn’t I get credit for a game that I played?</span></p>
                                                    <ul>
                                                        <li>This will vary depending on the specific game. If you notice that you are missing Miles, please submit a ticket to our <a href="https://adjoe.zone/legal/en/support_web.html?UserUUID=06e0e675-4dea-441c-9afc-0286bfc24b41&SDKHash=287a3b9fcbca1b366683e5e8c58ac9f8&user_id=2709208&idfa=ED4CC3CC-D35D-403D-B296-DE9F24428572&__platform__=ios">Game Partner</a>                    for more information and to request any missing miles.</li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>Please also ensure that you are not using a VPN or a private browser, as this will affect the ability for Miles to be able to automatically credit your account. Also, please ensure that the game is listed under the My Games section before you start playing. Additionally, If you downloaded the game from the Miles Play Portal, but prior to opening the downloaded game app, had interacted with a separate ad for the game, this will cause your game not to register with your Miles app.</span></p>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How can I track all the games I have installed through the Miles app?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>All of the games that have been added through Miles will be listed in the “My games” tab when redirected from the Miles app.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why doesn’t the game I installed show up in the My Games section?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You were probably just too fast! Please wait for the app to finish downloading to your device and refresh the offerwall. Also please do not forget to allow the app that you downloaded to track you across apps and websites owned by other companies. That would help our game partner to make sure that we could identify you to see if you completed a challenge or not. Please keep in mind that you have to get an app from the Apple Store/Google Play Store after you select it from your Playtime game feed. If you have not downloaded the game from the Apple Store/Google Play Store or you went directly to the Apple Store/Google Play Store for the download without selecting it from the game feed, we cannot reward you.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>What kinds of games can I play?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Available games are subject to change. The available games will be listed when you are redirected from the Play Tab.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Where can I find the goals for earning miles for my game?</span></p>
                                                    <ul>
                                                        <li>Open your Miles app to the Play Tab</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Select “Play Now”</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Upon redirection, select the tab on the web page titled “My Games”</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Locate the specific game</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Select “More” on the listed game</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>Each game goal and the associated bonus miles will be listed here.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 41,
                                "text": "Trivia",
                                "slug": "trivia",
                                "answer_array": [
                                    {
                                        "id": 441,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I earn miles answering Trivia questions?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can earn miles for answering Trivia by opening your Miles app to the Answer Tab, and answering the questions in the Trivia section. New trivia questions are offered daily!</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Do I earn miles for just attempting the questions?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You will earn the listed miles for answering the question correctly. If you attempt a question and do not select the correct answer, you will still be awarded some miles for the attempt.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How many questions can I answer everyday?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You will be able to answer at least 5 questions daily. The longer your streak is, the more questions you can answer!</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What is a Trivia streak?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Your Trivia streak is the number of consecutive days you have participated in Trivia. If you do not participate in Trivia before 11:59 pm on a calendar date, your Trivia streak will reset.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why did my streak reset?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Trivia streaks are calculated based on the number of dates in which your account pulls a trivia question from the system for you to answer. If no question was pulled from the system during a calendar date, the streak will reset on the next calendar day.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What is a Streak Freeze?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Streak Freeze is a way to freeze your streak in the case that you miss a day of trivia, but want to maintain your streak. If you have any available streak freezes, but you missed a day, your streak freeze will be applied, and your streak will be maintained for an extra day.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 51,
                                "text": "Survey",
                                "slug": "survey",
                                "answer_array": [
                                    {
                                        "id": 551,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I earn miles from taking a survey?</span></p>
                                                    <ul>
                                                        <li>To earn miles on Surveys:
                                                            <ul>
                                                                <li>Click on the Survey icon on the Homepage</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Scroll through the page and find a survey you would like to participate in</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Fill out the survey</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why didn’t I earn miles for my survey?</span></p>
                                                    <ul>
                                                        <li>There are a few reasons why you may have not earned miles for your survey:
                                                            <ul>
                                                                <li>You did not meet the survey qualifications or the target audience</li>
                                                            </ul>
                                                            <ul>
                                                                <li>You did not complete the survey and exited out of the survey prior to completion</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>A VPN was detected</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why did I only receive partial miles for my survey?</span></p>
                                                    <ul>
                                                        <li>If you completed the survey and only see partial miles awarded, that means the answers you provided did not match the needs or interest of the survey. You will not get full credit for the survey but will receive partial credit for the
                                                            attempt.</li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>You must complete the full survey without getting disqualified to receive full credit.</span></p>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>When are my miles awarded for my survey?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Miles are awarded within 12 hours of completion. In most cases, miles are awarded instantaneously. If you completed the survey and did not receive any miles after 12 hours of completion, please reach out to our customer support team at support@getmiles.com with a screenshot of the survey you completed.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why did I get disqualified from the survey?</span></p>
                                                    <ul>
                                                        <li>Not everyone will qualify for a survey. While taking a survey, you may experience your survey ending early. This is called a survey disqualification. This happens when your answers and experiences did not match the target audience the
                                                            survey was looking for.</li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>If your answers do not match the type of demographics they are targeting, your answers will be excluded from the survey results and the survey will end. Please make sure you are not speeding through the survey and thoroughly answering the question to minimize disqualification.</span></p>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why did an error occur while taking the survey?</span></p>
                                                    <ul>
                                                        <li>Sometimes while taking a survey, an error or technical difficulties may occur. This may be due to internet connectivity issues or if you are using a VPN. If you do encounter this, please contact our customer support team with the survey
                                                            name or ID and any relevant screenshots.</li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>Please note, if you are using a VPN you may not get any points at all. We do not recommend having a VPN while taking surveys.</span></p>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 61,
                                "text": "Promo Code",
                                "slug": "promo_code",
                                "answer_array": [
                                    {
                                        "id": 661,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I enter a promo code?</span></p>
                                                    <ul>
                                                        <li>Here are a few instructions on how to input a special code:
                                                            <ul>
                                                                <li>Open the Miles app</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Tap the Home tab</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Tap on the Profile icon located in the top left corner</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Tap Promo Code</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Enter the code as given</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 71,
                                "text": "Buy Miles",
                                "slug": "buy_miles",
                                "answer_array": [
                                    {
                                        "id": 771,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How can I purchase miles?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can purchase miles by opening your Miles app to the Home Tab, scrolling to where it states “Earn Miles,” and select the coin icon that is labeled “Buy.” There will be different denominations of miles available to purchase.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Where are the Miles I purchased?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>When making a purchase from the Buy Miles Campaign, the verification process takes 2-3 business days. If the purchase was authorized, the Miles will be deposited into your account. If your order was canceled because it was not authorized by your bank, you will receive a full refund.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 81,
                                "text": "Boost",
                                "slug": "boost",
                                "answer_array": [
                                    {
                                        "id": 881,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How can I start my boost?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can start your boost by selecting the lightning button in the top right corner of your Home Tab, Selecting “Start my Boost,” and selecting “Start Earning.” Once the timer has started, your earnings from the next 30 minutes will be doubled!</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How long does the boost last?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>From the time you begin your boost, you will have 30 minutes of 2x the earnings!</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Are all earning features included when I boost?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Most, but not all. Any miles earned from the Miles Back offers, Buy Miles feature, or from Referring friends will not be included.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            }
                        ]
                    },
                ],
            },
            {
                "id": 2,
                "name": "Redeeming Miles",
                "slug": "redeeming_miles",
                "user": "user",
                "image_src": assets.help_page_category_icon_2,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 12,
                                "text": "Gift Cards",
                                "slug": "gift_cards",
                                "answer_array": [
                                    {
                                        "id": 112,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Why is there a limit on gift card purchases?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Due to the high volume of users and the popularity of Gift Card rewards, we have placed a monthly spending limit to help ensure everyone has an opportunity to purchase a Gift Card reward.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How frequently can I redeem Cash and Gift Card rewards?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Miles users can redeem Cash and Gift Card rewards once a week for the free versions, and twice a week for Miles+. Miles users utilizing the free version will have a maximum redemption of 20,000 miles each month. Miles+ users have a maximum spending limit of 34,000 each month.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What do I do if the retailer doesn’t accept my Gift Card?</span></p>
                                                    <ul>
                                                        <li>Any issues involving a retailer denying a reward you redeemed on Miles will need to be resolved with the retailer directly. Here are some suggestions that have helped others:
                                                            <ul>
                                                                <li>Ask the cashier to manually enter the gift card number and PIN, especially if a barcode isn't available.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Seek help from a manager who may know more about applying the gift card credit to your purchase.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>If redeeming online, ensure the correct card number and PIN is entered.</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>If all else fails, check with the retailer's customer support team for more information. They may have more redemption tips and instructions. All restrictions and exceptions are listed on the reward and can vary from retailer to retailer.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why are Amazon and PayPal redemptions more expensive to redeem?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>We work with gift card providers to bring you the best rates possible. While some gift card rates are higher than others, our development teams are working on more ways to award you with miles.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Do gift cards expire?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Gift cards that are issued do not expire. However, our system does not keep the gift card data for more than 60 days. Please be sure to save your gift cards and/or gift card codes to ensure you have access to be able to redeem.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 22,
                                "text": "PayPal",
                                "slug": "paypal",
                                "answer_array": [
                                    {
                                        "id": 222,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I redeem miles for Paypal credit?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>To redeem your miles for PayPal credit, open your Miles app to the Home Tab, scroll to where it states “Redeem Miles,” and select the Cash option. All PayPal redemption options will be listed here. Once you have completed your purchase, you will be able to connect your PayPal account for the payout to be transferred to your PayPal account. This process may take up to 3 days to complete.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Why haven’t I received my PayPal payout?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>After connecting your PayPal account to Miles, it may take up to 3 days to process your redemption. If it has been longer than 3 days, please contact us at support@getmiles.com for further assistance.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 32,
                                "text": "Raffle",
                                "slug": "raffle",
                                "answer_array": [
                                    {
                                        "id": 332,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I enter a raffle?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can enter a Raffle by opening your Miles app to the Home Tab, scroll to where it states “Redeem Miles,” and select the Raffles option. Once you select the raffle you would like to participate in, you can edit how many raffle entries you would like to purchase by using the sliding scale. Once you have set the amount of entries you would like, select the green button in the bottom left corner to complete your purchase.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How does one get bonus entry tickets for a raffle?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You can get bonus entries for raffles based on your Tier Status. Gold Tier members get 2x the entries, Platinum Tier members receive 3x the entries, and Diamond Tier members receive 4x the raffle entries.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How do I claim my raffle prize?</span></p>
                                                    <ul>
                                                        <p><span>To check the status and claim prizes of the raffle, go to the "My Purchase" section within the Home tab by tapping on the profile icon in the top left corner. Within this section, you will be able to select "Raffles." 
                                                                                            Please note: Users have 2 weeks from the winner announcement to claim prizes. Prizes not claimed within this time period will be forfeited.</span></p>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 42,
                                "text": "Donations",
                                "slug": "donations",
                                "answer_array": [
                                    {
                                        "id": 442,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Are donations worth the same as gift cards?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>As we prioritize your redemption potential on desired gift cards, we have ensured that gift cards get the best rates, but we still have the opportunity to make donations to charity.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 52,
                                "text": "Other",
                                "slug": "other",
                                "answer_array": [
                                    {
                                        "id": 552,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Where can I find all the redemption options?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>All redemption options will be found in the Home Tab under “Redeem Miles.” In this section, there will be the options for Cash, Gift Cards, Raffles, and Donations.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What happens to the rewards once purchased?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Once purchased, you’d be able to see the reward within “My Purchases.” To access your purchase history, open your Miles app to the Home Tab, tap on the profile icon in the top left corner, and select “My Purchases.”</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How do the redemptions work?</span></p>
                                                    <ul>
                                                        <li>The types of redemptions include the following:
                                                            <ul>
                                                                <li>Gift Cards</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Cash</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Raffles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Donations / Charity</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>You can redeem the miles you have earned through all the trips and bonuses to purchase the rewards in the app. To purchase a reward, click on the respective reward card, click on the button at the bottom of the reward detail, and then hit ‘Confirm’ on the next screen. Also, note that Miles+ users will require fewer miles to purchase the same reward as compared to a user utilizing the free subscription.</span></p>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ],
                    },
                ],
            },
            {
                "id": 3,
                "name": "Referrals",
                "slug": "referrals",
                "user": "user",
                "image_src": assets.help_page_category_icon_3,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 13,
                                "text": "Claiming Referrals",
                                "slug": "claiming_referrals",
                                "answer_array": [
                                    {
                                        "id": 113,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>When do I get my referral rewards?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>The refer a friend rewards are awarded to qualifying members who have referrals that are verified as new Miles users who have utilized a referral code within the first 48 hours of account creation, and have earned 750 miles in the first 7 days of having an account. Users will receive Referral Bonuses automatically 7 days after the referral created their account.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ]
                    },
                ],
            },
            {
                "id": 4,
                "name": "Profile \u0026 Account Settings",
                "slug": "profile-and-account-settings",
                "user": "user",
                "image_src": assets.help_page_category_icon_4,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 14,
                                "text": "Account Set Up",
                                "slug": "account-set-up",
                                "answer_array": [
                                    {
                                        "id": 114,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Installing the app</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Download the Miles app from the App Store or Google Play Store by searching for the Miles app from ConnectIQ Labs. You can also receive a download link to your phone by visiting our home page and entering your phone number.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What are the different Plan options when setting up my Miles account?</span></p>
                                                    <ul>
                                                        <li>There are 3 different plan options available:
                                                            <ul>
                                                                <li>Miles
                                                                    <ul>
                                                                        <li>Ad-supported, no data sharing, 10 trips per day to be claimed, 10 miles earned per trip, 7 day trip history, 20k monthly spending limit, Unpaid subscription </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>Miles
                                                                    <ul>
                                                                        <li>Ad-supported, shares data, 10 trips per day to be claimed, 25 miles earned per trip, 7 day trip history, 20k monthly spending limit, unpaid subscription</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li> Miles+
                                                                    <ul>
                                                                        <li>
                                                                            <p><span>Ad-Free, No data sharing, 20 trips per day, Higher earned miles for trips over 25 miles, 30 day trip history, automatic trip claim, 2 weekly gift card redemptions, 34k monthly spending limit, Air trip miles, trip boosts to double trip miles, 15% discount on rewards, no Miles expiration from inactivity, paid subscription</span></p>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Creating an account</span></p>
                                                    <ul>
                                                        <li>Create a Miles account in the app by doing one of these two options:
                                                            <ul>
                                                                <li>Facebook - On the sign up page, tap "Login with Facebook," and follow the easy step by step workflow found there. Miles does not post anything to your Facebook account.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Email - On the sign up page, tap "Sign Up," and add a valid email address, create a valid password and enter your name.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Apple ID - On the sign up page, tap “Login with Apple ID,” and follow the easy step by step workflow associated. With this option, you will be presented the option to sign up hiding your email address. If you select this, the email associated with your account will present to us as [random letters/numbers]@appleid.privaterelay.com, but the email communications will still be sent to your email address associated with your Apple ID.</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>Setting up the app and best practices</span></p>
                                                    <ul>
                                                        <li>Once you have successfully created an account, please follow the best practices below to have the best possible experience:
                                                            <ul>
                                                                <li>Location Services:
                                                                    <ul>
                                                                        <li>For iOS devices, please set Location Services to ‘Always Allow’. Selecting ‘Never’ or ‘While Using the App’ will result in the app not functioning the way it phoneshould. Your location information will not be shared with
                                                                            third parties.To set Location Services go to your phone Settings → Select Miles from the list of apps → Tap Motion & Fitness to allow this setting → Select Location Services (On) → Miles.On iOS, you could be asked from
                                                                            time to time "Miles Has Been Using Your Location In The Background. Do You Want to Continue Allowing This?" Please select ‘Continue’ for the app to function as desired.</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>For Android devices, please set Location Services to 'Allow’. Location Services can also be updated by heading to Settings → Applications → Miles → Permissions → Location (toggle ‘on’) → Physical Activity (toggle ‘on’).</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>Notification Preferences: Preferences can be set as desired but we recommend turning them ‘On’ so that you don’t miss important updates related to new product features, bonus miles, new rewards, and many others.
                                                                    <ul>
                                                                        <li>For iOS devices,to update notifications, go to your phoneSettings → Notifications → Miles.</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>For Android devices, to update notifications, go to your phone Settings → Applications → Miles → Notifications.</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <p>Staying logged in: Make sure that you haven’t unintentionally logged out of the app.</p>
                                                            </ul>
                                                            <ul>
                                                                <p>Opening the app: Please open the app periodically to ensure there was no unintentional shutdown of the app by battery optimization or other settings.</p>
                                                            </ul>
                                                            <ul>
                                                                <p>App version: We periodically release updates to the app to add new features, fix issues or improve the experience and performance. Please make sure you keep your app up to date to ensure you get the best possible experience.</p>
                                                            </ul>
                                                            <ul>
                                                                <p>OS version: Whenever available, please update to the latest OS version to ensure support for functioning of the app.</p>
                                                            </ul>
                                                            <ul>
                                        
                                                                <p>Wi-Fi: While not essential for the app to work, enabling Wi-Fi helps in further improving performance accuracy of trips and reduces the use of battery.</p>
                                        
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 24,
                                "text": "Miles+",
                                "slug": "miles_plus",
                                "answer_array": [
                                    {
                                        "id": 224,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>What is the difference between the paid (Miles+)  and the free subscription?</span></p>
                                                    <ul>
                                                        <li>The Free subscription requires your trips to be claimed manually, allowing up to 10 trips to be claimed daily. You will have access to 7 day trip history. Free users also have a monthly spending limit of 20,000 miles.</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>The Miles+ Subscription offers automatic claims for your trips, allowing up to 20 trips to be claimed daily, with bonus miles for longer trips, trip boosts to double your trip miles and access to a 30 day trip history, 15% discount on purchases using miles, 2 weekly gift card redemptions, miles earned from flights, and an ad free platform. Miles+ users also have a monthly spending limit of 34,000 miles. Miles+ users are also exempt from miles expiring from inactivity, and data sharing.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What are the benefits of the Miles+ subscription?</span></p>
                                                    <ul>
                                                        <li>There are many benefits to the Miles+ Subscription. They include the following:
                                                            <ul>
                                                                <li>Automatic Trip Claim</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Bonus miles for long distance trips</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Earn up to 20 trips daily</li>
                                                            </ul>
                                                            <ul>
                                                                <li>2 trip boosts daily</li>
                                                            </ul>
                                                            <ul>
                                                                <li>30 day trip history</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Earning miles from Air trips</li>
                                                            </ul>
                                                            <ul>
                                                                <li>2 weekly gift card redemptions</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Access to premium Gift Cards</li>
                                                            </ul>
                                                            <ul>
                                                                <li>15% discount on all redemptions</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Higher monthly redemption limits</li>
                                                            </ul>
                                                            <ul>
                                                                <li>No ads</li>
                                                            </ul>
                                                            <ul>
                                                                <li>No data sharing</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>No Miles expiration from inactivity</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Does my Miles+ subscription automatically renew?</span></p>
                                                    <ul>
                                                        <li>Unless otherwise agreed to by Miles, subscriptions automatically renew for successive monthly or annual periods (as applicable) until canceled. Below, you can find information about auto-renewing subscriptions:
                                                            <ul>
                                                                <li>
                                                                    <p><span>App Store: <a href="https://support.apple.com/en-us/HT202039">https://support.apple.com/en-us/HT202039</a></span></p>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Google Play Store: <a href="https://support.google.com/googleplay/answer/2476088?vid=1-635770230184706449-3000457038">https://support.google.com/googleplay/answer/2476088?vid=1-635770230184706449-3000457038</a></span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>If I have subscribed to Miles+ and decide to delete my account, will my subscription automatically end?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>No. If you are in this situation, please ensure that you update your subscription through the App Store/Google Play Store.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How do I cancel/change my subscription?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>This will be done through your subscriptions within the App Store/Google Play Store.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Will my subscription be refunded if I don’t use it?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>You have the option to cancel your account at any time; however, unless prohibited by applicable law, we do not offer refunds for any unused time on a subscription or any other circumstances. Upon cancellation, your subscription will remain valid until the end of the current paid period. For instance, if you purchase an annual subscription and cancel it two months into the year, you will not receive a refund for the remaining portion of the year, but you will continue to enjoy the subscription benefits for the remaining ten months you have already paid for.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 34,
                                "text": "Tiers",
                                "slug": "tiers",
                                "answer_array": [
                                    {
                                        "id": 334,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I upgrade to the next tier?</span></p>
                                                    <ul>
                                                        <li>You can level up to the next tier by earning the following miles:
                                                            <ul>
                                                                <li>Silver Tier: earn 0 miles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Gold Tier: earn 500 miles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Platinum Tier: earn 5,000 miles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Diamond Tier: earn 10,000 miles</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>What are the benefits of each tier?</span></p>
                                                    <ul>
                                                        <li>Accelerated earnings:
                                                            <ul>
                                                                <li>Gold: +2% bonus for every mile you travel</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Platinum: +5% bonus for every mile you travel</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Diamond: +10% bonus for every mile you travel</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>Monthly Bonus Miles:
                                                            <ul>
                                                                <li>Silver: Get 1% bonus of your miles earned every month</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Gold: Get 2% bonus of your miles earned every month</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Platinum: Get 3% bonus of your miles earned every month</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Diamond: Get 4% bonus of your miles earned every month</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>Raffle Entries:
                                                            <ul>
                                                                <li>Gold: Increase your chances of winning with 2x the entries</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Platinum: Increase your chances of winning with 3x the entries</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Diamond: Increase your chances of winning with 4x the entries</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>Donation Match:
                                                            <ul>
                                                                <li>Silver: We'll match 1% of your donated miles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Gold: We'll match 2% of your donated miles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Platinum: We'll match 5% of your donated miles</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Diamond: We'll match 10% of your donated miles</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>Gift Cards:
                                                            <ul>
                                                                <li>Platinum: Premium Gift Card Access</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Diamond: Premium Gift Card Access</li>
                                                            </ul>                                                        
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 44,
                                "text": "Email Update",
                                "slug": "email_update",
                                "answer_array": [
                                    {
                                        "id": 444,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I update the email address on my account?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Please contact us at support@getmiles.com using the email address associated with your account. Within the body of the email, please state that you would like to update your email address, and include the new email address you would like your account to be associated with.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 54,
                                "text": "Phone Verification",
                                "slug": "phone_verification",
                                "answer_array": [
                                    {
                                        "id": 554,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Why can’t I get the code to create my account?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>The Miles app accepts major US carriers (AT&T, T-Mobile, Verizon, etc) and does not accept VoIP numbers.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 64,
                                "text": "Delete Account",
                                "slug": "delete_account",
                                "answer_array": [
                                    {
                                        "id": 664,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I delete my account?</span></p>
                                                    <ul>
                                                        <li>Here are some steps to delete your account within the Miles app:
                                                            <ul>
                                                                <li>Open your Miles app to the Home Tab</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Tap the Profile icon in the top left corner</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select Settings</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select Privacy</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Scroll to the bottom of the page</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select Delete Account</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>If you would prefer we manually delete your account for you, please contact us at support@getmiles.com. Please be advised account deletions are irreversible and your miles balance will be reduced to 0 once initiated. Should you change your mind about us, you can always reinstall and sign up again at a later date. Once we receive your confirmation your Miles account will be deleted and this will be effective within 7 days.</span></p>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 74,
                                "text": "Other",
                                "slug": "other",
                                "answer_array": [
                                    {
                                        "id": 774,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Can I see a history of my miles earned and redeemed?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Yes. To see a history of your miles earned (including bonus miles) and miles redeemed by month please click open your Miles app to the Home Tab and scroll to “Transactions.” By selecting “View All,” you will have access to the current month and one month prior of transaction history.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Do miles expire?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>In order for us to maintain a delightful user experience and continue to provide a high-level of account security, miles will expire if members have not been active in the Miles app for 2 months or more.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ]
                    },
                ],
            },
            {
                "id": 5,
                "name": "Other",
                "slug": "other",
                "user": "user",
                "image_src": assets.help_page_category_icon_5,
                "subcategories": [
                    {
                        "name": "",
                        "question_array": [
                            {
                                "id": 15,
                                "text": "Multiple Devices",
                                "slug": "multiple_devices",
                                "answer_array": [
                                    {
                                        "id": 115,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Can I use the app on multiple devices?</span></p>
                                                    <ul>
                                                        <li>Yes, but you will only receive miles for one device on which you are signed in.</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>Please note: You can not have multiple accounts signed into one device. This will connect all of the accounts, and will flag the accounts as fraudulent, as they are in violation of our Terms of Service.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 25,
                                "text": "Fraud",
                                "slug": "fraud",
                                "answer_array": [
                                    {
                                        "id": 225,
                                        "text": <div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>Why was my account removed?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>The Miles Rewards Program rewards users who utilize our platform, following guidelines stated in the Terms of Service. If Miles determines that a user is abusing the platform by utilizing multiple accounts, exploiting loopholes, manipulating the system in any way, etc. Miles reserves the right to remove any user, removing the account and any potential new accounts coming from that user.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 35,
                                "text": "Battery Consumption",
                                "slug": "battery_consumption",
                                "answer_array": [
                                    {
                                        "id": 335,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How much battery does Miles app consume?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Miles consumes no battery when you are stationary. Battery consumption increases minimally when you take a trip and for average daily travel is estimated to be 1% of your battery life. Battery life can be enhanced by making sure Wi-Fi is on all the time.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 45,
                                "text": "Data Download",
                                "slug": "data_download",
                                "answer_array": [
                                    {
                                        "id": 445,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How do I download my data?</span></p>
                                                    <ul>
                                                        <li>Here are some steps to request to download your data:
                                                            <ul>
                                                                <li>Open your Miles app to the Home Tab</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select the profile icon in the top left corner</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select Settings</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select Privacy</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Select Request data</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>There will be a Request Data under ”Download My Data”. Once you click "Request data" It will send a 6 digit code to your email address to finish the data download.</span></p>
                                                    </ul>
                                                    <ul>
                                                        <p><span>Please note: Miles only keeps up to 3 months of transaction data. Anything from older than this will no longer be available, as it is no longer within our system. Within your Miles app, if you utilize the free subscription of Miles, you will have access to a 7 day trip history, and a 30 day trip history for Miles+.</span></p>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                            {
                                "id": 55,
                                "text": "Data Privacy",
                                "slug": "data_privacy",
                                "answer_array": [
                                    {
                                        "id": 555,
                                        "text":<div className="answer">
                                            <ul>
                                                <li>
                                                    <p><span>How much data does the app use?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>Miles app uses a very small amount of your data. Data usage will vary depending on how many trips you take and the distance of each trip. However, we estimate data used by the app would be in the 1-2% of the average data plan. We recommend the use of Wi-Fi to reduce data usage further.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>Where can I find the Terms of Service and the Privacy Policy in the app?</span></p>
                                                    <ul>
                                                        <li>
                                                            <p><span>To find the Terms of Service and the Privacy Policy, go to the Home Tab of the app, click on the profile icon in the top left corner, and select 'Legal.'</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How can I opt out of Data Sharing?</span></p>
                                                    <ul>
                                                        <li>If you are currently utilizing the Free Subscription that allows for data sharing, you can either upgrade to Miles+ or downgrade to the Free Subscription that does not share data. This can be done within your Subscription Settings, which
                                                            can be found by opening your Miles app to the Home Tab, and selecting the profile icon in the top left corner.</li>
                                                    </ul>
                                                    <ul>
                                                        <p><span>If you are currently subscribed to Miles+ or the Free Subscription that does not share data, your account has already opted out of data sharing.</span></p>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>What information is collected through my use of the Miles app?</span></p>
                                                    <ul>
                                                        <li>Information You Provide to Us - We collect information you provide directly to us. For example, we collect information when you create an account, participate in any interactive features of the Services, fill out a form, upload a profile
                                                            photo, participate in a contest or promotion, redeem a reward, request customer support or otherwise communicate with us. The types of information we may collect include your name, phone number, email address, profile photo, and any
                                                            other information you choose to provide.</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Information About Your Use of the Services - We collect information about your use of the Services, and we use various technologies to determine location, including IP address, GPS, and other sensors that may, for example, provide us with
                                                            information on nearby devices, Wi-Fi access points and cell towers. We may also receive information from other sensors on your device such as the compass, accelerometer, and gyroscope.</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Automatically Collected Information -When you access or use our Services, we automatically collect information about you, including:
                                                            <ul>
                                                                <li>Log Information: We collect log information about your use of the Services, including the type of iOS version you use, app version, device ID, and other in-app or online activity (user path, access times, pages viewed, etc.).</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Location Information: As noted above, in accordance with your device permissions, we will collect information about the precise location and movement of your device, including travel data captured by your device (e.g., acceleration).
                                                                    For more details, please see "Your Choices" below.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Information Collected by Cookies and Other Tracking Technologies: Like most online services and mobile applications, we may use cookies and other technologies, such as web beacons, web storage, and unique advertising identifiers,
                                                                    to collect information about your activity, browser, and device. Cookies are small data files stored on your hard drive or in device memory that help us improve our services and your experience, see which areas and features
                                                                    of our Services are popular and count visits. Web beacons are electronic images that may be used in our services or emails and help deliver cookies, count visits and understand usage and campaign effectiveness. For more information
                                                                    about cookies and how to disable them, please see "Your Choices" below.</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <p><span>Information We Collect from Other Sources - We may obtain information from other sources and combine that with information we collect through our Services. For example, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, gender, photos, account information, and friends lists, in accordance with the authorization procedures determined by such social media site. As another example, if you opt in to connect your Uber or Lyft account, we will have access to certain information from those services, such as your driving location and distance, in accordance with their authorization procedures.</span></p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p><span>How is information collected used?</span></p>
                                                    <ul>
                                                        <li>We use the information we collect to provide, maintain, and improve our services, such as to facilitate the performance of primary functions of the Services (e.g., transportation, travel, and geolocation services), or to manage your account
                                                            with the Services. We also use the information we collect to:
                                                            <ul>
                                                                <li>Send you technical notices, updates, security alerts and support and administrative messages and to respond to your comments, questions and customer service requests;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Communicate with you about products, services, offers, promotions, and events offered by Miles or third parties, or provide news and information related to the Services or third parties we think will be of interest to you;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Monitor and analyze trends, usage and activities in connection with our Services;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of Miles and users or partners;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Personalize and improve the Services and provide advertisements, content or features that match user profiles or interests;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Facilitate Miles and third-party contests, sweepstakes and promotions and process and deliver entries and rewards;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Comply with our legal obligations;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Enforce, or otherwise use in connection with, the Terms of Service; and</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>Carry out any other purpose described to you at the time the information was collected.</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                        
                                                <li>
                                                    <p><span>How is information collected and shared?</span></p>
                                                    <ul>
                                                        <li>We may share information about you as follows or as otherwise described in this Privacy Policy:
                                                            <ul>
                                                                <li>With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>In an anonymized, aggregated, or de-identified form, with third parties for their own services and general marketing purposes, unless you opt out of this type of sharing by logging out of the Services or deleting our mobile application</li>
                                                            </ul>
                                                            <ul>
                                                                <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement
                                                                    requirements;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Miles or others;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;</li>
                                                            </ul>
                                                            <ul>
                                                                <li>Between and among Miles and our current and future parents, affiliates, subsidiaries and other companies under common control and ownership; and</li>
                                                            </ul>
                                                            <ul>
                                                                <li>With your consent or at your direction.</li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <p><span>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.</span></p>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                ]
                            },
                        ]
                    },
                ],
            }
        ].map(flatten),
    }
}.response;


export const RAW_DATA = Object.fromEntries(
    Object.entries(RAW_DATA_2_LEVEL).map(([key, value]) => [key, value])
);


export type ITEM_TYPE = typeof RAW_DATA.user[0];
export type QUESTION_TYPE = typeof RAW_DATA.user[0]['question_array'][0];
