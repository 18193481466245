import React, { useMemo } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap, StyledItem } from "./styles";
import { useTransitionCarousel } from "../../../components/v2/transition_carousel";
import { useTranslation } from 'react-i18next';

const {
  partner_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  const CARDS_INFO = [
    // card 01
    {
      card_wrap_className: `miles_ui_partner_card_wrap`,
      imgClassName: `card_inner_img item_01`,
      title_01: t('partner_page_carousel_01_text_001'),
      title_01_ClassName: `title_01`,
      title_02: `Placements`,
      title_02_ClassName: `title_02`,
      subtitle_01: t('partner_page_carousel_01_text_002'),
      subtitle_01_ClassName: `subtitle_01`,
      backgroundColor: `#FDFAF3`,
    },
    // card 02
    {
      card_wrap_className: `miles_ui_partner_card_wrap`,
      imgClassName: `card_inner_img item_02`,
      title_01: t('partner_page_carousel_01_text_001'),
      title_01_ClassName: `title_01`,
      title_02: `Multiple Offers`,
      title_02_ClassName: `title_02`,
      subtitle_01: t('partner_page_carousel_01_text_002'),
      subtitle_01_ClassName: `subtitle_01`,
      backgroundColor: `#f8fffb`,
    },
    // card 03
    {
      card_wrap_className: `miles_ui_partner_card_wrap`,
      imgClassName: `card_inner_img item_03`,
      title_01: t('partner_page_carousel_01_text_001'),
      title_01_ClassName: `title_01`,
      title_02: `Content & branding`,
      title_02_ClassName: `title_02`,
      subtitle_01: t('partner_page_carousel_01_text_002'),
      subtitle_01_ClassName: `subtitle_01`,
      backgroundColor: `#edeeff`,
    },
    // card 04
    {
      card_wrap_className: `miles_ui_partner_card_wrap`,
      imgClassName: `card_inner_img item_04`,
      title_01: t('partner_page_carousel_01_text_001'),
      title_01_ClassName: `title_01`,
      title_02: `Content & branding`,
      title_02_ClassName: `title_02`,
      subtitle_01: t('partner_page_carousel_01_text_002'),
      subtitle_01_ClassName: `subtitle_01`,
      backgroundColor: `#fefaf3`,
    },
  ];

  const items = useMemo(() => [
    <div className="carousel-item card_01 active">
      <StyledItem
        className={CARDS_INFO[0].card_wrap_className}
        style={{
          backgroundColor: CARDS_INFO[0].backgroundColor,
        }}
        key={CARDS_INFO[0].imgClassName}
      >
        <div className={CARDS_INFO[0].title_01_ClassName}>{CARDS_INFO[0].title_01}</div>
        <div className={CARDS_INFO[0].subtitle_01_ClassName}>{CARDS_INFO[0].subtitle_01}</div>

        <div className="content_box miles_ui_screen_on_1440">
          <img className={`carousel_item_img carousel_01_a_1440_v2_img`} src={t('partner_page_carousel_01_a_001')} alt={`carousel_01_a_1440_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1280">
          <img className={`carousel_item_img carousel_01_a_1280_v2_img`} src={t('partner_page_carousel_01_a_002')} alt={`carousel_01_a_1280_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1000">
          <img className={`carousel_item_img carousel_01_a_1000_v2_img`} src={t('partner_page_carousel_01_a_003')} alt={`carousel_01_a_1000_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_600">
          <img className={`carousel_item_img carousel_01_a_600_v2_img`} src={t('partner_page_carousel_01_a_004')} alt={`carousel_01_a_600_v2_img`} />
        </div>
      </StyledItem>
    </div>,

    <div className="carousel-item card_02 active">
      <StyledItem
        className={CARDS_INFO[1].card_wrap_className}
        style={{
          backgroundColor: CARDS_INFO[1].backgroundColor,
        }}
        key={CARDS_INFO[1].imgClassName}
      >
        <div className={CARDS_INFO[1].title_01_ClassName}>{CARDS_INFO[1].title_01}</div>
        <div className={CARDS_INFO[1].subtitle_01_ClassName}>{CARDS_INFO[1].subtitle_01}</div>

        <div className="content_box miles_ui_screen_on_1440">
          <img className={`carousel_item_img carousel_01_b_1440_v2_img`} src={t('partner_page_carousel_01_b_001')} alt={`carousel_01_b_1440_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1280">
          <img className={`carousel_item_img carousel_01_b_1280_v2_img`} src={t('partner_page_carousel_01_b_002')} alt={`carousel_01_b_1280_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1000">
          <img className={`carousel_item_img carousel_01_b_1000_v2_img`} src={t('partner_page_carousel_01_b_003')} alt={`carousel_01_b_1000_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_600">
          <img className={`carousel_item_img carousel_01_b_600_v2_img`} src={t('partner_page_carousel_01_b_004')} alt={`carousel_01_b_600_v2_img`} />
        </div>

      </StyledItem>
    </div>,

    <div className="carousel-item card_04 active">
      <StyledItem
        className={CARDS_INFO[3].card_wrap_className}
        style={{
          backgroundColor: CARDS_INFO[3].backgroundColor,
        }}
        key={CARDS_INFO[3].imgClassName}
      >
        <div className={CARDS_INFO[3].title_01_ClassName}>{CARDS_INFO[3].title_01}</div>
        <div className={CARDS_INFO[3].subtitle_01_ClassName}>{CARDS_INFO[3].subtitle_01}</div>

        <div className="content_box miles_ui_screen_on_1440">
          <img className={`carousel_item_img carousel_01_c_1440_v2_img`} src={t('partner_page_carousel_01_c_001')} alt={`carousel_01_c_1440_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1280">
          <img className={`carousel_item_img carousel_01_c_1280_v2_img`} src={t('partner_page_carousel_01_c_002')} alt={`carousel_01_c_1280_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1000">
          <img className={`carousel_item_img carousel_01_c_1000_v2_img`} src={t('partner_page_carousel_01_c_003')} alt={`carousel_01_c_1000_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_600">
          <img className={`carousel_item_img carousel_01_c_600_v2_img`} src={t('partner_page_carousel_01_c_004')} alt={`carousel_01_c_600_v2_img`} />
        </div>
      </StyledItem>
    </div>,
    
    <div className="carousel-item card_03 active">
      <StyledItem
        className={CARDS_INFO[2].card_wrap_className}
        style={{
          backgroundColor: CARDS_INFO[2].backgroundColor,
        }}
        key={CARDS_INFO[2].imgClassName}
      >
        <div className={CARDS_INFO[2].title_01_ClassName}>{CARDS_INFO[2].title_01}</div>
        <div className={CARDS_INFO[2].subtitle_01_ClassName}>{CARDS_INFO[2].subtitle_01}</div>

        <div className="content_box miles_ui_screen_on_1440">
          <img className={`carousel_item_img carousel_01_d_1440_v2_img`} src={t('partner_page_carousel_01_d_001')} alt={`carousel_01_d_1440_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1280">
          <img className={`carousel_item_img carousel_01_d_1280_v2_img`} src={t('partner_page_carousel_01_d_002')} alt={`carousel_01_d_1280_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_1000">
          <img className={`carousel_item_img carousel_01_d_1000_v2_img`} src={t('partner_page_carousel_01_d_003')} alt={`carousel_01_d_1000_v2_img`} />
        </div>

        <div className="content_box miles_ui_screen_on_600">
          <img className={`carousel_item_img carousel_01_d_600_v2_img`} src={t('partner_page_carousel_01_d_004')} alt={`carousel_01_d_600_v2_img`} />
        </div>
      </StyledItem>
    </div>,

  ].map((item, index) => ({
    id: `slide_${index}`,
    renderItem: item,
  })), []);
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
  } = useTransitionCarousel({items});

  return (
    <Styled_body_wrap className="partner_section_carousel_02_wrap" >
      <div className="main_container">
        <div
          id="miles_ui_PartnerPage_carousel_01"
          className="carousel slide"
        >
          <div className="carousel-inner">
            {carouselFragment}
          </div>


          <button
            className="carousel-control-prev carousel_arrow arrow_left"
            type="button"
            onClick={slideToPrevItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

          <button
            className="carousel-control-next carousel_arrow arrow_right"
            type="button"
            onClick={slideToNextItem}
          >
            <div className="carousel_arrow_on_desktop" />
            <div className="carousel_arrow_on_mobile" />
          </button>

        </div>
      </div>
    </Styled_body_wrap>
  );
});

export default Component;
