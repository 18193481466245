import React, { useEffect } from "react";
import { observer } from "mobx-react";
import milesUtils, { useCountry } from "../../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";

let firstRender = true;

const MilesWeb_Company_Info: React.FC<{}> = observer(() => {
  let country = useCountry();

  if (firstRender) {
    milesUtils.debug_log("component - MilesWeb_Company_Info -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_Company_Info -- did mount");

    firstRender = false;
  }, []);

  return (
    <Styled_body_wrap isJP={country === "jp"}>
      <div className="head_title">Miles Japan</div>
      <div className="info_wrap">
        <div className="infoItem title">会社名</div>
        <div className="infoItem content">Miles Japan株式会社</div>
        <div className="infoItem title">設立</div>
        <div className="infoItem content">2021年1月4日</div>
        <div className="infoItem title">代表者</div>
        <div className="infoItem content">代表取締役CEO ジガー・シャー</div>
        <div className="infoItem title">所在地</div>
        <div className="infoItem content">〒106-0032 東京都港区六本木1-4-5 <br/> アークヒルズサウスタワー 16F</div>
      </div>
    </Styled_body_wrap>
  );
});

export default MilesWeb_Company_Info;
