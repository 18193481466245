import styled from "styled-components";


export const TeamLinksContainer = styled("div")`
    display: flex;
    width: 100%;
    gap: 20px;
    position: absolute;
    margin-bottom: 30px;
    left: calc(50vw - 468px);
    margin-top: 60px;

    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        left: calc(50vw - 300px);
    }
`;


export const TeamLink = styled("button")`
    background: none;
    border: none;
    padding: 0;
    width: max-content;
    height: 30px;
    margin: 0;
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 0.69px;
    color: 2d2f3b;
    margin-right: 10px;
    margin-right: 30px;



    :hover {
        text-decoration: none;
    }
    &.active {
        opacity: 1;
        text-decoration: none;
        color: #10af42;
    }

    &.miles_screen_larger_than_1150 {
        display: block;
    }

    &.miles_screen_less_than_1150 {
        display: none;
    }

    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        font-size: 16px;

        &.active {
            opacity: 1;
            text-decoration: none;
            color: #10af42;
        }

        &.miles_screen_larger_than_1150 {
            display: none;
        }
    
        &.miles_screen_less_than_1150 {
            display: block;
        }
    }
`;
