import React from "react";
import { observer } from "mobx-react";
import { store } from "../../Store";
import { StyledListItem } from "./styles";

interface Props {
  keyNum: string;
  content: string;
}

export const ListItem: React.FC<Props> = observer(({
  keyNum,
  content,
}) => {
  return (
    <StyledListItem>
      <span className="keyNum">{keyNum}.</span>
      <span className="content">{content}</span>
    </StyledListItem>
  );
})
