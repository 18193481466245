import styled from "styled-components";
interface Props {
  country?: string;
}

export const Styled_body_wrap = styled("div")<Props>`
  margin: 0;
  width: 100vw;
  height: 2090px;
  background: #ffffff;

  .main_container {
    margin: 0 auto;
    width: 1440px;

    .title {
      margin: 0 auto;
      width: auto;
      height: 47px;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 47px;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 152px;
      margin-bottom: 50px;
    }

    .asset_wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 230px;

      .inner_box {
        display: flex;
        flex-direction: row;

        &.inner_box_01 {
          margin-top: 0;
        }

        &.inner_box_02 {
          margin-top: 164px;
        }

        &.inner_box_03 {
          margin-top: 196px;
        }

        img {
          display: inline-block;
        }

      }

    }

    .inner_box_01_asset {
      width: 410px;
      height: 451px;
      margin-right: 154px;
    }

    .inner_box_02_asset {
      width: 410px;
      height: 451px;
      margin-left: 148px;
    }

    .inner_box_03_asset {
      width: 399.5px;
      height: 451px;
      margin-right: 154px;
    }

    .inner_box_01_content {
      width: 416px;
    }

    .inner_box_content_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .inner_box_text_01 {
        width: 416px;
        height: auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        color: #2d2f3b;
      }

      .inner_box_text_02 {
        width: 416px;
        height: auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2d2f3b;
        margin-top: 30px;
      }

    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 2020px;

    .main_container {
      margin: 0 auto;
      width: 100vw;

      .title {
        margin: 0 auto;
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 124px;
        margin-bottom: 17px;
      }

      .asset_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 153px;

        .inner_box {
          display: flex;
          flex-direction: column;
          align-items: center;

          &.inner_box_01 {
            margin-top: 0;
            flex-direction: column-reverse;
          }

          &.inner_box_02 {
            margin-top: 125px;
          }

          &.inner_box_03 {
            margin-top: 125px;
            flex-direction: column-reverse;
          }

          img {
            display: inline-block;
          }

        }

      }

      .inner_box_01_asset {
        width: 298px;
        height: 328px;
        margin-right: 0;
        margin-top: 20px;
      }

      .inner_box_02_asset {
        width: 325px;
        height: 358px;
        margin-left: 0;
        margin-top: 20px;
      }

      .inner_box_03_asset {
        width: 310px;
        height: 350px;
        margin-right: 0;
        margin-top: 40px;
      }

      .inner_box_01_content {
        width: 416px;
      }

      .inner_box_content_box {
        flex-direction: column;
        align-items: center;

        .inner_box_text_01 {
          width: auto;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: 0.75px;
          text-align: center;
          color: #2d2f3b;
        }

        .inner_box_text_02 {
          width: 306px;
          height: auto;
          margin: 0 auto;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.61px;
          text-align: center;
          color: #5f5f69;
          margin-top: 10px;
        }

      }

    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: 2020px;

    .main_container {
      margin: 0 auto;
      width: 100vw;

      .title {
        margin: 0 auto;
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 85px;
        margin-bottom: 53px;
      }

      .asset_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 153px;

        .inner_box {
          display: flex;
          flex-direction: column;
          align-items: center;

          &.inner_box_01 {
            margin-top: 0;
            flex-direction: column-reverse;
          }

          &.inner_box_02 {
            margin-top: 125px;
          }

          &.inner_box_03 {
            margin-top: 125px;
            flex-direction: column-reverse;
          }

          img {
            display: inline-block;
          }

        }

      }

      .inner_box_01_asset {
        width: 298px;
        height: 328px;
        margin-right: 0;
        margin-top: 20px;
      }

      .inner_box_02_asset {
        width: 325px;
        height: 358px;
        margin-left: 0;
        margin-top: 20px;
      }

      .inner_box_03_asset {
        width: 310px;
        height: 350px;
        margin-right: 0;
        margin-top: 40px;
      }

      .inner_box_01_content {
        width: 416px;
      }

      .inner_box_content_box {
        flex-direction: column;
        align-items: center;

        .inner_box_text_01 {
          width: auto;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: 0.75px;
          text-align: center;
          color: #2d2f3b;
        }

        .inner_box_text_02 {
          width: 306px;
          height: auto;
          margin: 0 auto;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.61px;
          text-align: center;
          color: #5f5f69;
          margin-top: 10px;
        }

      }

    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: auto;

    .main_container {
      width: 100vw;

      .title {
        margin: 0 auto;
        width: auto;
        height: 47px;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.75px;
        text-align: center;
        color: #2d2f3b;
        margin-top: 120px;
        margin-bottom: 54px;
      }

      .asset_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 240px;

        .inner_box {
          display: flex;
          flex-direction: column;
          align-items: center;

          &.inner_box_01 {
            margin-top: 0;
            flex-direction: column-reverse;
          }

          &.inner_box_02 {
            margin-top: 125px;
          }

          &.inner_box_03 {
            margin-top: 222px;
            margin-bottom: 125px;
            flex-direction: column-reverse;
          }

          img {
            display: inline-block;
          }

        }

      }

      .inner_box_01_asset {
        width: 298px;
        height: 328px;
        margin: 0 auto;
        margin-top: 20px;
      }

      .inner_box_02_asset {
        width: 325px;
        height: 358px;
        margin: 0 auto;
        margin-top: 34px;
      }

      .inner_box_03_asset {
        width: 310px;
        height: 350px;
        margin: 0 auto;
        margin-top: 50px;
      }

      .inner_box_01_content {
        width: 430px;
      }

      .inner_box_02_content {
        width: 430px;
      }

      .inner_box_03_content {
        width: 430px;
      }

      .inner_box_content_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .inner_box_text_01 {
          width: 430px;
          height: auto;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
          font-size: 32px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          text-align: center;
        }

        .inner_box_text_02 {
          width: 430px;
          height: auto;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 10px;
          text-align: center;
        }

      }

      .inner_box_03_content {
        .inner_box_text_02 {
          width: 460px;
        }
      }

    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .main_container {
      width: 100vw;
  
      .title {
        height: 47px;
        font-size: 36px;
        line-height: 2.14;
        color: #2d2f3b;
        margin-top: 125px;
        margin-bottom: 82px;
      }
  
      .asset_wrap {
        padding: 0 182px;
  
        .inner_box {
  
          &.inner_box_01 {}
  
          &.inner_box_02 {}
  
          &.inner_box_03 {
            margin-top: 199px;
          }
  
        }
  
      }
  
      .inner_box_01_asset {
        margin-right: 90px;
      }
  
      .inner_box_02_asset {
        margin-left: 90px;
      }
  
      .inner_box_03_asset {
        margin-right: 90px;
      }
  
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: 2090px;
    background: #ffffff;

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }
  }
`;