import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import {
  EMAIL_DOMAINS_CLEANED,
  EXTRA_EMAIL_DOMAINS_CLEANED,
} from "../../../utils/milesConstant";
import {
  checkValidPhoneNumber
} from '../../../utils/phoneNumValidator';
import { Styled_body_wrap } from "./styles";
import { store } from "../../../Store";
import milesUtils from "../../../utils/milesUtils";

const {
  partner_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone_number: "",
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errs, setErrs] = useState({
    name: false,
    email: false,
    phone_number: false,
    submissionErr: false
  });

  const [position, setPosition] = useState(0);
  const inputEl = useRef<HTMLInputElement>(null);
  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      if (currentPhoneNum.length > 10) {
        currentPhoneNum = currentPhoneNum.slice(0, 10); 
      }
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);

      const selectionStart = inputEl!.current!.selectionStart as number;

      let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_miles_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleUpdateValues = useCallback(
    (event: React.ChangeEvent<HTMLFormElement | HTMLTextAreaElement | HTMLInputElement>) => {
      setValues({
        ...values,
        ...{ [event.target.name]: event.target.value },
      });
      event.preventDefault();
    },
    [values],
  );

  const checkEmailValue = (email: string) => {
    let new_array = [
      ...EMAIL_DOMAINS_CLEANED,
      ...EXTRA_EMAIL_DOMAINS_CLEANED,
    ]
    return new_array.indexOf(email.trim().split("@")[1]) > -1
  }

  const handleSubmitPartnerData = () => {
    setErrs({
      ...errs,
      name: !values.name.trim(),
      email: checkEmailValue(values.email) || !milesUtils.isValidEmailAddress(values.email),
      phone_number: !checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
    })

    let no_err = values.name.trim()
      && !checkEmailValue(values.email) && milesUtils.isValidEmailAddress(values.email)
      && checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
    
    if (values.name && values.email && phoneNumber && no_err) {
      store.handlePartnerFormData({
        ...values,
        phone_number: phoneNumber,
      }, "pre_footer_form")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  return (
    <>
      <Styled_body_wrap className="partner_section_partner_form" id="partner_page_let_get_started">
        <div className="main_container">
          <div className="title">Explore the right Miles solution for your business.</div>
          {
            store.partner_page_form_status.pre_footer_form === "sent"
            ? (
              <div className="form_wrap">
                <img className="partner_page_pre_footer_form_asset" src={assets.partner_page_form_sent_success_asset_img} alt={`partner_page_form_sent_success_asset_img`}></img>
                <div className="partner_page_pre_footer_form_success_text">We have received your information. Someone from our team will get back to you within 48 hours. </div>
              </div>
            ) : (
              <div className="form_wrap">
                <label className="input_inner_section input_box_01">
                  <span className="label_text">Name</span>
                  <input
                    className={`${errs.name ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleUpdateValues}
                    required
                    placeholder="Name"
                  />
                  <span className={errs.name ? "show_err_info" : "hide_err_info"}>Please enter a valid name</span>
                </label>

                <label className="input_inner_section input_box_02">
                  <span className="label_text">Business Email Address</span>
                  <input
                    className={`${errs.email ? "input_err" : "no_err"} partner_preFooter_form_input input_02`}
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleUpdateValues}
                    required
                    placeholder="Business Email Address"
                  />
                  <span className={errs.email ? "show_err_info" : "hide_err_info"}>Please enter a valid business email address</span>
                </label>

                <label className="input_inner_section input_box_03">
                  <span className="label_text">Phone Number</span>
                  <input
                    ref={inputEl}
                    className={`${errs.phone_number ? "input_err" : "no_err"} partner_preFooter_form_input input_03`}
                    type="tel"
                    name="phone_number"
                    required
                    value={phoneNumber}
                    onChange={handleChangePhoneNum}
                    onKeyDown={handleOnKeyDown}
                    placeholder="(123) - 456 - 7890"
                  />
                  <span className={errs.phone_number ? "show_err_info" : "hide_err_info"}>Please enter a valid phone number</span>
                </label>

                <div className="tip_text">By proceeding, I agree that MILES may contact me by email or phone</div>

                <button className={`partner_page_pre_form_cta_btn miles_ui_btn_primary ${store.partner_page_form_status.pre_footer_form === "sending" ? "loading_status" : ""}`} onClick={() => store.partner_page_form_status.pre_footer_form === "sending" ? {} : handleSubmitPartnerData()}>Submit</button>
                <span className={store.partner_page_form_status.pre_footer_form === "server_error" ? "show_err_info submissionErr" : "hide_err_info"}>Something went wrong. Please try again.</span>
              </div>
            )
          }
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
