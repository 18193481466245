import {MILES_WEB_S3_BASE_URL_V2} from './common/base';
import { commonIcon } from './common/commonIcon';


// TODO(Lin,Chirayu): Update for MJKK.
const config = {
  appStoreLinkBaseUrl: `https://app.getmiles.com`,
  miles_tenant_logo: require('src/assets/Miles/miles_logo@2x.png'),
  miles_share_reward_page_logo: require('src/assets/Miles/miles_logo@2x.png'),
  miles_logo_white: require('src/assets/commonImg/logo_wm_h_white@3x.png'),
  illustrations_gift: require('src/assets/Miles/graphic-raf-share@3x.png'),
  download_text: `Download the Miles app`,
  share_text: `Earn miles on all your travel & much more`,
  redeem_rewards_text: `Use miles to redeem for cash and gift cards`,
  apple_store_src: "https://apps.apple.com/app/miles/id1393913947",
  google_play_store_src: "https://play.google.com/store/apps/details?id=connectiq.miles.app",
  listItemContent_1: `Open the Miles app from your mobile phone`,
  apple_store_icon: require('src/assets/Miles/appStoreIcon/apple_app_store_icon@3x.png'),
  google_play_store_icon: require('src/assets/Miles/appStoreIcon/google_play_store_icon@3x.png'),
  reward_share_download_text: `Download the Miles app`,
  reward_share_share_text: `Earn miles on all your travel & much more`,
  reward_share_redeem_rewards_text: `Use miles to redeem for cash and gift cards`,
  reward_share_title_width_on_1150: `605px`,
  reward_share_title_width_on_600: `335px`,
  reward_share_title_width_on_360: `295px`,
  currentSupportEmailAddress: `support@getmiles.com`,
  topLogo: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_logo_white_2500%402x.png`,
  bgImgArr: [
    `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/miles_unsubscribe_bg_1440_v1/Group+10%402x.png`,
    `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_preference_top_bg_1280%402x.png`,
    `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/web_v2_preference_top_bg_mobile_v2_2022/Group+10%402x.png`,
  ],
  primary_color_01: "#10af42",
  primary_color_02: "#1ba756",
  preference_sad_face_dog: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/preference_sad_face_dog%402x.png`,
  preference_re_subscribe_thankyou_bg: `https://getmiles.s3.amazonaws.com/assets/web_v2/unsubscribe_preference/preference_re_subscribe_thankyou_bg%402x.png`,
  confirmation_10_days_text_note_description_margin: `23px auto 0 auto`,

  fontFamily_Gilroy_Medium: `Noto Sans JP Medium, Gilroy-Medium`,
  fontFamily_Gilroy_SemiBold: `Noto Sans JP Bold, Gilroy-SemiBold`,
  fontFamily_Gilroy_Regular: `Noto Sans JP, Gilroy-Regular`,

  password_reset_001: `新しいパスワードを設定する`,
  password_reset_002: `新しいパスワードをご設定ください。`,
  password_reset_003: `このページを更新しないでください。`,
  password_reset_004: `パスワード`,
  password_reset_005: `非表示`,
  password_reset_006: `表示`,
  password_reset_007: `パスワードは、少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`,
  password_reset_008: `パスワードは、8文字以上で少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`,
  password_reset_009: `パスワード（再入力`,
  password_reset_010: `入力されたパワワードが一致していません`,
  password_reset_011: `パスワードを設定`,
  password_reset_012: `パスワードの再設定が完了しました`,
  password_reset_013: `パスワードの更新が完了しました。その他、ご不明な点は`,
  password_reset_014: `ヘルプセンター`,
  password_reset_014_01: `をご利用ください`,
  password_reset_015: `パスワード再設定エラー`,
  password_reset_016: `パスワードの再設定中にエラーが発生しました。その他、ご不明な点は`,
  password_reset_017: `ヘルプセンター`,
  password_reset_017_01: `をご利用ください`,
  password_reset_018: `リンクの期限切れ`,
  password_reset_019: `パスワード再設定リンクの期限が切れています。アプリから再設定の手続きを再度お試しください。その他、ご不明な点は`,
  password_reset_020: `ヘルプセンター`,
  password_reset_020_01: `をご利用ください`,
  password_reset_help_url: `https://www.getmiles.com/jp/help/category/account-settings-preferences/how-to-reset-password`,
  
  help_page_success_asset_v1: `${MILES_WEB_S3_BASE_URL_V2}contact_page/send_contact_query_success/send_contact_query_success_1%402x.png`,
  ...commonIcon,
}

export default config;
