import React from "react";
import { Styled_Super_wrap, Styled_We_care_your_privacy } from "./styles";

interface Props {
  we_care_your_privacy_img: string;
}

export const We_care_your_privacy: React.FC<Props> = ({
  we_care_your_privacy_img,
}) => {
  return (
    <Styled_Super_wrap style={{ width: "100vw", backgroundColor: "#d4f0dd" }}>
      <Styled_We_care_your_privacy>
        <img className="inner_box_01" src={we_care_your_privacy_img} alt="brands_you_love_img"></img>

        <div className="inner_box_02">
          <div className="inner_box_02_title">プライバシーポリシー</div>
          <div className="inner_box_02_content">ユーザーの皆様がアプリご利用中に当社が取得したデータは、常に安全に保護されています。当社は、取得したデータの保護を最優先事項として考えています。</div>
        </div>
      </Styled_We_care_your_privacy>

      <div className="miles_ui_less_than_768">
        <div className="inner_box_title">プライバシーポリシー</div>
        <img className="inner_box_asset" src={we_care_your_privacy_img} alt="brands_you_love_img"></img>
        <div className="inner_box_content">ユーザーの皆様がアプリご利用中に当社が取得したデータは、常に安全に保護されています。当社は、取得したデータの保護を最優先事項として考えています。</div>
      </div>
    </Styled_Super_wrap>
  );
};
