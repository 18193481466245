import styled from "styled-components";
interface Props {
  isJP: boolean;
}
export const Styled_body_wrap = styled("div")<Props>`
  margin: 0 auto;
  padding: 0; 
  min-width: 360px;

  .miles_ui_about_page_leadership_main_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.isJP ? '#fff' : "rgb(232, 247, 237, 0.17)"};
  }

  .miles_ui_about_page_leadership_main_container {
    min-width: 360px;
    width: 965px;
    ${(props) => props.isJP ? `height: auto;` : `height: 820.8px;`}
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_about_page_leadership_main_container {
      min-width: 360px;
      width: 360px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_leadership_main_container {
      width: 215px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_leadership_main_container {
      width: 760px;
      ${(props) => props.isJP ? `height: auto;` : `height: 716.1px;`}
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;