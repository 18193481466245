import React from "react";
import { StyledOrTextBox } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {}

export const FcaRafOrTextBox: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <StyledOrTextBox>{t('raf_text_or')}</StyledOrTextBox>
  );
};
