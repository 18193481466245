import styled from "styled-components";
interface Props {
  country?: string;
}

export const Styled_body_wrap = styled("div")<Props>`
  margin: 0;
  width: 100vw;
  height: auto;
  background: #f8fffb;

  .main_container {
    margin: 0 auto;
    width: 1440px;

    .divider_space_after_box_01 {
      height: 160px;
    }

    .title {
      height: 61px;
      flex-grow: 0;
      font-family: Gilroy-Bold;
      font-size: 49px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 61px;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin: 0 auto;
      margin-top: 113px;
      margin-bottom: 91px;
    }

    .asset_wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0;

      .inner_box {
        display: flex;
        flex-direction: row;

        .inner_box_asset_img {
          display: inline-block;
          width: 410px;
          height: 450px;
        }
        &.inner_box_01 {}
      }

    }

    .inner_box_01_asset {
      margin-left: 172px;
    }

    .inner_box_content_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 480px;
      height: auto;

      .inner_box_title {
        width: 100%;
        height: 61px;
        font-family: Gilroy-Bold;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 61px;
        letter-spacing: normal;
        text-align: left;
        color: #2d2f3b;
        margin: 0 auto;
      }

      .inner_box_list {
        margin-top: 30px;

        li {
          width: 413px;
          height: 36px;
          font-family: Gilroy-Medium;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 36px;
          letter-spacing: normal;
          text-align: left;
          color: #2d2f3b;
        }

      }

    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

    .main_container {
      margin: 0 auto;
      width: 100vw;

      .divider_space_after_box_01 {
        height: 44px;
      }

      .title {
        height: 36px;
        font-size: 26px;
        font-weight: bold;
        line-height: 36px;
        margin-top: 66px;
        margin-bottom: 50px;
      }

      .asset_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .inner_box {
          display: flex;
          flex-direction: column;
          align-items: center;

          .inner_box_asset_img {
            width: 320px;
            height: 350px;
          }

          &.inner_box_01 {
            flex-direction: column;
          }
        }

      }

      .inner_box_01_asset {
        margin: 0 auto;
      }

      .inner_box_content_box {
        flex-direction: column;
        align-items: center;
        width: auto;
        height: auto;

        .inner_box_title {
          height: 22px;
          font-size: 22px;
          line-height: 22px;
          text-align: center;
        }
  
        .inner_box_list {
          margin-top: 25px;
          li {
            width: auto;
            height: 24px;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            text-align: left;
          }
  
        }
      }

    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {

    .main_container {
      margin: 0 auto;
      width: 100vw;

      .divider_space_after_box_01 {
        height: 44px;
      }

      .title {
        height: 36px;
        font-size: 26px;
        font-weight: bold;
        line-height: 36px;
        margin-top: 66px;
        margin-bottom: 50px;
      }

      .asset_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .inner_box {
          display: flex;
          flex-direction: column;
          align-items: center;

          .inner_box_asset_img {
            width: 320px;
            height: 350px;
          }

          &.inner_box_01 {
            flex-direction: column;
          }

        }

      }

      .inner_box_01_asset {
        margin: 0 auto;
      }

      .inner_box_content_box {
        flex-direction: column;
        align-items: center;
        width: auto;
        height: auto;

        .inner_box_title {
          height: 24px;
          font-size: 22px;
          line-height: 24px;
          text-align: center;
        }
  
        .inner_box_list {
          margin-top: 25px;
          li {
            width: auto;
            height: 24px;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            text-align: left;
          }
  
        }
      }

    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

    .main_container {
      width: 100vw;

      .divider_space_after_box_01 {
        height: 99px;
      }

      .title {
        height: 36px;
        font-size: 36px;
        line-height: 36px;
        margin-top: 145px;
        margin-bottom: 83px;
      }

      .asset_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .inner_box {
          display: flex;
          flex-direction: column;
          align-items: center;

          &.inner_box_01 {
            flex-direction: column;
          }

        }

      }

      .inner_box_01_asset {
        margin: 0 auto;
      }

      .inner_box_content_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        height: auto;

        .inner_box_title {
          height: 61px;
          font-size: 32px;
          font-stretch: normal;
          font-style: normal;
          line-height: 61px;
          text-align: center;
        }
  
        .inner_box_list {
          margin-top: 30px;
          li {
            width: auto;
            height: 34px;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 34px;
            letter-spacing: normal;
            text-align: left;
          }
  
        }

      }

    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .main_container {
      margin: 0 auto;
      width: 100vw;

        .inner_box_list {
          li.second_item {
            margin-bottom: 36px;
          }
        }
      }

    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .main_container {
      margin: 0 auto;
      width: 100vw;

        .inner_box_list {
          li.second_item {
            margin-bottom: 36px;
          }
  
        }
      }

    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: auto;

    .main_container {
      width: 1440px;
        .inner_box_list {
          li.second_item {
            margin-bottom: 36px;
          }
  
        }
      }

    }
  }
`;