import styled from "styled-components";

export const StyledFCASignInSignUp = styled("div")`
    width: 100vw;
    height: 100vh;

    img.signin_signup_asset {
        display: block;
        width: 300px;
        height: 100px;
        position:absolute;
        left:0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }

    a.cta_continue {
        display: block;
        width: calc(100% - 48px);
        height: 45px;
        position:absolute;
        left:0;
        right: 0;
        bottom: 88px;
        margin: auto;
        background-color: #243783;
        border-radius: 25px;
        font-family: Gilroy-SemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.7px;
        text-align: center;
        color: #fff;
        border: none;
        line-height: 45px;
    }
`;