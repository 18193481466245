import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { Styled_body_wrap } from "./styles";
import styled from "styled-components";

const Line = styled("div")`
  width: 100%;
  height: 17px;
  display: flex;
  justify-content: space-between;
  position: relative;
  ::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #2d2f3b;
    top: 8px;
    left: 0px;
    content: " ";
  }
`;


const Dot = styled("div")`
  cursor: pointer;
  height: 9px;
  width: 9px;
  background-color: #2d2f3b;
  border-radius: 50%;
  margin-top: 5px;
  z-index: 1;

  ::after {
    display: block;
    position: relative;
    z-index: 2;
    width: 30px;
    height: 30px;
    top: -10px;
    left: -10px;
    content: " ";
  }

  &.active {
    background-color: #10af42;
    height: 17px;
    width: 17px;
    margin-top: 0px;

    ::after {
      top: -6px;
      left: -6px;
    }

    .dot_label {
      display: block;
    }
  }

  &.disabled_dot {
    cursor: unset;
  }

  .dot_label {
    cursor: pointer;
    width: max-content;
    height: 56px;
    margin-top: 16px;
    font-family: Gilroy-Bold;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.8;
    letter-spacing: normal;
    color: rgb(16, 175, 66);
    display: none;
  }
`;


const Component: React.FC<{}> = observer(() => {
  const num_dots = 16;
  const dots = [] as React.ReactNode[];
  for (let i = 0; i < num_dots; i++) {
    dots.push(
      <Dot className={(i+1) % 5 === 0 ? 'active' : ''}>
        <div className="dot_label">
          Label {i.toString()}
        </div>
      </Dot>);
  }
  return (
    <>
      <Styled_body_wrap className="ciq_root">
        <div className="ciq_container" >
          <Line>
            {dots}
          </Line>        
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
