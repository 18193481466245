import React from "react";
import { AvatarControls } from '../types';

export interface PagedNavigationButtonsProps {
    selectedIndex: number;
    avatarControls: AvatarControls;
}

export const PagedNavigationButtons = ({selectedIndex, avatarControls}: PagedNavigationButtonsProps) => {
    const buttons = avatarControls.animationIndexes.map((_, index) => (
        <button
            className={selectedIndex === index ? 'active' : ''}
            onClick={() => avatarControls.slideToIndex(index)}
        />
    ));
    return <>{buttons}</>;
}
