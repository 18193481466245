import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import {
  checkValidPhoneNumber
} from '../../../utils/phoneNumValidator';
import { Send_download_link_cta } from "../../../components/v2/miles_ui_box_send_download_link_cta";
import { store } from "../../../Store";
import milesUtils from "../../../utils/milesUtils";
import { Styled_Box } from "./styles";

interface Props {
  apple_store_icon_img: string;
  google_play_icon_img: string;
  apple_store_src: string;
  google_play_store_src: string;
}

export const Send_download_link: React.FC<Props> = observer(({
  apple_store_icon_img,
  google_play_icon_img,
  apple_store_src,
  google_play_store_src,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [position, setPosition] = useState(0);
  const inputEl = useRef<HTMLInputElement>(null);

  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      if (currentPhoneNum.length > 10) {
        currentPhoneNum = currentPhoneNum.slice(0, 10); 
      }
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);

      const selectionStart = inputEl!.current!.selectionStart as number;

      let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_download_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_download_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let finalPhoneNum = phoneNumber
      if(!checkValidPhoneNumber(finalPhoneNum.replace( /[^0-9]/g, '' ))) {
        store.update_miles_links_status('get_download_link', 'invalidNum_error')
      } else {
        store.handleSendDownloadLink_HomePage(finalPhoneNum, "get_download_link");
      }
    },
    [phoneNumber]
  );

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);
  return (
    <Styled_Box>
      <div className="title">Travel, Rewarded.</div>
      <div className="sub_title">Now earn miles for all your</div>
      <div className="sub_title">commute and travel </div>
      <div className="cta_box desktop">
        <Send_download_link_cta
          inputEl={inputEl}
          placeholder="(123) - 456 - 7890"
          value={phoneNumber}
          onChange={handleChangePhoneNum}
          onKeyDown={handleOnKeyDown}
          type="tel"
          handleSendDownloadLink={handleSendDownloadLink}
          apple_store_icon_img={apple_store_icon_img}
          google_play_icon_img={google_play_icon_img}
          apple_store_src={apple_store_src}
          google_play_store_src={google_play_store_src}
        />
      </div>

      <div className="cta_box miles_ui_less_than_360">
        <a className="inline_block app_store_box apple" href={apple_store_src} target="_blank">
          <div className="inline_block app_store_logo apple_store_icon_img"></div>
          <img className="inline_block app_store_text_logo" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/icon_available_on_apple_store%402x.png`} alt="icon_available_on_apple_store"></img>
        </a>

        <a className="inline_block app_store_box google" href={google_play_store_src} target="_blank">
          <div className="inline_block app_store_logo google_play_icon_img"></div>
          <img className="inline_block app_store_text_logo" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/home_page/icon_get_it_on_google_play%402x.png`} alt="icon_get_it_on_google_play"></img>
        </a>
      </div>
    </Styled_Box>
  );
});
