import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { store } from "../../Store";
import { UnsubscribeCategoryGroup } from "../../components/unsubscribe_category_group_current";
import { UnsubscribeFromAll } from "../../components/unsubscribe_from_all_current";

import config from "../../utils/config";

import { StyledEmailPreferenceMainBody } from "./StyledEmailPreferenceMainBody";
import { StyledTitle } from "./StyledTitle";
import { StyledDividerLine } from "./StyledDividerLine";
import { StyledSubTitle } from "./StyledSubTitle";
import { StyledUpdateBtn } from "./StyledUpdateBtn";
import i18next from 'i18next';

let firstRender = true;

const {
  primary_color_01,
  primary_color_02,
} = config;

const EmailPreference: React.FC<{}> = observer(() => {
  let history = useHistory();

  if (firstRender) {
    store.fetchSubscriptionPreferences();
  }

  useEffect(() => {
    firstRender = false;
  }, []);

  const handleUpdateEmailPreference = useCallback(
    () => {
      let paramUrlPath = `?email=${encodeURIComponent(store.email_param)}&token=${encodeURIComponent(store.token_param)}`;
      if (store.is_unSubscribed_from_all_status) {
        store.handle_unsubscribe_all_emails().then(() => {
          history.push(`/preferences/email/thankyou${paramUrlPath}&type=update_preference_unsubscribe_all_emails`);
        });
      } else {
        store.update_notification_preferences().then(() => {
          history.push(`/preferences/email/thankyou${paramUrlPath}&type=update_preference_by_group`);
        });
      }
  }, []);

  const { t } = useTranslation();

  const unSubscribeFromAllData: any[] = [
    {
      "categories": [
        {
            "id": "is_unSubscribed_from_all_status",
            "title": t('text_Unsubscribe_from_all'),
            "description": t('text_Unsubscribe_from_all_description'),
            "is_subscribed": store.is_unSubscribed_from_all_status
        }
      ]
    },
  ]; 

  return (
    <React.Fragment>
      {
        store.subscription_preferences.groups.length > 0 ? (
          <StyledEmailPreferenceMainBody>
            <StyledTitle>{t('email_Preferences_box_title')}</StyledTitle>
            <StyledDividerLine height="3px" bg={primary_color_02} />
            <StyledSubTitle>{t('email_Preferences_box_subTitle')}</StyledSubTitle>
            
            <UnsubscribeCategoryGroup
              bgColor=""
              dataSource={store.subscription_preferences.groups}
              subscription_category_checkBox_status={store.subscription_category_checkBox_status}
            />

            <StyledDividerLine height="1px" bg="#e6e6e8" />

            <UnsubscribeFromAll
              bgColor="transparent"
              dataSource={unSubscribeFromAllData}
            />

            <StyledUpdateBtn onClick={handleUpdateEmailPreference} className={`miles_ui_btn_primary ${process.env.REACT_APP_MILES_TENANT}`}>{t('email_Preferences_box_btn_text')}</StyledUpdateBtn>
          </StyledEmailPreferenceMainBody>
        ) : null
      }
    </React.Fragment>
  );
});

export default EmailPreference;
