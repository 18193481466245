import styled from "styled-components";

interface Props {
  height: string;
  bg: string;
}
export const StyledDividerLine = styled("div")<Props>`
  width: ${(props) => props.bg === '#1ba756' ? '100px' : '540px'};
  height: ${(props) => props.height};
  margin: 20px auto;
  background-color: ${(props) => props.bg};
  // TODO: uncomment and fix when we get the design for this size.
  // /* Extra Small Devices, Phones */ 
  // @media only screen and (min-width : 320px) and (max-width : 768px) {
  //   width: ${(props) => props.bg === '#1ba756' ? '100px' : '95%'};
  //   margin-top: ${(props) => props.bg === '#1ba756' ? '15px' : '24px'};
  //   margin-bottom: ${(props) => props.bg === '#1ba756' ? '20px' : '24px'};

  // }
  // /* ----------- Smartphones (portrait and landscape) ----------- */
  // @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  //   width: ${(props) => props.bg === '#1ba756' ? '100px' : '95%'};
  //   margin-top: ${(props) => props.bg === '#1ba756' ? '15px' : '24px'};
  //   margin-bottom: ${(props) => props.bg === '#1ba756' ? '20px' : '24px'};
  // }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 260px;
    margin: 0 auto 40px auto;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 279px;
    margin: 0 auto 25px auto;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }
`;