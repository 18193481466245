import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import Lottie, { LottieRefCurrentProps, InteractivityProps } from "lottie-react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../utils/milesConstant";
import Sample_component from "./sample_component/index";
import WhereItAllHappens from "./where_it_all_happens/index";
import TopBanner from "./top_banner/index";
import Support from "./support/index";
import Address from "./address/index";
import ContactForm from "./contact_form/index";
import {Pre_footer_hero} from "../../components/v2/miles_ui_box_pre_footer_hero/index";

const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

type FormSelectType = 'user' | 'partner' | null;

const MilesWeb_ContactPage: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_ContactPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_ContactPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  const [formSelection, setFormSelection] = useState(() => (
    null as FormSelectType
    // 'user' as FormSelectType
  ));

  const onClickMemberSupport = useCallback(() => {
    console.log('onClickMemberSupport');
    setFormSelection('user');
  }, []);

  const onClickPartnerSupport = useCallback(() => {
    console.log('onClickPartnerSupport');
    setFormSelection('partner');
  }, []);

  return (<>
    {/* // <Styled_body_wrap> */}
      {/* <TopBanner></TopBanner> */}
      {
        (formSelection !== null)
          ? <ContactForm user_type={formSelection}/>
          : <Support 
            onClickMemberSupport={onClickMemberSupport}
            onClickPartnerSupport={onClickPartnerSupport}/>  
      }
      {/* <WhereItAllHappens /> */}
      <br></br>
      {/* <Address /> */}
      <Pre_footer_hero
        apple_store_icon_img={apple_store_icon_img}
        google_play_icon_img={google_play_icon_img}
        star_icon_img={star_icon_img}
        icon_star_half_img={icon_star_half_img}
        apple_store_src={apple_store_src}
        google_play_store_src={google_play_store_src}
      />
    {/* // </Styled_body_wrap> */}
  </>);
});

export default MilesWeb_ContactPage;
