import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

interface FcaRafSendDownloadErrTipEleProps {
  send_downloadLink_phoneErr: boolean;
}
export const StyledFcaRafSendDownloadErrTipEle = styled("span")<FcaRafSendDownloadErrTipEleProps>`
  display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
  width: 285px;
  height: 22px;
  margin-top: 10px;
  margin-left: 6px;
  font-family: ${fontFamily_Gilroy_Medium};
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff4949;
`;