import styled from "styled-components";

interface FcaRafDownloadTextBoxProps {}

export const StyledFcaRafDownloadTextBox = styled("div")<FcaRafDownloadTextBoxProps>`
    height: 62px;
    border-radius: 31px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    background-color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#10af42"};
    box-sizing: border-box;
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 62px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0;
    min-width: 100%;
    vertical-align: middle;

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        display: none;
    }
    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        display: none;
    }
`;
