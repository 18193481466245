import React from "react";
import { StyledTitleSection } from "./styles";

interface Props {
  src: string;
  alt: string;
  titleText1: string;
  titleText2: string;
}

export const TitleSection: React.FC<Props> = ({
  src,
  alt,
  titleText1,
  titleText2
}) => {
  return (
    <StyledTitleSection>
      <div className="fca-raf-title-text-wrap">
        <p className="fca-raf-title-text-1">{titleText1}</p>
        <p className="fca-raf-title-text-2">{titleText2}</p>
      </div>
      <div className="raf_hero_v2_wrap">
        <img className="raf_hero_img_v2 screen_1440" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/raf_page/raf_hero_img_v3_1440/info%402x.png`} alt={alt}></img>
        <img className="raf_hero_img_v2 screen_1000" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/raf_page/raf_hero_img_v3_1000/info%402x.png`} alt={alt}></img>
        <img className="raf_hero_img_v2 screen_600" src={`https://getmiles.s3.amazonaws.com/assets/web_v2/raf_page/raf_hero_img_v3_600/info%402x.png`} alt={alt}></img>
      </div>
    </StyledTitleSection>
  );
};
