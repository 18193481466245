import React, { useMemo } from "react";
import { Styled_Tab_carousel_wrap, Styled_Tab_carousel } from "./styles";
import { useTransitionCarousel } from "./../transition_carousel";


interface Props {
  carousel_raffle_img: string;
  carousel_giftcard_img: string;
  carousel_donation_img: string;
  carousel_challenges_img: string;
  active_raffle_img: string;
  active_giftcard_img: string;
  active_donation_img: string;
  active_challenges_img: string;
  fade_raffle_img: string;
  fade_Challenges_img: string;
  fade_gift_card_img: string;
  fade_Donation_img: string;
  carousel_arrow_left_img: string;
  carousel_arrow_right_img: string;
}

export const Tab_carousel: React.FC<Props> = ({
  carousel_giftcard_img,
  carousel_raffle_img,
  carousel_donation_img,
  carousel_challenges_img,
  active_giftcard_img,
  active_raffle_img,
  active_donation_img,
  active_challenges_img,
  carousel_arrow_left_img,
  carousel_arrow_right_img,
}) => {
  const {items, ITEM_DATA} = useMemo(() => {
    const ITEM_DATA = [
      {
        tab: {
          title: 'ギフトカード',
          img: <img src={active_giftcard_img} alt='giftcard'/>,
        },
        title1: '毎日の移動が',
        title2: 'ギフトカードに',
        description: <>たまったマイルをつかって獲得できる様々なギフトカードを準備しています。</>,
        img: <img src={carousel_giftcard_img} alt="giftcard" />,
        bgColor: "#fafffc",
      },
      {
        tab: {
          title: '抽選',
          img: <img src={active_raffle_img} alt="raffle" />,
        },
        title1: 'マイルをつかって',
        title2: '抽選に参加',
        description: <>抽選会は毎週実施中<br />豪華な特典ゲットの大チャンス</>,
        img: <img src={carousel_raffle_img} alt="raffle" />,
        bgColor: "#edeeff",
      },
      {
        tab: {
          title: '寄付',
          img: <img src={active_donation_img} alt="donation" />,
        },
        title1: 'たまったマイルを',
        title2: '寄付する',
        description: `様々な寄付団体やプロジェクトにマイルを寄付することができる`,
        img: <img src={carousel_donation_img} alt="donation" />,
        bgColor: "#fff4f4",
      },
      {
        tab: {
          title: 'チャレンジ',
          img: <img src={active_challenges_img} alt="challenges" />,
        },
        title1: 'Milesを',
        title2: 'より楽しもう',
        description: 'チャレンジに参加して毎日の移動を楽しもう',
        img: <img src={carousel_challenges_img} alt="challenges" />,
        bgColor:  "#fffdf5",
      },
    ];

    const items = ITEM_DATA.map((data, index) => ({
      id: `slide_${index}`,
      renderItem: (
        <div
            id={`slide_0${index+1}`}
            className={`active carousel-item carousel_slide_wrap slide_0${index+1}`}>
        <div className="carousel_slide_inner_left">
          <div className="left_p_01">
            <div className="left_p_01_01">{data.title1}</div>
            <div className="left_p_01_02">{data.title2}</div>
          </div>
          <div className={`left_p_02 slide_0${index+1}`}>
            {data.description}
          </div>
        </div>
        {data.img}
      </div>
      ),
    }));
    return { items, ITEM_DATA };
  }, []);


  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItems,
    activeItem,
  } = useTransitionCarousel({items});

  const tabs = ITEM_DATA.map(({tab}, index) => (
    <div
      key={index}
      className={`tab_box tab_0${index+1} ${activeItem === index ? 'active' : ''}`}
      onClick={slideToItems[index]}
      aria-current="true"
    >
      {tab.img}
      <div className="title">{tab.title}</div>
      <div className="border_line" />
  </div>

  ));

  return (
    <Styled_Tab_carousel_wrap id="miles_v2_Tab_carousel_wrap" style={{backgroundColor: ITEM_DATA[activeItem].bgColor}}>
      <Styled_Tab_carousel>
        <div
          id="miles_tab_carousel"
          className="carousel slide carousel-fade"
        >
          <div className="carousel-indicators carousel_tab">
            {tabs}
          </div>

          <div className="carousel-inner">
            {carouselFragment}
          </div>
          
          <button
            className="carousel-control-prev carousel_arrow_left_wrap"
            type="button"
            onClick={slideToPrevItem}
          >
            <div className="miles_ui_large_than_768_screen" />
            <div className="miles_ui_less_than_768_screen" />
          </button>

          <button
            className="carousel-control-next carousel_arrow_right_wrap"
            type="button"
            onClick={slideToNextItem}
          >
            <div className="miles_ui_large_than_768_screen" />
            <div className="miles_ui_less_than_768_screen" />
          </button>

        </div>
      </Styled_Tab_carousel>
    </Styled_Tab_carousel_wrap>
  );
};
