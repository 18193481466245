import styled from "styled-components";

interface Props {}
export const StyledEle = styled("div")<Props>`
  width: 1228.1px;
  height: 1316.9px;
  padding: 0;
  border-radius: 32px;
  box-shadow: 0 0 17px 0 #e6e6e8;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title_top {
    width: 746px;
    height: 61px;
    font-family: Gilroy-Bold;
    font-size: 49px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 61px;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 118px;
  }

  .sub_title_01 {
    height: 61px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 61px;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 85px;
  }

  .miles_ui_our_partnerships_box_img_01 {
    height: 250px;
    width: 850px;
    margin-top: 43px;
  }

  .sub_title_02 {
    height: 61px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 61px;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 120px;
  }

  .miles_ui_our_partnerships_box_img_02 {
    width: 745px;
    height: 314px;
    margin-top: 43px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 320px;
    height: 835px;
    padding: 0;
    border-radius: 32px;

    .title_top {
      height: 36px;
      font-size: 26px;
      line-height: 36px;
      margin-top: 69px;
    }

    .sub_title_01 {
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      margin-top: 46px;
    }

    .miles_ui_our_partnerships_box_img_01 {
      height: 200px;
      width: 221px;
      margin-top: 56px;
    }

    .sub_title_02 {
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      margin-top: 100px;
    }

    .miles_ui_our_partnerships_box_img_02 {
      width: 205px;
      height: 175px;
      margin-top: 16.8px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 320px;
    height: 835px;
    padding: 0;
    border-radius: 32px;

    .title_top {
      height: 36px;
      font-size: 26px;
      line-height: 36px;
      margin-top: 69px;
    }

    .sub_title_01 {
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      margin-top: 46px;
    }

    .miles_ui_our_partnerships_box_img_01 {
      height: 200px;
      width: 221px;
      margin-top: 56px;
    }

    .sub_title_02 {
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      margin-top: 100px;
    }

    .miles_ui_our_partnerships_box_img_02 {
      width: 205px;
      height: 175px;
      margin-top: 16.8px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
      
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 745px;
    height: 1072.1px;
    padding: 0;
    border-radius: 32px;

    .title_top {
      height: 36px;
      font-size: 36px;
      line-height: 36px;
      margin-top: 102px;
    }

    .sub_title_01 {
      height:56px;
      font-size: 32px;
      line-height: 56px;
      color: #2d2f3b;
      margin-top: 73px;
    }

    .miles_ui_our_partnerships_box_img_01 {
      height: 185px;
      width: 628px;
      margin-top: 42px;
    }

    .sub_title_02 {
      height:56px;
      font-size: 32px;
      line-height: 56px;
      color: #2d2f3b;
      margin-top: 104px;
    }

    .miles_ui_our_partnerships_box_img_02 {
      width: 553px;
      height: 233px;
      margin-top: 43px;
    }
  }

  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    width: 1083px;
    height: 1315px;

    .title_top {
      font-size: 49px;
      height: 60px;
      line-height: 60px;
      margin-top: 120px;
    }

    .sub_title_01 {
      font-size: 36px;
      height: 56px;
      line-height: 56px;
      margin-top: 85px;
    }

    .miles_ui_our_partnerships_box_img_01 {
      height: 250px;
      width: 850px;
      margin-top: 46px;
    }

    .sub_title_02 {
      font-size: 36px;
      height: 56px;
      line-height: 56px;
      margin-top: 123px;
    }

    .miles_ui_our_partnerships_box_img_02 {
      width: 745px;
      height: 314px;
      margin-top: 45px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
      
  }
`;