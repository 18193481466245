import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  width: 100vw;
  margin: 0 auto;

  .miles_ui_about_page_whereWeAreHeaded {
    position: static;
    z-index: -1;
  }

  .miles_ui_about_page_whereWeAreHeaded_img {
    width: 100vw;
    height: auto;
    object-fit: cover;
    object-position: center center;
  }

  .about_page_text_001 {
    position: absolute;
    height: 57px;
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 57px;
    text-align: center;
    letter-spacing: normal;
    text-transform: capitalize;
    color: #2D2F3B;
;
    margin-top: calc(-55%);
    left: calc(50% - 100px);
  }

  .about_page_text_002 {
    position: absolute;
    height: 74px;
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 37px;
    text-align: center;
    color: #2D2F3B;
    margin-top: calc(-55% + 96px);
    left: calc(50% - 268px);
    width: 550px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
  
    .miles_ui_about_page_whereWeAreHeaded_img {
      height: auto;
      object-fit: cover;
      object-position: center center;
    }

    .about_page_text_001 {
      margin-top: calc(-70%);
      left: calc(50% - 50px);
      font-size: 18px;
      height: auto;
      line-height: 20px;
    }
  
    .about_page_text_002 {
      margin-top: calc(-70% + 40px);
      left: calc(50% - 130px);
      font-size: 12px;
      width: 270px;
      line-height: 20px;
      height: 48px;
    }
  
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  
    .miles_ui_about_page_whereWeAreHeaded_img {
      height: auto;
      object-fit: cover;
      object-position: center center;
    }

    .about_page_text_001 {
      margin-top: calc(-58%);
      left: calc(50% - 50px);
      font-size: 18px;
      height: auto;
      line-height: 20px;
    }
  
    .about_page_text_002 {
      margin-top: calc(-58% + 40px);
      left: calc(50% - 130px);
      font-size: 12px;
      width: 270px;
      line-height: 20px;
      height: 48px;
    }
  
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

    .miles_ui_about_page_whereWeAreHeaded_img {
      height: auto;
      object-fit: cover;
      object-position: center center;
    }

    .about_page_text_001 {
      margin-top: calc(-55%);
      left: calc(50% - 70px);
      font-size: 24px;
    }
  
    .about_page_text_002 {
      margin-top: calc(-55% + 70px);
      left: calc(50% - 180px);
      font-size: 16px;
      width: 350px;
    }
  
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .about_page_text_001 {
      margin-top: calc(-65%);
      left: calc(50% - 100px);
    }
  
    .about_page_text_002 {
      margin-top: calc(-65% + 96px);
      left: calc(50% - 268px);
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;