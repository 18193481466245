import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import CtaBannerDownloadPressKit from "./cta_banner_download_press_kit/index";
import { Styled_body_wrap } from "./styles";
import styled from "styled-components";

const {
  press_page_assets: assets,
} = config;

const CtaDiv = styled("div")`
position: absolute;
display: block;
top: 201px;
@media (max-width : 768px) {
  top: 109px;
}
@media only screen and (min-width : 769px) and (max-width : 1150px) {
  top: 211px;
}
`;

const Component: React.FC<{}> = observer(() => {
  return (
    <>
      <Styled_body_wrap className="">
      <div className="ciq-root">
          <div className="ciq-container">
            <picture className="top_banner_picture">
              <source className="top_banner_img" media="(max-width : 360px)" srcSet={assets.press_page_top_banner_background_v2}></source>
              <source className="top_banner_img" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.press_page_top_banner_background_v2}></source>
              <source className="top_banner_img" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.press_page_top_banner_background_v2}></source>
              <source className="top_banner_img" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.press_page_top_banner_background_v2}></source>
              <source  className="top_banner_img" media="(min-width : 1441px)" srcSet={assets.press_page_top_banner_background_2500_v2}></source>
              <img className="top_banner_img" alt={assets.press_page_top_banner_background_v2}></img>
            </picture>
            <div className="cta-div">
              <CtaDiv>
                <CtaBannerDownloadPressKit/>
              </CtaDiv>
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
