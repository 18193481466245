import styled from "styled-components";
interface Props {
  country?: string;
}

export const Styled_body_wrap = styled("div")<Props>`
  margin: 0 auto;
  padding: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .miles_ui_home_pre_footer_blankSpace {
    height: 125px;
    width: 100%;
  }

  .screen_larger_than_1150 {
    display: block;
  }

  .screen_between_768_and_1150 {
    display: none;
  }

  .screen_between_360_x_768 {
    display: none;
  }

  .inline_block {
    display: inline-block;
  }

  .display_block {
    display: block;
  }

  .Our_partnerships_box_space_pre {
    height: 241.7px;
    width: 100vw;
  }

  .Our_partnerships_box_space_after {
    height: 225.1px;
    width: 100vw;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_home_pre_footer_blankSpace {
      height: 92px;
      width: 100%;
    }

    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: none;
    }
    
    .screen_between_360_x_768 {
      display: block;
    }

    .Our_partnerships_box_space_pre {
      height: 100px;
    }
  
    .Our_partnerships_box_space_after {
      height: 100px;
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_home_pre_footer_blankSpace {
      height: 130px;
      width: 100%;
    }

    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: none;
    }
    
    .screen_between_360_x_768 {
      display: block;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .Our_partnerships_box_space_pre {
      height: 100px;
    }
  
    .Our_partnerships_box_space_after {
      height: 100px;
    }

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    
    .miles_ui_home_pre_footer_blankSpace {
      height: 181px;
      width: 100%;
    }

    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: block;
    }
    
    .screen_between_360_x_768 {
      display: none;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .Our_partnerships_box_space_pre {
      height: 242px;
    }
  
    .Our_partnerships_box_space_after {
      height: 225px;
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .miles_ui_home_pre_footer_blankSpace {
      height: 134px;
      width: 100%;
    }
    
    .Our_partnerships_box_space_pre {
      height: 242px;
    }
  
    .Our_partnerships_box_space_after {
      height: 225px;
    }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;