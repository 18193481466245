import styled from "styled-components";

interface Props {}
export const StyledSubTitle = styled("p")<Props>`
  height: 24px;
  margin: 0;
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.35px;
  text-align: center;
  color: #2d2f3b;
  margin-bottom: 42px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 32px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    text-align: center;
    color: #2d2f3b;
    margin-bottom: 22px;
    width: 237px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: 32px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    text-align: center;
    color: #2d2f3b;
    margin-bottom: 22px;
    width: 237px;
  }
  
`;