import React from "react";
import { StyledFcaRafSendDownloadErrTipEle } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {
  send_downloadLink_phoneErr: boolean;
}

export const SendDownloadErrTip: React.FC<Props> = ({
  send_downloadLink_phoneErr
}) => {
  const { t } = useTranslation();
  return (
    <StyledFcaRafSendDownloadErrTipEle send_downloadLink_phoneErr={send_downloadLink_phoneErr}>
      {t('rs_text_008')}
    </StyledFcaRafSendDownloadErrTipEle>
  );
};
