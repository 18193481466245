import styled from "styled-components";

export const Styled_body_wrap = styled("div")`

    background-color: #fbfbfb;
    height: auto;
    width: 100vw;
    position: absolute;
    margin-top: -115px;

    .main_body {
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      height: 66px;
      align-items: center;
      vertical-align: middle;
      line-height: 66px;
      justify-content: left;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 60px;

      .path_first {
        width: auto;
        height: 18px;
        font-family: Gilroy-SemiBold;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #10af42;
        margin: 0;
        text-decoration: none;
      }

      .path_icon {
        font-size: 18px;
        color: #5f5f69;
        margin: 0;
        margin-left: 5px;
        margin-right: 5px;
      }

      .path_second {
        width: auto;
        height: 18px;
        font-family: Gilroy-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 0;
        color: #5f5f69;
      }
    }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    margin-top: -70px;
    position: relative;
    margin-bottom: 50px;
    
    .main_body {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 30px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin-top: -108px;
    position: relative;
    margin-bottom: 50px;

    .main_body {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 30px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
   
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;