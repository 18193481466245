import React from "react";
import { StyledDownloadTextBox } from "./styles";

interface Props {
  downloadText: string;
}

export const DownloadTextBox: React.FC<Props> = ({
  downloadText
}) => {
  return (
    <StyledDownloadTextBox>{downloadText}</StyledDownloadTextBox>
  );
};
