import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { Styled_body_wrap } from "./styles";


const Component: React.FC<{}> = observer(() => {
  return (
    <>
      <Styled_body_wrap className="ciq_root">
        <div className="ciq_container" >
          <div className="item">1</div>
          <div className="item">2</div>
          <div className="item">3</div>
          <div className="item">4</div>
          <div className="item">5</div>
          <div className="item">6</div>
          <div className="item">7</div>
          <div className="item">8</div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
