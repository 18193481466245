import styled from "styled-components";

export const MainWrap = styled("div")`
    width: 100%;
    margin: 0 auto;
    height: 100%;
    min-height: 100vh;
    background-color: #ffffff;

    .preferences_top_wrap {
      background-color: #FFFCF5;
    }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {}
`;