import styled from "styled-components";

interface Props {}
export const Styled_Box = styled("div")<Props>`
  margin: 0;
  padding: 0;
  height: auto;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;

  .inline_block {
    display: inline-block;
  }

  .title {
    width: 591px;
    height: 78px;
    margin: 0;
    font-family: Noto Sans JP;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 78px;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 36px;
  }

  .sub_title {
    width: 591px;
    height: 40px;
    margin: 0;
    font-family: Noto Sans JP;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.46px;
    text-align: center;
    color: #82828c;
  }

  .cta_box {
    width: 602px;
    width: 718px;
    height: 52px;
    padding: 0;
    margin: 0;
    margin-top: 33px;
    z-index: 900;

    img {
      display: inline-block;
      width: 50px;
      height: 50px;
    }
  }

  .screen_1150_to_1440_only {
    display: none;
  }

  .cta_box.desktop {
    display: block;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-width: 360px;

    .cta_box.desktop {
      display: block;
      width: max-content;
      height: 40px;
      margin-top: 20px;
      position: relative;
      
      img {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      width: max-content;
      height: 46px;
      margin: 30px 0 0 0;
      font-family: Noto Sans JP;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 46px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
    }
  
    .sub_title {
      width: max-content;
      height: 31px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 31px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #82828c;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .cta_box.desktop {
      display: block;
      width: max-content;
      height: 40px;
      margin-top: 20px;
      position: relative;
      
      img {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      width: max-content;
      height: 42px;
      margin: 0 0 10px 0;
      font-family: Noto Sans JP;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 46px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 30px;
    }
  
    .sub_title {
      width: 430px;
      height: 25px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: center;
      color: #82828c;
    }
  }

  /* iPhone 11 Pro Max----------- */
  @media
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 458dpi),
  only screen and (min-resolution: 3dppx) { 
    .cta_box.desktop {
      display: block;
      width: max-content;
      height: 40px;
      margin-top: 13px;
      position: relative;
      
      img {
        width: 40px;
        height: 40px;
      }
    }
  
    .title {
      width: max-content;
      height: 46px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 46px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 26px;
    }
  
    .sub_title {
      width: max-content;
      height: 31px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 31px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #82828c;
      margin-top: 26px;
    }
  }

  /* iPhone 12/ 12 Pro ----------- */
  @media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    .cta_box.desktop {
      display: block;
      width: max-content;
      height: 40px;
      margin-top: 13px;
      position: relative;
      
      img {
        width: 40px;
        height: 40px;
      }
    }
  
    .title {
      width: max-content;
      height: 46px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 46px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 26px;
    }
  
    .sub_title {
      width: max-content;
      height: 31px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 31px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #82828c;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .cta_box.desktop {
      display: block;
      width: max-content;
      height: 40px;
      margin-top: 13px;
      position: relative;
      
      img {
        width: 40px;
        height: 40px;
      }
    }
  
    .title {
      width: max-content;
      height: 46px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 46px;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 26px;
    }
  
    .sub_title {
      width: max-content;
      height: 31px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 31px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #82828c;
    }
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .title {
      width: max-content;
      height: 46px;
      margin: 0;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 42px;
      letter-spacing: 0.75px;
      text-align: center;
      font-family: Noto Sans JP;
      color: #2d2f3b;
      margin-top: 48px;
      margin-bottom: 20px;
    }

    .sub_title {
      width: max-content;
      height: 35px;
      margin: 0;
      font-family: Noto Sans JP;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: center;
      color: #82828c;
    }

    .cta_box {
      width: 464px;
      margin-top: 20px;
    }

    .screen_1150_to_1440_only {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      width: 110px;
      .apple {
        margin-right: 10px;
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .cta_box.desktop {
      margin-top: 30px;
    }
  }

  @media only screen and (width : 1440px) {
    .cta_box.desktop {
      margin-top: 30px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;