import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactSpringCarouselItem, useSpringCarousel as _useSpringCarousel } from "react-spring-carousel-js";
import { SpringConfig, config as SPRING_CONFIGS } from 'react-spring';
export type { ReactSpringCarouselItem } from "react-spring-carousel-js";

export interface UseSpringCarouselArgs {
    items: ReactSpringCarouselItem[];
    autoAdvanceDelayMs?: number;
    springConfig?: SpringConfig;
    itemsPerSlide?: number;
    withDots?: boolean,
};


export const DEFAULT_ARGS: Partial<UseSpringCarouselArgs> = {
    autoAdvanceDelayMs: 4000,
    springConfig: SPRING_CONFIGS.slow,
    itemsPerSlide: 1,
    withDots: false,
};


export function useSpringCarousel(args: UseSpringCarouselArgs) {
    const {
        items,
        autoAdvanceDelayMs,
        springConfig,
        itemsPerSlide,
        withDots,
    } = {...DEFAULT_ARGS, ...args};
    const {
        carouselFragment,
        slideToPrevItem,
        slideToNextItem,
        slideToItem,
        useListenToCustomEvent,
        getCurrentActiveItem,
    } = _useSpringCarousel({
        withThumbs: false,
        withLoop: true,
        items,
        itemsPerSlide,
        springConfig,
    });
    
    const timerRef = useRef<null|NodeJS.Timeout>(null);

    const {startTimer, clearTimer, slideToItems} = useMemo(() => {
        function clearTimer() {
            if (timerRef.current != null) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }

        return {
            startTimer: function() {
                clearTimer();
                if (typeof autoAdvanceDelayMs === "number" && autoAdvanceDelayMs > 0) {
                    timerRef.current = setTimeout(slideToNextItem, autoAdvanceDelayMs);
                }
            },
            clearTimer,
            slideToItems: items.map((_, idx) => () => {
                slideToItem(idx);
            }),
        };
    }, [autoAdvanceDelayMs, items, slideToItem, slideToNextItem])

    const [ activeItem, setActiveItem ] = useState(0);

    const {dotsFragment} = useMemo(() => {
        function renderDotsFragment() {
            if (!withDots) {
                return null;
            }
            const numItems = items.length;
            const dots = new Array(numItems).fill(null).map((_, index) => (
                <li
                    key={index}
                    className={`react-multi-carousel-dot ${
                        activeItem === index
                        ? "react-multi-carousel-dot--active"
                        : ""
                    } `}
                >
                    <button
                        aria-label={`Go to slide ${index + 1}`}
                        onClick={() => slideToItem(index)}
                    />
                </li>
            ));
            return (
                <ul className={`react-multi-carousel-dot-list`}>
                    {dots}
                </ul>
            );
        }

        return {
            dotsFragment: renderDotsFragment(),
        };
    }, [activeItem, items, slideToItem, withDots])

    useListenToCustomEvent((data) => {
        if (data.eventName === 'onSlideStartChange') {
            clearTimer();
            setActiveItem(getCurrentActiveItem().index);
        } else if (data.eventName === 'onSlideChange') {
            setActiveItem(getCurrentActiveItem().index);
            startTimer();
        }
    });

    useEffect(function() {
        startTimer();
        return clearTimer;
    }, []);

    return {
        carouselFragment,
        dotsFragment,
        slideToPrevItem,
        slideToNextItem,
        slideToItem,
        slideToItems,
        activeItem,
    };
}
