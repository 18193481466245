import { home_page_assets } from './home_page_assets';
import { jobs_page_assets } from './jobs_page_assets';
import { legal_page_assets } from './legal_page_assets';
import { password_reset_page_assets } from './password_reset_page_assets';
import { partner_page_assets } from './partner_page_assets';
import { auto_oem_page_assets } from './auto_oem_page_assets';
import { cities_page_assets } from './cities_page_assets';
import { contact_page_assets } from './contact_page_assets';
import { press_page_assets } from './press_page_assets';
import { help_page_assets } from './help_page_assets';
import { about_page_assets } from './about_page_assets';
import { jp_home_page_assets } from './jp_home_page_assets';
import { not_found_page_assets } from './not_found_page_assets';


export const commonIcon = {
  download_icon: require('src/assets/commonIcon/group-6@3x.png'),
  share_icon: require('src/assets/commonIcon/share@3x.png'),
  redeem_rewards_icon: require('src/assets/commonIcon/redeem-rewards@3x.png'),
  listItemContent_2: `Go to the Rewards page and  search for `,
  listItemContent_3: `Redeem the reward for exclusive savings`,
  email_preference_backgroundImg_desktop: require('src/assets/emailPreference/desktop/bg_unsubscribe@3x.png'),
  email_preference_backgroundImg_tablet: require('src/assets/emailPreference/tablet/bg_unsubscribe@3x.png'),
  email_preference_backgroundImg_phone: require('src/assets/emailPreference/phone/bg_unsubscribe@3x.png'),
  email_preference_group_background: ["#f5fdfd", "#fdfbf9", "#fffcf6",],
  mi_sad_face: require('src/assets/commonImg/mi_sad_face@3x.png'),
  mi_email_preference_thankyou_img: require('src/assets/emailPreference/thankyou/thankyou_resubscribe@3x.png'),
  ...home_page_assets,
  ...jobs_page_assets,
  ...password_reset_page_assets,
  ...legal_page_assets,
  partner_page_assets,
  auto_oem_page_assets,
  cities_page_assets,
  contact_page_assets,
  press_page_assets,
  help_page_assets,
  about_page_assets,
  jp_home_page_assets,
  not_found_page_assets,
  stellantis_welcome_img: `https://getmiles.s3.amazonaws.com/assets/web_v2/fca_web/fca_sso_signIn_signUp/Group+67%403x.png`,
};
