import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
    margin: 0;
    width: 100vw;
    height: auto;

    .main_container {
        margin: 0 auto;
    }

    .core_solution_01_wrap {
        width: 100vw;
        height: 1062px;
        background-color: #fcf3df;
        display: flex;
        justify-content: center;
    }

    .core_solution_02_wrap {
        width: 100vw;
        height: 950px;
        background-color: #f8fffb;
        display: flex;
        justify-content: center;
    }

    .core_solution_03_wrap {
        width: 100vw;
        height: 950px;
        background-color: #fefbf4;
        display: flex;
        justify-content: center;
    }

    .core_solution_01_img {
        width: 1058px;
        height: 822px;
        object-fit: contain;
        margin-top: 157px;
    }

    .core_solution_02_img {
        width: 1058px;
        height: 640px;
        object-fit: contain;
        margin-top: 155px;
    }

    .core_solution_03_img {
        width: 1058px;
        height: 640px;
        object-fit: contain;
        margin-top: 155px;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
        .core_solution_01_wrap {
            height: 962px;
        }

        .core_solution_02_wrap {
            height: 667px;
        }

        .core_solution_03_wrap {
            height: 667px;
        }

        .core_solution_01_img {
            width: 300px;
            height: 789px;
            margin-top: 92px;
        }
    
        .core_solution_02_img {
            width: 292px;
            height: 554px;
            margin-top: 56px;
        }

        .core_solution_03_img {
            width: 292px;
            height: 522px;
            margin-top: 72px;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .core_solution_01_wrap {
            height: 962px;
        }

        .core_solution_02_wrap {
            height: 667px;
        }

        .core_solution_03_wrap {
            height: 667px;
        }

        .core_solution_01_img {
            width: 300px;
            height: 789px;
            margin-top: 92px;
        }
    
        .core_solution_02_img {
            width: 292px;
            height: 554px;
            margin-top: 56px;
        }

        .core_solution_03_img {
            width: 292px;
            height: 522px;
            margin-top: 72px;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        .core_solution_01_wrap {
            height: 1074px;
        }

        .core_solution_01_img {
            width: 746px;
            height: 908px;
            margin-top: 80px;
        }
    
        .core_solution_02_img {
            width: 520px;
            height: 709px;
            margin-top: 120px;
        }

        .core_solution_03_img {
            width: 439px;
            height: 731px;
            margin-top: 120px;
        }
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .core_solution_01_img {
            width: 1031px;
            height: 822px;
            margin-top: 157px;
        }
    
        .core_solution_02_img {
            width: 1058px;
            height: 640px;
            margin-top: 155px;
        }

        .core_solution_03_img {
            width: 1023px;
            height: 640px;
            margin-top: 155px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
    }
`;