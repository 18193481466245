import styled from "styled-components";

export const FcaRafWrap = styled("div")`
  width: 1440px;
  min-height: 100vh;
  overflow: visible;
  margin: 0;
  .fcaRaf-mainBody-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: max-content;
    max-width: 100vw;
    height: 100%;
    min-height: 100%;
    overflow: visible;
    margin: auto;
    .fcaRaf-mainBody-wrap {
        height: auto;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: max-content;
    max-width: 100vw;
    height: 100%;
    min-height: 100%;
    overflow: visible;
    margin: auto;
    .fcaRaf-mainBody-wrap {
        height: auto;
    }
  }
  
  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .fcaRaf-mainBody-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 768px) and (max-width : 1150px) {
    width: 768px;
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    width: 768px;
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    width: 1280px;
  }
`;