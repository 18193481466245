import styled from "styled-components";

interface FooterProps {}
export const StyledPreFooter = styled("div")<FooterProps>`
  padding: 0;
  margin: 0;
  min-height: 612px;
  height: 612px;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;

  &.miles_ui_pre_footer_commonSize {
    min-height: 487px;
    height: 487px;
  }

  .miles_ui_screen_larger_than_768 {
    display: block;
  }

  .miles_ui_screen_less_than_768 {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .inline_block {
    display: inline-block;
  }

  .title {
    height: 60px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
  }

  .cta_wrap {
    height: 87px;
    padding: 0;
    margin: 0;
    margin-top: 51px;
  }

  .app_store_url_wrap {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    box-sizing: border-box;
    
    .app_store_box {
      width: 342px;
      height: 70px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      box-sizing: border-box;
      &.apple {
        margin-right: 120px;
      }

      .app_store_logo {
        margin-right: 16px;
        min-width: 70px;
        height: 70px;
      }

      .app_store_rating_wrap {
        .rating_title {
          width: max-content;
          height: 20px;
          margin-top: 5px;
          font-family: Gilroy-SemiBold;
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d2f3b;
        }

        .rating_star_wrap {
          margin-top: 10px;
          .star_icon_img {
            width: 22px;
            height: 22px;
            margin-right: 4px;
          }

          .rating_number {
            height: 22px;
            margin-left: 4px;
            font-family: Gilroy-Medium;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9b9ba5;
            vertical-align: middle;
          }
        }
        
      }

    }
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-height: 360px;
    height: 360px;

    &.miles_ui_pre_footer_commonSize {
      min-height: 260px;
      height: 260px;
    }

    .miles_ui_screen_larger_than_768 {
      display: none;
    }
  
    .miles_ui_screen_less_than_768 {
      display: block;
    }

    a {
      text-decoration: none;
    }

    .inline_block {
      display: inline-block;
    }

    .title {
      width: 288px;
      height: 84px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
    }

    .cta_wrap {
      display: none;
    }

    .app_store_url_wrap {
      margin-top: 20px;
      flex-direction: column;
      
      .app_store_box {
        width: 240px;
        height: 46px;
        margin-top: 30px;
        &.apple {
          margin-right: 0;
        }

        .app_store_logo {
          margin-right: 11px;
          min-width: 46px;
          height: 46px;
        }

        .app_store_rating_wrap {
          .rating_title {            
            width: 190px;
            height: 14px;
            margin-top: 2px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2d2f3b;
          }

          .rating_star_wrap {
            margin-top: 4px;
            .star_icon_img {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }

            .rating_number {
              height: 20px;
              margin-left: 9px;
              font-family: Gilroy-Medium;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 20px;
              letter-spacing: normal;
              color: #9b9ba5;
              vertical-align: middle;
            }
          }
          
        }

      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    min-height: 400px;
    height: 400px;

    &.miles_ui_pre_footer_commonSize {
      min-height: 280px;
      height: 280px;
    }

    .miles_ui_screen_larger_than_768 {
      display: none;
    }
  
    .miles_ui_screen_less_than_768 {
      display: block;
    }

    a {
      text-decoration: none;
    }

    .inline_block {
      display: inline-block;
    }

    .title {
      width: 288px;
      height: 84px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
    }

    .cta_wrap {
      display: none;
    }

    .app_store_url_wrap {
      margin-top: 20px;
      flex-direction: column;
      
      .app_store_box {
        width: 240px;
        height: 46px;
        margin-top: 30px;
        &.apple {
          margin-right: 0;
        }

        .app_store_logo {
          margin-right: 11px;
          min-width: 46px;
          height: 46px;
        }

        .app_store_rating_wrap {
          .rating_title {            
            width: 190px;
            height: 14px;
            margin-top: 2px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2d2f3b;
          }

          .rating_star_wrap {
            margin-top: 4px;
            .star_icon_img {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }

            .rating_number {
              height: 20px;
              margin-left: 9px;
              font-family: Gilroy-Medium;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 20px;
              letter-spacing: normal;
              color: #9b9ba5;
              vertical-align: middle;
            }
          }
          
        }

      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 512px;
    height: 512px;

    &.miles_ui_pre_footer_commonSize {
      min-height: 345px;
      height: 345px;
    }

    a {
      text-decoration: none;
    }

    .inline_block {
      display: inline-block;
    }

    .title {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: 1px;
      text-align: center;
      color: #2d2f3b;
    }

    .cta_wrap {
      height: 52px;
      padding: 0;
      margin: 0;
      margin-top: 35px;
    }

    .app_store_url_wrap {
      margin-top: 40px;
      
      .app_store_box {
        width: 245px;
        height: 50px;

        &.apple {
          margin-right: 50px;
        }

        .app_store_logo {
          margin-right: 11px;
          min-width: 50px;
          height: 50px;
        }

        .app_store_rating_wrap {
          .rating_title {
            width: max-content;
            height: 14px;
            margin-top: 5px;
            font-family: Gilroy-SemiBold;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2d2f3b;
          }

          .rating_star_wrap {
            margin-top: 5px;
            .star_icon_img {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }

            .rating_number {
              height: 14px;
              margin-left: 7px;
              font-family: Gilroy-Medium;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #9b9ba5;
              vertical-align: middle;
            }
          }
          
        }

      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    &.miles_ui_pre_footer_commonSize {
      min-height: 496px;
      height: 496px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;