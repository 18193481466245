// S3 https://s3.console.aws.amazon.com/s3/buckets/getmiles?prefix=assets/web_v2/partner_page/&showversions=false
import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";
import { store } from "../../Store";
import Help_your_brand from "./help_your_brand/index";
import Core_solution from "./core_solution/index";
import Pre_footer_form from "./pre_footer_form/index";

const {
  auto_oem_page_assets: assets,
} = config;

let firstRender = true;

const MilesWeb_AutoOEMPage: React.FC<{}> = observer(() => {

  const [phoneNumber, setPhoneNumber] = useState('');

  const [position, setPosition] = useState(0);
  
  const inputEl_on_dialog = useRef<HTMLInputElement>(null);

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (inputEl_on_dialog.current) {
      inputEl_on_dialog.current!.selectionStart = position;
      inputEl_on_dialog.current!.selectionEnd = position;
    }
  }, [position, phoneNumber]);

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_AutoOEMPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_AutoOEMPage -- did mount");

    firstRender = false;
  }, []);

  const scoll_to_pre_footer_form = () => {
    let scrollFormDiv = document && document.getElementById("partner_page_let_get_started")!.offsetTop;
    window.scrollTo({ top: scrollFormDiv - 20, behavior: 'smooth'});
  }

  return (
    <Styled_body_wrap>

      <div className="miles_ui_top_hero_01">
        <div className="main_container">
          <picture>
            <source className="section_left_wrap" media="(max-width : 360px)" srcSet={assets.oem_hero_01_360_img}></source>
            <source className="section_left_wrap" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.oem_hero_01_360_x_768_img}></source>
            <source className="section_left_wrap" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.oem_hero_01_768_x_1150_img}></source>
            <source className="section_left_wrap" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.oem_hero_01_1150_x_1440_img}></source>
            <source  className="section_left_wrap" media="(min-width : 1441px)" srcSet={assets.oem_hero_01_largerThan_1440_img}></source>
            <img className="section_left_wrap" alt={`section_left_wrap`}></img>
          </picture>
          <div className="miles_ui_top_content_wrap">
            <div className="miles_ui_top_content_02">
            Loyalty for Next Gen Mobility
            </div>
            <div className="miles_ui_top_content_03">
            Integrate and leverage Miles’ solutions to boost your brand’s customer loyalty and engagement
            </div>
            <button className={`miles_ui_btn_partner_with_us miles_ui_btn_primary`} onClick={scoll_to_pre_footer_form}>Partner with Us</button>

          </div>

        </div>
      </div>

      <Help_your_brand />

      <Core_solution />
      
      <Pre_footer_form />

    </Styled_body_wrap>
  );
});

export default MilesWeb_AutoOEMPage;
