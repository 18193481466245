import styled from "styled-components";

interface Props {}
export const MainWrapStyled = styled("div")<Props>`
  .gradient_box {
    width: 238px;
    height: 600px;
    position: absolute;
    z-index: 900;
    border: 0;
    opacity: 1;
    top: 125px;
    right: -558px;
    visibility: visible;
    background-image: linear-gradient(to right, transparent, rgb(248,253,248,0) 0, rgb(248,253,248, 89%) 30%, rgb(248,253,248, 100%) 56%);
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .gradient_box {
      width: 238px;
      height: 600px;
      right: -360px;
      background-image: linear-gradient(to right, transparent, rgb(248,253,248,0) 0, rgb(248,253,248, 89%) 30%, rgb(248,253,248, 100%) 56%);
    }
  
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;