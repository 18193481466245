import styled from "styled-components";
interface Props {
  country?: string;
}

export const Styled_body_wrap = styled("div")<Props>`
  margin: 0 auto;
  padding: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .miles_ui_home_pre_footer_blankSpace {
    height: 125px;
    width: 100%;
  }

  .screen_larger_than_1150 {
    display: block;
  }

  .screen_between_768_and_1150 {
    display: none;
  }

  .screen_between_360_x_768 {
    display: none;
  }

  .inline_block {
    display: inline-block;
  }

  .display_block {
    display: block;
  }

  .miles_ui_top_hero_01 {
    margin: 0;
    width: 100vw;
    height: 950px;
    background: #fffdfa;

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }
    
  }

  .miles_ui_partner_lottie_01 {
    margin: 0;
    height: 950px;
    & svg {
      height: 950px;

    }

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }
  }

  .miles_ui_top_content_wrap {
    margin: 0;
    margin-top: -653px;
    margin-left: 90px;
    position: absolute;

    .miles_ui_top_content_01 {
      width: max-content;
      height: auto;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-SemiBold'};
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #2d2f3b;
    }

    .miles_ui_top_content_02 {
      width: ${(props) => props.country === 'jp' ? '370px' : '433px'};
      height: 98px;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      color: #2d2f3b;
      margin-top: 10px;
    }

    .miles_ui_top_content_03 {
      width: 433px;
      height: 90px;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.31px;
      color: #5f5f69;
      margin-top: 23px;
    }

    button {
      width: 319px;
      height: 64px;
      padding: 0;
      border-radius: 32px;
      background-color: #10af42;
      cursor: pointer;
      border: none;
      outline: none;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-SemiBold'};
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 34px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_home_pre_footer_blankSpace {
      height: 92px;
      width: 100%;
    }

    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: none;
    }
    
    .screen_between_360_x_768 {
      display: block;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .miles_ui_top_hero_01 {
      height: auto;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .miles_ui_partner_lottie_between_360_x_768 {
      margin: 0;
      margin-right: auto;
      height: auto;
      margin-top: -150px;
      
      & svg {
        height: inherit;
  
      }
  
      .main_container {
        margin: 0 auto;
        width: 100vw;
      }
    }
  
    .miles_ui_top_content_wrap {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .miles_ui_top_content_01 {
        width: max-content;
        height: 36px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 12px;
      }
  
      .miles_ui_top_content_02 {
        width: ${(props) => props.country === 'jp' ? 'max-content' : '259px'};
        height: 64px;
        margin: 0 auto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 2px;
      }
  
      .miles_ui_top_content_03 {
        display: none;
      }
  
      button {
        width: 159px;
        height: 40px;
        margin: 24px auto 60px auto;
        padding: 0;
        border-radius: 25px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;

        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.5px;
        text-align: center;
        color: #ffffff;
      }

    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_home_pre_footer_blankSpace {
      height: 130px;
      width: 100%;
    }

    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: none;
    }
    
    .screen_between_360_x_768 {
      display: block;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .miles_ui_top_hero_01 {
      height: auto;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .miles_ui_partner_lottie_between_360_x_768 {
      margin: 0;
      margin-right: auto;
      height: auto;
      margin-top: -200px;
      
      & svg {
        height: inherit;
  
      }
  
      .main_container {
        margin: 0 auto;
        width: 100vw;
      }
    }
  
    .miles_ui_top_content_wrap {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .miles_ui_top_content_01 {
        width: max-content;
        height: 36px;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 16px;
      }
  
      .miles_ui_top_content_02 {
        width: ${(props) => props.country === 'jp' ? 'max-content' : '259px'};
        height: 64px;
        margin: 0 auto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 2px;
      }
  
      .miles_ui_top_content_03 {
        display: none;
      }
  
      button {
        width: 159px;
        height: 40px;
        margin: 24px auto 66px auto;
        padding: 0;
        border-radius: 25px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;

        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.5px;
        text-align: center;
        color: #ffffff;
      }

    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    
    .miles_ui_home_pre_footer_blankSpace {
      height: 181px;
      width: 100%;
    }

    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: block;
    }
    
    .screen_between_360_x_768 {
      display: none;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .miles_ui_top_hero_01 {
      height: auto;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .miles_ui_partner_lottie_between_768_and_1150 {
      margin: 0;
      margin-right: auto;
      height: auto;
      margin-top: -250px;
      
      & svg {
        height: inherit;
  
      }
  
      .main_container {
        margin: 0 auto;
        width: 100vw;
      }
    }
  
    .miles_ui_top_content_wrap {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .miles_ui_top_content_01 {
        width: max-content;
        height: 36px;
        margin: 0 auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-SemiBold'};
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 53px;
      }
  
      .miles_ui_top_content_02 {
        width: max-content;
        height: 44px;
        margin: 0 auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.69;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: 10px;
      }
  
      .miles_ui_top_content_03 {
        display: none;
      }
  
      button {
        width: 217px;
        height: 52px;
        margin: 42px auto 0 auto;
        padding: 0;
        border-radius: 25px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-SemiBold'};
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.7px;
        text-align: center;
        color: #ffffff;
      }

    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .miles_ui_home_pre_footer_blankSpace {
      height: 134px;
      width: 100%;
    }
    
    .miles_ui_top_hero_01 {
      .main_container {
        width: 100vw;
      }
      
    }
  
    .miles_ui_partner_lottie_01 {
      margin-right: auto;
  
      .main_container {
        width: 100vw;
      }
    }

    .miles_ui_top_content_wrap {
      margin-left: 118px;
    }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .miles_ui_top_hero_01 {
      width: 100vw;
      height: 950px;
      background: #fffdfa;
  
      .main_container {
        margin: 0 auto;
        width: 1440px;
      }
      
    }
  
    .miles_ui_partner_lottie_01 {
      margin: 0;
      height: 950px;
      & svg {
        height: 950px;
  
      }
  
      .main_container {
        margin: 0 auto;
        width: 1440px;
      }
    }
  
  }
`;