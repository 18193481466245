import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSpring } from "react-spring";
import { Team, AvatarInfo } from '../avatar_box/index';
import { TEAMS } from './config';
import {
    AnimationConfig,
    AnimationColumn,
    TeamControls,
    CoreAvatarControls,
    AvatarControls,
} from './types';


export function useCoreAvatarAnimation() {
    const [selectedTeam, setSelectedTeam] = useState<Team>('design');
    const [selectedColumn, setSelectedColumn] = useState<number>(0);
    const timerRef = useRef<null|NodeJS.Timeout>(null);
    const clearTimer = useCallback(() => {
        if (timerRef.current != null) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, []);
    const startTimerRef = useRef<null|(() => void)>(null);

    const [animationStyle, animationApi] = useSpring(() => ({
        from: { marginLeft: 0 },
        onRest: () => {
            startTimerRef.current?.();
        }
    }));
    const makeCoreAvatarControls = useMemo(() => {
        return function makeCoreAvatarControls(info?: AvatarInfo): CoreAvatarControls {
            const childInfos = info?.childInfos;
            const minMarginLeft = childInfos?.design?.[0] || 0;
    
            function nop() {}
    
            const teamControlsByTeam = Object.fromEntries(TEAMS.map((team) => [team, {
                onClick: nop,
                columnIndex: 0,
            }])) as {[id in Team]: TeamControls};
    
            function slideToTeam(team: Team) {
                teamControlsByTeam[team].onClick();
            }
    
            const animationColumns: AnimationColumn[] = [];
    
            function slideToColumn(index: number, previousIndex?: number) {
                clearTimer();
                const numColumns = animationColumns.length;
                if (index < 0 || index >= numColumns) {
                    return;
                }
                const {team, animationConfig} = animationColumns[index];
                setSelectedTeam(() => team);
                setSelectedColumn(() => index);
                if (previousIndex != null) {
                    const directDistance = Math.abs(index - previousIndex);
                    if (directDistance * 2 > numColumns) {
                        // it's cheaper to animate in the opposite direction.
                        const previousConfig = animationColumns[previousIndex].animationConfig
                        const previousMarginLeft = previousConfig.to.marginLeft
                        if (index > numColumns / 2) {
                            animationApi.set({
                                marginLeft: (
                                    previousMarginLeft - info!.rootElement.clientWidth
                                ), 
                            });    
                        } else {
                            animationApi.set({
                                marginLeft: (
                                    previousMarginLeft + info!.rootElement.clientWidth
                                ), 
                            });    
                        }
                    }
                }
                animationApi.start({
                    ...animationConfig,
                });
            }
    
            for (let team of TEAMS) {
                const marginLefts = childInfos?.[team];
                if (marginLefts == null) {
                    continue;
                }
                const startColumnIndex = animationColumns.length;
                teamControlsByTeam[team].columnIndex = startColumnIndex;
                teamControlsByTeam[team].onClick = function onTeamClick() {
                    slideToColumn(startColumnIndex);
                };
                for (let marginLeft of marginLefts) {
                    animationColumns.push({
                        team,
                        animationConfig: {
                            to: {
                                marginLeft: -(
                                    info!.rootElement.offsetLeft
                                    + marginLeft
                                    - minMarginLeft
                                ),
                            }
                        } as AnimationConfig
                    })
                }
            }

            return {
                ...teamControlsByTeam,
                minMarginLeft,
                slideToTeam,
                slideToColumn,
                animationColumns,
            }
        }
    }, [animationApi, clearTimer]);
    return {
        animationStyle,
        animationApi,
        selectedTeam,
        selectedColumn,
        clearTimer,
        timerRef,
        startTimerRef,
        makeCoreAvatarControls,
    }
}

export interface UseAvatarAnimationArgs {
    numColumnsPerSlide: number;
    autoAdvanceDelayMs: number;
}

export function useAvatarAnimation(args: UseAvatarAnimationArgs) {
    const {
        numColumnsPerSlide,
        autoAdvanceDelayMs,    
    } = args;
    const {
        animationStyle,
        animationApi,
        selectedTeam,
        selectedColumn,
        clearTimer,
        timerRef,
        startTimerRef,
        makeCoreAvatarControls,
    } = useCoreAvatarAnimation();

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const [coreAvatarControls, setCoreAvatarControls] = useState(
        () => makeCoreAvatarControls());

    const avatarRefFn = useCallback((info: AvatarInfo) => {
        setCoreAvatarControls(makeCoreAvatarControls(info));
    }, [makeCoreAvatarControls]);

    const {
        animationIndexes,
    } = useMemo(() => {
        const { animationColumns } = coreAvatarControls;
        const numColumns = animationColumns.length;
        const animationIndexes: number[] = [];
        for (let i = 0; i < numColumns; i += numColumnsPerSlide) {
            animationIndexes.push(i);
        }
        return {
            animationIndexes,
        };
    }, [coreAvatarControls, numColumnsPerSlide]);

    const {
        findClosestLeftIndex,
        slideToColumn,
        slideToIndex,
        slideToPrevious,
        slideToNext,
    } = useMemo(() => {
        const numIndexes = animationIndexes.length;
    
        function slideToColumn(columnIndex: number) {
            if (columnIndex !== selectedColumn) {
                coreAvatarControls.slideToColumn(columnIndex, selectedColumn);
            }
        }

        function findClosestLeftIndex() {
            let index = 0;
            for (let i = 0; i < numIndexes; i++) {
                if (animationIndexes[i] > selectedColumn) {
                    break;
                }
                index = i;
            }
            return index;
        }

        function slideToIndex(index: number) {
            if (index < 0 || index >= animationIndexes.length) {
                return;
            }
            slideToColumn(animationIndexes[index]);
            setSelectedIndex(() => index);
        }

        function slideToNext() {
            slideToIndex((selectedIndex + 1) % numIndexes);
        }

        function slideToPrevious() {
            slideToIndex((selectedIndex - 1 + numIndexes) % numIndexes);
        }

        for (let team of TEAMS) {
            const { columnIndex } = coreAvatarControls[team];
            coreAvatarControls[team].onClick = function onTeamClick() {
                slideToColumn(columnIndex);
            };
        }

        return {
            findClosestLeftIndex,
            slideToColumn,
            slideToIndex,
            slideToNext,
            slideToPrevious,
        }
    }, [animationIndexes, coreAvatarControls, selectedColumn, selectedIndex]);

    const closestLeftIndex = findClosestLeftIndex();
    if (closestLeftIndex != selectedIndex) {
        setSelectedIndex((_) => closestLeftIndex);
    }

    const avatarControls: AvatarControls = useMemo(() => ({
        ...coreAvatarControls,
        slideToColumn,
        animationIndexes,
        slideToIndex,
        slideToPrevious,
        slideToNext,
    }), [slideToColumn]);

    // Switch to the middle AvatarBox so we can smoothly slide left if needed.
    useEffect(function() {
        if (coreAvatarControls.animationColumns.length > 0) {
            animationApi.start({
                ...coreAvatarControls.animationColumns[0].animationConfig,
                immediate: true,
            });
        }
    }, [coreAvatarControls, animationApi, coreAvatarControls.animationColumns]);

    const startTimer = startTimerRef.current = useCallback(() => {
        clearTimer();
        if (autoAdvanceDelayMs > 0) {
            timerRef.current = setTimeout(avatarControls.slideToNext, autoAdvanceDelayMs);
        }
    }, [avatarControls.slideToNext, clearTimer, timerRef, autoAdvanceDelayMs]);

    useEffect(function() {
        if (avatarControls.animationColumns.length > 0) {
            startTimer();
        }
        return clearTimer;
    }, [avatarControls, clearTimer, startTimer]);

    return {
        animationStyle,
        animationApi,
        selectedTeam,
        selectedIndex,
        selectedColumn,
        clearTimer,
        timerRef,
        startTimerRef,
        coreAvatarControls,
        avatarControls,
        avatarRefFn,
    };
}