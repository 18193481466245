import React from "react";
import { Styled_Growing_fast } from "./styles";

interface Props {
  growing_fast_01_img: string;
  growing_fast_02_img: string;
  growing_fast_03_img: string;
  growing_fast_04_img: string;
}

export const Growing_fast: React.FC<Props> = ({
  growing_fast_01_img,
  growing_fast_02_img,
  growing_fast_03_img,
  growing_fast_04_img,
}) => {
  return (
    <Styled_Growing_fast>
      <div className="title">We Are Growing Fast</div>

      <div className="number_box_wrap">
        <div className="number_box_wrap_sub">
          <img className="number_box01_img first" src={growing_fast_01_img} alt="growing_fast_01_img"></img>
          <img className="number_box01_img" src={growing_fast_02_img} alt="growing_fast_02_img"></img>
        </div>

        <div className="number_box_wrap_sub">
          <img className="number_box02_img first" src={growing_fast_03_img} alt="growing_fast_03_img"></img>
          <img className="number_box02_img" src={growing_fast_04_img} alt="growing_fast_04_img"></img>
        </div>

      </div>
    </Styled_Growing_fast>
  );
};
