import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {
  contact_page_assets: assets,
} = config;


interface Props {
  onClick: () => void,
}

const Component: React.FC<Props> = observer((props) => {

  return (
    <>
      <Styled_body_wrap>
        <button className="card_unit" onClick={props.onClick}>
          <div className="card_unit_01">
            <img className="inner_box_01_asset" src={assets.contact_page_card_unit_01} alt={`contact_page_card_unit_01`}>
            </img>
          </div>
          <div className="card_unit_text">
          Member Support
          </div>
        </button>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
