import styled from "styled-components";

interface Props {}
export const StyledUpdateBtn = styled("button")<Props>`
  height: 44px;
  margin: 0 auto;
  margin-top: 66px;
  padding: 10px 24px 10px;
  border-radius: 43.5px;
  box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
  background-color: #10af42;

  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  display: block;
  cursor: pointer;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 234px;
    height: 40px;
    margin-top: 43px;
    padding: 8px 22px 8px 24px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
    background-color: #10af42;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 234px;
    height: 40px;
    margin-top: 43px;
    padding: 8px 22px 8px 24px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
    background-color: #10af42;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
  }

`;