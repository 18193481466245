import styled from "styled-components";

interface StyledDividerProps {
    isJP: boolean;
}
export const StyledDivider = styled("div")<StyledDividerProps>`
    height: inherit;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;

    .divider-line-1 {
        display: none;
    }

    .divider-text {
        display: inline-block;
        height: 40px;
        margin: 0;
        font-family: 'Avenir Next';
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.86;
        letter-spacing: normal;
        color: #898989;
    }

    .divider-line-2 {
        display: inline-block;
        width: ${(props) => props.isJP ? '400px' : "461px"};
        height: 1px;
        margin: 20px 0 19px 22px;
        background-color: #f3f4f4;
        line-height: 2.86;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        margin-top: 15px;

        .divider-line-1 {
            display: block;
            width: 92px;
            height: 1px;
            margin: 20px auto;
            background-color: #f3f4f4;
            line-height: 2.86;
        }

        .divider-text {
            width: max-content;
            height: 30px;
            margin: 0 14px;
            font-size: 12px;
            font-weight: 500;
            line-height: 3.33;
            color: #898989;
        }

        .divider-line-2 {
            width: 92px;
            height: 1px;
            margin: 20px auto;
        }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        margin-top: 15px;

        .divider-line-1 {
            display: block;
            width: 92px;
            height: 1px;
            margin: 20px auto;
            background-color: #f3f4f4;
            line-height: 2.86;
        }

        .divider-text {
            width: max-content;
            height: 30px;
            margin: 0 14px;
            font-size: 12px;
            font-weight: 500;
            line-height: 3.33;
            color: #898989;
        }

        .divider-line-2 {
            width: 92px;
            height: 1px;
            margin: 20px auto;
        }
    }
    
    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        margin-top: 60px;
        margin-left: 12px;

        .divider-line-1 {
            display: block;
            width: ${(props) => props.isJP ? '180px' : "221px"};
            height: 1px;
            margin: 20px 0 19px 22px;
            background-color: #f3f4f4;
            line-height: 2.86;
        }

        .divider-text {
            margin-left: ${(props) => props.isJP ? '15px' : "25px"};
        }

        .divider-line-2 {
            width: ${(props) => props.isJP ? '170px' : "221px"};
            height: 1px;
        }
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1279px) {
        margin-top: 60px;
        margin-left: 12px;

        .divider-line-1 {
            display: block;
            width: ${(props) => props.isJP ? '180px' : "221px"};
            height: 1px;
            margin: 20px 0 19px 22px;
            background-color: #f3f4f4;
            line-height: 2.86;
        }

        .divider-text {
            margin-left: ${(props) => props.isJP ? '15px' : "25px"};
        }

        .divider-line-2 {
            width: ${(props) => props.isJP ? '170px' : "221px"};
            height: 1px;
        }
    }
    
    @media only screen and (min-width : 1280px) and (max-width : 1439px) {
        .divider-line-2 {
            width: ${(props) => props.isJP ? '400px' : "440px"};
            height: 2px;
        }
    }

    /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .divider-line-2 {
        width: ${(props) => props.isJP ? '400px' : "440px"};
        height: 2px;
    }
  }
`;