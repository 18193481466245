import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap, Styled_Card } from "./styles";

const {
  press_page_assets: assets,
} = config;

const card_data = [
  {
    logo_01_src: assets.press_page_top_highlight_card_01_logo_01,
    logo_02_src: assets.press_page_top_highlight_card_01_logo_02,
    descriptionEle: `“Best Ground Travel Rewards Startup - Miles”`,
    className: `press_page_highlight_card_01`,
  },
];
interface CardProps {
  logo_01_src: string;
  logo_02_src: string;
  descriptionEle: string | JSX.Element;
  className?: string;
}

const CardUnit: React.FC<CardProps> = observer(({
  logo_01_src,
  logo_02_src,
  descriptionEle,
  className,
}) => {
  return (
    <Styled_Card className={`carousel_main_wrap ${className}`}>
      <div className={`highlight_card_leftBox`}>
        <img
          src={logo_01_src}
          className={`logo_01`}
          alt={`logo_01`}
        />
      </div>
      <div className="highlight_card_rightBox">
        <div className="highlight_card_top_title">Featured on</div>
        <div className="highlight_card_divider_line"></div>
        <img src={logo_02_src} className={`logo_02`} alt={`logo_02`} />
        <div className="highlight_card_testimonial">{descriptionEle}</div>
      </div>
    </Styled_Card>
  );
});

const Component: React.FC<{}> = observer(() => {
  return (
    <Styled_body_wrap className="">
      <CardUnit
        logo_01_src={card_data[0].logo_01_src}
        logo_02_src={card_data[0].logo_02_src}
        descriptionEle={card_data[0].descriptionEle}
        className={card_data[0].className}
      />
    </Styled_body_wrap>
  );
});

export default Component;
