import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { store } from "../../Store";
import config from "../../utils/config";
import { StyledEmailPreferenceMainBody } from "./StyledEmailPreferenceMainBody";
import { StyledConfirmedTitle } from "./ConfirmedStyles/StyledTitle";
import { StyledConfirmedSubTitle } from "./ConfirmedStyles/StyledSubTitle";
import { StyledImg } from "./ConfirmedStyles/StyledImg";
import { StyledConfirmedContentText } from "./ConfirmedStyles/StyledConfirmedContentText";
import { StyledUpdateReSubscribeBtn } from "./ConfirmedStyles/StyledUpdateReSubscribeBtn";
import { StyledConfirmedNoteText } from "./ConfirmedStyles/StyledConfirmedNoteText";
import { StyledSadFaceImg } from "./ConfirmedStyles/StyledSadFaceImg";
import i18next from 'i18next';

let firstRender = true;

const {
  mi_sad_face,
  mi_email_preference_thankyou_img,
} = config;

const EmailPreferenceThankyou: React.FC<{}> = observer(() => {
  let history = useHistory();

  if (firstRender) {
    store.fetchEmailUnsubscribeInfo();

    if (store.confirmed_param === "true") {
      store.update_confirmEmailPreferenceUnsubscribeStatus("ok");
    }
  }

  useEffect(() => {
    firstRender = false;
  }, []);

  const handleReSubscribe = useCallback(
    () => {
      let paramUrlPath = `?email=${encodeURIComponent(store.email_param)}&token=${encodeURIComponent(store.token_param)}`;
      store.handleEmailPreferenceReSubscribeAllEmails().then(() => {
        history.push(`/preferences/email/thankyou${paramUrlPath}&type=subscribe_all_emails`);
      });
  }, []);

  const { t } = useTranslation();

  const MainBodyEle = () => {
    if (store.type_param === "resubscribe" || store.type_param === "subscribe_all_emails") {
      return (
        <StyledEmailPreferenceMainBody type="confirm">
          <StyledConfirmedTitle>{t('text_Thank_you')}</StyledConfirmedTitle>
          {
            i18next.language === "english" ? (
              <StyledConfirmedSubTitle>
                {
                  store.type_param === "resubscribe"
                    ? `${t('email_unsubscribe_thx_box_resubscribe_subTitle_1')}${store.email_unsubscribe_info.title}${t('email_unsubscribe_thx_box_resubscribe_subTitle_2')}`
                    : t('email_unsubscribe_thx_box_resubscribeAll_subTitle')
                }
              </StyledConfirmedSubTitle>
            ) : (
              <StyledConfirmedSubTitle>
                {
                  store.type_param === "resubscribe"
                    ? `${store.email_unsubscribe_info.title}${t('email_unsubscribe_thx_box_resubscribe_subTitle_1')}${t('email_unsubscribe_thx_box_resubscribe_subTitle_2')}`
                    : t('email_unsubscribe_thx_box_resubscribeAll_subTitle')
                }
              </StyledConfirmedSubTitle>
            )
          }
          <StyledImg src={mi_email_preference_thankyou_img} />
          <StyledConfirmedContentText>{t('email_unsubscribe_thx_box_cheer_text')}</StyledConfirmedContentText>
        </StyledEmailPreferenceMainBody>
      )
    } else if (store.type_param === "update_preference_by_group") {
      return (
        <StyledEmailPreferenceMainBody type="confirm">
          <StyledConfirmedTitle>{t('text_Thank_you')}</StyledConfirmedTitle>
          <StyledImg src={mi_email_preference_thankyou_img} />
          <StyledConfirmedSubTitle>{t('email_unsubscribe_thx_box_update_preference_by_group_subTitle')}</StyledConfirmedSubTitle>
          <StyledConfirmedNoteText><b>{t('text_note')}</b>{t('email_unsubscribe_thx_box_note_description')}</StyledConfirmedNoteText>
        </StyledEmailPreferenceMainBody>
      )
    } else if (store.type_param === "update_preference_unsubscribe_all_emails") {
      return (
        <StyledEmailPreferenceMainBody type="confirm">
          <StyledConfirmedTitle>{t('email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_title')}</StyledConfirmedTitle>
          <StyledSadFaceImg src={mi_sad_face} />
          <StyledConfirmedSubTitle type={store.type_param}>{t('email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_subTitle')}</StyledConfirmedSubTitle>
          <StyledUpdateReSubscribeBtn onClick={handleReSubscribe}>{t('text_reSubscribe')}</StyledUpdateReSubscribeBtn>
          <StyledConfirmedNoteText><b>{t('text_note')}</b>{t('email_unsubscribe_thx_box_note_description')}</StyledConfirmedNoteText>
        </StyledEmailPreferenceMainBody>
      )
    }
  }

  return (
    <React.Fragment>
      {store.email_unsubscribe_info.id ? MainBodyEle() : null}
    </React.Fragment>
  );
});

export default EmailPreferenceThankyou;
