import styled from "styled-components";

interface Props {}
export const StyledUnsubscribeCategoryList = styled("div")<Props>`
    width: fit-content;
    margin: 15px auto 46px 139px;

    .mi-unsubscribe-category0list-item-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px auto;

        p {
            height: 30px;
            margin: 0 0 0 14px;
            font-family: Noto Sans JP;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: 0.3px;
            color: #2d2f3b;
        }
    }
    // TODO: uncomment and fix when we get the design for this size.
    // /* Extra Small Devices, Phones */ 
    // @media only screen and (min-width : 320px) and (max-width : 768px) {
    //     margin: 17px auto 20px 0;

    //     .mi-unsubscribe-category0list-item-wrap {
    //         margin: 5px auto;
    
    //         p {
    //             margin: 0 0 0 10px;
    //             font-size: 14px;
    //             line-height: 2.14;
    //         }
    //     }
    // }

    // /* ----------- Smartphones (portrait and landscape) ----------- */
    // @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    //     margin: 17px auto 20px 0;

    //     .mi-unsubscribe-category0list-item-wrap {
    //         margin: 5px auto;
    
    //         p {
    //             margin: 0 0 0 10px;
    //             font-size: 14px;
    //             line-height: 2.14;
    //         }
    //     }
    // }

    /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    margin: 0 auto 45px auto;
    p {
        height: 30px;
        margin: 0 0 0 10px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: 0.23px;
        color: #2d2f3b;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    margin: 15px auto 45px auto;
    p {
        height: 30px;
        margin: 0 0 0 10px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: 0.23px;
        color: #2d2f3b;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

`;