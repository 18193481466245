import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0 auto;
  padding: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .screen_larger_than_1150 {
    display: block;
  }

  .screen_between_768_and_1150 {
    display: none;
  }

  .screen_between_360_x_768 {
    display: none;
  }

  .inline_block {
    display: inline-block;
  }

  .display_block {
    display: block;
  }

  .miles_ui_top_hero_01 {
    margin: 0;
    width: 100vw;
    height: 930px;
    background: #f8fffb;

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }
    
  }

  .section_left_wrap {
    width: 879px;
    height: 930px;
    margin: 68px 50px 0 0;
    display: inline-block;
    position: absolute;
  }

  .miles_ui_top_content_wrap {
    margin: 0;
    margin-left: 930px;
    margin-top: 405px;
    position: absolute;

    .miles_ui_top_content_02 {
      width: max-content;
      height: auto;
      margin: 0 0 30px;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: normal;
      text-align: left;
      color: #2d2f3b;
    }

    .miles_ui_top_content_03 {
      width: 380px;
      height: 90px;
      flex-grow: 0;
      font-family: Gilroy-SemiBold;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.7px;
      text-align: left;
      color: #5f5f69;
      margin-top: 30px;
    }

    button {
      width: 319px;
      height: 64px;
      padding: 0;
      border-radius: 32px;
      background-color: #10af42;
      cursor: pointer;
      border: none;
      outline: none;
      font-family: Gilroy-SemiBold;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-top: 34px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: none;
    }
    
    .screen_between_360_x_768 {
      display: block;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .miles_ui_top_hero_01 {
      height: 667px;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .section_left_wrap {
      width: 318px;
      height: 304px;
      left: calc(50vw - 159px);
      top: 281px;
    }

    .miles_ui_top_content_wrap {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .miles_ui_top_content_02 {
        width: max-content;
        height: auto;
        margin: 30px auto 6px auto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .miles_ui_top_content_03 {
        width: 277px;
        height: auto;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.31px;
        text-align: center;
        color: #5f5f69;
        margin-top: 0;
      }

      button {
        width: 159px;
        height: 40px;
        margin: 24px auto 60px auto;
        padding: 0;
        border-radius: 25px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;

        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.5px;
        text-align: center;
        color: #ffffff;
      }

    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: none;
    }
    
    .screen_between_360_x_768 {
      display: block;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .miles_ui_top_hero_01 {
      height: 667px;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .section_left_wrap {
      width: 318px;
      height: 304px;
      left: calc(50vw - 159px);
      top: 281px;
    }

    .miles_ui_top_content_wrap {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .miles_ui_top_content_02 {
        width: max-content;
        height: auto;
        margin: 30px auto 6px auto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .miles_ui_top_content_03 {
        width: 277px;
        height: auto;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.31px;
        text-align: center;
        color: #5f5f69;
        margin-top: 0;
      }

      button {
        width: 159px;
        height: 40px;
        margin: 24px auto 66px auto;
        padding: 0;
        border-radius: 25px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;

        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.5px;
        text-align: center;
        color: #ffffff;
      }

    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .screen_larger_than_1150 {
      display: none;
    }
  
    .screen_between_768_and_1150 {
      display: block;
    }
    
    .screen_between_360_x_768 {
      display: none;
    }

    .miles_ui_btn_partner_with_us {
      z-index: 1;
    }

    .miles_ui_top_hero_01 {
      height: 950px;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .section_left_wrap {
      width: 628px;
      height: 560px;
      left: calc(50vw - 314px);
      top: 300px;
    }

    .miles_ui_top_content_wrap {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .miles_ui_top_content_02 {
        width: max-content;
        height: auto;
        margin: 32px 29px 15px 28px;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .miles_ui_top_content_03 {
        width: 380px;
        height: auto;
        font-family: Gilroy-SemiBold;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.7px;
        text-align: left;
        color: #5f5f69;
        margin-top: 0;
      }

  
      button {
        width: 217px;
        height: 52px;
        margin: 19px auto 0 auto;
        padding: 0;
        border-radius: 25px;
        box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;
        font-family: Gilroy-SemiBold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.7px;
        text-align: center;
        color: #ffffff;
      }

    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .miles_ui_top_hero_01 {
      height: 950px;
      
      .main_container {
        width: 100vw;
      }
      
    }

    .section_left_wrap {
      width: 739px;
      height: 950px;
      margin: 0 50px 0 0;
    }
  
    .miles_ui_top_content_wrap {
      margin: 0;
      margin-left: 790px;
      margin-top: 290px;
      position: absolute;

      .miles_ui_top_content_02 {
        width: 341px;
        height: auto;
        margin: 0 39px 15px 0;
        font-family: Gilroy-Bold;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: left;
        color: #2d2f3b;
      }

      .miles_ui_top_content_03 {
        width: 380px;
        height: auto;
        font-family: Gilroy-SemiBold;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.7px;
        text-align: left;
        color: #5f5f69;
        margin-top: 0;
      }
    }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .section_left_wrap {
      margin: 0 50px 0 0;
    }
    .miles_ui_top_hero_01 {
      width: 100vw;
      height: 930px;
  
      .main_container {
        margin: 0 auto;
        width: 1440px;
      }
      
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .section_left_wrap {
      margin: 0 50px 0 0;
    }
    .miles_ui_top_hero_01 {
      width: 100vw;
      height: 930px;
  
      .main_container {
        margin: 0 auto;
        width: 1440px;
      }
      
    }
  
  }
`;