import React from "react";
import { StyledNav } from "./styles";

interface Props {
  src: string;
  alt: string;
}

export const MilesWebHeader_option_02: React.FC<Props> = ({
  src,
  alt
}) => {
  return (
    <StyledNav>
      <a href="/">
        <img src={src} alt={alt}></img>
      </a>
    </StyledNav>
  );
};
