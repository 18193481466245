import styled from "styled-components";

interface Props {}
export const StyledUpdateBtnWrap = styled("div")<Props>`
  display: flex;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  }
`;