import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  .subtitle_text{
    width: 491px;
    height: 47px;
    font-family: Gilroy-Medium;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0px;
    color: #9b9ba5;
  }


  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .subtitle_text{
      width: 340px;
      height: 47px;
      font-family: Gilroy-Medium;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: normal;
      color: #9b9ba5;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;