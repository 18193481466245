import styled from "styled-components";

export const Styled_carousel = styled("div")`
    margin: 0 auto;
    padding: 0;  
    position: relative;
    max-width: 1440px;

    .miles_ui_about_carousel_03_wrap {
        height: 585px;
        height: auto;
    }

    .react-multi-carousel-list.miles_ui_about_carousel_03_wrap {
        position: static;
    }

    .image-item {
        padding: 0 8px 0 0;
    }

    li.react-multi-carousel-item.react-multi-carousel-item--active.image-item {
        width: max-content !important;
        background: white;
    }

    .react-multi-carousel-dot button {
        background: #e6e6e8;
        box-shadow: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        transition: opacity .25s ease-in;
        border: none;
      }
    
      .react-multi-carousel-dot--active.react-multi-carousel-dot button {
         background: #10af42;
      }
    
      .custom-dot-list-style {
        bottom: -70px;
      }
    
      .miles_carousel_wrap_arrow_left {
        left: 51px;
        position: absolute;
        outline: 0;
        border-radius: 35px;
        z-index: 900;
        border: 0;
        background: transparent;
        width: 97px;
        height: 97px;
        opacity: 1;
        cursor: pointer;
        display: block;
      }
    
      .miles_carousel_wrap_arrow_right {
        right: 51px;
        position: absolute;
        outline: 0;
        z-index: 900;
        border: 0;
        background: transparent;
        width: 97px;
        height: 97px;
        opacity: 1;
        cursor: pointer;
        display: block;
      }
    
      .miles_ui_large_than_768 {
        display: block;
      }
    
      .miles_ui_less_than_768 {
        display: none;
      }

      .miles_ui_screen_larger_than_1440 {
        display: block;
      }

      .miles_ui_screen_1150_x_1440 {
          display: none;
      }

      .miles_ui_screen_768_x_1150 {
          display: none;
      }

      .miles_ui_screen_360_x_768 {
          display: none;
      }

      .miles_ui_screen_less_than_360 {
        display: none;
      }

    
    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
      .react-multi-carousel-dot button {
        width: 12px;
        height: 12px;
      }
  
      .react-multi-carousel-dot--active.react-multi-carousel-dot button {
        width: 12px;
        height: 12px;
      }
  
      .custom-dot-list-style {
        bottom: -54px;
      }
  
      .miles_carousel_wrap_arrow_left {
        left: 27px;
        width: 50px;
        height: 50px;
      }
  
      .miles_carousel_wrap_arrow_right {
        right: 27px;
        width: 50px;
        height: 50px;
      }

      .miles_ui_screen_larger_than_1440 {
        display: none;
      }

      .miles_ui_screen_1150_x_1440 {
          display: none;
      }

      .miles_ui_screen_768_x_1150 {
          display: none;
      }

      .miles_ui_screen_360_x_768 {
          display: none;
      }

      .miles_ui_screen_less_than_360 {
        display: block;
      }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        .react-multi-carousel-dot button {
            width: 12px;
            height: 12px;
          }
      
          .react-multi-carousel-dot--active.react-multi-carousel-dot button {
            width: 12px;
            height: 12px;
          }
      
          .custom-dot-list-style {
            bottom: -54px;
          }
      
          .miles_carousel_wrap_arrow_left {
            left: 120px;
            width: 50px;
            height: 50px;
          }
      
          .miles_carousel_wrap_arrow_right {
            right: 120px;
            width: 50px;
            height: 50px;
          }

          .miles_ui_screen_larger_than_1440 {
            display: none;
          }
    
          .miles_ui_screen_1150_x_1440 {
              display: none;
          }
    
          .miles_ui_screen_768_x_1150 {
              display: none;
          }
    
          .miles_ui_screen_360_x_768 {
              display: block;
          }
    
          .miles_ui_screen_less_than_360 {
            display: none;
          }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        .react-multi-carousel-dot button {}

        .react-multi-carousel-dot--active.react-multi-carousel-dot button {}

        .custom-dot-list-style {
            bottom: -62px;
        }

        .miles_carousel_wrap_arrow_left {
            left: calc(50% - 342px);
            left: 152px;
            width: 50px;
            height: 50px;
        }

        .miles_carousel_wrap_arrow_right {
            right: calc(50% - 342px);
            right: 152px;
            width: 50px;
            height: 50px;
        }

        .miles_ui_screen_larger_than_1440 {
          display: none;
        }
  
        .miles_ui_screen_1150_x_1440 {
            display: none;
        }
  
        .miles_ui_screen_768_x_1150 {
            display: block;
        }
  
        .miles_ui_screen_360_x_768 {
            display: none;
        }
  
        .miles_ui_screen_less_than_360 {
          display: none;
        }
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
        .react-multi-carousel-dot button {
          width: 14px;
          height: 14px;
        }
        
        .custom-dot-list-style {
          bottom: -70px;
        }

        .miles_carousel_wrap_arrow_left {
            left: 98px;
            width: 97px;
            height: 97px;
        }

        .miles_carousel_wrap_arrow_right {
            right: 98px;
            width: 97px;
            height: 97px;
        }

    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {

    }
    
`;