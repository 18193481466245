import styled from "styled-components";

interface Props {}
export const StyledTitle = styled("p")<Props>`
  height: 32px;
  font-family: 'Avenir Next';
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.42px;
  text-align: center;
  color: #343434;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    color: ${() => process.env.NODE_ENV === "development" ? "red" : "#343434"};
    margin: 0;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.3px;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    color: ${() => process.env.NODE_ENV === "development" ? "red" : "#343434"};
    margin: 0;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.3px;
  }

  /* ----------- mobile small Tablets ----------- */
  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 768px) and (max-width : 1280px) {
    color: ${() => process.env.NODE_ENV === "development" ? "yellow" : "#343434"};
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1280px) and (max-width : 1440px) {
    color: ${() => process.env.NODE_ENV === "development" ? "green" : "#343434"};
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    color: ${() => process.env.NODE_ENV === "development" ? "red" : "#343434"};
  }
`;