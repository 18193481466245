import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { Styled_body_wrap } from "./styles";
import ContactFormTitle from "../contact_form_title/index";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import milesUtils from "../../../utils/milesUtils";
import {
  EMAIL_DOMAINS_CLEANED,
  EXTRA_EMAIL_DOMAINS_CLEANED,
} from "../../../utils/milesConstant";
import {
  checkValidPhoneNumber
} from '../../../utils/phoneNumValidator';
import ContactQuerySuccessImg from "../contact_query_success_img/index";
import ContactQuerySuccessText from "../contact_query_success_text/index";

const {
  contact_page_assets: assets,
} = config;

type UserType = 'user' | 'partner';


const SubjectValuesByUserType = {
  'user': [
    'Account Settings & Preferences',
    'App Installation & Setup',
    'Data Privacy',
    'General',
    'Rewards',
    'Trips & Miles',
    'Other'

  ],
  'partner': [
    'Interested in being a reward partner',
    'We are a City/public agency',
    'We are a mobility service provider',
    'Inquiring about PR opportunity',
    'Other'
  ],
}



export interface Props {
  user_type: UserType
}

const Component: React.FC<Props> = observer((props) => {
  const subjectValues = SubjectValuesByUserType[props.user_type];
  const inputEl = useRef<HTMLInputElement>(null);
  const [position, setPosition] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactQueryFlag, setcontactQueryFlag] = useState(false);
  const [errs, setErrs] = useState({
    subject: false,
    name: false,
    email: false,
    phone_number: false,
    message: false,
    submissionErr: false
  });
  let posit = 0;

  const [{formValues, onChangeName, onChangeEmailAddress, onChangePhoneNumber, onChangeSubject, onChangeMessage}] = useState(() => {
    const formValues = {
      name: '',
      email: '',
      phone_number: '',
      user_type: props.user_type as string,
      subject: '',
      message: '',
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    function makeCallback(attribute_name: keyof (typeof formValues)) {
      return (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        if(attribute_name === 'phone_number'){
          let shouldUseCurrentValue = (event.target.value.length <= 3);
          let currentPhoneNum = event.target.value.replace(/\D/g, "")
          let formattedPhoneNum;
          
          if (currentPhoneNum.length > 10) {
            currentPhoneNum = currentPhoneNum.slice(0, 10); 
          }
          formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);

          const selectionStart = inputEl!.current!.selectionStart as number;

          let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)

          setPosition(currentPosition as number)
          milesUtils.debug_log("selectionStart >>> ", selectionStart);

          setPhoneNumber(formattedPhoneNum);
          
        }
        formValues[attribute_name] = event.target.value
      }
    }

    function makeSubjectCallback(attribute_name: keyof (typeof formValues)) {
      return ((event, child: React.ReactNode) => {
        event.preventDefault();
        formValues[attribute_name] = event.target.value as string;
      }) as SelectInputProps["onChange"];
    }

    return {
      formValues,
      onChangeName: makeCallback('name'),
      onChangeEmailAddress: makeCallback('email'),
      onChangePhoneNumber: makeCallback('phone_number'),
      onChangeSubject: makeSubjectCallback('subject'),
      onChangeMessage: makeCallback('message'),
    };
  });

  const checkEmailValue = (email: string) => {
    let new_array = [
      ...EMAIL_DOMAINS_CLEANED,
      ...EXTRA_EMAIL_DOMAINS_CLEANED,
    ]
    return new_array.indexOf(email.trim().split("@")[1]) > -1
  }

  const sendContactQuery = useCallback(() => {
    const newErrs = {
      ...errs,
      subject: !formValues.subject.trim(),
      name: !formValues.name.trim(),
      email: checkEmailValue(formValues.email),
      phone_number: formValues.phone_number.trim() === '' ? false : !checkValidPhoneNumber(formValues.phone_number.replace( /[^0-9]/g, '' )),
      message: !formValues.message.trim(),
    }

    setErrs(newErrs);
    
    if (!newErrs.subject && !newErrs.name && !newErrs.email && !newErrs.message) {
      const finalFormValues = {...formValues, enquiry_type: null};
      
      fetch('/_api/submit_contact_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalFormValues),
      })
      .then(response => {
        if (response.ok) {
          setcontactQueryFlag(true)
          return 
        }

        if (response.status != 200) 
        {
          setErrs({
            ...errs,
            submissionErr: true
          })
        }
        return
      })
      .catch((error) => {
        {
          setErrs({
          ...errs,
            submissionErr: true
          })
        }
        return
      });
    }
  },
  [errs]);

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  return (
    <>
      <Styled_body_wrap>
        <div className="ciq_root">
          <div className="ciq_container">
            <ContactFormTitle />
            {/* Contact Query Form Container */}
            <div className={`form_container ${contactQueryFlag ? "hide_contact_form" : "show_contact_form"}`}>
              <label className="input_inner_section input_box_01">
                  <div className="label_text">Subject</div>
                  <FormControl variant="outlined" className="combo_outline">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      MenuProps={{
                          PaperProps: {
                          style: {
                            marginTop: '50px',
                          },
                        },
                        MenuListProps: {
                          classes: {
                            root: 'contact_form_subject_root',
                          },
                        },
                        
                      }}
                      placeholder="Select One"
                      onChange={onChangeSubject}
                    >
                        <MenuItem classes={{root: "select_subject"}} disabled value="Select One">Select One</MenuItem>
                      {
                        subjectValues.map((subjectValue, index) => (
                          <MenuItem classes={{root: "select_subject"}} value={subjectValue} key={index}>{subjectValue}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  <span className={errs.subject ? "show_err_info" : "hide_err_info"}>Please select a category</span>
               </label>
               <label className="input_inner_section input_box_01">
                  <span className="label_text">Name</span>
                  <input
                    type="text"
                    name="name"
                    className={`contact_form_inputs ${errs.name ? "contact_form_inputs_err_state" : "contact_form_inputs_no_error"}`}
                    required
                    placeholder="Name"
                    onChange={onChangeName}
                  />
                  <span className={errs.name ? "show_err_info" : "hide_err_info"}>Please enter a valid name</span>
                </label>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">Email Address</span>
                  <input
                    type="text"
                    name="emailAddress"
                    className={`contact_form_inputs ${errs.email ? "contact_form_inputs_err_state" : "contact_form_inputs_no_error"}`}
                    required
                    onChange={onChangeEmailAddress}
                    placeholder="Email Address"
                  />
                  <span className={errs.email ? "show_err_info" : "hide_err_info"}>Please enter a valid email address</span>
                </label>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">Phone Number (Optional)</span>
                  <input
                    ref={inputEl}
                    type="text"
                    name="phoneNumber"
                    className={`contact_form_inputs ${errs.phone_number ? "contact_form_inputs_err_state" : "contact_form_inputs_no_error"}`}
                    required
                    placeholder="(123) - 456 - 7890"
                    value={phoneNumber}
                    onChange={onChangePhoneNumber}
                  />
                  <span className={errs.phone_number ? "show_err_info" : "hide_err_info"}>Please enter a valid US phone number</span>
                </label>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">How can we help?</span>
                  <textarea
                    className={`contact_form_textarea ${errs.message ? "contact_form_textarea_err_state" : "contact_form_textarea_no_error"}`}
                    required
                    placeholder="How can we help?"
                    onChange={onChangeMessage}
                  ></textarea>
                  <span  className={errs.message ? "show_err_info" : "hide_err_info"}>Please enter how can we help with</span>
                </label>

                <div className="tip_text">Please enter the details of your request. A member of our support staff will respond as soon as possible.</div>

                <button className="btn_submit miles_ui_btn_primary" onClick={sendContactQuery}>Submit</button>
                <div className={errs.submissionErr ? "show_err_info" : "hide_err_info"}>
                  Something went wrong. Please try again.
                </div>
                {/* //  */}
            </div>
            
            {/* Success Response Container */}
            <div className={`contact_query_success ${contactQueryFlag ? "show_success_resp" : "hide_contact_form"}`}>
              <ContactQuerySuccessImg />
              <ContactQuerySuccessText />
            </div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
