import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0 auto;
  padding: 0; 

  .miles_ui_about_page_our_team_root {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(232, 247, 237, 0.17);
  }

  .miles_ui_about_page_our_team_container {
    width: 1440px;
    height: 519px;
    flex-direction: column;
    position: relative;
  }

  .miles_ui_about_page_our_team_title {
    font-family: Gilroy-Bold;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #2d2f3b;
    position: absolute;
    top: 164.8px;
    left: 178px;
  }

  .miles_ui_about_page_our_team_picture {
    position: absolute;
    top: 138px;
    left: 807px;
  }

  .miles_ui_about_page_our_team_picture_img {
    height: 244px;
  }

  .miles_ui_about_page_our_team_body {
    width: 492px;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2d2f3b;
    position: absolute;
    top: 247.3px;
    left: 178px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .miles_ui_about_page_our_team_container {
      height: 519px;
      width: 100vw;
    }

    .miles_ui_about_page_our_team_title {
      width: fit-content;
      font-size: 24px;
      letter-spacing: 0.75px;
      top: 89px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_our_team_picture {
      width: fit-content;
      top: 257px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_our_team_picture_img {
      height: 152px;
    }

    .miles_ui_about_page_our_team_body {
      font-size: 16px;
      letter-spacing: 0.7px;
      text-align: center;
      width: 295px;
      top: 127px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .miles_ui_about_page_our_team_container {
      height: 519px;
      width: 100vw;
    }

    .miles_ui_about_page_our_team_title {
      width: fit-content;
      font-size: 24px;
      letter-spacing: 0.75px;
      top: 89px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_our_team_picture {
      width: fit-content;
      top: 257px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .miles_ui_about_page_our_team_picture_img {
      height: 152px;
    }

    .miles_ui_about_page_our_team_body {
      font-size: 16px;
      letter-spacing: 0.7px;
      text-align: center;
      width: 295px;
      top: 127px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .miles_ui_about_page_our_team_title {
      font-size: 24px;
      letter-spacing: 0.75px;
      top: 119px;
      left: 154px;
    }

    .miles_ui_about_page_our_team_picture {
      top: 97px;
      left: 550px;
    }

    .miles_ui_about_page_our_team_picture_img {
      height: 160px;
    }

    .miles_ui_about_page_our_team_body {
      font-size: 16px;
      letter-spacing: 0.7px;
      width: 333px;
      top: 163px;
      left: 154px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .miles_ui_about_page_our_team_title {
      top: 120px;
      left: 225px;
    }

    .miles_ui_about_page_our_team_picture {
      left: 710px;
    }

    .miles_ui_about_page_our_team_picture_img {
      height: 244px;
      width: 450px;
    }

    .miles_ui_about_page_our_team_body {
      left: 120px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {

  }
    
`;