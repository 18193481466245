import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const contact_page_assets = {
  partner_carousel_01_img_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01_img_01/placements%402x.png`,
  contact_page_card_unit_01: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_card_unit_01/group-12%402x.png`,
  contact_page_card_unit_02: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_card_unit_02/group-11%402x.png`,
  contact_page_image_background_box: `${MILES_WEB_S3_BASE_URL_V2}contact_page/image_background_box/loc-asset%402x.png`,
  contact_page_location_image_box: `${MILES_WEB_S3_BASE_URL_V2}contact_page/address_img/loc-small-asset%402x.png`,
  contact_page_header_asset_1: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_header_asset/contact_page_header_asset_1%402x.png`,
  contact_page_header_asset_2: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_header_asset/contact_page_header_asset_2%402x.png`,
  contact_page_header_asset_3: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_header_asset/contact_page_header_asset_3%402x.png`,
  contact_page_header_asset_4: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_header_asset/contact_page_header_asset_4%402x.png`,
  send_contact_query_success_asset_1: `${MILES_WEB_S3_BASE_URL_V2}contact_page/send_contact_query_success/send_contact_query_success_1%402x.png`,
  send_contact_query_success_asset_2: `${MILES_WEB_S3_BASE_URL_V2}contact_page/send_contact_query_success/send_contact_query_success_2%402x.png`,
  contact_page_top_banner: `${MILES_WEB_S3_BASE_URL_V2}contact_page/contact_page_header_asset/contact_page_top_banner-3%402x.png`,
};
