import styled from "styled-components";

interface Cta_inner_wrapProps {
}

export const StyledCta_inner_wrap = styled("div")<Cta_inner_wrapProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: middle;

  .inline_block {
    display: inline-block;
  }

  a.inline_block {
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;

    img {
      width: 50px;
      height: 50px;
    }
  }

  a.apple {
    margin-left: 30px;
    margin-right: 10px;
  }

  @media only screen and (max-width : 360px) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: middle;

    .inline_block {
      display: inline-block;
      
    }

    a.apple {
      margin-left: 15px;
      margin-right: 0;
      padding-top: 5px;
    }

    a.google {
      padding-top: 5px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: middle;

    .inline_block {
      display: inline-block;
    }

    a.apple {
      margin-left: 15px;
      margin-right: 0;
      padding-top: 5px;
    }

    a.google {
      padding-top: 5px;
    }
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .inline_block.apple {
      display: none;
    }

    .inline_block.google {
      display: none;
    }
  }

`;

interface DownloadNumberInputProps {
  send_downloadLink_phoneErr: boolean;
}

export const StyledInput = styled("input")<DownloadNumberInputProps>`
  padding: 0;
  margin: 0;
  width: 580px;
  height: 52px;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  background-color: #ffffff;
  border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
  outline-style: none;
  box-sizing: border-box;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: 0.67px;
  color: #2d2f3b;
  padding-left: 24px;
  padding-right: 222px;

  :active {
    box-shadow: 0 2px 4px 0 #b1b1b180;
    border: solid 1px #10af42;
  }

  :focus {
    box-shadow: 0 2px 4px 0 #b1b1b180;
    border: solid 1px #10af42;
  }

  &::-webkit-input-placeholder {
    width: 191px;
    height: 27px
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.67px;
    color: #bfbfbf;
  }

  // --- firefox 19+ ---
  &::-moz-placeholder {
    width: 191px;
    height: 52px
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.67px;
    color: #bfbfbf;
}

  // --- ie ----
  &::-ms-input-placeholder {
    width: 191px;
    height: 52px
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.67px;
    color: #bfbfbf;
  }

  &::-moz-placeholder {
    width: 191px;
    height: 52px
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.67px;
    color: #bfbfbf;
  }

  @media only screen and (max-width : 360px) {
    padding: 0 90px 0 0;
    margin: 0;
    width: 231px;
    height: 40px;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: #ffffff;
    border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
    outline-style: none;
    box-sizing: border-box;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #2d2f3b;
    padding-left: 18px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
  }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    padding: 0 90px 0 0;
    margin: 0;
    width: 231px;
    height: 40px;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: #ffffff;
    border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
    outline-style: none;
    box-sizing: border-box;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #2d2f3b;
    padding-left: 18px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
  }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 132px;
      height: 27px
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 464px;
  }
`;

interface BtnProps {
  bgColor: string;
  get_miles_links_status: string;
}

export const StyledBtn = styled("button")<BtnProps>`
  &.screen_larger_than_768 {
    display: block;
  }
  &.screen_between_361_and_768 {
    display: none;
  }
  width: 217px;
  height: 52px;
  margin: 0;
  padding: 0;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  border-color: transparent;
  border: ${(props) => `solid 1px ${props.bgColor}`};
  font-family: Gilroy-SemiBold;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  position: absolute;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  margin-left: 363px;

  &:hover,
  &:active  {
    background-color: #178e3d;
    border: solid 1px #178e3d;
    box-shadow: 0 2px 4px 0 #b1b1b180;

    :disabled {
      background-color: ${(props) => props.get_miles_links_status === "sending" ? "#10af42" : "#9b9ba5"};
      border: ${(props) => props.get_miles_links_status === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
      box-shadow: ${(props) => props.get_miles_links_status === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
    }
  }

  background-color: ${(props) => props.bgColor};

  :hover {
    background-color: ${(props) => props.disabled ? "#9b9ba5" : "#178e3d"};
  }

  @media only screen and (max-width : 360px) {
    &.screen_between_361_and_768 {
      display: block;
    }

    &.screen_larger_than_768 {
      display: none;
    }
    width: 84px;
    height: 40px;
    margin: 0;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: ${(props) => props.bgColor};
    border-color: transparent;
    border: ${(props) => `solid 1px ${props.bgColor}`};
    font-family: Gilroy-SemiBold;

    vertical-align: middle;
    position: absolute;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    margin-left: 147px;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    &:hover,
    &:active  {
      background-color: #178e3d;
      border: solid 1px #178e3d;
      box-shadow: 0 2px 4px 0 #b1b1b180;

      :disabled {
        background-color: ${(props) => props.get_miles_links_status === "sending" ? "#10af42" : "#9b9ba5"};
        border: ${(props) => props.get_miles_links_status === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
        box-shadow: ${(props) => props.get_miles_links_status === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    &.screen_between_361_and_768 {
      display: block;
    }

    &.screen_larger_than_768 {
      display: none;
    }
    width: 84px;
    height: 40px;
    margin: 0;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: ${(props) => props.bgColor};
    border-color: transparent;
    border: ${(props) => `solid 1px ${props.bgColor}`};
    font-family: Gilroy-SemiBold;

    vertical-align: middle;
    position: absolute;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    margin-left: 147px;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    &:hover,
    &:active  {
      background-color: #178e3d;
      border: solid 1px #178e3d;
      box-shadow: 0 2px 4px 0 #b1b1b180;

      :disabled {
        background-color: ${(props) => props.get_miles_links_status === "sending" ? "#10af42" : "#9b9ba5"};
        border: ${(props) => props.get_miles_links_status === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
        box-shadow: ${(props) => props.get_miles_links_status === "sending" ? "solid 1px #10af42" : "solid 1px #9b9ba5"};
      }
    }
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    margin-left: 247px;

  }

`;

interface FcaRafSendDownloadErrTipEleProps {
  send_downloadLink_phoneErr: boolean;
}
export const StyledFcaRafSendDownloadErrTipEle = styled("span")<FcaRafSendDownloadErrTipEleProps>`
  display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
  width: max-content;
  height: 14px;
  margin-top: 15px;
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #ff4949;
  position: relative;

  @media only screen and (max-width : 360px) {
    display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
    width: max-content;
    height: 14px;
    margin-top: 15px;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
    color: #ff4949;
    position: absolute;
    font-size: 10px;
    margin-top: 10px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
    width: max-content;
    height: 14px;
    margin-top: 15px;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
    color: #ff4949;
    position: absolute;
    font-size: 10px;
    margin-top: 10px;
  }
`;

interface StyledMilesJpTopCtaTipProps {
  send_downloadLink_phoneErr: boolean;
}

export const StyledMilesJpTopCtaTip = styled("div")<StyledMilesJpTopCtaTipProps>`

  width: max-content;
  height: 40px;
  margin: ${(props) => props.send_downloadLink_phoneErr ? '32px auto 0 auto' : '10px auto 0 auto'};
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 0.33px;
  text-align: center;
  color: #82828c;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 220px;
    height: 30px;
    margin: ${(props) => props.send_downloadLink_phoneErr ? '33px auto 0 auto' : '15px auto 0 auto'};
    font-family: Noto Sans JP;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.19px;
    text-align: center;
    color: #82828c;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 220px;
    height: 30px;
    margin: ${(props) => props.send_downloadLink_phoneErr ? '33px auto 0 auto' : '15px auto 0 auto'};
    font-family: Noto Sans JP;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.19px;
    text-align: center;
    color: #82828c;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 16px;
    margin: ${(props) => props.send_downloadLink_phoneErr ? '48px auto 0 auto' : '14px auto 0 auto'};
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.25px;
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`

interface StyledSuccessInfoProps {}

export const StyledSuccessInfo = styled("div")<StyledSuccessInfoProps>`
  padding: 0;
  margin: 0;
  width: 580px;
  height: 52px;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  background-color: #10af42;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  outline-style: none;
  box-sizing: border-box;
  font-family: Gilroy-SemiBold;
  vertical-align: middle;

  @media only screen and (max-width : 360px) {
    padding: 0;
    margin: 0;
    width: 231px;
    height: 40px;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: #10af42;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    outline-style: none;
    box-sizing: border-box;
    font-family: Gilroy-SemiBold;
    vertical-align: middle;
    font-size: 10px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    padding: 0;
    margin: 0;
    width: 231px;
    height: 40px;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    background-color: #10af42;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    outline-style: none;
    box-sizing: border-box;
    font-family: Gilroy-SemiBold;
    vertical-align: middle;
    font-size: 10px;
  }
`;