import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
    margin: 0;
    width: 100vw;
    height: auto;
    background: #ffffff;

    .main_container {
      margin: 0 auto;
      width: 100%;
      max-width: 1440px;

      .miles_ui_cities_page_top_box {
        position: relative;
        width: 100%;
      }

      .miles_ui_cities_page_top_box_img{
        width: 100%;
        object-fit: cover;
        object-position: center center;
        position: relative;
        margin-top: 0;
      }

      .content_wrap {
        width: 100%;
        margin-top: 98px;
      }

      .top_box_text_01 {
        height: auto;
        font-family: Gilroy-Bold;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .top_box_text_02 {
        width: 599.7px;
        height: auto;
        font-family: Gilroy-Medium;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.31px;
        text-align: center;
        color: #5f5f69;
        margin: 0 auto;
        margin-top: 30px;
      }

      .get_started_btn {
        display: block;
        margin: 0 auto;
        width: 319px;
        height: 64px;
        padding: 0;
        border-radius: 32px;
        background-color: #10af42;
        cursor: pointer;
        border: none;
        outline: none;
        font-family: Gilroy-SemiBold;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin-top: 70px;
      }

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
      .main_container {
        width: 100%;
  
        .miles_ui_cities_page_top_box {
        }
  
        .content_wrap {
          margin-top: 30px;
        }
  
        .top_box_text_01 {
          height: 34px;
          font-size: 24px;
          line-height: 1.42;
          letter-spacing: 0.75px;
        }
  
        .top_box_text_02 {
          width: 320px;
          height: 48px;
          font-size: 14px;
          line-height: 1.71;
          letter-spacing: 0.6px;
          margin-top: 30px;
        }
  
        .get_started_btn {
          width: 160px;
          height: 40px;
          border-radius: 25px;
          font-size: 13px;
          line-height: 1.54;
          letter-spacing: 0.5px;
          margin-top: 50px;
        }
  
      }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      .main_container {
        width: 100%;
  
        .miles_ui_cities_page_top_box {}
  
        .content_wrap {
          margin-top: 30px;
        }
  
        .top_box_text_01 {
          height: 34px;
          font-size: 24px;
          line-height: 1.42;
          letter-spacing: 0.75px;
        }
  
        .top_box_text_02 {
          width: 320px;
          margin-top: 30px;
          height: 48px;
          font-size: 14px;
          line-height: 1.71;
          letter-spacing: 0.6px;
        }
  
        .get_started_btn {
          width: 160px;
          height: 40px;
          border-radius: 25px;
          font-size: 13px;
          line-height: 1.54;
          letter-spacing: 0.5px;
          margin-top: 50px;
        }
  
      }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
      
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
      .main_container {
        width: 100%;
  
        .miles_ui_cities_page_top_box {
        }
  
        .content_wrap {
          margin-top: 36px;
        }
  
        .top_box_text_01 {
          font-size: 32px;
          line-height: 1.31;
          height: 42px;
        }
  
        .top_box_text_02 {
          width: 488px;
          margin-top: 37px;
          height: 72px;
          font-size: 18px;
          line-height: 2;
          letter-spacing: 0.31px;
        }
  
        .get_started_btn {
          margin-top: 50px;
          width: 217px;
          height: 52px;
          border-radius: 25px;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 0.7px;
        }
  
      }
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {

      .main_container {
        width: 100%;
  
        .miles_ui_cities_page_top_box {}
  
        .content_wrap {
        }
  
        .top_box_text_01 {}
  
        .top_box_text_02 {}
  
        .get_started_btn {}
  
      }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
      margin: 0;
      width: 100vw;

      .main_container {
        margin: 0 auto;
        width: 1440px;
      }
    }
`;