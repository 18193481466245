import React from "react";
import { StyledCopyBox } from "./styles";

interface Props {
  id: string;
  ref: RefObject<HTMLIFrameElement>;
  content: string;
  shouldShowCopyBox: boolean;
  invite_code_tip: string;
}

export const FcaRafCopyBox: React.FC<Props> = ({
  id,
  ref,
  content,
  shouldShowCopyBox,
  invite_code_tip,
}) => {
  return (
    <StyledCopyBox
      shouldShowCopyBox={shouldShowCopyBox}
      id={id}
      ref={ref}
    >
      <div className="copy_box_wrap">{content}</div>
      <div className="copy_box_img_wrap">
        <img className={`raf_copy_box_img`} src="https://getmiles.s3.amazonaws.com/assets/web_v2/raf_page/raf_copy_box_mobile_img/Coupon_Scissors%402x.png" alt={`raf_copy_box_img`} />

        <div className="invite_code_wrap">
          <p className="invite_code">{content}</p>
          <p className="invite_code_tip">{invite_code_tip}</p>
        </div>
      </div>
    </StyledCopyBox>
  );
};
