import styled from "styled-components";

interface DownloadNumberInputProps {
  send_downloadLink_phoneErr: boolean;
}

export const StyledInput = styled("input")<DownloadNumberInputProps>`
  padding: 0;
  margin: 0;
  width: 760px;
  height: 87px;
  border-radius: 43.5px;
  box-shadow: 0 2px 4px 0 rgb(177 177 177 / 50%);
  background-color: #ffffff;
  border: ${(props) => props.send_downloadLink_phoneErr ? 'solid 1px #ff4949' : 'solid 1px rgba(30,186,96,0.25)'};
  outline-style: none;
  box-sizing: border-box;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 87px;
  letter-spacing: 0.93px;
  color: #2d2f3b;
  font-family: Gilroy-Medium;
  padding-left: 55px;
  padding-top: 8px;

  :active {
    box-shadow: 0 2px 4px 0 #b1b1b180;
    border: solid 1px #10af42;
  }

  :focus {
    box-shadow: 0 2px 4px 0 #b1b1b180;
    border: solid 1px #10af42;
  }

  &::-webkit-input-placeholder {
    width: 292px;
    height: 78px;
    font-family: Gilroy-Medium;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 78px;
    letter-spacing: 0.93px;
    color: #bfbfbf;
  }

  // --- firefox 19+ ---
  &::-moz-placeholder {
    width: 292px;
    height: 78px;
    font-family: Gilroy-Medium;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 78px;
    letter-spacing: 0.93px;
    color: #bfbfbf;
  }

  // --- ie ----
  &::-ms-input-placeholder {
    width: 292px;
    height: 78px;
    font-family: Gilroy-Medium;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 78px;
    letter-spacing: 0.93px;
    color: #bfbfbf;
  }

  &::-moz-placeholder {
    width: 292px;
    height: 78px;
    font-family: Gilroy-Medium;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 78px;
    letter-spacing: 0.93px;
    color: #bfbfbf;
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 464px;
    height: 52px;
    border-radius: 25px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    text-align: left;
    color: #2d2f3b;
    font-family: Gilroy-Medium;
    padding-left: 24px;
    padding-top: 4px;

    :active {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    :focus {
      box-shadow: 0 2px 4px 0 #b1b1b180;
      border: solid 1px #10af42;
    }

    &::-webkit-input-placeholder {
      width: 196px;
      height: 52px;
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: 0.67px;
      color: #bfbfbf;
    }

    // --- firefox 19+ ---
    &::-moz-placeholder {
      width: 196px;
      height: 52px;
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: 0.67px;
      color: #bfbfbf;
    }

    // --- ie ----
    &::-ms-input-placeholder {
      width: 196px;
      height: 52px;
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: 0.67px;
      color: #bfbfbf;
    }

    &::-moz-placeholder {
      width: 196px;
      height: 52px;
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: 0.67px;
      color: #bfbfbf;
    }
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }
`;

interface BtnProps {
  bgColor: string;
}

export const StyledBtn = styled("button")<BtnProps>`
  width: 384px;
  height: 87px;
  margin: 0;
  padding: 0;
  border-radius: 43.5px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  background-color: ${(props) => props.bgColor};
  border-color: transparent;
  border: ${(props) => `solid 1px ${props.bgColor}`};
  font-family: Gilroy-SemiBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.93px;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  position: absolute;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  margin-left: -384px;

  background-color: ${(props) => props.bgColor};

  :hover {
    background-color: ${(props) => props.disabled ? "#9b9ba5" : "#178e3d"};
  }

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 217px;
    height: 52px;
    padding: 0;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 #b1b1b180;
    font-family: Gilroy-SemiBold;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    margin-left: -217px;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }
`;

interface FcaRafSendDownloadErrTipEleProps {
  send_downloadLink_phoneErr: boolean;
}
export const StyledFcaRafSendDownloadErrTipEle = styled("span")<FcaRafSendDownloadErrTipEleProps>`
  display: ${(props) => props.send_downloadLink_phoneErr ? 'block' : 'none'};
  width: 322px;
  height: 16px;
  margin-top: 15px;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #ff4949;

  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: max-content;
    height: 14px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.47px;
    color: #ff4949;
  }
`;

interface StyledSuccessInfoProps {}

export const StyledSuccessInfo = styled("div")<StyledSuccessInfoProps>`
  padding: 30px 23px 29px 23px;
  margin: 0;
  width: 760px;
  height: 87px;
  border-radius: 43.5px;
  box-shadow: 0 2px 4px 0 #b1b1b180;
  background-color: #10af42;
  outline-style: none;
  box-sizing: border-box;
  font-family: Gilroy-SemiBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.93px;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;


  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    padding: 0;
    width: 464px;
    height: 52px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.7px;
    text-align: center;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }
`;