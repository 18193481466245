import styled from "styled-components";

interface Props {
  src: string;
}
export const StyledLogoImg = styled("img")<Props>`
  height: 50px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    height: 30px;
    object-fit: contain;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 30px;
    object-fit: contain;
  }
`;