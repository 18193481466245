import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;
  width: 100vw;
  height: 1062px;
  background: lightgray;

  .main_container {
    margin: 0 auto;
    width: 100vw;
  }

  .carousel-inner {
    width: inherit;
    height: 1062px;
  }

  .carousel-item.card_01 {
    background-color: #F8FFFB;
  }

  .carousel-item.card_02 {
    background-color: #F8FFFF;
    background-color: #fefaf3;
  }

  .carousel-item.card_03 {
    background-color: #F1F2FA;
  }

  img {
    display: inline-block;
  }

  .carousel_arrow_on_desktop {
    display: block;
  }

  .carousel_arrow_on_mobile {
    display: none;
  }

  .carousel_arrow {
    width: 97px;
    height: 97px;
    margin-top: 480px;

    &.arrow_left {
      left: calc(50vw - 680px);
      z-index: 2;
    }

    &.arrow_right {
      right: calc(50vw - 680px);
      z-index: 2;
    }

    & > div {
      width: 97px;
      height: 97px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: auto;

    .carousel-inner {
      height: auto;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }
  
    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 14px;
      margin-top: 525px;
  
      &.arrow_left {
        left: 145px;
        left: calc(50vw - 158px);
      }
  
      &.arrow_right {
        right: 145px;
        right: calc(50vw - 158px);;
      }
  
      & > div {
        width: 10px;
        height: 14px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: auto;

    .carousel-inner {
      height: auto;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }
  
    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 16px;
      margin-top: 532px;
  
      &.arrow_left {
        left: 145px;
        left: calc(50vw - 160px);
      }
  
      &.arrow_right {
        right: 145px;
        right: calc(50vw - 160px);;
      }
  
      & > div {
        width: 10px;
        height: 16px;
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 1050px;

    .carousel_arrow {
      width: 60px;
      height: 60px;
      margin-top: 520px;
  
      &.arrow_left {
        left: 160px;
      }
  
      &.arrow_right {
        right: 160px;
      }
  
      & > div {
        width: 60px;
        height: 60px;
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .carousel_arrow {
      width: 50px;
      height: 50px;
      margin-top: 540px;
  
      &.arrow_left {
        left: 83px;
      }
  
      &.arrow_right {
        right: 83px;
      }
  
      & > div {
        width: 50px;
        height: 50px;
      }
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    margin: 0;
    width: 100vw;
    height: 1062px;
    background: lightgray;

    .main_container {
      margin: 0 auto;
      width: 100vw;
    }
  }
`;


interface ItemProps {}
export const StyledItem = styled("div")<ItemProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    height: 1062px;

    padding: 134.7px 0 157px 0;
    width: 1084px;
    margin: 0 auto;

    .top_title_01,
    .top_title_02,
    .top_title_03 {
      width: 781px;
      height: auto;
      margin: 0;
      font-family: Gilroy-Bold;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #2d2f3b;
    }

    .content_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      margin-top: 66px;

      &.content_box_02 {
        margin-top: 91px;
      }

      .carousel_02_img_01.miles_ui_screen_larger_than_1150 {
        display: inline-block;
        width: 1041px;
        height: 576px;
      }

      .carousel_02_img_02.miles_ui_screen_larger_than_1150 {
        display: inline-block;
        width: 1054.5px;
        height: 545px;
      }

      .carousel_02_img_03.miles_ui_screen_larger_than_1150 {
        display: inline-block;
        width: 1084px;
        height: 501px;
      }

      .carousel_02_img_01.miles_ui_screen_1280,
      .carousel_02_img_02.miles_ui_screen_1280,
      .carousel_02_img_03.miles_ui_screen_1280 {
        display: none;
      }

      .carousel_02_img_01.miles_ui_screen_1000,
      .carousel_02_img_02.miles_ui_screen_1000,
      .carousel_02_img_03.miles_ui_screen_1000 {
        display: none;
      }

      .carousel_02_img_01.miles_ui_screen_600,
      .carousel_02_img_02.miles_ui_screen_600,
      .carousel_02_img_03.miles_ui_screen_600 {
        display: none;
      }

      .carousel_02_img_01.miles_ui_screen_360,
      .carousel_02_img_02.miles_ui_screen_360,
      .carousel_02_img_03.miles_ui_screen_360 {
        display: none;
      }

      .CTA_button_on_carousel_02 {
        align-self: flex-start;
        width: 319px;
        height: 64px;
        margin: 0;
        padding: 0;
        border-radius: 32px;
        background-color: #10af42;
        font-family: Gilroy-SemiBold;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        border: none;
        outline: none;
        cursor: pointer;

        &.btn_02 {
          margin-top: -64px;
        }
      }

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
      padding: 84px 0 103.9px 0;
      width: 286px;

      .top_title_01,
      .top_title_02,
      .top_title_03 {
        width: 255px;
        height: auto;
        margin: 0 auto;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .content_box {
        margin-top: 90px;

        .carousel_02_img_01.miles_ui_screen_larger_than_1150,
        .carousel_02_img_02.miles_ui_screen_larger_than_1150,
        .carousel_02_img_03.miles_ui_screen_larger_than_1150 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1280,
        .carousel_02_img_02.miles_ui_screen_1280,
        .carousel_02_img_03.miles_ui_screen_1280 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1000,
        .carousel_02_img_02.miles_ui_screen_1000,
        .carousel_02_img_03.miles_ui_screen_1000 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_600,
        .carousel_02_img_02.miles_ui_screen_600,
        .carousel_02_img_03.miles_ui_screen_600 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_360 {
          display: inline-block;
          width: 224px;
          height: 527px;
        }

        .carousel_02_img_02.miles_ui_screen_360 {
          display: inline-block;
          width: 220px;
          height: 525.7px;
        }

        .carousel_02_img_03.miles_ui_screen_360 {
          display: inline-block;
          width: 286px;
          height: 532.7px;
        }

        .CTA_button_on_carousel_02 {
          width: 164px;
          height: 52px;
          margin: 0 auto;
          padding: 14px 21px;
          border-radius: 25px;
          box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
          background-color: #10af42;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.7px;
          text-align: center;
          color: #ffffff;
          margin-top: 62px;

          &.btn_02 {
            margin-top: 62px;
          }
        }

      }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      padding: 83.7px 0 97.9px 0;
      width: 330px;

      .top_title_01,
      .top_title_02,
      .top_title_03 {
        width: 255px;
        height: 136px;
        margin: 0 auto;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .content_box {
        margin-top: 55px;

        &.content_box_01 {
          margin-top: 60px;
        }

        &.content_box_02 {
          margin-top: 62px;
        }

        .carousel_02_img_01.miles_ui_screen_larger_than_1150,
        .carousel_02_img_02.miles_ui_screen_larger_than_1150,
        .carousel_02_img_03.miles_ui_screen_larger_than_1150 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1280,
        .carousel_02_img_02.miles_ui_screen_1280,
        .carousel_02_img_03.miles_ui_screen_1280 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1000,
        .carousel_02_img_02.miles_ui_screen_1000,
        .carousel_02_img_03.miles_ui_screen_1000 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_600 {
          display: inline-block;
          width: 224px;
          height: 504px;
        }

        .carousel_02_img_02.miles_ui_screen_600 {
          display: inline-block;
          width: 221px;
          height: 502.7px;
        }

        .carousel_02_img_03.miles_ui_screen_600 {
          display: inline-block;
          width: 331px;
          height: 509.7px;
        }

        .CTA_button_on_carousel_02 {
          width: 164px;
          height: 52px;
          margin: 0 auto;
          padding: 14px 21px;
          border-radius: 25px;
          box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
          background-color: #10af42;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.7px;
          text-align: center;
          color: #ffffff;
          margin-top: 85px;

          &.btn_02 {
            margin-top: 85px;
          }
        }

      }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
        
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
      padding: 96px 0 119.3px;
      width: 524px;

      .top_title_01,
      .top_title_02,
      .top_title_03 {
        width: 457px;
        height: auto;
        margin: 0 auto;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .content_box {
        margin-top: 45px;

        &.content_box_01 {
          margin-top: 57px;
        }

        &.content_box_02 {
          margin-top: 57px;
        }

        .carousel_02_img_01.miles_ui_screen_larger_than_1150,
        .carousel_02_img_02.miles_ui_screen_larger_than_1150,
        .carousel_02_img_03.miles_ui_screen_larger_than_1150 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1280,
        .carousel_02_img_02.miles_ui_screen_1280,
        .carousel_02_img_03.miles_ui_screen_1280 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1000 {
          display: inline-block;
          width: 424px;
          height: 570px;
        }

        .carousel_02_img_02.miles_ui_screen_1000 {
          display: inline-block;
          width: 490px;
          height: 569.7px;
        }

        .carousel_02_img_03.miles_ui_screen_1000 {
          display: inline-block;
          width: 524px;
          height: 581.7px;
        }

        .CTA_button_on_carousel_02 {
          width: 164px;
          height: 52px;
          margin: 0 auto;
          padding: 14px 21px;
          border-radius: 25px;
          box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
          background-color: #10af42;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.7px;
          text-align: center;
          color: #ffffff;
          margin-top: 64px;

          &.btn_02 {
            margin-top: 64px;
          }
        }

      }
    }

    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {

      padding: 138.7px 0 143px;
      width: 927px;

      .top_title_01,
      .top_title_02,
      .top_title_03 {}

      .content_box {
        margin-top: 80px;

        &.content_box_01 {
          margin-top: 70px;
        }

        .carousel_02_img_01.miles_ui_screen_larger_than_1150,
        .carousel_02_img_02.miles_ui_screen_larger_than_1150,
        .carousel_02_img_03.miles_ui_screen_larger_than_1150 {
          display: none;
        }

        .carousel_02_img_01.miles_ui_screen_1280 {
          display: inline-block;
          width: 931px;
          height: 483px;
        }

        .carousel_02_img_02.miles_ui_screen_1280 {
          display: inline-block;
          width: 927px;
          height: 463px;
        }

        .carousel_02_img_03.miles_ui_screen_1280 {
          display: inline-block;
          width: 927px;
          height: 547px;
        }

        .CTA_button_on_carousel_02 {
          margin-top: -64px;

          &.btn_02 {
            margin-top: 0;
          }

        }

      }
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
        
    }
`;