import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const auto_oem_page_assets = {
  oem_hero_01_largerThan_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/oem_hero_01_largerThan_1440/Mask+Group%402x.png`,
  oem_hero_01_1150_x_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/oem_hero_01_1150_x_1440/Mask+Group%402x.png`,
  oem_hero_01_768_x_1150_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/oem_hero_01_768_x_1150/Mask+Group%402x.png`,
  oem_hero_01_360_x_768_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/oem_hero_01_360_x_768/Mask+Group%402x.png`,
  oem_hero_01_360_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/oem_hero_01_360/Group+648%402x.png`,

  core_solution_hero_01_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_01_1440_v2/Group+646%402x.png`,
  core_solution_hero_01_1150_x_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_01_1150_x_1440_v2/Group+647%402x.png`,
  core_solution_hero_01_768_x_1150_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_01_768_x_1150_v2/Group+637%402x.png`,
  core_solution_hero_01_360_x_768_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_01_360_x_768_v2/Group+638%402x.png`,
  core_solution_hero_01_x_360_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_01_x_360_v2/Group+643%402x.png`,
  core_solution_hero_02_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_02_1440_v2/Group+657%402x.png`,
  core_solution_hero_02_1150_x_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_02_1150_x_1440_v2/Group+314%402x.png`,
  core_solution_hero_02_768_x_1150_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_02_768_x_1150_v2/Group+354%402x.png`,
  core_solution_hero_02_360_x_768_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_02_360_x_768_v2/Group+639%402x.png`,
  core_solution_hero_02_x_360_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_02_x_360_v2/Group+364%402x.png`,

  core_solution_hero_03_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_03_1440/Group+338%402x.png`,
  core_solution_hero_03_1150_x_1440_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_03_1150_x_1440/Group+337%402x.png`,
  core_solution_hero_03_768_x_1150_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_03_768_x_1150/Group+354%402x.png`,
  core_solution_hero_03_360_x_768_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_03_360_x_768/Group+369%402x.png`,
  core_solution_hero_03_x_360_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/core_solution_hero_03_360/Group+369%402x.png`,

  help_your_brand_01_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/help_your_brand_01/icon_engagement%402x.png`,
  help_your_brand_02_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/help_your_brand_02/icon_loyalty%402x.png`,
  help_your_brand_03_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/help_your_brand_03/Icon_Customer%402x.png`,
  help_your_brand_04_img: `${MILES_WEB_S3_BASE_URL_V2}oem_page/help_your_brand_04/Icon_Revenue%402x.png`,
};
