import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  .ciq_root{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid red;"
  }
  }

  .ciq_container{
    width: 1440px;
    height: 430px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .top_row, .bottom_row{
    display: flex;
    flex-direction: row;
    width: 1022px;
    height: 200px;
  }

  .ciq_spacer{
    margin-bottom: 29px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq_container{
      width: auto;
      height: 1450px;
      ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 286px;
    }

    .top_row, .bottom_row{
      display: flex;
      flex-direction: column;
      width: 280px;
      height: 700px;
    }
    
    .ciq_spacer{
      margin-bottom: 50px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq_container{
      width: auto;
      height: 1450px;
      ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid blue;"
  }
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 286px;
    }

    .top_row, .bottom_row{
      display: flex;
      flex-direction: column;
      width: 320px;
      height: 700px;
    }
    
    .ciq_spacer{
      margin-bottom: 50px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .ciq_container{
      margin-bottom: 286px;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .ciq_container{
      margin-bottom: 286px;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .ciq_container{
      margin-bottom: 286px;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .ciq_container{
      margin-bottom: 129px;
    }

    .top_row, .bottom_row{
      display: flex;
      flex-direction: row;
      width: 672px;
      height: 200px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .ciq_container{
      margin-bottom: 225px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .ciq_container{
      margin-bottom: 224px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    .ciq_container{
      margin-bottom: 223px;
    }
  }
`;