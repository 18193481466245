import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

interface CopyBoxProps {
  shouldShowCopyBox: boolean;
  id: string;
  ref: RefObject<HTMLIFrameElement>;
}
export const StyledCopyBox = styled("div")<CopyBoxProps>`
  .copy_box_wrap {
    width: 686px;
    height: 52px;
    margin: 0 auto;
    padding: 0;
    border-radius: 30px;
    border: dashed 1.5px #afafb5;
    background-color: ${(props) => props.shouldShowCopyBox ? 'black' : 'white'};
    font-family: ${fontFamily_Gilroy_Medium};
    font-size: ${(props) => props.shouldShowCopyBox ? '20px' : '34px'};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: ${(props) => props.shouldShowCopyBox ? 'white' : 'black'};
    box-sizing: border-box;
    outline: none;
    text-align: center;
    line-height: 52px;
    vertical-align: middle;
  }

  .copy_box_img_wrap {
    display: none;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .copy_box_wrap {
      width: 335px;
      font-size: ${(props) => props.shouldShowCopyBox ? '17px' : '24px'};
      display: ${(props) => props.shouldShowCopyBox ? 'block' : 'none'};
      margin-top: 27px;
    }

    .copy_box_img_wrap {
      display: block;
      position: relative;
      height: 52px;
      display: ${(props) => props.shouldShowCopyBox ? 'none' : 'block'};
      margin-bottom: 25px;
      margin-left: 10px;
      margin-top: 27px;

      .raf_copy_box_img {
        width: 203px;
        height: 52px;
      }

    }

    .invite_code_wrap {
      width: 203px;
      margin-top: -40px;
      
      .invite_code {
        width: auto;
        height: 16px;
        margin: 0 0 1.5px;
        font-family: ${fontFamily_Gilroy_Medium};
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.39px;
        text-align: center;
        color: #2d2f3b;
      }

      .invite_code_tip {
        width: auto;
        height: 13px;
        margin: 1.5px 0 0 ;
        font-family: ${fontFamily_Gilroy_Regular};
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #9b9ba5;
      }

    }

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .copy_box_wrap {
      width: 335px;
      font-size: ${(props) => props.shouldShowCopyBox ? '17px' : '24px'};
      display: ${(props) => props.shouldShowCopyBox ? 'block' : 'none'};
    }

    .copy_box_img_wrap {
      position: relative;
      height: 52px;
      display: ${(props) => props.shouldShowCopyBox ? 'none' : 'block'};
      margin-bottom: 25px;
      
      .raf_copy_box_img {
        width: 203px;
        height: 52px;
      }

    }
  }
  /* ----------- mobile IOS ----------- */

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    .copy_box_wrap {
      width: 335px;
      font-size: ${(props) => props.shouldShowCopyBox ? '17px' : '24px'};
      display: ${(props) => props.shouldShowCopyBox ? 'block' : 'none'};
    }

    .copy_box_img_wrap {
      position: relative;
      height: 52px;
      display: ${(props) => props.shouldShowCopyBox ? 'none' : 'block'};
      margin-bottom: 25px;
      
      .raf_copy_box_img {
        width: 203px;
        height: 52px;
      }

    }
  }

  @media only screen and (min-width : 769px) and (max-width : 1279px) {
    .copy_box_wrap {
      width: 592px;
      font-size: ${(props) => props.shouldShowCopyBox ? '20px' : '28px'};
    }
  }
  
  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    .copy_box_wrap {
      height: 52px;
      line-height: 52px;
      width: 600px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    .copy_box_wrap {
      height: 52px;
      line-height: 52px;
      width: 686px;
    }
  }

`;