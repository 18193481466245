import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
    margin: 0;
    width: 100vw;
    height: 1168px;
    height: auto;
    background: #ffffff;

    .main_container {
      margin: 0 auto;
      width: 1440px;
    }

    .miles_ui_screen_larger_than_360 {
      display: block;
    }

    .miles_ui_screen_less_than_360 {
      display: none;
    }

    .partner_we_work_with_box {
      width: 1253px;
      height: 927px;
      margin: 125px auto 0 auto;
      padding: 100px 91px 162px;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;

      .title_on_partner_we_work_with {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: Noto Sans JP;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
      }

      .CTA_box_on_partner_we_work_with {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 86px;

        .asset_on_partner_we_work_with_img {
          width: 629px;
          height: 520px;
          display: inline-block;
          margin-right: 140px;
          background-image: url('https://getmiles.s3.amazonaws.com/assets/web_v2/jp_web/home_page/mjkk_asset_partner_we_work_with_1440/Group+683%403x.png');
          background-repeat: no-repeat; 
          background-size: contain;
          background-position: center;
        }

        .CTA_box_content_on_partner_we_work_with {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-item: center;

          .CTA_text_on_partner_we_work_with {
            width: 314px;
            height: auto;
            font-family: Noto Sans JP;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: center;
            color: #2d2f3b;
            margin-top: 83px;
          }

          .CTA_btn_on_partner_we_work_with {
            margin: 0 auto;
            width: 273px;
            height: 64px;
            padding: 0;
            border-radius: 32px;
            background-color: #10af42;
            margin-top: 33px;
            cursor: pointer;
            border: none;
            outline: none;

            font-family: Noto Sans JP;
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
          }


        }

      }

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 360px) {
      height: auto;

      .main_container {
        width: 100vw;
      }

      .miles_ui_screen_larger_than_360 {
        display: none;
      }
  
      .miles_ui_screen_less_than_360 {
        display: block;
      }

      .partner_we_work_with_box {
        width: 300px;
        height: 671px;
        margin: 60px auto 0 auto;
        padding: 48px 19px 63px 19px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        .title_on_partner_we_work_with {
          width: max-content;
          height: auto;
          margin: 0 auto 30px;
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.55;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 0;
        }

        .CTA_box_on_partner_we_work_with {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 0;

          .asset_on_partner_we_work_with_img {
            width: 262px;
            height: 239px;
            display: inline-block;
            margin-right: auto;
            background-image: url('https://getmiles.s3.amazonaws.com/assets/web_v2/jp_web/home_page/mjkk_asset_partner_we_work_with_360/360%403x.png');
            background-repeat: no-repeat; 
            background-size: contain;
            background-position: center;
          }

          .CTA_box_content_on_partner_we_work_with {
            justify-content: center;
            align-item: center;

            .CTA_text_on_partner_we_work_with {
              width: 220px;
              height: auto;
              margin: 0 auto;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.88;
              letter-spacing: normal;
              text-align: center;
              color: #2d2f3b;
              margin-top: 54px;
            }

            .CTA_btn_on_partner_we_work_with {
              margin: 0 auto;
              width: 159px;
              height: 40px;
              padding: 8px 21px 8px 22px;
              border-radius: 25px;
              box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
              background-color: #10af42;
              margin-top: 48px;
              cursor: pointer;
              border: none;
              outline: none;
              font-family: Noto Sans JP;
              font-size: 13px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.54;
              letter-spacing: 0.5px;
              text-align: center;
              color: #ffffff;
            }


          }

        }

      }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
      height: auto;

      .main_container {
        width: 100vw;
      }

      .partner_we_work_with_box {
        width: 340px;
        height: 671px;
        margin: 60px auto 0 auto;
        padding: 48px 26px 63px 27px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        .title_on_partner_we_work_with {
          width: max-content;
          height: auto;
          margin: 0 auto 30px;
          font-size: 22px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.55;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 0;
        }

        .CTA_box_on_partner_we_work_with {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 0;

          .asset_on_partner_we_work_with_img {
            width: 287px;
            height: 262px;
            display: inline-block;
            margin-right: auto;
            background-image: url('https://getmiles.s3.amazonaws.com/assets/web_v2/jp_web/home_page/mjkk_asset_partner_we_work_with_600/600%403x.png');
            background-repeat: no-repeat; 
            background-size: contain;
            background-position: center;
          }

          .CTA_box_content_on_partner_we_work_with {
            justify-content: center;
            align-item: center;

            .CTA_text_on_partner_we_work_with {
              width: 255px;
              height: auto;
              margin: 0 auto;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.88;
              letter-spacing: normal;
              text-align: center;
              color: #2d2f3b;
              margin-top: 80px;

            }

            .CTA_btn_on_partner_we_work_with {
              width: 159px;
              height: 40px;
              margin: 0 auto;
              padding: 8px 21px 8px 22px;
              border-radius: 25px;
              box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
              background-color: #10af42;
              margin-top: 30px;
              cursor: pointer;
              border: none;
              outline: none;
              font-family: Noto Sans JP;
              font-size: 13px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.54;
              letter-spacing: 0.5px;
              text-align: center;
              color: #ffffff;
            }


          }

        }

      }
    }

    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
      
    }

    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    }

    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    }

    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
      height: auto;

      .main_container {
        width: 100vw;
      }

      .partner_we_work_with_box {
        width: 651px;
        height: 1004px;
        margin: 94px auto 0 auto;

        padding: 68px 72px 93px 73px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        .title_on_partner_we_work_with {
          width: max-content;
          height: auto;
          margin: 0 auto;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.56;
          letter-spacing: normal;
          text-align: center;
          color: #2d2f3b;
          margin-top: 0;
        }

        .CTA_box_on_partner_we_work_with {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 63px;

          .asset_on_partner_we_work_with_img {
            width: 476px;
            height: 435px;
            display: inline-block;
            margin-right: auto;
            background-image: url('https://getmiles.s3.amazonaws.com/assets/web_v2/jp_web/home_page/mjkk_asset_partner_we_work_with_1000/1000%403x.png');
            background-repeat: no-repeat; 
            background-size: contain;
            background-position: center;
          }

          .CTA_box_content_on_partner_we_work_with {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-item: center;

            .CTA_text_on_partner_we_work_with {
              width: 415px;
              height: auto;
              margin: 0 auto;
              font-family: Noto Sans JP;
              font-size: 24px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: normal;
              text-align: center;
              color: #2d2f3b;
              margin-top: 76px;

              & span {
                font-size: 40px;
              }
            }

            .CTA_btn_on_partner_we_work_with {
              margin: 0 auto;
              width: 273px;
              height: 64px;
              padding: 0;
              border-radius: 32px;
              background-color: #10af42;
              margin-top: 33px;
              cursor: pointer;
              border: none;
              outline: none;

              font-family: Noto Sans JP;
              font-size: 24px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #ffffff;
            }


          }

        }

      }

    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1439px) {
      height: auto;

      .main_container {
        width: 100vw;
      }
  
      .partner_we_work_with_box {
        width: 1057px;
        height: 927px;
        padding: 100px 64px 145px 62px;
        
        .CTA_box_on_partner_we_work_with {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 121px;
  
          .asset_on_partner_we_work_with_img {
            width: 550px;
            height: 502px;
            display: inline-block;
            margin-right: 93px;
            background-image: url('https://getmiles.s3.amazonaws.com/assets/web_v2/jp_web/home_page/mjkk_asset_partner_we_work_with_1280/1280%403x.png');
            background-repeat: no-repeat; 
            background-size: contain;
            background-position: center;
          }
  
          .CTA_box_content_on_partner_we_work_with {
  
            .CTA_text_on_partner_we_work_with {

            }
  
          }
  
        }
  
      }

    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (width : 1440px) {
    }

    /* Large Devices, Wide Screens ----------- */
    @media only screen and (min-width : 1441px) {
      margin: 0;
      width: 100vw;
      background: #ffffff;
      height: auto;

      .main_container {
        margin: 0 auto;
        width: 1440px;
      }
    }
`;