import config from "../../config";
const {
	download_text,
	share_text,
	redeem_rewards_text,
	// reward sharing start
	reward_share_download_text,
	reward_share_share_text,
	reward_share_redeem_rewards_text,
	apple_store_icon,
	apple_store_src,
	google_play_store_icon,
	google_play_store_src,
	listItemContent_1,
	listItemContent_2,
	listItemContent_3,
	// reward sharing end

	// help page start
	contact_page_assets,
	// help page end
	// partner page start
	partner_page_assets,
	// partner page end
	about_page_assets,
  } = config

const ENGLISH_LNG = {
	"email_unsubscribe_initial_box_title": "Are you sure you want to unsubscribe from the following email category?",
	"text_confirm": "Confirm",
	"text_cancel": "Cancel",

	"email_unsubscribe_confirm_box_title": "We’re sad to see you go....",
	"email_unsubscribe_confirm_box_subTitle_1": "You have been successfully unsubscribed from '",
	"email_unsubscribe_confirm_box_subTitle_2": "' emails. If you did this in error, you may resubscribe by clicking the button below.",
	"email_unsubscribe_confirm_box_cheer_text": "Hope to keep delivering amazing experiences!",
	"text_reSubscribe": "Resubscribe",
	"preference_preText": "Or manage email",
	"text_preference": "preferences",
	"text_note": "Note",
	"text_note_icon": ":",
	"text_note_description": " Please allow 10 days for changes to take effect.",

	"text_Thank_you": "Thank you!",
	"email_unsubscribe_thx_box_resubscribe_subTitle_1": "We have resubscribed you to ‘",
	"email_unsubscribe_thx_box_resubscribe_subTitle_2": "’ emails. You can update your email preferences anytime from the app.",
	"email_unsubscribe_thx_box_resubscribeAll_subTitle": "We have resubscribed you to all our emails. You can update your email preferences anytime from the app.",
	"email_unsubscribe_thx_box_cheer_text": "Hope to keep delivering amazing experiences!",

	"email_unsubscribe_thx_box_update_preference_by_group_subTitle": "We have updated your email preferences. We hope to keep delivering you amazing experiences.",
	"email_unsubscribe_thx_box_note_description": " Please allow 10 days for changes to take effect.",

	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_title": "We’re sad to see you go...",
	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_subTitle": "You have been successfully unsubscribed from all email communications. If you did this in error, you may resubscribe by clicking the button below.",
	
	"email_Preferences_box_title": "Email Preferences",
	"email_Preferences_box_subTitle": "Uncheck the types of emails you do not want to receive.",
	"email_Preferences_box_btn_text": "Update Email Preferences",
	"text_Unsubscribe_from_all": "Unsubscribe from all",
	"text_Unsubscribe_from_all_description": "Stop receiving all marketing and promotional emails. You will continue to receive critical communications related to your account, if needed.",

	// -- RAF page start --
	"raf_head_logo_url": "/",
	"raf_text_01_01": "Try Miles App and we'll both get",
	"raf_text_01_02": "miles",
	"raf_text_02": "Everyday, Rewarded.",

	"raf_download_text": download_text,
	"raf_share_text": share_text,
	"raf_redeem_rewards_text": redeem_rewards_text,

	"raf_text_03": "'s invite code",

	"raf_download_text_pre": "+1",
	"raf_phone_num_placeholder": "(123) - 456 - 7890",
	"raf_text_copied": "Copied",
	"raf_text_copy": "Copy invite code",
	"raf_text_copy_mobile": "Copy",
	"raf_text_or": "or",

	"raf_fcaRafDownloadText": "Check your phone for download link",

	"raf_text_04": "Send Download Link",
	"raf_text_05": "Sending...",

	"raf_text_err_01": "Please enter a valid US phone number.",
	"raf_text_06": "GET THE APP",
	"raf_get_the_app_url_path": "/refer?c=",

	// -- RAF page end --

	// -- Reward Sharing page start --
	"rs_header_logo_url": "/",
	"rs_text_001": "Your friend shared this reward with you",
	"rs_text_002": "Not a member yet?",
	"rs_reward_share_download_text": reward_share_download_text,
	"rs_reward_share_share_text": reward_share_share_text,
	"rs_reward_share_redeem_rewards_text": reward_share_redeem_rewards_text,

	"rs_reward_share_available_ios": "Available on App Store",
	"rs_reward_share_available_android": "Available on Google Play",
	"rs_reward_share_available_rating_text": "18.2K+ Ratings",

	"rs_apple_store_icon": apple_store_icon,
	"rs_apple_store_src": apple_store_src,
	"rs_google_play_store_icon": google_play_store_icon,
	"rs_google_play_store_src": google_play_store_src,

	"rs_text_003": "Check your phone for download link",
	"rs_text_004": "(123) - 456 - 7890",
	"rs_text_005": "+1",
	"rs_text_006": "Sending...",
	"rs_text_007": "Send Download Link",
	"rs_text_007_short": "Download",
	"rs_text_008": "Please enter a valid US phone number.",
	"rs_text_009": "Get the App",

	"rs_text_010": "Already a member?",

	"rs_listItemContent_1": listItemContent_1,
	"rs_listItemContent_2_01": listItemContent_2 + `'`,
	"rs_listItemContent_2_02": `' reward`,
	"rs_listItemContent_3": listItemContent_3,

	"rs_text_011": `& 200+ rewards`,
	"rs_text_012": `from exclusive brands`,

	// -- Reward Sharing page end --

	// -- Help page start --
	"help_text_001": "We’re Here To Help",
	"help_page_header_asset_1": contact_page_assets.contact_page_header_asset_1,
	"help_page_header_asset_2": contact_page_assets.contact_page_header_asset_2,
	"help_page_header_asset_3": contact_page_assets.contact_page_header_asset_3,
	"help_page_header_asset_4": contact_page_assets.contact_page_header_asset_4,
	"help_text_002": "Member Support",
	"help_text_003": "Partnership Support",

	"help_form_text_001": "How can we help?",
	"help_form_text_002": "Subject",
	"help_form_text_002_placeHolder": "Subject",
	"help_form_text_002_err": "Please select a category",
	"help_form_text_003": "Name",
	"help_form_text_003_placeHolder": "Name",
	"help_form_text_003_err": "Please enter a valid name",
	"help_form_text_004": "Email Address",
	"help_form_text_004_placeHolder": "Email Address",
	"help_form_text_004_err": "Please enter a valid email address",
	"help_form_text_005": "Phone Number (Optional)",
	"help_form_text_005_err": "Please enter a valid US phone number",
	"help_form_text_006": "How can we help?",
	"help_form_text_006_placeHolder": "How can we help?",
	"help_form_text_006_err": "Please enter how can we help with",
	"help_form_text_007": "Please enter the details of your request. A member of our support staff will respond as soon as possible.",
	"help_form_text_008": "Submit",
	"help_form_text_009": "(123) - 456 - 7890",
	"help_form_text_010": "Something went wrong. Please try again.",
	"help_form_text_011": "We have received your request. Someone from our team will get back to you within 48 hours. ",
	// SubjectValuesByUserType start
	"help_form_text_012": "Earning Miles",
	"help_form_text_013": "Redeeming Miles",
	"help_form_text_014": "Referrals",
	"help_form_text_015": "Profile & Account Settings",
	"help_form_text_016": "Other",

	"help_form_text_019": "Interested in being a reward partner",
	"help_form_text_020": "We are a City/public agency",
	"help_form_text_021": "We are a mobility service provider",
	"help_form_text_022": "Inquiring about PR opportunity",
	"help_form_text_023": "Other",
	// SubjectValuesByUserType end
	"help_form_text_024": "Select one",

	// -- Help page end --

	// -- partner page start --
	"partner_page_text_001": "The only marketing channel with",
	"partner_page_text_002": "Guaranteed High Intent & High Quality Users",
	"partner_page_text_003": "Performance marketing channel to guarantee your brand free awareness and customers with highest intent.",
	"partner_page_text_004": "Partner with Us",

	"partner_page_discover_text_001": "Discover New Customers",
	"partner_page_discover_text_002": "User Profile Insights",
	"partner_page_discover_text_003": "Understand and connect your brand with potential customers that match your target demographic.",
	"partner_page_discover_text_004": "Behavioral Insights",
	"partner_page_discover_text_005": "Expand discoverability of your brand by targeting users with behavioral insights.",
	"partner_page_discover_text_006": "Location & POI Insights",
	"partner_page_discover_text_007": "Utilize our first party location and POI data to run geo-targeted offers and reach customers at the right time.",

	"partner_page_carousel_01_text_001": "Acquire & Retain Your Customers",
	"partner_page_carousel_01_text_002": "From building brand awareness to increasing the lifetime value of your customers, Miles gives you multiple opportunities to get in front of the the right customers.",
	"partner_page_carousel_01_a_001": partner_page_assets.carousel_01_a_1440_v2_img,
	"partner_page_carousel_01_a_002": partner_page_assets.carousel_01_a_1280_v2_img,
	"partner_page_carousel_01_a_003": partner_page_assets.carousel_01_a_1000_v2_img,
	"partner_page_carousel_01_a_004": partner_page_assets.carousel_01_a_600_v2_img,
	"partner_page_carousel_01_b_001": partner_page_assets.carousel_01_b_1440_v2_img,
	"partner_page_carousel_01_b_002": partner_page_assets.carousel_01_b_1280_v2_img,
	"partner_page_carousel_01_b_003": partner_page_assets.carousel_01_b_1000_v2_img,
	"partner_page_carousel_01_b_004": partner_page_assets.carousel_01_b_600_v2_img,
	"partner_page_carousel_01_c_001": partner_page_assets.carousel_01_c_1440_v2_img,
	"partner_page_carousel_01_c_002": partner_page_assets.carousel_01_c_1280_v2_img,
	"partner_page_carousel_01_c_003": partner_page_assets.carousel_01_c_1000_v2_img,
	"partner_page_carousel_01_c_004": partner_page_assets.carousel_01_c_600_v2_img,
	"partner_page_carousel_01_d_001": partner_page_assets.carousel_01_d_1440_v2_img,
	"partner_page_carousel_01_d_002": partner_page_assets.carousel_01_d_1280_v2_img,
	"partner_page_carousel_01_d_003": partner_page_assets.carousel_01_d_1000_v2_img,
	"partner_page_carousel_01_d_004": partner_page_assets.carousel_01_d_600_v2_img,

	"partner_page_drive_success_title_text_001": "Drive success in many different ways",
	"partner_page_drive_success_title_text_002": "Miles can work with your brand to identify clear objectives and help achieve its goals through unique offerings and marketing initiatives.",
	"partner_page_drive_success_text_001_01": "Increase",
	"partner_page_drive_success_text_001_02": "Sales and AOV",
	"partner_page_drive_success_text_002": "Get New Customers",
	"partner_page_drive_success_text_003": "Lower CAC",
	"partner_page_drive_success_text_004": "Increase Visits to Physical Stores",
	"partner_page_drive_success_text_005": "Pay for Performance",
	"partner_page_drive_success_text_006": "Simple and Easy to Get Started",

	// -- partner page end --

	// -- contact page start --
	"contact_page_text_001": "Get In Touch With Miles",
	"contact_page_text_002": "Member Support",
	"contact_page_text_003": "Partnership Support",
	"contact_page_card_unit_01": contact_page_assets.contact_page_card_unit_01,
	"contact_page_card_unit_02": contact_page_assets.contact_page_card_unit_02,
	"contact_page_image_background_box": contact_page_assets.contact_page_image_background_box,
	"contact_page_location_image_box": contact_page_assets.contact_page_location_image_box,
	"contact_page_header_asset_1": contact_page_assets.contact_page_header_asset_1,
	"contact_page_header_asset_2": contact_page_assets.contact_page_header_asset_2,
	"contact_page_header_asset_3": contact_page_assets.contact_page_header_asset_3,
	"contact_page_header_asset_4": contact_page_assets.contact_page_header_asset_4,
	"contact_page_send_contact_query_success_asset_1": contact_page_assets.send_contact_query_success_asset_1,
	"contact_page_send_contact_query_success_asset_2": contact_page_assets.send_contact_query_success_asset_2,
	// -- contact page end --

	// -- about page start --
	"about_page_carousel_01_title": "The Miles Journey",
	"about_page_carousel_01_rawItems_01": "2016",
	"about_page_carousel_01_rawItems_01_content": "Miles was founded in 2016",
	"about_page_carousel_01_rawItems_02": "July 2018",
	"about_page_carousel_01_rawItems_02_content_part1": "The Miles app",
	"about_page_carousel_01_rawItems_02_content_part2": "was launched",
	"about_page_carousel_01_rawItems_03": "March 2019",
	"about_page_carousel_01_rawItems_03_content": "10 Million Trips",
	"about_page_carousel_01_rawItems_04": "June 2019",
	"about_page_carousel_01_rawItems_04_title": "City Partnerships",
	"about_page_carousel_01_rawItems_04_content_part0": ``,
	"about_page_carousel_01_rawItems_04_content_part1": `Partnered with the City of Sacramento, Aspen, Contra Costa, San Mateo (CalTrain & SamTrans) to encourage `,
	"about_page_carousel_01_rawItems_04_content_part2": "Greener Travel",
	"about_page_carousel_01_rawItems_04_content_part3": ".",
	"about_page_carousel_01_rawItems_05": "March 2020",
	"about_page_carousel_01_rawItems_05_content_part0": `Launched`,
	"about_page_carousel_01_rawItems_05_content_part1": `Stay-At-Home`,
	"about_page_carousel_01_rawItems_05_content_part2": ` bonus miles`,
	"about_page_carousel_01_rawItems_05_content_part3": `(help during COVID)`,
	"about_page_carousel_01_rawItems_06": "May 2020",
	"about_page_carousel_01_rawItems_06_content": `100 Million Trips`,
	"about_page_carousel_01_rawItems_07": "June 2020",
	"about_page_carousel_01_rawItems_07_content_part1": `Awarded 20x bonus miles `,
	"about_page_carousel_01_rawItems_07_content_part2": `for `,
	"about_page_carousel_01_rawItems_07_content_part3": `Social Distancing`,
	"about_page_carousel_01_rawItems_08": "August 2020",
	"about_page_carousel_01_rawItems_08_content_part1": `1 Million Rewards `,
	"about_page_carousel_01_rawItems_08_content_part2": `Redeemed`,
	"about_page_carousel_01_rawItems_08": "August 2020",
	"about_page_carousel_01_rawItems_09": "Sept 2020",
	"about_page_carousel_01_rawItems_09_title": "Donated 600 Mn miles",
	"about_page_carousel_01_rawItems_09_content": `Partnered w/ Feeding America, UNICEF, American Redcross, National Breast Cancer Foundation, World Wildlife Fund, Toys for Tots, Goodwill to help donate 600 million miles.`,
	"about_page_carousel_01_rawItems_10": "Dec 2020",
	"about_page_carousel_01_rawItems_10_title": "Brand Partnerships",
	"about_page_carousel_01_rawItems_10_content": `200+ brand partnerships & committed to adding hundreds more.`,
	"about_page_carousel_01_rawItems_11": "March 2021",
	"about_page_carousel_01_rawItems_11_content_part1": `1 Billion miles `,
	"about_page_carousel_01_rawItems_11_content_part2": `Redeemed`,
	"about_page_carousel_01_rawItems_12": "April 2021",
	"about_page_carousel_01_rawItems_12_content_part1": `Featured in Benzinga as `,
	"about_page_carousel_01_rawItems_12_content_part2": `the Top 10 startup for 2021`,
	"about_page_carousel_01_rawItems_13": "May 2021",
	"about_page_carousel_01_rawItems_13_content_part1": `Introduced Challenges to `,
	"about_page_carousel_01_rawItems_13_content_part2": `Encourage `,
	"about_page_carousel_01_rawItems_13_content_part3": `Sustainability`,
	"about_page_carousel_01_rawItems_14": "June 2021",
	"about_page_carousel_01_rawItems_14_content_part1": `3 Billion miles `,
	"about_page_carousel_01_rawItems_14_content_part2": `Redeemed & Counting`,
	"about_page_carousel_01_rawItems_15": "July 2021",
	"about_page_carousel_01_rawItems_15_content_part1": `7 Million Rewards `,
	"about_page_carousel_01_rawItems_15_content_part2": `Redeemed`,
	"about_page_carousel_01_rawItems_16": "October 2021",
	"about_page_carousel_01_rawItems_16_content_part1": `Miles was launched`,
	"about_page_carousel_01_rawItems_16_content_part2": `in Japan`,
	"about_page_founders_text_001": `Meet The Founders`,
	"about_page_founders_text_002": `Jigar Shah, Paresh Jain, and Parin Shah left their day jobs on a mission - to build a product that provides value for every mile traveled.`,

	"about_page_investors_text_001": `Our Investors`,

	"about_page_work_at_miles_text_001": `Working at Miles!`,
	"about_page_work_at_miles_text_002": `At Miles we’re like family! The culture and atmosphere is a commitment to excellence and to each other.`,
	"about_page_work_at_miles_text_003": `See Openings`,
	"about_page_work_at_miles_url": `https://apply.workable.com/getmiles`,

	"about_page_leadership_text_001": `Introducing`,
	"about_page_leadership_text_002": `Leadership`,
	"about_page_leadership_asset_001": about_page_assets.leadership_1,
	"about_page_leadership_asset_001_name": `Jigar Shah`,
	"about_page_leadership_asset_001_designation": `Co-Founder, CEO`,
	"about_page_leadership_asset_002": about_page_assets.leadership_2,
	"about_page_leadership_asset_002_name": `Paresh Jain`,
	"about_page_leadership_asset_002_designation": `Co-Founder, CBO`,
	"about_page_leadership_asset_003": about_page_assets.leadership_3,
	"about_page_leadership_asset_003_name": `Parin Shah`,
	"about_page_leadership_asset_003_designation": `Co-Founder, Principal Engineer`,
	"about_page_leadership_asset_004": about_page_assets.leadership_4,
	"about_page_leadership_asset_004_name": `Chirayu Krishnappa`,
	"about_page_leadership_asset_004_designation": `CTO`,
	"about_page_leadership_asset_005": about_page_assets.leadership_5,
	"about_page_leadership_asset_005_name": `Ron Drabkin`,
	"about_page_leadership_asset_005_designation": `Strategic Partnerships`,
	// -- about page start --

	// -- jobs page start --
	"jobs_page_text_001": "Join the Rocket-ship to create Mile-Stones!",
	"jobs_page_text_002": "Team up our mission to deliver value to our users for every mile traveled across every mode of transportation.",
	
	"jobs_page_text_003": "Find your right fit!",
	"jobs_page_text_004": "Love building a great company? Explore our open positions to help us scale to hundreds of millions of users.",
	"jobs_page_text_005": "See Openings",

	"jobs_page_text_006": "Where It All Happens?",
	"jobs_page_text_007": "Miles is located at the heart of Silicon Valley, where dreamers and doers come together to build a vision!",
	"jobs_page_text_008": "Miles operates in the heart of Silicon Valley, where technology and innovation meet diversity and culture. ",

	"jobs_page_text_009": "255 Shoreline Drive",
	"jobs_page_text_010": "Suite 600, Redwood City,",
	"jobs_page_text_011": "CA 94065 ",

	"jobs_page_text_012": "Are you ready? Become part of the tomorrow alongside a rockstar team.",
	"jobs_page_text_013": "Join Us",

	// -- jobs page end --

	// -- account delete form start --

	"account_delete_form_text_header": "Request to Delete Account",
	"account_delete_form_text_001": "Name",
	"account_delete_form_text_001_placeHolder": "Name",
	"account_delete_form_text_001_err": "Please enter a valid name",
	"account_delete_form_text_002": "Email Address",
	"account_delete_form_text_002_placeHolder": "Email Address",
	"account_delete_form_text_002_err": "Please enter a valid email address",
	"account_delete_form_text_003": "Phone Number (Optional)",
	"account_delete_form_text_003_placeHolder": "(123) - 456 - 7890",
	"account_delete_form_text_003_err": "Please enter a valid US phone number",
	"account_delete_form_text_004": "Reason for deletion (Optional)",
	"account_delete_form_text_004_placeHolder": "Please enter reason for deletion",
	"account_delete_form_text_004_err": "Please enter reason for deletion",
	"account_delete_form_text_005": "Please enter the details of your account deletion request. Our support staff will respond as soon as possible.",
	"account_delete_form_text_006": "Submit",
	"account_delete_form_text_007": "Something went wrong. Please try again.",
	"account_delete_form_text_008": "We have received your request. Someone from our team will get back to you as soon as possible.",

	// -- account delete form end --

}

export default ENGLISH_LNG;