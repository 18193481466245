import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import { AsYouType } from 'libphonenumber-js'
import config from "../../utils/config";
import {
  EMAIL_DOMAINS_CLEANED,
  EXTRA_EMAIL_DOMAINS_CLEANED,
} from "../../utils/milesConstant";
import {
  checkValidPhoneNumber
} from '../../utils/phoneNumValidator';
import { Styled_body_wrap } from "./styles";
import { store } from "../../Store";
import milesUtils from "../../utils/milesUtils";

const {
  partner_page_assets: assets,
} = config;

const Component: React.FC<{}> = observer(() => {
  const [values, setValues] = useState({
    name: "",
    business_title: "",
    email: "",
    phone_number: "",
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errs, setErrs] = useState({
    name: false,
    business_title: false,
    email: false,
    phone_number: false,
    submissionErr: false
  });

  const [position, setPosition] = useState(0);
  const inputEl = useRef<HTMLInputElement>(null);
  let posit = 0;

  const handleChangePhoneNum = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldUseCurrentValue = (e.target.value.length <= 3);
      let currentPhoneNum = e.target.value.replace(/\D/g, "")
      let formattedPhoneNum;
      
      // TIP: JP phone number may longer than 10
      // if (currentPhoneNum.length > 10) {
      //   currentPhoneNum = currentPhoneNum.slice(0, 10); 
      // }
      // formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : milesUtils.formatPhoneText(currentPhoneNum);
      formattedPhoneNum = shouldUseCurrentValue ? currentPhoneNum : new AsYouType("JP").input(currentPhoneNum);

      const selectionStart = inputEl!.current!.selectionStart as number;

      // let currentPosition = milesUtils.getCurrentPosition(formattedPhoneNum, phoneNumber, posit, selectionStart)
      let currentPosition = milesUtils.getCurrentPosition_JP(formattedPhoneNum, phoneNumber, posit, selectionStart)

      setPosition(currentPosition as number)
      milesUtils.debug_log("selectionStart >>> ", selectionStart);

      setPhoneNumber(formattedPhoneNum);
      store.update_miles_links_status('get_miles_link', 'no_error')
    },
    [position, phoneNumber]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      posit = inputEl.current?.selectionStart as number;
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link', 'no_error')
      }
    },
    [phoneNumber]
  );

  const handleUpdateValues = useCallback(
    (event: React.ChangeEvent<HTMLFormElement | HTMLTextAreaElement | HTMLInputElement>) => {
      setValues({
        ...values,
        ...{ [event.target.name]: event.target.value },
      });
      event.preventDefault();
    },
    [values],
  );

  const checkEmailValue = (email: string) => {
    let new_array = [
      ...EMAIL_DOMAINS_CLEANED,
      ...EXTRA_EMAIL_DOMAINS_CLEANED,
    ]
    return new_array.indexOf(email.trim().split("@")[1]) > -1
  }

  const handleSubmitPartnerData = () => {
    setErrs({
      ...errs,
      business_title: !values.business_title.trim(),
      name: !values.name.trim(),
      email: checkEmailValue(values.email) || !milesUtils.isValidEmailAddress(values.email),
      phone_number: !checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
    })

    let no_err = values.business_title.trim()
      && values.name.trim()
      && !checkEmailValue(values.email) && milesUtils.isValidEmailAddress(values.email)
      && checkValidPhoneNumber(phoneNumber.replace( /[^0-9]/g, '' ))
    
    if (values.name && values.business_title && values.email && phoneNumber && no_err) {
      store.handlePartnerFormData({
        ...values,
        phone_number: phoneNumber,
      }, "pre_footer_form")
    }
  }

  useEffect(() => {
    milesUtils.debug_log("position >>> ", position);
    if (position === 0 && phoneNumber === '') {
      return;
    }
    inputEl.current!.selectionStart = position;
    inputEl.current!.selectionEnd = position;
  }, [position, phoneNumber]);

  return (
    <>
      <Styled_body_wrap className="partner_section_partner_form mjkk" id="partner_page_let_get_started">
        <div className="main_container">
          {/* <div className="title">Let’s Get Started</div> */}
          {
            store.partner_page_form_status.pre_footer_form === "sent"
            ? (
              <div className="form_wrap">
                <div className="partner_page_dialog_header">
                  <span className="partner_page_dialog_header_title"></span>

                </div>

                <img className="partner_page_pre_footer_form_asset" src={assets.partner_page_form_sent_success_asset_img} alt={`partner_page_form_sent_success_asset_img`}></img>
                <div className="partner_page_pre_footer_form_success_text">お問い合わせいただきありがとうございました。折り返し、担当者よりご連絡いたしますので、今しばらくお待ちください。</div>
              </div>
            ) : (
              <div className="form_wrap">

              <div className="partner_page_dialog_header">
                <span className="partner_page_dialog_header_title">以下ご入力お願い致します。</span>
              </div>

                <label className="input_inner_section input_box_01">
                  <span className="label_text">お名前</span>
                  <input
                    className={`${errs.name ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleUpdateValues}
                    required
                    placeholder="お名前"
                  />
                  <span className={errs.name ? "show_err_info" : "hide_err_info"}>有効なお名前をご入力ください</span>
                </label>

                <label className="input_inner_section input_box_02">
                  <span className="label_text">会社名</span>
                  <input
                    className={`${errs.business_title ? "input_err" : "no_err"} partner_preFooter_form_input input_01`}
                    type="text"
                    name="business_title"
                    value={values.business_title}
                    onChange={handleUpdateValues}
                    required
                    placeholder="会社名"
                  />
                  <span className={errs.business_title ? "show_err_info" : "hide_err_info"}>有効な会社名をご入力ください</span>
                </label>

                <label className="input_inner_section input_box_03">
                  <span className="label_text">会社のメールアドレス</span>
                  <input
                    className={`${errs.email ? "input_err" : "no_err"} partner_preFooter_form_input input_02`}
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleUpdateValues}
                    required
                    placeholder="会社のメールアドレス"
                  />
                  <span className={errs.email ? "show_err_info" : "hide_err_info"}>有効な会社のメールアドレスをご入力ください</span>
                </label>

                <label className="input_inner_section input_box_04">
                  <span className="label_text">お電話番号</span>
                  <input
                    ref={inputEl}
                    className={`${errs.phone_number ? "input_err" : "no_err"} partner_preFooter_form_input input_03`}
                    type="tel"
                    name="phone_number"
                    required
                    value={phoneNumber}
                    onChange={handleChangePhoneNum}
                    onKeyDown={handleOnKeyDown}
                    placeholder="例) 03-1234 - 5678"
                  />
                  <span className={errs.phone_number ? "show_err_info" : "hide_err_info"}>有効な電話番号をご入力ください</span>
                </label>

                <div className="tip_text">Milesよりご連絡させていただくことにご同意いただいたものと致します。</div>

                <button className={`partner_page_pre_form_cta_btn miles_ui_btn_primary ${store.partner_page_form_status.pre_footer_form === "sending" ? "loading_status" : ""}`} onClick={() => store.partner_page_form_status.pre_footer_form === "sending" ? {} : handleSubmitPartnerData()}>送信</button>
                <span className={store.partner_page_form_status.pre_footer_form === "server_error" ? "show_err_info submissionErr" : "hide_err_info"}>エラーが発生しました。<br></br>しばらく時間を置いてから再度お試しください。</span>
              </div>
            )
          }
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
