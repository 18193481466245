import React from "react";
import { StyledWrap } from "./styles";

interface Props {
  bgImgSrc: string;
  healthy_icon_img: string;
  stock_icon_img: string;
  pto_icon_img: string;
  gift_icon_img: string;
}

export const Perks_benefits_section: React.FC<Props> = ({
  bgImgSrc,
  healthy_icon_img,
  stock_icon_img,
  pto_icon_img,
  gift_icon_img,
}) => {
  return (
    <StyledWrap>
      <div className="title">Perks & Benefits</div>
      <img className="perks_img" src={bgImgSrc} />

      <div className="perks_sub_title between_360_and_768 lessThan_360">Miles competitive benefits package gives employees the flexibility and peace of mind to live in the now and plan for the future!</div>

      <div className="benefit_box_wrap_01">
        <div className="benefit_box">
          <img className="benefit_icon" src={healthy_icon_img} />
          <div className="inner_title">Stay Healthy</div>

          <div className="benefit_list first_list">
            <span className="dot"></span>
            <span className="benefit_content">Comprehensive Medical,</span>
          </div>

          <div className="benefit_list">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Dental, and Vision</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Life Insurance</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">FSA and HSA</span>
          </div>

        </div>

        <div className="benefit_box">
          <img className="benefit_icon" src={stock_icon_img} />
          <div className="inner_title">Retirement & Stocks</div>

          <div className="benefit_list first_list">
            <span className="dot"></span>
            <span className="benefit_content">401(k) Contribution Plus 4% </span>
          </div>
          
          <div className="benefit_list">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Company Match</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Generous Equity (Options)</span>
          </div>

        </div>

        <div className="benefit_box last">
          <img className="benefit_icon" src={pto_icon_img} />
          <div className="inner_title">Take Time For You!</div>

          <div className="benefit_list first_list">
            <span className="dot"></span>
            <span className="benefit_content">Paid Time Off (PTO) </span>
          </div>
          
          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Unlimited Sick Leaves</span>
          </div>

          <div className="benefit_list large_than_1150">
            <span className="dot"></span>
            <span className="benefit_content">Company & National Holidays</span>
          </div>

          <div className="benefit_list between_768_and_1150">
            <span className="dot"></span>
            <span className="benefit_content">Company & National</span>
          </div>

          <div className="benefit_list between_768_and_1150">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Holidays</span>
          </div>

          <div className="benefit_list between_360_and_768">
            <span className="dot"></span>
            <span className="benefit_content">Company & National</span>
          </div>

          <div className="benefit_list between_360_and_768">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Holidays</span>
          </div>

        </div>
      </div>

      <div className="benefit_box_wrap_02 large_than_1150">

        <div className="benefit_box_inner_section section_01">
          <img className="benefit_icon" src={gift_icon_img} />
          <div className="inner_title">Special Perks</div>
        </div>

        <div className="benefit_box_inner_section section_02">
          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">5k Worth of Workstation</span>
          </div>

          <div className="benefit_list">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Essentials <span>(Apple-branded)</span></span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Free Lunch Everyday!</span>
          </div>
        </div>
        
        <div className="benefit_box_inner_section section_03">
          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Pet-Friendly Office</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Unlimited Snacks and Drinks</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Friday Happy Hours</span>
          </div>
        </div>
        
        <div className="benefit_box_inner_section section_04">
          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Monthly Team Lunches</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Quarterly Team Off-Sites</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Onsite Gym</span>
          </div>
        </div>

      </div>

      <div className="benefit_box_wrap_02 between_768_and_1150">

        <div className="benefit_box_inner_section section_01">
          <img className="benefit_icon" src={gift_icon_img} />
          <div className="inner_title">Special Perks</div>
        </div>

        <div className="benefit_box_inner_section section_02">
          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">5k Worth of Workstation</span>
          </div>

          <div className="benefit_list">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Essentials <span>(Apple-branded)</span></span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Free Lunch Everyday!</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Pet-Friendly Office</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Unlimited Snacks and Drinks</span>
          </div>
        </div>
        
        <div className="benefit_box_inner_section section_03">
          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Friday Happy Hours</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Monthly Team Lunches</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Quarterly Team Off-Sites</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Onsite Gym</span>
          </div>
        </div>
      </div>

      <div className="benefit_box_wrap_02 between_360_and_768">

        <div className="benefit_box_inner_section">
          <img className="benefit_icon" src={gift_icon_img} />
          <div className="inner_title">Special Perks</div>

          <div className="benefit_list first_list">
            <span className="dot"></span>
            <span className="benefit_content">5k Worth of Workstation</span>
          </div>

          <div className="benefit_list">
            <span className="dot_inVisible"></span>
            <span className="benefit_content">Essentials <span>(Apple-branded)</span></span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Free Lunch Everyday!</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Onsite Gym</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Pet-Friendly Office</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Unlimited Snacks and Drinks</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Friday Happy Hours</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Monthly Team Lunches</span>
          </div>

          <div className="benefit_list">
            <span className="dot"></span>
            <span className="benefit_content">Quarterly Team Off-Sites</span>
          </div>
        </div>
      </div>
    </StyledWrap>
  );
};
