import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer, PropTypes } from "mobx-react";
import config from "../../../../utils/config";
import { Styled_body_wrap } from "./styles";

const {} = config;

export interface Props {
  readMoreCTA: string;
}

const Component: React.FC<Props> = observer((props) => {
  return (
    <>
      <Styled_body_wrap className="">
        <div className="cta">
          <a href={props.readMoreCTA} target="_blank">Read more</a>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
