import { observer } from 'mobx-react';
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StyledInput,
  StyledBtn,
  StyledSendDownloadErrTipEle,
  StyledSuccessInfo,
} from './styles';
import milesUtils from "../../../utils/milesUtils";
import { store } from "../../../Store";
import { useMediaQuery } from '@material-ui/core';
import config from "../../../utils/config";
import { StyledNav } from "./styles";

const {
  apple_store_icon_img,
  google_play_icon_img,
  apple_store_src,
  google_play_store_src,
} = config

interface InternalProps {
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type: string;
  handleSendDownloadLink?: () => void;
  inputEl: RefObject<HTMLInputElement>;
  className?: string;
}

const GetMilesCTAInternal: React.FC<InternalProps> = observer(({
  inputEl,
  placeholder,
  value,
  onChange,
  onKeyDown,
  type,
  handleSendDownloadLink,
  className,
}) => {
  let isBtnDisabled = store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error' || store.get_miles_links_status.get_miles_link_on_nav === 'sending'
  let shouldShowErrMessage = store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error'
                              || store.get_miles_links_status.get_miles_link_on_nav === 'timeout'
                              || store.get_miles_links_status.get_miles_link_on_nav === 'server_error'
  const mediaQueryResultForBtnText = useMediaQuery('(min-width: 550px) and (max-width: 1150px)');
  const ctaText = mediaQueryResultForBtnText ? "リンクを送る" : 'リンクを送る';
  const successText = mediaQueryResultForBtnText ? "リンクが送られました！" : 'リンクが送られました！';

  return (
    <>
      {store.get_miles_links_status.get_miles_link_on_nav === 'sent'
        ? <StyledSuccessInfo>{successText}</StyledSuccessInfo>
        : (
          <>
            <div className={className}>
              <StyledInput
                ref={inputEl}
                send_downloadLink_phoneErr={shouldShowErrMessage}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type={type}
              /> 

              <StyledBtn
                onClick={handleSendDownloadLink}
                style={{
                  cursor: isBtnDisabled ? 'unset' : 'pointer'
                }}
                disabled={isBtnDisabled}
                bgColor={store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error' ? '#9b9ba5' : '#10af42'}
                className="navPhoneNumberButton"
              >{store.get_miles_links_status.get_miles_link_on_nav === 'sending' ? '送信中…' : ctaText}</StyledBtn>
            </div>
            <div className="error_state">
              <StyledSendDownloadErrTipEle send_downloadLink_phoneErr={shouldShowErrMessage}>
              {store.get_miles_links_status.get_miles_link_on_nav === 'invalidNum_error' &&  '*有効なメールアドレスをご記入ください。'}
              {(store.get_miles_links_status.get_miles_link_on_nav === 'timeout' || store.get_miles_links_status.get_miles_link_on_nav === 'server_error') && '*しばらく時間をおいてからお試しください。'}
              </StyledSendDownloadErrTipEle>
            </div>
          </>
        )
      }
    </>
  );
});


const GetMilesCTA: React.FC<{}> = observer(({
}) => {

  const [email, setEmail] = useState('');
  const inputEl = useRef<HTMLInputElement>(null);
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  const handleChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLFormElement | HTMLTextAreaElement | HTMLInputElement>) => {
      setEmail(event.target.value);
      store.update_miles_links_status('get_miles_link_on_nav', 'no_error')
      event.preventDefault();
    },
    [email],
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 8) {
        store.update_miles_links_status('get_miles_link_on_nav', 'no_error')
      }
    },
    [email]
  );

  const handleSendDownloadLink = useCallback(
    () => {
      let finalEmail = email
      if(!milesUtils.isValidEmailAddress(finalEmail)) {
        store.update_miles_links_status('get_miles_link_on_nav', 'invalidNum_error')
      } else {
        store.handleSendDownloadLink_HomePage_JP(finalEmail, "get_miles_link_on_nav");
      }
    },
    [email]
  );

  return <StyledNav>
    {milesUtils.debug_log("deviceType", deviceType)}
    <GetMilesCTAInternal 
      inputEl={inputEl}
      placeholder="メールアドレス"
      value={email}
      onChange={handleChangeEmail}
      onKeyDown={handleOnKeyDown}
      type="text"
      handleSendDownloadLink={handleSendDownloadLink}
      className=""
    />
    
  </StyledNav>
});


export default GetMilesCTA;
