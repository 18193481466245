import React, { useMemo } from "react";
import { Card_with_avatar_testimonial } from "../card_with_avatar_testimonial";
import "react-multi-carousel/lib/styles.css";
import { Styled_Our_users_love } from "./styles";
import { ReactSpringCarouselItem, useSpringCarousel } from "../spring_carousel";
import { useMediaQuery } from "@material-ui/core";

interface Props {
  our_users_love_avatar_01_img: string;
  our_users_love_avatar_02_img: string;
  our_users_love_avatar_03_img: string;
  our_users_love_avatar_04_img: string;
  our_users_love_avatar_05_img: string;
  our_users_love_avatar_06_img: string;
  our_users_love_avatar_07_img: string;
  our_users_love_avatar_08_img: string;
  our_users_love_avatar_09_img: string;
  our_users_love_avatar_10_img: string;
  our_users_love_avatar_11_img: string;
  our_users_love_avatar_12_img: string;
  carousel_arrow_left_img: string;
  carousel_arrow_right_img: string;
  star_icon_green_img: string;
  logo_apple_dark_img: string;
  logo_android_dark_img: string;
}

export const Our_users_love: React.FC<Props> = ({
  our_users_love_avatar_01_img,
  our_users_love_avatar_02_img,
  our_users_love_avatar_03_img,
  our_users_love_avatar_04_img,
  our_users_love_avatar_05_img,
  our_users_love_avatar_06_img,
  our_users_love_avatar_07_img,
  our_users_love_avatar_08_img,
  our_users_love_avatar_09_img,
  our_users_love_avatar_10_img,
  our_users_love_avatar_11_img,
  our_users_love_avatar_12_img,
  logo_android_dark_img,
  logo_apple_dark_img,
  carousel_arrow_left_img,
  carousel_arrow_right_img,
  star_icon_green_img,
}) => {
  const matchesDesktopAndLarger = useMediaQuery('(min-width: 1150px)');
  const matchesTablet = useMediaQuery('(min-width: 768px)');

  const itemsPerSlide = (
    matchesDesktopAndLarger ? 3 : matchesTablet ? 2 : 1
  );

  const items = useMemo(() => {
    const card_with_testimonial_data = [
      {
        avatarImgSrc: our_users_love_avatar_01_img,
        userName: "Julia R.",
        testimonialStr: `“I love that I get rewarded just by moving and doing things I would do anyway!”`,
        testimonialStyle: {
          width: "295px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_02_img,
        userName: "Heather G.",
        testimonialStr: `“I move. I drive. I work. I get miles! And I can give them away to charity. It’s all good!”`,
        testimonialStyle: {
          width: "254px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_03_img,
        userName: "Emily K.",
        testimonialStr: `“This app is really cool! You can earn cool prizes and give back to charity.”`,
        testimonialStyle: {
          width: "252px",
          height: "99",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_04_img,
        userName: "Gina L.",
        testimonialStr: `“I have to run around all day anyway. With Miles I at least get some sort of reward for it.”`,
        testimonialStyle: {
          width: "295px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_05_img,
        userName: "Paul D.",
        testimonialStr: `“I really enjoy this app and already got discounts on basic things I buy everyday.”`,
        testimonialStyle: {
          width: "258px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_06_img,
        userName: "Luis R.",
        testimonialStr: `“It's fun to watch miles go up each day and the rewards are cool too.”`,
        testimonialStyle: {
          width: "278px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_07_img,
        userName: "Hannah. M",
        testimonialStr: `“Who doesn't like saving money or earning free gift cards?? Love this app!”`,
        testimonialStyle: {
          width: "247px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_08_img,
        userName: "Andrew L.",
        testimonialStr: `“Cool innovative app that makes commuting fun.”`,
        testimonialStyle: {
          width: "179px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_09_img,
        userName: "Lola D.",
        testimonialStr: `“Drive and earn miles!! Plus get free stuff. You really can't go wrong.”`,
        testimonialStyle: {
          width: "242px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_10_img,
        userName: "Christina L.",
        testimonialStr: `“You get access to great deals and use the miles you’ve earned to redeem them.”`,
        testimonialStyle: {
          width: "262px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_11_img,
        userName: "Donna A.",
        testimonialStr: `“It's awesome that I can earn rewards just for traveling down the street!”`,
        testimonialStyle: {
          width: "256px",
          height: "99px",
        },
        osType: "",
      },
      {
        avatarImgSrc: our_users_love_avatar_12_img,
        userName: "Leah J.",
        testimonialStr: `“I got my mother her Mother’s Day present with Miles!”`,
        testimonialStyle: {
          width: "209px",
          height: "99px",
        },
        osType: "",
      },
    ]
    return card_with_testimonial_data.map<ReactSpringCarouselItem>((card, index) => ({
      id: index.toString(),
      renderItem: (
        <Card_with_avatar_testimonial
          key={`our_users_love_${index}`}
          className="react-multi-carousel-item"
          avatarImgSrc={card.avatarImgSrc}
          userName={card.userName}
          testimonialStr={card.testimonialStr}
          testimonialStyle={{
            width: card.testimonialStyle.width,
            height: card.testimonialStyle.height,
          }}
          osType={card.osType}
          star_icon_green_img={star_icon_green_img}
          logo_android_dark_img={logo_android_dark_img}
          logo_apple_dark_img={logo_apple_dark_img}
        />
      ),
    }));
  }, []);

  const {
    carouselFragment,
    dotsFragment,
    slideToPrevItem,
    slideToNextItem,
  } = useSpringCarousel({
    items,
    itemsPerSlide,
    withDots: true,
  });

  const buttons = <>
    <div onClick={slideToPrevItem}>
      <div className="miles_carousel_wrap_arrow_left miles_ui_large_than_768" />
      <div className="miles_carousel_wrap_arrow_left miles_ui_less_than_768" />
    </div>
    <div onClick={slideToNextItem}>
      <div className="miles_carousel_wrap_arrow_right miles_ui_large_than_768" />
      <div className="miles_carousel_wrap_arrow_right miles_ui_less_than_768" />
    </div>
  </>;

  return (
    <div style={{ width: 'inherit', backgroundColor: "#ffffff" }}>
      <Styled_Our_users_love>
        <div className="title">Users Love Miles</div>
        <div
          style={{
            position: 'relative'
          }}
        >
          <div className="carousel-container miles_carousel_wrap react-multi-carousel-list">
            {carouselFragment}
            {buttons}
          </div>
          <div className="react-multi-carousel-dot-list custom-dot-list-style">
            {dotsFragment}
          </div>
        </div>
      </Styled_Our_users_love>
    </div>
  );
};
