import styled from "styled-components";

interface NavProps {}
export const StyledNav = styled("nav")<NavProps>`
  flex-flow: row;
  justify-content: flex-start;
  display: flex;
  position: relative;
  padding: 0;
  align-items: center;
  max-width: 1440px;
  height: 75px;
  padding-left: 60px;

  a {
    display: inline-block;
    line-height: inherit;
  }

  img {
    width: auto;
    height: 30px;
  }

  ${process.env.REACT_APP_MILES_TENANT === "fca" && `
    img {
      width: 318px;
      height: auto;
    }
  `}

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "red" : ""};
    height: 80px;
    padding-left: 25px;
    max-width: 100vw;

    img {
      width: auto;
      height: 26px;
    }

    ${process.env.REACT_APP_MILES_TENANT === "fca" && `
      img {
        width: 275px;
        height: auto;
      }
    `}
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "green" : ""};
    max-width: 100vw;

    height: 80px;
    padding-left: 28px;

    img {
      width: auto;
      height: 31px;
    }

    ${process.env.REACT_APP_MILES_TENANT === "fca" && `
      img {
        width: 275px;
        height: auto;
      }
    `}
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "yellow" : ""};
    height: 80px;
    padding-left: 60px;
    max-width: 100vw;

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "green" : ""};
    max-width: 100vw;
  
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "red" : ""};
    ${process.env.REACT_APP_MILES_TENANT === "fca" && `
    padding-left: 65px;
    `}
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    background-color: ${() => process.env.NODE_ENV === "development" ? "pink" : ""};
    max-width: 1440px;
    height: 80px;
    padding-left: 88px;

    ${process.env.REACT_APP_MILES_TENANT === "fca" && `
    padding-left: 65px;
    `}
  }
`;