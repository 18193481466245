import styled from "styled-components";
import config from "../../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
} = config;

interface Props {}
export const StyledConfirmedNoteText = styled("p")<Props>`
  height: 32px;
  margin: 29px auto 0 auto;
  font-family: ${() => fontFamily_Gilroy_Medium};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2d2f3b;

  b {
    font-weight: 600;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 244px;
    height: 32px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: 0.21px;
    text-align: center;
    color: #2d2f3b;
    margin: 23px auto 0 auto;

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 244px;
    height: 32px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: 0.21px;
    text-align: center;
    color: #2d2f3b;
    margin: 20px auto 0 auto;

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }
  
`;