import React from "react";
import { StyledNav } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {
  src: string;
  alt: string;
}

export const FcaRafHeader: React.FC<Props> = ({
  src,
  alt
}) => {
  const { t } = useTranslation();

  return (
    <StyledNav>
      <a href={t('raf_head_logo_url')}>
        <img src={src} alt={alt}></img>
      </a>
    </StyledNav>
  );
};
