import config from "../../config";
const {
	// reward sharing start
	apple_store_src,
	google_play_store_src,
	// reward sharing end
  } = config

const JAPANESE_LNG = {
	"email_unsubscribe_initial_box_title": "以下項目に関してのメール配信を停止してもよろしいでしょうか？",
	"text_confirm": "配信を停止する",
	"text_cancel": "キャンセル",

	"email_unsubscribe_confirm_box_title": "配信設定が更新されました",
	"email_unsubscribe_confirm_box_subTitle_1": " に関するメールの配信停止が完了しました。謝って配信を停止した場合は、以下ボタンから配信を再開できます。",
	"email_unsubscribe_confirm_box_subTitle_2": "",
	"email_unsubscribe_confirm_box_cheer_text": "今後もMilesをよろしくお願いいたします！",
	"text_reSubscribe": "配信を再開する",
	"text_note": "",
	"text_note_icon": "",
	"text_preference": "管理できます",
	"preference_preText": "こちらからでもメールの配信設定を",
	"text_note_description": ` 配信停止の設定が反映されるまで、最大10日ほどお時間をいただく場合がございます。`,

	"text_Thank_you": "ありがとうございます！",
	"email_unsubscribe_thx_box_resubscribe_subTitle_1": " に関するメールの配信が再開されました。メールの配信設定は、アプリからいつでも変更ができます。",
	"email_unsubscribe_thx_box_resubscribe_subTitle_2": "",
	"email_unsubscribe_thx_box_resubscribeAll_subTitle": "すべてのメールの配信が再開されました。メールの配信設定は、アプリからいつでも変更ができます。",
	"email_unsubscribe_thx_box_cheer_text": "今後もMilesをよろしくお願いいたします！",

	"email_unsubscribe_thx_box_update_preference_by_group_subTitle": "メールの配信設定が更新されました。今後もMilesをよろしくお願いいたします！",
	"email_unsubscribe_thx_box_note_description": "配信停止の設定が反映されるまで、最大10日ほどお時間をいただく場合がございます。",

	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_title": "配信設定が更新されました",
	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_subTitle": " に関するメールの配信停止が完了しました。誤って配信を停止した場合は、以下ボタンから配信を再開できます。",
	
	"email_Preferences_box_title": "Eメール配信設定",
	"email_Preferences_box_subTitle": "受信を希望しないメールの項目は、チェックマークをお外しください。",
	"email_Preferences_box_btn_text": "設定を保存する",
	"text_Unsubscribe_from_all": "すべてのメールを配信停止",
	"text_Unsubscribe_from_all_description": "全てのメール配信を停止します。必要に応じて、お客様のアカウントに関連する重要なお知らせは送らせていただくこともありますので、ご了承ください。",

	// -- Reward Sharing page start --
	"rs_header_logo_url": "/jp",
	"rs_text_001": "友達が素敵な特典をシェアしました",
	"rs_text_002": "ノルクへのご登録がまだの方",
	"rs_reward_share_download_text": "ノルクをダウンロード",
	"rs_reward_share_share_text": "すべての移動でマイルがたまる",
	"rs_reward_share_redeem_rewards_text": "ためたマイルは特典と交換",

	"rs_reward_share_available_ios": "Apple Store からダウンロード",
	"rs_reward_share_available_android": "Google Play で手に入れよう",
	"rs_reward_share_available_rating_text": "3.5万件の評価",

	"rs_apple_store_icon": `https://connectiq-edsp-prod-getmiles.s3.amazonaws.com/assets/web_v2/reward_share/edsp_reward_share_ios_logo_v1/Group+560%403x.png`,
	"rs_apple_store_src": apple_store_src,
	"rs_google_play_store_icon": `https://connectiq-edsp-prod-getmiles.s3.amazonaws.com/assets/web_v2/reward_share/edsp_reward_share_android_logo_v1/Group+561%403x.png`,
	"rs_google_play_store_src": google_play_store_src,

	"rs_text_003": "リンクがご入力された電話番号に送られました",
	"rs_text_004": "(090) - 1234 - 5678",
	"rs_text_005": "+81",
	"rs_text_006": "送信中…",
	"rs_text_007": "リンクを送信",
	"rs_text_007_short": "リンクを送信",
	"rs_text_008": "有効な電話番号をご入力ください",
	"rs_text_009": "アプリをインストール",

	"rs_text_010": "すでにアカウントをお持ちですか？",
	"rs_listItemContent_1": `スマホからノルクアプリを開く`,
	"rs_listItemContent_2_01": `特典ページから`,
	"rs_listItemContent_2_02": `を検索`,
	"rs_listItemContent_3": `マイルをつかって特典と交換`,

	"rs_text_011": `様々な特典を`,
	"rs_text_012": `アプリ内から提供中`,

	// -- Reward Sharing page end --

	// -- Help page start --
	"help_text_001": "ヘルプセンター",
	"help_page_header_asset_1": `https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/help_page/edsp_help_page_header_asset_1440_v1/help_center_graphic1%403x.png`,
	"help_page_header_asset_2": `https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/help_page/edsp_help_page_header_asset_1440_v1/help_center_graphic1%403x.png`,
	"help_page_header_asset_3": `https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/help_page/edsp_help_page_header_asset_600_v2/Group+671%403x.png`,
	"help_page_header_asset_4": `https://getmiles.s3.amazonaws.com/assets/web_v2/edsp_web/help_page/edsp_help_page_header_asset_600_v2/Group+671%403x.png`,
	"help_text_002": "ユーザーサポート",
	"help_text_003": "パートナー企業サポート",

	"help_form_text_001": "お問い合わせフォーム",
	"help_form_text_002": "項目",
	"help_form_text_002_placeHolder": "一つご選択ください",
	"help_form_text_002_err": "項目を選択ください",
	"help_form_text_003": "お名前",
	"help_form_text_003_placeHolder": "お名前をご入力ください",
	"help_form_text_003_err": "有効なお名前をご入力ください",
	"help_form_text_004": "メールアドレス",
	"help_form_text_004_placeHolder": "メールアドレスをご入力ください",
	"help_form_text_004_err": "有効なメールアドレスをご入力ください",
	"help_form_text_005": "お電話番号（任意）",
	"help_form_text_005_err": "有効なお電話番号をご入力ください",
	"help_form_text_006": "お問い合わせフォーム",
	"help_form_text_006_placeHolder": "お問い合わせ内容をご入力ください",
	"help_form_text_006_err": "お問い合わせ内容をご入力ください",
	"help_form_text_007": "お問い合わせ内容をご入力ください。 担当者より近日中に連絡をさせていただきます",
	"help_form_text_008": "送信",
	"help_form_text_009": "お電話番号をご入力ください",
	"help_form_text_010": "エラーが発生しました。再度お試しください",
	"help_form_text_011": `この度は、ノルクへのお問い合わせありがとうございました。
	ブランドパートナー様のご希望やPR関連のお問い合わせについては、改めて担当者よりご連絡をさせていただきますので、今しばらくお待ちください。
	個人ユーザー様からのお問い合わせは、アプリ内から受け付けております。こちらのメールにはご返信できませんのでご了承くださいませ。`,
	// SubjectValuesByUserType start
	"help_form_text_012": "アカウント設定について",
	"help_form_text_013": "インストール・初期設定について",
	"help_form_text_014": "データプライバシー",
	"help_form_text_015": "その他",
	"help_form_text_016": "特典について",
	"help_form_text_017": "移動・マイルについて",
	"help_form_text_018": "その他",

	"help_form_text_019": "ブランドパートナーへのご参画希望",
	"help_form_text_020": "代理店様からのお問い合わせ",
	"help_form_text_021": "公共交通機関のお客様からのお問い合わせ",
	"help_form_text_022": "PR関連のお問い合わせ",
	"help_form_text_023": "その他",
	// SubjectValuesByUserType end
	"help_form_text_024": "一つご選択ください",
	
	// -- Help page end --

	// -- account delete form start --

	"account_delete_form_text_header": "アカウント削除リクエスト",
	"account_delete_form_text_001": "名前",
	"account_delete_form_text_001_placeHolder": "名前",
	"account_delete_form_text_001_err": "有効なお名前をご入力ください",
	"account_delete_form_text_002": "ご登録メールアドレス",
	"account_delete_form_text_002_placeHolder": "ご登録メールアドレス",
	"account_delete_form_text_002_err": "有効なメールアドレスをご入力ください",
	"account_delete_form_text_003": "電話番号（任意）",
	"account_delete_form_text_003_placeHolder": "（例）090-1234-5678",
	"account_delete_form_text_003_err": "有効なお電話番号をご入力ください",
	"account_delete_form_text_004": "アカウント削除理由（任意）",
	"account_delete_form_text_004_placeHolder": "アカウント削除理由をお聞かせください。",
	"account_delete_form_text_004_err": "お問い合わせ内容をご入力ください",
	"account_delete_form_text_005": "アカウント削除リクエストの詳細をご入力ください。弊社サポートスタッフが順次対応させていただきます。",
	"account_delete_form_text_006": "送信",
	"account_delete_form_text_007": "エラーが発生しました。再度お試しください",
	"account_delete_form_text_008": "アカウント削除リクエストを承りました。弊社サポートスタッフが順次ご連絡させていただきます。",

	// -- account delete form end --
}

export default JAPANESE_LNG;