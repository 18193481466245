import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../utils/config";
import { useLanguageOnTenant } from "../../../utils/milesUtils";
import { Styled_body_wrap } from "./styles";
import CardUnit01 from "../card_unit_01/index";
import CardUnit02 from "../card_unit_02/index";

const {
  contact_page_assets: assets,
} = config;

interface Props {
  onClickMemberSupport: () => void,
  onClickPartnerSupport: () => void,
}

const Component: React.FC<Props> = observer((props) => {
  let languageOnTenant = useLanguageOnTenant();

  let shouldShowMemberSupport = true;
  switch (languageOnTenant) {
    case 'jp_edsp':
      shouldShowMemberSupport = false;
      break;
    case 'jp_mjkk':
      shouldShowMemberSupport = false;
      break;
    default:
      shouldShowMemberSupport = true;
  }

  return (
    <>
      <Styled_body_wrap>
        <div className="root">
          <div className="container">
            {shouldShowMemberSupport && <CardUnit01 onClick={props.onClickMemberSupport} />}
            <CardUnit02 onClick={props.onClickPartnerSupport} />
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
