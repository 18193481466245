import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { store } from "../../Store";
import { StyledCategoryGroup } from "./styles";
import config from "../../utils/config";

import milesUtils from "../../utils/milesUtils";
interface Props {
  bgColor: string;
  dataSource?: {
    categories: {
      id: number | any,
      title: string,
      description: string,
      is_subscribed: boolean,
    }[]
  }[] | null;
  subscription_category_checkBox_status: Map<number, boolean>;
}

interface categoriesProp {
  category: {
    id: number,
    title: string,
    description: string,
    is_subscribed: boolean,
  }[]
}

const GreenCheckbox = withStyles({
  root: {
    color: "#9b9b9b",
    '&$checked': {
      color: milesUtils.get_checkBox_checked_color(),
    },
  },
  checked: {},
})((props: any | CheckboxProps) => <Checkbox color="default" {...props} />);

const GlobalCss = withStyles({
  "@global": {
    ".mi-Unsubscribe-Category-Group": {
      marginBottom: "27px",
    },
    ".mi-Unsubscribe-Category-Group .MuiTypography-body1": {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.4px",
      color: "#2d2f3b",
    },
  },
})(() => null);

let firstRender = true;

const {
  email_preference_group_background,
} = config;

export const UnsubscribeCategoryGroup: React.FC<Props> = observer(({
  bgColor,
  dataSource,
}) => {
  if (firstRender) {}
  useEffect(() => {
    firstRender = false;
}, []);

  const handleChangeMiCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let updated_check_status = store.subscription_category_checkBox_status
      let new_subscription_preferences = store.subscription_preferences
      new_subscription_preferences.groups.map((item, index) => {
        item.categories.map((categoryItem, categoryIndex) => {
          if (categoryItem.id === parseInt(event.target.name)) {
            categoryItem.is_subscribed = event.target.checked
          }
        })
      })
      store.update_subscription_preferences(new_subscription_preferences)
      updated_check_status.set(parseInt(event.target.name), event.target.checked)
      store.get_subscribe_status_by_category_id(store.subscription_preferences.groups)
      if (Array.from(store.subscription_category_checkBox_status.values()).indexOf(true) > -1) {
        store.update_is_unSubscribed_from_all_status(false)
      } else { store.update_is_unSubscribed_from_all_status(true) }
    },
    [store.subscription_category_checkBox_status, store.is_unSubscribed_from_all_status]
  );

  let currentCategoryOuterEle: any = [] // TBC
  dataSource && dataSource.map((item, index) => {
      currentCategoryOuterEle.push(
        <StyledCategoryGroup bgColor={index <= 2 ? email_preference_group_background[index] : "transparent"} key={`mi-Unsubscribe-Category-Wrap_${index}`}>
            <GlobalCss />
            {
              item.categories.map((category, categoryIndex) => {
                return (
                  <div key={`mi-Unsubscribe-Category-Group_${categoryIndex}`}>
                    <FormGroup row className="mi-Unsubscribe-Category-Group">
                      <FormControlLabel
                        control={<GreenCheckbox
                          onChange={handleChangeMiCheckBox}
                          checked={category.is_subscribed}
                          name={category.id}
                        />}
                        label={category.title}
                      />
                      <p className="mi-checkBox-description">{category.description}</p>
                    </FormGroup>
                  </div>
                )
              })
            }
        </StyledCategoryGroup>
      )
    })

  return (
    <>
      {currentCategoryOuterEle}
    </>
  );
});
