import styled from "styled-components";

interface Props {
  height: string;
  bg: string;
}
export const StyledDividerLine = styled("div")<Props>`
  width: ${(props) => props.bg === '#1ba756' ? '100px' : '540px'};
  height: ${(props) => props.height};
  margin: 15px auto;
  background-color: ${(props) => props.bg};

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: ${(props) => props.bg === '#1ba756' ? '100px' : '95%'};
    margin-top: ${(props) => props.bg === '#1ba756' ? '15px' : '24px'};
    margin-bottom: ${(props) => props.bg === '#1ba756' ? '20px' : '24px'};

  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: ${(props) => props.bg === '#1ba756' ? '100px' : '95%'};
    margin-top: ${(props) => props.bg === '#1ba756' ? '15px' : '24px'};
    margin-bottom: ${(props) => props.bg === '#1ba756' ? '20px' : '24px'};
  }
`;