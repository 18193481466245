import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import Lottie, { LottieRefCurrentProps, InteractivityProps } from "lottie-react";
import { observer } from "mobx-react";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";
import { MILES_ANIMATIONS } from "../../utils/milesConstant";
import TopBanner from "../pressPage/top_banner/index";
import TopStoriesTitle from "../pressPage/top_stories_title/index";
import TopHighlightCarousel_v1 from "../pressPage/top_highlight_carousel_v1/index";
import TopStories from "../pressPage/top_stories/index";
import DiscoverTitle from "../pressPage/discover_title/index";
import DiscoverArticles from "../pressPage/discover_articles/index";
import {Props as CardUnitProps} from "./top_stories/card_unit/index";
import { Styled_body_wrap } from "../pressPage/styles";
import {Pre_footer_hero} from "../../components/v2/miles_ui_box_pre_footer_hero/index"
import styled from "styled-components";


const PreFooterHero = styled(Pre_footer_hero)`
.title {
  margin-top: 0px;
}
;`


const {
  apple_store_icon_img,
  google_play_icon_img,
  star_icon_img,
  icon_star_half_img,
  apple_store_src,
  google_play_store_src,
} = config

let firstRender = true;

const {} = config;

const {
} = MILES_ANIMATIONS;

const MilesWeb_PressPage: React.FC<{}> = observer(() => {

  if (firstRender) {
    (window as any).history.scrollRestoration = "manual";
    milesUtils.debug_log("component - MilesWeb_PressPage -- firstRender");
  }

  useEffect(() => {
    milesUtils.debug_log("component - MilesWeb_PressPage -- did mount");

    firstRender = false;
  }, []);

  useEffect(() => {}, []);

  return (
    <Styled_body_wrap>
      <TopBanner/>
      <TopStoriesTitle/>
      <TopHighlightCarousel_v1 />
      <TopStories/>
      <DiscoverTitle/>
      <DiscoverArticles className={"discoverArticlesWrapper"}/>
      <PreFooterHero
        apple_store_icon_img={apple_store_icon_img}
        google_play_icon_img={google_play_icon_img}
        star_icon_img={star_icon_img}
        icon_star_half_img={icon_star_half_img}
        apple_store_src={apple_store_src}
        google_play_store_src={google_play_store_src}
        className="miles_ui_pre_footer_commonSize"
      />
    </Styled_body_wrap>
  );
});

export default MilesWeb_PressPage;
