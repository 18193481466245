import styled from "styled-components";

interface Props {}
export const StyledEle = styled("div")<Props>`
  background-color: #FAE8BF;
  width: 100vw;
  padding: 24px;
  min-width: 360px;

  .top_banner_notice_content {
    max-width: 1440px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;

    img {
      display: inline-block;
      width: 38px;
      height: 35.4px;
      margin-right: 24px;
    }

    .top_banner_notice_text {
      width: 1332.3px;
      flex-grow: 0;
      font-family: Noto Sans JP;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.7px;
      text-align: left;
      color: #2d2f3b;

      a {
        text-decoration: underline;
        color: #178e3d;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    padding: 16px;

    .top_banner_notice_content {
      img {
        display: none;
      }

      .top_banner_notice_text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.7px;
      }
      
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    padding: 16px;

    .top_banner_notice_content {
      img {
        display: none;
      }

      .top_banner_notice_text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.7px;
      }
      
    }

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;