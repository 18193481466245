import React from "react";
import { observer } from "mobx-react";
import { StyledBtn } from "./styles";
import { store } from "../../Store";
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
  send_downloadLink_shouldDisableSendBtn: boolean;
}

export const FcaRafSendDownloadLinkBtn: React.FC<Props> = observer(({
  onClick,
  send_downloadLink_shouldDisableSendBtn
}) => {
  const { t } = useTranslation();
  return (
    <StyledBtn
      className={`miles_ui_btn_primary ${process.env.REACT_APP_MILES_TENANT}`}
      onClick={onClick}
      style={{
        cursor: send_downloadLink_shouldDisableSendBtn ? 'unset' : 'pointer'
      }}
      bgColor={store.sendDownLoadLinkStatus === 'error' ? '#9b9ba5' : '#10af42'}
      disabled={store.isSendFcaLink === 'sending' || send_downloadLink_shouldDisableSendBtn ? true : false}
      sendDownLoadLinkStatus={store.isSendFcaLink}
    >{store.isSendFcaLink === 'sending' ? t('raf_text_05') : t('raf_text_04')}</StyledBtn>
  );
})

