import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

interface TitleProps {
}
export const StyledTitleSection = styled("div")<TitleProps>`
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .fca-raf-title-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 57px;
    width: 650px;

    .fca-raf-title-text-1 {
        width: 576px;
        min-height: 160px;
        font-family: ${fontFamily_Gilroy_SemiBold};
        font-size: 60px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #3b3b3b;
    }

    .fca-raf-title-text-2 {
        width: max-content;
        height: 96px;
        font-family: ${fontFamily_Gilroy_Medium};
        font-size: 40px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1.67px;
        color: #343434;
        margin-top: 79px;
    }
  }

  .raf_hero_v2_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 470px;
    margin-right: 127px;
    img.raf_hero_img_v2 {
        width: 470px;
        height: auto;
    }
  }

  .screen_1440 {
    display: block;
  }

  .screen_1000 {
    display: none;
  }

  .screen_600 {
    display: none;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    flex-direction: column;
    width: min-content;
    align-items: center;
    padding-top: 9.5px;

    .fca-raf-title-text-wrap {
      width: 300px;
      margin-left: 0;
      .fca-raf-title-text-1 {
        font-size: 30px;
        font-weight: 600;
        width: 300px;
        text-align: left;
        height: auto;
        min-height: 0;
      }
      .fca-raf-title-text-2 {
        display: none;
      }
    }
    .raf_hero_v2_wrap {
      width: 300px;
      margin-top: 24px;
      margin-right: auto;
      img.raf_hero_img_v2 {
          width: 300px;
          margin-top: 0;
      }
    }

    .screen_1440 {
      display: none;
    }
  
    .screen_1000 {
      display: none;
    }
  
    .screen_600 {
      display: block;
    }
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: column;
    width: min-content;
    align-items: center;
    padding-top: 9.5px;

    .fca-raf-title-text-wrap {
      width: 300px;
      margin-left: 0;
      .fca-raf-title-text-1 {
        font-size: 30px;
        font-weight: 600;
        width: 300px;
        text-align: left;
        height: auto;
        min-height: 0;
      }
      .fca-raf-title-text-2 {
        display: none;
      }
    }
    .raf_hero_v2_wrap {
      width: 300px;
      margin-top: 24px;
      margin-right: auto;
      img.raf_hero_img_v2 {
          width: 300px;
          margin-top: 0;
      }
    }

    .screen_1440 {
      display: none;
    }
  
    .screen_1000 {
      display: none;
    }
  
    .screen_600 {
      display: block;
    }
  }

  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .fca-raf-title-text-wrap {
      width: 251px;
      .fca-raf-title-text-1 {
        font-size: 25px;
        font-weight: 600;
        width: 255px;
        text-align: left;
        height: auto;
      }
      .fca-raf-title-text-2 {
        display: none;
      }
    }

    .screen_1440 {
      display: none;
    }
  
    .screen_1000 {
      display: none;
    }
  
    .screen_600 {
      display: block;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    margin-top: 5px;
    
    .fca-raf-title-text-wrap {
      width: 251px;
      .fca-raf-title-text-1 {
        font-size: 25px;
        font-weight: 600;
        width: 255px;
        text-align: left;
        height: auto;
      }
      .fca-raf-title-text-2 {
        display: none;
      }
      
    }

    .raf_hero_v2_wrap {
      width: 280px;
      margin-top: 24px;
      margin-right: auto;
      img.raf_hero_img_v2 {
          width: 280px;
          margin-top: 0;
      }
    }

    .screen_1440 {
      display: none;
    }
  
    .screen_1000 {
      display: none;
    }
  
    .screen_600 {
      display: block;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    margin-top: 15px !important;
  }

  /* iPhone 11 Pro Max----------- */
  @media
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 458dpi),
  only screen and (min-resolution: 3dppx) { 
    margin-top: 50px;
  }

  /* iPhone 12/ 12 Pro ----------- */
  @media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    margin-top: 30px;
  }

  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    flex-direction: column;
    width: min-content;
    align-items: center;
    padding-top: 15px;

    .fca-raf-title-text-wrap {
      width: 500px;
      margin-left: 0;

      .fca-raf-title-text-1 {
        font-size: 50px !important;
        width: 500px;
      }
      .fca-raf-title-text-2 {
        display: none;
      }
    }
    .raf_hero_v2_wrap {
      width: 592px;
      margin-right: auto;
      img.raf_hero_img_v2 {
          width: 592px;
          margin-top: 34px;
      }
    }

    .screen_1440 {
      display: none;
    }
  
    .screen_1000 {
      display: block;
    }
  
    .screen_600 {
      display: none;
    }
  }

  @media only screen and (min-width : 1151px) and (max-width : 1279px) {
    flex-direction: column;
    width: min-content;
    align-items: center;
    padding-top: 15px;

    .fca-raf-title-text-wrap {
      width: 500px;
      margin-left: 0;

      .fca-raf-title-text-1 {
        font-size: 50px !important;
        width: 500px;
      }
      .fca-raf-title-text-2 {
        display: none;
      }
    }
    .raf_hero_v2_wrap {
      width: 592px;
      margin-right: auto;
      img.raf_hero_img_v2 {
          width: 592px;
          margin-top: 34px;
      }
    }

    .screen_1440 {
      display: none;
    }
  
    .screen_1000 {
      display: block;
    }
  
    .screen_600 {
      display: none;
    }
  }

  @media only screen and (min-width : 1280px) and (max-width : 1439px) {
    .fca-raf-title-text-wrap {
      width: 576px;

      .fca-raf-title-text-1 {
          width: 576px;
          min-height: 160px;
          font-size: 60px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 1px;
          color: #3b3b3b;
      }
    }

  }
`;