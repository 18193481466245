import React from "react";
import { observer } from "mobx-react";
import { StyledBtn } from "./styles";
import { store } from "../../Store";
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
  send_downloadLink_shouldDisableSendBtn: boolean;
}

export const SendDownloadLinkBtn: React.FC<Props> = observer(({
  onClick,
  send_downloadLink_shouldDisableSendBtn
}) => {
  const { t } = useTranslation();
  return (
    <StyledBtn
      className={`miles_ui_btn_primary ${process.env.REACT_APP_MILES_TENANT}`}
      onClick={onClick}
      style={{
        cursor: send_downloadLink_shouldDisableSendBtn ? 'unset' : 'pointer'
      }}
      bgColor={store.sendDownLoadLinkStatus === 'error' ? '#9b9ba5' : '#10af42'}
      disabled={store.sendDownLoadLinkStatus === 'sending' || send_downloadLink_shouldDisableSendBtn ? true : false}
      sendDownLoadLinkStatus={store.sendDownLoadLinkStatus}
    // >{store.sendDownLoadLinkStatus === 'sending' ? t('rs_text_006') : t('rs_text_007')}</StyledBtn>
    >{store.sendDownLoadLinkStatus === 'sending' ? t('rs_text_006') : (
      <>
        <p className="screen_larger_than_768">{t('rs_text_007')}</p>
        <p className="screen_between_361_and_768">{t('rs_text_007_short')}</p>
      </>
    )}</StyledBtn>
  );
})

