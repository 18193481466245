import styled from "styled-components";

interface FooterProps {}

export const StyledOrTextBox = styled("p")<FooterProps>`
    height: 45px;
    line-height: 45px;
    vertical-align: middle;
    font-family: 'Avenir Next';
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a2a2a2;
    margin-left: -60px;
    margin-bottom: 0;

    /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }
`;
