import React, { useState, useCallback, useEffect, useRef, ReactNode } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import CtaBannerDownloadPressKit from "../../top_banner/cta_banner_download_press_kit/index";
import { Styled_body_wrap } from "./styles";

const {} = config;


export interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
}

const Component: React.FC<Props> = observer((props) => {
  console.log(props.className);
  const buttonDisabled = props.disabled === true;
  if (buttonDisabled) {
    return null;
  }
  return (
    <>
      <Styled_body_wrap>
      <div className={`ciq-root ${props.className}`}>
        <div className="ciq-container">
          <button className="cta miles_ui_btn_primary" onClick={props.onClick} disabled={buttonDisabled}>
            Show more
          </button>
        </div>
      </div>
      </Styled_body_wrap>
    </>
  );
});

export default Component;
