import { MILES_WEB_S3_BASE_URL_V2 } from './base';

export const cities_page_assets = {
  top_box_img_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/top_box_img_1440_v3/Frame+1094%402x.png`,
  top_box_img_1280: `${MILES_WEB_S3_BASE_URL_V2}cities_page/top_box_img_1280_v3/Frame+1094%402x.png`,
  top_box_img_1024: `${MILES_WEB_S3_BASE_URL_V2}cities_page/top_box_img_1024_v3/Asset+1auto_cities+2%402x.png`,
  top_box_img_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/top_box_img_600_v3/Website_Landing_04_12_2021_size_1440+Copy+17+2%402x.png`,
  top_box_img_360: `${MILES_WEB_S3_BASE_URL_V2}cities_page/top_box_img_360_v3/Website_Landing_04_12_2021_size_1440+Copy+17+2%402x.png`,

  solutions_can_help_01_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/solutions_can_help_section/solutions_can_help_section_01_1440_v1/Group+793%402x.png`,
  solutions_can_help_02_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/solutions_can_help_section/solutions_can_help_section_02_1440_v1/2021_09_24_4+infographics-06+1%402x.png`,
  solutions_can_help_03_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/solutions_can_help_section/solutions_can_help_section_03_1440_v1/2021_09_24_4+infographics-07%402x.png`,
  solutions_can_help_01_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/solutions_can_help_section/solutions_can_help_section_01_600_v1/Group+798%402x.png`,
  solutions_can_help_02_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/solutions_can_help_section/solutions_can_help_section_02_600_v1/2021_09_24_4+infographics-06+1%402x.png`,
  solutions_can_help_03_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/solutions_can_help_section/solutions_can_help_section_03_600_v1/2021_09_24_4+infographics-07%402x.png`,

  reward_community_01_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/reward_community/reward_community_01_1440_v1/2021_09_24_4+infographics-05%402x.png`,
  reward_community_01_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/reward_community/reward_community_01_600_v1/2021_09_24_4+infographics-05%402x.png`,

  our_partners_say_01_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_01_1440_v1/Group+739%402x.png`,
  our_partners_say_01_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_01_1280_v1/Group+764%402x.png`,
  our_partners_say_01_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_01_1024_v1/Group+17%402x.png`,
  our_partners_say_01_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_01_600_v2/Group+746%402x.png`,
  our_partners_say_01_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_01_360_v1/Group+746%402x.png`,

  our_partners_say_02_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_02_1440_v1/Group+736%402x.png`,
  our_partners_say_02_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_02_1280_v1/Group+736%402x.png`,
  our_partners_say_02_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_02_1024_v1/Group+17%402x.png`,
  our_partners_say_02_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_02_600_v1/Group+42%402x.png`,
  our_partners_say_02_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_02_360_v1/Group+747%402x.png`,

  our_partners_say_03_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_03_1440_v1/Group+737%402x.png`,
  our_partners_say_03_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_03_1280_v1/Group+788%402x.png`,
  our_partners_say_03_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_03_1024_v1/Group+17%402x.png`,
  our_partners_say_03_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_03_600_v1/Group+42%402x.png`,
  our_partners_say_03_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_03_360_v1/Group+748%402x.png`,

  our_partners_say_04_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_04_1440_v1/Group+738%402x.png`,
  our_partners_say_04_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_04_1280_v1/Group+738%402x.png`,
  our_partners_say_04_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_04_1024_v1/Group+17%402x.png`,
  our_partners_say_04_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_04_600_v1/Group+42%402x.png`,
  our_partners_say_04_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_04_360_v1/Group+749%402x.png`,

  our_partners_say_05_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_05_1440_v1/Group+738%402x.png`,
  our_partners_say_05_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_05_1280_v1/Group+738%402x.png`,
  our_partners_say_05_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_05_1024_v1/Group+17%402x.png`,
  our_partners_say_05_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_05_600_v1/Group+42%402x.png`,
  our_partners_say_05_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_05_360_v1/Group+42%402x.png`,

  our_partners_say_06_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_06_1440_v1/Group+738%402x.png`,
  our_partners_say_06_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_06_1280_v1/Group+738%402x.png`,
  our_partners_say_06_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_06_1024_v1/Group+17%402x.png`,
  our_partners_say_06_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_06_600_v1/Group+42%402x.png`,
  our_partners_say_06_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_06_360_v1/Group+42%402x.png`,

  our_partners_say_07_1440_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_07_1440_v1/Group+768%402x.png`,
  our_partners_say_07_1280_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_07_1280_v1/Group+768%402x.png`,
  our_partners_say_07_1024_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_07_1024_v1/Group+17%402x.png`,
  our_partners_say_07_600_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_07_600_v1/Group+42%402x.png`,
  our_partners_say_07_360_v1: `${MILES_WEB_S3_BASE_URL_V2}cities_page/section_our_partners_say/our_partners_say_07_360_v1/Group+42%402x.png`,


  our_partnership_box_01_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_01_1440/Group+773%402x.png`,
  our_partnership_box_01_1280: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_01_1280/Group+773%402x.png`,
  our_partnership_box_01_1000: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_01_1000/Group+773%402x.png`,
  our_partnership_box_01_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_01_600/Group+773%402x.png`,
  our_partnership_box_01_360: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_01_360/Group+773%402x.png`,
  our_partnership_box_02_1440: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_02_1440/Group+772%402x.png`,
  our_partnership_box_02_1280: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_02_1280/Group+772%402x.png`,
  our_partnership_box_02_1000: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_02_1000/Group+772%402x.png`,
  our_partnership_box_02_600: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_02_600/Group+772%402x.png`,
  our_partnership_box_02_360: `${MILES_WEB_S3_BASE_URL_V2}cities_page/our_partnership_box_02_360/Group+772%402x.png`,

  discover_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/discover_01/asset-profile-insights%402x.png`,
  discover_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/discover_02/asset-beh-insights%402x.png`,
  discover_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/discover_03/loc-insights-asset%402x.png`,
  partner_carousel_01_img_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01_img_01/placements%402x.png`,
  partner_carousel_01_img_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01_img_02/asset-content-bran%402x.png`,
  partner_carousel_01_img_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01_img_03/multiple-offers%402x.png`,
  partner_carousel_02_img_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_img_01/group-76%402x.png`,
  partner_carousel_02_img_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_img_02/group%402x.png`,
  partner_carousel_02_img_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_img_03/lower%402x.png`,
  partner_carousel_02_logo_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_logo_01/logo-garmin%402x.png`,
  partner_carousel_02_logo_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_logo_02/logo-winc%402x.png`,
  partner_carousel_02_logo_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_logo_03/logo-mint-mongoose%402x.png`,
  partner_carousel_02_asset_01_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_asset_01_01/icon-box%402x.png`,
  partner_carousel_02_asset_01_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_asset_01_02/icons-rewards-category-retail%402x.png`,
  partner_carousel_02_asset_02_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_asset_02_01/icon-box%402x.png`,
  partner_carousel_02_asset_02_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_asset_02_02/category-food-and-drink%402x.png`,
  partner_carousel_02_asset_03_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_asset_03_01/icon-box%402x.png`,
  partner_carousel_02_asset_03_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_02_asset_03_02/icon-gifts%402x.png`,
  partner_carousel_03_avatar_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_03_avatar_01/bitmap%402x.png`,
  partner_carousel_03_avatar_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_03_avatar_02/bitmap%402x.png`,
  partner_carousel_03_avatar_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_03_avatar_03/bitmap%402x.png`,
  partner_carousel_03_logo_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_03_logo_01/bitmap%402x.png`,
  partner_carousel_03_logo_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_03_logo_02/bitmap%402x.png`,
  partner_carousel_03_logo_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_03_logo_03/bitmap%402x.png`,
  partner_page_form_sent_success_asset_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_page_form_sent_success_asset/resub%402x.png`,

  drive_success_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/drive_success_01/increase-sales-asset%402x.png`,
  drive_success_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/drive_success_02/new-customers-asset%402x.png`,
  drive_success_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/drive_success_03/lower-cac-asset%402x.png`,
  drive_success_04_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/drive_success_04/pysical-stores-asset%402x.png`,
  drive_success_05_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/drive_success_05/pay-perf-asset%402x.png`,
  drive_success_06_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/drive_success_06/easy-start-asset%402x.png`,
  asset_on_partner_we_work_with_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/asset_on_partner_we_work_with/group-45%402x.png`,
  we_are_growing_fast_01_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/we_are_growing_fast_01/group-4%402x.png`,
  we_are_growing_fast_02_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/we_are_growing_fast_02/group-17%402x.png`,
  we_are_growing_fast_03_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/we_are_growing_fast_03/group-54%402x.png`,
  we_are_growing_fast_04_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/we_are_growing_fast_04/group-75%402x.png`,
  partner_page_esc_icon_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_page_esc_icon/group-17%402x.png`,
  carousel_01_email_icon_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_email_icon/icon-email%402x.png`,
  carousel_01_push_notification_icon_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_push_notification_icon/icon-push%402x.png`,
  carousel_01_blog_icon_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_blog_icon/icon-blog%402x.png`,
  carousel_01_01_img_on_screen_1000_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_01_img_on_screen_1000/group-7%402x.png`,
  carousel_01_01_img_on_screen_600_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_01_img_on_screen_600/group-7%402x.png`,
  carousel_01_02_img_on_screen_1000_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_02_img_on_screen_1000/group%402x.png`,

  carousel_01_a_1440_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_a_1440_v3/group-4%402x.png`,
  carousel_01_a_1280_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_a_1280_v3/group-5%402x.png`,
  carousel_01_a_1000_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_a_1000_v3/group-5%402x.png`,
  carousel_01_a_600_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_a_600_v3/group%402x.png`,
  carousel_01_b_1440_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_b_1440_v4/group-4%402x.png`,
  carousel_01_b_1280_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_b_1280_v4/group-4%402x.png`,
  carousel_01_b_1000_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_b_1000_v4/group-5%402x.png`,
  carousel_01_b_600_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_b_600_v4/group-5%402x.png`,
  carousel_01_c_1440_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_c_1440_v2/group-8%402x.png`,
  carousel_01_c_1280_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_c_1280_v2/group-8%402x.png`,
  carousel_01_c_1000_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_c_1000_v2/group-8%402x.png`,
  carousel_01_c_600_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/carousel_01_c_600_v2/group-8%402x.png`,
  carousel_01_d_1440_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_d_1440_v3/group-9%402x.png`,
  carousel_01_d_1280_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_d_1280_v3/group-9%402x.png`,
  carousel_01_d_1000_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_d_1000_v3/group-9%402x.png`,
  carousel_01_d_600_v2_img: `${MILES_WEB_S3_BASE_URL_V2}partner_page/partner_carousel_01/slide_d_600_v3/group-9%402x.png`,
};
