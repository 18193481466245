import styled from "styled-components";

interface Growing_fast_Props {}
export const Styled_Growing_fast = styled("div")<Growing_fast_Props>`
  margin: 0;
  padding: 0;
  min-height: 1000px;
  height: 1000px;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  background-color: #f8fffb;
  
  .title {
    height: 56px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 110px;
  }

  .number_box_wrap {
    display: flex;
    flex-direction: column;
    justify-content: start;
    box-sizing: border-box;
    margin-top: 42px;
    padding: 0;

    .number_box_wrap_sub {
      display: flex;
      flex-direction: row;
      justify-content: start;
      box-sizing: border-box;

      img {
        width: 340px;
        height: 340px;
        display: inline-block;
      }

      .first {
        margin-right: 70px;
      }

      .number_box02_img {
        margin-top: 28px;
      }
    }
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-height: 1414px;
    height: 1414px;
    min-width: 360px;
    
    .title {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 50px;
    }

    .number_box_wrap {
      display: flex;
      flex-direction: column;
      justify-content: start;
      box-sizing: border-box;
      margin-top: 50px;
      padding: 0;

      .number_box_wrap_sub {
        flex-direction: column;

        img {
          width: 256px;
          height: 258px;
          margin: 0;
          margin-bottom: 50px;
        }

        .first {
          margin: 0;
          margin-bottom: 50px;
        }

        .number_box02_img {
          margin: 0;
          margin-bottom: 50px;
        }
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    min-height: 1414px;
    height: 1414px;
    
    .title {
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 56px;
    }

    .number_box_wrap {
      display: flex;
      flex-direction: column;
      justify-content: start;
      box-sizing: border-box;
      margin-top: 44px;
      padding: 0;

      .number_box_wrap_sub {
        flex-direction: column;

        img {
          width: 256px;
          height: 258px;
          margin: 0;
          margin-bottom: 50px;
        }

        .first {
          margin: 0;
          margin-bottom: 50px;
        }

        .number_box02_img {
          margin: 0;
          margin-bottom: 50px;
        }
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 1024px;
    height: 1024px;
    
    .title {
      margin-top: 173px;
      height: 56px;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
    }

    .number_box_wrap {
      margin-top: 76px;

      .number_box_wrap_sub {
        img {
          width: 256px;
          height: 258px;
        }

        .first {
          margin-right: 33px;
        }

        .number_box02_img {
          margin-top: 29px;
        }
      }
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    min-height: 1000px;
    height: 1000px;
    
    .title {
      margin-top: 107px;
    }

    .number_box_wrap {
      margin-top: 42px;

      .number_box_wrap_sub {
        img {}

        .first {
          margin-right: 70px;
        }

        .number_box02_img {
          margin-top: 32px;
        }
      }
    }
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;