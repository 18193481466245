import React, { useState, useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  MainWrap,
  Styled_password_reset_success,
  Styled_password_reset_err,
  Styled_password_reset_expired,
} from "./styles";
import { store } from "../../Store";
import config from "../../utils/config";
import milesUtils from "../../utils/milesUtils";

const GlobalCss = withStyles({
  "@global": {
    ".reset_password_input_box .MuiInputBase-input": {
      
      "&[type='text']": {
        fontFamily: "Noto Sans JP",
        fontSize: "20px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "50px",
        letterSpacing: "1px",
        height: "50px",
      },

      "&[type='password']": {
        fontFamily: "Noto Sans JP",
        fontSize: "30px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "50px",
        letterSpacing: "1px",
        height: "50px",
      },

      "&::-webkit-input-placeholder": {
        width: "auto",
        height: "34px",
        margin: 0,
        fontFamily: "Noto Sans JP",
        fontSize: "20px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "1px",
        color: "#afafb5",
      },
      // --- firefox 19+ ---
      "&::-moz-placeholder": {
        width: "auto",
        height: "34px",
        margin: 0,
        fontFamily: "Noto Sans JP",
        fontSize: "20px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "1px",
        color: "#afafb5",
      },
      // --- ie ----
      "&::-ms-input-placeholder": {
        width: "auto",
        height: "34px",
        margin: 0,
        fontFamily: "Noto Sans JP",
        fontSize: "20px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "1px",
        color: "#afafb5",
      },
    },
    ".reset_password_input_box .MuiInputBase-root": {
      width: "325px",
      "@media only screen and (max-width : 360px)": {
        width: "280px",

      },
      "@media only screen and (min-width : 1441px)": {
        width: "414px",
      }
    },
    ".reset_password_input_box .MuiIconButton-root": {
      width: "43px",
      "&:hover": {
        background: "none"
      }
    },
    ".reset_password_input_box .MuiIconButton-label": {
      width: "43px",
      height: "21px",
      margin: 0,
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.63,
      letterSpacing: "0.7px",
      textAlign: "right",
      color: "#10af42",
    },
    ".reset_password_input_box .MuiInput-underline:after": {
      borderBottom: "solid 1px #9b9ba5",
    },
    ".reset_password_input_box .MuiInput-underline:before": {
      borderBottom: "solid 1px #9b9ba5",
    },
    ".reset_password_input_box .MuiInput-underline:hover:not(.Mui-disabled):after": {
      borderBottom: "solid 1px #9b9ba5",
    },
    ".reset_password_input_box .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "solid 1px #9b9ba5",
    },
  },
})(() => null);

const {
  password_reset_success_img,
  password_reset_err_img,
  password_reset_expired_img,
  password_reset_help_url,
} = config;

let firstRender = true;

const Password_reset_jp: React.FC<{}> = observer(() => {
  if (firstRender) {
    console.log("store.email_param", store.email_param)
    store.fetchPasswordResetInfo();
  }

  useEffect(() => {
    firstRender = false;
  }, []);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    confirm_password: "",
    show_confirm_Password: false,
    err: {
      password: false,
      confirm_password: false,
    }
  });

  const handleChange = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, show_confirm_Password: !values.show_confirm_Password });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  const handleSubmitPasswordReset = () => {
    let isCorrectPassword = milesUtils.checkPasswordValue(values.password);
    let isCorrectConfirmPassword = values.password === values.confirm_password;
    setValues({
      ...values,
      err: {
        ...values.err,
        password: !isCorrectPassword,
        confirm_password: !isCorrectConfirmPassword,
      }
    });

    if (isCorrectPassword && isCorrectConfirmPassword) {
      let currentPasswordInfo = {
        email: store.email_param, 
        token: store.token_param,
        new_password: values.password,
        confirm_password: values.confirm_password,
      }
      store.handlePostPasswordResetInfo(currentPasswordInfo);
    }
  }
  
  return (
    <React.Fragment>

      {
        store.password_reset_request_status === "not_start"
        && (
          <MainWrap>
            <GlobalCss />
            <div className="text_01">新しいパスワードを設定する</div>
            <div className="text_02">新しいパスワードをご設定ください。</div>
            <div className="text_03">このページを更新しないでください。</div>
            <div className="text_04">{store.email_param}</div>
            <div className="reset_password_input_box">
              <p className={`label_01 ${values.err.password ? 'err' : ''}`}>パスワード*</p>
              <Input
                placeholder="password"
                id="miles_password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? "非表示" : "表示"}
                    </IconButton>
                  </InputAdornment>
                }
              />
              
              {
                values.err.password
                ? <p className="input_tip_01 err">{`パスワードは、少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`}</p>
                : <p className="input_tip_01">{`パスワードは、8文字以上で少なくとも1つの記号を含む必要があります（-@#$%!^&　等）`}</p>
              }
              <p className={`label_02 ${values.err.confirm_password ? 'err' : ''}`}>パスワード（再入力）*</p>
              <Input
                placeholder="confirm password"
                id="miles_confirm_password"
                type={values.show_confirm_Password ? "text" : "password"}
                value={values.confirm_password}
                onChange={handleChange("confirm_password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {values.show_confirm_Password ? "非表示" : "表示"}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {
                values.err.confirm_password && <p className="input_tip_02 err">{`入力されたパワワードが一致していません`}</p>
              }
            </div>
            <button className={`miles_ui_btn_primary btn_reset_password ${values.err.confirm_password ? 'err' : ''}`} onClick={() => handleSubmitPasswordReset()}>パスワードを設定</button>
          </MainWrap>
        )
      }

      {
        store.password_reset_request_status === "success"
        && (
          <Styled_password_reset_success>
            <div className="content_01">パスワードの再設定が完了しました</div>
            <img className="password_reset_success_img" src={password_reset_success_img} alt={`password_reset_success_img`}></img>
            <div className="content_02">パスワードの更新が完了しました。その他、ご不明な点は<a href={password_reset_help_url}>ヘルプセンター</a>をご利用ください</div>
          </Styled_password_reset_success>
        )
      }

      {
        store.password_reset_request_status === "err"
        && (
          <Styled_password_reset_err>
            <div className="content_01">パスワード再設定エラー</div>
            <img className="password_reset_err_img" src={password_reset_err_img} alt={`password_reset_err_img`}></img>
            <div className="content_02">パスワードの再設定中にエラーが発生しました。その他、ご不明な点は<a href={password_reset_help_url}>ヘルプセンター</a>をご利用ください</div>
          </Styled_password_reset_err>
        )
      }

      {
        store.password_reset_request_status === "link_expired"
        && (
          <Styled_password_reset_expired>
            <div className="content_01">リンクの期限切れ</div>
              <img className="password_reset_expired_img" src={password_reset_expired_img} alt={`password_reset_expired_img`}></img>
            <div className="content_02">パスワード再設定リンクの期限が切れています。アプリから再設定の手続きを再度お試しください。その他、ご不明な点は<a href={password_reset_help_url}>ヘルプセンター</a>をご利用ください</div>
          </Styled_password_reset_expired>
        )
      }
    </React.Fragment>
  );
});

export default Password_reset_jp;
