import miles_config from './miles';
import mjkk_config from './mjkk';
import mjp_config from './mjp';
import fca_config from './fca';
import noruku_config from './noruku';
import jre_config from './jre';


const config = (() => {
  switch(process.env.REACT_APP_MILES_TENANT) {
    case 'miles':
      return miles_config;
    case 'mjkk':
      return mjkk_config;
    case 'mjp':
      return mjp_config;
    case 'fca':
      return fca_config;
    case 'edsp':
    case 'noruku':
      return noruku_config;
    case 'jre':
      return jre_config;
    default:
      throw new Error(`Unknown tenant: ${process.env.REACT_APP_MILES_TENANT}`);
  }
})();


export default config;
