import styled from "styled-components";
import config from "../../utils/config";

const {
  fontFamily_Gilroy_Medium,
  fontFamily_Gilroy_SemiBold,
  fontFamily_Gilroy_Regular,
} = config;

interface FooterProps {}

export const StyledCopyTip = styled("div")<FooterProps>`
  margin-top: 40px;
  margin-bottom: 8px;
  font-family: ${fontFamily_Gilroy_Regular};
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "#00aeef" : "#9B9BA5"};

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    font-size: 14px;
    margin-top: 33px;
    margin-bottom: 5px;
    display: none;
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 14px;
    margin-top: 33px;
    margin-bottom: 5px;
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    font-size: 14px;
    margin-top: 20px;
  }
`;
