import React from "react";
import { TEAM_INFOS } from '../config';
import { TeamLinksContainer, TeamLink } from './styles';
import { AvatarControls, Team } from '../types';

export interface TeamLinkTabBarProps {
    selectedTeam: Team;
    avatarControls: AvatarControls;
}

export const TeamLinksTabBar = ({selectedTeam, avatarControls}: TeamLinkTabBarProps) => {
    return (
        <TeamLinksContainer>
            {
                TEAM_INFOS.map(({id, title}) => (
                    <>
                        <TeamLink
                            key={id}
                            onClick={avatarControls[id].onClick}
                            className={selectedTeam === id ? 'active tab_title miles_screen_larger_than_1150' : 'tab_title miles_screen_larger_than_1150'}>
                                {title}
                        </TeamLink> 

                        <TeamLink
                            key={id}
                            onClick={avatarControls[id].onClick}
                            className={selectedTeam === id ? 'active tab_title miles_screen_less_than_1150' : 'tab_title miles_screen_less_than_1150'}>
                                { id as string === "support" ? "Support" : title}
                        </TeamLink> 
                    </>
                ))
            }
        </TeamLinksContainer>
    );
}
