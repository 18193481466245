import React from "react";
import { StyledCard } from "./styles";

interface Props {
  avatarImgSrc: string;
  avatarImgStyle: {};
  userName: string;
  testimonialStr: string;
  testimonialStyle: {};
  className?: string | undefined,
}

export const Card_with_avatar: React.FC<Props> = ({
  avatarImgSrc,
  avatarImgStyle,
  userName,
  testimonialStr,
  testimonialStyle,
  className,
}) => {
  return (
    <StyledCard className={className}>
      <img
        src={avatarImgSrc}
        style={avatarImgStyle}
      />
      <div className="inner_card_wrap">
        <div className="userName">{userName}</div>
        <p
          className={`testimonial`}
          style={testimonialStyle}
        >
          {testimonialStr}
        </p> 
      </div>
    </StyledCard>
  );
};
