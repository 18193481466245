import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    overrides: {
        MuiPopover: {
            root: {
                "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded": {
                    transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
                }
              },
        },
    },
});
export default theme;