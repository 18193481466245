import React from "react";
import { observer } from "mobx-react";
import { StyledEle } from "./styles";

const top_banner_notice_icon = "https://getmiles.s3.amazonaws.com/assets/web_v2/top_banner/top_banner_icon_v1/top_banner_icon_v1%402x.png";

interface Props {
  click_here_url?: string;
}

export const TopBannerNoticeComponent: React.FC<Props> = observer(({
  click_here_url,
}) => {
  return (
    <StyledEle>
      <div className="top_banner_notice_content">
        <img src={top_banner_notice_icon} alt="top_banner_notice_icon" />
        <div className="top_banner_notice_text">We are currently facing some technical difficulties which may impact your experience on Miles. We appreciate your patience. <a href={click_here_url}>Click here</a> to learn more.</div>
      </div>
    </StyledEle>
  );
});
