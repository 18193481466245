import styled from "styled-components";
interface Props {
  country?: string;
}

export const Styled_body_wrap = styled("div")<Props>`
  margin: 0;
  margin: 0 auto;
  width: 100vw;
  height: 569px;

  background: #fff;

  .main_container {
    margin: 0 auto;
    width: 1440px;
  }

  .miles_ui_about_carousel_01_title {
    width: 1048px;
    height: 75px;
    margin: 0 auto;
    font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    color: #2d2f3b;
    text-align: left;
    margin-top: 32px;
  }

  .carousel-inner {
    width: inherit;
    height: 569px;
    overflow: visible;
  }

  .carousel_arrow_on_desktop {
    display: block;
  }

  .carousel_arrow_on_mobile {
    display: none;
  }

  .carousel_arrow {
    width: 50px;
    height: 50px;
    margin-top: 431px;

    &.arrow_left {
      left: calc(50vw - 640px);
      left: 93px;
      z-index: 2;
    }

    &.arrow_right {
      right: calc(50vw - 640px);
      right: 93px;
      z-index: 2;
    }

    & > div {
      width: 50px;
      height: 50px;
    }
  }

  .carousel-indicators {
    top: 105px;
    width: 1048px;
    margin: 0 auto;

    .aboutPage_carousel_01_step_line {
      width: 1125px;
      height: 1px;
      background-color: #2d2f3b;
      position: absolute;
      top: 8px;
      z-index: -1;
    }

    & > div {

      & span {
        height: 8px;
        width: 8px;
        background-color: #2d2f3b;
        border-radius: 50%;
        display: block;
        margin-top: 4px;
        cursor: pointer;

        ::after {
          display: block;
          position: relative;
          z-index: 1;
          width: 30px;
          height: 30px;
          top: -10px;
          left: -10px;
          content: " ";
        }
      }

      .aboutPage_carousel_01_label {
        color: red;
        width: max-content;
        height: 56px;
        margin: 0;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.8;
        letter-spacing: normal;
        color: #10af42;
        visibility: hidden;
      }

      &.active {
        & span {
          height: 16px;
          width: 16px;
          margin-top: 0;
          background-color: #10af42;
        }

        & .aboutPage_carousel_01_label {
          visibility: visible;
        }

      }
    }

  }

  .carousel-indicators .indicator_dot {
    background: transparent;
    margin: 0;
    margin-right: 75px;
    width: 0;
    opacity: 1;
    &.active {
      text-indent: 0;
      margin-right: 75px;
    }
  }

  .carousel-item {
    background: #fff;
  }

  .disabled_dot {
    cursor: unset;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    height: 560px;

    .main_container {
      width: 100vw;
    }

    .miles_ui_about_carousel_01_title {
      width: 283px;
      height: 32px;
      margin-top: 25px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #2d2f3b;
      text-align: left;
    }

    .carousel-inner {
      width: inherit;
      height: 560px;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }

    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 16px;
      margin-top: 275px;

      &.arrow_left {
        left: 26px;
      }

      &.arrow_right {
        right: 26px;
      }

      & > div {
        width: 10px;
        height: 16px;
      }
    }

    .carousel-indicators {
      top: 70px;
      width: 274px;
      margin: 0 auto;

      .aboutPage_carousel_01_step_line {
        width: 274px;
        height: 1px;
        background-color: #2d2f3b;
        position: absolute;
        top: 6px;
        z-index: -1;
        left: 3px;
      }
  
      & > div {
  
        & span {
          height: 6px;
          width: 6px;
          background-color: #2d2f3b;
          border-radius: 50%;
          display: block;
          margin-top: 3px;
        }
  
        .aboutPage_carousel_01_label {
          color: red;
          width: max-content;
          height: 56px;
          margin: 0;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.8;
          letter-spacing: normal;
          color: #10af42;
          visibility: hidden;

          &.pre_last {
            margin-left: -50px;
          }
  
          &.last {
            margin-left: -100px;
          }

        }
  
        &.active {
          & span {
            height: 12px;
            width: 12px;
            margin-top: 0;
            background-color: #10af42;
          }
  
          & .aboutPage_carousel_01_label {
            visibility: visible;
          }
  
        }
      }
  
    }
  
    .carousel-indicators .indicator_dot {
      background: transparent;
      margin: 0;
      margin-right: 20px;
      opacity: 1;

      &.miles_mobile_last {
        margin-right: 0;
      }      
      &.active {
        text-indent: 0;
        margin-right: 20px;
      }
    }

    .carousel-indicators > div .aboutPage_carousel_01_label_11,
    .carousel-indicators > div .aboutPage_carousel_01_label_12,
    .carousel-indicators > div .aboutPage_carousel_01_label_13,
    .carousel-indicators > div .aboutPage_carousel_01_label_14,
    .carousel-indicators > div .aboutPage_carousel_01_label_15 {
      margin-left: -72px;
    }

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    height: 560px;

    .main_container {
      width: 100vw;
    }

    .miles_ui_about_carousel_01_title {
      width: 274px;
      width: 292px;
      height: 32px;
      margin-top: 46px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #2d2f3b;
      text-align: left;
    }

    .carousel-inner {
      width: inherit;
      height: 560px;
    }

    .carousel_arrow_on_desktop {
      display: none;
    }

    .carousel_arrow_on_mobile {
      display: block;
    }

    .carousel_arrow {
      width: 10px;
      height: 16px;
      margin-top: 322px;

      &.arrow_left {
        left: calc(50vw - 160px);
      }

      &.arrow_right {
        right: calc(50vw - 160px);
      }

      & > div {
        width: 10px;
        height: 16px;
      }
    }

    .carousel-indicators {
      top: 70px;
      width: 274px;
      margin: 0 auto;

      .aboutPage_carousel_01_step_line {
        width: 274px;
        height: 1px;
        background-color: #2d2f3b;
        position: absolute;
        top: 6px;
        z-index: -1;
        left: 3px;
      }
  
      & > div {
  
        & span {
          height: 6px;
          width: 6px;
          background-color: #2d2f3b;
          border-radius: 50%;
          display: block;
          margin-top: 3px;
        }
  
        .aboutPage_carousel_01_label {
          color: red;
          width: max-content;
          height: 56px;
          margin: 0;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.8;
          letter-spacing: normal;
          color: #10af42;
          visibility: hidden;

          &.pre_last {
            margin-left: -50px;
          }
  
          &.last {
            margin-left: -100px;
          }
        }
  
        &.active {
          & span {
            height: 12px;
            width: 12px;
            margin-top: 0;
            background-color: #10af42;
          }
  
          & .aboutPage_carousel_01_label {
            visibility: visible;
          }
  
        }
      }
    }
  
    .carousel-indicators .indicator_dot {
      background: transparent;
      margin: 0;
      margin-right: 20px;
      opacity: 1;

      &.miles_mobile_last {
        margin-right: 0;
      }      
      &.active {
        text-indent: 0;
        margin-right: 20px;
      }
    }

    .carousel-indicators > div .aboutPage_carousel_01_label_12,
    .carousel-indicators > div .aboutPage_carousel_01_label_13,
    .carousel-indicators > div .aboutPage_carousel_01_label_14,
    .carousel-indicators > div .aboutPage_carousel_01_label_15 {
      margin-left: -72px;
    }

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    height: 575px;

    .main_container {
      width: 100vw;
    }

    .miles_ui_about_carousel_01_title {
      width: 628px;
      margin-top: 90px;
    }

    .carousel-inner {
      width: inherit;
      height: 575px;
    }

    .carousel_arrow_on_desktop {
      display: block;
    }

    .carousel_arrow_on_mobile {
      display: none;
    }

    .carousel_arrow {
      margin-top: 317px;

      &.arrow_left {
        left: 150px;
      }

      &.arrow_right {
        right: 150px;
      }

      & > div {
        width: 50px;
        height: 50px;
      }
    }

    .carousel-indicators {
      top: 90px;
      width: 630px;
      margin: 0 auto;
  
      .aboutPage_carousel_01_step_line {
        width: 628px;
        height: 1px;
        background-color: #2d2f3b;
        position: absolute;
        top: 6px;
        z-index: -1;
        left: 6px;
      }
  
      & > div {
  
        & span {
          height: 6px;
          width: 6px;
          background-color: #2d2f3b;
          border-radius: 50%;
          display: block;
          margin-top: 3px;
        }
  
        .aboutPage_carousel_01_label {
          color: red;
          width: max-content;
          height: 56px;
          margin: 0;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.8;
          letter-spacing: normal;
          color: #10af42;
          visibility: hidden;
        }
  
        &.active {
          & span {
            height: 12px;
            width: 12px;
            margin-top: 0;
            background-color: #10af42;
          }
  
          & .aboutPage_carousel_01_label {
            visibility: visible;
          }
  
        }
      }
  
    }
  
    .carousel-indicators .indicator_dot {
      background: transparent;
      margin: 0;
      margin-right: 45px;
      opacity: 1;
      &.active {
        text-indent: 0;
        margin-right: 45px;
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    height: 534px;

    .main_container {
      width: 1280px;
      width: 100vw;
    }

    .miles_ui_about_carousel_01_title {
      width: 914px;
      margin-top: 103px;
    }

    .carousel-inner {
      height: 534px;
    }

    .carousel_arrow_on_desktop {
      display: block;
    }

    .carousel_arrow_on_mobile {
      display: none;
    }

    .carousel_arrow {
      width: 50px;
      height: 50px;
      margin-top: 393px;

      &.arrow_left {
        left: calc(50vw - 640px);
        left: 105px;
      }

      &.arrow_right {
        right: calc(50vw - 640px);
        right: 105px;
      }

      & > div {
        width: 50px;
        height: 50px;
      }
    }

    .carousel-indicators {
      top: 110px;
      width: 912px;
      margin: 0 auto;
  
      .aboutPage_carousel_01_step_line {
        width: 912px;
        height: 1px;
        background-color: #2d2f3b;
        position: absolute;
        top: 6px;
        z-index: -1;
        left: 5px;
      }
  
      & > div {
  
        & span {
          height: 6px;
          width: 6px;
          background-color: #2d2f3b;
          border-radius: 50%;
          display: block;
          margin-top: 3px;
        }
  
        .aboutPage_carousel_01_label {
          color: red;
          width: max-content;
          height: 56px;
          margin: 0;
          font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.8;
          letter-spacing: normal;
          color: #10af42;
          visibility: hidden;
        }
  
        &.active {
          & span {
            height: 12px;
            width: 12px;
            margin-top: 0;
            background-color: #10af42;
          }
  
          & .aboutPage_carousel_01_label {
            visibility: visible;
          }
  
        }
      }
  
    }
  
    .carousel-indicators .indicator_dot {
      background: transparent;
      margin: 0;
      margin-right: 65px;
      opacity: 1;
      &.active {
        text-indent: 0;
        margin-right: 65px;
      }
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;

export const Styled_Card = styled("div")<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 163px 0 0 0;
  margin: 0 307px;
  justify-content: flex-start;

  .carousel_01_img {
    display: inline-block;
    width: 414px;
    height: 414px;
    margin-right: 60px;
  }

  .descriptionEle {
    width: 414px;
    width: auto;
    height: auto;
    margin: 0;
    font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #2d2f3b;
    text-align: left;

    .highlighted {
      color: #10af42;
    }

    .subtext {
      font-size: 24px;
    }
  }

  &.carousel_card_longer_content {
    margin: 0 210px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px 0 0 0;
    margin: 0 auto;
    justify-content: center;

    .carousel_01_img {
      display: inline-block;
      width: 250px;
      height: 250px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .descriptionEle {
      width: 300px;
      height: auto;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      text-align: center;

      .highlighted {
        color: #10af42;
      }

      .subtext {
        font-size: 24px;
      }
    }

    &.carousel_card_longer_content {
      margin: 0 auto;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px 0 0 0;
    margin: 0 auto;
    justify-content: center;

    .carousel_01_img {
      display: inline-block;
      width: 250px;
      height: 250px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .descriptionEle {
      width: auto;
      height: auto;
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      text-align: center;

      .highlighted {
        color: #10af42;
      }
    }

    .subtext {
      font-size: 24px;
    }

    &.carousel_card_longer_content {
      margin: 0 auto;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 107px 0 0 0;
    margin: 0 auto;
    justify-content: center;

    .carousel_01_img {
      display: inline-block;
      width: 280px;
      height: 280px;
      margin-right: 0;
      margin-bottom: 53px;
    }

    .descriptionEle {
      width: auto;
      height: auto;
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      text-align: center;

      .highlighted {
        color: #10af42;
      }
    }

    .subtext {
      font-size: 24px;
    }

    &.carousel_card_longer_content {
      margin: 0 210px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    padding: 168px 0 0 0;
    margin: 0 288px;

    .carousel_01_img {
      display: inline-block;
      width: 366px;
      height: 366px;
      margin-right: 45px;
    }

    .descriptionEle {

      .highlighted {
        color: #10af42;
      }
    }

    .subtext {
      font-size: 24px;
    }

    &.carousel_card_longer_content {
      margin: 0 150px;
    } 
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;

interface card_on_template_02_Props {
  country?: string;
}
export const Styled_card_on_template_02 = styled("div")<card_on_template_02_Props>`
  padding: 163px 0 0 0;
  margin: 0 307px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .carousel_01_template_img {
    display: inline-block;
    width: 464px;
    height: 295px;
    margin-right: 60px;
  }

  .content_wrap {
    .title {
      width: max-content;
      height: auto;
      margin: 0;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: rgb(45, 47, 59);
      margin-bottom: 10px;
    }

    .content {
      width: 480px;
      height: auto;
      margin: 0;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: rgb(45, 47, 59);

      .highlighted {
        color: #10af42;
      }

      .subtext {
        font-size: 24px;
      }
    }

  }

  &.carousel_card_longer_content {
    .content_wrap {
      .content {
        width: 544px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    padding: 100px 0 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .carousel_01_template_img {
      display: inline-block;
      width: 250px;
      height: 250px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .content_wrap {
      .title {
        width: 255px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: rgb(45, 47, 59);
        margin: 0 auto;
        margin-bottom: 26px;
      }

      .content {
        width: 284px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: rgb(45, 47, 59);

        .highlighted {
          color: #10af42;
        }

        .subtext {
          font-size: 24px;
        }
      }

    }

    &.carousel_card_longer_content {
      padding: 100px 0 0 0;
      .content_wrap {
        .content {
          width: 350px;
          margin: 0 auto;
        }
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    padding: 100px 0 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .carousel_01_template_img {
      display: inline-block;
      width: 250px;
      height: 250px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .content_wrap {
      .title {
        width: 255px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: rgb(45, 47, 59);
        margin: 0 auto;
        margin-bottom: 26px;
      }

      .content {
        width: 284px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: rgb(45, 47, 59);

        .highlighted {
          color: #10af42;
        }

        .subtext {
          font-size: 24px;
        }
      }

    }

    &.carousel_card_longer_content {
      padding: 100px 0 0 0;
      .content_wrap {
        .content {
          width: 350px;
          margin: 0 auto;
        }
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

    padding: 107px 0 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .carousel_01_template_img {
      display: inline-block;
      width: 280px;
      height: 280px;
      margin-right: 0;
    }

    .content_wrap {
      .title {
        width: max-content;
        height: auto;
        margin: 0 auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: rgb(45, 47, 59);
        margin-bottom: 20px;
      }

      .content {
        width: 414px;
        height: auto;
        margin: 0 auto;
        font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Medium'};
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        text-align: center;
        color: rgb(45, 47, 59);

        .highlighted {
          color: #10af42;
        }

        .subtext {
          font-size: 24px;
        }
      }

    }

    &.carousel_card_longer_content {
      .content_wrap {
        .content {
          width: 450px;
        }
      }
    }

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    padding: 168px 0 0 0;
    margin: 0 288px;

    .carousel_01_template_img {
      display: inline-block;
      width: 366px;
      height: 366px;
      margin-right: 30px;
    }
  
    .content_wrap {
      .title {}
      .content {}
    }
  
    &.carousel_card_longer_content {
      .content_wrap {
        .content {
          width: 544px;
        }
      }
    }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;

interface card_on_template_03_Props {
  country?: string;
}
export const Styled_card_on_template_03 = styled("div")<card_on_template_03_Props>`
  padding: 120px 0 0 0;
  margin: 0 307px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .carousel_01_template_img {
    display: inline-block;
    width: 564px;
    height: 286px;
    margin-bottom: 40px;
   }

   .content {
    width: max-content;
    height: auto;
    margin: 0 auto;
    font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: rgb(45, 47, 59);

    .highlighted {
      color: #10af42;
    }

    .subtext {
      font-size: 24px;
    }
   }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    padding: 180px 0 0 0;
    margin: 0 auto;

    .carousel_01_template_img {
      display: inline-block;
      width: 262px;
      height: 133px;
      margin-bottom: 75px;
    }

    .content {
      width: max-content;
      height: auto;
      margin: 0 auto;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgb(45, 47, 59);

      .highlighted {
        color: #10af42;
      }

      .subtext {
        font-size: 24px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    padding: 180px 0 0 0;
    margin: 0 auto;

    .carousel_01_template_img {
      display: inline-block;
      width: 262px;
      height: 133px;
      margin-bottom: 75px;
    }

    .content {
      width: max-content;
      height: auto;
      margin: 0 auto;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgb(45, 47, 59);

      .highlighted {
        color: #10af42;
      }

      .subtext {
        font-size: 24px;
      }
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    padding: 80px 0 0 0;
    margin: 0 auto;

    .carousel_01_template_img {
      display: inline-block;
      width: 500px;
      height: 254px;
      margin-bottom: 60px;
    }

    .content {
      width: max-content;
      height: auto;
      margin: 0 auto;
      font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
      color: rgb(45, 47, 59);

      .highlighted {
        color: #10af42;
      }

      .subtext {
        font-size: 24px;
      }
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    padding: 80px 0 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  .carousel_01_template_img {
    display: inline-block;
    width: 564px;
    height: 286px;
    margin-bottom: 40px;
   }

   .content {
    width: max-content;
    height: auto;
    margin: 0 auto;
    font-family: ${(props) => props.country === 'jp' ? 'Noto Sans JP' : 'Gilroy-Bold'};
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: rgb(45, 47, 59);
   }

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;