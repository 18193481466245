const JAPANESE_LNG = {
	"email_unsubscribe_initial_box_title": `以下のメールカテゴリからの配信を解除してもよろしいでしょうか？`,
	"text_confirm": "確認",
	"text_cancel": "キャンセル",

	"email_unsubscribe_confirm_box_title": "逝ってしまうのは寂しいですね...。",
	"email_unsubscribe_confirm_box_subTitle_1": " のメールの配信を停止しました。エラーが発生した場合は、以下のボタンをクリックして再登録してください。",
	"email_unsubscribe_confirm_box_subTitle_2": "",
	"email_unsubscribe_confirm_box_cheer_text": "これからも素晴らしい体験をお届けしていきたいと思います。",
	"text_reSubscribe": "再購読",
	"preference_preText": "または電子メールの管理",
	"text_preference": "好み",
	"text_note": "留意事項: ",
	"text_note_description": "変更が有効になるまで10日ほどかかりますので、ご了承ください。",

	"text_Thank_you": "ありがとうございます！",
	"email_unsubscribe_thx_box_resubscribe_subTitle_1": " メーリングリストに再登録しました。アプリからいつでもメール通知の設定を変更することができます。",
	"email_unsubscribe_thx_box_resubscribe_subTitle_2": "",
	"email_unsubscribe_thx_box_resubscribeAll_subTitle": "全てのメールに再登録しました。アプリからいつでもメール通知の設定を更新することができます。",
	"email_unsubscribe_thx_box_cheer_text": "これからも素晴らしい体験をお届けしていきたいと思います。",

	"email_unsubscribe_thx_box_update_preference_by_group_subTitle": "メールの設定を更新しました。これからも素晴らしい体験をお届けしていきたいと思っています。",
	"email_unsubscribe_thx_box_note_description": "変更が有効になるまで10日ほどかかりますので、ご了承ください。",

	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_title": "逝ってしまうのは寂しいですね...。",
	"email_unsubscribe_thx_box_update_preference_unsubscribe_all_emails_subTitle": "すべてのメール配信の停止が完了しました。誤って解除してしまった場合は、以下のボタンをクリックして再登録することができます。",
	
	"email_Preferences_box_title": "電子メールの設定",
	"email_Preferences_box_subTitle": "受信したくないメールの種類のチェックを外します。",
	"email_Preferences_box_btn_text": "メールの環境設定を更新する",
	"text_Unsubscribe_from_all": "すべての購読を解除する",
	"text_Unsubscribe_from_all_description": "すべてのマーケティングおよびプロモーションメールの受信を停止します。必要に応じて、お客様のアカウントに関連した重要な連絡を引き続き受け取ることができます。",
}

export default JAPANESE_LNG;