import styled from "styled-components";

interface Props {
  type: string
}
export const StyledEmailPreferenceMainBody = styled("div")<Props>`
  width: 600px;
  height: auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 170px;
  padding: ${(props) => props.type === "unsubscribe" ? '48px 30px 53px 29px' : '30px'};
  border-radius: 32px;
  box-shadow: 0 0 17px 0 #e6e6e8;
  background-color: #ffffff;
  margin-bottom: 80px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    top: 100px;
    width: 320px;
    padding: ${(props) => props.type === "unsubscribe" ? '34px 30px 33px' : '0'};
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 600px) {
    top: 75px;
    width: 340px;
    padding: ${(props) => props.type === "unsubscribe" ? '34px 32px 32.5px 29px' : '0 0 20px 0'};
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-device-width : 361px) and (max-device-width : 600px) {
    top: 100px;
    width: 340px;
    padding: ${(props) => props.type === "unsubscribe" ? '34px 32px 32.5px 29px' : '0 0 20px 0'};
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 601px) and (max-width : 768px) {
    top: 150px;
    width: 340px;
    padding: ${(props) => props.type === "unsubscribe" ? '34px 32px 32.5px 29px' : '0 0 20px 0'};
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-device-width : 601px) and (max-device-width : 768px) {
    top: 75px;
    width: 340px;
    padding: ${(props) => props.type === "unsubscribe" ? '34px 32px 32.5px 29px' : '0 0 20px 0'};
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {

  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {

  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }
`;