import styled from "styled-components";
import config from "../../../utils/config";
const {
  primary_color_01
} = config

export const Styled_body_wrap = styled("div")`

.ciq_root_breadcrumb{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #f3f4f4;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
    "border: 1px solid red;"
  }
  }

  .ciq_container_breadcrumb{
    width: 1440px;
    ${process.env.REACT_APP_MILES_ENV === "development" &&
      "border: 1px solid blue;"
    }
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: Gilroy-Medium;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 2.0px;
    color: #2d2f3b;
    background-color: #f3f4f4;
    position: relative;
  }

  .help_path{
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    color: ${() => primary_color_01};
  }

  a:active, a:visited, a:hover {
    text-decoration: none;
  }

  .pointer_path{
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    color: #afafb5;
    margin-left: 16px;
    margin-right: 16px;
  }

  .pointer_path_question {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    color: #afafb5;
    margin-left: 16px;
    margin-right: 16px;
  }

  .section_path{
    color: #2d2f3b;
    text-transform: uppercase;
  }

  a > .section_path{
    color: ${() => primary_color_01};
  }

  .ciq_inner_container_breadcrumb{
      margin-left: 216.5px;
    }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    .ciq_inner_container_breadcrumb{
      margin-left: 25px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    .ciq_inner_container_breadcrumb{
      width: 320px;
      margin: 0 auto;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    .ciq_inner_container_breadcrumb{
      width: 320px;
      margin: 0 auto;
    }
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    .ciq_inner_container_breadcrumb{
      width: 320px;
      margin: 0 auto;
    }
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .ciq_inner_container_breadcrumb{
      width: 320px;
      margin: 0 auto;
    }
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .ciq_inner_container_breadcrumb{
      margin-left: 82.5px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .ciq_inner_container_breadcrumb{
      margin-left: 125.5px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;