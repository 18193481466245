import styled from "styled-components";

interface Props {}
export const StyledWrap = styled("div")<Props>`
  width: 100vw;
  height: 1060px;
  background-color: #fbfefc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .large_than_1150 {
    display: inline;
  }

  .between_768_and_1150 {
    display: none;
  }

  .between_360_and_768 {
    display: none;
  }

  .lessThan_360 {
    display: none;
  }

  .title {
    width: auto;
    height: 36px;
    font-family: Gilroy-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.13px;
    color: #2d2f3b;
    margin-top: 125px;
  }

  .perks_img {
    width: 646px;
    height: 196px;
    display: inline-block;
    margin-top: 47px;
  }

  .benefit_box_wrap_01 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 930px;

    .benefit_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 290px;
      height: 290px;
      margin: 0;
      margin-top: 40px;
      margin-right: 30px;
      padding: 20px 40px 29px 20px;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;

      &.last {
        margin-right: 0;
      }
    }

    .benefit_icon {
      width: 60px;
      height: 60px;
    }
    .inner_title {
      font-family: Gilroy-Bold;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.94;
      letter-spacing: 0.53px;
      color: #2d2f3b;
      margin-top:17px;
    }

    .benefit_list {
      width: max-content;
      &.first_list {
        margin-top: 13px;
      }
      .dot {
        height: 5px;
        width: 5px;
        background-color: #10af42;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6.5px;
        bottom: 3px;
        position: relative;
      }

      .dot_inVisible {
        height: 5px;
        width: 5px;
        margin-right: 6.5px;border-radius: 50%;
        display: inline-block;
      }

      .benefit_content {
        width: 232px;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.81;
        letter-spacing: normal;
        color: #82828c;
      }

    }
  }

  .benefit_box_wrap_02 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 930px;
    height: 170px;
    margin: 0;
    margin-top: 30px;
    padding: 33px 39.5px 32px 29px;
    border-radius: 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;

    &.large_than_1150 {
      display: flex;
    }
  
    &.between_768_and_1150 {
      display: none;
    }

    &.between_360_and_768 {
      display: none;
    }

    .benefit_box_inner_section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.section_01 {
        margin-right: 32px;
      }

      &.section_02 {
        width: 206px;
        margin-right: 29.5px;
      }

      &.section_03 {
        width: max-content;
        margin-right: 32px;
      }
    }

    .benefit_icon {
      width: 60px;
      height: 60px;
    }
    .inner_title {
      font-family: Gilroy-Bold;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.53px;
      color: #2d2f3b;
      margin-top:10px;
    }

    .dot {
      height: 5px;
      width: 5px;
      background-color: #10af42;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6.5px;
      bottom: 3px;
      position: relative;
    }

    .dot_inVisible {
      height: 5px;
      width: 5px;
      margin-right: 6.5px;border-radius: 50%;
      display: inline-block;
    }

    .benefit_content {
      width: 232px;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.81;
      letter-spacing: normal;
      color: #82828c;

      span {
        font-size: 12px;
        line-height: 29px;
      }
    }

  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 100vw;
    height: 1640px;
    background-color: #fbfefc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .large_than_1150 {
      display: none;
    }
  
    .between_768_and_1150 {
      display: none;
    }

    .between_360_and_768 {
      display: flex;
    }

    .lessThan_360 {
      display: block;
    }

    .title {
      width: auto;
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.1;
      letter-spacing: 0.63px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 56px;
    }

    .perks_img {
      display: none;
    }

    .perks_sub_title {
      width: 290px;
      height: 72px;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.53px;
      text-align: center;
      color: #2d2f3b;
      margin_top: 15px;
    }

    .benefit_box_wrap_01 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 220px;
  
      .benefit_box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 220px;
        height: 280px;
        margin: 0;
        margin-top: 50px;
        margin-right: 0;
        padding: 25px 17.5px 0 20px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;
  
        &.last {
          margin-right: 0;
        }
      }
  
      .benefit_icon {
        width: 60px;
        height: 60px;
      }
      .inner_title {
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.47px;
        color: #2d2f3b;
        margin-top: 10px;
      }
  
      .benefit_list {
        width: max-content;
        &.between_360_and_768 {
          display: inline-block;
        }
        &.first_list {
          margin-top: 10px;
        }
        .dot {
          height: 5px;
          width: 5px;
          background-color: #10af42;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6.5px;
          bottom: 3px;
          position: relative;
        }
  
        .dot_inVisible {
          height: 5px;
          width: 5px;
          margin-right: 6.5px;border-radius: 50%;
          display: inline-block;
        }
  
        .benefit_content {
          width: 176px;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.23;
          letter-spacing: normal;
          color: #82828c;
        }
  
      }
    }

    .benefit_box_wrap_02 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 220px;
      height: 426px;
      margin: 0;
      margin-top: 25px;
      padding: 20px 17.5px 0 20px;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
  
      &.large_than_1150 {
        display: none;
      }
    
      &.between_768_and_1150 {
        display: none;
      }

      &.between_360_and_768 {
        display: flex;
      }
  
      .benefit_box_inner_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
      }
  
      .benefit_icon {
        width: 60px;
        height: 60px;
        margin-top: 0;
      }
      .inner_title {
        height: 35px;
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.19;
        letter-spacing: 0.47px;
        color: #2d2f3b;
        margin-top:10px;
      }
  
      .dot {
        height: 5px;
        width: 5px;
        background-color: #10af42;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6.5px;
        bottom: 3px;
        position: relative;
      }
  
      .dot_inVisible {
        height: 5px;
        width: 5px;
        margin-right: 6.5px;border-radius: 50%;
        display: inline-block;
      }

      .first_list {
        margin-top: 10px;
      }
  
      .benefit_content {
        width: 206px;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 29px;
        letter-spacing: normal;
        color: #82828c;
  
        span {
          font-size: 11px;
          line-height: 29px;
        }
      }
  
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 100vw;
    height: 1640px;
    background-color: #fbfefc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .large_than_1150 {
      display: none;
    }
  
    .between_768_and_1150 {
      display: none;
    }

    .between_360_and_768 {
      display: flex;
    }

    .title {
      width: auto;
      height: 46px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.75px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 55px;
    }

    .perks_img {
      display: none;
    }

    .perks_sub_title {
      width: 327px;
      height: 72px;
      margin: 0;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.61px;
      text-align: center;
      color: #2d2f3b;
      margin_top: 15px;
    }

    .benefit_box_wrap_01 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 220px;
  
      .benefit_box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 220px;
        height: 280px;
        margin: 0;
        margin-top: 25px;
        margin-right: 0;
        padding: 25px 17.5px 0 20px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;
  
        &.last {
          margin-right: 0;
        }
      }
  
      .benefit_icon {
        width: 60px;
        height: 60px;
      }
      .inner_title {
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.47px;
        color: #2d2f3b;
        margin-top: 10px;
      }
  
      .benefit_list {
        width: max-content;
        &.between_360_and_768 {
          display: inline-block;
        }
        &.first_list {
          margin-top: 10px;
        }
        .dot {
          height: 5px;
          width: 5px;
          background-color: #10af42;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6.5px;
          bottom: 3px;
          position: relative;
        }
  
        .dot_inVisible {
          height: 5px;
          width: 5px;
          margin-right: 6.5px;border-radius: 50%;
          display: inline-block;
        }
  
        .benefit_content {
          width: 176px;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.23;
          letter-spacing: normal;
          color: #82828c;
        }
  
      }
    }

    .benefit_box_wrap_02 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 220px;
      height: 426px;
      margin: 0;
      margin-top: 25px;
      padding: 20px 17.5px 0 20px;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
  
      &.large_than_1150 {
        display: none;
      }
    
      &.between_768_and_1150 {
        display: none;
      }

      &.between_360_and_768 {
        display: flex;
      }
  
      .benefit_box_inner_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
      }
  
      .benefit_icon {
        width: 60px;
        height: 60px;
        margin-top: 0;
      }
      .inner_title {
        height: 35px;
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.19;
        letter-spacing: 0.47px;
        color: #2d2f3b;
        margin-top:10px;
      }
  
      .dot {
        height: 5px;
        width: 5px;
        background-color: #10af42;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6.5px;
        bottom: 3px;
        position: relative;
      }
  
      .dot_inVisible {
        height: 5px;
        width: 5px;
        margin-right: 6.5px;border-radius: 50%;
        display: inline-block;
      }

      .first_list {
        margin-top: 10px;
      }
  
      .benefit_content {
        width: 206px;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 29px;
        letter-spacing: normal;
        color: #82828c;
  
        span {
          font-size: 11px;
          line-height: 29px;
        }
      }
  
    }

  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
   
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 100vw;
    height: 1024px;

    .large_than_1150 {
      display: none;
    }
  
    .between_768_and_1150 {
      display: inline;
    }

    .title {
      width: auto;
      height: 32px;
      font-family: Gilroy-Bold;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #2d2f3b;
      margin-top: 89px;
    }

    .perks_img {
      width: 645.6px;
      height: 196px;
      display: inline-block;
      margin-top: 35px;
    }

    .benefit_box_wrap_01 {
      width: 670px;

      .benefit_box {
        width: 210px;
        height: 290px;
        margin: 0;
        margin-top: 16px;
        margin-right: 20px;
        padding: 20px 21px 27.5px 20px;
        border-radius: 32px;
        box-shadow: 0 0 17px 0 #e6e6e8;
        background-color: #ffffff;

        &.last {
          margin-right: 0;
        }
      }

      .benefit_icon {}

      .inner_title {
        width: 170px;
        height: 32px;
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.47px;
        color: #2d2f3b;
        margin-top: 19px;
      }

      .benefit_list {
        width: max-content;
        &.first_list {
          margin-top: 14.5px;
        }
        .dot {}

        .dot_inVisible {}

        .benefit_content {
          width: 232px;
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.23;
          letter-spacing: normal;
          color: #82828c;
        }

      }
    }

    .benefit_box_wrap_02 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 671px;
      height: 185px;
      margin: 0;
      margin-top: 35px;
      padding: 20px 25.5px 20px 29px;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
  
      &.large_than_1150 {
        display: none;
      }
    
      &.between_768_and_1150 {
        display: flex;
      }
  
      .benefit_box_inner_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
  
        &.section_01 {
          width: 123px;
          margin-right: 75.5px;
        }
  
        &.section_02 {
          width: 206px;
          margin-right: 37.5px;
        }

        &.section_03 {
          width: max-content;
          margin-right: 0;

        }
        
      }
  
      .benefit_icon {
        width: 60px;
        height: 60px;
        margin-top: 10.5px;
      }
      .inner_title {
        font-family: Gilroy-Bold;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: 0.53px;
        color: #2d2f3b;
        margin-top:10px;
      }
  
      .dot {
        height: 5px;
        width: 5px;
        background-color: #10af42;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6.5px;
        bottom: 3px;
        position: relative;
      }
  
      .dot_inVisible {
        height: 5px;
        width: 5px;
        margin-right: 6.5px;border-radius: 50%;
        display: inline-block;
      }
  
      .benefit_content {
        width: 206px;
        margin: 0;
        font-family: Gilroy-Medium;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.23;
        letter-spacing: normal;
        color: #82828c;
  
        span {
          font-size: 11px;
          line-height: 29px;
        }
      }
  
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    .title {
      margin-top: 140px;
    }
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;