import styled from "styled-components";
import config from "../../utils/config";
const {
	// reward sharing start
  primary_color_01
	// reward sharing end
  } = config

interface DownloadTextBoxProps {}

export const StyledDownloadTextBox = styled("div")<DownloadTextBoxProps>`
    height: 52px;
    border-radius: 31px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    background-color: ${() => primary_color_01};
    box-sizing: border-box;
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    margin-top: 42px;
    width: 464px;

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 361px) and (max-width : 768px) {
        font-size: 10px;
        width: 231px;
    }
    /* ----------- Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        font-size: 10px;
        width: 231px;
    }

    /* ----------- mobile small Tablets ----------- */
    /* Small Devices, Tablets ----------- */
    @media only screen and (min-width : 769px) and (max-width : 1150px) {
        margin: 0 auto;
        margin-top: 42px;
    }
    
    /* ----------- desktop ----------- */
    /* Medium Devices, Desktops ----------- */
    @media only screen and (min-width : 1151px) and (max-width : 1279px) {
        margin: 0 auto;
        margin-top: 25px;
    }
    
    @media only screen and (min-width : 1280px) and (max-width : 1439px) {
        width: 464px;
    }

    /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
    width: 464px;
  }
`;
