import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { TopBannerNoticeComponent as TopBannerNoticeComponent_en } from "../topBannerNotice";
import { TopBannerNoticeComponent as TopBannerNoticeComponent_jp } from "../topBannerNotice_jp";
import { store } from "../../../Store";
import { useCountry } from "../../../utils/milesUtils";

interface Props {}
let firstRender = true;

export const TopBannerNoticeComponent_hide_show: React.FC<Props> = observer(({}) => {
  let location = useLocation();
  if (firstRender) {
    if(location.pathname === "/" || location.pathname === "/jp") {
      store.fetch_over_subscription()
    }
  }
  useEffect(() => {
    firstRender = false;
  }, []);

  let country = useCountry();
  let shouldTriggerTopBanner = location.pathname === "/" || location.pathname === "/jp"

  let TopBannerNoticeComponent_current = country === "jp"
    ? <TopBannerNoticeComponent_jp click_here_url={store.top_banner_notice.click_here_url} />
    : <TopBannerNoticeComponent_en click_here_url={store.top_banner_notice.click_here_url} />

  return (shouldTriggerTopBanner && store.top_banner_notice.show_banner) ? TopBannerNoticeComponent_current : null;;
});
