import styled from "styled-components";

interface Props {
  bg: string[];
}
export const StyledEmailPreferenceHeader = styled("div")<Props>`
  height: 300px;
  background-image: url(${(props) => props.bg[0]});
  border-bottom: 1px solid #d2d2d2;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 65px;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    height: 270px;
    padding-top: 100px;
    background-image: url(${(props) => props.bg[2]});
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 270px;
    padding-top: 100px;
    background-image: url(${(props) => props.bg[2]});
  }
`;