import i18n from 'i18next'; 
import { initReactI18next } from 'react-i18next';
import { languages } from './language.js';

const defaultLanguage = 'english';

i18n
  .use(initReactI18next)
  .init({
    resources: languages,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(t => {
    console.log('18n is ready');
  });

const changeLanguage = lng => {
  i18n.changeLanguage(lng);
};

export default changeLanguage;
