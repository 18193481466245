import styled from "styled-components";

interface Props {}
export const StyledCard = styled("div")<Props>`
  width: 315px;
  height: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  img {
    display: inline-block;
  }

  .inner_card_wrap {
    width: 315px;
    height: 320px;
    margin: 0 auto;
    border-radius: 32px;
    box-shadow: 0 0 10px 0 #e6e6e8;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: -70px;
    padding-top: 70px;
  }

  .userName {
    height: 40px;
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #2d2f3b;
    margin-top: 20px;
  }

  .testimonial {
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    margin-top: 15px;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    width: 275px;
    height: 250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    img {
      display: inline-block;
      width: 119px !important;
      height: 119px !important;
    }

    .inner_card_wrap {
      width: 275px;
      height: 310px;
      margin: 0 auto;
      border-radius: 32px;
      box-shadow: 0 0 10px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-top: -60px;
      padding-top: 60px;
    }

    .userName {
      height: 40px;
      font-family: Gilroy-Bold;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.82;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 14px;
    }

    .testimonial {
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-top: 18px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    width: 275px;
    height: 250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    img {
      display: inline-block;
      width: 119px !important;
      height: 119px !important;
    }

    .inner_card_wrap {
      width: 275px;
      height: 310px;
      margin: 0 auto;
      border-radius: 32px;
      box-shadow: 0 0 10px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-top: -60px;
      padding-top: 60px;
    }

    .userName {
      height: 40px;
      font-family: Gilroy-Bold;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.82;
      letter-spacing: normal;
      text-align: center;
      color: #2d2f3b;
      margin-top: 14px;
    }

    .testimonial {
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-top: 18px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }
  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
   
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    width: 275px;
    height: 250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    img {
      display: inline-block;
    }

    .inner_card_wrap {
      width: 275px;
      height: 320px;
      margin: 0 auto;
      border-radius: 32px;
      box-shadow: 0 0 17px 0 #e6e6e8;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-top: -70px;
      padding-top: 70px;
    }

    .userName {
      margin-top: 16px;
    }

    .testimonial {
      width: 240px;
      height: auto;
      font-size: 14px;
      font-family: Gilroy-Medium;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
      margin-top: 20px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
  }
`;