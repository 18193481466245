import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Card_with_testimonial } from "../card_with_testimonial";
import "react-multi-carousel/lib/styles.css";
import { Styled_We_are_highlighted } from "./styles";
import { useSpringCarousel, ReactSpringCarouselItem } from './../spring_carousel';
import { useMediaQuery } from "@material-ui/core";

interface Props {
  we_are_highlighted_techCrunch_img: string;
  we_are_highlighted_Forbes_img: string;
  we_are_highlighted_fastCompany_img: string;

  we_are_highlighted_wired_img: string;
  we_are_highlighted_ozy_img: string;
  we_are_highlighted_the_points_guy_img: string;

  we_are_highlighted_greenBiz_img: string;
  we_are_highlighted_mashable_img: string;
  we_are_highlighted_the_verge_img: string;

  we_are_highlighted_nast_traveler_img: string;
  we_are_highlighted_skift_img: string;
  we_are_highlighted_card_rates_img: string;

  we_are_highlighted_badcredit_img: string;
  we_are_highlighted_curbed_img: string;
  we_are_highlighted_geekwire_img: string;

  logo_2022_wired_v1: string;
  logo_2022_greekwire_v1: string;
  logo_2022_smartCitiesDrive_v1: string;
  logo_2022_metro_v1: string;
  logo_2022_massTransit_v1: string;
  logo_2022_techCrunch_v1: string;
  logo_2022_intelligentTransport_v1: string;
  logo_2022_KQED_v1: string;
  logo_2022_eastCountyToday_v1: string;

  carousel_arrow_left_img: string;
  carousel_arrow_right_img: string;
}

export const We_are_highlighted: React.FC<Props> = ({
  we_are_highlighted_techCrunch_img,
  we_are_highlighted_Forbes_img,
  we_are_highlighted_fastCompany_img,
  we_are_highlighted_wired_img,
  we_are_highlighted_ozy_img,
  we_are_highlighted_the_points_guy_img,
  we_are_highlighted_greenBiz_img,
  we_are_highlighted_mashable_img,
  we_are_highlighted_the_verge_img,
  we_are_highlighted_nast_traveler_img,
  we_are_highlighted_skift_img,
  we_are_highlighted_card_rates_img,
  we_are_highlighted_badcredit_img,
  we_are_highlighted_curbed_img,
  we_are_highlighted_geekwire_img,
  logo_2022_wired_v1,
  logo_2022_greekwire_v1,
  logo_2022_smartCitiesDrive_v1,
  logo_2022_metro_v1,
  logo_2022_massTransit_v1,
  logo_2022_techCrunch_v1,
  logo_2022_intelligentTransport_v1,
  logo_2022_KQED_v1,
  logo_2022_eastCountyToday_v1,

  carousel_arrow_left_img,
  carousel_arrow_right_img,
}) => {
  const matchesDesktopAndLarger = useMediaQuery('(min-width: 1150px)');
  const matchesTablet = useMediaQuery('(min-width: 768px)');
  let location = useLocation();

  let currentTitle = location.pathname.split('/')[1].toLowerCase() === "cities" ? "In the news" : "Discover Us In The News";

  const itemsPerSlide = (
    matchesDesktopAndLarger ? 3 : matchesTablet ? 2 : 1
  );

  const items = useMemo(() => {
    const card_with_testimonial_data = [
      {
        logoImgSrc: we_are_highlighted_fastCompany_img,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.fastcompany.com/90328726/miles-lets-you-earn-airplanes-miles-for-walking-biking-or-scootering",
        testimonialStr: `“Miles: the world’s first universal miles earnings program… is ready to take over the world” `,
        testimonialStyle: {
          width: "300px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_Forbes_img,
        logoImgStyle: {
          width: "126.4px",
          height: "33px",
        },
        webUrl: "https://www.forbes.com/sites/grantmartin/2019/04/03/miles-a-loyalty-program-for-everyday-transportation-raises-additional-funding-and-expands-reach/",
        testimonialStr: `“Miles has a lot of potential to connect travelers, brands, and destinations”`,
        testimonialStyle: {
          width: "305px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_ozy_img,
        logoImgStyle: {
          width: "103px",
          height: "39px",
        },
        webUrl: "https://www.ozy.com/good-sht/how-to-get-free-coffee-just-for-taking-the-train-to-work/228052/",
        testimonialStr: `“The app puts perks into your soul-sucking commute.”`,
        testimonialStyle: {
          width: "275px",
          height: "66px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_the_points_guy_img,
        logoImgStyle: {
          width: "93px",
          height: "66px",
        },
        webUrl: "https://thepointsguy.com/news/the-miles-app-will-reward-you-for-every-mode-of-transportation/",
        testimonialStr: `“The Miles App Will Reward You For Every Mode of Transportation”`,
        testimonialStyle: {
          width: "233px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_greenBiz_img,
        logoImgStyle: {
          width: "175px",
          height: "40px",
        },
        webUrl: "https://www.greenbiz.com/article/introducing-frequent-flyer-program-ground-transportation",
        testimonialStr: `“Introducing a frequent flyer program for ground transportation”`,
        testimonialStyle: {
          width: "275px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_mashable_img,
        logoImgStyle: {
          width: "236px",
          height: "42px",
        },
        webUrl: "https://mashable.com/2018/07/24/miles-transit-app-rewards/#zpL0f2ySU5q6",
        testimonialStr: `“Frequent flyer miles aren't just for airplanes -- this app rewards you for walking, carpooling”`,
        testimonialStyle: {
          width: "275px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_the_verge_img,
        logoImgStyle: {
          width: "235px",
          height: "42px",
        },
        webUrl: "https://www.theverge.com/2018/7/24/17604438/miles-app-tracking-transportation-deals-discounts",
        testimonialStr: `“Miles is an app that tracks your every move in exchange for deals and discounts”`,
        testimonialStyle: {
          width: "255px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_nast_traveler_img,
        logoImgStyle: {
          width: "132px",
          height: "66px",
        },
        webUrl: "https://www.cntraveler.com/story/this-new-app-will-give-you-free-starbucks-just-for-traveling",
        testimonialStr: `“You can use your trips.. to save money on everyday purchases— sounds like a dream come true.”`,
        testimonialStyle: {
          width: "275px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_skift_img,
        logoImgStyle: {
          width: "132px",
          height: "49px",
        },
        webUrl: "https://skift.com/2018/07/24/l/",
        testimonialStr: `“Walk a few miles and earn points: New location-based loyalty program launches”`,
        testimonialStyle: {
          width: "275px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_card_rates_img,
        logoImgStyle: {
          width: "146px",
          height: "68px",
        },
        webUrl: "https://www.cardrates.com/news/miles-lets-users-earn-rewards-for-ground-travel/",
        testimonialStr: `“The platform is geared toward those of us who don't fly frequently but still..rewarded for our travel”`,
        testimonialStyle: {
          width: "302px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_badcredit_img,
        logoImgStyle: {
          width: "130.7px",
          height: "58px",
        },
        webUrl: "https://www.badcredit.org/news/miles-app-offers-rewards-for-all-transportation/",
        testimonialStr: `“Global names leverage Miles as an engagement channel, while startups… for its efficiency”`,
        testimonialStyle: {
          width: "275px",
          height: "99px",
        },
      },
      {
        logoImgSrc: we_are_highlighted_curbed_img,
        logoImgStyle: {
          width: "145.8px",
          height: "75px",
        },
        webUrl: "https://www.curbed.com/2019/4/11/18306547/london-ultra-low-emission-zone-biking",
        testimonialStr: `“Miles has gamified using non-car transportation, but it also adds a rewards structure”`,
        testimonialStyle: {
          width: "275px",
          height: "99px",
        },
      },
      {
        logoImgSrc: logo_2022_wired_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.wired.com/story/public-transit-agencies-rewards-programs/",
        testimonialStr: `“Transit officials around the country are preparing for your commute to score you...drinks, gift certificates” `,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_greekwire_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.geekwire.com/2019/earn-green-greener-travel-transit-agency-teams-rewards-app-miles-target-commuters/",
        testimonialStr: `“Commuters looking for a reason to ditch cars and use alternate modes of transport will be encouraged to do so”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_smartCitiesDrive_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.smartcitiesdive.com/news/sacramento-transportation-app-low-carbon-travel/551563/",
        testimonialStr: `“Miles is a great opportunity for people to consider trying transit or change the way they get around”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_metro_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.metro-magazine.com/10031300/samtrans-caltrain-launch-ridership-rewards-program",
        testimonialStr: `“Travelers can now earn unique rewards simply by choosing to take their trip on SamTrans or Caltrain.”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_massTransit_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.masstransitmag.com/technology/passenger-info/mobile-applications/article/21085382/samtrans-caltrains-partner-with-miles-on-gamification-pilot-program",
        testimonialStr: `“The trio of partners will offer exclusive rewards...and special public transit challenges”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_techCrunch_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://techcrunch.com/2020/07/14/miles-the-app-that-offers-rewards-for-traveling-finds-a-strategic-investor-in-mexicos-mobilityado/",
        testimonialStr: `“While Miles rewards all travel, it gives bonuses for different, greener modes of transportation.”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_intelligentTransport_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.intelligenttransport.com/transport-news/96382/nine-tech-start-ups-chosen-to-pilot-transport-solutions-in-new-york/",
        testimonialStr: `“Miles provides multimodal analytics to cities and offers mileage based rewards…encouraging public transit use”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_KQED_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://www.kqed.org/news/11759464/could-rewards-systems-work-for-public-transit",
        testimonialStr: `“Rewards programs have long been used...to incentivize customers. Could that work for public transit, too?”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
      {
        logoImgSrc: logo_2022_eastCountyToday_v1,
        logoImgStyle: {
          width: "218px",
          height: "31px",
        },
        webUrl: "https://eastcountytoday.net/contra-costa-transportation-authority-partnership-promotes-cashing-in-on-your-commute/",
        testimonialStr: `“The ability to provide real-time, earned rewards is now easier with the Miles app.”`,
        testimonialStyle: {
          width: "305px",
          height: "auto",
        },
        version_type: 'version_2022_v1',
      },
    ];
  
    const items = card_with_testimonial_data.map((card, index) => ({
      id: index.toString(),
      renderItem: (
        <Card_with_testimonial
          key={`we_are_highlighted_card_${index}`}
          className="react-multi-carousel-item"
          logoImgSrc={card.logoImgSrc}
          logoImgStyle={{
            width: card.logoImgStyle.width,
            height: card.logoImgStyle.height,
          }}
          webUrl={card.webUrl}
          testimonialStr={card.testimonialStr}
          testimonialStyle={{
            width: card.testimonialStyle.width,
            height: card.testimonialStyle.height,
          }}
          version_type={card.version_type || ""}
        />
      ),
    } as ReactSpringCarouselItem));
    return items;
  }, []);

  const {
    carouselFragment,
    dotsFragment,
    slideToPrevItem,
    slideToNextItem,
  } = useSpringCarousel({
    items: items,
    itemsPerSlide,
    withDots: true,
  });

  const buttons = <>
    <div onClick={slideToPrevItem}>
      <div className="miles_carousel_wrap_arrow_left miles_ui_large_than_768" />
      <div className="miles_carousel_wrap_arrow_left miles_ui_less_than_768" />
    </div>
    <div onClick={slideToNextItem}>
      <div className="miles_carousel_wrap_arrow_right miles_ui_large_than_768" />
      <div className="miles_carousel_wrap_arrow_right miles_ui_less_than_768" />
    </div>
  </>;

  return (
    <div style={{ width: 'inherit', backgroundColor: "#fffaf5" }}>
      <Styled_We_are_highlighted>
        <div className="title">{currentTitle}</div>
        <div
          style={{
            position: 'relative'
          }}
        >
          <div className="carousel-container miles_carousel_wrap react-multi-carousel-list">
            {carouselFragment}
            {buttons}
          </div>
          <div className="react-multi-carousel-dot-list custom-dot-list-style">
            {dotsFragment}
          </div>
        </div>
        <a className="cta_btn miles_ui_btn_primary" href="/press">View All</a>
        </Styled_We_are_highlighted>
    </div>
  );
};
