import React from "react";
import { StyledRewardShareTextImgCard } from "./styles";
import { useTranslation } from 'react-i18next';

interface Props {
  src: string;
}

export const RewardShareTextImgCard: React.FC<Props> = ({
  src
}) => {
  const { t } = useTranslation();
  return (
    <StyledRewardShareTextImgCard>
      <img className="reward-gift-icon" src={src} alt="reward gift icon"></img>
      <div className="reward-share-bottom-text-wrap">
        <p className="reward-share-bottom-text-1">{t('rs_text_011')}</p>
        <p className="reward-share-bottom-text-2">{t('rs_text_012')}</p>
      </div>
    </StyledRewardShareTextImgCard>
  );
};
