import styled from "styled-components";

export const Styled_body_wrap = styled("div")`
  margin: 0;

  .ciq-root {
    display: flex;
    justify-content: center;
    width: auto;
  }

  .ciq-container {
    width: auto;
  }

  .article {
    width: 950px;
    height: 300px;
    padding: 20px 39px 22px 25px;
    border-radius: 32px;
    box-shadow: 0 0 17px 0 #e6e6e8;
    background-color: #ffffff;
    position: relative;
  }

  .image {
    position: absolute;
  }

  .logo {
    position: absolute;
    left: 419px;
    top: 36px;
  }

  .title {
    position: absolute;
    left: 420px;
    top: 96px;
  }

  .subtitle {
    position: absolute;
    left: 420px;
    top: 176px;
  }

  .date {
    position: absolute;
    left: 420px;
    top: 243px;
  }

  .cta-read-more {
    position: absolute;
    left: 839px;
    top: 243px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    .article {
      width: 650px;
      height: 300px;
      padding: 20px 25px 20px 25px;
    }

    .image {
      position: absolute;
    }

    .logo {
      position: absolute;
      left: 287px;
      top: 36px;
    }

    .title {
      position: absolute;
      left: 285px;
      top: 96px;
    }

    .subtitle {
      position: absolute;
      left: 285px;
      top: 176px;
    }

    .date {
      position: absolute;
      left: 285px;
      top: 243px;
    }

    .cta-read-more {
      position: absolute;
      left: 550px;
      top: 243px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (width : 1440px) {
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    
  }
`;