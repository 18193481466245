import React, { useEffect, ReactNode, useMemo, useLayoutEffect, Ref, useImperativeHandle, useRef } from "react";
import { observer } from "mobx-react";
import config from "../../../../utils/config";
import { Styled_body_wrap, Styled_Card } from "./styles";

const {
  about_page_assets: assets,
} = config;

interface CardProps {
  imgSrc: string;
  descriptionEle: string | JSX.Element;
  subDescriptionEle?: string | JSX.Element;
  className?: string;
}

const CardUnit = observer(React.forwardRef(({
  imgSrc,
  descriptionEle,
  subDescriptionEle,
  className,
}: CardProps, ref: Ref<HTMLDivElement>) => {
  return (
    <Styled_Card ref={ref} className={`carousel_main_wrap CardUnit_on_carousel_01 ${className}`}>
      <img src={imgSrc} className={`carousel_01_img`} alt={`carousel_03_img`} />
      <div className="descriptionEle title">{descriptionEle}</div>
      <div className="descriptionEle subtitle">{subDescriptionEle}</div>
    </Styled_Card>
  );
}));


export type Team = (
  'design' |
  'engineering' |
  'marketing' |
  'support'
);


export type AvatarChildInfos = {
  [id in Team]: number[];
};

export type AvatarInfo = {
  rootElement: HTMLElement,
  childInfos: AvatarChildInfos,
};

export type AvatarChildRefs = Ref<AvatarChildInfos>;

const Component = observer(React.forwardRef((props: {}, ref) => {
  const carouselData_desktop = {
    design: [
      {
        imgSrc: assets.carousel_02_Product_Design_Pradeep_img,
        descriptionEle: <span>Pradeep Periasamy</span>,
        subDescriptionEle: <span>UX/UI Lead</span>,
        className: 'design first',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Evan_img,
        descriptionEle: <span>Evan Shores</span>,
        subDescriptionEle: <span>Sr. Product Manager</span>,
        className: 'design',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Anita_img,
        descriptionEle: <span>Anita Liao</span>,
        subDescriptionEle: <span>UX/UI Designer</span>,
        className: 'design',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Jing_img,
        descriptionEle: <span>Jing Li</span>,
        subDescriptionEle: <span>UX/UI Designer</span>,
        className: 'design',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Xinya_img,
        descriptionEle: <span>Xinya Wang</span>,
        subDescriptionEle: <span>UX/UI Designer</span>,
        className: 'design',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Irene_img,
        descriptionEle: <span>Irene Kadir</span>,
        subDescriptionEle: <span>Sr. Visual Designer</span>,
        className: 'design first',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Yuqi_img,
        descriptionEle: <span>Yuqi Lu</span>,
        subDescriptionEle: <span>UX/UI Designer</span>,
        className: 'design',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Ying_img,
        descriptionEle: <span>Ying Xu</span>,
        subDescriptionEle: <span>UX/UI Designer</span>,
        className: 'design',
      },
      {
        imgSrc: assets.carousel_02_Product_Design_Sayyam_img,
        descriptionEle: <span>Sayyam Mehta</span>,
        subDescriptionEle: <span>Product Intern</span>,
        className: 'design',
      },
    ],
    engineering: [
      {
        imgSrc: assets.carousel_02_Product_Engineering_Mayukh_img,
        descriptionEle: <span>Mayukh Samanta</span>,
        subDescriptionEle: <span>Staff Engineer (Data <br />Science)</span>,
        className: 'engineering first',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Doron_img,
        descriptionEle: <span>Doron Lemze</span>,
        subDescriptionEle: <span>Sr. Data Scientist</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Darshil_img,
        descriptionEle: <span>Darshil Desai</span>,
        subDescriptionEle: <span>Data Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Kashish_img,
        descriptionEle: <span>Kashish Parikh</span>,
        subDescriptionEle: <span>Data Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Janit_img,
        descriptionEle: <span>Janit Modi</span>,
        subDescriptionEle: <span>Data Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Abhinav_img,
        descriptionEle: <span>Abhinav Sharma</span>,
        subDescriptionEle: <span>Data Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Siddhi_img,
        descriptionEle: <span>Siddhi Borkar</span>,
        subDescriptionEle: <span>Data Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Amit_img,
        descriptionEle: <span>Amit Soni</span>,
        subDescriptionEle: <span>Sr. S/w Engineer (QA)</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_David_img,
        descriptionEle: <span>David Matevosyan</span>,
        subDescriptionEle: <span>S/w Engineer (QA)</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Eshan_img,
        descriptionEle: <span>Eshan Toshniwal</span>,
        subDescriptionEle: <span>Backend Engineer Intern</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Austin_img,
        descriptionEle: <span>Austin Kelley</span>,
        subDescriptionEle: <span>iOS Lead</span>,
        className: 'engineering first',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_John_img,
        descriptionEle: <span>John Baer</span>,
        subDescriptionEle: <span>iOS Developer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Navdeep_img,
        descriptionEle: <span>Navdeep Patel</span>,
        subDescriptionEle: <span>Android Developer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Gautam_img,
        descriptionEle: <span>Gautam Adavi</span>,
        subDescriptionEle: <span>Android Developer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Harrison_img,
        descriptionEle: <span>Harrison Oglesby</span>,
        subDescriptionEle: <span>Android Developer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Praharshit_img,
        descriptionEle: <span>Praharshit Gorripaty</span>,
        subDescriptionEle: <span>Backend Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Prutha_img,
        descriptionEle: <span>Prutha Kulkarni</span>,
        subDescriptionEle: <span>Backend Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Vaibhav_img,
        descriptionEle: <span>Vaibhav Shanbhag</span>,
        subDescriptionEle: <span>Backend Engineer</span>,
        className: 'engineering',
      },
      {
        imgSrc: assets.carousel_02_Product_Engineering_Dylan_img,
        descriptionEle: <span>Dylan Teare</span>,
        subDescriptionEle: <span>Backend Engineer Intern</span>,
        className: 'engineering',
      },
    ],
    marketing: [
      {
        imgSrc: assets.carousel_02_Product_Growth_Sales_Joann_img,
        descriptionEle: <span>Joann Panyarachun</span>,
        subDescriptionEle: <span>Partner Success <br />Manager</span>,
        className: 'marketing first',
      },
      {
        imgSrc: assets.carousel_02_Product_Growth_Sales_Ray_img,
        descriptionEle: <span>Ray. Dequina</span>,
        subDescriptionEle: <span>Partner Success</span>,
        className: 'marketing',
      },
      {
        imgSrc: assets.carousel_02_Product_Growth_Sales_Parker_img,
        descriptionEle: <span>Parker Davis</span>,
        subDescriptionEle: <span>Marketing Manager <br />Content & Engagement</span>,
        className: 'marketing',
      },
      {
        imgSrc: assets.carousel_02_Product_Growth_Sales_Tiffany_img,
        descriptionEle: <span>Tiffany Liu</span>,
        subDescriptionEle: <span>Marketing Associate</span>,
        className: 'marketing first',
      },
      {
        imgSrc: assets.carousel_02_Product_Growth_Sales_Lorena_img,
        descriptionEle: <span>Lorena Zapata</span>,
        subDescriptionEle: <span>Growth Marketing <br />Associate</span>,
        className: 'marketing',
      },
    ],
    support: [
      {
        imgSrc: assets.carousel_02_Product_Support_Estephania_img,
        descriptionEle: <span>Estephania. M</span>,
        subDescriptionEle: <span>Customer Support <br />Specialist</span>,
        className: 'support first',
      },
      {
        imgSrc: assets.carousel_02_Product_Support_Andy_img,
        descriptionEle: <span>Andy Senter</span>,
        subDescriptionEle: <span>Sr. Customer Support <br />Specialist</span>,
        className: 'support',
      },
      {
        imgSrc: assets.carousel_02_Product_Support_Christopher_img,
        descriptionEle: <span>Christopher. P</span>,
        subDescriptionEle: <span>Customer Support <br />Specialist</span>,
        className: 'support',
      },
      {
        imgSrc: assets.carousel_02_Product_Support_Jenny_img,
        descriptionEle: <span>Jenny Trieu</span>,
        subDescriptionEle: <span>Customer Support <br />Specialist</span>,
        className: 'support',
      },
      {
        imgSrc: assets.carousel_02_Product_Support_Stephany_img,
        descriptionEle: <span>Stephany Aragon</span>,
        subDescriptionEle: <span>Customer Support <br />Specialist</span>,
        className: 'support first',
      },
      {
        imgSrc: assets.carousel_02_Product_Support_Nishita_img,
        descriptionEle: <span>Nishita Shankar</span>,
        subDescriptionEle: <span>Customer Support <br />Specialist</span>,
        className: 'support',
      },
      {
        imgSrc: assets.carousel_02_Product_Support_Ivan_img,
        descriptionEle: <span>Ivan Maupome</span>,
        subDescriptionEle: <span>Operations Intern</span>,
        className: 'support',
      },
    ]
  }

  const allRefs = useMemo(() => {
    return {
      root: null as HTMLElement|null,
      childRefs: {
        design: Array<HTMLElement|null>(carouselData_desktop.design.length),
        engineering: Array<HTMLElement|null>(carouselData_desktop.engineering.length),
        marketing: Array<HTMLElement|null>(carouselData_desktop.marketing.length),
        support: Array<HTMLElement|null>(carouselData_desktop.support.length),
      },
    }
  }, []);

  const {childRefs} = allRefs;

  useImperativeHandle(ref, () => {
    function getLeftMargins(items: (HTMLElement|null)[]) {
      const leftMarginSet = new Set(items.map((item) => item!.offsetLeft));
      const leftMargins = [...leftMarginSet];
      leftMargins.sort((a, b) => a - b);
      return leftMargins;
    }
    return {
      rootElement: allRefs.root,
      childInfos: {
        design: getLeftMargins(childRefs.design),
        engineering: getLeftMargins(childRefs.engineering),
        marketing: getLeftMargins(childRefs.marketing),
        support: getLeftMargins(childRefs.support),  
      },
    };
  }, [childRefs]);

  let carouselCards_design_01: ReactNode[] = []
  carouselData_desktop.design.map((card, index) => {
    if(index < Math.ceil(carouselData_desktop.design.length/2)) {
      carouselCards_design_01.push(
        <CardUnit
          ref={(val) => {childRefs.design[index] = val;}}
          key={`about_us_carousel_02_desktop_design_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_design_02: ReactNode[] = []
  carouselData_desktop.design.map((card, index) => {
    if(index >= Math.ceil(carouselData_desktop.design.length/2)) {
      carouselCards_design_02.push(
        <CardUnit
          ref={(val) => {childRefs.design[index] = val;}}
          key={`about_us_carousel_02_desktop_design_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_engineering_01: ReactNode[] = []
  carouselData_desktop.engineering.map((card, index) => {
    if(index < Math.ceil(carouselData_desktop.engineering.length/2)) {
      carouselCards_engineering_01.push(
        <CardUnit
          ref={(val) => {childRefs.engineering[index] = val;}}
          key={`about_us_carousel_02_desktop_engineering_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_engineering_02: ReactNode[] = []
  carouselData_desktop.engineering.map((card, index) => {
    if(index >= Math.ceil(carouselData_desktop.engineering.length/2)) {
      carouselCards_engineering_02.push(
        <CardUnit
          ref={(val) => {childRefs.engineering[index] = val;}}
          key={`about_us_carousel_02_desktop_engineering_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_marketing_01: ReactNode[] = []
  carouselData_desktop.marketing.map((card, index) => {
    if(index < Math.ceil(carouselData_desktop.marketing.length/2)) {
      carouselCards_marketing_01.push(
        <CardUnit
          ref={(val) => {childRefs.marketing[index] = val;}}
          key={`about_us_carousel_02_desktop_marketing_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_marketing_02: ReactNode[] = []
  carouselData_desktop.marketing.map((card, index) => {
    if(index >= Math.ceil(carouselData_desktop.marketing.length/2)) {
      carouselCards_marketing_02.push(
        <CardUnit
          ref={(val) => {childRefs.marketing[index] = val;}}
          key={`about_us_carousel_02_desktop_marketing_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_support_01: ReactNode[] = []
  carouselData_desktop.support.map((card, index) => {
    if(index < Math.ceil(carouselData_desktop.support.length/2)) {
      carouselCards_support_01.push(
        <CardUnit
          ref={(val) => {childRefs.support[index] = val;}}
          key={`about_us_carousel_02_desktop_support_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  let carouselCards_support_02: ReactNode[] = []
  carouselData_desktop.support.map((card, index) => {
    if(index >= Math.ceil(carouselData_desktop.support.length/2)) {
      carouselCards_support_02.push(
        <CardUnit
          ref={(val) => {childRefs.support[index] = val;}}
          key={`about_us_carousel_02_desktop_support_${index}`}
          imgSrc={card.imgSrc}
          descriptionEle={card.descriptionEle}
          subDescriptionEle={card.subDescriptionEle}
          className={card.className}
        />
      )
    }
  })

  return (
    <>
      <Styled_body_wrap ref={(ref) => {allRefs.root = ref;}} className="ciq_root">
        <div className="avatar_box_wrap outSite">
          <div className="innerBox box_common">
            <div className="inner_sub_wrap">{carouselCards_design_01}</div>
            <div className="inner_sub_wrap innerBox_margin_top">{carouselCards_design_02}</div>
          </div>
          <div className="innerBox box_common">
            <div className="inner_sub_wrap">{carouselCards_engineering_01}</div>
            <div className="inner_sub_wrap innerBox_margin_top">{carouselCards_engineering_02}</div>
          </div>
          <div className="innerBox box_common">
            <div className="inner_sub_wrap">{carouselCards_marketing_01}</div>
            <div className="inner_sub_wrap innerBox_margin_top">{carouselCards_marketing_02}</div>
          </div>
          <div className="innerBox box_common">
            <div className="inner_sub_wrap">{carouselCards_support_01}</div>
            <div className="inner_sub_wrap innerBox_margin_top">{carouselCards_support_02}</div>
          </div>
        </div>
      </Styled_body_wrap>
    </>
  );
}));

export default Component;
