import styled from "styled-components";
import config from "../../utils/config";
const {
	// reward sharing start
  primary_color_01
	// reward sharing end
  } = config

interface StyledGetTheAppBoxProps {}

export const StyledGetTheAppBox = styled("a")<StyledGetTheAppBoxProps>`
    display: none;

    /* mobile phone */
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    display: visible;
    display: block;
    margin: 0 auto;
    margin-top: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "15px" : "25px"};
    margin-bottom: ${process.env.REACT_APP_MILES_TENANT === "fca" ? "20px" : "25px"};
    height: 55px;
    width: 335px;
    padding: 10px;
    color: #ffffff;
    background-color: ${() => primary_color_01};
    border: 1px solid ${() => primary_color_01};
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: 0.5px;
    text-align: center;
    outline: none;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 27.5px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    outline: none;
    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: visible;
    display: block;
    margin: 25px auto;
    height: 55px;
    width: 335px;
    padding: 10px;
    color: #ffffff;
    background-color: ${() => primary_color_01};
    border: 1px solid ${() => primary_color_01};
    font-family: 'Avenir Next';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 35px;
    letter-spacing: 0.5px;
    text-align: center;
    outline: none;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 27.5px;
    box-shadow: 3px 8px 24px 1px rgba(32, 32, 35, 0.19);
    outline: none;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  /* iPhone 5 ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 95%;
  }
`;