import styled from "styled-components";
interface Tab_carousel_wrap_Props {}
export const Styled_Tab_carousel_wrap = styled("div")<Tab_carousel_wrap_Props>`
  width: 100vw;
  transition: opacity .6s ease-in-out;
  transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
`

interface Tab_carousel_Props {}
export const Styled_Tab_carousel = styled("div")<Tab_carousel_Props>`
  margin: 0 auto;
  padding: 0;
  min-height: 1121px;
  height: 1121px;
  box-sizing: border-box;

  .carousel-indicators [data-mdb-target] {
    text-indent: unset;
    border: 0;
    margin: 0;
    background-color: transparent;
  }

  .carousel-indicators.carousel_tab {
    margin: 0 280px;
    height: 183px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    padding: 0;
    left: 0;
    right: 0;
    bottom: auto;
    margin-top: 119px;

    .tab_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;

      width: 200px;
      height: 183px;

      img {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin-top: 30px;
        opacity: 0.5;
      }

      .title {
        width: 128px;
        height: 40px;
        margin: 11px 0 0;
        font-family: Noto Sans JP;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        text-align: center;
        color: #82828c;
        margin-top: 11px;
      }

    }

    .tab_box.active {
      width: 200px;
      height: 183px;
      padding: 0;
      border-radius: 18px;
      box-shadow: 0 2px 10px 3px #e6e6e8;
      background-color: #ffffff;
      box-sizing: border-box;

      img {
        display: inline-block;
        width: 120px;
        height: 120px;
        margin-top: 13px;
        opacity: 1;
      }

      .title {
        width: max-content;
        height: 42px;
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #2d2f3b;
        margin-top: -12px;
      }

      .border_line {
        width: 150px;
        height: 6px;
        background-color: #10af42;
        margin-top: 14px;
      }
    }
  }

  .carousel {
    width: inherit;
    height: 525px;
    height: inherit;
  }

  .carousel-inner {
    height: 1121px;
  }

  .carousel_slide_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 202px;
    padding-right: 201px;
    padding-top: 302px;
    height: 1121px;
    left: 0;
    bottom: 0;
    right: 0;

    transition: 2.5s;
    transition-timing-function: ease-in;
    .carousel_slide_inner_left {
      height: 525px;
      .left_p_01 {
        width: 388px;
        font-family: Noto Sans JP;
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #2d2f3b;
        text-align: left;
        margin-top: 148px;

        .left_p_01_02 {
          height: 60px;
          line-height: 60px;
        }

        .left_p_01_02 {
          font-family: Noto Sans JP;
          font-size: 48px;
          height: 60px;
          line-height: 60px;
        }
      }

      .left_p_02 {
        margin-top: 20px;
        width: 450px;
        height: 111px;
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2d2f3b;
        text-align: left;

        &.slide_02 {
          width: 350px;
        }
        &.slide_03 {
          width: 360px;
        }
        &.slide_04 {
          width: 250px;
        }
      }
    }

    img {
      display: inline-block;
      width: 512px;
      height: 525px;
    }
  }

  .carousel_arrow_left_wrap {
    .miles_ui_large_than_768_screen {
      width: 97px;
      height: 97px;
    }
  }

  .carousel_arrow_right_wrap {
    .miles_ui_large_than_768_screen {
      width: 97px;
      height: 97px;
    }
  }

  .carousel-control-prev {
    width: 97px;
    height: 97px;
    opacity: 1;
    margin-left: 51px;
    margin-top: 652px;
  }

  .carousel-control-next {
    width: 97px;
    height: 97px;
    opacity: 1;
    margin-right: 50px;
    margin-top: 652px;
  }

  .miles_ui_large_than_768_screen {
    display: block;
  }

  .miles_ui_less_than_768_screen {
    display: none;
  }

  /* Mini Extra Small Devices, Phones */ 
  @media only screen and (max-width : 360px) {
    min-height: 635px;
    height: 635px;
    width: 100vw;
    min-width: 360px;

    .miles_ui_large_than_768_screen {
      display: none;
    }
  
    .miles_ui_less_than_768_screen {
      display: block;
    }
    .carousel-indicators.carousel_tab {
      margin: 0 auto;
      width: 360px;
      margin-top: 55px;

      .tab_box {
        width: 90px;
        height: 80px;

        img {
          width: 40px;
          height: 40px;
          margin-top: 13px;
        }
  
        .title {
          display: none;
        }
  
      }
  
      .tab_box.active {
        width: 90px;
        height: 80px;

        img {
          width: 60px;
          height: 60px;
          margin-top: 8px;
        }
  
        .title {
          display: none;
        }
  
        .border_line {
          width: 60px;
          height: 4px;
          background-color: #10af42;
          margin-top: 8px;
        }
      }
    }

    .carousel {}
  
    .carousel-inner {
      height: 635px;
    }
  
    .carousel_slide_wrap {
      padding-left: 69px;
      padding-right: 69px;
      padding-top: 159px;
      height: 635px;
      flex-direction: column;
      justify-content: flex-start;
  
      .carousel_slide_inner_left {
        height: 126px;

        .left_p_01 {
          width: auto;
          height: auto;
          margin-top: 0;
          height: 30px;
          height: auto;
          font-family: Noto Sans JP;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: 0.56px;
          text-align: center;
          color: #2d2f3b;

  
          .left_p_01_02 {
            height: 33px;
            line-height: 33px;
          }
  
          .left_p_01_02 {
            font-family: Noto Sans JP;
            height: 33px;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 33px;
            letter-spacing: 0.75px;
            text-align: center;
            color: #2d2f3b;
          }
        }
  
        .left_p_02 {
          margin-top: 0;
          width: 275px;

          height: 62px;
          font-family: Noto Sans JP;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.53px;
          text-align: center;
          color: #2d2f3b;

          &.slide_02 {
            width: 150px;
          }
          &.slide_03 {
            width: 150px;
          }
          &.slide_04 {
            width: 150px;
          }
        }
      }
  
      img {
        width: 230px;
        height: 230px;
        margin-top: 16px;
      }
    }
  
    .carousel_arrow_left_wrap {
      .miles_ui_less_than_768_screen {
        width: 10px;
        height: 16px;
      }
    }
  
    .carousel_arrow_right_wrap {
      .miles_ui_less_than_768_screen {
        width: 10px;
        height: 16px;
      }
    }
  
    .carousel-control-prev {
      width: 10px;
      height: 16px;
      opacity: 1;
      margin-left: calc(50% - 160px);
      margin-top: 379px;
    }
  
    .carousel-control-next {
      width: 10px;
      height: 16px;
      opacity: 1;
      margin-right: calc(50% - 160px);
      margin-top: 379px;
    }
  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 361px) and (max-width : 768px) {
    min-height: 635px;
    height: 635px;
    width: 100vw;
    .miles_ui_large_than_768_screen {
      display: none;
    }
  
    .miles_ui_less_than_768_screen {
      display: block;
    }
    .carousel-indicators.carousel_tab {
      margin: 0 auto;
      width: 360px;
      margin-top: 55px;

      .tab_box {
        width: 90px;
        height: 80px;

        img {
          width: 40px;
          height: 40px;
          margin-top: 13px;
        }
  
        .title {
          display: none;
        }
  
      }
  
      .tab_box.active {
        width: 90px;
        height: 80px;

        img {
          width: 60px;
          height: 60px;
          margin-top: 8px;
        }
  
        .title {
          display: none;
        }
  
        .border_line {
          width: 60px;
          height: 4px;
          background-color: #10af42;
          margin-top: 8px;
        }
      }
    }

    .carousel {}
  
    .carousel-inner {
      height: 635px;
    }
  
    .carousel_slide_wrap {
      padding-left: 69px;
      padding-right: 69px;
      padding-top: 159px;
      height: 635px;
      flex-direction: column;
      justify-content: flex-start;
  
      .carousel_slide_inner_left {
        height: 126px;

        .left_p_01 {
          width: auto;
          height: auto;
          margin-top: 0;
          height: 30px;
          height: auto;
          font-family: Noto Sans JP;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: 0.56px;
          text-align: center;
          color: #2d2f3b;

  
          .left_p_01_02 {
            height: 33px;
            line-height: 33px;
          }
  
          .left_p_01_02 {
            font-family: Noto Sans JP;
            height: 33px;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 33px;
            letter-spacing: 0.75px;
            text-align: center;
            color: #2d2f3b;
          }
        }
  
        .left_p_02 {
          margin-top: 0;
          width: 275px;

          height: 62px;
          font-family: Noto Sans JP;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.53px;
          text-align: center;
          color: #2d2f3b;

          &.slide_02 {
            width: 150px;
          }
          &.slide_03 {
            width: 150px;
          }
          &.slide_04 {
            width: 150px;
          }
        }
      }
  
      img {
        width: 230px;
        height: 230px;
        margin-top: 16px;
      }
    }
  
    .carousel_arrow_left_wrap {
      .miles_ui_less_than_768_screen {
        width: 10px;
        height: 16px;
      }
    }
  
    .carousel_arrow_right_wrap {
      .miles_ui_less_than_768_screen {
        width: 10px;
        height: 16px;
      }
    }
  
    .carousel-control-prev {
      width: 10px;
      height: 16px;
      opacity: 1;
      margin-left: calc(50% - 160px);
      margin-top: 379px;
    }
  
    .carousel-control-next {
      width: 10px;
      height: 16px;
      opacity: 1;
      margin-right: calc(50% - 160px);
      margin-top: 379px;
    }
  }

  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 360px) and (max-device-width : 480px) {
    
  }

  /* iPhone 6, 7, 8 ----------- */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  }

  /* iPhone 6+, 7+, 8+ ----------- */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  }

  /* Small Devices, Tablets ----------- */
  @media only screen and (min-width : 769px) and (max-width : 1150px) {
    min-height: 1024px;
    height: 1024px;
    width: 100vw;
    .carousel-indicators.carousel_tab {
      margin: 0 auto;
      width: 720px;
      margin-top: 71px;

      .tab_box {
        width: 180px;
        height: 183px;

        img {}
  
        .title {}
  
      }
  
      .tab_box.active {
        width: 180px;
        height: 183px;

        img {}
  
        .title {}
  
        .border_line {}
      }
    }

    .carousel {}
  
    .carousel-inner {
      height: 1024px;
    }
  
    .carousel_slide_wrap {
      padding-left: 160px;
      padding-right: 160px;
      padding-top: 306px;
      height: 1024px;
      flex-direction: column;
      justify-content: flex-start;
  
      .carousel_slide_inner_left {
        height: 162px;

        .left_p_01 {
          width: auto;
          height: auto;
          font-family: Noto Sans JP;
          font-size: 22px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: 0.77px;
          text-align: center;
          color: #2d2f3b;
          text-align: center;
          margin-top: 0;
  
          .left_p_01_02 {
            height: 42px;
            line-height: 42px;
          }
  
          .left_p_01_02 {
            font-family: Noto Sans JP;
            font-size: 32px;
            height: 42px;
            line-height: 42px;
          }
        }
  
        .left_p_02 {
          margin-top: 10px;
          width: 330px;
          height: 70px;
          font-family: Noto Sans JP;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.7px;
          text-align: center;
          color: #2d2f3b;

          &.slide_02 {
            width: 182px;
          }
          &.slide_03 {
            width: 200px;
          }
          &.slide_04 {
            width: 200px;
          }
        }
      }
  
      img {
        width: 455px;
        height: 455px;
        margin-top: 27px;
      }
    }
  
    .carousel_arrow_left_wrap {
      .miles_ui_large_than_768_screen {
        width: 60px;
        height: 60px;
      }
    }
  
    .carousel_arrow_right_wrap {
      .miles_ui_large_than_768_screen {
        width: 60px;
        height: 60px;
      }
    }
  
    .carousel-control-prev {
      width: 60px;
      height: 60px;
      opacity: 1;
      margin-left: 160px;
      margin-top: 646px;
    }
  
    .carousel-control-next {
      width: 60px;
      height: 60px;
      opacity: 1;
      margin-right: 160px;
      margin-top: 646px;
    }
  }
  
  /* ----------- desktop ----------- */
  /* Medium Devices, Desktops ----------- */
  @media only screen and (min-width : 1151px) and (max-width : 1439px) {
    width: 100vw;
    .carousel-indicators.carousel_tab {
      margin: 0 209px;
      margin-top: 119px;

      .tab_box {
        img {}
  
        .title {}
  
      }
  
      .tab_box.active {
        img {}
  
        .title {}
  
        .border_line {}
      }
    }

    .carousel {}
  
    .carousel-inner {}
  
    .carousel_slide_wrap {
      padding-left: 156px;
      padding-right: 178px;
      padding-top: 302px;
      height: 1121px;
  
      .carousel_slide_inner_left {
        height: 525px;

        .left_p_01 {
          color: #2d2f3b;
          margin-top: 148px;
  
          .left_p_01_02 {}
  
          .left_p_01_02 {}
        }
  
        .left_p_02 {

          &.slide_02 {
            width: 350px;
          }
          &.slide_03 {
            width: 350px;
          }
          &.slide_04 {
            width: 350px;
          }
        }
      }
  
      img {
        width: 469px;
        height: 469px;
      }
    }
  
    .carousel_arrow_left_wrap {
      .miles_ui_large_than_768_screen {
        width: 50px;
        height: 50px;
      }
    }
  
    .carousel_arrow_right_wrap {
      .miles_ui_large_than_768_screen {
        width: 50px;
        height: 50px;
      }
    }
  
    .carousel-control-prev {
      width: 50px;
      height: 50px;
      opacity: 1;
      margin-left: 89px;
      margin-top: 672px;
    }
  
    .carousel-control-next {
      width: 50px;
      height: 50px;
      opacity: 1;
      margin-right: 103px;
      margin-top: 672px;
    }
  }

  /* Large Devices, Wide Screens ----------- */
  @media only screen and (min-width : 1441px) {
    width: 1440px;
  }
`;