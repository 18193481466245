import styled from "styled-components";

interface Props {}
export const StyledImg = styled("img")<Props>`
  width: 250px;
  height: 125px;
  object-fit: contain;
  display: block;
  margin: 50px auto 30px auto;

  /* Extra Small Devices, Phones */ 
  @media only screen and (min-width : 320px) and (max-width : 768px) {
    width: 202px;
    height: 100px;
    margin: 20px auto;
  }
  /* ----------- Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 202px;
    height: 100px;
    margin: 20px auto;
  }
`;