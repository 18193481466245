import React from "react";
import config from "../../../utils/config";
import { StyledEle } from "./styles";

const {
  cities_page_assets: assets,
} = config;

interface Props {}

export const Our_partnerships_box: React.FC<Props> = ({}) => {
  return (
    <StyledEle>
      <div className="title_top">Our partnerships</div>
      <div className="sub_title sub_title_01">City partners</div>
      <picture className="our_partnerships_box_box">
        <source className="miles_ui_our_partnerships_box_img_01" media="(max-width : 360px)" srcSet={assets.our_partnership_box_01_360}></source>
        <source className="miles_ui_our_partnerships_box_img_01" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.our_partnership_box_01_360}></source>
        <source className="miles_ui_our_partnerships_box_img_01" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.our_partnership_box_01_1000}></source>
        <source className="miles_ui_our_partnerships_box_img_01" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.our_partnership_box_01_1280}></source>
        <source  className="miles_ui_our_partnerships_box_img_01" media="(min-width : 1441px)" srcSet={assets.our_partnership_box_01_1440}></source>
        <img className="miles_ui_our_partnerships_box_img_01" alt={`top_box_img`}></img>
      </picture>
      <div className="sub_title sub_title_02">Mobility service providers</div>
      <picture className="miles_ui_cities_page_top_box">
        <source className="miles_ui_our_partnerships_box_img_02" media="(max-width : 360px)" srcSet={assets.our_partnership_box_02_360}></source>
        <source className="miles_ui_our_partnerships_box_img_02" media="(min-width : 361px) and (max-width : 768px), " srcSet={assets.our_partnership_box_02_360}></source>
        <source className="miles_ui_our_partnerships_box_img_02" media="(min-width : 769px) and (max-width : 1150px)" srcSet={assets.our_partnership_box_02_1000}></source>
        <source className="miles_ui_our_partnerships_box_img_02" media="(min-width : 1151px) and (max-width : 1440px)" srcSet={assets.our_partnership_box_02_1280}></source>
        <source  className="miles_ui_our_partnerships_box_img_02" media="(min-width : 1441px)" srcSet={assets.our_partnership_box_02_1440}></source>
        <img className="miles_ui_our_partnerships_box_img_02" alt={`top_box_img`}></img>
      </picture>
    </StyledEle>
  );
};
